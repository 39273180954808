export type ProductGroup = {
  id: number;
  groupName: string;
  state: number;
  groupType: GroupType;
  description: string;
  groupValues;
  agencyIds?: number[];
  agencys: string[];
};

export type ProductFormValue = {
  groupName: string;
  groupType: GroupType;
  description: string;
  groupValues: any;
  agencyIds: number[];
};

export enum GroupType {
  DOMAIN = 'DOMAIN',
  ADSPACE = 'ADSPACE'
}

export enum InputType {
  SINGLE = 'SINGLE',
  MULTIPLE = 'MULTIPLE'
}
