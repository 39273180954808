import React from 'react';

export class ErrorBoundary extends React.Component<{ errorCode: string } & React.PropsWithChildren, { error, errorInfo }> {
  constructor (props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // componentDidCatch (error, errorInfo) {
  //   if (this.state.error.message === error.toString()) {
  //     return;
  //   }
  //   const renderError: ErrorEventInit = {
  //     error: {
  //       message: error.toString(),
  //       stack: error.stack?.toString(),
  //       errorCode: this.props.errorCode
  //     },
  //     message: errorInfo.componentStack.toString()
  //   };
  //   console.error(renderError.message);
  //   const renderErrorEvent = new ErrorEvent('render-error', renderError);
  //   window.dispatchEvent(renderErrorEvent);
  //   toast.error(`${i18n.t<string>('errorBoundary.labels.title')}: ${this.props.errorCode}`);
  //   this.setState({
  //     error: error,
  //     errorInfo: errorInfo
  //   });
  // }

  render () {
    return this.props.children;
  }
}
