import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AddonFeatureManager, LocaleMeta } from 'core';
import { LimitationManager, DefaultLimitationManager } from 'core/limitation/LimitationManager';
import { DeliverType, RtbCampaignPlanType, CreativeDeliverType, AdType, VideoAdViewObjective, CampaignState, RtbCampaignBasic, RTBCAMPAIGN_DEFAULT_AGE_MIN, RTBCAMPAIGN_DEFAULT_AGE_MAX, RtbCampaign } from 'core/rtbCampaign/RtbCampaign';
import { FinalReportGender, Order, OrderType } from 'core/order/Order';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { ConversionManager, DefaultConversionManager } from 'core/conversion/ConversionManager';
import { RtbCampaignBasicFormModel, RtbCampaignBasicFormModelConstructorParams } from './RtbCampaignForm/RtbCampaignBasicFormModel';
import { defaultInventorySetting, DEFAULT_INVENTORY, LIMITATION_TYPE, pilotTVInventorySetting, retailMediaInventorySetting, keywordInventorySetting } from 'containers/Limitations/LimitationSetting/LimitationSettingData';
import { DefaultEditLimitationModel, EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { OPERATE } from 'enum/Operate';
import moment from 'moment';
import i18n from 'i18next';
import _, { defaultTo, get } from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import { getPriceValue } from 'helper/CurrencyHelper';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { L1Object, L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { CreateOneForAllDisplayFormModel, EditOneForAllDisplayFormModel, OneForAllDisplayFormModel } from './RtbCampaignForm/OneForAllDisplayFormModel';
import { UnknowAdTypeFormModel, CreateUnknowAdTypeFormModel, EditUnknowAdTypeFormModel } from './RtbCampaignForm/UnknowAdTypeFormModel';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { DefaultRtbCampaignSummaryStepModel, RtbCampaignDraftSummaryStepModel, RtbCampaignSummaryStepModel } from './FlowSteps/RtbCampaignSummaryStepModel';
import { Retail } from 'core/product/Product';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { CreatePilotTVFormModel, EditPilotTVFormModel, PilotTVFormModel } from './RtbCampaignForm/PilotTVFormModel';
import { toServerStructure } from 'utils/LimitationUtil';
import { genderOptions, getPmax2RtbAgeGroupsByAgeRange, getPmax3RtbAgeGroupsByAgeRange } from 'core/limitation/l2ObjectTAOptions';
import { EstimatedAudience } from 'core/goSegment/GoSegment';
import { SavedTargeting } from 'core/limitation/Limitation';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { DraftManager, RtbCampaignDraftManager } from 'core/draft/DraftManager';
import { toast } from 'react-toastify';
import { CreateDisplayFormModel, DisplayFormModel, EditDisplayFormModel } from './RtbCampaignForm/DisplayFormModel';
import { CreateVideoFormModel, EditVideoFormModel, VideoFormModel } from './RtbCampaignForm/VideoFormModel';
import { ComboFormModel, CreateComboFormModel, EditComboFormModel } from './RtbCampaignForm/ComboFormModel';
import { CreateOutdoorFormModel, EditOutdoorFormModel, OutdoorFormModel } from './RtbCampaignForm/OutdoorFormModel';
import { CreateThirdPartyBottomFormModel, EditThirdPartyBottomFormModel, ThirdPartyBottomFormModel } from './RtbCampaignForm/ThirdPartyBottomFormModel';
import { CreateThirdPartyRectangleFormModel, EditThirdPartyRectangleFormModel, ThirdPartyRectangleFormModel } from './RtbCampaignForm/ThirdPartyRectangleFormModel';
import { CreateRetailFormModel, EditRetailFormModel, RetailFormModel } from './RtbCampaignForm/RetailFormModel';
import { CtvFormModel, CreateCtvFormModel, EditCtvFormModel } from './RtbCampaignForm/CTVFormModel';

export interface RtbCampaignSetupFlowPageModel {
  readonly type: string;
  readonly objectType: string;
  readonly order: Order;
  readonly conversionOptions?: Array<SelectOptions>;
  readonly addonFeatureManager: AddonFeatureManager;
  readonly state: RtbCampaignSetupFlowPageState;
  readonly event: UpdateEventListener<RtbCampaignSetupFlowPageModel>;
  readonly limitationModel?: EditLimitationModel;
  readonly campaignBasicFormModel?: RtbCampaignBasicFormModel;
  readonly limitationPreSet: any;
  readonly campaignId: number | string | null;
  readonly needSetupTracking: boolean;
  readonly l1Object: L1Object;
  readonly localeMeta?: LocaleMeta;
  readonly estimatedAudience?: EstimatedAudience;
  readonly audienceLowestThreshold: number;
  readonly savedTAList: SavedTargeting[];
  readonly appliedSavedTAInfo?: SelectOptions;
  init (): void;
  setCampaign (campaign: any, rerender?: boolean): void;
  getRtbCampaignBasicFormModel (adType): RtbCampaignBasicFormModel | undefined;
  getLimitationModel (priceModel): EditLimitationModel;
  getTitle (): string;
  onAdTypeChange (adType): void;
  onUnmount (handler): void;
  setOrder (order: Order): void;
  setRedirectPath (redirectPath?: string): void;
  setFinishedRedirectPath (redirectPath?: string): void;
  cancel (): void;
  getRtbCampaignSummaryStepModel (goLast, goStep): RtbCampaignSummaryStepModel;
  getLimitationsSummaryData (limitations): any;
  setShowTAManagement (showTAManagement: boolean): void;
  importLimitation (appliedSavedTAInfo: SelectOptions, limitationValue: any): void;
  onDeleteSaveTA (deletedTAId: number);
  onSaveDraft?: (campaign: RtbCampaign) => void;
}

export type RtbCampaignSetupFlowPageProps = {
  readonly model: RtbCampaignSetupFlowPageModel;
};

export type RtbCampaignSetupFlowPageState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly campaign: any;
  readonly finished: boolean;
  readonly showTAManagement: boolean;
};

export abstract class DefaultRtbCampaignSetupFlowPageModel implements RtbCampaignSetupFlowPageModel {
  objectType: string;
  event: FireableUpdateEventListener<RtbCampaignSetupFlowPageModel>;
  loading: boolean;
  redirectPath?: string;
  addonFeatureManager: AddonFeatureManager;
  campaign: any;
  defaultCampaign: any;
  conversionOptions: Array<SelectOptions>;
  limitationPreSet: any;
  order: Order;
  campaignOneForAllDisplayFormModel?: OneForAllDisplayFormModel;
  campaignPilotTVFormModel?: PilotTVFormModel;
  campaignDisplayFormModel?: DisplayFormModel;
  campaignVideoFormModel?: VideoFormModel;
  campaignOutdoorFormModel?: OutdoorFormModel;
  campaignComboFormModel?: ComboFormModel;
  campaignCtvFormModel?: CtvFormModel;
  campaignUnknowAdTypeFormModel?: UnknowAdTypeFormModel;
  campaignThirdPartyBottomFormModel?: ThirdPartyBottomFormModel;
  campaignThirdPartyRectangleFormModel?: ThirdPartyRectangleFormModel;
  campaignRetailFormModel?: RetailFormModel;
  campaignBasicFormModel?: RtbCampaignBasicFormModel;
  campaignSummaryModel?: RtbCampaignSummaryStepModel;
  limitationModel: any;
  rbLimitationModel: any;
  normalLimitationModel: any;
  finished: boolean;
  goSegments: SelectOptions[] | undefined = [];
  retailers?: Retail[];
  savedTAList: SavedTargeting[] = [];
  showTAManagement: boolean = false;
  appliedSavedTAInfo?: SelectOptions;

  constructor (
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    public l1Object: L1Object,
    public localeMeta?: LocaleMeta,
    protected otherCampaignOfL1Object?: RtbCampaignBasic[],
    protected manager: RtbCampaignManager= new DefaultRtbCampaignManager(),
    protected conversionManager: ConversionManager = new DefaultConversionManager(),
    protected limitationManager: LimitationManager = new DefaultLimitationManager(),
    protected adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    protected rdpManager: RdpManager = new DefaultRdpManager()
  ) {
    this.objectType = 'campaign';
    this.order = { ...order };
    this.order.budgetBalance = this.l1Object ? this.l1Object.budgetBalance : this.order.budgetBalance;
    this.addonFeatureManager = addonFeatureManager;
    this.manager = manager;
    this.conversionManager = conversionManager;
    this.limitationManager = limitationManager;
    this.event = new FireableUpdateEventListener<RtbCampaignSetupFlowPageModel>();
    this.loading = true;
    this.conversionOptions = [];
    this.finished = false;
  }

  abstract get type ();

  abstract init ();

  abstract getTitle ();

  async initRetailOptions () {
    if (this.l1Object?.channel !== L1ObjectChannel.RETAIL_MEDIA) {
      return;
    }
    try {
      this.retailers = await this.rdpManager.getRetails();
    } catch (e) {}
  }

  async initSavedTAList () {
    try {
      this.savedTAList = await this.limitationManager.getSavedTargetings(
        this.order.advertiserId,
        _.get(this.l1Object, 'channel', L1ObjectChannel.RTB),
        this.order.orderType
      );
    } catch (e) {}
  }

  setShowTAManagement = (showTAManagement: boolean): void => {
    this.showTAManagement = showTAManagement;
    this.updateState(false);
  }

  refreshSavedTAList = async () => {
    this.updateState(true);
    try {
      this.savedTAList = await this.limitationManager.getSavedTargetings(
        this.order.advertiserId,
        _.get(this.l1Object, 'channel', L1ObjectChannel.RTB),
        this.order.orderType
      );
    } catch (e) {}
    this.updateState(false);
  }

  get needSetupTracking () {
    const addonEnable = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST) ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.THIRDPARTY_TRACKING) ||
      this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.RETARGETING_TRACKER);
    return addonEnable && ![AdType.PILOT_TV, AdType.OUTDOOR].includes(_.get(this.campaign, 'basic.adType')) && this.order.orderType !== OrderType.GOJEK;
  }

  get estimatedAudience () {
    return this.limitationModel ? this.limitationModel.estimateData : undefined;
  }

  get audienceLowestThreshold () {
    return 5000000;
  }

  to404 () {
    const redirectPath = this.l1Object ?
      `/orders/${this.order.orderNumber}/campaign-groups/${this.l1Object.l1ObjectId}/campaigns/${this.campaignId}/edit/error404` :
      `/orders/${this.order.orderNumber}/campaigns/${this.campaignId}/edit/error404`;
    this.setRedirectPath(redirectPath);
  }

  initBidStrategy () {
    if (!this.campaign) {
      return;
    }
    if (this.l1Object.autoOptimise) {
      _.set(this.campaign, 'basic.bidStrategy', this.l1Object.rtb.bid_strategy);
    } else {
      _.set(this.campaign, 'basic.bidStrategy',
        _.isNumber(_.get(this.campaign, 'basic.bidPrice')) ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP
      );
    }
  }

  getRtbCampaignSummaryStepModel (goLast, goStep) {
    if (this.campaignSummaryModel) {
      return this.campaignSummaryModel;
    }
    this.campaignSummaryModel = new DefaultRtbCampaignSummaryStepModel(this, goLast, goStep);
    return this.campaignSummaryModel;
  }

  async initConvertsionOptions () {
    this.conversionOptions = await this.conversionManager.getConversionOptions(this.order.advertiserId);
  }

  async initGoSegments () {
    const goSegments = await this.adRequestSourceManager.getGoSegments(this.order.advertiserId, this.l1Object ? this.l1Object.channel : L1ObjectChannel.RTB);
    if (this.order.orderType === OrderType.GOJEK) {
      this.goSegments = goSegments;
    } else {
      this.goSegments = undefined;
    }
  }

  getLimitationsSummaryData = (limitations): any => {
    const limitationSummaryData = this.manager.getLimitationSummaryData(
      limitations,
      this.order.orderType,
      this.l1Object?.channel === L1ObjectChannel.RETAIL_MEDIA,
      _.get(this.localeMeta, 'property.addonProps.agencySegmentLimitationName', '')
    );
    return _.omitBy({
      include: {
        title: i18n.t<string>('campaignSummary.titles.inc'),
        titlePrefixColor: SummaryTitleColor.GREEN,
        content: limitationSummaryData.include
      },
      preferred: {
        title: i18n.t<string>('campaignSummary.titles.preferred'),
        titlePrefixColor: SummaryTitleColor.BLUE,
        content: limitationSummaryData.preferred
      },
      nonPreferred: {
        title: i18n.t<string>('campaignSummary.titles.nonPreferred'),
        titlePrefixColor: SummaryTitleColor.YELLOW,
        content: limitationSummaryData.nonPreferred
      },
      exclude: {
        title: i18n.t<string>('campaignSummary.titles.exc'),
        titlePrefixColor: SummaryTitleColor.RED,
        content: limitationSummaryData.exclude
      },
      other: limitationSummaryData.other ? {
        title: i18n.t<string>('campaignSummary.titles.other'),
        content: limitationSummaryData.other
      } : undefined
    }, _.isEmpty);
  }

  importLimitation = (appliedSavedTAInfo: SelectOptions, limitationValue: any) => {
    if (!this.limitationModel) {
      return;
    }
    this.appliedSavedTAInfo = appliedSavedTAInfo;
    this.limitationModel.updateLimitationValue(limitationValue);
  }

  onDeleteSaveTA = (deletedTAId: number) => {
    if (this.appliedSavedTAInfo && this.appliedSavedTAInfo.value === deletedTAId) {
      this.appliedSavedTAInfo = undefined;
    }
    this.refreshSavedTAList();
  }

  setCampaign = (campaign: any, rerender: boolean | undefined = true) => {
    this.campaign = campaign;
    this.createCampaignFormModels();
    rerender && this.updateState(false);
  }

  setOrder = (order: Order) => {
    this.order = { ...order };
    this.order.budgetBalance = this.l1Object ? this.l1Object.budgetBalance : this.order.budgetBalance;
  }

  abstract get campaignId ();

  abstract createCampaignFormModels ();

  get state (): RtbCampaignSetupFlowPageState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath,
      campaign: this.campaign,
      finished: this.finished,
      showTAManagement: this.showTAManagement
    };
  }

  getCamapignFormModelParams (): RtbCampaignBasicFormModelConstructorParams {
    return [
      this.defaultCampaign,
      this.campaign.basic,
      this.order,
      this.l1Object,
      this.addonFeatureManager,
      this.onPriceModelChange,
      this.otherCampaignOfL1Object,
      this.retailers
    ];
  }

  onAdTypeChange (adType: AdType) {
    const campaignBasicModel = this.getRtbCampaignBasicFormModel(adType);
    const defaultCampaign = _.cloneDeep(this.defaultCampaign);
    const objective = _.get(this.l1Object, 'objective');
    if (campaignBasicModel) {
      const optimize = campaignBasicModel.getDefaultOptimizeType(campaignBasicModel.defaultPriceModel);
      _.set(defaultCampaign, 'basic.priceModel', campaignBasicModel.defaultPriceModel);
      _.set(defaultCampaign, 'basic.optimize', optimize);
      _.set(defaultCampaign, 'basic.videoAdViewObjective', campaignBasicModel.needVideoAdViewObjective(campaignBasicModel.defaultPriceModel) ? {
        videoAdEvent: VideoAdViewObjective.DEFAULT
      } : undefined);
      if (this.manager.showFrequencyControl(objective, campaignBasicModel.campaignAdType, _.defaultTo(optimize, L2ObjectOptimizationGoal.UNSPECIFIED))) {
        _.set(defaultCampaign, 'basic.frequency', {
          maxFrequency: 5,
          intervalDays: 1
        });
      } else {
        _.set(defaultCampaign, 'basic.frequency', undefined);
      }
      const optimizeSameAsPriceModel = optimize ? this.manager.checkOptimizeSameAsPriceModel(campaignBasicModel.defaultPriceModel, optimize) : false;
      const isOutdoor = adType === AdType.OUTDOOR;
      const onlyBidCap = (!this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE) && optimizeSameAsPriceModel) || isOutdoor;
      _.set(defaultCampaign, 'basic.bidStrategy', onlyBidCap ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP);
    }
    if (this.l1Object.autoOptimise) {
      _.set(defaultCampaign, 'basic.bidStrategy', this.l1Object.rtb.bid_strategy);
    }
    const newCampaign = {
      ...defaultCampaign,
      basic: {
        ...defaultCampaign.basic,
        adType: adType
      }
    };
    this.setCampaign(newCampaign, true);
  }

  setRedirectPath (redirectPath?: string) {
    this.redirectPath = redirectPath;
    this.updateState(false);
  }

  setFinishedRedirectPath (redirectPath?: string) {
    this.redirectPath = redirectPath;
    this.finished = true;
    this.updateState(false);
  }

  getRtbCampaignBasicFormModel (adType?: AdType): RtbCampaignBasicFormModel | undefined {
    switch (adType) {
      case AdType.VIDEO:
        this.campaignBasicFormModel = this.campaignVideoFormModel;
        break;
      case AdType.DISPLAY:
        this.campaignBasicFormModel = this.campaignDisplayFormModel;
        break;
      case AdType.THIRD_PARTY_BOTTOM:
        this.campaignBasicFormModel = this.campaignThirdPartyBottomFormModel;
        break;
      case AdType.THIRD_PARTY_RECTANGLE:
        this.campaignBasicFormModel = this.campaignThirdPartyRectangleFormModel;
        break;
      case AdType.OUTDOOR:
        this.campaignBasicFormModel = this.campaignOutdoorFormModel;
        break;
      case AdType.COMBO:
        this.campaignBasicFormModel = this.campaignComboFormModel;
        break;
      case AdType.ONE_FOR_ALL_DISPLAY:
        this.campaignBasicFormModel = this.campaignOneForAllDisplayFormModel;
        break;
      case AdType.PILOT_TV:
        this.campaignBasicFormModel = this.campaignPilotTVFormModel;
        break;
      case AdType.RETAIL:
        this.campaignBasicFormModel = this.campaignRetailFormModel;
        break;
      case AdType.CTV:
        this.campaignBasicFormModel = this.campaignCtvFormModel;
        break;
      default:
        this.campaignBasicFormModel = this.campaignUnknowAdTypeFormModel;
        break;
    }
    return this.campaignBasicFormModel;
  }

  getLimitationModel (priceModel: RtbCampaignPlanType) {
    const defaultCanNotNull = this.l1Object?.channel === L1ObjectChannel.RETAIL_MEDIA ? {} : {
      include: ['adx']
    };
    const limitationPreSet = this.limitationPreSet ? this.limitationPreSet : {};
    Object.keys(defaultCanNotNull).forEach(opreate => {
      _.remove(defaultCanNotNull[opreate],
        value => !limitationPreSet[opreate] ||
          limitationPreSet[opreate].find(element => element.type === value) === undefined
      );
    });

    const limitationsCanNotNull = _.omitBy(defaultCanNotNull, _.isEmpty);
    const requiredOperateOfTaTypes = {};
    Object.keys(limitationsCanNotNull).forEach(operate => {
      const limitationCanNotNullOfOperate = limitationsCanNotNull[operate];
      limitationCanNotNullOfOperate && limitationCanNotNullOfOperate.forEach(type => {
        let requiredOperateOfTaType = defaultTo(requiredOperateOfTaTypes[type], []);
        requiredOperateOfTaType.push(operate);
        requiredOperateOfTaTypes[type] = requiredOperateOfTaType;
      });
    });
    this.limitationModel = this.getNormalLimitationModel(requiredOperateOfTaTypes);
    return this.limitationModel;
  }

  isCampaignNeedOtherLimitation (campaign) {
    return true;
  }

  getLimitationByHierarchy (orderType: OrderType, channel: L1ObjectChannel, limitationSetting: any[]): any[] {
    switch (orderType) {
      case OrderType.GOJEK:
        const gojekLimitationSetting = _.filter(limitationSetting, (inventory) => [
          DEFAULT_INVENTORY,
          'geography',
          'os',
          'device',
          'segment',
          'adx',
          'spaceType',
          'adFormat'
        ].includes(inventory.name))
        .map(inventory => {
          if (inventory.name === 'geography') {
            inventory = {
              ...inventory,
              cb: async () => {
                const countries = await this.adRequestSourceManager.getCountries();
                return _.filter(countries, country => country.value === 'IDN');
              }
            };
          }
          return inventory;
        });
        return gojekLimitationSetting;
      case OrderType.TENMAX:
        if (_.includes([
          L1ObjectChannel.RTB,
          L1ObjectChannel.RETAIL_MEDIA,
          L1ObjectChannel.EDIMAX
        ], channel)) {
          const rtbLimitationSetting = _.filter(limitationSetting, (inventory) => !['age', 'gender'].includes(inventory.name));
          if (['campaign_keyword', 'creative_keyword'].some(permission => this.addonFeatureManager.addonFeature.includes(permission))) {
            rtbLimitationSetting.push(...keywordInventorySetting);
          }
          return rtbLimitationSetting;
        }
        return limitationSetting;
      default:
        return limitationSetting;
    }
  }

  getNormalLimitationModel (requiredOperateOfTaTypes) {
    const limitationSetting = this.l1Object?.channel === L1ObjectChannel.RETAIL_MEDIA ?
      retailMediaInventorySetting(requiredOperateOfTaTypes, this.campaign.basic.advertiserId, LIMITATION_TYPE.CAMPAIGN, this.goSegments, this.localeMeta, this.campaign.basic.retailType) :
      [AdType.OUTDOOR, AdType.PILOT_TV].includes(this.campaign.basic.adType) ?
        pilotTVInventorySetting(requiredOperateOfTaTypes, LIMITATION_TYPE.CAMPAIGN) :
        defaultInventorySetting(requiredOperateOfTaTypes, this.campaign.basic.advertiserId, LIMITATION_TYPE.CAMPAIGN, this.goSegments, this.localeMeta);

    const limitationSettingByHierarchy = this.getLimitationByHierarchy(this.order.orderType, this.l1Object.channel, limitationSetting);
    if (!this.normalLimitationModel) {
      this.normalLimitationModel = new DefaultEditLimitationModel(
        limitationSettingByHierarchy,
        this.campaign.limitations,
        {
          need: [OPERATE.INCLUDE, OPERATE.PREFERRED, OPERATE.NONPREFERRED],
          notNeed: [OPERATE.EXCLUDE],
          other: this.isCampaignNeedOtherLimitation(this.campaign) ? [] : undefined
        },
        this.addonFeatureManager.addonFeature,
        _.partial(this.setShowTAManagement, true),
        {
          channel: _.get(this.l1Object, 'channel', L1ObjectChannel.RTB),
          audienceLowestThreshold: this.audienceLowestThreshold,
          channelTargetingGetter: this.getRtbTargeting,
          campaignDetailDataGetter: () => {
            if (!this.campaignBasicFormModel) {
              return;
            }
            return {
              campaign: this.campaignBasicFormModel.formikValue,
              order: this.order
            };
          }
        }
      );
    }
    this.normalLimitationModel.setLimitationSetting(limitationSettingByHierarchy);
    return this.normalLimitationModel;
  }

  getRtbTargeting = limitationValue => {
    const includeLimitation = defaultTo(limitationValue.include, []);
    const preferredLimitation = defaultTo(limitationValue.preferred, []);
    const includeAgeMin = includeLimitation.find(limitation => limitation.type === 'age_min');
    const ageMinLimitation = includeAgeMin || preferredLimitation.find(limitation => limitation.type === 'age_min');
    const ageMin = get(ageMinLimitation, 'value', 18);
    const includeAgeMax = includeLimitation.find(limitation => limitation.type === 'age_max');
    const ageMaxLimitation = includeAgeMax || preferredLimitation.find(limitation => limitation.type === 'age_max');
    const ageMax = get(ageMaxLimitation, 'value', 61);
    const ageOptValue = includeAgeMin ? 'include' : 'preferred';
    const ages = this.order.orderType === OrderType.TENMAX
     ? getPmax2RtbAgeGroupsByAgeRange(ageMin, ageMax)
     : getPmax3RtbAgeGroupsByAgeRange(ageMin, ageMax);
    const otherLimitations = toServerStructure(limitationValue)
      .filter(limitation => (
          limitation.op === 'inc' && ['gender', 'segment'].includes(limitation.type)
        ) || (
          limitation.op === 'Preferred' && limitation.type === 'gender'
        ) || (limitation.op === 'exc' && limitation.type === 'segment'));
    otherLimitations.push({
      op: ageOptValue === 'include' ? 'inc' : 'Preferred',
      limits: ages,
      isGroup: false,
      type: 'age'
    });
    return otherLimitations;
  }

  onPriceModelChange = (priceModel, currentCampaignBasic) => {
    if (!this.campaignBasicFormModel) {
      return;
    }
    const limitationModel = this.getLimitationModel(priceModel);
    const needVideoAdViewObjective = this.campaignBasicFormModel.needVideoAdViewObjective(priceModel);
    const optimize = this.campaignBasicFormModel.getDefaultOptimizeType(priceModel);
    const needFrequency = optimize === L2ObjectOptimizationGoal.REACH && this.l1Object.objective === L1ObjectObjective.AWARENESS;
    const optimizeSameAsPriceModel = optimize ? this.manager.checkOptimizeSameAsPriceModel(priceModel, optimize) : false;
    const isOutdoor = this.campaignBasicFormModel.campaignAdType === AdType.OUTDOOR;
    const onlyBidCap = (!this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE) && optimizeSameAsPriceModel) || isOutdoor;
    this.setCampaign({
      basic: {
        ...currentCampaignBasic,
        priceModel,
        optimize,
        orderPrice: undefined,
        bidPrice: undefined,
        bidStrategy: this.l1Object.autoOptimise ?
          this.l1Object.rtb.bid_strategy :
          onlyBidCap ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP,
        frequency: needFrequency ? {
          maxFrequency: 5,
          intervalDays: 1
        } : undefined,
        videoAdViewObjective: needVideoAdViewObjective ?
          currentCampaignBasic.videoAdViewObjective ?
            currentCampaignBasic.videoAdViewObjective : {
              videoAdEvent: VideoAdViewObjective.DEFAULT
            } : undefined
      },
      limitations: limitationModel.limitationValue
    });
  }

  cancel = () => {
    const redirectPath = this.l1Object ?
      `/orders/${this.order.orderNumber}/campaign-groups/${this.l1Object.l1ObjectId}` :
      `/orders/${this.order.orderNumber}`;
    this.setRedirectPath(redirectPath);
  }

  validateCampaign () {
    return (this.l1Object && this.campaign.basic.goGanGroupId !== _.get(this.l1Object, 'rtb.group_id')) || this.campaign.basic.state === CampaignState.DELETE;
  }

  onUnmount (handler) {
    this.event.remove(handler);
    this.redirectPath = undefined;
    this.campaign = undefined;
    this.rbLimitationModel = undefined;
    this.normalLimitationModel = undefined;
    this.campaignSummaryModel = undefined;
    this.finished = false;
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

abstract class CreateFlowPageModel extends DefaultRtbCampaignSetupFlowPageModel {

  createCampaignFormModels () {
    const params = this.getCamapignFormModelParams();
    this.campaignOneForAllDisplayFormModel = new CreateOneForAllDisplayFormModel(...params);
    this.campaignPilotTVFormModel = new CreatePilotTVFormModel(...params);
    this.campaignDisplayFormModel = new CreateDisplayFormModel(...params);
    this.campaignVideoFormModel = new CreateVideoFormModel(...params);
    this.campaignThirdPartyBottomFormModel = new CreateThirdPartyBottomFormModel(...params);
    this.campaignThirdPartyRectangleFormModel = new CreateThirdPartyRectangleFormModel(...params);
    this.campaignOutdoorFormModel = new CreateOutdoorFormModel(...params);
    this.campaignComboFormModel = new CreateComboFormModel(...params);
    this.campaignRetailFormModel = new CreateRetailFormModel(...params);
    this.campaignUnknowAdTypeFormModel = new CreateUnknowAdTypeFormModel(...params);
    this.campaignCtvFormModel = new CreateCtvFormModel(...params);
  }
}

abstract class CreateFlowFromExistOnePageModel extends CreateFlowPageModel {

  constructor (
    protected modalCampaignId: number,
    ...args: ConstructorParameters<typeof DefaultRtbCampaignSetupFlowPageModel>
  ) {
    super(...args);
    this.modalCampaignId = modalCampaignId;
  }
}

abstract class EditFlowPageModel extends DefaultRtbCampaignSetupFlowPageModel {

  constructor (
    protected modalCampaignId: number,
    ...args: ConstructorParameters<typeof DefaultRtbCampaignSetupFlowPageModel>
  ) {
    super(...args);
    this.modalCampaignId = modalCampaignId;
  }

  createCampaignFormModels () {
    const params = this.getCamapignFormModelParams();
    this.campaignOneForAllDisplayFormModel = new EditOneForAllDisplayFormModel(...params);
    this.campaignPilotTVFormModel = new EditPilotTVFormModel(...params);
    this.campaignDisplayFormModel = new EditDisplayFormModel(...params);
    this.campaignVideoFormModel = new EditVideoFormModel(...params);
    this.campaignThirdPartyBottomFormModel = new EditThirdPartyBottomFormModel(...params);
    this.campaignThirdPartyRectangleFormModel = new EditThirdPartyRectangleFormModel(...params);
    this.campaignOutdoorFormModel = new EditOutdoorFormModel(...params);
    this.campaignComboFormModel = new EditComboFormModel(...params);
    this.campaignRetailFormModel = new EditRetailFormModel(...params);
    this.campaignUnknowAdTypeFormModel = new EditUnknowAdTypeFormModel(...params);
    this.campaignCtvFormModel = new EditCtvFormModel(...params);
  }
}

export class CreateRtbCampaignSetupFlowPageModel extends CreateFlowPageModel {

  get type () {
    return 'create';
  }

  get campaignId () {
    return null;
  }

  async init () {
    this.updateState(true);
    try {
      await this.initRetailOptions();
      await this.initSavedTAList();
      this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
      if (this.l1Object?.channel === L1ObjectChannel.RETAIL_MEDIA) {
        Object.keys(this.limitationPreSet).forEach(key => {
          const limitationPreset = this.limitationPreSet[key];
          _.remove(limitationPreset, (preset: any) => preset.type === 'adx');
        });
      }
      this.campaign = await this.createDefaultCampaign();
      this.initBidStrategy();
      this.defaultCampaign = _.cloneDeep(this.campaign);
      await this.initConvertsionOptions();
      await this.initGoSegments();
      this.createCampaignFormModels();
    } catch (e) {}
    this.updateState(false);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.createCampaignTitle');
  }

  async createDefaultCampaign () {
    const orderType: OrderType = this.order.orderType;
    const orderAgeMin: number = (typeof this.order.ageMin === 'string')
     ? defaultTo(_.parseInt(this.order.ageMin), RTBCAMPAIGN_DEFAULT_AGE_MIN)
     : defaultTo(this.order.ageMin, RTBCAMPAIGN_DEFAULT_AGE_MIN);
    const orderAgeMax: number = (typeof this.order.ageMax === 'string')
     ? defaultTo(_.parseInt(this.order.ageMax), RTBCAMPAIGN_DEFAULT_AGE_MAX)
     : defaultTo(this.order.ageMax, RTBCAMPAIGN_DEFAULT_AGE_MAX);
    const orderGender: FinalReportGender = defaultTo(this.order.gender, FinalReportGender.ALL);
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const ageLimitationPresets = [{
      type: 'age_min',
      value: orderAgeMin
    }, {
      type: 'age_max',
      value: orderAgeMax
    }];
    const genderLimitationPresets = orderGender !== FinalReportGender.ALL ? [{
      type: 'gender',
      value: [genderOptions.find(option => option.value === orderGender)]
    }] : [];
    const preferredLimitationPresets = defaultTo(this.limitationPreSet.preferred, []);
    const includeLimitationPresets = defaultTo(this.limitationPreSet.include, []);
    let limitationPreSet = orderType === OrderType.TENMAX ? {
      ...this.limitationPreSet,
      preferred: [
        ...preferredLimitationPresets,
        ...ageLimitationPresets,
        ...genderLimitationPresets
      ]
    } : {
      ...this.limitationPreSet,
      include: [
        ...includeLimitationPresets,
        ...ageLimitationPresets,
        ...genderLimitationPresets
      ]
    };
    const startDate = this.getCampaignStartDay();
    const endDate = this.getCampaignEndDay();
    const scheduleDateCount = endDate.diff(startDate, 'days') + 1;
    const minBudgetOfCampaign = this.order.campaignConstraint.budgetMinimum * scheduleDateCount;
    const parentBidStrategy = _.get(this.l1Object, 'rtb.bid_strategy');
    const defaultRetailer = get(this.retailers, '0');
    const autoOptimise = get(this.l1Object, 'autoOptimise', false);
    const campaign = {
      basic: {
        adLogo: this.order.adLogo,
        advertiserId: this.order.advertiserId,
        budget: autoOptimise ?
          minBudgetOfCampaign :
          this.order.budgetBalance,
        checkpoints: [],
        creativeDeliverType: CreativeDeliverType.OPTIMIZE,
        dailyTargetBudget: null,
        tags: [],
        name: this.l1Object ? this.l1Object.name : this.order.projectName,
        orderId: this.order.id,
        startDate: startDate.format(dateFormat),
        endDate: endDate.format(dateFormat),
        priceModel: RtbCampaignPlanType.RS,
        optimize: L2ObjectOptimizationGoal.CLICKS,
        enableMonitor: true,
        deliverType: DeliverType.STANDARD,
        convTrackEvent: 'click',
        expectedSpent: 0,
        orderPriceEnable: true,
        bidPrice: parentBidStrategy && parentBidStrategy === BidStrategy.LOWEST_COST_WITH_BID_CAP ? 0 : undefined,
        retailType: this.l1Object?.channel === L1ObjectChannel.RETAIL_MEDIA && defaultRetailer ? defaultRetailer.id : undefined
      },
      limitations: _.cloneDeep(limitationPreSet)
    };

    return campaign;
  }

  getCampaignStartDay () {
    const now = moment()
      .startOf('hour');
    const orderStartDate = moment(this.order.startDate);
    return now.isBefore(orderStartDate) ? orderStartDate : now;
  }

  getCampaignEndDay () {
    return moment.min(
      moment(this.order.endDate)
        .add(1, 'days')
        .subtract(1, 'seconds'),
      this.getCampaignStartDay().add(91, 'days').endOf('day')
    );
  }
}

export class EditRtbCampaignSetupFlowPageModel extends EditFlowPageModel {

  get type () {
    return 'edit';
  }

  get campaignId () {
    return this.modalCampaignId;
  }

  async getCampaign () {
    return this.manager.getCampaign(this.modalCampaignId);
  }

  async init () {
    this.updateState(true);
    try {
      await this.initRetailOptions();
      await this.initSavedTAList();
      this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
      this.campaign = await this.getCampaign();
      this.initBidStrategy();
      this.defaultCampaign = _.cloneDeep(this.campaign);
      await this.initConvertsionOptions();
      await this.initGoSegments();
      this.createCampaignFormModels();
      if (this.validateCampaign()) {
        this.to404();
        return;
      }
    } catch (e) {}
    this.updateState(false);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.editCampaignTitle');
  }
}

export class CopyRtbCampaignSetupFlowPageModel extends CreateFlowFromExistOnePageModel {

  get type () {
    return 'copy';
  }

  get campaignId () {
    return this.modalCampaignId;
  }

  async init () {
    this.updateState(true);
    try {
      await this.initRetailOptions();
      await this.initSavedTAList();
      this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
      this.campaign = await this.manager.getNoCidCampaign(this.campaignId);
      _.set(this.campaign, 'basic.orderPriceEnable', true);
      this.initBidStrategy();
      this.campaign.basic.spents = 0;
      this.campaign.basic.expectedSpent = 0;
      const campaignIsStart = moment().isAfter(this.campaign.basic.startDate);
      const campaignIsEnd = moment().isAfter(this.campaign.basic.endDate);
      if (campaignIsStart) {
        this.campaign.basic.startDate = moment().startOf('hours').format('YYYY-MM-DD HH:mm:ss');
      }
      if (campaignIsEnd) {
        this.campaign.basic.endDate = moment(this.order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      if (this.l1Object && this.l1Object.autoOptimise) {
        this.campaign.basic.budget = this.manager.getMinBudgetOfCampaign(this.campaign.basic, this.order.campaignConstraint.budgetMinimum);
      }
      this.defaultCampaign = _.cloneDeep(this.campaign);
      await this.initConvertsionOptions();
      await this.initGoSegments();
      this.createCampaignFormModels();
      if (this.validateCampaign()) {
        this.to404();
        return;
      }
    } catch (e) {}
    this.updateState(false);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.copyCampaignTitle');
  }
}

export class SplitRtbCampaignSetupFlowPageModel extends CreateFlowFromExistOnePageModel {

  get type () {
    return 'split';
  }

  get campaignId (): number | null {
    return this.modalCampaignId;
  }

  async init () {
    this.updateState(true);
    try {
      await this.initRetailOptions();
      await this.initSavedTAList();
      this.limitationPreSet = await this.limitationManager.getLimitationPreSet('campaign');
      this.campaign = await this.manager.getNoCidCampaign(this.modalCampaignId);
      _.set(this.campaign, 'basic.orderPriceEnable', true);
      this.initBidStrategy();
      this.campaign.basic.expectedSpent = 0;
      this.campaign.basic.spents = 0;
      this.campaign.basic.state = CampaignState.ACTIVATE;
      const campaignIsStart = moment().isAfter(this.campaign.basic.startDate);
      const campaignIsEnd = moment().isAfter(this.campaign.basic.endDate);
      if (campaignIsStart) {
        this.campaign.basic.startDate = moment().startOf('hours').format('YYYY-MM-DD HH:mm:ss');
      }
      if (campaignIsEnd) {
        this.campaign.basic.endDate = moment(this.order.endDate).endOf('day').format('YYYY-MM-DD HH:mm:ss');
      }
      const oldCampaignSpent = this.campaign.basic.state !== CampaignState.DEACTIVATE ? this.campaign.basic.expectedSpent : this.campaign.basic.spents;
      const minBudgetOfCampaign = this.manager.getMinBudgetOfCampaign(this.campaign.basic, this.order.campaignConstraint.budgetMinimum);
      const budgetMinimum = this.campaign.basic.state !== CampaignState.DEACTIVATE ? minBudgetOfCampaign : 0;
      let oldCampaignMin = oldCampaignSpent > budgetMinimum ? oldCampaignSpent : budgetMinimum;
      if (this.campaign.basic.state === CampaignState.DEACTIVATE) {
        oldCampaignMin = this.campaign.basic.spents;
      }
      this.order.budgetBalance = oldCampaignSpent > this.campaign.basic.budget ? 0 : getPriceValue(this.order.currency, this.campaign.basic.budget - oldCampaignMin);
      this.campaign.basic.budget = this.l1Object && this.l1Object.autoOptimise ? minBudgetOfCampaign : this.order.budgetBalance;
      this.defaultCampaign = _.cloneDeep(this.campaign);
      await this.initConvertsionOptions();
      await this.initGoSegments();
      this.createCampaignFormModels();
      if (this.validateCampaign()) {
        this.to404();
        return;
      }
    } catch (e) {}
    this.updateState(false);
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.splitCampaignTitle');
  }
}

export class EditRtbCampaignDraftSetupFlowPageModel extends CreateRtbCampaignSetupFlowPageModel {

  constructor (
    private draftId: string,
    order: Order,
    addonFeatureManager: AddonFeatureManager,
    l1Object: L1Object,
    localeMeta?: LocaleMeta,
    otherCampaignOfL1Object?: RtbCampaignBasic[],
    private draftManager: DraftManager = new RtbCampaignDraftManager()
  ) {
    super(
      order,
      addonFeatureManager,
      l1Object,
      localeMeta,
      otherCampaignOfL1Object
    );
    this.objectType = 'draft';
  }

  get type () {
    return 'edit';
  }

  getTitle () {
    return i18n.t<string>('campaign.labels.editCampaignDraftTitle');
  }

  async createDefaultCampaign () {
    return this.draftManager.getDraft(this.draftId);
  }

  getRtbCampaignSummaryStepModel (goLast, goStep) {
    if (this.campaignSummaryModel) {
      return this.campaignSummaryModel;
    }
    this.campaignSummaryModel = new RtbCampaignDraftSummaryStepModel(this.draftId, this, goLast, goStep, this.draftManager);
    return this.campaignSummaryModel;
  }

  onSaveDraft = async (campaign: RtbCampaign) => {
    this.updateState(true);
    try {
      await this.draftManager.updateDraft(this.draftId, this.manager.prepareCreateCampaignPayload(campaign));
      toast.success(i18n.t<string>('campaignSetupFlow.messages.updateDraftSuccess'));
    } catch (e) {
      toast.error(i18n.t<string>('campaignSetupFlow.messages.updateDraftFailed'));
    }
    this.updateState(false);
  }
}
