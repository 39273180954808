import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { SubSegmentFormDTO, SegmentRuleContent } from 'core/segment/Segment';
import { SegmentManager, DefaultSegmentManager } from 'core/segment/SegmentManager';
import i18n from 'i18next';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { validateEmpty } from 'utils/ValidateUtils';

export type SubSegmentFormState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly subSegment?: SubSegmentFormDTO;
  readonly finished: boolean;
};

export interface SubSegmentFormModel {
  readonly state: SubSegmentFormState;
  readonly event: UpdateEventListener<SubSegmentFormModel>;
  readonly title: string;
  validate (subSegment: SubSegmentFormDTO): any;
  submit (subSegment: SubSegmentFormDTO): void;
  onUnmount (eventHandler): void;
  cancel (): void;
  postSubmit (): void;
  setRedirectPath (redirectPath?: string): void;
}

export type SubSegmentFormProps = {
  readonly model: SubSegmentFormModel;
};

abstract class DefaultSubSegmentFormModel implements SubSegmentFormModel {
  event: FireableUpdateEventListener<SubSegmentFormModel>;
  loading: boolean;
  parentId: number;
  subSegment: SubSegmentFormDTO;
  manager: SegmentManager;
  redirectPath?: string;
  postSubmit: () => void;
  finished: boolean;

  constructor (
    parentId: number,
    postSubmit: () => void,
    manager: SegmentManager = new DefaultSegmentManager()
  ) {
    this.parentId = parentId;
    this.event = new FireableUpdateEventListener<SubSegmentFormModel>();
    this.loading = false;
    this.manager = manager;
    this.subSegment = this.getSubSegment();
    this.postSubmit = () => {
      this.finished = true;
      this.updateState(false, `/audience-segments/${this.parentId}`);
      postSubmit();
    };
    this.finished = false;
  }

  abstract get title ();

  abstract getSubSegment (): SubSegmentFormDTO;

  abstract submit (segment: SubSegmentFormDTO): void;

  setRedirectPath (redirectPath?: string): void {
    this.updateState(false, redirectPath);
  }

  validateRuleContents (ruleContents: Array<SegmentRuleContent>) {
    if (ruleContents.length === 0) {
      return i18n.t<string>('subSegmentForm.labels.ruleContentEmptyError');
    }
    const validRule = ruleContents.find(ruleContent => !_.isEmpty(ruleContent.pattern));
    if (!validRule) {
      return i18n.t<string>('subSegmentForm.labels.ruleContentEmptyError');
    }
  }

  validate = (segment: SubSegmentFormDTO) => {
    return _.omitBy(
      {
        name: validateEmpty(_.get(segment, 'name')),
        ruleContents: this.validateRuleContents(_.get(segment, 'ruleContents', []))
      }, (value) => value === undefined);
  }

  cancel = () => {
    this.updateState(false, `/audience-segments/${this.parentId}`);
  }

  onUnmount = (eventHandler) => {
    this.event.remove(eventHandler);
    this.redirectPath = undefined;
    this.finished = false;
  }

  get state (): SubSegmentFormState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath,
      subSegment: this.subSegment,
      finished: this.finished
    };
  }

  updateState (loading: boolean, redirectPath?: string) {
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this);
  }
}

export class CreateSubSegmentFormModel extends DefaultSubSegmentFormModel {

  get title () {
    return i18n.t<string>('subSegmentForm.labels.createTitle');
  }

  getSubSegment (): SubSegmentFormDTO {
    return {
      name: '',
      ruleContents: []
    };
  }

  async submit (newSegment: SubSegmentFormDTO) {
    this.updateState(true);
    try {
      await this.manager.createSubSegment(this.parentId, newSegment);
      this.postSubmit();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }
}

export class EditSubSegmentFormModel extends DefaultSubSegmentFormModel {

  subSegment: SubSegmentFormDTO;

  constructor (
    parentId: number,
    subSegment: SubSegmentFormDTO,
    postSubmit: () => void,
    manager: SegmentManager = new DefaultSegmentManager()
  ) {
    super(parentId, postSubmit, manager);
    this.subSegment = subSegment;
  }

  get title () {
    return i18n.t<string>('subSegmentForm.labels.editTitle');
  }

  getSubSegment (): SubSegmentFormDTO {
    return this.subSegment;
  }

  async submit (editedSegment: SubSegmentFormDTO) {
    this.updateState(true);
    try {
      await this.manager.updateSubSegment(this.parentId, editedSegment);
      this.postSubmit();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }
}
