import React from 'react';
import { FormikField } from 'components/common/form/field/FormikField';
import { Form as BootstrapForm } from 'react-bootstrap';
import { L1Object, L1ObjectObjective } from 'core/l1Object/L1Object';
import { FormikProps } from 'formik';
import { FbAdSetFormData } from '../FbAdSetSetupFlowPageModel';
import { Order } from 'core/order/Order';
import { FbAdSetFormModelData } from './FbAdSetFormModel';
import _ from 'lodash';
import i18n from 'i18n';
import { Dayparts, getInitDaypart } from 'components/Dayparts/Dayparts';
import cx from 'classnames/bind';
import styles from './fbAdSetMainStep.module.scss';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { FbAppEvent, FbPacingType } from 'core/fbAdSet/FbAdSet';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { getFieldErrors } from 'utils/FormikUtils';
import { renderAdSetTotalDaysDes } from './fbAdSetMainStepRenderFunctions';
import moment from 'moment';
import { LabelField } from 'components/common/form/field/LabelField';
import { CustomField } from 'components/common/form/field/CustomField';

const classNames = cx.bind(styles);

export type FbAdSetFormProps = {
  order: Order;
  l1Object: L1Object;
  adSet: FbAdSetFormData;
  canEditOptimizationGoal: boolean;
  handleOptimizationGoalChanged: (newAdSet) => void;
  useModel: (
    order: Order,
    l1Object: L1Object,
    adSet: FbAdSetFormData
  ) => FbAdSetFormModelData
};

export const getAdSetTotalDaysDes = (startTime, endTime) => {
  const startDateMoment = moment(startTime);
  const endDateMoment = moment(endTime);
  const days = endDateMoment.diff(startDateMoment, 'days') + 1;
  const hours = endDateMoment.diff(startDateMoment, 'hours') + 1;

  return renderAdSetTotalDaysDes(days, hours);
};

const renderDayParts = (props) => <Dayparts {...props} />;

export const FbAdSetForm: React.FC<FormikProps<FbAdSetFormData> & FbAdSetFormProps> = ({
  values,
  errors,
  touched,
  submitCount,
  order,
  l1Object,
  adSet,
  canEditOptimizationGoal,
  useModel,
  setFieldValue,
  setFieldTouched,
  handleOptimizationGoalChanged
}) => {

  const {
    formType,
    currency,
    dateRangeProps,
    optimizationGoalOptions,
    pacingTypeOptions,
    billingEventOptions,
    canEditBidStrategy,
    canEditBidCap,
    appEventOptions,
    onOptimizationGoalChanged
  } = useModel(order, l1Object, adSet);

  const onBidAmountChanged = () => {
    setFieldTouched('lifetime_budget');
  };
  const onDaypartSwitchChanged = (dayPartEnable: boolean) => {
    if (!dayPartEnable) {
      l1Object.autoOptimise
        ? setFieldValue('dayPart', getInitDaypart())
        : setFieldValue('dayPart', undefined);
    } else {
      setFieldValue('dayPart', {
        ..._.get(adSet, 'dayPart'),
        enabled: '1'
      });
      setFieldValue('pacing_type', FbPacingType.STANDARD);
    }
  };

  const afterOptimizationGoalChanged = (newOptimizationGoal, newDefaultBillingEvent) => {
    let frequencyControlspecs;
    if (_.get(l1Object, 'fb.objective') === L1ObjectObjective.AWARENESS && newOptimizationGoal === L2ObjectOptimizationGoal.REACH) {
      frequencyControlspecs = {
        event: 'IMPRESSIONS',
        interval_days: 7,
        max_frequency: 1
      };
    } else {
      frequencyControlspecs = undefined;
    }
    handleOptimizationGoalChanged({
      ...values,
      optimization_goal: newOptimizationGoal,
      promoted_object: newOptimizationGoal === L2ObjectOptimizationGoal.SALES ? {
        custom_event_type: FbAppEvent.PURCHASE
      } : undefined,
      frequency_control_specs: frequencyControlspecs,
      billing_event: newDefaultBillingEvent
    });
  };

  const campaignBidStrategy = _.get(l1Object, 'fb.bid_strategy');
  const initBudget = +(_.get(adSet, 'lifetime_budget', 0).toString());
  const budget = +(_.get(values, 'lifetime_budget', 0).toString());
  const budgetIsInvalid = getFieldErrors({
    errors,
    touched,
    submitCount
  }, 'lifetime_budget');
  const dayPartEnable = _.get(values, 'dayPart.enabled', 0);
  const optimizationGoalLabelFormatter = goal => i18n.t<string>(`optimizationGoal.${goal.toLowerCase()}`);
  const billingEventFormatter = billingEvent => i18n.t<string>(`adSet.billingEvent.${billingEvent.toLowerCase()}`);
  const totalBudget = _.get(adSet, 'isDraft', false) ?
    l1Object.budgetBalance :
    l1Object.budgetBalance + initBudget;
  const renderBidControl = (formikProps) => {
    const value = formikProps.values[formikProps.name];
    const onBidStrategyChange = (e) => {
      formikProps.setValues(prev => ({
        ...prev,
        [formikProps.name]: e.target.value,
        bid_amount: '',
        pacing_type: FbPacingType.STANDARD
      }));
    };
    const bidAmountFormatter = value => formatPriceWithCurrency(currency, +value);
    return (
      <div className={styles.bidControl}>
        <BootstrapForm.Check
          type={'radio'}
          label={i18n.t<string>('l1Object.labels.bidStrategy.lowest_cost_without_cap')}
          value={BidStrategy.LOWEST_COST_WITHOUT_CAP}
          name={'bidStrategy'}
          id={'autoBid'}
          onChange={onBidStrategyChange}
          checked={value === BidStrategy.LOWEST_COST_WITHOUT_CAP}
          disabled={!canEditBidStrategy}
        />
        <BootstrapForm.Check
          type={'radio'}
          label={i18n.t<string>('l1Object.labels.bidStrategy.lowest_cost_with_bid_cap')}
          value={BidStrategy.LOWEST_COST_WITH_BID_CAP}
          name={'bidStrategy'}
          id={'bidCap'}
          onChange={onBidStrategyChange}
          checked={value === BidStrategy.LOWEST_COST_WITH_BID_CAP}
          disabled={!canEditBidStrategy}
        />
        {formType === 'create' && !canEditBidStrategy && (
          <span className={styles.bidStrategyHint}>
            {i18n.t<string>('adSetSetupFlow.mainStep.bidStrategyFixHint')}
          </span>
        )}
        {((campaignBidStrategy && campaignBidStrategy === BidStrategy.LOWEST_COST_WITH_BID_CAP) ||
          value === BidStrategy.LOWEST_COST_WITH_BID_CAP) &&
          <>
            {
              canEditBidCap ?
              <FormikField.InputGroup
                name='bid_amount'
                prefix={currency}
                type='number'
                min={0}
                className={styles.bidAmount}
                onChange={onBidAmountChanged}
              /> :
              <FormikField.Label
                name='bid_amount'
                formatter={bidAmountFormatter}
              />
            }
          </>
        }
      </div>
    );
  };
  const renderMinSpentLimitInput = () => {
    return (
      <>
        <div className={styles.spentLimitLabel}>{i18n.t<string>('adSetSetupFlow.mainStep.field.lifetimeMinSpendTarget')}</div>
        <FormikField.InputGroup
          name='lifetime_min_spend_target'
          prefix={currency}
          type='number'
          min={0}
          hint={i18n.t<string>('adSetSetupFlow.mainStep.hints.lifetimeMinSpendTarget')}
        />
      </>
    );
  };
  const renderMaxSpentLimitInput = () => {
    return (
      <>
        <div className={styles.spentLimitLabel}>{i18n.t<string>('adSetSetupFlow.mainStep.field.lifetimeSpendCap')}</div>
        <FormikField.InputGroup
          name='lifetime_spend_cap'
          prefix={currency}
          type='number'
          min={0}
          hint={i18n.t<string>('adSetSetupFlow.mainStep.hints.lifetimeSpendCap')}
        />
      </>
    );
  };
  const onHasSpendLimitsChange = () => {
    setFieldValue('lifetime_min_spend_target', '');
    setFieldValue('lifetime_spend_cap', '');
  };
  const renderFrequencyControlInput = () => {
    const onMaxFrequencyChange = e => {
      setFieldValue('frequency_control_specs.max_frequency', e.target.value < 0 ? 0 : e.target.value);
    };
    const onIntervalDaysChange = e => {
      setFieldValue('frequency_control_specs.interval_days', e.target.value < 0 ? 0 : e.target.value);
    };
    const maxFrequency = _.get(values, 'frequency_control_specs.max_frequency');
    const intervalDays = _.get(values, 'frequency_control_specs.interval_days');
    const errorMaxFrequency = _.get(errors, 'frequency_control_specs.max_frequency');
    const errorintervalDays = _.get(errors, 'frequency_control_specs.interval_days');
    const maxFrequencyClass = classNames('maxFrequency', {
      error: errorMaxFrequency
    });
    const intervalDaysClass = classNames('intervalDays', {
      error: errorintervalDays
    });
    return (
      <div className={styles.frequencyControl}>
        <div>
          <input
            className={maxFrequencyClass}
            value={maxFrequency}
            name='frequency_control_specs.max_frequency'
            onChange={onMaxFrequencyChange}
            min={1}
            max={20}
            type='number'
          />
          {i18n.t<string>('adSetSetupFlow.mainStep.labels.frequencyCap.event', {
            event: i18n.t<string>('adSetSetupFlow.mainStep.labels.impression'),
            plural: +(maxFrequency) > 1 ? 's' : ''
          })}
          <input
            className={intervalDaysClass}
            name='frequency_control_specs.interval_days'
            value={intervalDays}
            onChange={onIntervalDaysChange}
            min={1}
            max={7}
            type='number'
          />
          {i18n.t<string>('adSetSetupFlow.mainStep.labels.frequencyCap.unit', {
            unit: i18n.t<string>('common.units.day'),
            plural: +(intervalDays) > 1 ? 's' : ''
          })}
        </div>
        {errorMaxFrequency && <div className='errorTip'>{errorMaxFrequency}</div>}
        {errorintervalDays && <div className='errorTip'>{errorintervalDays}</div>}
      </div>
    );
  };
  const renderFrequencyControlLabel = () => {
    const frequencyControl = _.get(values, 'frequency_control_specs');
    return i18n.t<string>('adSetSetupFlow.mainStep.labels.frequencyControl', {
      event: i18n.t<string>('adSetSetupFlow.mainStep.labels.impression'),
      eventPlural: +(frequencyControl.max_frequency) > 1 ? 's' : '',
      interval_days: frequencyControl.interval_days,
      max_frequency: frequencyControl.max_frequency,
      unit: i18n.t<string>('common.units.day'),
      unitPlural: +(frequencyControl.interval_days) > 1 ? 's' : ''
    });
  };
  const CBOBudgetFormatter = () => {
    return i18n.t<string>('adSetSetupFlow.mainStep.CBOAdSetBudgetHint');
  };
  const appEventLabelFormatter = value => _.startCase(_.lowerCase(value));
  return (
    <div>
      <fieldset>
        <legend>
          <span>{i18n.t<string>('adSetSetupFlow.mainStep.fieldset.basicTitle')}</span>
        </legend>
        <div className={styles.component}>
          <LabelField
            className={classNames('label', ['col-form-label'])}
            label={i18n.t<string>('adSetSetupFlow.mainStep.field.fbCampaignName')}
            value={l1Object.name}
          />
          <FormikField.Input
            label={i18n.t<string>('adSetSetupFlow.mainStep.field.name')}
            name='name'
          />
          {l1Object.autoOptimise &&
            <FormikField.Label
              label={i18n.t<string>('adSetSetupFlow.mainStep.field.lifetimeBudget')}
              name='lifetime_budget'
              formatter={CBOBudgetFormatter}
            />
          }
          {!l1Object.autoOptimise &&
            <FormikField.InputGroup
              label={i18n.t<string>('adSetSetupFlow.mainStep.field.lifetimeBudget')}
              name='lifetime_budget'
              prefix={currency}
              type='number'
              min={0}
              hint={budgetIsInvalid ? '' : `${i18n.t<string>('l1Object.labels.budgetBalance')} : ${totalBudget - budget}`}
            />
          }
          {!l1Object.autoOptimise &&
            values.bid_strategy !== BidStrategy.LOWEST_COST_WITHOUT_CAP &&
            !dayPartEnable &&
            <FormikField.ToggleButton
              label={i18n.t<string>('campaign.labels.deliverType')}
              name='pacing_type'
              options={pacingTypeOptions}
            />
          }
          <FormikField.DateRangePicker
            label={i18n.t<string>('adSetSetupFlow.mainStep.field.dateRange')}
            className={styles.orderInput}
            minDate={dateRangeProps.minDate}
            maxDate={dateRangeProps.maxDate}
            startDateFormikName='start_time'
            endDateFormikName='end_time'
            name='dateRange'
            format={'YYYY-MM-DD HH:mm:ss'}
            timeInterval={60}
            startDatePickerDisabled={dateRangeProps.isAdSetStart}
            fieldContentWidth='auto'
            hint={getAdSetTotalDaysDes(values.start_time, values.end_time)}
            hoverHint={i18n.t<string>('campaignInfo.labels.dateCannotModifyHint')}
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18n.t<string>('adSetSetupFlow.mainStep.fieldset.optimizationTitle')}</span>
        </legend>
        <div className={styles.component}>
          {canEditOptimizationGoal ?
            <FormikField.Select
              label={i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal')}
              name='optimization_goal'
              simpleValue
              options={optimizationGoalOptions}
              onChange={_.partial(onOptimizationGoalChanged, afterOptimizationGoalChanged)}
              hint={formType === 'create' && l1Object.autoOptimise ?
              <div style={{ maxWidth: 500 }}>
                {i18n.t<string>('adSetSetupFlow.mainStep.firstCBOAdSetOptimizationGoalHint')}
              </div> : ''}
            /> :
            <FormikField.Label
              label={i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal')}
              name='optimization_goal'
              formatter={optimizationGoalLabelFormatter}
              hint={formType === 'create' && l1Object.autoOptimise ?
              <div style={{ maxWidth: 500 }}>
                {i18n.t<string>('adSetSetupFlow.mainStep.optimizationGoalFixHint')}
              </div> : ''}
            />
          }
          {
            appEventOptions && (
              formType === 'create' ?
                <FormikField.Select
                  label={i18n.t<string>('adSetSetupFlow.mainStep.field.promoted_object_custom_event_type')}
                  name='promoted_object.custom_event_type'
                  simpleValue
                  options={appEventOptions}
                /> :
                <FormikField.Label
                  label={i18n.t<string>('adSetSetupFlow.mainStep.field.promoted_object_custom_event_type')}
                  name='promoted_object.custom_event_type'
                  formatter={appEventLabelFormatter}
                />
            )
          }
          <FormikField.Custom
            label={i18n.t<string>('adSetSetupFlow.mainStep.field.bidControl')}
            name='bid_strategy'
            render={renderBidControl}
            fieldContentWidth={500}
            hint={
              <div style={{ maxWidth: 500 }}>
                {`${i18n.t<string>(`adSetSetupFlow.mainStep.hint.${values.bid_strategy.toLowerCase()}`, {
                  optimization: i18n.t<string>(`optimizationGoal.${values.optimization_goal?.toLowerCase()}`).toLowerCase(),
                  bidControl: formatPriceWithCurrency(order.currency, values.bid_amount ? +(values.bid_amount) : 0)
                })}`}
              </div>
            }
          />
          {formType === 'create' ?
            <FormikField.Select
              label={i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent')}
              name='billing_event'
              simpleValue
              options={billingEventOptions}
            /> :
            <FormikField.Label
              label={i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent')}
              name='billing_event'
              formatter={billingEventFormatter}
            />
          }
          {l1Object.autoOptimise &&
            <>
              <FormikField.Switch
                label={i18n.t<string>('adSetSetupFlow.mainStep.field.hasSpendLimits')}
                name='hasSpendLimits'
                onChange={onHasSpendLimitsChange}
              />
              {values.hasSpendLimits &&
                (
                  <>
                    <CustomField
                      label=''
                      render={renderMinSpentLimitInput}
                    />
                    <CustomField
                      label=''
                      render={renderMaxSpentLimitInput}
                    />
                  </>
                )
              }
            </>
          }
          {_.get(l1Object, 'fb.objective') === L1ObjectObjective.AWARENESS && values.optimization_goal === L2ObjectOptimizationGoal.REACH && values.frequency_control_specs && (
            formType === 'create' ?
              <CustomField
                label={i18n.t<string>('adSetSetupFlow.mainStep.field.frequencyControl')}
                name='frequency_control_specs'
                render={renderFrequencyControlInput}
              /> :
              <FormikField.Label
                label={i18n.t<string>('adSetSetupFlow.mainStep.field.frequencyControl')}
                name='frequency_control_specs'
                formatter={renderFrequencyControlLabel}
              />
            )
          }
          {_.get(l1Object, 'fb.pacing_type[0]') !== FbPacingType.STANDARD &&
            <div className={styles.section}>
              <FormikField.Switch
                label={i18n.t<string>('campaignInfo.labels.daypart')}
                name='dayPart.enabled'
                onChange={onDaypartSwitchChanged}
              />
              {!!dayPartEnable &&
                <FormikField.Custom
                  label=''
                  name='dayPart'
                  render={renderDayParts}
                />
              }
            </div>
          }
        </div>
      </fieldset>
    </div>
  );
};
