import React from 'react';
import classNames from 'classnames/bind';
import _ from 'lodash';

import styles from './summaryDetail.module.scss';
import i18n from 'i18n';
let cssClassNames = classNames.bind(styles);

export enum SummaryTitleColor {
  RED = 'red',
  GREEN = 'green',
  BLUE = 'blue',
  YELLOW = 'yellow',
  DARK = 'dark'
}

export type SummaryData = {
  label: string;
  value: string | number;
  hint?: string;
  hintColor?: string;
  showArea?: boolean;
};

const SummaryDetail: React.FC<{
  title?: string;
  data: SummaryData[];
  prefixColor?: SummaryTitleColor;
}> = ({ title, data, prefixColor }) => {

  const renderDetail = () => {
    if (_.isEmpty(data)) {
      return (
        <div className={styles.detailRow}>
          <div className={styles.label}>
            {i18n.t<string>('summary.titles.noData')}
          </div>
          <div className={styles.value} />
        </div>
      );
    }
    return data.map((detail) => {
      const hintClassName = cssClassNames('hint', detail.hintColor);
      return (
        detail.value ?
          <div className={styles.detailRow} key={`${title}:${detail.label}`}>
            <div className={styles.label}>
              {detail.label}
            </div>
            <div>
              <div className={detail.showArea ? styles.area : styles.value}>
                {detail.value}
              </div>
              {
                detail.hint &&
                <span className={hintClassName}>{detail.hint}</span>
              }
            </div>
          </div> :
          <div key={`${title}:${detail.label}`} />
      );
    });
  };

  const titlePrefixClassName = cssClassNames('titlePrefix', {
    red: prefixColor === SummaryTitleColor.RED,
    green: prefixColor === SummaryTitleColor.GREEN,
    blue: prefixColor === SummaryTitleColor.BLUE,
    yellow: prefixColor === SummaryTitleColor.YELLOW
  });

  return (
    <div className={styles.summaryDetail}>
      {
        title && (
          <div className={styles.title}>
            <div className={titlePrefixClassName}/>
            {title}
          </div>
        )
      }
      <div className={styles.details}>
        {renderDetail()}
      </div>
    </div>
  );
};

export default SummaryDetail;
