import React from 'react';
import { FormikInputField, FormikInputFieldProps, InputField, InputProps } from './InputField';
import { FieldLayoutProps } from 'hoc/withFieldLayout';

export const TextAreaField: React.FC<FieldLayoutProps & InputProps> = (props: FieldLayoutProps & InputProps) => (
  <InputField
    {...props}
    as={'textarea'}
  />
);
export const FormikTextAreaField: React.FC<FormikInputFieldProps> = (props: FormikInputFieldProps) => (
  <FormikInputField
    {...props}
    as={'textarea'}
  />
);
