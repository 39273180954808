import React from 'react';
import { AdvertiserMemberListProps, AdvertiserMemberListState, AdvertiserMemberListColumns } from './AdvertiserMemberListModel';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { renderColumn } from 'components/TableColumn/TableColumn';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './advertiserMemberList.module.scss';
import { SearchBar } from 'components/common/SearchBar';
import i18n from 'i18next';
import { Modal } from 'components/common/Modal/Modal';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';

export class AdvertiserMemberList extends React.Component<AdvertiserMemberListProps, AdvertiserMemberListState> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.onUnmount(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.updateViewModelData();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleOnSearch = (searchString: string) => {
    this.props.model.handleOnSearch(searchString);
  }

  nameFormatter = (id: any, { name }: any): any => {
    const editActionHandler = () => {
      this.props.model.showEditModal(id);
    };
    return (
      <div className={styles.nameCell}>
        <div
          className={styles.name}
          onClick={editActionHandler}
        >
          {name}
        </div>
        <div className={styles.id}>
          {'ID: ' + id}
        </div>
      </div>
    );
  }

  floatingEditBtnsFormatter = (cell: any, row: any): any => {
    const editActionHandler = () => {
      this.props.model.showEditModal(row.account.id);
    };
    const deleteActionHandler = () => {
      this.props.model.showDeleteModal(row.account.id);
    };
    return (
      <PermissionChecker permissionAware={hasFuncs(Permission.ADVERTISER_MEMBER_WRITE)}>
        <TableRowToolBar className={styles.floatingEditArea}>
          <IconWithTooltip
            icon={faPencilAlt}
            onClick={editActionHandler}
            tooltipProps={{
              id: `advertiserMemberListEditTip-${row.account.id}`,
              tooltip: i18n.t<string>('advertiserMemberList.labels.editHint')
            }}
          />
          <IconWithTooltip
            icon={faTrashAlt}
            onClick={deleteActionHandler}
            tooltipProps={{
              id: `advertiserMemberListDeleteTip-${row.account.id}`,
              tooltip: i18n.t<string>('advertiserMemberList.labels.deleteHint')
            }}
          />
        </TableRowToolBar>
      </PermissionChecker>
    );
  }

  renderModal (modalData) {
    const model = this.props.model;
    const ModalContent: typeof React.Component = modalData.component;
    return (
      <Modal
        fullScreen={modalData.fullScreen}
        title={modalData.title}
        primaryButton={modalData.confirmBtnData && {
          title: modalData.confirmBtnData.title,
          callback: modalData.confirmBtnData.callback
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.close'),
          callback: model.hideModal
        }}
        dismiss={model.hideModal}
        animation={false}
      >
        {ModalContent ? <ModalContent {...modalData.componentProps} /> : modalData.message}
      </Modal>
    );
  }

  render () {
    const model = this.props.model;
    const columns = [
      renderColumn(model.getColumnSetting(AdvertiserMemberListColumns.NAME), this.nameFormatter),
      renderColumn(model.getColumnSetting(AdvertiserMemberListColumns.EMAIL)),
      renderColumn(model.getColumnSetting(AdvertiserMemberListColumns.STATUS)),
      renderColumn(model.getColumnSetting(AdvertiserMemberListColumns.PERMISSION)),
      renderColumn(model.getColumnSetting(AdvertiserMemberListColumns.EDITBTNS), this.floatingEditBtnsFormatter)
    ];
    return (
      <div className={styles.advertiserMemberListContainer}>
        {model.state.loading && <LoadingIndicator/>}
        <div className={styles.operationArea}>
          <PermissionChecker permissionAware={hasFuncs(Permission.ADVERTISER_MEMBER_WRITE)}>
            <button className={'btn btn-tertiary btn-sm'} onClick={model.showAddMemberModal}>
              {i18n.t<string>('advertiserMemberList.labels.newAdvertiserMember')}
            </button>
          </PermissionChecker>
          <PermissionChecker permissionAware={hasFuncs(Permission.ACCOUNT_WRITE)}>
            <button className={'btn btn-tertiary btn-sm'} onClick={model.showAddAccountModal}>
              {i18n.t<string>('advertiserMemberList.labels.newAccount')}
            </button>
          </PermissionChecker>
          <SearchBar
            model={{
              placeholder: i18n.t<string>('advertiserMemberList.placeholders.searchbar'),
              search: this.handleOnSearch,
              defaultValue: model.searchString
            }}
          />
        </div>
        <div className={styles.advertiserMemberList}>
          <StickableBootstrapTable
            stickFirstColumn
            stickLastColumn
            keyField='id'
            data={model.state.viewModelData}
            columns={columns}
            noDataIndication={i18n.t<string>('advertiserMemberList.labels.nodata')}
            defaultSorted={model.sortDescriptor}
          />
        </div>
        {model.state.modalData && this.renderModal(model.state.modalData)}
      </div>
    );
  }
}
