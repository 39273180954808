import { useState, useEffect, useCallback } from 'react';
import { useFetchData } from 'utils/FinalReportUtil';
import i18n from 'i18n';
import _ from 'lodash';
import { FinalReportResponseStatus } from 'core/finalReport/FinalReportStatus';
import { ReportManager, DefaultReportManager } from 'core/report/ReportManager';
import { DismissFunction } from 'components/AlertDialog';
import { ROUTE_PATH } from 'enum/RoutePath';

const defaultReportManager: ReportManager = new DefaultReportManager();

export type FinalReportModelData = {
  loading: boolean;
  data: any;
  reportId: string;
  modalData: {
    title: string,
    message: string,
    onConfirm: DismissFunction
  } | undefined;
  redirectPath: string | undefined;
};

export const useFinalReportPageModel = (
  search: string,
  pathname: string,
  logined: boolean
): FinalReportModelData => {
  const [loading, setLoading] = useState<boolean>(false);
  const [data, setData] = useState<any>();
  const [failMessage, setFailMessage] = useState<string>('');
  const [modalData, setModalData] = useState<{
    title: string,
    message: string,
    onConfirm: DismissFunction
  } | undefined>();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [error, retryFetch] = useFetchData();

  const permissionAccepted = !pathname.includes(`${ROUTE_PATH.ERROR403}`);
  const preparing = search.includes('preparing');
  const reportId = search.includes('id') ? _.get(search.split('='), '[1]', '') : '';

  const callFetchDataAPI = useCallback(async (reportId: string) => {
    const retryTime = 20;
    setLoading(true);
    logined && permissionAccepted && retryFetch(
      defaultReportManager.getFinalReportData.bind(defaultReportManager, reportId),
      (response: FinalReportResponseStatus) => {
        // console.log('final response', response);
        if (response.status === 'SUCCESS') {
          const filterData = _.omitBy(JSON.parse(response.payload), _.isNil);
          setData(filterData);
          setModalData(undefined);
        } else if (response.status === 'FAIL') {
          setFailMessage(i18n.t<string>('finalReportPage.messages.invalid'));
        }
        setLoading(false);
      },
      retryTime
    );
  }, [retryFetch, logined, permissionAccepted]);

  useEffect(() => {
    if (reportId) {
      callFetchDataAPI(reportId);
    } else if (preparing) {
      setLoading(true);
    } else if (!permissionAccepted) {
      setLoading(false);
    }
  }, [callFetchDataAPI, preparing, reportId, permissionAccepted]);

  useEffect(() => {
    const modalData = getModalData(reportId, {
      logined,
      permissionAccepted,
      error,
      failMessage
    }, {
      setLoading,
      callFetchDataAPI,
      setRedirectPath
    });
    setModalData(modalData);
  }, [logined, permissionAccepted, reportId, error, failMessage, callFetchDataAPI]);

  return {
    loading,
    data,
    reportId,
    modalData,
    redirectPath
  };
};

const getModalData = (reportId, state, actions) => {
  if (!state.logined) {
    return({
      title: i18n.t<string>('finalReportPage.titles.confirmation'),
      message: i18n.t<string>('finalReportPage.messages.login'),
      onConfirm: _.partial(actions.setRedirectPath, '/')
    });
  }
  if (!state.permissionAccepted) {
    return({
      title: i18n.t<string>('finalReportPage.titles.confirmation'),
      message: i18n.t<string>('finalReportPage.messages.permission'),
      onConfirm: _.partial(actions.setRedirectPath, '/')
    });
  }
  if (state.error) {
    actions.setLoading(false);
    return({
      title: i18n.t<string>('finalReportPage.titles.fail'),
      message: i18n.t<string>('finalReportPage.messages.exceeded'),
      onConfirm: _.partial(actions.callFetchDataAPI, reportId)
    });
  }
  if (state.failMessage) {
    return {
      title: i18n.t<string>('finalReportPage.titles.fail'),
      message: state.failMessage,
      onConfirm: _.partial(actions.setRedirectPath, '/')
    };
  }
};
