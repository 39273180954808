import React from 'react';
import styles from './advertiserList.module.scss';
import { Link } from 'react-router-dom';
import i18n from 'i18next';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { ADVERTISER_COLUMNS } from './advertiserListColumnSettings';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';

export function getAdvertiserListFormatters () {

  const nameFormatter = (columnData: any, rowData: any) => {
    if (columnData === undefined) {
      return <div/>;
    }
    return (
      <div className={styles.nameCell}>
        <Link to={`/advertisers/${columnData}`} className={styles.name}>
          {rowData.name}
        </Link>
        <div className={styles.id}>
          {'ID: ' + columnData}
        </div>
      </div>
    );
  };

  const categoryFormatter = (category: any) => {
    const targetText = category ?
      i18n.t<string>(`tenmaxCategory.labels.${category.toLowerCase().replace('-', '_')}`) :
      i18n.t<string>('campaignList.labels.emptyContent');
    return (
      <span>{targetText}</span>
    );
  };

  const urlFormatter = (url: any) => {
    return (
      <a href={url} target='_blank' rel='noopener noreferrer'>
        {url}
      </a>
    );
  };

  const floatingEditBtnsFormatter = (cell: any, rowData: any): any => {
    return (
      <PermissionChecker permissionAware={hasFuncs(Permission.ADVERTISER_WRITE)}>
        <TableRowToolBar className={styles.floatingEditBtnsCell}>
          <Link
            to={`/advertisers/${rowData[ADVERTISER_COLUMNS.NAMEID]}/edit`}
          >
            <IconWithTooltip
              icon={faPencilAlt}
              tooltipProps={{
                id: `advertiserListEditTip-${rowData[ADVERTISER_COLUMNS.NAMEID]}`,
                tooltip: i18n.t<string>('advertiserList.labels.editHint')
              }}
            />
          </Link>
        </TableRowToolBar>
      </PermissionChecker>
    );
  };

  return {
    nameFormatter,
    categoryFormatter,
    urlFormatter,
    floatingEditBtnsFormatter
  };
}
