import React, { useRef } from 'react';
import parser from 'html-react-parser';
import styles from './adPreview.module.scss';
import { defaultTo } from 'lodash';

export const AdPreview: React.FC<{
  iframeString: string | undefined,
  iframeConfig?: any
}> = ({
  iframeString,
  iframeConfig
}) => {

  const previewRef = useRef<any>(null);

  const onIframeLoaded = () => {
    if (!previewRef.current) {
      return;
    }
    previewRef.current.className = '';
    previewRef.current.querySelector(`.${styles.loadingCircle}`).style.display = 'none';
  };

  let content;
  if (iframeString) {
    const iframe = parser(iframeString);
    if (React.isValidElement(iframe)) {
      content = React.cloneElement(iframe, {
        ...defaultTo(iframeConfig, {}),
        onLoad: onIframeLoaded
      });
    }
  }
  return (
    <div
      ref={el => previewRef.current = el}
      className={styles.previewPlaceholder}
    >
      <div className={styles.loadingCircle} />
      {content}
    </div>
  );
};
