import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext, useMemo, useState } from 'react';
import { SourceDetails } from 'core/draft/Draft';
import styles from './draftSourceDetail.module.scss';
import i18n from 'i18n';
import { StateDataContext } from 'containers/Common/StateDataContext';
import { get, map } from 'lodash';

export const DraftSourceDetail: React.FC<any> = () => {

  const [showSourceDetails, setShowSourceDetails] = useState<boolean>(false);
  const { data } = useContext(StateDataContext);

  const sourceDetail = useMemo(() => {
    return {
      [SourceDetails.ORDERNAME]: get(data, 'order.projectName', ''),
      [SourceDetails.GROUPNAME]: get(data, 'l1Object.name', ''),
      [SourceDetails.CHANNEL]: get(data, 'l1Object.channel', ''),
      [SourceDetails.SELECED]: get(data, 'l2ObjectsToCreateDraft.length', 0)
    };
  }, [data]);

  const triggerSourceDetails = () => {
    setShowSourceDetails(showSourceDetails => !showSourceDetails);
  };

  return (
    <div className={styles.sourceDetails}>
      <div
        className={styles.triggerSourceDetailsBtn}
        onClick={triggerSourceDetails}
      >
        <FontAwesomeIcon
          icon={showSourceDetails ? faAngleUp : faAngleDown}
        />
        {i18n.t<string>('draftSourceDetails.labels.sourceDetails')}
      </div>

      {showSourceDetails &&
        <div className={styles.detailsSection}>
          {map(Object.values(SourceDetails), (property, index) => (
            <div className={styles.detail} key={index}>
              <div className={styles.title}>{i18n.t<string>(`draftSourceDetails.labels.${property}`)}</div>
              <div className={styles.content}>{sourceDetail[property]}</div>
            </div>
          ))}
        </div>
      }
    </div>
  );
};
