import _ from 'lodash';
import {
  AuthenticationManager,
  DefaultAuthenticationManager
} from './AuthenticationManager';
import { isSystemAdmin } from 'helper/ActorHelper';
import { AddonFeatureType, AddonDependency } from 'core/agency/AddonFeature';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';

export interface AddonFeatureManager {
  /**
   * Validate current actor addonFeature is enable and with about dependency addonFeature.
   */
  isFeatureEnable (addonFeature: AddonFeatureType): boolean;
  updateAddonFeature (addonFeatures: any): void;
  convertAgencyAddonFeatures (addonFeatures: []): string[];
  readonly addonFeature: string[];
  readonly event: UpdateEventListener<AddonFeatureManager>;
}

export class DefaultAddonFeatureManager implements AddonFeatureManager {
  authManager: AuthenticationManager;
  modelAddonFeatures: string[];
  addonEvent: FireableUpdateEventListener<AddonFeatureManager>;

  constructor (
    authenticationManager: AuthenticationManager = new DefaultAuthenticationManager()
  ) {
    this.authManager = authenticationManager;
    this.modelAddonFeatures = [];
    this.addonEvent = new FireableUpdateEventListener<AddonFeatureManager>();
  }

  isFeatureEnable (toBeCheckedAddonFeature: AddonFeatureType): boolean {
    if (isSystemAdmin(this.authManager.actor)) {
      return false;
    }

    const hasAddonFeature = this.modelAddonFeatures.includes(
      toBeCheckedAddonFeature.toString()
    );
    if (hasAddonFeature && AddonDependency[toBeCheckedAddonFeature]) {
      return AddonDependency[toBeCheckedAddonFeature].every(addonFeature => {
        return this.modelAddonFeatures.includes(addonFeature);
      });
    }
    return hasAddonFeature;
  }

  convertAgencyAddonFeatures (addonFeatures: []): string[] {
    const addons = Object.values(_.omit(addonFeatures, ['campaignLimitation', 'creativeLimitation'])).reduce(
      (acc: string[], addonFeature) => {
        Object.keys(addonFeature)
          .filter(addonKey => {
            return addonFeature[addonKey];
          })
          .forEach((addonKey: string) => {
            acc.push(addonKey);
          });
        return acc;
      },
      []
    );
    if ('campaignLimitation' in addonFeatures) {
      const campaignLimitation: any = addonFeatures['campaignLimitation'];
      Object.keys(campaignLimitation)
      .filter(addonKey => {
        return campaignLimitation[addonKey];
      })
      .forEach((addonKey: string) => {
        addons.push(`campaign_${addonKey}`);
      });
    }

    if ('creativeLimitation' in addonFeatures) {
      const creativeLimitation: any = addonFeatures['creativeLimitation'];
      Object.keys(creativeLimitation)
      .filter(addonKey => {
        return creativeLimitation[addonKey];
      })
      .forEach((addonKey: string) => {
        addons.push(`creative_${addonKey}`);
      });
    }

    return addons;
  }

  notifyListeners () {
    this.addonEvent.fireEvent(this);
  }

  get addonFeature (): string[] {
    return this.modelAddonFeatures;
  }

  get event (): UpdateEventListener<AddonFeatureManager> {
    return this.addonEvent;
  }

  updateAddonFeature (addonFeatures: any): void {
    const converedAddonFeatures = this.convertAgencyAddonFeatures(
      addonFeatures
    );
    this.modelAddonFeatures = converedAddonFeatures;
    this.notifyListeners();
  }
}
