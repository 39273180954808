export const rtbInventories = {
  geography: {
    name: 'geography',
    title: 'limitation.labels.geography'
  },
  carrier: {
    name: 'carrier',
    title: 'limitation.labels.carrier',
    i18nPrefix: 'limitationOptions.labels.carrier'
  },
  os: {
    name: 'os',
    title: 'limitation.labels.os'
  },
  device: {
    name: 'device',
    title: 'limitation.labels.device',
    i18nPrefix: 'limitationOptions.labels.device'
  },
  age: {
    name: 'age',
    title: 'limitation.labels.age'
  },
  gender: {
    name: 'gender',
    title: 'limitation.labels.gender',
    i18nPrefix: 'limitationOptions.labels.gender'
  },
  segment: {
    name: 'segment',
    title: 'limitation.labels.segment'
  },
  agencySegment: {
    name: 'agencySegment',
    title: 'limitation.labels.agencySegment'
  },
  tenmaxAudience: {
    name: 'tenmaxAudience',
    title: 'limitation.labels.tenmaxAudience'
  },
  contentcat: {
    name: 'contentcat',
    title: 'limitation.labels.contentcat',
    i18nPrefix: 'limitation.contentCategory'
  },
  unicornkeywords: {
    name: 'unicornkeywords',
    title: 'limitation.labels.unicornkeywords'
  },
  adx: {
    name: 'adx',
    title: 'limitation.labels.adx'
  },
  spaceType: {
    name: 'spaceType',
    title: 'limitation.labels.spaceType',
    i18nPrefix: 'limitationOptions.labels.spaceType'
  },
  adspace: {
    name: 'adspace',
    title: 'limitation.labels.adspace'
  },
  contentlang: {
    name: 'contentlang',
    title: 'limitation.labels.contentlang',
    i18nPrefix: 'limitationOptions.labels.contentlang'
  },
  adcat: {
    name: 'adcat',
    title: 'limitation.labels.adcat',
    i18nPrefix: 'tenmaxCategory.labels'
  },
  domain: {
    name: 'domain',
    title: 'limitation.labels.domain'
  },
  adFormat: {
    name: 'adFormat',
    title: 'limitation.labels.adFormat',
    i18nPrefix: 'adFormat'
  }
};
