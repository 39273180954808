import { PropsWithChildren } from 'react';
import styles from './warning.module.scss';
import classNames from 'classnames';
const cx = classNames.bind(styles);

export interface WarningProps {
  className?: string;
}

export const Warning = ({ className, children }: WarningProps & PropsWithChildren) => {
  const finalClassName = cx(styles.warning, className);
  return <div className={finalClassName}>{children}</div>;
};
