import { useState, useEffect, useCallback } from 'react';
import { ProductFilter } from './ProductFilter';
import { Product } from 'core/product/Product';
import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import formatters from './listFormatters';
import _ from 'lodash';
import styles from './productList.module.scss';

export enum ProductListColumns {
  ID = 'productId',
  NAME = 'name',
  IMAGE = 'imgLink',
  CATEGORY = 'category',
  SALEPRICE = 'salePrice'
}

export enum ProductListType {
  BASIC = 'basic'
}

export type ProductListState = {
  readonly filteredList: Product[];
  readonly selectedProductListType: ProductListType;
};

export const useProductListModel = (
  filterSet: ProductFilter,
  searchString: string,
  productList: Product[]
) => {

  const [state, setState] = useState({
    filteredList: productList,
    selectedProductListType: ProductListType.BASIC
  } as ProductListState);

  const [selectedProducts, setSelectedProducts] = useState<(number | string)[]>([]);

  useEffect(() => {
    const filteredList = productList
    .filter(product => {
      // TODO: This block of filtering is temporarily for frontend specificly, could be removed afterwards
      const productCategoryInclude = filterSet.productCategory
       ? (product.category.includes(filterSet.productCategory) || filterSet.productCategory === 'unlimited') : true;

      return productCategoryInclude;
    })
    // .filter((_1, index) => {
    //   const topNInclude = filterSet.topN ? index + 1 <= parseInt(filterSet.topN, 10) : true;

    //   return topNInclude;
    // })
    .filter(product => {
      const nameInclude = product.name.toLowerCase().includes(searchString.toLowerCase());
      const idInclude = product.productId.toString().includes(searchString);
      return nameInclude || idInclude;
    });

    setState(prevState => ({ ...prevState, filteredList }));
  }, [filterSet, searchString, productList]);

  const columnDefinition = (columnName, sortable: boolean = true, customLabel?: string): ColumnDefinition => ({
    ...sortableColumn(columnName, customLabel ? customLabel : `productList.labels.${columnName}`, sortable),
    classes: () => styles[columnName],
    headerClasses : () => styles[columnName]
  });

  const onSelect = (productId: number | string) => {
    if (selectedProducts.indexOf(productId.toString()) > -1) {
      setSelectedProducts(selectedProducts.filter(id => id !== productId));
    } else {
      setSelectedProducts([...selectedProducts, productId.toString()]);
    }
  };

  const onSelectAll = () => {
    if (selectedProducts.length === state.filteredList.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(_.map(state.filteredList, (product) => product.productId.toString()));
    }
  };

  const handleRemoveSelect = useCallback(() => {
    setSelectedProducts([]);
  }, []);

  const onProductListTypeChange = (selectedProductListType) => {
    setState(prevState => ({ ...prevState, selectedProductListType }));
  };

  const idColumn = renderColumn({
    ...columnDefinition(ProductListColumns.ID),
    formatExtraData: {
      // currentUrl: match.url,
      selectedProducts,
      onSelect
    }
  },
  formatters.nameFormatter,
  _.partial(
    formatters.nameHeaderFormatter,
    state.filteredList.length,
    selectedProducts,
    onSelectAll
  ));

  const basicColumns = _.compact([
    idColumn,
    renderColumn(columnDefinition(ProductListColumns.IMAGE, false), formatters.imageFormatter),
    renderColumn(columnDefinition(ProductListColumns.CATEGORY)),
    renderColumn(columnDefinition(ProductListColumns.SALEPRICE))
  ]);

  const typeColumnsMap = {
    [ProductListType.BASIC]: basicColumns
  };

  return {
    state,
    selectedProducts,
    columns: typeColumnsMap[state.selectedProductListType],
    onProductListTypeChange,
    handleRemoveSelect
  };
};
