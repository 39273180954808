import React, { Fragment } from 'react';
import { Fields, DownloadReportPanelProps, DownloadReportPanelState, ReportDownloadPanelTab } from './DownloadReportPanelModel';
import styles from './downloadReportPanel.module.scss';
import moment from 'moment';
import { Modal } from 'components/common/Modal/Modal';
import i18n from 'i18next';
import _ from 'lodash';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { DateRange } from 'components/common/DateRangePicker/DateRangePicker';
import { Form, Tab, Tabs } from 'react-bootstrap';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled } from 'core/permission/PermissionDSL';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { DateRangePickerField } from 'components/common/form/field/DateRangePickerField';
import { SelectField } from 'components/common/form/field/SelectField';
import { CustomField } from 'components/common/form/field/CustomField';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

export const defaultFormat = 'YYYY-MM-DD_HH:mm:ss';
export class DownloadReportPanel extends React.Component<DownloadReportPanelProps, DownloadReportPanelState> {

  handler: number;

  constructor (props) {
    super(props);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  hide = () => {
    this.props.model.cancel();
  }

  handleDateRangeChange = (startDate, endDate) => {
    this.props.model.updateDateRange(startDate, endDate);
  }

  download = () => {
    this.props.model.downloadReport();
  }

  renderFieldChecks = (...fields) => {
    const fieldsDom = fields.map(field => {
      const errors = this.props.model.errors;
      const className = cx({
        error: errors[field] !== undefined
      });

      return (
        <Form.Check
          id={field}
          key={field}
          type='checkbox'
          className={className}
          checked={this.props.model.state.selectFields[field]}
          label={i18n.t<string>(`downloadReportPanel.labels.${_.camelCase(field)}`)}
          onChange={this.props.model.onFieldSelect}
        />
      );
    });

    return (
      <div className={styles.fieldChecks}>
        {fieldsDom}
      </div>
    );
  }

  renderClosingReportFieldChecks = (...fields) => {
    const fieldsDom = fields.map(field => (
      <Form.Check
        id={field}
        key={field}
        type='checkbox'
        checked={this.props.model.state.closingReportCustom[field]}
        label={i18n.t<string>(`orderDownloadReportPanel.labels.${_.camelCase(field)}`)}
        onChange={this.props.model.onClosingReportCustomSelect}
      />
    ));
    return (
      <div className={styles.fieldChecks}>
        {fieldsDom}
      </div>
    );
  }

  renderGroupBy () {
    const model = this.props.model;
    return model.supportGroupBy.map(groupByType => {
      const groupById = _.camelCase(`group_by_${groupByType.toLowerCase()}`);
      return (
        <Form.Check
          key={groupByType}
          type='radio'
          label={i18n.t<string>(`downloadReportPanel.labels.${groupById}`)}
          value={groupByType}
          name='groupBy'
          id={groupById}
          checked={model.state.groupBy === groupByType}
          onChange={model.changeGroupBy}
        />
      );
    });
  }

  render () {
    const dateRangeShortCut: DateRange[] = [
      {
        label: 'Today',
        dateRange: [moment().startOf('day').toDate(), moment().endOf('day').toDate()]
      },
      {
        label: 'Yesterday',
        dateRange: [moment().subtract(1, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]
      },
      {
        label: 'Last week',
        dateRange: [moment().subtract(7, 'days').startOf('day').toDate(), moment().subtract(1, 'days').endOf('day').toDate()]
      }
    ];
    const model = this.props.model;
    const renderQuickSelect = () => model.reportTypeOptions
      .filter(option => !model.reportTypeCannotShowInCustom.includes(option.value.toString()))
      .map(option => (
        <div
          key={option.value}
          onClick={_.partial(model.onQuickFieldSelect, option.value.toString())}
        >
          {option.label}
        </div>
      )
    );
    const convsEnabled = model.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST);
    const renderErrors = () => Object.keys(model.errors).map(key => {
      const error = model.errors[key];
      return (
        <div key={key} className={styles.errorHint}>
          {error}
        </div>
      );
    });
    return (
      <Fragment>
        {model.state.loading && <LoadingIndicator />}
        <Modal
          title={i18n.t<string>('downloadReportPanel.labels.downloadReport')}
          dismiss={this.hide}
          animation={false}
          className={styles.downloadReportPanel}
          fullScreen
          enforceFocus={false}
          primaryButton={{
            title: i18n.t<string>('downloadReportPanel.buttons.download'),
            callback: this.download,
            disabled: !model.state.enableDownload
          }}
          secondaryButton={{
            title: i18n.t<string>('common.buttons.cancel'),
            callback: this.hide
          }}
        >
          <Tabs
            id='reportDownloadPanelTab'
            defaultActiveKey={ReportDownloadPanelTab.NORMAL}
            activeKey={model.state.activeTab}
            onSelect={model.onSelectTab}
          >
            <Tab eventKey={ReportDownloadPanelTab.NORMAL} title={i18n.t<string>('downloadReportPanel.labels.normal')}>
              <div className={styles.content}>
                <DateRangePickerField
                  label={i18n.t<string>('downloadReportPanel.labels.dateRange')}
                  name='dateRange'
                  popOverClassName={styles.datePicker}
                  onChange={this.handleDateRangeChange}
                  startDate={model.state.startDate}
                  endDate={model.state.endDate}
                  minDate={model.defaultStartDate}
                  maxDate={model.defaultEndDate}
                  format={'YYYY-MM-DD'}
                  showTimePicker={false}
                  shortcuts={dateRangeShortCut}
                />
                <SelectField
                  label={i18n.t<string>('downloadReportPanel.labels.reportType')}
                  options={model.reportTypeOptions}
                  name='reportOptions'
                  simpleValue
                  onChange={model.onSelectReportType}
                  placeholder={i18n.t<string>('DownloadReportPanel.placeholders.reportType')}
                  maxMenuHeight={150}
                  value={model.state.reportType}
                />
              </div>
            </Tab>
            {model.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT) &&
              <Tab eventKey={ReportDownloadPanelTab.CLOSING} title={i18n.t<string>('downloadReportPanel.labels.closing')}>
                <div className={styles.content}>
                  <CustomField
                    label={i18n.t<string>('orderDownloadReportPanel.labels.audienceData')}
                  >
                    {this.renderClosingReportFieldChecks(Fields.COUNTRY, Fields.DOMAIN)}
                  </CustomField>
                </div>
              </Tab>
            }
            {model.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_ORDER_CUSTOM) &&
              <Tab eventKey={ReportDownloadPanelTab.ADVANCE} title={i18n.t<string>('downloadReportPanel.labels.advance')}>
                <div className={styles.content}>
                  <DateRangePickerField
                    label={i18n.t<string>('downloadReportPanel.labels.dateRange')}
                    popOverClassName={styles.datePicker}
                    onChange={this.handleDateRangeChange}
                    startDate={model.state.startDate}
                    endDate={model.state.endDate}
                    minDate={model.defaultStartDate}
                    maxDate={model.defaultEndDate}
                    format={'YYYY-MM-DD'}
                    showTimePicker={false}
                    shortcuts={dateRangeShortCut}
                  />
                  <CustomField
                    name='groupBy'
                    label={i18n.t<string>('downloadReportPanel.labels.groupBy')}
                  >
                    <div className={styles.groupBy}>
                      {this.renderGroupBy()}
                    </div>
                  </CustomField>
                  <CustomField
                    label={i18n.t<string>('downloadReportPanel.labels.dataContent')}
                    formGroupClassName={styles.dataContent}
                  />
                  <CustomField
                    name='quickSelect'
                    label={i18n.t<string>('downloadReportPanel.labels.quickSelect')}
                  >
                    <div>
                      <div className={styles.quickSelect}>
                        {renderQuickSelect()}
                        <div onClick={_.partial(model.onQuickFieldSelect, 'reset')}>
                          {i18n.t<string>('downloadReportPanel.labels.reset')}
                        </div>
                      </div>
                      {this.renderFieldChecks(Fields.IMPRES, Fields.UNIQUE_USERS)}
                      {this.renderFieldChecks(Fields.CLICKS, Fields.CTR)}
                      {this.renderFieldChecks(Fields.VIEW, Fields.VIEW_RATE)}
                      {convsEnabled ? this.renderFieldChecks(Fields.CONVS, Fields.SPENT) : this.renderFieldChecks(Fields.SPENT)}
                      {convsEnabled ? this.renderFieldChecks(Fields.CPM, Fields.CPC, Fields.CPV, Fields.CPA) : this.renderFieldChecks(Fields.CPM, Fields.CPC, Fields.CPV)}
                      <div className={styles.errorContainer}>
                        {renderErrors()}
                      </div>
                    </div>
                  </CustomField>
                  <PermissionChecker permissionAware={addOnEnabled(ADDONFEATURE.CREATIVES.VIDEO, ADDONFEATURE.CREATIVES.THIRDPARTY, ADDONFEATURE.CREATIVES.COMBO, ADDONFEATURE.CREATIVES.OUTDOOR)}>
                    <CustomField name='videoAdViewObjective' label={i18n.t<string>('downloadReportPanel.labels.videoAdViewObjective')}>
                      {this.renderFieldChecks(Fields.FIRST_QUARTILE, Fields.MID_POINT, Fields.THIRD_QUARTILE, Fields.COMPLETE)}
                    </CustomField>
                  </PermissionChecker>
                  <PermissionChecker permissionAware={addOnEnabled(ADDONFEATURE.REPORT.REPORT_CLOSINGREPORT)}>
                    <CustomField
                      name='divider'
                      label=''
                      formGroupClassName={styles.dividerContainer}
                    >
                      <div className={styles.divider} />
                    </CustomField>
                    <CustomField name='audienceData' label={i18n.t<string>('downloadReportPanel.labels.audienceData')}>
                      <IconWithTooltip
                        className={styles.audienceDataHint}
                        icon={faInfoCircle}
                        tooltipProps={{
                          id: 'audienceDataHint',
                          tooltip: i18n.t<string>('downloadReportPanel.labels.audienceDataHint')
                        }}
                      />
                      {this.renderFieldChecks(Fields.GENDER, Fields.AGE, Fields.INTERESTS)}
                    </CustomField>
                  </PermissionChecker>
                </div>
              </Tab>
            }
          </Tabs>
        </Modal>
      </Fragment>
    );
  }
}
