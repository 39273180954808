import React from 'react';
import cx from 'classnames/bind';
import styles from './audioFormInput.module.scss';
import { AudioInput } from 'components/common/AudioInput/AudioInput';

const classNames = cx.bind(styles);

export const AudioFormInput = (props) => {

  const onFileChange = (file) => {
    if (!file) {
      props.onChange && props.onChange({
        url: undefined,
        file: undefined
      });
      return;
    }
    const validTypes = props.supportType ? props.supportType : ['audio/mpeg'];
    if (validTypes.indexOf(file.type) === -1) {
      props.onChange && props.onChange({
        file,
        url: undefined
      });
      return;
    }
    const audio = document.createElement('audio');
    audio.addEventListener('loadedmetadata', () => {
      props.onChange && props.onChange({
        file,
        duration: audio.duration,
        url: props.url
      });
    });
    audio.src = URL.createObjectURL(file);
  };

  const mainClassdName = classNames('audioPreview', props.className, {
    showErrorBorder: props.errorBorderHint,
    showSolidBorder: props.url || props.file
  });

  return (
    <div className={mainClassdName}>
      <div className={styles.audioContent}>
        <AudioInput
          {...props}
          onChange={onFileChange}
        />
      </div>
    </div>
  );
};
