import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartComponentLike,
  LegendItem
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import i18n from 'i18n';
import styles from './doctorChart.module.scss';
import _ from 'lodash';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  ChartDataLabels as ChartComponentLike,
  Title,
  Tooltip,
  Legend
);

const filteredColor = '#82ca9d';
const remianColor = '#8884d8';
export const DoctorChart: React.FC<{chartData: any[]}> = props => {

  const remainSpaceChannelCount: number[] = [];
  const filteredReasonCount: number[] = [];
  const labels: string[] = [];
  props.chartData.forEach(data => {
    remainSpaceChannelCount.push(data.remainSpaceChannels.length);
    filteredReasonCount.push(data.filteredReasons.length);
    labels.push(i18n.t<string>(`doctorTable.labels.${_.camelCase(data.type)}`));
  });

  const barHeight = 30;
  const barGap = 10;
  const legendHeight = 70;
  const chartHeight = barHeight * labels.length + (labels.length - 1) * barGap;

  const chartOptions = {
    indexAxis: 'y' as const,
    animation: {
      duration: 0
    },
    maintainAspectRatio: false,
    scales: {
      x: {
        grid: {
          display: false,
          drawBorder: false
        },
        ticks: {
          display: false
        },
        stacked: true
      },
      y: {
        grid: {
          display: false,
          drawBorder: false
        },
        stacked: true
      }
    },
    plugins: {
      datalabels: {
        formatter: function (value) {
          return value.toString() === '0' ? '' :  value;
        },
        align: 'center' as const,
        anchor: 'center' as const,
        color: 'white',
        font: {
          size: 14
        }
      },
      legend: {
        position: 'bottom' as const,
        labels: {
          boxWidth: 15,
          generateLabels: (chart) => {
            const data = chart.data;
            return data.datasets.map(dataset => {
              return {
                text: i18n.t<string>(`doctorChart.labels.${dataset.label}`),
                strokeStyle: dataset.borderColor,
                fillStyle: dataset.borderColor
              } as LegendItem;
            });
          }
        }
      },
      tooltip: {
        mode: 'nearest' as const,
        position: 'nearest' as const,
        callbacks: {
          title: (tooltipItems) => {
            if (!tooltipItems[0].label) {
              return '';
            }
            return tooltipItems[0].label;
          },
          label: (tooltipItems) => {
            if (!tooltipItems.label) {
              return '';
            }
            const category = tooltipItems.dataset.label;
            return `${i18n.t<string>(`doctorChart.labels.${category}`)}: ${tooltipItems.formattedValue}`;
          }
        }
      }
    },
    layout: {
      padding: {
        left: 100,
        right: 100,
        top: 0,
        bottom: 0
      }
    }
  };
  return (
    <div className={styles.chartContainer}>
      <div className={styles.arrowContainer} style={{ height: chartHeight }}>
        <div className={styles.arrow}/>
      </div>
      <Bar
        height={chartHeight + legendHeight}
        data={{
          datasets: [{
            borderColor: remianColor,
            backgroundColor: remianColor,
            data: remainSpaceChannelCount,
            label: 'remain',
            categoryPercentage: 0.9
          },{
            borderColor: filteredColor,
            backgroundColor: filteredColor,
            data: filteredReasonCount,
            label: 'filtered',
            categoryPercentage: 0.9
          }],
          labels: labels
        }}
        options={chartOptions}
      />
    </div>
  );
};
