import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { CreativeListRecord, CreativeType } from 'core/creative/Creative';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { toast } from 'react-toastify';
import i18n from 'i18next';
import { CreativesWithPagination } from 'ws/CreativeWebService';
import _ from 'lodash';
import { CreativeListDataProvider } from './CreativeListDataProvider';
import { getDataProvider } from './CreativeListDataProviderFactory';
import { Pagination } from 'core/pagination/Pagination';
import { CreativeListCommonColumns } from './CreativeListCommonColumnData';
import { CreativeDetail } from 'components/CreativeDetail/CreativeDetail';
import { DefaultCreativeDetailModel } from 'components/CreativeDetail/CreativeDetailModel';
import { CreativeBindManagePage } from 'components/CreativeBindManagePage/CreativeBindManagePage';
import { AddonFeatureManager } from 'core';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { SortableList, AbstractSortableList } from 'containers/Common/AbstractSortableList';
import { ADDONFEATURE } from 'core/agency/AddonFeature';

const SIZE_PER_PAGE: number = 10;

export type CreativeListState = {
  readonly loading: boolean;
  readonly selectedCreatives: Array<number>
  readonly creativeTypeToShow: CreativeType;
  readonly viewModelData: any;
  readonly pagination?: Pagination;
  readonly modalData?: any;
  readonly summaryData: any;
};
export interface CreativeListModel extends SortableList {
  readonly event: UpdateEventListener<CreativeListModel>;
  readonly state: CreativeListState;
  readonly selectedCreatives: Array<number | undefined>;
  readonly sizePerPage: number;
  readonly searchString: string;
  init (page?: number, newCreativeType?: CreativeType): Promise<void>;
  setSearchbarRef (ref: any): void;
  reviewCreative: (adx, creativeId) => void;
  getColumnsToRender: () => Array<any>;
  updateViewModelData: () => any;
  changeCreativeTypeToRender: (creativeType: string | null, event?: any) => void;
  changeAdvertiser (advertiserId?: number): Promise<void>;
  sortCreatives (field, direction);
  handleOnSearch (searchString): void;
  handleOnSelect (creativeId: number): void;
  handleOnSelectAll (): void;
  showCreativeDetailModal (creative: CreativeListRecord): void;
  hideModal (): void;
  clearSelectedCreatives (): void;
  updateBindState (enable: boolean, event?: any): Promise<void>;
  cloneCreatives (creatives?: Array<number>): Promise<void>;
  deleteCreative (creativeIds: Array<number>): Promise<void>;
  showCreativeBindingManage (creative): void;
  showDeleteCreativeModal (creativeIds, event?: any): void;
  canDeleteSelectedCreatives (creativeIds: Array<number>): boolean;
  onSearchChangeCallback: (searchString: string) => void;
  isCreativeTypeSupport: (creativeType) => boolean;
  addCache: (key: string, data: any) => void;
  getCache: (key: string) => any;
}

export type CreativeListProps = {
  readonly model: CreativeListModel;
};

export class DefaultCreativeListModel extends AbstractSortableList implements CreativeListModel {
  event: FireableUpdateEventListener<CreativeListModel>;
  creativesWithPagination?: CreativesWithPagination;
  selectedCreatives: Array<number>;
  creativeManager: CreativeManager;
  loading: boolean;
  advertiserId?: number;
  searchString: string;
  creativeTypeToShow: CreativeType;
  listDataProvider: CreativeListDataProvider;
  searchbarRef: any;
  viewModelData: any;
  pagination?: Pagination;
  sortTimeout: any;
  sizePerPage: number;
  modalData: any;
  debouncedOnSearch: any;
  summaryData: any = {};
  onSearchChange: (searchString: string) => void;
  cache: any = {};

  constructor (defaultSearchString: string | null, public onSearchChangeCallback: (searchString: string) => void, private addonFeatureManager: AddonFeatureManager, creativeManager: CreativeManager = new DefaultCreativeManager()) {
    super(CreativeListCommonColumns.NAME_ID, 'desc');
    this.advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    this.event = new FireableUpdateEventListener<CreativeListModel>();
    this.selectedCreatives = [];
    this.creativeManager = creativeManager;
    this.loading = true;
    this.searchString = defaultSearchString ? defaultSearchString : '';
    this.onSearchChange = (searchString: string) => {
      onSearchChangeCallback(searchString);
      this.init(1);
    };
    let firstSupportType = [
      CreativeType.ONE_FOR_ALL_DISPLAY,
      CreativeType.NATIVE,
      CreativeType.IMAGE,
      CreativeType.VIDEO,
      CreativeType.THIRDPARTY,
      CreativeType.HTML5,
      CreativeType.COMBO,
      CreativeType.CUSTOM_RECTANGLE,
      CreativeType.CUSTOM_BOTTOM,
      CreativeType.OUTDOOR
    ].find(type => this.isCreativeTypeSupport(type));
    firstSupportType = firstSupportType ? firstSupportType : CreativeType.ONE_FOR_ALL_DISPLAY;
    this.creativeTypeToShow = firstSupportType;
    this.listDataProvider = getDataProvider(this.creativeTypeToShow, this);
    this.sizePerPage = SIZE_PER_PAGE;
    this.debouncedOnSearch = _.debounce(this.onSearchChange.bind(this), 1000);
    this.updateViewModelData();
  }

  async init (page?: number, newCreativeType?: CreativeType) {
    page = page !== undefined ? page :
      this.creativesWithPagination ? this.creativesWithPagination.pagination.page : 1;
    this.updateState(true);
    const creativeTypeToShow = newCreativeType ? newCreativeType : this.creativeTypeToShow;
    try {
      if (creativeTypeToShow === CreativeType.THIRDPARTY) {
        await this.creativeManager.getPpsLayoutId();
      }
    } catch (e) {}
    try {
      const pageable = { page: page, sizePerPage: SIZE_PER_PAGE, sort: this.sortField, direction: this.sortOrder };
      this.creativesWithPagination = await this.creativeManager.getCreatives(creativeTypeToShow, this.advertiserId, pageable, this.searchString);
      this.pagination = this.creativesWithPagination ? this.creativesWithPagination.pagination : undefined;
      this.selectedCreatives = [];
      if (newCreativeType) {
        this.creativeTypeToShow = newCreativeType;
        this.listDataProvider = getDataProvider(newCreativeType, this);
      }
      this.listDataProvider.init && await this.listDataProvider.init();
      this.updateViewModelData();
    } catch (e) {
      this.updateState(false);
    }
  }

  handleOnSelect = (creativeId) => {
    if (this.selectedCreatives.indexOf(creativeId) > -1) {
      _.remove(this.selectedCreatives, id => id === creativeId);
    } else {
      this.selectedCreatives.push(creativeId);
    }
    this.updateViewModelData();
  }

  handleOnSelectAll = () => {
    if (!this.creativesWithPagination) {
      return;
    }

    const creatives = this.creativesWithPagination.creatives;
    if (this.selectedCreatives.length === creatives.length) {
      this.selectedCreatives = [];
    } else {
      this.selectedCreatives = creatives.map(creative => creative.creativeId);
    }
    this.updateViewModelData();
  }

  handleOnSearch = (searchString) => {
    this.searchString = searchString;
    if (this.searchString === '') {
      this.debouncedOnSearch && this.debouncedOnSearch.cancel();
      this.onSearchChange(searchString);
    } else {
      this.debouncedOnSearch(searchString);
    }
  }

  canDeleteSelectedCreatives (creativeIds: Array<number>) {
    if (creativeIds.length === 0) {
      return false;
    }

    const canDeleteCreatives = _.filter(this.creativesWithPagination?.creatives, creative => {
      const selected = creativeIds.indexOf(creative.creativeId) > -1;
      if (!selected) {
        return false;
      }
      return _.get(creative, 'bindingCampaign', []).length === 0;
    });

    return canDeleteCreatives.length === creativeIds.length;
  }

  sortCreatives = (field, direction) => {
    clearTimeout(this.sortTimeout);
    this.sortTimeout = setTimeout(async () => {
      if (this.sortField !== field || this.sortOrder !== direction) {
        this.handleSort(field, direction);
        this.init();
      }
    }, 0);
  }

  reviewCreative = async (adx, creativeId) => {
    this.updateState(true);
    try {
      await this.creativeManager.reviewCreatives([creativeId], [adx]);
      toast.success(i18n.t<string>('creativeList.labels.reviewSuccess'));
      this.updateState(false);
    } catch (e) {
      toast.success(i18n.t<string>('creativeList.labels.reviewFailed'));
      this.updateState();
    }
  }

  get state (): CreativeListState {
    return {
      loading: this.loading,
      selectedCreatives: this.selectedCreatives,
      creativeTypeToShow: this.creativeTypeToShow,
      viewModelData: this.viewModelData,
      summaryData: this.summaryData,
      pagination: this.pagination,
      modalData: this.modalData
    };
  }

  updateViewModelData () {
    if (!this.creativesWithPagination) {
      return this.viewModelData = [];
    }

    const data = this.creativesWithPagination.creatives.map(creative => {
      return {
        id: creative.creativeId,
        selected: this.selectedCreatives.indexOf(creative.creativeId) > -1,
        ...this.listDataProvider.getViewModel(creative)
      };
    });

    this.summaryData = this.getSummaryData();
    this.viewModelData = data;
    this.updateState();
  }

  getSummaryData () {
    const creativesCount = this.creativesWithPagination ? this.creativesWithPagination.pagination.totalCount : 0;
    return {
      id: 0,
      [CreativeListCommonColumns.NAME_ID]: i18n.t<string>('creativeList.labels.creativeCount', { count: creativesCount })
    };
  }

  getColumnsToRender () {
    return this.listDataProvider.getColumns();
  }

  changeCreativeTypeToRender = (creativeType: string | null, event?: any) => {
    event && event.stopPropagation();
    if (!creativeType) {
      return;
    }
    if (CreativeType[creativeType] === this.creativeTypeToShow) {
      return;
    }
    this.init(1, CreativeType[creativeType]);
  }

  async changeAdvertiser (advertiserId): Promise<void> {
    this.advertiserId = advertiserId;
    if (this.searchbarRef) {
      this.searchbarRef.current.clear();
    } else {
      await this.init(1);
    }
  }

  setSearchbarRef (ref) {
    this.searchbarRef = ref;
  }

  showCreativeDetailModal = (creative: CreativeListRecord) => {
    this.modalData = {
      title: creative.name,
      fullScreen: true,
      component: CreativeDetail,
      componentProps: {
        model: new DefaultCreativeDetailModel(creative)
      }
    };
    this.updateState();
  }

  hideModal = () => {
    this.modalData = undefined;
    this.updateState();
  }

  clearSelectedCreatives = () => {
    this.selectedCreatives = [];
    this.updateViewModelData();
  }

  showDeleteCreativeModal = (creativeIds: Array<number>, event?: any) => {
    event && event.stopPropagation();
    this.modalData = {
      title: i18n.t<string>('creativeList.labels.deleteModalTitle'),
      fullScreen: false,
      message: i18n.t<string>('creativeList.labels.deleteModalContent'),
      confirmBtnData: {
        title: i18n.t<string>('common.buttons.delete'),
        callback: this.deleteCreative.bind(this, creativeIds)
      }
    };
    this.updateState();
  }

  showCreativeBindingManage = (creative) => {
    const close = () => {
      this.modalData = undefined;
    };
    this.modalData = {
      title: i18n.t<string>('creativeList.labels.creativeBindingManage'),
      fullScreen: false,
      component: CreativeBindManagePage,
      componentProps: {
        bindingData: _.get(creative, 'bindingCampaign', []),
        callback: this.updateBindStateOfCampaigns.bind(this, creative.id),
        close: close
      }
    };
    this.updateState();
  }

  async updateBindState (enable: boolean, event?: any) {
    event && event.stopPropagation();
    this.updateState(true);
    try {
      await this.creativeManager.updateCreativeBindingStatus(this.selectedCreatives, enable);
      await this.init();
      toast.success(i18n.t<string>('creativeList.labels.updateBindStateSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState();
    }
  }

  async updateBindStateOfCampaigns (creativeId: number, campaignIds: Array<number>, isActive: boolean) {
    this.updateState(true);
    this.modalData = undefined;
    try {
      await this.creativeManager.updateCreativeBindingCampaigns(creativeId, campaignIds, isActive);
      await this.init();
      toast.success(i18n.t<string>('creativeList.labels.updateBindStateSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState();
    }
  }

  cloneCreatives = async (creatives: Array<number> | undefined) => {
    const creativesToClone = creatives ? creatives : this.selectedCreatives;
    creativesToClone.sort((a, b) => (a - b));
    this.updateState(true);
    try {
      await this.creativeManager.cloneCreatives(creativesToClone);
      await this.init();
      toast.success(i18n.t<string>('creativeList.labels.cloneCreativeSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  deleteCreative = async (creativeIds: Array<number>) => {
    this.updateState(true);
    this.modalData = undefined;
    try {
      await this.creativeManager.deleteCreative(creativeIds);
      await this.init(this.creativesWithPagination ? this.creativesWithPagination.pagination.page : 1);
      toast.success(i18n.t<string>('creativeList.labels.deleteCreativeSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  isCreativeTypeSupport = (creativeType) => {
    const target: any = `option_${creativeType}`;
    const creativeTypeSupported: boolean = this.addonFeatureManager.isFeatureEnable(target);
    switch (creativeType) {
      case CreativeType.OUTDOOR:
        return creativeTypeSupported && this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.EDIMAX);
      case CreativeType.RETAIL_NATIVE_PRODUCT:
      case CreativeType.RETAIL_RICH_MEDIA_PRODUCT:
        return creativeTypeSupported && this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CHANNEL.RETAIL_MEDIA);
      default:
        return creativeTypeSupported;
    }
  }

  addCache = (key: string, data: any) => {
    this.cache[key] = data;
  }

  getCache = (key: string) => {
    return this.cache[key];
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
