import { ChangeEvent, PropsWithChildren, useCallback } from 'react';
import styles from './selectableHeader.module.scss';
import { PermissionAware } from 'core';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';

export type SelectableHeaderProps = {
  selectedCount: number;
  allSelected: boolean;
  selectPermissionAware?: PermissionAware;
  onSelectPage?: (checked: boolean) => void;
};

export const SelectableHeader: React.FC<SelectableHeaderProps & PropsWithChildren> = ({
  selectedCount,
  allSelected,
  children,
  selectPermissionAware,
  onSelectPage
}) => {

  const onCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onSelectPage && onSelectPage(e.target.checked);
  }, [onSelectPage]);

  const renderCheckbox = useCallback(() => (
    <>
      <input
        type='checkbox'
        checked={allSelected}
        ref={el => el && (el.indeterminate = !allSelected && selectedCount > 0)}
        onChange={onCheckboxChange}
        id={'checkbox-input-all'}
      />
      <label htmlFor='checkbox-input-all' />
    </>
  ), [allSelected, selectedCount, onCheckboxChange]);

  return (
    <div className={styles.selectableHeader} key={allSelected.toString()}>
      {onSelectPage ?
        selectPermissionAware ?
          <PermissionChecker permissionAware={selectPermissionAware}>
            {renderCheckbox()}
          </PermissionChecker> :
          renderCheckbox() :
        null
      }
      {children}
    </div>
  );
};
