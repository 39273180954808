import { SegmentListDTO, SegmentFormDTO, SegmentDTO, SubSegmentFormDTO } from './Segment';
import { SegmentWebService, RestfulSegmentWebService } from 'ws/SegmentWebService';

export enum SegmentImportType {
  COOKIE = 'cookie',
  DEVICE_ID = 'deviceId'
}
export interface SegmentManager {
  getSegments (agencyId?: number): Promise<Array<SegmentListDTO>>;
  deleteSegment (segmentId: number): Promise<void>;
  getSegment (segmentId: string | number): Promise<SegmentDTO>;
  createSegment (segment: SegmentFormDTO): Promise<number>;
  updateSegment (segment: SegmentFormDTO): Promise<number>;
  createSubSegment (parentId: number, subSegment: SubSegmentFormDTO): Promise<void>;
  updateSubSegment (parentId: number, subSegment: SubSegmentFormDTO): Promise<void>;
  importAudiences (segmentId: number, importType: SegmentImportType, file: File): Promise<void>;
  reImportAudiences (segmentId: number): Promise<void>;
  exportAudiences (segmentId: number): Promise<void>;
}

export class DefaultSegmentManager implements SegmentManager {

  webService: SegmentWebService;

  constructor (
    webService: SegmentWebService = new RestfulSegmentWebService()
  ) {
    this.webService = webService;
  }

  async getSegments (agencyId?: number): Promise<Array<SegmentListDTO>> {
    return this.webService.getSegments(agencyId);
  }

  async deleteSegment (segmentId: number): Promise<void> {
    return this.webService.deleteSegment(segmentId);
  }

  async getSegment (segmentId: string | number): Promise<SegmentDTO> {
    return this.webService.getSegment(segmentId);
  }

  async createSegment (segment: SegmentFormDTO): Promise<number> {
    return this.webService.createSegment(segment);
  }

  async updateSegment (segment: SegmentFormDTO): Promise<number> {
    return this.webService.updateSegment(segment);
  }

  async createSubSegment (parentId: number, subSegment: SubSegmentFormDTO): Promise<void> {
    return this.webService.createSubSegment(parentId, subSegment);
  }

  async updateSubSegment (parentId: number, subSegment: SubSegmentFormDTO): Promise<void> {
    return this.webService.updateSubSegment(parentId, subSegment);
  }

  async importAudiences (segmentId: number, importType: SegmentImportType, file: File): Promise<void> {
    const formData = new FormData();
    formData.append('is_device', importType === SegmentImportType.DEVICE_ID ? 'true' : 'false');
    formData.append('audiences', file);
    return this.webService.importAudiences(segmentId, formData);
  }

  async reImportAudiences (segmentId: number): Promise<void> {
    return this.webService.reImportAudiences(segmentId);
  }

  async exportAudiences (segmentId: number): Promise<void> {
    return this.webService.exportAudiences(segmentId);
  }
}
