import i18n from 'i18n';
import _ from 'lodash';
import { getLimitationContentLabel, toServerStructure } from 'utils/LimitationUtil';
import {
  MessageCampaignWebService,
  RestfulMessageCampaignWebService
} from 'ws/MessageCampaignWebService';
import {
  MessageCampaign
} from './MessageCampaign';

export interface MessageCampaignManager {
  getCampaignsOfGroup (l1ObjectId: number | string): Promise<MessageCampaign[]>;
  getLimitationSummaryData (limitation: any): any;
  getCampaign (campaignId: string | number): Promise<MessageCampaign>;
  createCampaign (campaign: MessageCampaign, l1ObjectId: number | string): Promise<void>;
  updateCampaign (campaign: MessageCampaign, l1ObjectId: number | string): Promise<void>;
  deleteCampaigns (campaignIds: Array<number>): Promise<void>;
  updateCampaignState (campaignData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  fetchInvalidKeywords (message: string): Promise<{[key: number]: string[]}>;
}

export class DefaultMessageCampaignManager implements MessageCampaignManager {
  webService: MessageCampaignWebService;

  constructor (
    webService: MessageCampaignWebService = new RestfulMessageCampaignWebService()
  ) {
    this.webService = webService;
  }

  getLimitationContent = (limitationData) => {
    if (!limitationData || _.size(limitationData) === 0) {
      return [];
    }

    return _.compact(limitationData.map((data) => {
      if (_.defaultTo(data.value, []).length === 0) {
        return undefined;
      }
      const labelKey = `limitation.labels.${data.type}`;
      return {
        label: i18n.exists(labelKey) ? i18n.t<string>(labelKey) : data.type,
        value: data.value.map((value) => getLimitationContentLabel(data.type, value)).join(', ')
      };
    }));
  }

  getLimitationSummaryData (limitationData: any) {
    return _.omitBy({
      include: this.getLimitationContent(limitationData.include),
      preferred: this.getLimitationContent(limitationData.preferred),
      nonPreferred: this.getLimitationContent(limitationData.nonPreferred),
      exclude: this.getLimitationContent(limitationData.exclude),
      other: limitationData.other ? this.getLimitationContent(limitationData.other) : undefined
    }, _.isEmpty);
  }

  wrapCampaignForServer = (campaign: MessageCampaign) => {
    let result: any = {
      campaign: {
        ..._.omit(campaign.basic, ['id', 'tags']),
        tags: campaign.basic.tags.join(','),
        budget: campaign.basic.budget,
        campaignId: campaign.basic.id,
        optimize: campaign.basic.optimize,
        message: campaign.basic.message,
        clickUrl: campaign.basic.clickUrl
      },
      limitations: toServerStructure({
        ...campaign.limitations
      }).map(limitation => ({ ...limitation, type: `pic_${limitation.type}` }))
    };
    return result;
  }

  prepareCreateCampaignPayload (campaign: MessageCampaign) {
    const prepareCreate = _.flow([_.cloneDeep, this.wrapCampaignForServer]);
    return prepareCreate(campaign);
  }

  async deleteCampaigns (campaignIds: number[]): Promise<void> {
    await this.webService.deleteCampaigns(campaignIds);
  }

  async updateCampaignState (campaignData: { l2ChannelId: string | number; isDraft: boolean; }[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.webService.updateCampaignState(campaignData, state);
  }

  async getCampaignsOfGroup (l1ObjectId: number | string): Promise<MessageCampaign[]> {
    return this.webService.getCampaignsOfGroup(l1ObjectId);
  }

  async getCampaign (campaignId: string | number): Promise<MessageCampaign> {
    return this.webService.getCampaign(campaignId);
  }

  async createCampaign (campaign: MessageCampaign, l1ObjectId: number | string): Promise<void> {
    const payload = this.prepareCreateCampaignPayload(campaign);
    _.set(payload, 'campaign.l1ObjectId', l1ObjectId);
    await this.webService.createCampaign(payload, l1ObjectId);
  }

  async updateCampaign (campaign: MessageCampaign, l1ObjectId: number | string): Promise<void> {
    const payload = this.wrapCampaignForServer(_.cloneDeep(campaign));
    _.set(payload, 'campaign.l1ObjectId', l1ObjectId);
    await this.webService.updateCampaign(payload, l1ObjectId);
  }

  async fetchInvalidKeywords (message: string): Promise<{[key: number]: string[]}> {
    return this.webService.fetchInvalidKeywords(message);
  }
}
