import { Agency, AgencyManager, AgencyWithPagination } from 'core';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { SearchBarModel } from 'components/common/SearchBar';
import { debounce } from 'lodash';
import { Pagination } from 'core/pagination/Pagination';
import { AgencyListColumns } from 'components/AgencyList';

export type AgenciesHomeState = {

  readonly loading: boolean;
  readonly error: Error | null;
  readonly agencies: Array<Agency>;
  readonly pagination: Pagination;
  readonly sortField: string;
  readonly sortOrder: 'asc' | 'desc';
  readonly searchString: string;
};

export interface AgenciesHomeModel extends SearchBarModel {

  readonly state: AgenciesHomeState;
  readonly event: UpdateEventListener<AgenciesHomeModel>;

  fetch (): Promise<void>;
  onAgencyListChange (type: string, props: any): void;
}

export type AgenciesHomeProps = {

  readonly model: AgenciesHomeModel;
};

export class DefaultAgenciesHomeModel implements AgenciesHomeModel {
  loading: boolean;
  error: Error | null;
  agencies: Array<Agency>;
  pagination: Pagination;
  sortField: string;
  sortOrder: 'asc' | 'desc';
  searchString: string;
  debouncedFetch: any;

  agencyManager: AgencyManager;
  event: FireableUpdateEventListener<AgenciesHomeModel>;

  constructor (agencyManager: AgencyManager) {
    this.loading = false;
    this.error = null;
    this.agencies = [];
    this.pagination = {
      page: 1,
      size: 10,
      totalCount: 0
    };
    this.sortField = AgencyListColumns.AGENCY_ID;
    this.sortOrder = 'asc';
    this.searchString = '';
    this.debouncedFetch = debounce(this.fetch.bind(this), 1000);
    this.agencyManager = agencyManager;
    this.event = new FireableUpdateEventListener<AgenciesHomeModel>();
  }

  get placeholder (): string {
    return 'agencies.home.searchPlaceholder';
  }

  get state (): AgenciesHomeState {
    return {
      error: this.error,
      loading: this.loading,
      agencies: this.agencies,
      pagination: this.pagination,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      searchString: this.searchString
    };
  }

  async fetch (page: number | undefined = 1): Promise<void> {
    this.notify(true);
    try {
      const pageable = {
        page,
        sizePerPage: 10,
        sort: this.sortField,
        direction: this.sortOrder
      };
      const result: AgencyWithPagination = await this.agencyManager.getAgenciesWithPagination(pageable, this.searchString);
      this.pagination = result.pagination;
      this.agencies = result.agencies;
      this.notify(false);
    } catch (error: any) {
      this.notify(false, error);
    }
  }

  search (keyword: string) {
    this.searchString = keyword;
    if (this.searchString === '') {
      this.debouncedFetch && this.debouncedFetch.cancel();
      this.fetch();
    } else {
      this.debouncedFetch();
    }
  }

  onSort = async (field, direction) => {
    if (field === this.sortField && direction === this.sortOrder) {
      return;
    }
    this.sortField = field;
    this.sortOrder = direction;
    await this.fetch(1);
  }

  onChangePage = async (page: number) => {
    if (page === this.pagination.page) {
      return;
    }
    this.pagination.page = page;
    await this.fetch(page);
  }

  notify (loading: boolean, error: Error | null = null) {
    this.error = error;
    this.loading = loading;
    this.event.fireEvent(this);
  }

  onAgencyListChange = async (type: string, props) => {
    if (type === 'sort') {
      await this.onSort(props.sortField, props.sortOrder);
      return;
    }
    await this.onChangePage(props.page);
  }
}
