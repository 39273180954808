import {
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { SupportVideoViewsFormModel } from './SupportVideoViewsFormModel';

export interface VideoFormModel extends RtbCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}

abstract class AbstractVideoFormModel extends SupportVideoViewsFormModel
  implements VideoFormModel {

  showVideoProgressRadio = false;

  getBidPriceFloorData (): any {
    const data = this.order.campaignBidPrice.find(data => data.type === AdType.VIDEO);
    return data ? data.bidFloor : {};
  }

  get campaignAdType (): AdType {
    return AdType.VIDEO;
  }

  needVideoAdViewObjective (priceModel: RtbCampaignPlanType): boolean {
    return priceModel === RtbCampaignPlanType.FCPV;
  }
}

export class EditVideoFormModel extends AbstractVideoFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }

  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateVideoFormModel extends AbstractVideoFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
