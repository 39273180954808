import _ from 'lodash';
import { CreativeListDataProvider } from './CreativeListDataProvider';
import { CreativeListCommonColumns, commonColumnSetting, getColumnSetting, getCommonColumnData } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn/TableColumn';
import { getCreativeListFormatters } from './CreativeListFormatters';
import { CreativeListRecord } from 'core/creative/Creative';
import i18n from 'i18n';

enum COLUMNS {
  AD_TITLE = 'adTitle',
  AD_DESC = 'adDesc',
  AD_MESSAGE = 'message',
  AD_SHORT_MESSAGE = 'shortMessage',
  THUMBNAIL_URL = 'thumbnailUrl',
  FB_LANDING_URL = 'fbClickUrl',
  ONE_FOR_ALL_LANDING_URL = 'oneForAllClickUrl'
}

export class OneForAllVideoCreativeListDataProvider implements CreativeListDataProvider {

  formatters: any;
  constructor (model) {
    this.formatters = getCreativeListFormatters(model);
  }

  getColumns () {
    const textFieldFormatter = value =>
      this.formatters.textFormatter(!value || value.length === 0 ? i18n.t<string>('common.labels.noData') : value);
    return [
      renderColumn(commonColumnSetting[CreativeListCommonColumns.NAME_ID], this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.PREVIEW], this.formatters.previewFormatter),
      renderColumn(getColumnSetting(COLUMNS.AD_TITLE), textFieldFormatter),
      renderColumn(getColumnSetting(COLUMNS.AD_MESSAGE), textFieldFormatter),
      renderColumn(getColumnSetting(COLUMNS.AD_SHORT_MESSAGE), textFieldFormatter),
      renderColumn(getColumnSetting(COLUMNS.AD_DESC), textFieldFormatter),
      renderColumn(getColumnSetting(COLUMNS.ONE_FOR_ALL_LANDING_URL), this.formatters.urlFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LIMITATION], this.formatters.limitationFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }

  getViewModel (creative: CreativeListRecord) {
    return _.omitBy({
      ...getCommonColumnData(creative),
      [COLUMNS.AD_TITLE]: _.get(creative, 'bannerExtra.title', ''),
      [COLUMNS.AD_MESSAGE]: _.get(creative, 'bannerExtra.message', ''),
      [COLUMNS.AD_SHORT_MESSAGE]: _.get(creative, 'bannerExtra.shortMessage', ''),
      [COLUMNS.AD_DESC]: _.get(creative, 'bannerExtra.description', ''),
      [COLUMNS.THUMBNAIL_URL]: _.get(creative, 'bannerExtra.thumbnailUrl', ''),
      [COLUMNS.ONE_FOR_ALL_LANDING_URL]: _.get(creative, 'bannerExtra.fbLandingUrl', ''),
      // [CreativeListCommonColumns.BIND_STATE]: undefined,
      // [CreativeListCommonColumns.LIMITATION]: undefined,
      [CreativeListCommonColumns.LANDING_URL]: undefined
    }, _.isUndefined);
  }
}
