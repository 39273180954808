import {
  ReportWebService,
  RestfulReportWebService
} from 'ws/ReportWebService';

import { SelectOptions } from 'components/common/commonType';
import { FinalReportResponseStatus } from 'core/finalReport/FinalReportStatus';
import { Order } from 'core/order/Order';
import { isNil, omitBy } from 'lodash';
import { ReportCompareData, ReportCompareDimension, ReportData, ReportDimension, ReportGran, ReportType } from './ReportData';

export interface ReportManager {
  downloadOrderReport (order: {
    name: string,
    orderNumber: string
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void>;
  downloadL1ObjectReport (order: {
    name: string,
    l1ObjectId: number
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void>;
  downloadVideoReport (order: Order, startDate: string, endDate: string): Promise<void>;
  downloadOutdoorReport (order: Order, startDate: string, endDate: string): Promise<void>;
  downloadClosingReport (order: Order, reportType?: string): Promise<void>;
  downloadRebateReport (date: string, agencyId: string | number): Promise<void>;
  getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string, useAppsflyerConversion?: boolean): Promise<ReportData>;
  getReportCompareData (compareDimension: ReportCompareDimension, filter: any, from: string, to: string): Promise<ReportCompareData>;
  uploadFinalReportData (orderId: string | number): Promise<FinalReportResponseStatus>;
  getFinalReportData (reportId: string | number): Promise<FinalReportResponseStatus>;
  downloadOrderReportRawData (orderId: string | number, startDate: string, endDate: string): Promise<void>;
  getFilterOptions (
    type: ReportType,
    gran: ReportGran,
    dimension: string,
    currentFilters: {
      [key: string]: string | number
    }
  ): Promise<SelectOptions[]>;
}

export class DefaultReportManager implements ReportManager {
  webService: ReportWebService;

  constructor (
    webService: ReportWebService = new RestfulReportWebService()
  ) {
    this.webService = webService;
  }
  async downloadOrderReport (order: {
    name: string,
    orderNumber: string
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void> {
    await this.webService.downloadOrderReport(order, startDate, endDate, groupBy, selectFields);
  }
  async downloadL1ObjectReport (l1Object: {
    name: string,
    l1ObjectId: number
  }, startDate: string, endDate: string, groupBy: string, selectFields: string[]): Promise<void> {
    await this.webService.downloadL1ObjectReport(l1Object, startDate, endDate, groupBy, selectFields);
  }
  async downloadVideoReport (order: Order, startDate: string, endDate: string): Promise<void> {
    await this.webService.downloadVideoReport(order, startDate, endDate);
  }
  async downloadOutdoorReport (order: Order, startDate: string, endDate: string): Promise<void> {
    await this.webService.downloadOutdoorReport(order, startDate, endDate);
  }
  async downloadClosingReport (order: Order, reportType?: string): Promise<void> {
    await this.webService.downloadClosingReport(order, reportType);
  }
  async downloadRebateReport (date: string, agencyId?: string | number): Promise<void> {
    await this.webService.downloadRebateReport(date, agencyId);
  }
  async getReportData (type: ReportType, dimension: ReportDimension, gran: ReportGran, filter: any, from: string, to: string, useAppsflyerConversion?: boolean) {
    return this.webService.getReportData(type, dimension, gran, filter, from, to, useAppsflyerConversion);
  }
  async getReportCompareData (compareDimension: ReportCompareDimension, filter: any, from: string, to: string): Promise<ReportCompareData> {
    return this.webService.getReportCompareData(compareDimension, filter, from, to);
  }
  async uploadFinalReportData (orderId: string | number): Promise<FinalReportResponseStatus> {
    return this.webService.uploadFinalReportData(orderId);
  }
  async getFinalReportData (reportId: string | number): Promise<FinalReportResponseStatus> {
    return this.webService.getFinalReportData(reportId);
  }

  async downloadOrderReportRawData (orderId: string | number, startDate: string, endDate: string): Promise<void> {
    return this.webService.downloadOrderReportRawData(orderId, startDate, endDate);
  }

  async getFilterOptions (
    type: ReportType,
    gran: ReportGran,
    dimension: string,
    currentFilters: {
      [key: string]: string | number
    }
  ): Promise<SelectOptions[]> {
    let queryType;
    switch (type) {
      case ReportType.MESSAGE:
        queryType = 'MESSAGE';
        break;
      case ReportType.VIDEO:
        queryType = 'VIDEO';
        break;
      default:
        queryType = 'PERFORMANCE';
        break;
    }
    return this.webService.getFilterOptions(
      queryType,
      gran === ReportGran.HOUR ? 'hour' : 'daily',
      dimension,
      omitBy(currentFilters, isNil)
    );
  }
}
