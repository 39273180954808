import { AxiosInstance } from 'axios';
import { MessageCampaign } from 'core/messageCampaign/MessageCampaign';
import _ from 'lodash';
import { wrapLimitationsFromServer } from 'utils/LimitationUtil';
import client from './RestClient';

export type CreateCampaignRequest = {
  readonly campaign: any;
  readonly limitations: any;
};

export type UpdateCampaignRequest = {
  readonly campaign: any;
  readonly limitations: any;
};

export interface MessageCampaignWebService {
  getCampaignsOfGroup (l1ObjectId: number | string): Promise<MessageCampaign[]>;
  getCampaign (campaignId: number | string): Promise<MessageCampaign>;
  createCampaign (body: any, l1ObjectId: number | string): Promise<number>;
  updateCampaign (body: any, l1ObjectId: number | string): Promise<number>;
  updateCampaignState (campaignData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  deleteCampaigns (campaignIds: Array<number>): Promise<void>;
  fetchInvalidKeywords (message: string): Promise<{[key: number]: string[]}>;
}

function wrapTags (tags?: string | string[]): string[] {
  if (!tags) {
    return [];
  }
  if (Array.isArray(tags)) {
    return tags;
  }

  return tags.split(',');
}

function wrapBasic (json: any): any {
  const orderPrice = _.get(json, 'orderPrice');
  const report = _.get(json, 'report', {});
  return {
    id: _.get(json, 'campaignId', _.get(json, 'goganCampaignId')),
    name: _.get(json, 'name'),
    advertiserId: _.get(json, 'advertiserId'),
    orderId: _.get(json, 'orderId'),
    budget: _.get(json, 'budget', 0),
    startDate: _.get(json, 'startDate'),
    createDate: _.get(json, 'createDate'),
    message: _.get(json, 'message'),
    clickUrl: _.get(json, 'clickUrl'),
    priceModel: _.get(json, 'priceModel'),
    orderPrice,
    optimize: _.get(json, 'optimize'),
    bidPrice: _.get(json, 'bidPrice'),
    state: _.get(json, 'state'),
    effectiveStatus: _.get(json, 'effectiveStatus'),
    currencyRate: _.get(json, 'currencyRate'),
    expectedSpent: _.get(json, 'expectedSpent', 0),
    spents: _.get(report, 'spent', 0),
    impres: _.get(report, 'impres', 0),
    viewable: _.get(report, 'viewable', 0),
    clicks: _.get(report, 'clicks', 0),
    adType: _.get(json, 'adType'),
    tags: wrapTags(_.get(json, 'tags')),
    orderPriceEnable: orderPrice === null || orderPrice === undefined ? false : true
  };
}

export function wrapCampaign (json: any): MessageCampaign {
  const limitations = wrapLimitationsFromServer(_.defaultTo(json.limitations, json.limitation));
  Object.values(limitations).forEach((limitationsOfOp) => {
    limitationsOfOp.forEach((limitation) => {
      limitation.type = limitation.type.replace('pic_', '');
    });
  });

  return {
    basic: wrapBasic(_.get(json, 'campaign')),
    limitations
  };
}

function wrapCampaignList (json: any): Array<MessageCampaign> {
  return _.defaultTo(json, []).flatMap((json: any) => wrapCampaign({
    campaign: _.omit(json, ['limitations', 'limitation']),
    limitations: _.defaultTo(json.limitations, json.limitation)
  }));
}

export class RestfulMessageCampaignWebService implements MessageCampaignWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getCampaignsOfGroup (l1ObjectId: number | string): Promise<MessageCampaign[]> {
    const response = await this.restClient.get(`/v2/l1-objects/${l1ObjectId}/message-campaigns`);
    return wrapCampaignList(response.data.records);
  }

  async getCampaign (campaignId: number | string): Promise<MessageCampaign> {
    const response = await this.restClient.get(`/v2/message-campaigns/${campaignId}`);
    return wrapCampaign(response.data);
  }

  async createCampaign (body: CreateCampaignRequest, l1ObjectId: number | string): Promise<number> {
    const response = await this.restClient.post(`/v2/l1-objects/${l1ObjectId}/message-campaigns`, body);
    console.log('create message campaign', response);
    return _.get(response.data, 'campaign.campaignId', 0);
  }

  async updateCampaign (body: UpdateCampaignRequest, l1ObjectId: number | string): Promise<number> {
    let path = `/v2/l1-objects/${l1ObjectId}/message-campaigns/${_.get(body, 'campaign.campaignId')}`;
    const response = await this.restClient.put(path, body);
    console.log('update message campaign', response);
    return _.get(response.data, 'campaign.campaignId', 0);
  }

  async updateCampaignState (campaignData: {
    l2ChannelId: (number | string);
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    await this.restClient.put(`/v2/message-campaigns/${state}`, campaignData);
  }

  async deleteCampaigns (campaignIds: Array<number>): Promise<void> {
    await this.restClient.delete(`/v2/message-campaigns?campaignIds=${campaignIds.join(',')}`);
  }

  async fetchInvalidKeywords (message: string): Promise<{[key: number]: string[]}> {
    const response = await this.restClient.post(`/v2/message-campaigns/validate`, message);
    return response.data;
  }
}
