import { SelectOptions } from 'components/common/commonType';
import i18n from 'i18n';
export function selectOptionsAddI18n (
  selectOptions: SelectOptions,
  prefix?: string[]
) {
  if (!selectOptions) {
    return {};
  }
  const { label, value, options } = selectOptions;
  let labelI18n = i18n.t<string>(label);
  if (prefix && prefix.length > 0) {
    labelI18n = i18n.t<string>(prefix.map(p => `${p}${label}`));
  }
  return {
    label: labelI18n,
    value,
    options:
      options && options.map(option => selectOptionsAddI18n(option, prefix))
  };
}

export function generateI18nOfSelectOptions (
  selectOptions: SelectOptions,
  prefix: string,
  formatter?: (value: any) => any
) {
  if (!selectOptions) {
    return;
  }
  const { label, value, options } = selectOptions;
  const finalValue = formatter ? formatter(value) : value;
  const key = `${prefix}.${finalValue}`;
  i18n.addResource('en', 'translation', key, label);
  i18n.addResource('zh-TW', 'translation', key, label);
  options && options.map(option => generateI18nOfSelectOptions(option, prefix, formatter));
}
