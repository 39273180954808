import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { OutdoorFormProps } from './OutdoorFormModel';
import styles from './outdoorForm.module.scss';
import _, { defaultTo, get } from 'lodash';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { OutdoorType } from 'core/creative/Creative';
import { faFileArchive, faFileAudio } from '@fortawesome/free-regular-svg-icons';
import { FormConfig } from 'components/common/form/FormConfig';
import config from 'config';
import { Trans } from 'react-i18next';
import { FormContent } from 'components/common/form/Form';

export const OutdoorForm: React.FunctionComponent<OutdoorFormProps & { formik: FormikContextType<any> }> = (props) => {

  const [formConfig, setFormConfig] = useState<FormConfig>(props.getInitFormConfig());

  const renderTemperatureRange = useCallback((props) => {
    const onChange = (values: number[]) => {
      props.form.setFieldValue(props.field.name, values);
      props.form.setFieldTouched(props.field.name);
    };
    const min = 0;
    const max = 40;
    const marks = { [min]: `${min}°C`, [max]: `${max}°C` };
    if (props.field.value) {
      props.field.value.forEach(temp => {
        marks[temp] = `${temp}°C`;
      });
    }
    return (
      <div style={{ width: 400, margin: 50 }}>
        <Range
          trackStyle={[{ backgroundColor: '#5845a5' }]}
          handleStyle={[{ borderColor: '#5845a5' }, { borderColor: '#5845a5' }]}
          min={min}
          max={max}
          step={0.5}
          allowCross={false}
          defaultValue={props.field.value}
          marks={marks}
          onChange={onChange}
        />
      </div>
    );
  }, []);

  const { values, setFieldValue, setValues } = props.formik;
  const outdoorType = _.get(values, 'typeProperties.outdoorType');
  const isEditing = !!values.creativeId;
  const useAudio = _.get(values, 'typeProperties.useAudio');
  const temperatureEnable = _.get(values, 'typeProperties.temperatureEnable');

  const onUseAudioChange = useCallback(() => {
    setFieldValue('typeProperties.duration', 30);
  }, [setFieldValue]);

  const getNewDuration = useCallback((newType, oldDuration) => {
    if (outdoorType === OutdoorType.HTML5 ||
      [OutdoorType.IMAGE_AUDIO, OutdoorType.HTML5].includes(newType)
    ) {
      return 30;
    }

    return oldDuration;
  }, [outdoorType]);

  const onChangeOutdoorType = useCallback((type) => {
    setValues(prev => {
      const oldImage = get(prev, 'medias.image');
      const oldVideo = get(prev, 'medias.video');
      const oldAudio = get(prev, 'medias.audio');
      const oldDuration = get(prev, 'typeProperties.duration');
      let newValue = {
        ...prev,
        typeProperties: {
          ...defaultTo(prev.typeProperties, {}),
          duration: getNewDuration(type, oldDuration)
        },
        medias: {
          ...defaultTo(prev.medias, {}),
          image: oldImage ? {
            ...oldImage,
            file: undefined
          } : undefined,
          video: oldVideo ? {
            ...oldVideo,
            file: undefined
          } : undefined,
          audio: oldAudio ? {
            ...oldAudio,
            file: undefined
          } : undefined
        }
      };
      return newValue;
    });
  }, [setValues, getNewDuration]);

  useEffect(() => {
    const showVideo = (outdoorType === OutdoorType.VIDEO || outdoorType === OutdoorType.VIDEO_IMAGE);
    const showDurationLabel = isEditing ||
      (outdoorType === OutdoorType.IMAGE_AUDIO && !useAudio);
    const showDurationInput = outdoorType === OutdoorType.HTML5;
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...props.basicFields])
          .addFormikLabel({
            label: i18n.t<string>('creativeSetupFlow.labels.outdoorType'),
            name: 'typeProperties.outdoorType',
            formatter: value => i18n.t<string>(`outdoorForm.labels.outdoorType${value}`)
          }, !isEditing)
          .addFormikSelect({
            label: i18n.t<string>('creativeSetupFlow.labels.outdoorType'),
            name: 'typeProperties.outdoorType',
            simpleValue: true,
            options: [{
              label: i18n.t<string>('outdoorForm.labels.outdoorType1'),
              value: OutdoorType.VIDEO
            }, {
              label: i18n.t<string>('outdoorForm.labels.outdoorType2'),
              value: OutdoorType.IMAGE_AUDIO
            }, {
              label: i18n.t<string>('outdoorForm.labels.outdoorType3'),
              value: OutdoorType.VIDEO_IMAGE
            }, {
              label: i18n.t<string>('outdoorForm.labels.outdoorType4'),
              value: OutdoorType.HTML5
            }],
            onChange: onChangeOutdoorType
          }, isEditing)
          .addFormikSwitch({
            label: i18n.t<string>('creativeSetupFlow.labels.temperatureRange'),
            name: 'typeProperties.temperatureEnable',
            hoverHint: i18n.t<string>('outdoorForm.labels.temperatureHint'),
            className: styles.temperatureSwitch
          })
          .addFormikCustom({
            label: '',
            name: 'typeProperties.temperatureRange',
            render: renderTemperatureRange
          }, !temperatureEnable)
          .addFormikFileInput({
            type: 'video',
            label: i18n.t<string>('videoForm.labels.tenMaxVideoInput'),
            name: 'medias.video',
            maxWidth: 270,
            maxHeight: 500,
            hints: [
              i18n.t<string>('creativeSetupFlow.labels.videoTypeHint'),
              i18n.t<string>('outdoorForm.labels.videoSizeHint', { proportion: outdoorType === OutdoorType.VIDEO ? '9:16' : '16:9' }),
              i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '490MB' })
            ],
            validate: value => props.model.validateVideo(outdoorType, value)
          }, !showVideo)
          .addFormikFileInput({
            type: 'image',
            label: i18n.t<string>('creativeSetupFlow.labels.image'),
            name: 'medias.image',
            className: styles.imageInput,
            maxWidth: 270,
            hints: [
              i18n.t<string>('creativeSetupFlow.labels.typeHint'),
              i18n.t<string>('creativeSetupFlow.labels.imageSizeHint', { size: '1080 x 1313' }),
              i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
            ],
            validate: props.model.validateVideoImage
          }, outdoorType !== OutdoorType.VIDEO_IMAGE)
          .addFormikFileInput({
            type: 'other',
            label: i18n.t<string>('creativeSetupFlow.labels.outdoorHtml'),
            name: 'medias.html',
            className: styles.fileInput,
            icon: faFileArchive,
            hints: [
              i18n.t<string>('html5Form.labels.typeHint'),
              i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
            ],
            permanentHint: (
              <span className={styles.hint}>
                <Trans i18nKey='html5Form.labels.fileHint'>
                  ...<a href={`${config.cloudStorageConfig.path}index.html.zip`} download>Click here</a>...
                </Trans>
              </span>
            ),
            validate: props.model.validateZip
          }, outdoorType !== OutdoorType.HTML5)
          .addFormikFileInput({
            type: 'image',
            label: i18n.t<string>('creativeSetupFlow.labels.image'),
            name: 'medias.image',
            className: styles.audioImageInput,
            maxWidth: 270,
            hints: [
              i18n.t<string>('creativeSetupFlow.labels.typeHint'),
              i18n.t<string>('creativeSetupFlow.labels.imageSizeHint', { size: '1080 x 1920' }),
              i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
            ],
            validate: props.model.validateAudioImage
          }, outdoorType !== OutdoorType.IMAGE_AUDIO)
          .addFormikSwitch({
            label: i18n.t<string>('creativeSetupFlow.labels.outdoorAudioSwitch'),
            name: 'typeProperties.useAudio',
            onChange: onUseAudioChange
          }, outdoorType !== OutdoorType.IMAGE_AUDIO)
          .addFormikFileInput({
            type: 'audio',
            label: i18n.t<string>('creativeSetupFlow.labels.audio'),
            name: 'medias.audio',
            className: styles.fileInput,
            icon: faFileAudio,
            validTypes: ['audio/mpeg'],
            hints: [
              i18n.t<string>('creativeSetupFlow.labels.audioTypeHint'),
              i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
            ],
            validate: props.model.validateAudio
          }, !useAudio || outdoorType !== OutdoorType.IMAGE_AUDIO)
          .addFormikLabel({
            label: i18n.t<string>('creativeSetupFlow.labels.outdoorDuration'),
            name: 'typeProperties.duration',
            formatter: value => i18n.t<string>(`outdoorForm.labels.duration${value}`)
          }, !showDurationLabel || showDurationInput)
          .addFormikSelect({
            label: i18n.t<string>('creativeSetupFlow.labels.outdoorDuration'),
            name: 'typeProperties.duration',
            simpleValue: true,
            disabled: isEditing,
            options: [{
              label: i18n.t<string>('outdoorForm.labels.duration30'),
              value: 30
            }, {
              label: i18n.t<string>('outdoorForm.labels.duration0'),
              value: 0
            }]
          }, showDurationLabel || showDurationInput)
          .addFormikInputGroup({
            label: i18n.t<string>('creativeSetupFlow.labels.outdoorDuration'),
            name: 'typeProperties.duration',
            type: 'number',
            fieldContentWidth: 'auto',
            postText: i18n.t<string>('common.units.seconds')
          }, !showDurationInput)
          .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      ).build()
    );
  }, [
    isEditing,
    useAudio,
    outdoorType,
    props.basicFields,
    temperatureEnable,
    onUseAudioChange,
    onChangeOutdoorType,
    renderTemperatureRange,
    props.model.validateVideo,
    props.model.validateVideoImage,
    props.model.validateAudioImage,
    props.model.validateZip,
    props.model.validateAudio,
    props.model
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(OutdoorForm);
