
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { Limitation } from 'core/limitation/Limitation';
import { AdType, CampaignState } from 'core/rtbCampaign/RtbCampaign';

export type MessageCampaign = {
  basic: MessageCampaignBasic;
  limitations: { [type: string]: Limitation[] };
  report?: any
};

export enum MessageCampaignPlanType {
  FIXED_VIEWABLE_IMPRESSION = 'fixed_viewable_impression'
}

export enum MessageCampaignStatus {
  DRAFT = 'DRAFT',
  SCHEDULED = 'SCHEDULED',
  RUNNING = 'RUNNING',
  PENDING = 'PENDING',
  REPLENISH = 'REPLENISH',
  FINISHED = 'FINISHED',
  ABNORMAL = 'ABNORMAL',
  ABANDON = 'ABANDON'
}

export type MessageCampaignBasic = {
  id?: number;
  name: string;
  advertiserId: number;
  orderId: number;
  budget: number;
  startDate: string;
  createDate?: string;
  priceModel: MessageCampaignPlanType;
  orderPrice?: number;
  optimize: L2ObjectOptimizationGoal;
  state?: CampaignState;
  effectiveStatus?: string;
  spents?: number;
  currencyRate?: number;
  expectedSpent: number;
  adType?: AdType;
  tags: Array<string>;
  bindings?: {
    bindingId: string,
    active: boolean
  },
  message?: string;
  clickUrl?: string;
  isDraft?: boolean;
  draftId?: number;
};

export const MIN_HOUR = 8;
export const MAX_HOUR = 17;
