import React from 'react';
import styles from './creditList.module.scss';
import { Link } from 'react-router-dom';

const nameFormatter = (id: any, { com_name }: { agency_id: number, com_name: string }): any => {
  return (
    <div className={`${styles.nameCell}`}>
      <Link to={`/reports/credits/${id}`} className={styles.name}>
        {com_name}
      </Link>
      <div className={styles.id}>
        {'ID: ' + id}
      </div>
    </div>
  );
};

const formatters = {
  nameFormatter
};
export default formatters;
