import {
  RtbCampaignBasicFormModel,
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { ADDONFEATURE } from 'core/agency/AddonFeature';

export interface CtvFormModel extends RtbCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}

abstract class AbstractCtvFormModel extends DefaultRtbCampaignBasicFormModel
  implements CtvFormModel {

  showVideoProgressRadio = false;

  getRtbOptionsMap () {
    const enableOptimizedPrice = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE);
    return {
      [L1ObjectObjective.AWARENESS]: {
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPM]: enableOptimizedPrice ? [
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ] : [
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPV]: [
          L2ObjectOptimizationGoal.VIDEO_VIEWS
        ]
      },
      [L1ObjectObjective.TRAFFIC]: {},
      [L1ObjectObjective.SALES]: {},
      [L1ObjectObjective.UNSPECIFIED]: {
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPV]: [
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPM]: [
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        rb: [
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      }
    };
  }

  getBidPriceFloorData (): any {
    const data = this.order.campaignBidPrice.find(data => data.type === AdType.CTV);
    return data ? data.bidFloor : {};
  }

  get campaignAdType (): AdType {
    return AdType.CTV;
  }
}

export class EditCtvFormModel extends AbstractCtvFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }

  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateCtvFormModel extends AbstractCtvFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
