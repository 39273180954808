import { ColumnDefinition } from 'components/TableColumn/TableColumn';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { SegmentManager, DefaultSegmentManager, SegmentImportType } from 'core/segment/SegmentManager';
import { SegmentDTO } from 'core/segment/Segment';
import styles from './subSegmentList.module.scss';
import i18n from 'i18next';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { numberWithCommas } from 'utils/StringUtil';
import { SortableList, AbstractSortableList } from 'containers/Common/AbstractSortableList';

export type SubSegmentListState = {
  readonly viewModel: any;
  readonly loading: boolean;
  readonly modalData: any;
  readonly needUpdateViewModelData: boolean;
};

export interface SubSegmentListModel extends SortableList {
  readonly parentId: number;
  readonly event: UpdateEventListener<SubSegmentListModel>;
  readonly state: SubSegmentListState;
  readonly searchString: string;
  getColumnDefinition (columnName): ColumnDefinition;
  handleOnSearch (searchString): void;
  onUnmount (handler): void;
  showDeleteModal (SubSegmentId: number): void;
  hideModal (): void;
  importAudiences (segmentId: number, file: File, callback: () => void): Promise<void>;
  exportAudiences (segmentId: number): Promise<void>;
  updateModelData (subSegments: Array<SegmentDTO>);
  updateViewModelData ();
}

export enum SubSegmentListColumns {
  NAME = 'nameIdColumn',
  DMP_SEGMENT_ID = 'dmpSegmentIdColumn',
  SEGMENT_COUNT = 'segmentCountColumn',
  RULE_CONTENT = 'ruleContentColumn',
  EDITBTNS = 'editBtnsColumn'
}

export type SubSegmentListProps = {
  readonly model: SubSegmentListModel;
};

export const IMPORT_FILE_SIZE_MAXIMUM = 5242880;

export class DefaultSubSegmentListModel extends AbstractSortableList implements SubSegmentListModel {
  loading: boolean;
  parentId: number;
  manager: SegmentManager;
  event: FireableUpdateEventListener<SubSegmentListModel>;
  subSegments: Array<SegmentDTO>;
  searchString: string;
  viewModel: any;
  modalData: any;
  onSubSegmentChange: () => void;
  needUpdateViewModelData: boolean;

  constructor (parentId: number, subSegments: Array<SegmentDTO>, onSubSegmentChange: () => void, manager: SegmentManager = new DefaultSegmentManager()) {
    super(SubSegmentListColumns.NAME, 'desc');
    this.parentId = parentId;
    this.manager = manager;
    this.loading = false;
    this.event = new FireableUpdateEventListener<SubSegmentListModel>();
    this.searchString = '';
    this.subSegments = subSegments;
    this.onSubSegmentChange = onSubSegmentChange;
    this.updateViewModelData();
    this.needUpdateViewModelData = false;
  }

  get state () {
    return {
      viewModel: this.viewModel,
      loading: this.loading,
      modalData: this.modalData,
      needUpdateViewModelData: this.needUpdateViewModelData
    };
  }

  showFileSizeErrorModal = () => {
    this.modalData = {
      title: i18n.t<string>('segmentDetail.labels.fileSizeErrorModalTitle'),
      message: i18n.t<string>('segmentDetail.labels.fileSizeErrorModalContent', { bytes: numberWithCommas(IMPORT_FILE_SIZE_MAXIMUM) })
    };
    this.updateState(false);
  }

  showDeleteModal = (subSegmentId: number) => {
    this.modalData = {
      title: i18n.t<string>('subSegmentList.labels.deleteModalTitle'),
      message: i18n.t<string>('subSegmentList.labels.deleteModalContent'),
      dangerBtnData: {
        title: i18n.t<string>('common.buttons.delete'),
        callback: this.deleteSubSegment.bind(this, subSegmentId)
      }
    };
    this.updateState(false);
  }

  updateModelData (subSegments: Array<SegmentDTO>) {
    this.subSegments = subSegments;
    this.needUpdateViewModelData = true;
  }

  hideModal = () => {
    this.modalData = undefined;
    this.updateState(false);
  }

  async deleteSubSegment (subSegmentId: number) {
    this.updateState(true);
    this.modalData = undefined;
    try {
      await this.manager.deleteSegment(subSegmentId);
      toast.success(i18n.t<string>('subSegmentList.labels.deleteSuccess'));
      this.onSubSegmentChange();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  async importAudiences (segmentId: number, file: File, callback: () => void): Promise<void> {
    if (file.size > IMPORT_FILE_SIZE_MAXIMUM) {
      this.showFileSizeErrorModal();
      return;
    }
    this.updateState(true);
    try {
      await this.manager.importAudiences(segmentId, SegmentImportType.COOKIE, file);
      toast.success(i18n.t<string>('segmentDetail.labels.importSuccess'));
      this.onSubSegmentChange();
      callback();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  async exportAudiences (segmentId: number): Promise<void> {
    this.updateState(true);
    try {
      await this.manager.exportAudiences(segmentId);
      toast.success(i18n.t<string>('segmentDetail.labels.exportSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  handleOnSearch = (searchString) => {
    this.searchString = searchString;
    this.updateViewModelData();
  }

  updateViewModelData () {
    this.needUpdateViewModelData = false;
    this.viewModel = this.subSegments
      .filter(subSegment => {
        return subSegment.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
                            subSegment.id.toString().includes(this.searchString);
      }).map(subSegment => {
        const ruleContents = subSegment.ruleContents ?
          _.uniq(subSegment.ruleContents.map(ruleContent =>
            i18n.t<string>(`segmentRuleComparator.labels.${ruleContent.comparator}`))) :
          [];
        return {
          id: subSegment.id,
          name: subSegment.name,
          [SubSegmentListColumns.NAME]: subSegment.id,
          [SubSegmentListColumns.DMP_SEGMENT_ID]: subSegment.dmpSegmentId,
          [SubSegmentListColumns.SEGMENT_COUNT]: subSegment.segmentCount,
          [SubSegmentListColumns.RULE_CONTENT]: ruleContents.join(',')
        };
      });
    this.updateState(false);
  }

  getColumnDefinition (columnName): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };
    let text = `subSegmentList.headers.${columnName}`;
    let sort = true;
    if (columnName === SubSegmentListColumns.EDITBTNS) {
      text = '';
      sort = false;
    } else if (columnName === SubSegmentListColumns.RULE_CONTENT) {
      sort = false;
    }
    return {
      sort,
      text,
      dataField: columnName,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    };
  }

  onUnmount (handler) {
    this.event.remove(handler);
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
