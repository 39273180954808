/* eslint-disable no-template-curly-in-string */
import React from 'react';
import i18n from 'i18n';
import styles from './ppsMacroInstruction.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const UrlMacroInstruction: React.FunctionComponent<{onClose: () => void}> = (props) => {
  const renderRow = (macroData) => {
    return macroData.map(data => {
      return (
        <tr key={data.macro}>
          <td>
            {data.macro}
          </td>
          <td>
            {data.desc}
          </td>
        </tr>
      );
    });
  };
  return (
    <div className={styles.ppsMacroInstruction}>
      <FontAwesomeIcon className={styles.closeBtn} icon={faTimes} onClick={props.onClose}/>
      <section>
        <div className={styles.sessionTitle}>{i18n.t<string>('creativeBasicForm.titles.clickMacroDesc')}</div>
        <table className={styles.macroTable}>
          <thead>
            <tr>
              <th>{i18n.t<string>('macro.headers.clickMacro')}</th>
              <th>{i18n.t<string>('macro.headers.desc')}</th>
            </tr>
          </thead>
          <tbody>
            {renderRow([
              { macro: 'clickId=${TRACKING_ID}', desc: i18n.t<string>('macro.bannerUrl.clickId') },
              { macro: 'idfa=${IDFA}', desc: i18n.t<string>('macro.bannerUrl.idfa') },
              { macro: 'android_id=${ANDROID_ID}', desc: i18n.t<string>('macro.bannerUrl.androidId') },
              { macro: 'af_c_id=${CAMPAIGN_ID}', desc: i18n.t<string>('macro.bannerUrl.campaignId') },
              { macro: 'c=${BANNER_ID}', desc: i18n.t<string>('macro.bannerUrl.bannerId') }
            ])}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={2}>{i18n.t<string>('macro.hint.appsflyer')}</td>
            </tr>
          </tfoot>
        </table>
      </section>
    </div>
  );
};
