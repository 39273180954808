import { FormikFileInputField } from 'components/common/form/field/FileInputField';
import { FormikInputField } from 'components/common/form/field/InputField';
import { FormikUrlInputField } from 'components/common/form/field/UrlInputField';
import { defaultTo, get, isEmpty } from 'lodash';
import { JSXElementConstructor, ReactElement, useCallback, useEffect, useMemo } from 'react';
import i18n from 'i18n';
import { formatBytes } from 'utils/StringUtil';
import { renderErrors } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/SubSteps/FormContent/CreativeFormHintRenderFunction';
import { validateEmpty } from 'utils/ValidateUtils';
import { useFormikContext } from 'formik';
import styles from './formikConfigInputField.module.scss';
import { FieldConfig } from './FieldConfig';

const mimeDB = require('mime-db');

export type FormikConfigInputFieldProps = {
  name: string,
  label?: string,
  fieldConfig: FieldConfig,
  placeholder?: ReactElement<any, string | JSXElementConstructor<any>>,
  postComponent?: ReactElement<any, string | JSXElementConstructor<any>>,
  permanentHint?: ReactElement<any, string | JSXElementConstructor<any>>
};

export const FormikConfigInputField = ({
  name,
  label,
  fieldConfig,
  postComponent,
  permanentHint,
  placeholder = <div/>
}: FormikConfigInputFieldProps) => {

  const { validateField } = useFormikContext();

  const limits = fieldConfig ? fieldConfig.limits : undefined;

  useEffect(() => {
    validateField(name);
  }, [fieldConfig, name, validateField]);

  const mimeTypeMap = useMemo(() => ({
    jpg: ['image/jpg', 'image/jpeg'],
    png: ['image/png'],
    gif: ['image/gif'],
    mp4: ['video/mp4']
  }), []);

  const validateFile = useCallback((fileData: any) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!limits || !fileData.file) {
      return;
    }

    const file = fileData.file;
    if (!isEmpty(limits.fileType)) {
      const validTypes = limits.fileType!.flatMap(fileType => mimeTypeMap[fileType]);
      if (validTypes.indexOf(file.type) === -1) {
        const extensions = get(mimeDB[file.type], 'extensions', ['Unknown']);
        return i18n.t<string>('formikConfigInputField.errors.fileType', { type: extensions[0], types: validTypes.join(', ') });
      }
    }
    const errors: string[] = [];
    const width = fileData.width;
    const height = fileData.height;
    if (!isEmpty(limits.fileSize)) {
      const size = limits.fileSize!.split('x').map(size => size.trim());
      if (size.length === 2 && (size[0] !== width.toString() || size[1] !== height.toString())) {
        errors.push(i18n.t<string>('formikConfigInputField.errors.fileSize', { size1: `${width} x ${height}`, size2: `${size[0]} x ${size[1]}` }));
      }
    }

    if (!isEmpty(limits.fileRatio)) {
      const ratio = (width / height).toFixed(2);
      const targetRatios = limits.fileRatio!.split(':').map(ratio => ratio.trim());
      const invalidSetting = targetRatios.length !== 2 || isNaN(+targetRatios[0]) || isNaN(+targetRatios[1]);
      if (!invalidSetting && (+targetRatios[0] / +targetRatios[1]).toFixed(2) !== ratio) {
        errors.push(i18n.t<string>('formikConfigInputField.errors.fileRatio', { ratio: limits.fileRatio }));
      }
    }

    if (!isEmpty(limits.fileStorage)) {
      if (file.size > 1048576 * +limits.fileStorage!) {
        errors.push(i18n.t<string>('formikConfigInputField.errors.fileStorage', { storage1: formatBytes(file.size), storage2: `${limits.fileStorage}MB` }));
      }
    }

    return errors.length > 0 ? errors : undefined;
  }, [mimeTypeMap, limits]);

  const validateVideo = useCallback((fileData: any) => {
    const errors = validateFile(fileData);
    if (errors && !Array.isArray(errors)) {
      return errors;
    }

    if (!limits) {
      return;
    }

    if (!fileData.file) {
      return;
    }

    const fileErrors = defaultTo(errors, []) as string[];
    const videoErrors: string[] = [];
    if (limits.videoDuration !== undefined) {
      const duration = fileData.duration;
      if (duration > limits.videoDuration) {
        videoErrors.push(i18n.t<string>('formikConfigInputField.errors.videoDuration', { duration1: duration, duration2: limits.videoDuration }));
      }
    }

    const finalErrors = fileErrors.concat(videoErrors);
    if (finalErrors.length > 0) {
      return renderErrors(finalErrors);
    }
  }, [limits, validateFile]);

  const validateText = useCallback((value: any) => {
    const error = validateEmpty(value);
    if (error) {
      return error;
    }

    if (!limits) {
      return;
    }

    const textLength = value.length;
    if (limits.textMinLength !== undefined && limits.textMinLength.toString() !== '') {
      const minLength = +limits.textMinLength;
      if (textLength < minLength) {
        return i18n.t<string>('formikConfigInputField.errors.minLength', { min: minLength });
      }
    }
    if (limits.textMaxLength !== undefined && limits.textMaxLength.toString() !== '') {
      const maxLength = +limits.textMaxLength;
      if (textLength > maxLength) {
        return i18n.t<string>('formikConfigInputField.errors.maxLength', { max: maxLength });
      }
    }
    return undefined;
  }, [limits]);

  const hints = useMemo(() => {
    if (!limits) {
      return [];
    }
    return Object.keys(limits)
      .filter(key => key !== 'type')
      .map(key => i18n.t<string>(`formikConfigInputField.hints.${key}Limit`, { limit: Array.isArray(limits[key]) ? limits[key].join(', ') : limits[key] }));
  }, [limits]);

  const validTypes = useMemo(() => {
    return get(limits, 'fileType', []).flatMap(fileType => mimeTypeMap[fileType]);
  }, [limits, mimeTypeMap]);

  if (!fieldConfig) {
    return placeholder;
  }

  if (fieldConfig.type === 'image') {
    return (
      <FormikFileInputField
        label={label}
        name={name}
        type='image'
        hints={hints}
        validate={validateFile}
        validTypes={validTypes.length > 0 ? validTypes : undefined}
        postComponent={postComponent}
        className={styles.fileInput}
        permanentHint={permanentHint}
      />
    );
  }

  if (fieldConfig.type === 'video') {
    return (
      <FormikFileInputField
        label={label}
        name={name}
        type='video'
        hints={hints}
        validate={validateVideo}
        validTypes={validTypes.length > 0 ? validTypes : undefined}
        postComponent={postComponent}
        className={styles.fileInput}
        permanentHint={permanentHint}
      />
    );
  }

  if (fieldConfig.type === 'text') {
    const hint = hints.map((hint, index) => <div key={index}>{hint}</div>);
    return (
      <FormikInputField
        label={label}
        name={name}
        hint={hint}
        validate={validateText}
        postComponent={postComponent}
        permanentHint={permanentHint}
      />
    );
  }

  if (fieldConfig.type === 'url') {
    return (
      <FormikUrlInputField
        label={label}
        name={name}
        postComponent={postComponent}
        formGroupClassName={styles.urlField}
        permanentHint={permanentHint}
      />
    );
  }

  return placeholder;
};
