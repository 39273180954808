import React, { useCallback, useRef, useState } from 'react';
import { Overlay } from 'react-bootstrap';
import styles from './copyableOverlayTrigger.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

export const CopyableOverlayTrigger: React.FC<React.PropsWithChildren<{
  placement: 'bottom' | 'top' | 'left' | 'right',
  overlay: React.ReactNode,
  trigger?: 'click' | 'hover',
  show?: boolean,
  onTrigger?: (show: boolean) => void,
  onlyShowOverlay?: boolean,
  minHeight?: number,
  maxHeight?: number,
  minWidth?: number,
  maxWidth?: number
}>> = props => {

  const isControlled = props.show !== undefined;

  const [show, setShow] = useState<boolean>(false);
  const [target, setTarget] = useState<any>();
  const containerRef = useRef<any>(null);

  const scrollerRef = useRef<HTMLDivElement | null>(null);

  const [atBottom, setAtBottom] = useState<boolean>(false);
  const [canScroll, setCanScroll] = useState<boolean>(false);

  const updateAtBottom = useCallback((container) => {
    const atBottom = Math.abs(container.scrollHeight - container.scrollTop - container.clientHeight) < 1;
    setAtBottom(atBottom);
  }, []);

  const onScroll = useCallback((e) => {
    updateAtBottom(e.target);
  }, [updateAtBottom]);

  const handleClick = useCallback((e) => {
    setTarget(e.target);
    if (isControlled) {
      props.onTrigger && props.onTrigger(!props.show);
    } else {
      setShow(!show);
    }
  }, [isControlled, show, props]);

  const handleMouseEnter = useCallback((e) => {
    if (isControlled) {
      props.onTrigger && props.onTrigger(true);
    } else {
      setShow(true);
    }
    setTarget(e.target);
  }, [isControlled, props]);

  const handleMouseLeave = useCallback(() => {
    if (isControlled) {
      props.onTrigger && props.onTrigger(false);
    } else {
      setShow(false);
    }
  }, [isControlled, props]);

  const handleTipMouseEnter = useCallback(() => {
    if (isControlled) {
      props.onTrigger && props.onTrigger(true);
    } else {
      setShow(true);
    }
  }, [isControlled, props]);

  const setupScrollerRef = useCallback((element) => {
    if (!element) {
      return;
    }
    scrollerRef.current = element;
    updateAtBottom(element);
    setCanScroll(element.scrollHeight > element.clientHeight);
  }, [updateAtBottom]);

  const renderOverlay = overlayProps => {
    const { placement, arrowProps, show, popper, ...otherProps } = overlayProps;

    let top = '0px';
    if (placement === 'bottom') {
      top = '3px';
    }
    const overlayStyles = { ...otherProps.style, zIndex: 1100 };
    if (props.minWidth) {
      overlayStyles.minWidth = props.minWidth;
    }
    if (props.maxWidth) {
      overlayStyles.maxWidth = props.maxWidth;
    }

    const contentStyles: any = {
      marginBottom: canScroll ? '15px' : '10px'
    };

    if (props.minHeight) {
      contentStyles.minHeight = props.minHeight;
    }

    if (props.maxHeight) {
      contentStyles.maxHeight = props.maxHeight;
      contentStyles.overflowY = 'auto';
    }

    if (props.onlyShowOverlay) {
      return (
        <div
          {...otherProps}
          style={overlayStyles}
        >
          <div style={canScroll ? { paddingRight: '8px' } : undefined}>
            <div className={styles.overlayContent} style={contentStyles} ref={setupScrollerRef} onScroll={onScroll}>
              {props.overlay}
            </div>
            {!atBottom && <div className={styles.scrollHint}><FontAwesomeIcon icon={faCaretDown}/></div>}
          </div>
        </div>
      );
    }

    return (
      <div
        {...otherProps}
        style={{ ...overlayStyles, backgroundColor: '#242328', top }}
        className={styles.overlay}
        onMouseEnter={props.trigger === 'click' ? undefined : handleTipMouseEnter}
        onMouseLeave={props.trigger === 'click' ? undefined : handleMouseLeave}
      >
        <div style={canScroll ? { paddingRight: '8px' } : undefined}>
          <div className={styles.overlayContent} style={contentStyles} ref={setupScrollerRef} onScroll={onScroll}>
            {props.overlay}
          </div>
          {!atBottom && <div className={styles.scrollHint}><FontAwesomeIcon icon={faCaretDown}/></div>}
        </div>
      </div>
    );
  };

  const onHide = useCallback(() => {
    props.onTrigger && props.onTrigger(false);
  }, [props]);

  return (
    <div ref={containerRef}>
      <div
        style={{ width: 'fit-content' }}
        onClick={props.trigger === 'click' ? handleClick : undefined}
        onMouseEnter={props.trigger === 'click' ? undefined : handleMouseEnter}
        onMouseLeave={props.trigger === 'click' ? undefined : handleMouseLeave}
      >
        {props.children}
      </div>
      {props.overlay &&
        <Overlay
          show={isControlled ? props.show : show}
          target={target}
          rootClose={props.trigger === 'click' ? true : undefined}
          placement={props.placement}
          container={containerRef.current}
          onHide={onHide}
        >
          {(props) => renderOverlay(props)}
        </Overlay>
      }
    </div>
  );
};
