import React from 'react';
import i18n from 'i18n';
import { Modal } from 'components/common/Modal/Modal';
import classnames from 'classnames/bind';
import styles from './ThirdPartyTrackingCode.module.scss';
import { SelectField } from 'components/common/form/field/SelectField';
import { InputField } from 'components/common/form/field/InputField';

class InputModal extends React.Component<any> {
  cssClass: any;
  constructor (props) {
    super(props);
    this.cssClass = classnames.bind(styles);
  }

  hide = () => {
    this.props.handleClose();
  }

  submit = () => {
    this.props.handleUpdateCell();
  }

  render () {
    const {
      disabled,
      tmpTrackingType,
      tmpOriginTrackingCode,
      previewTrackingCode,
      changetmpTrackingType,
      changeTmpOriginTrackingCode,
      className: parentClass
    } = this.props;
    const trackingTypeOptions = [{ label: i18n.t<string>('campaign.strategyAndTracking.thirdPartyTrackingTypePixel'), value: 'pixel' },{ label: i18n.t<string>('campaign.strategyAndTracking.thirdPartyTrackingTypeJs'), value: 'js' }];
    const className = this.cssClass(parentClass, ['previewTrackingCode']);
    return (
      <Modal
        title={i18n.t<string>('campaign.strategyAndTracking.thirdPartyTracking')}
        primaryButton={{
          title: i18n.t<string>('common.buttons.submit'),
          callback: this.submit,
          disabled: disabled
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.cancel'),
          callback: this.hide
        }}
        dismiss={this.hide}
      >
        <SelectField
          name='tmpTrackingType'
          label={i18n.t<string>('campaign.strategyAndTracking.thirdPartyTrackingType')}
          value={tmpTrackingType}
          options={trackingTypeOptions}
          onChange={changetmpTrackingType}
          simpleValue
          fieldContentWidth={336}
          className={styles.select}
        />
        <InputField
          name='tmpOriginTrackingCode'
          label={i18n.t<string>('campaign.strategyAndTracking.thirdPartyTrackingCode')}
          type='text'
          placeholder={tmpTrackingType === 'js' ? 'eg. https://dmp.tenmax.io/js/imax.js' : 'eg. https://dmp.tenmax.io/p'}
          value={tmpOriginTrackingCode}
          onChange={changeTmpOriginTrackingCode}
          as='textarea'
          className={styles.tmpOriginTrackingCode}
          fieldContentWidth={336}
        />
        <InputField
          name='previewTrackingCode'
          label={i18n.t<string>('campaign.strategyAndTracking.thirdPartyTrackingPreview')}
          type='text'
          value={previewTrackingCode}
          disabled={true}
          className={className}
          fieldContentWidth={336}
        />
      </Modal>
    );
  }
}

export default InputModal;
