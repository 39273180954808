import React from 'react';
import { CreativeOverviewStateContentState, CreativeOverviewStateContentProps, CreativeOverviewStateContentModel } from './CreativeOverviewStateContentModel';
import classNames from 'classnames/bind';
import styles from './creativeOverviewStateContent.module.scss';
import i18n from 'i18next';
import Tags from 'components/common/Tags/Tags';

import { CreativeList } from 'components/CampaignCreativeList/CreativeList';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { StateDataContext } from 'containers/Common/StateDataContext';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { CreativeManagementStateContext } from './CreativeManagementStateContext';

export class CreativeOverviewStateContent extends React.Component<CreativeOverviewStateContentProps, CreativeOverviewStateContentState> {

  handler: number;
  cssClassNames: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  getAddCreativeOprations (model: CreativeOverviewStateContentModel) {
    const renderCreateCreativeBtn = () => {
      const createCss = this.cssClassNames('btn', 'btn-secondary', 'btn-sm', 'new');
      return (
        <button
          className={createCss}
          onClick={model.goCreateCreativeFn}
        >
          {i18n.t<string>('creativeOverviewStateContent.buttons.new')}
        </button>
      );
    };

    return (
      <PermissionChecker permissionAware={model.bindPermissionAware}>
        <div className={styles.operationArea}>
          <button
            className={`btn btn-tertiary btn-sm ${styles.bind}`}
            onClick={model.l3ObjectStateFn}
          >
            {i18n.t<string>('creativeOverviewStateContent.buttons.bind')}
          </button>
          {model.showCreateCreativeBtn && renderCreateCreativeBtn()}
        </div>
      </PermissionChecker>
    );
  }

  getChangeCreativeStateOperations (model) {
    const canActive = model.canActive();
    const canDeactive = model.canDeactive();
    const activeClass = this.cssClassNames('activateBtn',{
      block: !canActive
    });

    const deactiveClass = this.cssClassNames('deactivateBtn',{
      block: !canDeactive
    });
    return (
      <div className={styles.batchOperationArea}>
        <Tags
          value={[
            {
              label: i18n.t<string>('creativeOverviewStateContent.tags.selectedTip', { count: model.state.selectedCreatives.length }),
              value: model.state.selectedCreatives.length
            }
          ]}
          onChange={model.handleOnUnSelectAll}
        />
        <div className={activeClass} onClick={canActive ? model.activeCreatives : undefined}>
          {!canActive &&
            <div className={styles.tooltip}>
              {i18n.t<string>('creativeOverviewStateContent.buttons.actionTips')}
            </div>
          }
          <span>{i18n.t<string>('creativeOverviewStateContent.buttons.active')}</span>
        </div>
        <div className={deactiveClass} onClick={canDeactive ? model.deactiveCreatives : undefined}>
          {!canDeactive &&
            <div className={styles.tooltip}>
              {i18n.t<string>('creativeOverviewStateContent.buttons.actionTips')}
            </div>
          }
          <span>{i18n.t<string>('creativeOverviewStateContent.buttons.deactive')}</span>
        </div>
      </div>
    );
  }

  render () {
    const model = this.props.model;
    const state = model.state;
    return (
      <StateDataContext.Consumer>
        {
          (stateContext: CreativeManagementStateContext) => {
            model.setStateContext(stateContext);
            return (
              <div className={styles.creativeOverviewStateContent} >
                {state.loading && <LoadingIndicator />}
                {
                  state.selectedCreatives.length > 0 ?
                    this.getChangeCreativeStateOperations(model) :
                    this.getAddCreativeOprations(model)
                }
                <div className={styles.creatives}>
                  <CreativeList model={model.getCreativeListModel()}/>
                </div>
              </div>
            );
          }
        }
      </StateDataContext.Consumer>
    );
  }
}
