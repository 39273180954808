import React from 'react';
import './AppMenuBar.scss';

import { AppMenu } from './AppMenu';
import { AppMenuBarProps, AppMenuBarState } from './AppMenuBarModel';
import { AppFooter } from 'components/AppFooter';
import config from 'config';
export class AppMenuBar extends React.Component<AppMenuBarProps, AppMenuBarState> {
  handler: number;

  constructor (props: any) {
    super(props);
    this.handler = 0;
    this.state = this.props.model.state;
  }

  componentDidMount (): void {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  render () {
    const className = this.state.expanded ? 'app-menu-bar expanded' : 'app-menu-bar collapsed';
    const menus = this.props.model.menus.map((menu) => <AppMenu model={menu} key={menu.title} />);
    return (
      <div className={className}>
        <div className='menus'>
          <div className='app-menu-bar-title' />
          {menus}
        </div>
        {this.state.expanded && config.showVersionBar &&
          <AppFooter/>
        }
      </div>
    );
  }
}
