import { AdRequestSourceSpace } from './AdRequestSource';
import { SelectOptions } from 'components/common/commonType';
import {
  AdRequestSourceWebService,
  RestfulAdRequestSourceWebService
} from 'ws/AdRequestSourceWebService';
import { generateI18nOfSelectOptions, selectOptionsAddI18n } from 'utils/I18nUtils';
import { L1ObjectChannel } from 'core/l1Object/L1Object';

export interface AdRequestSourceManager {
  getCountries (): Promise<SelectOptions[]>;
  getFBCountries (): Promise<SelectOptions[]>;
  getTiktokCountries (): Promise<SelectOptions[]>;
  getAdx (): Promise<SelectOptions[]>;
  getSegmentLabel (): Promise<SelectOptions[]>;
  getSSPSpaces (publisherIds?: string[]): Promise<AdRequestSourceSpace[]>;
  getAsiaMaxCountries (): Promise<SelectOptions[]>;
  getAges (): Promise<SelectOptions[]>;
  getGenders (): Promise<SelectOptions[]>;
  getOS (): Promise<SelectOptions[]>;
  getSpaceTypes (): Promise<SelectOptions[]>;
  getCarriers (): Promise<SelectOptions[]>;
  getDevice (): Promise<SelectOptions[]>;
  getTenmaxCategories (): Promise<SelectOptions[]>;
  getArticleCategories (): Promise<SelectOptions[]>;
  getContentLanguages (): Promise<SelectOptions[]>;
  getSegments (advertiserId: number): Promise<SelectOptions[]>;
  getGoSegments (advertiserId: number, channel: L1ObjectChannel): Promise<SelectOptions[]>;
  getTenmaxSegments (): Promise<SelectOptions[]>;
  getMessageSegments (): Promise<SelectOptions[]>;
  getProductSegments (advertiserId: number | string, retailId: string): Promise<SelectOptions[]>;
  getDomains (): Promise<SelectOptions[]>;
  getFBCountryPeopleAmount (country: string): Promise<number>;
  getRelatedSearchKeywords (keywords: string[], retailer?: string): Promise<{
    keyword: string,
    searchCount: number
  }[]>;
  getAgencySegments (agencyId?: number): Promise<SelectOptions[]>;
}

export class DefaultAdRequestSourceManager implements AdRequestSourceManager {
  webService: AdRequestSourceWebService;

  constructor (
    webService: AdRequestSourceWebService = new RestfulAdRequestSourceWebService()
  ) {
    this.webService = webService;
  }
  async getCountries (): Promise<SelectOptions[]> {
    const countries = await this.webService.getCountries();
    return countries.map(country =>
      selectOptionsAddI18n(country, ['geography.country.', 'geography.region.'])
    );
  }
  async getFBCountries (): Promise<SelectOptions[]> {
    const countries = await this.webService.getFBCountries();
    countries.forEach(country =>
      generateI18nOfSelectOptions(country, 'geoLocations.fb', value => value.toString().toLowerCase().replace(/-|\s/g, '_'))
    );
    return countries;
  }
  async getTiktokCountries (): Promise<SelectOptions[]> {
    const countries = await this.webService.getTiktokCountries();
    countries.forEach(country =>
      generateI18nOfSelectOptions(country, 'geoLocations.tiktok')
    );
    return countries;
  }
  getAdx (): Promise<SelectOptions[]> {
    return this.webService.getAdx();
  }
  getSegmentLabel (): Promise<SelectOptions[]> {
    return this.webService.getSegmentLabel();
  }
  getSSPSpaces (publisherIds?: string[]): Promise<AdRequestSourceSpace[]> {
    const requestParameter =
      publisherIds && publisherIds.length > 0 ? publisherIds.join(',') : '';
    return this.webService.getSSPSpaces(requestParameter);
  }
  getAges (): Promise<SelectOptions[]> {
    return this.webService.getAges();
  }
  getGenders (): Promise<SelectOptions[]> {
    return this.webService.getGenders();
  }
  getOS (): Promise<SelectOptions[]> {
    return this.webService.getOS();
  }
  getSpaceTypes (): Promise<SelectOptions[]> {
    return this.webService.getSpaceTypes();
  }
  getCarriers (): Promise<SelectOptions[]> {
    return this.webService.getCarriers();
  }
  getDevice (): Promise<SelectOptions[]> {
    return this.webService.getDevice();
  }
  getTenmaxCategories (): Promise<SelectOptions[]> {
    return this.webService.getTenmaxCategories();
  }
  getArticleCategories (): Promise<SelectOptions[]> {
    return this.webService.getArticleCategories();
  }
  getContentLanguages (): Promise<SelectOptions[]> {
    return this.webService.getContentLanguages();
  }
  getSegments (advertiserId: number): Promise<SelectOptions[]> {
    return this.webService.getSegments(advertiserId);
  }
  async getGoSegments (advertiserId: number, channel: L1ObjectChannel): Promise<SelectOptions[]> {
    const segments = await this.webService.getGoSegments(advertiserId, channel);
    segments.forEach(segment =>
      generateI18nOfSelectOptions(segment, `targeting.${channel.toLowerCase()}.segment`)
    );
    return segments;
  }
  getTenmaxSegments (): Promise<SelectOptions[]> {
    return this.webService.getTenmaxSegments();
  }
  getMessageSegments (): Promise<SelectOptions[]> {
    return this.webService.getMessageSegments();
  }
  getProductSegments (advertiserId: number | string, retailId: string): Promise<SelectOptions[]> {
    return this.webService.getProductSegments(advertiserId, retailId);
  }
  getDomains (): Promise<SelectOptions[]> {
    return this.webService.getDomains();
  }
  async getAsiaMaxCountries (): Promise<SelectOptions[]> {
    const countries = await this.webService.getAsiaMaxCountries();
    return countries.map(country =>
      selectOptionsAddI18n(country, ['geography.country.', 'geography.region.'])
    );
  }
  async getFBCountryPeopleAmount (country: string): Promise<number> {
    return this.webService.getFBCountryPeopleAmount(country);
  }
  async getRelatedSearchKeywords (keywords: string[], retailer: string = 'pchome'): Promise<{
    keyword: string,
    searchCount: number
  }[]> {
    return this.webService.getRelatedSearchKeywords(keywords, retailer);
  }

  async getAgencySegments (agencyId?: number): Promise<SelectOptions[]> {
    if (!agencyId) return [];
    return this.webService.getAgencySegments(agencyId);
  }
}
