import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import styles from './page.module.scss';
import classNames from 'classnames/bind';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { Title } from './Title';
import { PropsWithChildren } from 'react';

const cx = classNames.bind(styles);

export type PageProps = {
  title: string;
  breadcrumbsRoutes?: BreadcrumbsRoute[];
  topAreaWithPadding?: boolean;
  topAreaEndWithShadow?: boolean;
  spaceBetweenTopAreaAndContent?: boolean;
  renderBtns?: () => React.ReactNode;
  renderInTopArea?: () => React.ReactNode;
};

const Section: React.FC<{title?: string} & PropsWithChildren> = ({
  title,
  children
}) => {

  return (
    <section className={styles.pageSection}>
      {title && <h2>{title}</h2>}
      <div className={styles.sectionContent}>
        {children}
      </div>
    </section>
  );
};

const Content: React.FC<{
  renderBtns?: () => React.ReactNode
} & PropsWithChildren> = ({
  children,
  renderBtns
}) => {

  return (
    <div className={styles.mainArea}>
      {children}
      {renderBtns &&
        <div className={styles.buttonArea}>
          {renderBtns()}
        </div>
      }
    </div>
  );
};

export const Page: React.FC<PageProps & React.PropsWithChildren> & { Section: typeof Section, Content: typeof Content } = ({
  children,
  title,
  breadcrumbsRoutes,
  topAreaWithPadding,
  topAreaEndWithShadow,
  spaceBetweenTopAreaAndContent,
  renderBtns,
  renderInTopArea
}) => {

  const topAreaClass = cx(styles.topArea, {
    topAreaWithPadding: breadcrumbsRoutes ? false : topAreaWithPadding,
    topAreaEndWithShadow,
    spaceBetweenTopAreaAndContent
  });

  return (
    <div className={styles.page}>
      <div className={topAreaClass}>
        {
          breadcrumbsRoutes ?
            <TitleWithBreadcrumbs title={title} routes={breadcrumbsRoutes}/> :
            <Title>{title}</Title>
        }
        {renderInTopArea && renderInTopArea()}
      </div>
      <Content renderBtns={renderBtns}>
        {children}
      </Content>
    </div>
  );
};

Page.defaultProps = {
  topAreaWithPadding: true,
  topAreaEndWithShadow: true,
  spaceBetweenTopAreaAndContent: true
};

Page.Section = Section;
Page.Content = Content;
