import React, { FC, useCallback } from 'react';
import { FormikField } from 'components/common/form/field/FormikField';
import i18n from 'i18n';
import { SelectOptions } from 'components/common/commonType';
import styles from './productSegmentForm.module.scss';
import { AlertModalData } from './ProductSegmentFormModel';

export const BasicSettings: FC<{
  formType: string,
  retailId: string,
  advertiser: number | string | undefined,
  retailers: SelectOptions[],
  advertisers: SelectOptions[],
  setFieldValue: (field: string, value: any) => void,
  onRetailIdChange: (retailId: string, doRefresh: boolean) => void,
  onAdvertiserChange: (advertiser: number | string | undefined, doRefresh: boolean) => void,
  updateAlertModalData: (modalData: AlertModalData | undefined) => void
}> = ({
  formType,
  retailId,
  advertiser,
  retailers,
  advertisers,
  setFieldValue,
  onRetailIdChange,
  onAdvertiserChange,
  updateAlertModalData
}) => {

  const advertiserFormatter = value => {
    const advertiser = advertisers.find(option => option.value === value);
    return advertiser ? advertiser.label : value;
  };

  const retailerFormatter = value => {
    const retailer = retailers.find(option => option.value === value);
    return retailer ? retailer.label : value;
  };

  const handleAdvertiserChange = useCallback((selectedAdvertiser: number | string) => {
    const hideModalFunc = () => {
      updateAlertModalData(undefined);
    };
    const confirmFunc = () => {
      onAdvertiserChange(selectedAdvertiser, true);
      setFieldValue('advertiser', selectedAdvertiser);
      hideModalFunc();
    };
    if (!advertiser) {
      onAdvertiserChange(selectedAdvertiser, false);
    } else if (advertiser !== selectedAdvertiser) {
      setFieldValue('advertiser', advertiser);
      updateAlertModalData({
        title: i18n.t<string>('productSegmentForm.alertModal.title'),
        message: 'productSegmentForm.alertModal.message',
        dismiss: hideModalFunc,
        onConfirm: confirmFunc,
        onCancel: hideModalFunc
      });
    } else {
      setFieldValue('advertiser', advertiser);
    }
  }, [advertiser, onAdvertiserChange, updateAlertModalData, setFieldValue]);

  const handleRetailIdChange = useCallback((selectedRetailId: string) => {
    const hideModalFunc = () => {
      updateAlertModalData(undefined);
    };
    const confirmFunc = () => {
      onRetailIdChange(selectedRetailId, true);
      setFieldValue('retailId', selectedRetailId);
      hideModalFunc();
    };
    if (!retailId) {
      onRetailIdChange(selectedRetailId, false);
    } else if (retailId !== selectedRetailId) {
      setFieldValue('retailId', retailId);
      updateAlertModalData({
        title: i18n.t<string>('productSegmentForm.alertModal.title'),
        message: 'productSegmentForm.alertModal.message',
        dismiss: hideModalFunc,
        onConfirm: confirmFunc,
        onCancel: hideModalFunc
      });
    } else {
      setFieldValue('retailId', retailId);
    }
  }, [retailId, onRetailIdChange, updateAlertModalData, setFieldValue]);

  return (
    <>
      {formType === 'create' ?
        <FormikField.Select
          label={i18n.t<string>('productSegmentForm.labels.advertiser')}
          name='advertiser'
          options={advertisers}
          simpleValue
          onChange={handleAdvertiserChange}
        /> :
        <FormikField.Label
          label={i18n.t<string>('productSegmentForm.labels.advertiser')}
          name='advertiser'
          formatter={advertiserFormatter}
        />
      }
      <FormikField.Input
        label={i18n.t<string>('productSegmentForm.labels.name')}
        name='segmentName'
        className={styles.orderInput}
      />
      {formType === 'create' ?
        <FormikField.Select
          label={i18n.t<string>('productSegmentForm.labels.retailer')}
          name='retailId'
          simpleValue
          options={retailers}
          onChange={handleRetailIdChange}
        /> :
        <FormikField.Label
          label={i18n.t<string>('productSegmentForm.labels.retailer')}
          name='retailId'
          formatter={retailerFormatter}
        />
      }
    </>
  );
};
