import { getNameIdColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { Category } from 'core/tenmaxSegment/TenmaxSegment';
import { DefaultTenmaxSegmentManager } from 'core/tenmaxSegment/TenmaxSegmentManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { SegmentImportType } from 'core/segment/SegmentManager';

const tenmaxSegmentManager = new DefaultTenmaxSegmentManager();

export enum CategoryListColumns {
  NAME = 'id',
  AUDIENCE_COUNT = 'audienceCount'
}

export const useCategoryListModel = (
  sourceId: number
) => {

  const [categories, setCategories] = useState<Category[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  const {
    loading,
    callAPIs
  } = useCallAPI();

  const fetchCategories = useCallback(() => {
    callAPIs([() => tenmaxSegmentManager.getCategories(sourceId)], categories => {
      setCategories(categories);
    });
  }, [sourceId, callAPIs]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  const columns = useMemo(() => ([
    getNameIdColumn(
      sortableColumn(CategoryListColumns.NAME, i18n.t<string>('categoryList.headers.name')),
      category => `/tenmax-segments/sources/${sourceId}/categories/${category.id}/edit`
    ),
    sortableColumn(CategoryListColumns.AUDIENCE_COUNT, i18n.t<string>('categoryList.headers.audienceCount'))
  ]), [sourceId]);

  const onHandleSearch = useCallback((searchString: string) => {
    setSearchString(searchString);
  }, []);

  const filterdCategories = useMemo(() => {
    return categories.filter(category => {
      const idMatch = category.id.toString().includes(searchString);
      const nameMatch = category.name.includes(searchString);
      return idMatch || nameMatch;
    });
  }, [categories, searchString]);

  const importAudiences = useCallback((categoryId: number, importType: SegmentImportType, file: File, callback: () => void) => {
    callAPIs([() => tenmaxSegmentManager.importAudiences(sourceId, categoryId, importType, file)], () => {
      toast.success(i18n.t<string>('categoryList.hints.importSuccess'));
      callAPIs([() => tenmaxSegmentManager.getCategories(sourceId)], categories => {
        setCategories(categories);
      });
      callback();
    }, (e) => {
      (e instanceof Error) && toast.error(e.message);
    });
  }, [sourceId, callAPIs]);

  const exportAudiences = useCallback((categoryId: number) => {
    callAPIs([() => tenmaxSegmentManager.exportAudiences(sourceId, categoryId)], () => {
      toast.success(i18n.t<string>('categoryList.hints.exportSuccess'));
    }, (e) => {
      (e instanceof Error) && toast.error(e.message);
    });
  }, [sourceId, callAPIs]);

  return {
    loading,
    columns,
    categories,
    searchString,
    filterdCategories,
    onHandleSearch,
    importAudiences,
    exportAudiences
  };
};
