import React, { PropsWithChildren, useRef } from 'react';
import { FormConfig } from './FormConfig';
import { FormSection } from './FormSection';
import { Button } from 'react-bootstrap';
import i18n from 'i18n';
import { useHistory } from 'react-router-dom';
import { Page } from 'components/Page/Page';
import { Form as FormikForm, FormikProps } from 'formik';
import { ErrorHandler } from './FormErrorHandler/FormErrorHandler';
import NavigationPrompt from '../NavigationPrompt/NavigationPrompt';

const SubmitButton: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <Button type='submit' size='sm'>
      {children ? children : i18n.t<string>('common.buttons.submit')}
    </Button>
  );
};

const CancelButton: React.FC<PropsWithChildren> = ({ children }) => {
  const { goBack } = useHistory();
  return (
    <Button variant='secondary' size='sm' onClick={goBack}>
      {children ? children : i18n.t<string>('common.buttons.cancel')}
    </Button>
  );
};

export const Form: React.FC<{
  formikProps: FormikProps<any>,
  formConfig?: FormConfig,
  withNavigationPrompt?: boolean,
  renderFormBtns?: () => JSX.Element
}> & {
  SubmitButton: typeof SubmitButton,
  CancelButton: typeof CancelButton
} = ({
  formikProps,
  formConfig,
  withNavigationPrompt,
  renderFormBtns
}) => {

  const mainDomRef = useRef(null);

  return (
    <>
      {withNavigationPrompt && <NavigationPrompt/>}
      <FormikForm ref={mainDomRef}>
        <ErrorHandler
          parentRef={mainDomRef}
          isSubmitting={formikProps.isSubmitting}
          submitCount={formikProps.submitCount}
        />
        <Page.Content renderBtns={renderFormBtns}>
          <FormContent formConfig={formConfig}/>
        </Page.Content>
      </FormikForm>
    </>
  );
};

Form.defaultProps = {
  withNavigationPrompt: true
};

Form.SubmitButton = SubmitButton;
Form.CancelButton = CancelButton;

export const FormContent: React.FC<{
  formConfig?: FormConfig
}> = ({
  formConfig
}) => {

  const renderFormContent = () => {
    if (!formConfig) {
      return;
    }
    return formConfig.sections.map((section, index) => (
      <FormSection key={index} formSection={section}/>
    ));
  };

  return (
    <div>
      {renderFormContent()}
    </div>
  );
};
