import React, { Fragment } from 'react';
import { SubSegmentFormState, SubSegmentFormProps } from './SubSegmentFormModel';
import { Formik, FormikProps, getIn } from 'formik';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Form, Button, NavDropdown, InputGroup } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import { SubSegmentFormDTO, SegmentRuleComparator } from 'core/segment/Segment';
import { SelectInput } from 'components/SelectInput/SelectInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import styles from './subSegmentForm.module.scss';
import i18n from 'i18next';
import _ from 'lodash';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { FormikField } from 'components/common/form/field/FormikField';

export class SubSegmentForm extends React.PureComponent<SubSegmentFormProps, SubSegmentFormState> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleSubmit = (formData) => {
    this.props.model.submit(formData);
  }

  validate = (segment: SubSegmentFormDTO): any => {
    return this.props.model.validate(segment);
  }

  onPromptCancel = () => {
    this.props.model.setRedirectPath(undefined);
  }

  renderRuleOptions (formProps) {
    const onAddRule = (rule) => {
      formProps.setFieldValue('ruleContents', [
        ..._.get(formProps.values, 'ruleContents', []),
        {
          pattern: '',
          comparator: rule
        }
      ]);
    };
    return Object.values(SegmentRuleComparator).map(rule => (
      <NavDropdown.Item
        key={rule}
        onClick={_.partial(onAddRule, rule)}
      >
        {i18n.t<string>(`segmentRuleComparator.labels.${rule}`)}
      </NavDropdown.Item>
    ));
  }

  renderRules (formProps, errorHint) {
    const value = formProps.values[formProps.name];
    if (!value) {
      return <div/>;
    }
    const options = Object.values(SegmentRuleComparator).map(comparator => {
      return {
        label: i18n.t<string>(`segmentRuleComparator.labels.${comparator}`),
        value: comparator
      };
    });
    return value.map((rule, index) => {
      const onChange = (newValue) => {
        const fieldValue = value.map(value => {
          return { ...value };
        });
        if (newValue) {
          fieldValue[index] = {
            comparator: newValue.option,
            pattern: newValue.inputValue
          };
        } else {
          fieldValue[index] = undefined;
        }
        formProps.setFieldValue(formProps.name, _.compact(fieldValue));
      };
      return (
        <InputGroup key={index} className={styles.ruleInput}>
          <SelectInput
            className={errorHint ? styles.errorRuleInput : undefined}
            options={options}
            value={{
              option: rule.comparator,
              inputValue: rule.pattern
            }}
            onChange={onChange}
          />
          <InputGroup.Append>
            <FontAwesomeIcon
              className={styles.removeRuleBtn}
              icon={faTimesCircle}
              onClick={_.partial(onChange, undefined)}
            />
          </InputGroup.Append>
        </InputGroup>
      );
    });
  }

  renderRuleContentsField = (formProps) => {
    let errorHint;
    const error = _.get(formProps.errors, formProps.name);
    if (error && getIn(formProps.touched, formProps.name)) {
      errorHint = error;
    }
    return (
      <Fragment>
        <div className={styles.ruleComparatorAdder}>
          <FontAwesomeIcon icon={faPlus}/>
          <div className={styles.comparatorList}>
            <NavDropdown title={i18n.t<string>('subSegmentForm.labels.filterRules')} id='segmentRuleComparatorList'>
              {this.renderRuleOptions(formProps)}
            </NavDropdown>
          </div>
        </div>
        {this.renderRules(formProps, errorHint)}
        {errorHint && <span className={styles.errorHint}>{errorHint}</span>}
      </Fragment>
    );
  }

  renderForm = (formProps: FormikProps<SubSegmentFormDTO>) => {
    return (
      <Form onSubmit={formProps.handleSubmit}>
        <div className={styles.formArea}>
          <FormikField.Input
            label={i18n.t<string>('subSegmentForm.labels.name')}
            name='name'
          />
          <FormikField.Custom
            label={i18n.t<string>('subSegmentForm.labels.ruleContent')}
            name='ruleContents'
            render={this.renderRuleContentsField}
          />
        </div>
        <div className={styles.buttonArea}>
          <Button type='submit' size='sm'>
            {i18n.t<string>('common.buttons.submit')}
          </Button>
          <Button variant='secondary' size='sm' onClick={this.props.model.cancel}>
            {i18n.t<string>('common.buttons.cancel')}
          </Button>
        </div>
      </Form>
    );
  }

  render () {
    const model = this.props.model;
    const state = model.state;
    if (!state.subSegment) {
      return <LoadingIndicator />;
    }
    return (
      <div className={styles.subSegmentFormContainer}>
        <NavigationPrompt when={!state.finished} onCancel={this.onPromptCancel}/>
        {state.loading && <LoadingIndicator />}
        <div className={styles.title}>
          {model.title}
        </div>
        <div className={styles.titleBottomLine} />
        <Formik
          initialValues={state.subSegment}
          onSubmit={this.handleSubmit}
          validate={this.validate}
          validateOnBlur={false}
        >
          {this.renderForm}
        </Formik>
        {state.redirectPath && <Redirect to={state.redirectPath}/>}
      </div>
    );
  }
}
