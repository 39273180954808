import React from 'react';
import { FormikField } from 'components/common/form/field/FormikField';
import i18n from 'i18n';
import { SelectOptions } from 'components/common/commonType';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';

export const GoSegmentFormBasicFields: React.FC<{ formType: string, advertisers: SelectOptions[] }> = ({
  formType,
  advertisers
}) => {

  const advertiserFormatter = value => {
    const advertiser = advertisers.find(option => option.value.toString() === value.toString());
    return advertiser ? advertiser.label : '';
  };

  return (
    <>
      <PermissionChecker
        permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport)}
      >
        {
          formType === 'edit' ?
          <FormikField.Label
            label={i18n.t<string>('goSegmentForm.fields.advertiser')}
            name='advertiserId'
            formatter={advertiserFormatter}
          /> :
          <FormikField.Select
            label={i18n.t<string>('goSegmentForm.fields.advertiser')}
            name='advertiserId'
            options={advertisers}
            simpleValue
          />
        }
      </PermissionChecker>
      <FormikField.Input
        label={i18n.t<string>('goSegmentForm.fields.name')}
        name='name'
      />
      <FormikField.TextArea
        label={i18n.t<string>('goSegmentForm.fields.description')}
        name='description'
      />
    </>
  );
};
