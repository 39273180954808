import { validateUrl } from 'utils/ValidateUtils';
import i18n from 'i18next';
import _ from 'lodash';
import { AdLogoImageData, AdLogoType } from 'core/adLogo/AdLogo';
const mimeDB = require('mime-db');

export interface AdLogoFormModel {
  validateAdLogoImage (image: AdLogoImageData | undefined, type: AdLogoType): any;
  validateLink (link: string | undefined, type: AdLogoType): any;
}

export type AdLogoFormProps = {
  readonly model: AdLogoFormModel;
};

export class DefaultAdLogoFormModel implements AdLogoFormModel {

  validateImage = (fileData) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('adLogoForm.labels.typeErrorHint', { type: extensions[0] });
    }

    const validWitdh = 50;
    const validHeight = 50;
    const width = fileData.width;
    const height = fileData.height;
    if (width !== validWitdh || height !== validHeight) {
      return i18n.t<string>('adLogoForm.labels.sizeErrorHint', { size1: `${width} x ${height}`, size2: `${validWitdh} x ${validHeight}` });
    }
  }

  validateLink = (link: string | undefined, type: AdLogoType): any => {
    if (type === AdLogoType.CUSTOM) {
      return validateUrl(link);
    }
  }

  validateAdLogoImage = (image: AdLogoImageData | undefined, type: AdLogoType): any => {
    if (type === AdLogoType.CUSTOM) {
      return this.validateImage(image);
    }
  }
}
