import React from 'react';

import i18n from 'i18n';
import './ActorPermissionForm.scss';

import { Col, Form, Row } from 'react-bootstrap';
import { ActorPermissionFormProps, PermissionFormState } from './ActorPermissionFormModel';
import { Modal } from 'components/common/Modal/Modal';
import _ from 'lodash';
import Select from 'components/common/Select/Select';
import classname from 'classnames';

export class ActorPermissionForm extends React.Component<ActorPermissionFormProps, PermissionFormState> {
  handler: number;

  constructor (props: any) {
    super(props);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  hide = () => {
    this.props.model.cancel();
  }

  submit = () => {
    this.props.model.submit();
  }

  setEmail = (event: any) => {
    this.props.model.setEmail(event.target.value);
  }

  select = (value: any) => {
    this.props.model.select(value);
  }

  render () {
    const model = this.props.model;
    const options = model.options.flatMap((option) => {
      const description = _.camelCase(option);
      return {
        value: option,
        label: `${i18n.t<string>(`actor.roleNames.${description}`)} - ${i18n.t<string>(`actor.descriptions.${description}`)}`
      };
    });

    const emailClass = classname('account-email', {
      error: !!model.validationError
    });

    return (
      <Modal
        fullScreen={true}
        animation={false}
        title={i18n.t<string>('accounts.form.titles.permission')}
        primaryButton={{
          title: i18n.t<string>('common.buttons.submit'),
          callback: this.submit
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.cancel'),
          callback: this.hide
        }}
        dismiss={this.hide}
      >
        <div className='actor-permission-form'>
          <Form.Group as={Row}>
            <Col sm={2}>
              <Form.Label column>
                {i18n.t<string>('accounts.form.labels.account-email')}
              </Form.Label>
            </Col>
            <Col sm={4}>
              <div>
                <input
                  className={emailClass}
                  type='email'
                  readOnly={!model.emailEditable}
                  placeholder={i18n.t<string>('accounts.form.labels.account-email')}
                  value={model.email}
                  onChange={this.setEmail}
                />
                {model.validationError && <div className='error-message'>{i18n.t<string>(model.validationError)}</div>}
              </div>
            </Col>
          </Form.Group>
          <Form.Group as={Row}>
            <Col sm={2}>
              <Form.Label column>
                {i18n.t<string>('accounts.form.labels.permission')}
              </Form.Label>
            </Col>
            <Col sm={4}>
              <Select
                className={'permission-list'}
                options={options}
                name='permissionOptions'
                simpleValue
                value={model.selected}
                onChange={this.select}
                placeholder={i18n.t<string>('advertiserSegmentSource.placeholders.agencies')}
              />
            </Col>
          </Form.Group>
        </div>
      </Modal>
    );
  }
}
