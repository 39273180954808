import React from 'react';
import styles from './storedValueList.module.scss';
import { Link } from 'react-router-dom';
import { StoredValueSummary } from 'core/storedValue/StoredValueLog';

const nameFormatter = (id: number, summary: StoredValueSummary): any => {
  return (
    <div className={`${styles.nameCell}`}>
      <Link
        to={{
          pathname: `/stored-value/${id}`,
          state: {
            logs: summary.logs,
            agency: {
              name: summary.adAgencyName,
              currency: summary.currency
            }
          }
        }}
        className={styles.name}
      >
        {summary.adAgencyName}
      </Link>
      <div className={styles.id}>
        {'ID: ' + id}
      </div>
    </div>
  );
};

const formatters = {
  nameFormatter
};
export default formatters;
