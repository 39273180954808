import React from 'react';
import { OrderFormProps } from './OrderFormModel';
import { FormikProps } from 'formik';
import i18n from 'i18next';
import _ from 'lodash';
import styles from './orderForm.module.scss';
import { Button } from 'react-bootstrap';
import moment from 'moment';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Order } from 'core/order/Order';
import { FormikField } from 'components/common/form/field/FormikField';
import { CustomField } from 'components/common/form/field/CustomField';

export class EditOrderFormContent extends React.Component<OrderFormProps & { formik: FormikProps<Order> }, any> {

  originBudget: number;
  originMargin: number;

  constructor (props) {
    super(props);
    if (this.props.model.formikProps) {
      this.originBudget = this.props.model.formikProps.values.budget;
      this.originMargin = this.props.model.formikProps.values.orderMargin;
    } else {
      this.originBudget = 0;
      this.originMargin = 0;
    }
  }

  componentDidMount () {
    this.props.model.setNeedCheckModifyReason(this.getShowModifyReasonValue());
  }

  componentDidUpdate () {
    this.props.model.setNeedCheckModifyReason(this.getShowModifyReasonValue());
  }

  onDateRangeChanged = (_1, endDate) => {
    this.props.formik.setFieldValue('finalReportSendOutDate', moment(endDate).startOf('day').add(2, 'days').format('YYYY-MM-DD'));
  }

  onEditBudgetClick = () => {
    const model = this.props.model;
    if (model.state.showBudgetInputField && model.formikProps) {
      model.formikProps.setValues(prev => ({
        ...prev,
        budget: this.originBudget,
        modifyReason: undefined
      }), true);
    }
    model.triggerBudgetInputField && model.triggerBudgetInputField();
  }

  getShowModifyReasonValue = () => {
    const model = this.props.model;
    const order = model.formikProps && model.formikProps.values;
    if (order) {
      return order.modifyReason !== undefined ||
        model.state.showBudgetInputField ||
        model.state.showMarginInputField;
    }
    return false;
  }

  renderBudgetField = () => {
    const model = this.props.model;
    const currency = model.localeMeta ? model.localeMeta.currency : 'NTD';
    const renderField = () => (
      model.state.showBudgetInputField ?
        <FormikField.InputGroup
          prefix={currency}
          name='budget'
          type='number'
          formGroupClassName={styles.orderBudgetWithButton}
          postComponent={
            <Button style={{ width: 'max-content' }} variant='secondary' size='sm' onClick={this.onEditBudgetClick}>
              {i18n.t<string>('orderForm.labels.modifyCancel')}
            </Button>
          }
          hint={model.budgetTip}
        /> :
        <FormikField.InputGroup
          as='label'
          prefix={currency}
          prefixBorder={false}
          name='budget'
          fieldContentWidth={'max-content'}
          className={styles.budgetLabel}
          formGroupClassName={styles.orderBudgetWithButton}
          postComponent={
            <Button style={{ width: 'max-content' }} variant='secondary' size='sm' onClick={this.onEditBudgetClick}>
              {i18n.t<string>('orderForm.labels.modifyBudget')}
            </Button>
          }
        />
    );
    if (model.showEditButton) {
      return (
        <CustomField
          label={i18n.t<string>('orderForm.labels.budget')}
          render={renderField}
        />
      );
    }

    return (
      <FormikField.InputGroup
        formGroupClassName={styles.budget}
        label={i18n.t<string>('orderForm.labels.budget')}
        name='budget'
        prefix={currency}
        type='number'
        hint={model.budgetTip}
      />
    );
  }

  renderMarginField = () => {
    const model = this.props.model;
    if (model.isOrderHasApproved) {
      return (
        <FormikField.InputGroup
          as='label'
          formGroupClassName={styles.marginWithButton}
          label={i18n.t<string>('orderForm.labels.orderMargin')}
          name='orderMargin'
          postfix='%'
          postfixBorder={false}
          className={styles.marginLabel}
        />
      );
    }

    return (
      <FormikField.InputGroup
        formGroupClassName={styles.margin}
        label={i18n.t<string>('orderForm.labels.orderMargin')}
        name='orderMargin'
        className={styles.orderMargin}
        postfix='%'
        type='number'
      />
    );
  }

  render () {
    const order = this.props.model.formikProps && this.props.model.formikProps.values;
    if (!order) {
      return <div />;
    }

    const orderTypeFormatter = value => i18n.t<string>(`orderType.enums.${_.defaultTo(value, '').toLowerCase()}`);

    return (
      <div className={styles.editOrderForm}>
        <PermissionChecker
          permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport)}
        >
          <FormikField.Label
            label={i18n.t<string>('orderForm.labels.advertiser')}
            name='advertiserName'
          />
        </PermissionChecker>
        <FormikField.Input
          label={i18n.t<string>('orderForm.labels.name')}
          name='projectName'
        />
        <FormikField.Label
          label={i18n.t<string>('orderForm.labels.orderType')}
          name='orderType'
          formatter={orderTypeFormatter}
        />
        <FormikField.Input
          label={i18n.t<string>('orderForm.labels.externalId')}
          name='externalId'
        />
        {this.props.model.showOrderExternalTypes &&
          <FormikField.Select
            label={i18n.t<string>('orderForm.labels.externalType')}
            name='externalType'
            options={this.props.model.orderExternalTypes}
            className={styles.externalType}
            simpleValue
            isClearable
          />
        }
        <FormikField.DateRangePicker
          name='dateRange'
          label={i18n.t<string>('orderForm.labels.dateRange')}
          className={styles.dateRange}
          minDate={this.props.model.dateRangeMinDate}
          maxDate={moment(this.props.model.dateRangeMinDate).add(10, 'years').format('YYYY-MM-DD')}
          startDateFormikName='startDate'
          endDateFormikName='endDate'
          format={'YYYY-MM-DD'}
          showTimePicker={false}
          startDatePickerDisabled={this.props.model.isOrderStarted}
          onChange={this.onDateRangeChanged}
          hint={i18n.t<string>('orderForm.labels.totaldates', { num: this.props.model.totalDays })}
          hoverHint={i18n.t<string>('orderForm.labels.dateCannotModifyHint')}
        />
        {this.renderBudgetField()}
        {this.props.model.canEditMargin &&
          <PermissionChecker
            permissionAware={
              notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)
                .and(addOnEnabled(ADDONFEATURE.CAMPAIGN.ORDER_AGENCY_PROFIT_SETTING))
            }
          >
            {this.renderMarginField()}
          </PermissionChecker>
        }
        { this.getShowModifyReasonValue() &&
          <FormikField.TextArea
            label={i18n.t<string>('orderForm.labels.modifyReason')}
            name='modifyReason'
          />
        }
        <FormikField.TextArea
          label={i18n.t<string>('orderForm.labels.remark')}
          name='comments'
        />
        {true &&
          <FormikField.Switch
            name='monitor'
            label={i18n.t<string>('orderForm.labels.monitor')}
            className={styles.nativeBannerSwitch}
            hint={i18n.t<string>('orderForm.labels.monitor')}
          />
        }
      </div>
    );
  }
}
