import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { useCallback, useEffect, useState } from 'react';
import formatters from './listFormatters';
import moment from 'moment';
import i18n from 'i18n';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { useCallAPI } from 'hooks/useCallAPI';
import { RdpManager, DefaultRdpManager } from 'core/rdp/RdpManager';
import { ProductSet } from 'core/product/Product';
import { useCoreContext } from 'contexts/coreContext';
import styles from './productSetList.module.scss';

export enum ProductSetListColumns {
  ID = 'id',
  UPDATE_FREQUENCY = 'update_frequency',
  LAST_UPDATED_TIME = 'lastUpdatedTime',
  PRODUCT_COUNT = 'quantity',
  EDITBTNS = 'editBtns'
}

const defaultRdpManager: RdpManager = new DefaultRdpManager();

export const useProductSetListModel = (
  advertiserId: number | string | undefined,
  startDate: string,
  endDate: string
) => {
  const coreContext = useCoreContext();
  const agencyId = _.defaultTo(coreContext?.accountManager.localeMeta?.agencyId, 1);
  const [productSets, setProductSets] = useState<ProductSet[]>([]);
  const [filteredList, setFilteredList] = useState<ProductSet[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [deleteProductSet, setDeleteProductSet] = useState<() => Promise<void> | undefined>();
  const [selectedProductSet, setSelectedProductSet] = useState<ProductSet>();
  const [productSetDetail, setProductSetDetail] = useState<ProductSet>();
  const {
    loading,
    callAPIs
  } = useCallAPI();

  const fetchProductSetList = useCallback(async () => {
    callAPIs(
      [defaultRdpManager.getAllRetailProductSets.bind(defaultRdpManager, agencyId, advertiserId)],
      (productSets: ProductSet[]) => {
        setProductSets(productSets);
      }
    );
  }, [agencyId, advertiserId, callAPIs]);

  useEffect(() => {
    fetchProductSetList();
  }, [fetchProductSetList]);

  const fetchProductSetDetail = useCallback(async () => {
    if (!_.isNil(selectedProductSet)) {
      const { retailId, id: productSetId } = selectedProductSet;
      callAPIs(
        [defaultRdpManager.getProductSetDetail.bind(defaultRdpManager, retailId, productSetId)],
        (productSet: ProductSet) => {
          setProductSetDetail(productSet);
        }
      );
    }
  }, [selectedProductSet, callAPIs]);

  useEffect(() => {
    fetchProductSetDetail();
  }, [fetchProductSetDetail]);

  useEffect(() => {
    const filteredList = productSets.filter(productSet => {
      const nameInclude = productSet.name.toLowerCase().includes(searchString.toLowerCase());
      const idInclude = productSet.id.toString().includes(searchString);
      const advertiserMatch = productSet.advertiser === advertiserId || advertiserId === undefined;
      const dateInRange = moment(startDate).isBefore(productSet.createdTime) && moment(endDate).add(1, 'day').isAfter(productSet.createdTime);
      return (nameInclude || idInclude) && advertiserMatch && dateInRange;
    });

    setFilteredList(filteredList);
  }, [advertiserId, searchString, productSets, startDate, endDate]);

  const onDeleteBtnClick = (productSetToDelete: number) => {
    setDeleteProductSet(_1 => _.partial(deleteFunc, productSetToDelete));
  };

  const onDetailBtnClick = (productSetToViewDetail: number) => {
    setSelectedProductSet(productSets.find(productSet => productSet.id === productSetToViewDetail));
  };

  const onDeleteModalClose = useCallback((refresh: boolean) => {
    setDeleteProductSet(undefined);
    refresh && fetchProductSetList();
  }, [fetchProductSetList]);

  const onDetailModalClose = useCallback(() => {
    setProductSetDetail(undefined);
  }, []);

  const deleteFunc = useCallback(async (productSetId: number) => {
    callAPIs(
      [() => {
        try {
          console.log(productSetId);
          onDeleteModalClose(true);
        } catch (e) {
          (e instanceof Error) && toast.error(e.message);
        }
      }]
    );
  }, [onDeleteModalClose, callAPIs]);

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `productSetList.headers.${_.camelCase(columnName)}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const columns = [
    renderColumn({
      ...columnDefinition(ProductSetListColumns.ID),
      formatExtraData: {
        onDetailBtnClick
      }
    }, formatters.nameFormatter),
    renderColumn(columnDefinition(ProductSetListColumns.UPDATE_FREQUENCY), value =>
      value + (value > 1 ? i18n.t<string>('common.units.days') : i18n.t<string>('common.units.day'))),
    renderColumn(columnDefinition(ProductSetListColumns.LAST_UPDATED_TIME), value => moment(value).format('YYYY-MM-DD HH:mm:ss')),
    renderColumn(columnDefinition(ProductSetListColumns.PRODUCT_COUNT)),
    renderColumn({
      ...columnDefinition(ProductSetListColumns.EDITBTNS),
      text: '',
      sort: false,
      formatExtraData: {
        onDeleteBtnClick
      }
    }, formatters.floatingEditBtnsFormatter)
  ];

  return {
    loading,
    columns,
    filteredList,
    searchString,
    productSetDetail,
    deleteProductSet,
    setSearchString,
    onDeleteModalClose: _.partial(onDeleteModalClose, false),
    onDetailModalClose
  };
};
