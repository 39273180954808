import { useFormikContext } from 'formik';
import React from 'react';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';
import { FormikFieldProps } from 'hoc/withFormikField';

interface FormikCustomProps extends Omit<FormikFieldProps, 'name'> {
  render?: Function;
  name?: string;
  children?: React.ReactNode | ((props: any) => React.ReactNode);
}

export type CustomFieldProps = any;

export type FormikCustomWithFieldLayoutProps = FormikCustomProps & FieldLayoutProps;
export type FormikCustomFieldProps = ({ withFieldLayout?: true } & FormikCustomWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikCustomProps);

export const CustomField = withFieldLayout<CustomFieldProps>(({
  render,
  children,
  ...props
}: CustomFieldProps) => {
  if (children) {
    if (typeof children === 'function') {
      return children(props);
    }
    return children;
  }
  return render ? render(props) : <div/>;
});

CustomField.defaultProps = {
  fieldContentWidth: 'auto'
};

export const FormikCustom: React.FC<FormikCustomProps> = ({
  render,
  children,
  ...props
}: FormikCustomProps) => {

  const formikBag = useFormikContext();
  const newProps = {
    ...props,
    ...formikBag
  };

  if (children) {
    if (typeof children === 'function') {
      return children(newProps);
    }
    return children;
  }
  return render ? render(newProps) : <div/>;
};
export const FormikCustomWithFieldLayout = withFieldLayout<FormikCustomWithFieldLayoutProps>(FormikCustom);

export const FormikCustomField: React.FC<FormikCustomFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikCustom {...props} />;
  } else {
    return <FormikCustomWithFieldLayout {...props} />;
  }
};

FormikCustomField.defaultProps = {
  fieldContentWidth: 'auto'
};
