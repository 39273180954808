export function getCpc (spent: number, clicks: number) {
  let cpc = 0;
  if (clicks !== 0) {
    cpc = spent / clicks;
  }

  return cpc;
}

export function getCtr (impres: number, clicks: number) {
  let vctr = 0;
  if (impres !== 0) {
    vctr = (clicks / impres) * 100;
  }
  return vctr;
}

export function getVctr (viewable: number, clicks: number) {
  let vctr = 0;
  if (viewable !== 0) {
    vctr = (clicks / viewable) * 100;
  }
  return vctr;
}

export function getCpa (spent: number, convs: number) {
  let cpa = 0;
  if (convs !== 0) {
    cpa = spent / convs;
  }
  return cpa;
}

export function getCvr (convs: number, clicks: number) {
  let cvr = 0;
  if (clicks !== 0) {
    cvr = (convs / clicks) * 100;
  }
  return cvr;
}

export function getViewRate (view: number, impres: number) {
  let viewRate = 0;
  if (impres !== 0) {
    viewRate = (view / impres) * 100;
  }
  return viewRate;
}

export const getPercentValue = (divisor: number, dividend: number) => {
  return `${dividend === 0 ? 0.00.toFixed(2) : ((divisor / dividend) * 100).toFixed(2)}%`;
};

export const getDivideValue = (divisor: number, dividend: number) => {
  return dividend === 0 ? 0 : divisor / dividend;
};

export const metricsAdder = (acc, value) => {
  if (!value) {
    return acc;
  }
  return acc + value;
};

export const metricsFormatter = (formatter, value) => {
  let target = value;
  if (!value) {
    target = 0;
  }
  return formatter(target);
};
