import { faEye, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import React from 'react';
import styles from './productGroupList.module.scss';
import i18n from 'i18n';
import { Link } from 'react-router-dom';

const nameFormatter = (id: any, { groupName }: { id: number, groupName: string }): any => {
  return (
    <div className={`${styles.nameCell}`}>
      <Link to={`/system/product-groups/${id}/edit`} className={styles.name}>
        {groupName}
      </Link>
      <div className={styles.id}>
        {'ID: ' + id}
      </div>
    </div>
  );
};

const agenciesFormatter = (agencies: string[]) => {
  return (
    <div className={styles.productGroupAgencies}>
      {agencies.join('\n')}
    </div>
  );
};

const floatingEditBtnsFormatter = (_1, productGroup: { id: number, groupName: string }, _2, formatExtraData: any): any => {
  const onDeleteClickHandler = () => formatExtraData.onDeleteBtnClick(productGroup.id);
  const onCheckCampaignHandler = () => formatExtraData.showCampaigns(productGroup.id);
  return (
    <TableRowToolBar className={styles.floatingEditArea}>
      <IconWithTooltip
        icon={faPencilAlt}
        tooltipProps={{
          id: `productGroupListEditTip-${productGroup.id}`,
          link: `/system/product-groups/${productGroup.id}/edit`,
          tooltip: i18n.t<string>('productGroupList.labels.editHint')
        }}
      />
      <IconWithTooltip
        icon={faEye}
        onClick={onCheckCampaignHandler}
        tooltipProps={{
          id: `productGroupListShowCampaignTip-${productGroup.id}`,
          tooltip: i18n.t<string>('productGroupList.labels.showCampaignHint')
        }}
      />
      <IconWithTooltip
        icon={faTrashAlt}
        onClick={onDeleteClickHandler}
        tooltipProps={{
          id: `productGroupListDeleteTip-${productGroup.id}`,
          tooltip: i18n.t<string>('productGroupList.labels.deleteHint')
        }}
      />
    </TableRowToolBar>
  );
};

const formatters = {
  nameFormatter,
  agenciesFormatter,
  floatingEditBtnsFormatter
};
export default formatters;
