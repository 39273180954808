import React from 'react';
import { RecentGroupDropdown } from 'components/RecentGroupDropdown/RecentGroupDropdown';
import { SegmentHomeProps } from './SegmentHomeModel';
import { SegmentList } from 'components/SegmentList/SegmentList';
import { SessionStorageItemKeys } from 'helper/StorageHelper';
import styles from './segmentHome.module.scss';
import i18n from 'i18next';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasRoles, notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Page } from 'components/Page/Page';

export class SegmentHome extends React.Component<SegmentHomeProps> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  onAgencySelected = (agencyId?: number) => {
    this.props.model.setAgencyId && this.props.model.setAgencyId(agencyId);
  }

  onAdvertiserSelected = (advertiserId?: number) => {
    this.props.model.setAdvertiserId(advertiserId);
  }

  renderFilterArea = () => {
    const model = this.props.model;
    return (
      <div className={styles.filterArea}>
        <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
          <div className={styles.agencyList}>
            <div className={styles.title}>
              {i18n.t<string>('segmentHome.labels.agencyListTitle')}
            </div>
            <RecentGroupDropdown
              value={model.state.agencyId}
              options={model.state.agencies}
              storageKey={SessionStorageItemKeys.AGENCY}
              callback={this.onAgencySelected}
              showShowAllBtn={false}
            />
          </div>
        </PermissionChecker>
        <PermissionChecker
          permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)}
        >
          <div className={styles.advertiserList}>
            <div className={styles.title}>
              {i18n.t<string>('segmentHome.labels.advertiserListTitle')}
            </div>
            <RecentGroupDropdown
              value={model.state.advertiserId}
              options={model.state.advertisers}
              storageKey={SessionStorageItemKeys.ADVERTISER}
              callback={this.onAdvertiserSelected}
            />
          </div>
        </PermissionChecker>
      </div>
    );
  }

  render () {
    const model = this.props.model;
    return (
      <Page title={i18n.t<string>('segmentHome.labels.title')} renderInTopArea={this.renderFilterArea}>
        {model.state.loading && <LoadingIndicator/>}
        <SegmentList model={this.props.model.getSegmentListModel()}/>
      </Page>
    );
  }
}
