import { AbstractFormContentModel } from './FormContentModel';
import ComboForm from './ComboForm';
import { Creative } from 'core/creative/Creative';
import _ from 'lodash';
import i18n from 'i18next';
import { formatBytes } from 'utils/StringUtil';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { BasicFormProps } from './BasicFormProps';

const mimeDB = require('mime-db');

export type ComboFormProps = {
  readonly model: ComboFormModel;
} & BasicFormProps;

export class ComboFormModel extends AbstractFormContentModel {

  public needBannerUrl: boolean = true;

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = () => {
    return ComboForm;
  }

  validateImage = (fileData) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];
    const width = fileData.width;
    const height = fileData.height;
    if (width !== 600 && height !== 500) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.sizeErrorHint2', { size1: `${width} x ${height}`, size2: '600 x 500' }));
    }

    if (file.size > 2097152) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validateVideo = (fileData) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    const validTypes = ['video/mp4'];
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];
    if (fileData.height > 720) {
      errors.push(i18n.t<string>('comboForm.labels.videoHeightErrorHint', { height1: fileData.height, height2: 720 }));
    }

    if (fileData.duration > 120) {
      errors.push(i18n.t<string>('comboForm.labels.videoDurationErrorHint', { sec1: fileData.duration, sec2: 120 }));
    }

    if (file.size > 10485760) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '10MB' }));
    }
    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getInitTypeProperties () {
    return undefined;
  }

  getFormModelData (creative: Creative) {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const imageUrl = _.get(creativeValue, 'imageUrl');
    const videoUrl = _.get(creativeValue, 'videoUrl');
    const mediaData = imageUrl || videoUrl ? _.omitBy({
      image: imageUrl ? {
        url: imageUrl
      } : undefined,
      video: videoUrl ? {
        url: videoUrl,
        width: _.get(creativeValue, 'videoW'),
        height: _.get(creativeValue, 'videoH'),
        duration: _.get(creativeValue, 'duration')
      } : undefined
    }, _.isEmpty) : undefined;
    return {
      basic: {
        ...creativeBasic,
        medias: mediaData
      },
      limitations: creative.limitations
    };
  }
}
