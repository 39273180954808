import React from 'react';
import { SegmentsProps } from './SegmentsModel';
import { Switch, Route, Redirect } from 'react-router-dom';
import { SegmentHome } from './SegmentHome/SegmentHome';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { SegmentForm } from './SegmentForm/SegmentForm';
import { SegmentDetail } from './SegmentDetail/SegmentDetail';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';

const SegmentHomeView = withErrorBoundary(ERROR_CODE.SEGMENT_HOME, SegmentHome);
const CreateSegmentView = withErrorBoundary(ERROR_CODE.SEGMENT_CREATE, SegmentForm);
const EditSegmentView = withErrorBoundary(ERROR_CODE.SEGMENT_EDIT, SegmentForm);
const SegmentDetailView = withErrorBoundary(ERROR_CODE.SEGMENT_DETAIL, SegmentDetail);
export class Segments extends React.PureComponent<SegmentsProps> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  renderSegmentHome = () => {
    return <SegmentHomeView model={this.props.model.getSegmentHomeModel()}/>;
  }

  renderSegmentCreateForm = (props) => {
    return <CreateSegmentView {...props} model={this.props.model.getCreateSegmentFormModel()}/>;
  }

  renderSegmentEditForm = (props) => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`}/>;
    const segmentId = parseInt(props.match.params.segmentId, 10);
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.SEGMENT_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <EditSegmentView {...props} model={this.props.model.getEditSegmentFormModel(segmentId)}/>
      </PermissionChecker>
    );
  }

  renderSegmentDetail = (props) => {
    const segmentId = parseInt(props.match.params.segmentId, 10);
    return <SegmentDetailView model={this.props.model.getSegmentDetailModel(segmentId)}/>;
  }

  render () {
    if (this.props.model.state.loading) {
      return <LoadingIndicator />;
    }
    return (
      <Switch>
        <RouteWithPermission
          permissionAware={hasFuncs(Permission.SEGMENT_WRITE)}
          redirectPath={`/audience-segments/new/${ROUTE_PATH.ERROR403}`}
          path={'/audience-segments/new'}
          render={this.renderSegmentCreateForm}
        />
        <Route
          path={'/audience-segments/:segmentId/edit'}
          render={this.renderSegmentEditForm}
        />
        <Route
          path={'/audience-segments/:segmentId'}
          render={this.renderSegmentDetail}
        />
        <Route
          render={this.renderSegmentHome}
        />
      </Switch>
    );
  }
}
