import { L1Object } from 'core/l1Object/L1Object';
import { Order } from 'core/order/Order';
import moment from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { FbAdSetFormData } from '../FbAdSetSetupFlowPageModel';
import _ from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { useCoreContext } from 'contexts/coreContext';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { DefaultFbAdSetManager, FbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { createSelectOptions } from 'utils/SelectOptionsUtils';
import { FbPacingType } from 'core/fbAdSet/FbAdSet';

type FbAdSetBasicFormModelData = {
  currency: string,
  canEditBidCap: boolean,
  dateRangeProps: {
    minDate: string,
    maxDate: string,
    isAdSetStart?: boolean
  },
  l1Object: L1Object,
  billingEventOptions: SelectOptions[],
  appEventOptions: SelectOptions[] | undefined,
  optimizationGoalOptions: SelectOptions[],
  pacingTypeOptions: SelectOptions[],
  onOptimizationGoalChanged: (
    afterChangedCallback: (newOptimizationGoal, newDefaultBillingEvent) => void,
    newOptimizationGoal: L2ObjectOptimizationGoal
  ) => void
};

export type FbAdSetFormModelData = FbAdSetBasicFormModelData & {
  canEditBidStrategy: boolean;
  formType: string;
};

const defaultFbAdSetManager: FbAdSetManager = new DefaultFbAdSetManager();

const useFbAdSetFormModel = (
  order: Order,
  l1Object: L1Object,
  adSet: FbAdSetFormData,
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager
): FbAdSetBasicFormModelData => {

  const core = useCoreContext();

  const minDate = useMemo(() => {
    const orderStartDate = moment(_.get(order, 'startDate'));
    const thisHour = moment().startOf('hour').format('YYYY-MM-DD_HH:mm:ss');
    if (adSet && adSet.start_time) {
      return moment(adSet.start_time).isBefore() ? moment(adSet.start_time).format('YYYY-MM-DD_HH:mm:ss') : thisHour;
    }
    return moment().isAfter(orderStartDate) ? thisHour : orderStartDate.format('YYYY-MM-DD_HH:mm:ss');
  }, [order, adSet]);

  const maxDate = useMemo(() => {
    return moment(_.get(order, 'endDate')).endOf('day').format('YYYY-MM-DD HH:mm:ss');
  }, [order]);

  const optimizationGoalOptions = useMemo(() => {
    return fbAdSetManager.getOptimizationGoalOptions(l1Object);
  }, [l1Object, fbAdSetManager]);

  const pacingTypeOptions = useMemo(() => {
    return createSelectOptions([
      FbPacingType.STANDARD,
      FbPacingType.NO_PACING
    ], 'adSetSetupFlow.mainStep.labels.');
  }, []);

  const [billingEventOptions, setBillingEventOptions] = useState<SelectOptions[]>(
    fbAdSetManager.getBilliingEventOptions(adSet.optimization_goal)
  );

  const [appEventOptions, setAppEventOptions] = useState<SelectOptions[] | undefined>(
    fbAdSetManager.getAppEventOptions(adSet.optimization_goal)
  );

  const onOptimizationGoalChanged = useCallback((afterChangedCallback, newOptimizationGoal) => {
    const billingEventOptions = fbAdSetManager.getBilliingEventOptions(newOptimizationGoal);
    setBillingEventOptions(billingEventOptions);
    setAppEventOptions(fbAdSetManager.getAppEventOptions(newOptimizationGoal));
    afterChangedCallback(newOptimizationGoal, _.get(billingEventOptions[0], 'value'));
  }, [fbAdSetManager]);

  return {
    l1Object: l1Object,
    dateRangeProps: {
      minDate,
      maxDate
    },
    billingEventOptions,
    appEventOptions,
    optimizationGoalOptions,
    pacingTypeOptions,
    currency: order.currency,
    canEditBidCap: core ? core.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE) : true,
    onOptimizationGoalChanged
  };
};

export const useFbCreateAdSetFormModel = (
  order: Order,
  l1Object: L1Object,
  adSet: FbAdSetFormData
): FbAdSetFormModelData => {

  const basicFormModelData = useFbAdSetFormModel(order, l1Object, adSet);
  return {
    formType: 'create',
    ...basicFormModelData,
    dateRangeProps: {
      ...basicFormModelData.dateRangeProps,
      isAdSetStart: false
    },
    canEditBidStrategy: l1Object.autoOptimise ? false : true
  };
};

export const useFbEditAdSetFormModel = (
  order: Order,
  l1Object: L1Object,
  adSet: FbAdSetFormData
): FbAdSetFormModelData => {

  const isAdSetStart = useMemo(() => {
    return adSet && adSet.start_time ? moment(adSet.start_time).isBefore() : false;
  }, [adSet]);

  const basicFormModelData = useFbAdSetFormModel(order, l1Object, adSet);
  return {
    formType: 'edit',
    ...basicFormModelData,
    dateRangeProps: {
      ...basicFormModelData.dateRangeProps,
      isAdSetStart
    },
    canEditBidStrategy: false
  };
};
