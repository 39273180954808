import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { FbAdSetManager, DefaultFbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { DraftManager, FbAdSetDraftManager } from 'core/draft/DraftManager';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './fbAdSetList.module.scss';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import formatters, { getAdSetStatusDesData, getAdSetEffectiveStatusDesData } from './listFormatters';
import _ from 'lodash';
import { CampaignState } from 'core/rtbCampaign/RtbCampaign';
import { numberWithCommas } from 'utils/StringUtil';
import i18n from 'i18n';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Order, State } from 'core/order/Order';
import moment from 'moment';
import { L1Object } from 'core/l1Object/L1Object';
import { formatPrice, getPriceValue } from 'helper/CurrencyHelper';
import { useCallAPI } from 'hooks/useCallAPI';
import {
  getCpa,
  getCpc,
  getCtr,
  getCvr,
  getVctr,
  getViewRate,
  getDivideValue,
  getPercentValue,
  metricsAdder,
  metricsFormatter
} from 'helper/MetricsHelper';
import { FilterMenuTabConfig } from 'components/common/FilterMenuTab/FilterMenuTab';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';

export enum FbAdSetListColumns {
  LISTID = 'listId',
  ID = 'id',
  BUDGET = 'budget',
  STATE = 'state',
  DELIVERY = 'delivery',
  CREATIVE = 'enableCount',
  OPTIMIZATION = 'optimization_goal',
  SCHEDULING = 'start_time',
  TARGETING = 'targeting',
  RESULTS = 'results',
  IMPRES = 'impres',
  VIEWABLE = 'viewable',
  CLICKS = 'clicks',
  CPC = 'cpc',
  CTR = 'ctr',
  VCTR = 'vctr',
  CONVERT = 'convs',
  VIDEO_VIEW = 'video_view',
  VIDEO_VIEW_RATE = 'video_view_rate',
  CPA = 'cpa',
  CVR = 'cvr',
  UU = 'uu',
  SPENT = 'spent',
  APP_CUSTOM_EVENT = 'app_custom_event',
  APP_CUSTOM_EVENT_FB_MOBILE_ACTIVATE_APP = 'app_custom_event_fb_mobile_activate_app',
  APP_CUSTOM_EVENT_FB_MOBILE_ADD_PAYMENT_INFO = 'app_custom_event_fb_mobile_add_payment_info',
  APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_CART = 'app_custom_event_fb_mobile_add_to_cart',
  APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_WISHLIST = 'app_custom_event_fb_mobile_add_to_wishlist',
  APP_CUSTOM_EVENT_FB_MOBILE_COMPLETE_REGISTRATION = 'app_custom_event_fb_mobile_complete_registration',
  APP_CUSTOM_EVENT_FB_MOBILE_CONTENT_VIEW = 'app_custom_event_fb_mobile_content_view',
  APP_CUSTOM_EVENT_FB_MOBILE_INITIATED_CHECKOUT = 'app_custom_event_fb_mobile_initiated_checkout',
  APP_CUSTOM_EVENT_FB_MOBILE_PURCHASE = 'app_custom_event_fb_mobile_purchase',
  APP_CUSTOM_EVENT_FB_MOBILE_SEARCH = 'app_custom_event_fb_mobile_search',
  APP_CUSTOM_EVENT_OTHER = 'app_custom_event_other',
  COMMENT = 'comment',
  LIKE = 'like',
  LINK_CLICK = 'link_click',
  POST = 'post',
  POST_ENGAGEMENT = 'post_engagement',
  POST_REACTION = 'post_reaction',
  EDITBTNS = 'editBtns'
}

export enum FbAdSetListType {
  BASIC = 'basic',
  PERFORMANCE = 'performance',
  FB_VIDEO_PERFORMANCE = 'fb_video_performance',
  FB_ONSITE_ACTIONS = 'fb_onsite_actions',
  APP_ACTIONS = 'app_actions'
}

export type FbAdSetListState = {
  readonly loading: boolean;
  readonly rate: string;
  readonly currentCurrency: string;
  readonly currencyRates: { [key: string]: FbAdSet[] };
};

const defaultFbAdSetManager: FbAdSetManager = new DefaultFbAdSetManager();
const defaultDraftManager: DraftManager = new FbAdSetDraftManager();
const defaultAdRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager();

export const useFbAdSetListModel = (
  order: Order,
  l1Object: L1Object | undefined,
  budgetBalance: number,
  fbAdSets: FbAdSet[],
  refreshList: () => void,
  addonFeatureManager,
  fbAdSetManager: FbAdSetManager = defaultFbAdSetManager,
  fbAdSetDraftManager: DraftManager = defaultDraftManager,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager
) => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultSearchString = searchParams.get('search');
  const { loading, callAPIs } = useCallAPI();
  const [filteredList, setFilteredList] = useState<any[]>([]);
  const [searchString, setSearchString] = useState<string>(_.defaultTo(defaultSearchString, ''));
  const [selectedAdSets, setSelectedAdSets] = useState<(number | string)[]>([]);
  const [selectedDrafts, setSelectedDrafts] = useState<(number | string)[]>([]);
  const [adSetToDelete, setAdSetToDelete] = useState<(number | string)[] | undefined>();
  const [deleteDraftIds, setDeleteDraftIds] = useState<(number | string)[]>([]);
  const [showDeleteDraftConfirmModal, setShowDeleteDraftConfirmModal] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>();
  const [selectedMetricsGroup, setSelectedMetricsGroup] = useState<FbAdSetListType>(FbAdSetListType.BASIC);
  const [showDraftCreateModal, setShowDraftCreateModal] = useState<boolean>(false);
  const [objectTypes, setObjectTypes] = useState<string[]>([]);
  const [adSetsDeliveryStatus, setAdSetsDeliveryStatus] = useState<string[]>([]);
  const [adSetsConfigureStatus, setAdSetsConfigureStatus] = useState<string[]>([]);
  const [selectedObjectTypeFilter, setSelectedObjectTypeFilter] = useState<string[]>([]);
  const [selectedDeliveryStatusFilter, setSelectedDeliveryStatusFilter] = useState<string[]>([]);
  const [selectedConfigureStatusFilter, setSelectedConfigureStatusFilter] = useState<string[]>([]);
  const [i18nInited, setI18nInited] = useState(false);
  const match = useRouteMatch();
  const history = useHistory();

  const onHandleSearch = (searchString: string) => setSearchString(searchString);

  useEffect(() => {
    const initFbCountriesI18n = async () => {
      callAPIs([() => adRequestSourceManager.getFBCountries()], () => setI18nInited(true));
    };
    initFbCountriesI18n();
  }, [adRequestSourceManager, callAPIs]);

  const fbAdSetWithPerformanceList = useMemo(() => {
    const list = fbAdSets.map(fbAdSet => {
      const report = fbAdSet.report;
      const adSetConversions = _.defaultTo(fbAdSet.conversion, []);
      const conversion = _.mapValues(
        _.keyBy(adSetConversions.map(data => ({ ...data, action_type: data.action_type.replace('.', '_') })), 'action_type'),
        'actions'
      );
      const videoViewAction = adSetConversions.find(data => data.action_type === 'video_view');
      const videoView = _.get(videoViewAction, 'actions', 0);
      const isDraft: boolean = _.get(fbAdSet, 'isDraft', false);
      const listId = isDraft ? `${fbAdSet.id}_draft_${fbAdSet.draftId}` : fbAdSet.id;
      const delivery = getAdSetEffectiveStatusDesData(fbAdSet);
      const budget = {
        lifetime: +_.defaultTo(fbAdSet.lifetime_budget, -1),
        daily: +_.defaultTo(fbAdSet.daily_budget, -1)
      };
      const bidStrategy = _.defaultTo(fbAdSet.bid_strategy, _.get(l1Object, 'fb.bid_strategy'));
      if (report) {
        return {
          ..._.omit(fbAdSet, ['report', 'conversion']),
          ..._.omit(report, ['name']),
          ...conversion,
          state: getAdSetStatusDesData(fbAdSet),
          delivery,
          listId,
          budget,
          bid_strategy: bidStrategy,
          [FbAdSetListColumns.TARGETING]: getTAToShow(fbAdSet.targeting, i18nInited),
          [FbAdSetListColumns.CPA]: getPriceValue(order.currency, getCpa(_.get(report, 'spent', 0), report.convs)),
          [FbAdSetListColumns.CPC]: getPriceValue(order.currency, getCpc(_.get(report, 'spent', 0), report.clicks)),
          [FbAdSetListColumns.CTR]: getCtr(report.impres, report.clicks),
          [FbAdSetListColumns.CVR]: getCvr(report.convs, report.clicks),
          [FbAdSetListColumns.VCTR]: getVctr(report.viewable, report.clicks),
          [FbAdSetListColumns.SPENT]: _.get(report, 'spent', 0),
          [FbAdSetListColumns.VIDEO_VIEW]: videoView,
          [FbAdSetListColumns.VIDEO_VIEW_RATE]: getViewRate(videoView, report.impres)
        };
      }
      return {
        ...fbAdSet,
        ...conversion,
        state: getAdSetStatusDesData(fbAdSet),
        delivery,
        listId,
        bid_strategy: bidStrategy,
        budget,
        [FbAdSetListColumns.TARGETING]: getTAToShow(fbAdSet.targeting, i18nInited),
        [FbAdSetListColumns.CPA]: getPriceValue(order.currency, 0),
        [FbAdSetListColumns.CPC]: getPriceValue(order.currency, 0),
        [FbAdSetListColumns.CTR]: 0,
        [FbAdSetListColumns.CVR]: 0,
        [FbAdSetListColumns.VCTR]: 0,
        [FbAdSetListColumns.SPENT]: 0,
        [FbAdSetListColumns.VIDEO_VIEW]: videoView,
        [FbAdSetListColumns.VIDEO_VIEW_RATE]: 0
      };
    });

    setAdSetsDeliveryStatus(_.uniq(_.compact(list.map(adSet => {
      return _.get(adSet, 'delivery.des', '');
    }))));

    setAdSetsConfigureStatus(_.uniq(list.map(adSet => {
      return adSet.state.des;
    })));
    return list;
  }, [order.currency, fbAdSets, l1Object, i18nInited]);

  useEffect(() => {
    const filteredList: any[] = fbAdSetWithPerformanceList.filter(fbAdSet => {
      return fbAdSetListFilter(
        fbAdSet,
        searchString,
        selectedDeliveryStatusFilter,
        selectedConfigureStatusFilter,
        selectedObjectTypeFilter
      );
    });
    setFilteredList(filteredList);

    const objectTypes = _.uniq<string>(
      fbAdSetWithPerformanceList.map(getObjectTypeDesc)
    );
    setObjectTypes(objectTypes);

    const viewableSum = filteredList.reduce<number>((partial, fbAdSet) => metricsAdder(partial, fbAdSet.viewable), 0);
    const clickSum = filteredList.reduce<number>((partial, fbAdSet) => metricsAdder(partial, fbAdSet.clicks), 0);
    const convsSum = filteredList.reduce<number>((partial, fbAdSet) => metricsAdder(partial, fbAdSet.convs), 0);
    const spentSum = filteredList.reduce<number>((partial, fbAdSet) => metricsAdder(partial, getPriceValue(order.currency, fbAdSet.spent)), 0);
    const impresSum = filteredList.reduce<number>((partial, fbAdSet) => metricsAdder(partial, fbAdSet.impres), 0);
    const videoViewsSum = filteredList.reduce<number>((partial, fbAdSet) => metricsAdder(partial, +fbAdSet[FbAdSetListColumns.VIDEO_VIEW]), 0);
    const actionColumns = [
      FbAdSetListColumns.APP_CUSTOM_EVENT,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ACTIVATE_APP,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_PAYMENT_INFO,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_CART,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_WISHLIST,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_COMPLETE_REGISTRATION,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_CONTENT_VIEW,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_INITIATED_CHECKOUT,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_PURCHASE,
      FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_SEARCH,
      FbAdSetListColumns.APP_CUSTOM_EVENT_OTHER,
      FbAdSetListColumns.COMMENT,
      FbAdSetListColumns.LIKE,
      FbAdSetListColumns.LINK_CLICK,
      FbAdSetListColumns.POST,
      FbAdSetListColumns.POST_ENGAGEMENT,
      FbAdSetListColumns.POST_REACTION
    ];
    const actionSummaryData = actionColumns.reduce((acc, column) => {
      const sum = filteredList.reduce<number>((partial, fbAdSet) => metricsAdder(partial, +fbAdSet[column]), 0);
      acc[column] = numberWithCommas(sum);
      return acc;
    }, {});
    const summaryData = {
      [FbAdSetListColumns.LISTID]: i18n.t<string>('l1ObjectList.labels.l1ObjectCount', { count: filteredList.length }),
      [FbAdSetListColumns.IMPRES]: numberWithCommas(impresSum),
      [FbAdSetListColumns.VIEWABLE]: numberWithCommas(viewableSum),
      [FbAdSetListColumns.CLICKS]: numberWithCommas(clickSum),
      [FbAdSetListColumns.CONVERT]: numberWithCommas(convsSum),
      [FbAdSetListColumns.CPC]: formatPrice(order.currency, getDivideValue(spentSum, clickSum)),
      [FbAdSetListColumns.CTR]: getPercentValue(clickSum, impresSum),
      [FbAdSetListColumns.VCTR]: getPercentValue(clickSum, viewableSum),
      [FbAdSetListColumns.CPA]: formatPrice(order.currency, getDivideValue(spentSum, convsSum)),
      [FbAdSetListColumns.CVR]: getPercentValue(convsSum, clickSum),
      [FbAdSetListColumns.SPENT]: numberWithCommas(spentSum),
      [FbAdSetListColumns.VIDEO_VIEW]: numberWithCommas(videoViewsSum),
      [FbAdSetListColumns.VIDEO_VIEW_RATE]: getPercentValue(videoViewsSum, impresSum),
      ...actionSummaryData
    };
    setSummaryData(summaryData);
  }, [searchString, fbAdSetWithPerformanceList, order.currency, selectedObjectTypeFilter, selectedConfigureStatusFilter, selectedDeliveryStatusFilter]);

  const onSelect = (adSet: FbAdSet) => {
    const isDraft: boolean = _.get(adSet, 'isDraft', false);
    let objectId = adSet.id;
    let selectedArray = selectedAdSets;
    let setSelectedArray = setSelectedAdSets;
    if (isDraft) {
      objectId = _.get(adSet, 'draftId', 0);
      selectedArray = selectedDrafts;
      setSelectedArray = setSelectedDrafts;
    }

    setSelectedArray(handleOnSelect(selectedArray, objectId));
  };

  const onSelectAll = () => {
    const selectedArrays = [...selectedAdSets, ...selectedDrafts];
    const {
      selectedAdSets: newSelectedAdSets,
      selectedDrafts: newSelectedDrafts
    } = handleOnSelectAll(selectedArrays, filteredList);

    setSelectedAdSets(newSelectedAdSets);
    setSelectedDrafts(newSelectedDrafts);
  };

  const handleRemoveSelect = () => {
    setSelectedAdSets([]);
    setSelectedDrafts([]);
  };

  const invokeRefreshList = useCallback((refresh: boolean) => {
    refresh && refreshList();
  }, [refreshList]);

  const onDeleteModalClose = useCallback((refresh: boolean) => {
    setAdSetToDelete(undefined);
    invokeRefreshList(refresh);
  }, [invokeRefreshList]);

  const onDeleteDraftModalClose = useCallback((refresh: boolean) => {
    setShowDeleteDraftConfirmModal(false);
    setDeleteDraftIds([]);
    invokeRefreshList(refresh);
  }, [invokeRefreshList]);

  const deleteAdSets = useCallback(async (adSetIds: (number | string)[]) => {
    callAPIs([
      fbAdSetManager.deleteAdSets.bind(fbAdSetManager, adSetIds)
    ], () => {
      setSelectedAdSets([]);
      setSelectedDrafts([]);
      onDeleteModalClose(true);
    });
  }, [fbAdSetManager, onDeleteModalClose, callAPIs]);

  const deleteDraft = useCallback(async (draftIds: (number | string)[]) => {
    setShowDeleteDraftConfirmModal(false);
    callAPIs([
      fbAdSetDraftManager.deleteDrafts.bind(fbAdSetDraftManager, draftIds)
    ], () => {
      setSelectedAdSets([]);
      setSelectedDrafts([]);
      onDeleteDraftModalClose(true);
    });
  }, [fbAdSetDraftManager, onDeleteDraftModalClose, callAPIs]);

  const onDeleteBtnClick = (adSetToDelete: number | string) => {
    setAdSetToDelete([adSetToDelete]);
  };

  const columnDefinition = (columnName, customLabel?: string, sortFunc?: any): ColumnDefinition => ({
    ...sortableColumn(columnName, _.defaultTo(customLabel, `fbAdSetList.headers.${columnName}`), true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName],
    sortFunc
  });

  const idColumn = renderColumn({
    ...columnDefinition(FbAdSetListColumns.LISTID),
    sortFunc: idSorter,
    formatExtraData: {
      currentUrl: match.url,
      selectedAdSets,
      selectedDrafts,
      onSelect,
      l1Object: _.defaultTo(l1Object, { isSelfServe: true })
    }
  },
  formatters.nameFormatter,
  _.partial(
    formatters.nameHeaderFormatter,
    filteredList.length,
    [...selectedAdSets, ...selectedDrafts],
    onSelectAll,
    selectedConfigureStatusFilter.includes(i18n.t<string>('campaignList.labels.deleteState'))
  ));

  const budgetColumn = renderColumn({
    ...columnDefinition(
      FbAdSetListColumns.BUDGET,
      i18n.t<string>(`fbAdSetList.headers.${FbAdSetListColumns.BUDGET}`) + ` (${order.currency})`,
      budgetSorter
    ),
    formatExtraData: {
      currency: order.currency
    }
  }, formatters.budgetFormatter);

  const optimizationColumn = renderColumn({
    ...columnDefinition(FbAdSetListColumns.OPTIMIZATION),
    formatExtraData: {
      currency: order.currency
    }
  }, formatters.optimizationFormatter);

  const editBtnsColumn = renderColumn({
    ...columnDefinition(FbAdSetListColumns.EDITBTNS),
    text: '',
    sort: false,
    formatExtraData: {
      currentUrl: match.url,
      onDeleteBtnClick,
      canDeleteSelectedAdSets: _.partial(canDeleteSelectedAdSets, fbAdSets),
      l1Object: _.defaultTo(l1Object, { isSelfServe: true })
    }
  }, formatters.floatingEditBtnsFormatter);

  const basicColumns = useMemo(() => ([
    idColumn,
    renderColumn({
      ...columnDefinition(FbAdSetListColumns.STATE),
      sortFunc: (stateA, stateB, order) => {
        const stateDes = [
          i18n.t<string>('campaignList.labels.activateState'),
          i18n.t<string>('campaignList.labels.deactivateState'),
          i18n.t<string>('campaignList.labels.deleteState')
        ];
        const dataAToCompare = stateDes.indexOf(stateA.des);
        const dataBToCompare = stateDes.indexOf(stateB.des);
        return basicSorter(dataAToCompare, dataBToCompare, order);
      }
    }, formatters.stateFormatter),
    renderColumn({
      ...columnDefinition(FbAdSetListColumns.DELIVERY),
      sortFunc: (stateA, stateB, order) => {
        const stateDes = [
          i18n.t<string>('campaignList.labels.budgetRemainState'),
          i18n.t<string>('campaignList.labels.activateState'),
          i18n.t<string>('campaignList.labels.deactivateState'),
          i18n.t<string>('campaignList.labels.noCreativesState'),
          i18n.t<string>('campaignList.labels.notStartState'),
          i18n.t<string>('campaignList.labels.endState'),
          i18n.t<string>('campaignList.labels.deleteState')
        ];
        const dataAToCompare = stateDes.indexOf(stateA.des);
        const dataBToCompare = stateDes.indexOf(stateB.des);
        return basicSorter(dataAToCompare, dataBToCompare, order);
      }
    }, formatters.deliveryFormatter),
    renderColumn({
      ...columnDefinition(FbAdSetListColumns.CREATIVE),
      formatExtraData: {
        onClick: _.partial(onCreativeBindingBtnClick, match.url, history),
        l1Object: _.defaultTo(l1Object, { isSelfServe: true })
      }
    }, formatters.creativeBindingFormatter),
    budgetColumn,
    renderColumn(columnDefinition(FbAdSetListColumns.SCHEDULING), formatters.scheduleFormatter),
    optimizationColumn,
    renderColumn({
      ...columnDefinition(FbAdSetListColumns.TARGETING),
      sort: false
    }, formatters.targetingFormatter),
    editBtnsColumn
  ]), [
    budgetColumn,
    editBtnsColumn,
    idColumn,
    l1Object,
    optimizationColumn,
    match.url,
    history
  ]);

  const spentColumn = renderColumn(
    columnDefinition(
      FbAdSetListColumns.SPENT,
      i18n.t<string>(`fbAdSetList.headers.${FbAdSetListColumns.SPENT}`) + ` (${order.currency})`),
      _.partial(metricsFormatter, _.partial(formatPrice, order.currency)
    )
  );

  const impresColumn = renderColumn(
    columnDefinition(FbAdSetListColumns.IMPRES),
    _.partial(metricsFormatter, numberWithCommas)
  );

  const viewableEnable = addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_VIEWABLE_CTR);
  const convsEnable = addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST);
  let viewableColumn;
  let vcrColumn;
  if (viewableEnable) {
    viewableColumn = renderColumn(columnDefinition(FbAdSetListColumns.VIEWABLE), _.partial(metricsFormatter, numberWithCommas));
    vcrColumn = renderColumn(columnDefinition(FbAdSetListColumns.VCTR), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`));
  }
  let convertColumn;
  let cpaColumn;
  let cvrColumn;
  if (convsEnable) {
    convertColumn = renderColumn(columnDefinition(FbAdSetListColumns.CONVERT), _.partial(metricsFormatter, numberWithCommas));
    cpaColumn = renderColumn(
      columnDefinition(
        FbAdSetListColumns.CPA,
        i18n.t<string>(`fbAdSetList.headers.${FbAdSetListColumns.CPA}`) + ` (${order.currency})`),
        _.partial(formatPrice, order.currency)
    );
    cvrColumn = renderColumn(columnDefinition(FbAdSetListColumns.CVR), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`));
  }

  const performanceColumns = _.compact([
    idColumn,
    budgetColumn,
    optimizationColumn,
    renderColumn({
      ...columnDefinition(FbAdSetListColumns.RESULTS),
      formatExtraData: {
        objective: _.get(l1Object, 'fb.objective')
      }
    }, formatters.resultsFormatter),
    impresColumn,
    viewableColumn,
    renderColumn(columnDefinition(FbAdSetListColumns.CLICKS), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(
      columnDefinition(FbAdSetListColumns.CPC, i18n.t<string>(`fbAdSetList.headers.${FbAdSetListColumns.CPC}`) + ` (${order.currency})`),
      _.partial(formatPrice, order.currency)
    ),
    renderColumn(columnDefinition(FbAdSetListColumns.CTR), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`)),
    vcrColumn,
    convertColumn,
    cpaColumn,
    cvrColumn,
    renderColumn(columnDefinition(FbAdSetListColumns.UU), _.partial(metricsFormatter, numberWithCommas)),
    spentColumn,
    editBtnsColumn
  ]);
  const videoPerformanceColumns = _.compact([
    idColumn,
    budgetColumn,
    optimizationColumn,
    renderColumn({
      ...columnDefinition(FbAdSetListColumns.RESULTS),
      formatExtraData: {
        objective: _.get(l1Object, 'fb.objective')
      }
    }, formatters.resultsFormatter),
    impresColumn,
    viewableColumn,
    renderColumn(columnDefinition(FbAdSetListColumns.CLICKS), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.VIDEO_VIEW), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.VIDEO_VIEW_RATE), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`)),
    renderColumn(columnDefinition(FbAdSetListColumns.UU), _.partial(metricsFormatter, numberWithCommas)),
    spentColumn,
    editBtnsColumn
  ]);
  const fbOnSiteActionsColumns = _.compact([
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(FbAdSetListColumns.COMMENT), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.LIKE), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.LINK_CLICK), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.POST), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.POST_ENGAGEMENT), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.POST_REACTION), _.partial(metricsFormatter, numberWithCommas)),
    editBtnsColumn
  ]);

  const appActionsColumns = _.compact([
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ACTIVATE_APP), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_PAYMENT_INFO), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_CART), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_ADD_TO_WISHLIST), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_COMPLETE_REGISTRATION), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_CONTENT_VIEW), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_INITIATED_CHECKOUT), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_PURCHASE), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_FB_MOBILE_SEARCH), _.partial(metricsFormatter, numberWithCommas)),
    renderColumn(columnDefinition(FbAdSetListColumns.APP_CUSTOM_EVENT_OTHER), _.partial(metricsFormatter, numberWithCommas)),
    editBtnsColumn
  ]);

  const typeColumnsMap = useMemo(() => ({
    [FbAdSetListType.BASIC]: basicColumns,
    [FbAdSetListType.PERFORMANCE]: performanceColumns,
    [FbAdSetListType.FB_VIDEO_PERFORMANCE]: videoPerformanceColumns,
    [FbAdSetListType.FB_ONSITE_ACTIONS]: fbOnSiteActionsColumns,
    [FbAdSetListType.APP_ACTIONS]: appActionsColumns
  }), [
    basicColumns,
    performanceColumns,
    videoPerformanceColumns,
    fbOnSiteActionsColumns,
    appActionsColumns
  ]);

  const metricsGroupOptions = useMemo(() => {
    return Object.keys(typeColumnsMap).map(metricsGroup => ({
      label: i18n.t<string>(`campaignList.tabs.${metricsGroup}`),
      value: metricsGroup
    }));
  }, [typeColumnsMap]);

  const stopEventPropagation = useCallback(event => {
    event && event.stopPropagation();
  }, []);

  const getCreativesManagementPath = useCallback(() => {
    return getManageCreativesPath(location.pathname, selectedAdSets, selectedDrafts);
  }, [location.pathname, selectedAdSets, selectedDrafts]);

  const bindCreatives = useCallback((event) => {
    stopEventPropagation(event);
    history.push(`${getCreativesManagementPath()}&action=bind`);
  }, [history, stopEventPropagation, getCreativesManagementPath]);

  const activateCreatives = useCallback((event) => {
    stopEventPropagation(event);
    history.push(`${getCreativesManagementPath()}&action=activate`);
  }, [history, stopEventPropagation, getCreativesManagementPath]);

  const deactivateCreatives = useCallback((event) => {
    stopEventPropagation(event);
    history.push(`${getCreativesManagementPath()}&action=deactivate`);
  }, [history, stopEventPropagation, getCreativesManagementPath]);

  const getUpdateStatusPayload = useCallback(() => {
    return [
      ...generateUpdateStatePayload(fbAdSets, selectedAdSets, 'adSet'),
      ...generateUpdateStatePayload(fbAdSets, selectedDrafts, 'draft')
    ];
  }, [fbAdSets, selectedAdSets, selectedDrafts]);

  const activeAdSet = useCallback(async () => {
    callAPIs([
      fbAdSetManager.updateAdSetState.bind(fbAdSetManager, getUpdateStatusPayload(), 'activate')
    ], () => {
      setSelectedAdSets([]);
      setSelectedDrafts([]);
      refreshList();
    });
  }, [fbAdSetManager, refreshList, getUpdateStatusPayload, callAPIs]);

  const deactiveAdSet = useCallback(async () => {
    callAPIs([
      fbAdSetManager.updateAdSetState.bind(fbAdSetManager, getUpdateStatusPayload(), 'deactivate')
    ], () => {
      setSelectedAdSets([]);
      setSelectedDrafts([]);
      refreshList();
    });
  }, [fbAdSetManager, refreshList, getUpdateStatusPayload, callAPIs]);

  const showDeleteConfirmModal = useCallback(() => {
    setAdSetToDelete(selectedAdSets);
  }, [selectedAdSets]);

  const onShowDeleteDraftConfirmModal = useCallback(() => {
    setShowDeleteDraftConfirmModal(true);
    setDeleteDraftIds(selectedDrafts);
  }, [selectedDrafts]);

  const hideDraftCreateModal = useCallback((dirty: boolean) => {
    setShowDraftCreateModal(false);
    setSelectedAdSets([]);
    setSelectedDrafts([]);
    invokeRefreshList(dirty);
  }, [invokeRefreshList]);

  const handleOnDeliveryStatusFilterApply = useCallback((stateList: string[]) => {
    setSelectedDeliveryStatusFilter([...stateList]);
  }, []);

  const handleOnConfigureStatusFilterApply = useCallback((stateList: string[]) => {
    setSelectedConfigureStatusFilter([...stateList]);
  }, []);

  const handleOnObjectTypeFilterApply = useCallback((objectTypeList) => {
    setSelectedObjectTypeFilter([...objectTypeList]);
  }, []);

  const filterMenuTabConfigs: FilterMenuTabConfig[] = useMemo(() => ([
    {
      filterType: i18n.t<string>('l2ObjectList.labels.statusFilter'),
      menuTitle: i18n.t<string>('l2ObjectList.labels.statusFilterMenuTitle'),
      tag: i18n.t<string>('l2ObjectList.labels.statusTag'),
      selectedValues: selectedConfigureStatusFilter,
      options: adSetsConfigureStatus,
      applyMethod: handleOnConfigureStatusFilterApply
    },
    {
      filterType: i18n.t<string>('l2ObjectList.labels.deliveryFilter'),
      menuTitle: i18n.t<string>('l2ObjectList.labels.deliveryFilterMenuTitle'),
      tag: i18n.t<string>('l2ObjectList.labels.deliveryTag'),
      selectedValues: selectedDeliveryStatusFilter,
      options: adSetsDeliveryStatus,
      applyMethod: handleOnDeliveryStatusFilterApply
    },
    {
      filterType: i18n.t<string>('l2ObjectList.labels.objectTypeFilter'),
      menuTitle: i18n.t<string>('l2ObjectList.labels.objectTypeFilterMenuTitle'),
      tag: i18n.t<string>('l2ObjectList.labels.objectTypeTag'),
      selectedValues: selectedObjectTypeFilter,
      options: objectTypes,
      applyMethod: handleOnObjectTypeFilterApply
    }
  ]), [
    adSetsDeliveryStatus,
    selectedDeliveryStatusFilter,
    adSetsConfigureStatus,
    selectedConfigureStatusFilter,
    objectTypes,
    selectedObjectTypeFilter,
    handleOnConfigureStatusFilterApply,
    handleOnDeliveryStatusFilterApply,
    handleOnObjectTypeFilterApply
  ]);

  return {
    loading,
    columns: typeColumnsMap[selectedMetricsGroup],
    rowClasses,
    filteredList,
    searchString,
    currentUrl: match.url,
    selectedAdSets,
    selectedDrafts,
    metricsGroupOptions,
    summaryData,
    selectedMetricsGroup,
    canNotCreateMessage: canNotCreateMessage(order, budgetBalance),
    showDraftCreateModal,
    hideDraftCreateModal,
    setShowDraftCreateModal,
    onMetricsGroupChange: selectedMetricsGroup => setSelectedMetricsGroup(selectedMetricsGroup),
    onHandleSearch,
    handleRemoveSelect,
    onDeleteModalClose,
    onDeleteDraftModalClose,
    showDeleteDraftConfirmModal,
    onShowDeleteDraftConfirmModal,
    deleteAdSets: adSetToDelete !== undefined ?
      _.partial(deleteAdSets, adSetToDelete) :
      undefined,
    deleteDraft: _.partial(deleteDraft, deleteDraftIds),
    bindCreatives,
    activateCreatives,
    deactivateCreatives,
    activeAdSet,
    deactiveAdSet,
    showDeleteConfirmModal,
    canDeleteSelectedAdSets: _.partial(canDeleteSelectedAdSets, fbAdSets),
    filterMenuTabConfigs
  };
};

const getObjectTypeDesc = (fbAdSet) => {
  if (fbAdSet.isDraft) {
    return i18n.t<string>('l2ObjectList.labels.draft');
  } else {
    return i18n.t<string>('l2ObjectList.labels.ordinaryCampaign_fb');
  }
};

const fbAdSetListFilter = (
  fbAdSet,
  searchString: string,
  selectedDeliveryStatusFilter: string[],
  selectedConfigureStatusFilter: string[],
  selectedObjectTypeFilter: string[]
) => {
  const objectTypeDes = getObjectTypeDesc(fbAdSet);
  const nameInclude = fbAdSet.name.toLowerCase().includes(searchString.toLowerCase());
  const listIdInclude = fbAdSet.listId.toString().includes(searchString);
  const deliveryStatusInclude = selectedDeliveryStatusFilter.length === 0 || (fbAdSet.delivery && selectedDeliveryStatusFilter.includes(fbAdSet.delivery.des));
  const configureStatusInclude = (selectedConfigureStatusFilter.length === 0 && fbAdSet.configured_status !== 'DELETED') || selectedConfigureStatusFilter.includes(fbAdSet.state.des);
  const objectTypeInclude = selectedObjectTypeFilter.length === 0 || selectedObjectTypeFilter.includes(objectTypeDes);
  return (nameInclude || listIdInclude) && objectTypeInclude && configureStatusInclude && deliveryStatusInclude;
};

const basicSorter = (dataAToCompare, dataBToCompare, order) => {
  if (order === 'desc') {
    return dataAToCompare > dataBToCompare ? 1 : -1;
  } else {
    return dataAToCompare < dataBToCompare ? 1 : -1;
  }
};

const idSorter = (adSet1, adSet2, order) => {
  const adSet1IsDraft = adSet1.includes('draft');
  const adSet2IsDraft = adSet2.includes('draft');
  const sameObjectType = adSet1IsDraft === adSet2IsDraft;
  const compareListId = adSet1.toString().localeCompare(adSet2.toString());
  if (!sameObjectType) {
    return adSet1IsDraft ? -1 : 1;
  }
  if (compareListId === 0) {
    return 0;
  }
  if (order === 'desc') {
    return compareListId > 0 ? -1 : 1;
  } else {
    return compareListId < 0 ? -1 : 1;
  }
};

const budgetSorter = (dataA, dataB, order) => {
  const dataADaily = _.get(dataA, 'daily');
  const dataBDaily = _.get(dataB, 'daily');
  const dataALifetime = _.get(dataA, 'lifetime');
  const dataBLifetime = _.get(dataB, 'lifetime');
  const allDailyType = dataADaily !== -1 && dataBDaily !== -1;
  const allLifetimeType = dataALifetime !== -1 && dataBLifetime !== -1;
  const allNoBudget = dataADaily === -1 && dataBDaily === -1 && dataALifetime === -1 && dataBLifetime === -1;
  if (allNoBudget) {
    return 0;
  }
  if (allDailyType) {
    return basicSorter(dataBDaily, dataADaily, order);
  }
  if (allLifetimeType) {
    return basicSorter(dataBLifetime, dataALifetime, order);
  }

  return dataALifetime !== -1 ? -1 : 1;
};

const rowClasses = (adSet) => {
  let classes: any = null;
  if (adSet.state === CampaignState.DEACTIVATE && adSet.budget_remaining > 0) {
    classes = [classes, styles.lightDanger].join(' ');
  }
  if (adSet.isDraft) {
    classes = [classes, styles.draftBg].join(' ');
  }
  return classes;
};

const getManageCreativesPath = (pathname, selectedAdSets, selectedDrafts) => {
  const campaignIds = selectedAdSets.join(',');
  const draftIds = selectedDrafts.join(',');
  let path = `${pathname}?`;
  if (campaignIds) {
    path = path + `campaignIds=${campaignIds}`;
  }
  if (draftIds) {
    path = campaignIds ? `${path}&draftIds=${draftIds}` : `${path}draftIds=${draftIds}`;
  }
  return path;
};

const generateUpdateStatePayload = (fbAdSets, selectedObjects: (number | string)[], objectType: 'adSet' | 'draft') => {
  const key = objectType === 'adSet' ? 'id' : 'draftId';
  const payload = selectedObjects.map(objectId => {
    const object = fbAdSets.find(adSet => _.get(adSet, key, '').toString() === objectId.toString());
    return {
      l2ChannelId: objectId,
      isDraft: _.get(object, 'isDraft', false)
    };
  });
  return payload;
};

const canNotCreateMessage = (order, budgetBalance) => {
  const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
  const state = order.state;
  if (canNotCreateState.includes(state)) {
    return i18n.t<string>('fbAdSetList.labels.orderStateCannotCreate');
  }

  const isEnd = moment(order.endDate).isBefore(moment().startOf('day'));
  if (isEnd) {
    return i18n.t<string>('fbAdSetList.labels.isEndCannotCreate');
  }

  if (budgetBalance <= 0) {
    return i18n.t<string>('orderDetail.labels.lessThanBudgetMinimum', { budget: 0 });
  }
  return '';
};

const handleOnSelect = (selectedArray, objectId) => {
  if (selectedArray.indexOf(objectId.toString()) > -1) {
    return selectedArray.filter(id => id.toString() !== objectId.toString());
  }
  return [...selectedArray, objectId.toString()];
};

const handleOnSelectAll = (selectedArrays, filteredList) => {
  if (selectedArrays.length === filteredList.length) {
    return {
      selectedAdSets: [],
      selectedDrafts: []
    };
  }

  return {
    selectedAdSets: filteredList.filter(object => !_.get(object, 'isDraft', false)).map(adSet => adSet.id.toString()),
    selectedDrafts: filteredList.filter(object => _.get(object, 'isDraft', true)).map(draft => _.get(draft, 'draftId', 0).toString())
  };
};

const onCreativeBindingBtnClick = (url, history, id, isDraft) => {
  let toUrl = `${url}?campaignIds=${id}&action=manage`;
  if (isDraft) {
    toUrl = `${url}?draftIds=${id}&action=manage`;
  }
  history.replace(toUrl);
};

const getCanDeleteAdSets = (fbAdSetIds, fbAdSets) =>
  _.filter(fbAdSets, fbAdSet => {
    const selected = fbAdSetIds.indexOf(fbAdSet.id) > -1;
    if (!selected) {
      return false;
    }
    const notBindCreative = _.get(fbAdSet, 'bindingCount', 0) === 0;
    return notBindCreative;
  });

const canDeleteSelectedAdSets = (fbAdSets, fbAdSetIds: (string | number)[]) => {
  if (fbAdSetIds.length === 0) {
    return false;
  }

  const canDeleteAdSets = getCanDeleteAdSets(fbAdSets, fbAdSetIds);
  return canDeleteAdSets.length === fbAdSetIds.length;
};

const getTAToShow = (targeting, i18nInited) => {
  if (!i18nInited) {
    return {};
  }
  return defaultFbAdSetManager.getTASummaryData(targeting, false, true);
};
