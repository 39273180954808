import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, hasFuncs, notSelfServeAdObject } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { FbAdSetSetupFlowPage } from './FbAdSetSetupFlowPage/FbAdSetSetupFlowPage';
import { useCreateFbAdSetSetupFlowPageModel, useEditFbAdSetSetupFlowPageModel } from './FbAdSetSetupFlowPage/FbAdSetSetupFlowPageModel';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Order } from 'core/order/Order';
import { L1Object } from 'core/l1Object/L1Object';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';

const CreateAdSetView = withErrorBoundary(ERROR_CODE.ADSET_CREATE, FbAdSetSetupFlowPage);
export const FbAdSets: React.FC<RouteComponentProps<any> & {
  order: Order,
  l1Object: L1Object,
  fbAdSetList: FbAdSet[]
}> = ({
  match,
  order,
  l1Object,
  fbAdSetList
}) => {

  const modifyPermission = hasFuncs(Permission.CAMPAIGN_WRITE).and(addOnEnabled(ADDONFEATURE.CHANNEL.FB)).and(notSelfServeAdObject(l1Object));

  const renderCreateAdSetView = props => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={modifyPermission}
        renderOtherwise={renderRedirect}
      >
        <CreateAdSetView
          order={order}
          l1Object={l1Object}
          fbAdSetList={fbAdSetList}
          useModel={useCreateFbAdSetSetupFlowPageModel}
        />
      </PermissionChecker>
    );
  };

  const renderEditAdSetView = props => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={modifyPermission}
        renderOtherwise={renderRedirect}
      >
        <CreateAdSetView
          order={order}
          l1Object={l1Object}
          fbAdSetList={fbAdSetList}
          useModel={useEditFbAdSetSetupFlowPageModel}
        />
      </PermissionChecker>
    );
  };

  const redirectToL1Object = (props) => {
    const match = props.match.url.match(/\/campaign-groups\/\d+\//);
    return <Redirect to={`${match[0].slice(0, -1)}`} />;
  };

  return (
    <Switch>
      <Route
        path={`${match.url}/new`}
        render={renderCreateAdSetView}
      />
      <Route
        exact
        path={`${match.url}/:adSetId/edit`}
        render={renderEditAdSetView}
      />
      <Route
        render={redirectToL1Object}
      />
    </Switch>
  );
};
