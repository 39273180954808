import React from 'react';
import { InputGroup, FormControl, Form } from 'react-bootstrap';
import styles from './inputGroupField.module.scss';
import classnames from 'classnames/bind';
import { InputProps } from './InputField';
import { FormikFieldProps, withFormikField } from 'hoc/withFormikField';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';

const cx = classnames.bind(styles);

export interface InputGroupProps extends InputProps {
  prefix?: string | any;
  postfix?: string | any;
  prefixBorder?: boolean;
  postfixBorder?: boolean;
  children?: React.ReactNode;
}
export interface FormikInputGroupProps extends FormikFieldProps, InputGroupProps {}
export interface FormikInputGroupWithFieldLayoutProps extends FormikFieldProps, Omit<FieldLayoutProps, 'name'>, InputGroupProps {}
export type FormikInputGroupFieldProps = ({ withFieldLayout?: true } & FormikInputGroupWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikInputGroupProps);

const InputGroupContent: React.FC<InputGroupProps> = ({
  name, type, value, error, onChange, prefix, postfix, prefixBorder = true, postfixBorder = true, className: customClassName, ...props
}: InputGroupProps) => {

  const hasError = error !== undefined;
  const className = cx({
    error: hasError,
    disabled: props.disabled
  });
  const prefixClassName = cx({
    withoutBorder: !prefixBorder,
    error: hasError,
    disabled: props.disabled
  });
  const postfixClassName = cx({
    withoutBorder: !postfixBorder,
    error: hasError,
    disabled: props.disabled
  });

  const inputOnWheel = (e) => e.target.blur();
  return (
    <InputGroup className={customClassName}>
      {prefix &&
        <InputGroup.Prepend>
          <InputGroup.Text className={prefixClassName}>
            {prefix}
          </InputGroup.Text>
        </InputGroup.Prepend>
      }
      { props.children ?
        props.children :
        props.as === 'label' ?
          <Form.Label className={'col-form-label form-label'}>{value}</Form.Label> :
          <FormControl
            name={name}
            value={value}
            disabled={props.disabled}
            className={className}
            type={type}
            onChange={onChange}
            onBlur={props.onBlur}
            step={props.step}
            min={props.min}
            max={props.max}
            as={props.as}
            onWheel={inputOnWheel}
          />
      }
      {postfix &&
        <InputGroup.Append>
          <InputGroup.Text className={postfixClassName}>
            {postfix}
          </InputGroup.Text>
        </InputGroup.Append>
      }
    </InputGroup>
  );
};
InputGroupContent.defaultProps = {
  type: 'text',
  disabled: false,
  rows: 12,
  autoComplete: 'off',
  value: '',
  prefixBorder: true,
  postfixBorder: true
};
const MemorizedInputGroupContent = React.memo(InputGroupContent);
export const InputGroupField = withFieldLayout<FieldLayoutProps & InputGroupProps>(InputGroupContent);

const FormikInputGroup = withFormikField<FormikInputGroupProps>(MemorizedInputGroupContent);
const FormikInputGroupWithFieldLayout = withFormikField<FormikInputGroupWithFieldLayoutProps>(withFieldLayout(InputGroupContent));

export const FormikInputGroupField: React.FC<FormikInputGroupFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikInputGroup {...props} />;
  } else {
    return <FormikInputGroupWithFieldLayout {...props} />;
  }
};
