import React from 'react';
import styles from './bidPriceSetting.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { getDecimalPlaceCount } from 'helper/CurrencyHelper';
import { FormikField } from 'components/common/form/field/FormikField';

interface BidPriceSettingProps {
  fieldName: string;
  type: string;
  currency: string;
  optimizes: string[];
}

export const BidPriceSetting: React.FC<BidPriceSettingProps> = ({
  type,
  currency,
  fieldName,
  optimizes
}) => {

  const bidPriceInputStep = Math.pow(10, getDecimalPlaceCount(currency) * -1);

  const renderBidPriceSettings = (settingType: string) => {
    return optimizes.map(optimize => {
      return (
        <div key={`${fieldName}.${settingType}.${optimize}`} className={styles.optimizeContainer}>
          <span className={styles.optimize}>
            {i18n.t<string>(`bidPriceSetting.optimize.${optimize.toLowerCase()}`)}
          </span>
          <FormikField.InputGroup
            fieldContentWidth={'auto'}
            name={`${fieldName}.${settingType}.${optimize}`}
            prefix={currency}
            type='number'
            step={bidPriceInputStep}
          />
        </div>
      );
    });
  };

  return (
    <div className={styles.bidPriceSetting}>
      <span className={styles.title}>
        {i18n.t<string>(`bidPriceSetting.labels.${_.camelCase(type)}`)}
      </span>
      <fieldset>
        <div className={styles.autoBidCapContainer}>
          <div className={styles.subTitle}>
            {i18n.t<string>('bidPriceSetting.labels.autoBidCapSetting')}
          </div>
          {renderBidPriceSettings('autoBidCap')}
        </div>
        <div className={styles.bidFloorContainer}>
          <div className={styles.subTitle}>
            {i18n.t<string>('bidPriceSetting.labels.bidFloorSetting')}
          </div>
          {renderBidPriceSettings('bidFloor')}
        </div>
      </fieldset>
    </div>
  );
};
