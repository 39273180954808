import { AxiosInstance } from 'axios';
import { Category, CategoryFormType, Source, SourceFormType } from 'core/tenmaxSegment/TenmaxSegment';
import client from './RestClient';
import fileDownload from 'js-file-download';
import { get } from 'lodash';

export interface TenmaxSegmentWebService {
  getSource: (sourceId: number) => Promise<Source>;
  getSources: () => Promise<Source[]>;
  getCategory: (sourceId: number, categoryId: number) => Promise<Category>;
  getCategories: (sourceId: number) => Promise<Category[]>;
  createSource: (source: SourceFormType) => Promise<number>;
  updateSource: (source: Source) => Promise<void>;
  createCategory: (category: CategoryFormType) => Promise<void>;
  updateCategory: (category: CategoryFormType & {id: number}) => Promise<void>;
  importAudiences: (sourceId: number, categoryId: number, formData: FormData) => Promise<void>;
  exportAudiences: (sourceId: number, categoryId: number) => Promise<void>;
}

export class RestfulTenmaxSegmentWebService implements TenmaxSegmentWebService {

  restClient: AxiosInstance;
  apiPath: string = '/v2/tenmax-segment/source';
  defualtFileDownload: any;

  constructor (restClient: AxiosInstance = client, defualtFileDownload: any = fileDownload) {
    this.restClient = restClient;
    this.defualtFileDownload = defualtFileDownload;
  }

  async getSource (sourceId: number) {
    const result = await this.restClient.get(`${this.apiPath}/${sourceId}`);
    return result.data;
  }

  async getSources () {
    const result = await this.restClient.get(`${this.apiPath}`);
    return result.data.records;
  }

  async getCategory (sourceId: number, categoryId: number) {
    const result = await this.restClient.get(`${this.apiPath}/${sourceId}/category/${categoryId}`);
    return result.data;
  }

  async getCategories (sourceId: number) {
    const result = await this.restClient.get(`${this.apiPath}/${sourceId}/category`);
    return result.data.records;
  }

  async createSource (source: SourceFormType) {
    const result = await this.restClient.post(`${this.apiPath}`, source);
    return get(result, 'data.id');
  }

  async updateSource (source: Source) {
    await this.restClient.put(`${this.apiPath}/${source.id}`, source);
  }

  async createCategory (category: CategoryFormType) {
    await this.restClient.post(`${this.apiPath}/${category.sourceId}/category`, category);
  }

  async updateCategory (category: CategoryFormType & {id: number}) {
    await this.restClient.put(`${this.apiPath}/${category.sourceId}/category/${category.id}`, category);
  }

  async importAudiences (sourceId: number, categoryId: number, formData: FormData) {
    await this.restClient.post(`${this.apiPath}/${sourceId}/category/${categoryId}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async exportAudiences (sourceId: number, categoryId: number) {
    const response = await this.restClient.get(`${this.apiPath}/${sourceId}/category/${categoryId}/export`);
    this.defualtFileDownload(response.data, `category_${categoryId}.csv`);
  }
}
