import _ from 'lodash';
import { CreativeListDataProvider } from './CreativeListDataProvider';
import { CreativeListCommonColumns, commonColumnSetting, getColumnSetting, getCommonColumnData } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn/TableColumn';
import { getCreativeListFormatters } from './CreativeListFormatters';
import { CreativeListRecord } from 'core/creative/Creative';

enum COLUMNS {
  AD_TITLE = 'adTitle',
  FB_MESSAGE = 'fbMessage',
  AD_DESC = 'adDesc',
  IMG_URL = 'imgUrl',
  RTB_Click_URL = 'rtbClickUrl',
  FB_Click_URL = 'fbClickUrl',
  SPONSOR = 'sponsor'
}

export class OneForAllCreativeListDataProvider implements CreativeListDataProvider {

  formatters: any;
  constructor (model) {
    this.formatters = getCreativeListFormatters(model);
  }

  getColumns () {
    return [
      renderColumn(commonColumnSetting[CreativeListCommonColumns.NAME_ID], this.formatters.nameIdFormatter, this.formatters.nameIdHeaderFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.PREVIEW], this.formatters.previewFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.APPROVAL], this.formatters.approvalFormatter),
      renderColumn(getColumnSetting(COLUMNS.AD_TITLE), this.formatters.textFormatter),
      renderColumn(getColumnSetting(COLUMNS.FB_MESSAGE), this.formatters.textFormatter),
      renderColumn(getColumnSetting(COLUMNS.AD_DESC), this.formatters.textFormatter),
      renderColumn(getColumnSetting(COLUMNS.RTB_Click_URL), this.formatters.urlFormatter),
      renderColumn(getColumnSetting(COLUMNS.FB_Click_URL), this.formatters.urlFormatter),
      // renderColumn(commonColumnSetting[CreativeListCommonColumns.BIND_STATE], this.formatters.bindStateFormatter),
      renderColumn(getColumnSetting(COLUMNS.SPONSOR), this.formatters.textFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.LIMITATION], this.formatters.limitationFormatter),
      renderColumn(commonColumnSetting[CreativeListCommonColumns.EDITBTNS], this.formatters.floatingEditBtnsFormatter)
    ];
  }

  getViewModel (creative: CreativeListRecord) {
    return _.omitBy({
      ...getCommonColumnData(creative),
      [COLUMNS.AD_TITLE]: _.get(creative, 'creativeValues.title', ''),
      [COLUMNS.FB_MESSAGE]: _.get(creative, 'bannerExtra.message', ''),
      [COLUMNS.AD_DESC]: _.get(creative, 'creativeValues.desc', ''),
      [COLUMNS.SPONSOR]: _.get(creative, 'creativeValues.sponsored.name', ''),
      [COLUMNS.IMG_URL]: _.get(creative, 'bannerExtra.img_url', ''),
      [COLUMNS.RTB_Click_URL]: _.get(creative, 'bannerUrl', ''),
      [COLUMNS.FB_Click_URL]: _.get(creative, 'bannerExtra.fbLandingUrl', ''),
      // [CreativeListCommonColumns.BIND_STATE]: undefined,
      // [CreativeListCommonColumns.LIMITATION]: undefined,
      [CreativeListCommonColumns.LANDING_URL]: undefined
    }, _.isUndefined);
  }
}
