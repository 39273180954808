import client from './RestClient';
import { L1ObjectChannel, L1ObjectObjective } from 'core/l1Object/L1Object';
import { SelectOptions } from 'components/common/commonType';
import _ from 'lodash';
import { FbObjective } from 'core/fbCampaign/FbCampaign';
import { RtbObjective } from 'core/rtbCampaignGroup/RtbCampaignGroup';
import { TiktokObjective } from 'core/tiktokCampaign/TiktokCampaign';
import { AxiosInstance } from 'axios';

export type L1ObjectUpdateRequest = {
  name: string;
  l1ObjectLifetimeBudget?: number;
  autoOptimise: boolean;
  fb?: any;
  tiktok?: any;
};

export type L1ObjectCreateRequest = L1ObjectUpdateRequest & {
  adsOrderId: number;
  channel: L1ObjectChannel;
};

export interface L1ObjectWebService {
  createL1Object (l1Object: L1ObjectCreateRequest): Promise<number>;
  updateL1Object (l1ObjectId: number | string, l1Object: L1ObjectUpdateRequest): Promise<void>;
  getL1Object (l1ObjectId: number | string): Promise<any>;
  getL1Objects (orderId: number | string): Promise<Array<any>>;
  deleteL1Object (l1ObjectId: number): Promise<void>;
  updateL1ObjectState (l1ObjectIds: (number | string)[], state: 'activate' | 'deactivate'): Promise<void>;
  getL1ObjectOptions (): Promise<SelectOptions[]>;
  getOrderNumber (l1ObjectId: string | number): Promise<string>;
  // refreshFbAdSets (l1ObjectId: number | string): Promise<void>;
  getFBCampaignMinBudget (l1ObjectId: number | string): Promise<number>;
  getAdSetLifetimeBudgetOfCampaign (l1ObjectId: number | string): Promise<{[key: string]: number}>;
}

const channelObjecitveMap = {
  [L1ObjectChannel.FB]: {
    [FbObjective.REACH]: L1ObjectObjective.AWARENESS,
    [FbObjective.OUTCOME_AWARENESS]: L1ObjectObjective.AWARENESS,
    [FbObjective.LINK_CLICKS]: L1ObjectObjective.TRAFFIC,
    [FbObjective.OUTCOME_TRAFFIC]: L1ObjectObjective.TRAFFIC,
    [FbObjective.CONVERSIONS]: L1ObjectObjective.SALES,
    [FbObjective.OUTCOME_SALES]: L1ObjectObjective.SALES
  },
  [L1ObjectChannel.RTB]: {
    [RtbObjective.REACH]: L1ObjectObjective.AWARENESS,
    [RtbObjective.LINK_CLICKS]: L1ObjectObjective.TRAFFIC,
    [RtbObjective.AWARENESS]: L1ObjectObjective.AWARENESS,
    [RtbObjective.TRAFFIC]: L1ObjectObjective.TRAFFIC,
    [RtbObjective.SALES]: L1ObjectObjective.SALES
  },
  [L1ObjectChannel.RETAIL_MEDIA]: {
    [RtbObjective.REACH]: L1ObjectObjective.AWARENESS,
    [RtbObjective.LINK_CLICKS]: L1ObjectObjective.TRAFFIC,
    [RtbObjective.AWARENESS]: L1ObjectObjective.AWARENESS,
    [RtbObjective.TRAFFIC]: L1ObjectObjective.TRAFFIC,
    [RtbObjective.SALES]: L1ObjectObjective.SALES
  },
  [L1ObjectChannel.EDIMAX]: {
    [RtbObjective.AWARENESS]: L1ObjectObjective.AWARENESS
  },
  [L1ObjectChannel.MESSAGE]: {
    [RtbObjective.AWARENESS]: L1ObjectObjective.AWARENESS
  },
  [L1ObjectChannel.TIKTOK]: {
    [TiktokObjective.REACH]: L1ObjectObjective.AWARENESS,
    [TiktokObjective.TRAFFIC]: L1ObjectObjective.TRAFFIC,
    [TiktokObjective.CONVERSIONS]: L1ObjectObjective.SALES
  }
};

const wrapL1Object = json => {
  const objectiveMap = channelObjecitveMap[json.channel];
  const transToPMAX3Objective = objective => objectiveMap[objective] ? objectiveMap[objective] : objective;
  let fb = json.fb;
  if (fb) {
    fb = {
      ...fb,
      objective: transToPMAX3Objective(fb.objective)
    };
  }
  let tiktok = json.tiktok;
  if (tiktok) {
    tiktok = {
      ...tiktok,
      objective_type: transToPMAX3Objective(tiktok.objective_type)
    };
  }
  return {
    ...json,
    fb,
    tiktok,
    budget: json.l1ObjectLifetimeBudget,
    dailyBudget: json.l1ObjectDailyBudget,
    objective: transToPMAX3Objective(json.objective),
    l2ObjectMinStartDate: _.get(json, 'l2ObjectMinStartDate'),
    l2ObjectMaxEndDate: _.get(json, 'l2ObjectMaxEndDate'),
    l2ObjectRunningStatus: _.get(json, 'l2ObjectRunningStatus'),
    actionable: _.get(json, 'actionable')
  };
};

export class RestfulL1ObjectWebService implements L1ObjectWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async createL1Object (l1Object: L1ObjectCreateRequest): Promise<number> {
    const response = await this.restClient.post('/v2/l1-objects', l1Object);
    return response.data.l1ObjectId;
  }
  async updateL1Object (l1ObjectId: number | string, l1Object: L1ObjectUpdateRequest): Promise<void> {
    return this.restClient.put(`/v2/l1-objects/${l1ObjectId}`, l1Object);
  }
  async getL1Object (l1ObjectId: number | string): Promise<any> {
    const response = await this.restClient.get(`/v2/l1-objects/${l1ObjectId}`);
    return wrapL1Object(response.data);
  }
  async getL1Objects (orderId: number | string): Promise<any> {
    const response = await this.restClient.get(`/v2/adsOrders/${orderId}/l1-objects`);
    return _.get(response.data, 'records', []).map(wrapL1Object);
  }
  async deleteL1Object (l1ObjectId: number): Promise<void> {
    await this.restClient.delete(`/v2/l1-objects/${l1ObjectId}`);
  }
  async updateL1ObjectState (l1ObjectIds: (number | string)[], state: 'activate' | 'deactivate'): Promise<void> {
    const l1ObjectParameters = l1ObjectIds.join(',');
    await this.restClient.put(`/v2/l1-objects/state/${state}?l1ObjectIds=${l1ObjectParameters}`);
  }
  async getL1ObjectOptions (): Promise<SelectOptions[]> {
    const response = await this.restClient.get(`/v2/l1-objects/options`);
    return response.data.records;
  }
  async getOrderNumber (l1ObjectId: string | number): Promise<string> {
    const response = await this.restClient.get(`/v2/l1-objects/${l1ObjectId}/orderNumber`);
    return response.data;
  }
  // async refreshFbAdSets (l1ObjectId: number | string): Promise<void> {
  //   await this.restClient.post(`/v2/l1-objects/sync/${l1ObjectId}`);
  // }
  async getFBCampaignMinBudget (l1ObjectId: number | string): Promise<number> {
    const response = await this.restClient.get(`/v2/l1-objects/${l1ObjectId}/min-budget`);
    return response.data.budget;
  }
  async getAdSetLifetimeBudgetOfCampaign (l1ObjectId: number | string): Promise<{[key: string]: number}> {
    const response = await this.restClient.get(`/v2/l1-objects/${l1ObjectId}/campaigns/lifetime-budget`);
    return response.data;
  }
}
