import React, { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { CustomLayoutFormProps } from './CustomLayoutFormModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { cloneDeep } from 'lodash';
import { useCallAPI } from 'hooks/useCallAPI';
import { FormConfig } from 'components/common/form/FormConfig';
import { FormContent } from 'components/common/form/Form';
import { CustomLayout } from 'core/customLayout/CustomLayout';
import { FieldType } from 'components/common/form/field/FieldConfig';
import styles from './customLayoutForm.module.scss';

export const CustomLayoutForm: React.FunctionComponent<CustomLayoutFormProps & { formik: FormikContextType<any> }> = (props) => {

  const {
    formik
  } = props;

  const setFieldValue = formik.setFieldValue;
  const layoutIdFieldProps = formik.getFieldProps('typeProperties.customLayoutId');
  const currentCustomLayoutId = layoutIdFieldProps.value;
  const isEditing = formik.values.creativeId !== undefined;
  const { loading, callAPIs } = useCallAPI();
  const [layoutData, setLayoutData] = useState<CustomLayout[]>();
  const [formConfig, setFormConfig] = useState<FormConfig>(props.getInitFormConfig());

  useEffect(() => {
    callAPIs([() => props.model.init()], () => {
      setLayoutData(props.model.layoutData);
    });
  }, [props.model, callAPIs]);

  const layoutOptions = useMemo(() => {
    return layoutData ? layoutData.map((data) => ({
      label: data.name,
      value: data.id
    })) : [];
  }, [layoutData]);

  const currentLayoutData = useMemo(() => {
    return layoutData && layoutData.find(data => data.id === currentCustomLayoutId);
  }, [layoutData, currentCustomLayoutId]);

  const getNameOfMacroField = useCallback((type: FieldType, macroName: string) => {
    if (type === FieldType.IMAGE || type === FieldType.VIDEO) {
      return `medias.${type}.${macroName}`;
    }
    return `typeProperties.macros.${macroName}`;
  }, []);

  useEffect(() => {
    if (!currentLayoutData) {
      return;
    }
    setFieldValue('typeProperties.customLayout', currentLayoutData);
  }, [currentLayoutData, setFieldValue]);

  useEffect(() => {
    const formFields = cloneDeep(props.basicFields);
    const basicFields = new FormConfig.FieldsBuilder(formFields);
    if (isEditing) {
      basicFields.addFormikLabel({
        label: i18n.t<string>('creativeSetupFlow.labels.customLayout'),
        name: 'typeProperties.customLayout.name'
      });
    } else {
      basicFields.addFormikSelect({
        label: i18n.t<string>('customLayoutForm.labels.customLayout'),
        name: 'typeProperties.customLayoutId',
        options: layoutOptions,
        simpleValue: true
      });
    }
    currentLayoutData && Object.keys(currentLayoutData.macros).forEach(macroName => {
      const macro = currentLayoutData.macros[macroName];
      basicFields.addFormikConfigInput({
        label: macro.name,
        name: getNameOfMacroField(macro.type, macroName),
        fieldConfig: macro,
        permanentHint: macro.description ?
          <div className={styles.hint}>{macro.description}</div> :
          undefined
      });
    });
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(basicFields.build())
          .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
          .build()
      ).build()
    );
  }, [
    isEditing,
    layoutOptions,
    currentLayoutData,
    currentCustomLayoutId,
    props.basicFields,
    setFieldValue,
    getNameOfMacroField
  ]);

  return (
    <div>
      {loading && <LoadingIndicator/>}
      <FormContent
        formConfig={formConfig}
      />
    </div>
  );
};

export default connect(CustomLayoutForm);
