import { PMaxCore } from 'core';
import { AdvertiserHomeModel, DefaultAdvertiserHomeModel } from './AdvertiserHome/AdvertiserHomeModel';
import { AdvertiserFormModel, CreateAdvertiserFormModel, EditAdvertiserFormModel } from './AdvertiserForm/AdvertiserFormModel';
import { AdvertiserDetailModel, DefaultAdvertiserDetailModel } from './AdvertiserDetail/AdvertiserDetailModel';
import _ from 'lodash';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { SelectOptions } from 'components/common/commonType';
import { FbAdAccountStatus } from 'core/fbAdAccount/FbAdAccount';
import { TiktokAdAccountStatus } from 'core/tiktokAdAccount/TiktokAdAccount';

export interface AdvertisersModel {
  readonly state: AdvertisersState;
  readonly event: UpdateEventListener<AdvertisersModel>;
  init (): Promise<void>;
  getCreateAdvertiserFormModel (): AdvertiserFormModel;
  getEditAdvertiserFormModel (advertiserId): AdvertiserFormModel;
  getAdvertiserHomeModel (): AdvertiserHomeModel;
  getAdvertiserDetailModel (advertiserId): AdvertiserDetailModel;
  onUnmount (handler): void;
}

export type AdvertisersProps = {
  readonly model: AdvertisersModel;
};

export type AdvertisersState = {
  readonly loading: boolean;
  readonly fbAdAccountOptions?: (SelectOptions & { disabled?: boolean })[];
  readonly tiktokAdAccountOptions?: (SelectOptions & { disabled?: boolean })[];
  readonly fbPageOptions?: (SelectOptions & { disabled?: boolean })[];
};

export class DefaultAdvertisersModel implements AdvertisersModel {

  core: PMaxCore;
  advertiserHomeModel?: AdvertiserHomeModel;
  createAdvertiserFormModel?: AdvertiserFormModel;
  editAdvertiserFormModel?: AdvertiserFormModel;
  advertiserDetailModel?: AdvertiserDetailModel;
  loading: boolean = false;
  event: FireableUpdateEventListener<AdvertisersModel> = new FireableUpdateEventListener<AdvertisersModel>();
  fbAdAccountOptions?: (SelectOptions & { disabled?: boolean })[];
  tiktokAdAccountOptions?: (SelectOptions & { disabled?: boolean })[];
  fbPageOptions?: (SelectOptions & { disabled?: boolean })[];

  constructor (
    core: PMaxCore,
    private manager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.core = core;
  }

  get state () {
    return ({
      loading: this.loading,
      fbAdAccountOptions: this.fbAdAccountOptions,
      tiktokAdAccountOptions: this.tiktokAdAccountOptions,
      fbPageOptions: this.fbPageOptions
    });
  }

  async init () {
    this.updateState(true);
    try {
      const fbAdAccounts = await this.manager.getFBAdAccounts();
      this.fbAdAccountOptions = fbAdAccounts.filter(account => account.account_status === FbAdAccountStatus.ACTIVE)
        .map(account => ({
          label: account.name,
          value: account.id
        }));
      const tiktokAdAccounts = await this.manager.getTikTokAdAccounts();
      this.tiktokAdAccountOptions = tiktokAdAccounts.filter(account => account.status === TiktokAdAccountStatus.STATUS_ENABLE)
        .map(account => ({
          label: account.name,
          value: account.id
        }));
      const fbPages = await this.manager.getFBPages();
      this.fbPageOptions = fbPages.map(page => ({
        label: page.name,
        value: page.id,
        disabled: page.status === 0
      }));
    } catch (e) {}
    this.updateState(false);
  }

  getAdvertiserHomeModel () {
    const actor = this.core.authenticationManager.actor;
    let agencyId = _.get(actor, 'agencyId');
    agencyId = agencyId === null ? undefined : agencyId;
    if (this.advertiserHomeModel && agencyId === this.advertiserHomeModel.agencyId) {
      return this.advertiserHomeModel;
    }
    this.advertiserHomeModel = new DefaultAdvertiserHomeModel(actor!);
    return this.advertiserHomeModel;
  }

  getCreateAdvertiserFormModel (): AdvertiserFormModel {
    if (this.createAdvertiserFormModel) {
      return this.createAdvertiserFormModel;
    }
    this.createAdvertiserFormModel = new CreateAdvertiserFormModel(
      this.core.authenticationManager.actor!.agencyId,
      _.get(this.state, 'fbAdAccountOptions', []),
      _.get(this.state, 'tiktokAdAccountOptions', []),
      _.get(this.state, 'fbPageOptions', [])
    );
    return this.createAdvertiserFormModel;
  }

  getEditAdvertiserFormModel (advertiserId): AdvertiserFormModel {
    if (this.editAdvertiserFormModel &&
      this.editAdvertiserFormModel.advertiserId === advertiserId
    ) {
      return this.editAdvertiserFormModel;
    }
    this.editAdvertiserFormModel = new EditAdvertiserFormModel(
      advertiserId,
      _.get(this.state, 'fbAdAccountOptions', []),
      _.get(this.state, 'tiktokAdAccountOptions', []),
      _.get(this.state, 'fbPageOptions', [])
    );
    return this.editAdvertiserFormModel;
  }

  getAdvertiserDetailModel (advertiserId) {
    if (this.advertiserDetailModel &&
      advertiserId === this.advertiserDetailModel.advertiserId
    ) {
      return this.advertiserDetailModel;
    }
    this.advertiserDetailModel = new DefaultAdvertiserDetailModel(
      advertiserId,
      _.get(this.state, 'fbAdAccountOptions', []),
      _.get(this.state, 'tiktokAdAccountOptions', []),
      _.get(this.state, 'fbPageOptions', []),
      this.core.addonFeatureManager
    );
    return this.advertiserDetailModel;
  }

  onUnmount (handler) {
    this.advertiserHomeModel = undefined;
    this.createAdvertiserFormModel = undefined;
    this.editAdvertiserFormModel = undefined;
    this.advertiserDetailModel = undefined;
    this.fbAdAccountOptions = undefined;
    this.fbPageOptions = undefined;
    handler && this.event.remove(handler);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
