import { Currency } from 'core';
import { L1Object } from 'core/l1Object/L1Object';
import { Order } from 'core/order/Order';
import { AdType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import i18n from 'i18next';
import _ from 'lodash';
import { toast } from 'react-toastify';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { MessageCampaignSetupFlowPageModel } from '../MessageCampaignSetupFlowPageModel';
import { CreateMessageCampaignSetupFlowStep, EditMessageCampaignSetupFlowStep } from '../MessageCampaignSetupFlowStep';
import { CampaignSetupTab } from './MessageCampaignSetupStepModel';
import { DefaultMessageCampaignManager, MessageCampaignManager } from 'core/messageCampaign/MessageCampaignManager';

export type MessageCampaignSummaryStepState = {
  readonly loading: boolean;
};

export interface MessageCampaignSummaryStepModel {
  readonly flowModel: MessageCampaignSetupFlowPageModel;
  readonly event: UpdateEventListener<MessageCampaignSummaryStepModel>;
  readonly state: MessageCampaignSummaryStepState;
  readonly targetingValue: any;
  readonly audienceLowestThreshold: number;
  readonly order: Order;
  readonly l1Object?: L1Object;
  updateState (loading: boolean): void;
  getAdTypeSummaryData: () => any;
  getSetUpSummaryData: () => any;
  getLimitationsSummaryData: () => any;
  submit: () => void;
  goLast: () => void;
  goStep: (stepIndex: number, subStepIndex: number) => void;
  redirectToLastPage: () => void;
}

export type MessageCampaignSummaryStepProps = {
  readonly model: MessageCampaignSummaryStepModel;
};

export class DefaultMessageCampaignSummaryStepModel implements MessageCampaignSummaryStepModel {

  event: FireableUpdateEventListener<MessageCampaignSummaryStepModel>;
  loading: boolean;
  showPublishBindingFailed: boolean = false;
  stepChangeListener?: (stepIndex, subStepName?: string) => void;

  constructor (
    public flowModel: MessageCampaignSetupFlowPageModel,
    public goLast: () => void,
    public goStep: (stepIndex: number, subStepIndex: number) => void,
    protected campaignManager: MessageCampaignManager = new DefaultMessageCampaignManager()
  ) {
    this.event = new FireableUpdateEventListener<MessageCampaignSummaryStepModel>();
    this.loading = false;
  }

  get state () {
    return {
      loading: this.loading
    };
  }

  get audienceLowestThreshold () {
    return this.flowModel.audienceLowestThreshold;
  }

  get targetingValue () {
    return this.flowModel.limitationModel ? this.flowModel.limitationModel.limitationValue : [];
  }

  get order () {
    return this.flowModel.order;
  }

  get l1Object () {
    return this.flowModel.l1Object;
  }

  getAdTypeSummaryData () {
    return {
      title: i18n.t<string>('campaignSummary.titles.adType'),
      data: {
        adType: {
          title: undefined,
          content: [
            {
              label: i18n.t<string>('campaign.labels.adType'),
              value: this.getAdTypeValue(this.flowModel.state.campaign.basic.adType)
            }
          ]
        }
      }
    };
  }

  get campaignSetupStepIndex () {
    const isCreate = this.flowModel.type === 'create';
    return isCreate ? CreateMessageCampaignSetupFlowStep.SETUP_CAMPAIGN : EditMessageCampaignSetupFlowStep.SETUP_CAMPAIGN;
  }

  getSetUpSummaryData () {
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const priceModelRelatedSummaryData = this.getPriceModelRelatedSummaryData(campaignSetUpData.priceModel);
    return {
      title: i18n.t<string>('campaignSummary.titles.basicSetting'),
      backStep: this.campaignSetupStepIndex,
      backSubStep: CampaignSetupTab.BASIC,
      data: _.omitBy({
        general: {
          title: i18n.t<string>('campaignSummary.titles.generalInformation'),
          content: _.compact(_.concat([
            {
              label: i18n.t<string>('campaignSummary.labels.orderName'),
              value: this.flowModel.order.projectName
            },
            {
              label: i18n.t<string>('campaignSummary.labels.campaignName'),
              value: campaignSetUpData.name
            },
            {
              label: i18n.t<string>('campaignSummary.labels.deliverDate'),
              value: campaignSetUpData.startDate
            },
            {
              label: i18n.t<string>('campaignSummary.labels.message'),
              value: campaignSetUpData.message
            },
            {
              label: i18n.t<string>('campaignSummary.labels.pricingModel'),
              value: this.getCampaignPlanTypeValue(campaignSetUpData.priceModel)
            },
            {
              label: i18n.t<string>('campaignSummary.labels.tags'),
              value: this.getTags(campaignSetUpData.tags)
            }
          ], priceModelRelatedSummaryData))
        },
        optimizeSetting: {
          title: i18n.t<string>('adSetSetupFlow.mainStep.fieldset.optimizationTitle'),
          content: _.compact([
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal'),
              value: i18n.t<string>(`optimizationGoal.${campaignSetUpData.optimize.toLowerCase()}`)
            },
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent'),
              value: i18n.t<string>(`messageCampaign.billingEvent.${campaignSetUpData.priceModel}`)
            }
          ])
        }
      }, _.isUndefined)
    };
  }

  getLimitationsSummaryData (): any {
    const limitations = this.flowModel.state.campaign.limitations;
    const summaryData = this.flowModel.getLimitationsSummaryData(limitations);
    return {
      title: i18n.t<string>('campaignSummary.titles.targetSetting'),
      backStep: this.campaignSetupStepIndex,
      backSubStep: CampaignSetupTab.LIMITATION,
      data: summaryData
    };
  }

  getPriceModelRelatedSummaryData (priceModel) {
    const currency = this.flowModel.order.currency;
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const needShowOrderPrice = priceModel !== RtbCampaignPlanType.RS && priceModel !== RtbCampaignPlanType.DCPM;
    const needShowBudget = !_.get(this.flowModel.l1Object, 'autoOptimise', false);
    return _.compact([
      (needShowOrderPrice && campaignSetUpData.orderPrice) && {
        label: i18n.t<string>('campaignSummary.labels.orderPrice', { model: this.getCampaignPlanTypeValue(priceModel) }),
        value: `${currency} ${campaignSetUpData.orderPrice}`
      },
      (needShowBudget) && {
        label: i18n.t<string>('campaignSummary.labels.campaignBudget'),
        value: `${currency} ${campaignSetUpData.budget}`
      }
    ]);
  }

  getOptimizeBudgetValue (dailyTargetBudget) {
    const currency = this.flowModel ? this.flowModel.order.currency : Currency.NTD;
    if (dailyTargetBudget === null || dailyTargetBudget === undefined || dailyTargetBudget.toString() === '0') {
      return i18n.t<string>('campaignSummary.labels.budgetScheduleDistribution');
    } else {
      return i18n.t<string>('campaignSummary.labels.budgetDailyDistribution', { currency: currency, budget: dailyTargetBudget });
    }
  }

  getCampaignPlanTypeValue (campaignPlanType) {
    return i18n.t<string>(`campaign.labels.${campaignPlanType}`);
  }

  getAdTypeValue (adType) {
    switch (adType) {
      case AdType.MESSAGE:
        return i18n.t<string>('campaignSummary.labels.adTypeMessage');
      default:
        return adType;
    }
  }

  getTags (tags) {
    if (!tags || tags.length === 0) {
      return i18n.t<string>('summary.titles.noData');
    }

    return tags.join(',');
  }

  submit = async () => {
    if (this.flowModel.type === 'edit') {
      await this.updateCampaign(this.flowModel.state.campaign, this.flowModel.order, this.flowModel.l1Object);
    } else {
      await this.createCampaign(this.flowModel.state.campaign, this.flowModel.order, this.flowModel.l1Object);
    }
  }

  createCampaign = async (campaign: any, order: Order, l1Object?: L1Object) => {
    this.updateState(true);
    try {
      const l1ObjectId = _.get(l1Object, 'l1ObjectId', 0);
      await this.campaignManager.createCampaign(campaign, l1ObjectId);
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.redirectToLastPage();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  updateCampaign = async (campaign: any, order: Order, l1Object?: L1Object) => {
    this.updateState(true);
    try {
      const l1ObjectId = _.get(l1Object, 'l1ObjectId', 0);
      await this.campaignManager.updateCampaign(campaign, l1ObjectId);
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.redirectToLastPage();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  redirectToLastPage = () => {
    const order = this.flowModel.order;
    const l1Object = this.flowModel.l1Object;
    const l1ObjectId = _.get(l1Object, 'l1ObjectId');
    const redirectPath = l1ObjectId ?
        `/orders/${order.orderNumber}/campaign-groups/${l1ObjectId}` :
        `/orders/${order.orderNumber}`;
    this.flowModel.setFinishedRedirectPath(redirectPath);
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
