import React from 'react';
import i18n from 'i18n';

export const renderFBAdSetSpendLimit = (lifetimeMinSpendTarget, lifetimeSpendCap) => (
  <>
    {`${i18n.t<string>('adSetSetupFlow.mainStep.field.lifetimeMinSpendTarget')}: `}
    {lifetimeMinSpendTarget}
    <br />
    {`${i18n.t<string>('adSetSetupFlow.mainStep.field.lifetimeSpendCap')}: `}
    {lifetimeSpendCap}
  </>
);
