import React, { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import styles from './oneForAllForm.module.scss';
import _, { defaultTo } from 'lodash';
import { MAX_ADTITLE_LENGTH, MAX_MESSAGE_LENGTH, MAX_SPONSOR_LENGTH, OneForAllDisplayFormProps } from './OneForAllDisplayFormModel';
import { FormConfig } from 'components/common/form/FormConfig';
import { FBPageOption } from 'components/FBPageSelect/FBPageOption';
import { FBPageSelectSingleValue } from 'components/FBPageSelect/FBPageSelectSingleValue';
import { CALL_TO_ACTION } from 'core/creative/Creative';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { useFetchFbPageOptions } from './FbPageOptionsHook';
import { FormContent } from 'components/common/form/Form';
import { StorySpacePreview } from './StorySpacePreview';
import { validateEmpty, validateUrl } from 'utils/ValidateUtils';
import { MAX_ADDESC_LENGTH } from './NativeFormModel';

const callToActionOptions = Object.values(CALL_TO_ACTION).map(callToAction => ({
  label: _.startCase(_.lowerCase(callToAction.toString())),
  value: callToAction
}));

export const OneForAllDisplayForm: React.FunctionComponent<OneForAllDisplayFormProps & { formik: FormikContextType<any> }> = (props) => {

  const [formConfig, setFormConfig] = useState<FormConfig>(props.getInitFormConfig());
  const storyImg = _.get(props.formik.values, 'medias.storyImg');
  const [useStoryImage, setUseStoryImage] = useState(storyImg ? true : false);
  const customRectUrl = _.get(props.formik.values, 'typeProperties.customRectUrl');
  const [useRtbWideImage, setUseRtbWideImage] = useState(customRectUrl ? true : false);
  const advertiserId = props.formik.values.advertiserId;
  const { setFieldValue, setValues, values } = props.formik;
  const { loading, defaultFbPage, fbPageOptions } = useFetchFbPageOptions(advertiserId, props.model.flowPageModel);

  const onUseStoryImgChanged = useCallback(checked => {
    setFieldValue('medias.storyImg', checked ? {} : undefined);
    setUseStoryImage(checked);
  }, [setFieldValue]);

  const onUseRtbWideImageChanged = useCallback(checked => {
    setValues(prev => ({
      ...prev,
      typeProperties: {
        ...defaultTo(prev.typeProperties, {}),
        customRectUrl: checked
      },
      medias: {
        ...defaultTo(prev.medias, {}),
        rectImg: checked ? {} : undefined
      }
    }));
    setUseRtbWideImage(checked);
  }, [setValues]);

  const pageId = _.get(values, 'typeProperties.pageId');
  useEffect(() => {
    if (defaultFbPage && !pageId) {
      setFieldValue('typeProperties.pageId', defaultFbPage);
    }
  }, [pageId, defaultFbPage, setFieldValue]);

  const {
    validateRemainingCharacters,
    validateImage,
    validateStoryImage
  } = props.model;

  const wideImgFileInputHint = useMemo(() => [
    i18n.t<string>('creativeSetupFlow.labels.typeHint'),
    i18n.t<string>('creativeSetupFlow.labels.sizeHint', { size: '1080 x 564', proportion: '1.91:1' }),
    i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
  ], []);

  const squareImgInputHint = useMemo(() => [
    i18n.t<string>('creativeSetupFlow.labels.typeHint'),
    i18n.t<string>('creativeSetupFlow.labels.sizeHint', { size: '1080 x 1080', proportion: '1:1' }),
    i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
  ], []);

  const storyImgInputHint = useMemo(() => [
    i18n.t<string>('oneForAllDisplayForm.hints.width', { width: 540 }),
    i18n.t<string>('oneForAllDisplayForm.hints.proportion', { proportion: '9:16 +- 2%' })
  ], []);

  useEffect(() => {
    const basicFields = [...props.basicFields];
    const bannerUrlField = basicFields.find(field => field.props.name === 'bannerUrl');
    if (bannerUrlField) {
      bannerUrlField.props.label = i18n.t<string>('creativeSetupFlow.labels.rtbBannerUrl');
    }
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder(basicFields)
            .addFormikSelect({
              label: i18n.t<string>('creativeSetupFlow.labels.fbPage'),
              simpleValue: true,
              name: 'typeProperties.pageId',
              optionComponent: FBPageOption,
              singleValue: FBPageSelectSingleValue,
              fieldContentWidth: 'auto',
              options: fbPageOptions,
              validate: validateEmpty
            })
            .addFormikUrlInput({
              className: styles.urlField,
              label: i18n.t<string>('creativeSetupFlow.labels.fbClickUrl'),
              name: 'typeProperties.fbLandingUrl',
              validate: validateUrl
            })
            .addFormikSelect({
              label: i18n.t<string>('creativeSetupFlow.labels.callToAction'),
              simpleValue: true,
              name: 'typeProperties.callToAction',
              options: callToActionOptions,
              validate: validateEmpty
            })
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.deepLink'),
              name: 'typeProperties.deepLink',
              validate: value => value ? validateDeepLink(value) : undefined
            })
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.title'),
              name: 'typeProperties.title',
              hint: i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: props.model.adTitleRemainLength }),
              onChange: props.model.handleAdTitleChange,
              validate: value => validateRemainingCharacters(value, MAX_ADTITLE_LENGTH)
            })
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.message'),
              name: 'typeProperties.message',
              hint: i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: props.model.adMessageRemainLength }),
              onChange: props.model.handleAdMessageChange,
              validate: value => validateRemainingCharacters(value, MAX_MESSAGE_LENGTH)
            })
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.desc'),
              name: 'typeProperties.desc',
              hint: i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: props.model.adDescriptionRemainLength }),
              onChange: props.model.handleAdDescriptionChange,
              validate: value => validateRemainingCharacters(value, MAX_ADDESC_LENGTH)
            })
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.sponsor'),
              name: 'typeProperties.sponsor',
              hint: i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: props.model.sponsorRemainLength }),
              onChange: props.model.handleSponsorChange,
              validate: value => validateRemainingCharacters(value, MAX_SPONSOR_LENGTH)
            })
            .addFormikUrlInput({
              className: styles.urlField,
              label: i18n.t<string>('creativeSetupFlow.labels.sponsorLink'),
              name: 'typeProperties.sponsorLink',
              validate: validateUrl
            })
            .addFormikFileInput({
              type: 'image',
              className: styles.imageInput,
              maxWidth: 200,
              label: i18n.t<string>('oneForAllDisplayForm.labels.squareImg'),
              name: 'medias.squareImg',
              hints: squareImgInputHint,
              validate: value => validateImage(value, 1080, 1080)
            })
            .addCheckbox({
              name: 'useStoryImg',
              label: '',
              checkboxLabel: i18n.t<string>('oneForAllDisplayForm.labels.useStoryLikeImage'),
              checked: useStoryImage,
              onChange: onUseStoryImgChanged,
              postfix: <StorySpacePreview/>
            })
            .addFormikFileInput({
              type: 'image',
              className: styles.storyImgInput,
              maxWidth: 180,
              label: i18n.t<string>('oneForAllDisplayForm.labels.storyImg'),
              name: 'medias.storyImg',
              hints: storyImgInputHint,
              validate: validateStoryImage
            }, !useStoryImage)
            .addCheckbox({
              label: '',
              name: 'useRtbWideImage',
              checkboxLabel: i18n.t<string>('oneForAllDisplayForm.labels.useRtbWideImage'),
              checked: useRtbWideImage,
              onChange: onUseRtbWideImageChanged
            })
            .addFormikFileInput({
              type: 'image',
              className: styles.rectImageInput,
              maxWidth: 200,
              label: i18n.t<string>('oneForAllDisplayForm.labels.rectImg'),
              name: 'medias.rectImg',
              hints: wideImgFileInputHint,
              validate: value => validateImage(value, 1080, 564)
            }, !useRtbWideImage)
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      )
      .build()
    );
  }, [
    fbPageOptions,
    props.basicFields,
    props.model.adDescriptionRemainLength,
    props.model.adMessageRemainLength,
    props.model.adTitleRemainLength,
    props.model.sponsorRemainLength,
    props.model.handleAdDescriptionChange,
    props.model.handleAdMessageChange,
    props.model.handleAdTitleChange,
    props.model.handleSponsorChange,
    useRtbWideImage,
    useStoryImage,
    wideImgFileInputHint,
    squareImgInputHint,
    storyImgInputHint,
    onUseRtbWideImageChanged,
    onUseStoryImgChanged,
    setFormConfig,
    validateRemainingCharacters,
    validateImage,
    validateStoryImage
  ]);

  return (
    <div>
      {loading && <LoadingIndicator/>}
      <FormContent
        formConfig={formConfig}
      />
    </div>
  );
};

export default connect(OneForAllDisplayForm);

function validateDeepLink (deepLink: string) {
  const protocolPattern = /^(\S+):\/\//i;
  const match = deepLink.match(protocolPattern);
  if (match && match[1] !== 'http' && match[1] !== 'https') {
    return;
  }
  return i18n.t<string>('oneForAllDisplayForm.labels.deepLinkError');
}
