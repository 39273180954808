import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { SourceListColumns, useSourceListModel } from './SourceListModel';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import i18n from 'i18n';
import { Page } from 'components/Page/Page';
import { Link } from 'react-router-dom';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';

export const SourceList = () => {

  const {
    loading,
    columns,
    searchString,
    filterdSources,
    onHandleSearch
  } = useSourceListModel();

  const renderRowBtns = useCallback((source) => [(
    <Link key={`edit-source-${source.id}`} to={`/tenmax-segments/sources/${source.id}/edit`}>
      <IconWithTooltip
        icon={faPencilAlt}
        tooltipProps={{
          id: `edit-source-${source.id}`,
          tooltip: i18n.t<string>('sourceForm.titles.edit')
        }}
      />
    </Link>
  )], []);

  const renderListOperations = useCallback(() => [(
    <Link
      key='new-btn'
      to={'/tenmax-segments/sources/new'}
      className={'btn btn-tertiary btn-sm'}
    >
      {i18n.t<string>('sourceList.buttons.create')}
    </Link>
  )], []);

  return (
    <Page title={i18n.t<string>('sourceList.title')}>
      {loading && <LoadingIndicator/>}
      <StickableBootstrapTable
        customPadding={false}
        keyField={SourceListColumns.NAME}
        data={filterdSources}
        columns={columns}
        noDataIndication={i18n.t<string>('sourceList.placeholders.noData')}
        defaultSorted={[{
          dataField: SourceListColumns.NAME,
          order: 'desc'
        }]}
        renderRowBtns={renderRowBtns}
        defaultSearchString={searchString}
        searchbarPlaceholder={i18n.t<string>('sourceList.placeholders.searchbar')}
        onHandleSearch={onHandleSearch}
        renderListOperations={renderListOperations}
      />
    </Page>
  );
};
