import { FormikLabelField } from 'components/common/form/field/LabelField';
import i18n from 'i18n';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import styles from './macroFieldPlaceholder.module.scss';

export const MacroFieldPlaceHolder = ({
  name,
  fieldConfig,
  postComponent
}) => {

  const validateConfig = useCallback(() => {
    if (isEmpty(fieldConfig) || !fieldConfig.type) {
      return i18n.t<string>('macroFieldPlaceholder.hints.pleaseConfigMacro');
    }
    return undefined;
  }, [fieldConfig]);

  return (
    <FormikLabelField
      name={name}
      validate={validateConfig}
      hint={i18n.t<string>('macroFieldPlaceholder.hints.pleaseConfigMacro')}
      postComponent={(
        <div className={styles.editMacroButtonContainer}>
          {postComponent}
        </div>
      )}
      fieldContentWidth='0px'
    />
  );
};
