import React from 'react';
import styles from './CreativeList.module.scss';
import _ from 'lodash';
import defaultListFormatters from './defaultListFormatters';
import { CreativePreview } from 'components/CreativePreview/CreativePreview';
import { DefaultCreativePreviewModel } from 'components/CreativePreview/CreativePreviewModel';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18n';

const resultsFormatter = (value, creativeData: any, _1, formatExtraData) => {
  if (!creativeData || creativeData.id === 0) {
    return <div/>;
  }
  const l1Object = formatExtraData.l1Object;
  let objective = _.get(l1Object, 'fb.objective');
  return (
    <div>
      <div className={styles.value}>
        {value === undefined ? 0 : value}
      </div>
      <div className={styles.objective}>
        {objective}
      </div>
    </div>
  );
};

const uuFormatter = (value: any) => {
  const isSummary = value === '';
  if (isSummary) {
    return '';
  }
  return defaultListFormatters.numberFormatter(value);
};

const creativePreviewFormatter = (cell: any, row: any): any => {
  if (row.id !== 0) {
    const onPreviewLinkBtnClicked = () => {
      window.open(row.previewLink, '_blank', 'noopener');
    };
    return (
      <div className={styles.previewCell}>
        <div className={styles.creativeReview}>
          <CreativePreview model={new DefaultCreativePreviewModel(row)}/>
        </div>
        {row.previewLink &&
          <IconWithTooltip
            placement='right'
            className={styles.fbPreviewBtn}
            onClick={onPreviewLinkBtnClicked}
            icon={faExternalLinkAlt}
            tooltipProps={{
              id: `campaignCreativeListFbPreviewHint-${row.id}`,
              tooltip: i18n.t<string>('campaignCreativeList.hints.fbPreviewHint')
            }}
          />
        }
      </div>
    );
  } else {
    return <div/> ;
  }
};

const formatters = {
  ...defaultListFormatters,
  creativePreviewFormatter,
  resultsFormatter,
  uuFormatter
};

export default formatters;
