import React from 'react';
import { FormikField } from 'components/common/form/field/FormikField';
import { Formik, Form } from 'formik';
import { ProductGroupFormData } from './ProductGroupFormModel';
import i18n from 'i18n';
import { GroupType, InputType } from 'core/limitation/ProductGroup';
import styles from './productGroupForm.module.scss';
import { Link, Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Button, Col, Nav } from 'react-bootstrap';
import _ from 'lodash';
import { Modal } from 'components/common/Modal/Modal';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import { readFromClipboard, writeToClipboard } from 'helper/ClipboardHelper';
import { SelectOptions } from 'components/common/commonType';
import { CustomField } from 'components/common/form/field/CustomField';

export const ProductGroupForm: React.FC<{ useModel: any }> = (props) => {

  const modelData: ProductGroupFormData = props.useModel();
  if (!modelData.initProductGroup) {
    return <LoadingIndicator />;
  }

  const textToSelectOptions = (text: string): SelectOptions[] => {
    const labelValueItems = text.replace(/\n$/, '').split('\n');
    return _.filter(labelValueItems, labelValueItem => labelValueItem.split(',').length === 2)
      .map(labelValueItem => {
        const customInputs = labelValueItem.split(',');
        return {
          label: customInputs[1].trim(),
          value: customInputs[0].trim()
        };
      });
  };

  const renderCustomModal = (groupType: GroupType, addGroupValues) => {
    const {
      hideCustomModal,
      validateModalData,
      modalData,
      inputTypes,
      selectedInputType,
      onInputTypeChange
    } = modelData;
    if (!modalData) {
      return <div />;
    }
    const onSubmit = (value) => {
      addGroupValues(value);
      hideCustomModal();
    };

    return (
      <Modal title={modalData.title} className={styles.productGroupCustomModal}>
        {groupType === GroupType.ADSPACE &&
          <Nav
            activeKey={selectedInputType}
            onSelect={onInputTypeChange}
          >
            {
              inputTypes.map(tab => (
                <Nav.Item key={tab}>
                  <Nav.Link eventKey={tab}>
                    {i18n.t<string>(`productGroupForm.labels.${tab.toLowerCase()}`)}
                  </Nav.Link>
                </Nav.Item>
              ))
            }
          </Nav>
        }
        <Formik
          initialValues={modalData.value}
          enableReinitialize
          onSubmit={onSubmit}
          validate={_.partial(validateModalData, groupType, selectedInputType)}
          validateOnBlur={false}
        >
          {({ values, setFieldValue }) => {
            const handlePasteDomain = async (e) => {
              e.preventDefault();
              const domainValues = _.defaultTo(values[GroupType.DOMAIN], []);
              const clipboardValue = await readFromClipboard();
              const newDomains = clipboardValue.includes(',') ?
                textToSelectOptions(clipboardValue) :
                ([{
                  label: clipboardValue,
                  value: clipboardValue
                }]);
              setFieldValue(GroupType.DOMAIN, [...domainValues, ...newDomains]);
              return false;
            };
            const handleDomainChange = (values) => {
              setFieldValue(GroupType.DOMAIN, values.map(value => (
                typeof value === 'string' ?
                ({ label: value, value: value }) :
                value
              )));
            };
            return (
              <Form>
                <div className={styles.modalContentArea}>
                  {groupType === GroupType.ADSPACE ?
                  (selectedInputType === InputType.SINGLE ?
                    <>
                      <FormikField.Input
                        direction={Col}
                        label={i18n.t<string>('productGroupForm.labels.customSpaceName')}
                        name={`${GroupType.ADSPACE}.name`}
                      />
                      <FormikField.Input
                        direction={Col}
                        label={i18n.t<string>('productGroupForm.labels.customSpaceId')}
                        name={`${GroupType.ADSPACE}.id`}
                      />
                    </> :
                    <div className={styles.customModalContainer}>
                      <div className={styles.descriptionArea}>
                        <div className={styles.leftDescription}/>
                        <div className={styles.rightDescription}>
                          {i18n.t<string>('limitation.addSpaceTip')}
                        </div>
                     </div>
                      <FormikField.TextArea
                        direction={Col}
                        name={`${GroupType.ADSPACE}.inputs`}
                        placeholder={i18n.t<string>('limitation.placeholders.addSpace')}
                      />
                      </div>
                    ) :
                    <div onPaste={handlePasteDomain}>
                      <FormikField.Tags
                        direction={Col}
                        name={`${GroupType.DOMAIN}`}
                        placeholder={i18n.t<string>('productGroupForm.placeholders.customDomain')}
                        onChange={handleDomainChange}
                        addOnBlur={false}
                      />
                    </div>
                  }
                </div>
                <div className={styles.modalButtonArea}>
                  <Button variant='primary' size='sm' type='submit'>
                    {i18n.t<string>('common.buttons.confirm')}
                  </Button>
                  <Button variant='secondary' size='sm' onClick={hideCustomModal}>
                    {i18n.t<string>('common.buttons.cancel')}
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  };

  const renderFormContent = ({ values, setFieldValue, isSubmitting, initialValues }) => {
    const currentGroupType = values.groupType;
    const selectedInputType = modelData.selectedInputType;
    const resetGroupValues = (value) => {
      if (value === currentGroupType) {
        return;
      }
      setFieldValue('groupValues', value === initialValues.groupType ?
        initialValues.groupValues :
        []);
    };
    const addCustomGroupValue = (value) => {
      const groupValues = _.defaultTo(values.groupValues, []);
      if (currentGroupType === GroupType.ADSPACE) {
        if (selectedInputType === InputType.SINGLE) {
          setFieldValue('groupValues', _.uniqBy([...groupValues, { label: value[GroupType.ADSPACE].name, value: value[GroupType.ADSPACE].id }], 'value'));
        } else {
          const inputItems = textToSelectOptions(value[GroupType.ADSPACE].inputs);
          setFieldValue('groupValues', _.uniqBy([...groupValues, ...inputItems], 'value'));
        }
      } else {
        setFieldValue('groupValues', _.uniqBy([...groupValues, ...value[GroupType.DOMAIN]], 'value'));
      }
    };
    const handleCopyToClipboard = () => {
      const groupValues = _.defaultTo(values.groupValues, []);
      const copyText = groupValues.reduce((acc, value) => acc.concat(value.value, ',', value.label, '\n'), '');
      writeToClipboard(copyText);
    };
    const handlePasteFromClipboard = async (e) => {
      e.preventDefault();
      const groupValues = _.defaultTo(values.groupValues, []);
      const pasteText = await readFromClipboard();
      const addGroupValues = textToSelectOptions(pasteText);
      setFieldValue('groupValues', _.uniqBy([...groupValues, ...addGroupValues], 'value'));
      return false;
    };
    return (
      <>
        {renderCustomModal(currentGroupType, addCustomGroupValue)}
        <Form>
          <div className={styles.formContent}>
            <FormikField.Input
              label={i18n.t<string>('productGroupForm.labels.groupName')}
              name='groupName'
            />
            <FormikField.Select
              label={i18n.t<string>('productGroupForm.labels.groupType')}
              name='groupType'
              simpleValue
              options={modelData.productGroupTypeOptions}
              onChange={resetGroupValues}
              className={styles.selectField}
            />
            {
              currentGroupType === GroupType.ADSPACE ?
                <CustomField label={i18n.t<string>('productGroupForm.labels.adspace')} name='groupValues'>
                  <div className={styles.row}>
                    <div onPaste={handlePasteFromClipboard}>
                      <FormikField.Select
                        name='groupValues'
                        isMulti
                        options={modelData.spaceOptions}
                        className={styles.selectField}
                        closeMenuOnSelect={false}
                      />
                      <span className={styles.copyHintLabel} onClick={handleCopyToClipboard}>{i18n.t<string>('common.labels.copyToClipBoard')}</span>
                    </div>
                    <Button variant='secondary' size='sm' onClick={_.partial(modelData.showCustomModal, currentGroupType)}>
                      {i18n.t<string>('productGroupForm.buttons.customSpace')}
                    </Button>
                  </div>
                </CustomField> :
                <CustomField label={i18n.t<string>('productGroupForm.labels.domain')} name='domain'>
                  <div className={styles.row}>
                    <div onPaste={handlePasteFromClipboard}>
                      <FormikField.Select
                        name='groupValues'
                        isMulti
                        options={modelData.domainOptions}
                        className={styles.selectField}
                        closeMenuOnSelect={false}
                      />
                      <span className={styles.copyHintLabel} onClick={handleCopyToClipboard}>{i18n.t<string>('common.labels.copyToClipBoard')}</span>
                    </div>
                    <Button variant='secondary' size='sm' onClick={_.partial(modelData.showCustomModal, currentGroupType)}>
                      {i18n.t<string>('productGroupForm.buttons.customDomain')}
                    </Button>
                  </div>
                </CustomField>
            }
            <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
              <FormikField.Select
                label={i18n.t<string>('productGroupForm.labels.agencyIds')}
                name='agencyIds'
                simpleValue
                isMulti
                options={modelData.agencyOptions}
                className={styles.selectField}
                closeMenuOnSelect={false}
              />
            </PermissionChecker>
            <FormikField.TextArea
              label={i18n.t<string>('productGroupForm.labels.description')}
              name='description'
            />
          </div>
          <div className={styles.formButtons}>
            <button
              type='submit'
              className='btn btn-primary btn-sm'
              disabled={isSubmitting}
            >
              {i18n.t<string>('common.buttons.submit')}
            </button>
            <Link
              to={'/system/product-groups'}
              className='btn btn-secondary btn-sm'
            >
              {i18n.t<string>('common.buttons.cancel')}
            </Link>
          </div>
        </Form>
      </>
    );
  };

  return (
    <div className={styles.productGroupForm}>
      {modelData.loading && <LoadingIndicator />}
      {modelData.redirectPath && <Redirect to={modelData.redirectPath} />}
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {modelData.title}
        </div>
      </div>
      <Formik
        initialValues={modelData.initProductGroup}
        enableReinitialize
        onSubmit={modelData.save}
        validate={modelData.validate}
        validateOnBlur={false}
      >
        {renderFormContent}
      </Formik>
    </div>
  );
};
