import { Order } from 'core/order/Order';
import moment from 'moment';

export interface L2ObjectManager {
  getInitDateRange (order: Order): {
    startTime: string,
    endTime: string
  };
}

export class DefaultL2ObjectManager implements L2ObjectManager {

  getInitDateRange (order: Order) {
    const now = moment().startOf('hour');
    const orderStartDate = moment(order.startDate);
    const startTime = now.isBefore(orderStartDate) ? orderStartDate : now;
    const endTime = moment.min(
        moment(order.endDate)
          .add(1, 'days')
          .subtract(1, 'seconds'),
          moment(startTime).add(91, 'days').endOf('day')
      );
    return {
      startTime: startTime.format('YYYY-MM-DD HH:mm:ss'),
      endTime: endTime.format('YYYY-MM-DD HH:mm:ss')
    };
  }
}
