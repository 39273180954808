import { AuthenticationManager } from 'core/auth/AuthenticationManager';
import { FbUser } from './fbUser';

export interface FbManager {

  fbLogin (scope: string[]): Promise<FbUser | undefined>;
  getAdsManagementPermission (): Promise<{
    fbUserValid: boolean,
    permissionGranted: boolean
  }>;
  checkAdsManagementPermission (): Promise<{
    fbUserValid: boolean,
    permissionGranted: boolean
  }>;
}

export class DefaultFbManager implements FbManager {

  constructor (private authenticationManager: AuthenticationManager) {}

  async fbLogin (scope: string[]) {
    return new Promise<FbUser | undefined>(resolve => {
      FB.login((response) => {
        if (!response.authResponse || response.status === 'unknown') {
          resolve(undefined);
          return;
        }
        const grantedScopes: string = response.authResponse.grantedScopes ? response.authResponse.grantedScopes : '';
        const scopes = grantedScopes.split(',');
        if (!scopes.includes('email')) {
          resolve(undefined);
          return;
        }
        if (response.status === 'connected' && response.authResponse) {
          FB.api('/me', { fields: 'name,email' }, function (userInfo: any) {
            resolve({
              ...response.authResponse,
              name: userInfo.name,
              email: userInfo.email
            });
          });
        } else {
          resolve(undefined);
        }
      }, {
        scope: scope.join(','),
        return_scopes: true,
        auth_type: 'rerequest'
      });
    });
  }

  async getAdsManagementPermission () {
    return new Promise<{
      fbUserValid: boolean,
      permissionGranted: boolean
    }>(async resolve => {
      const fbLoginStatus = await this.getFbLoginStatus();
      const fbUserInfo = await this.getFbUserInfo();
      if (fbLoginStatus.status !== 'connected' || fbUserInfo.error || fbUserInfo.email !== this.authenticationManager.account?.email) {
        resolve({
          fbUserValid: false,
          permissionGranted: false
        });
      } else {
        FB.login((response) => {
          if (response.status === 'connected' && response.authResponse) {
            this.authenticationManager.validateFbAdsManagement(response.authResponse.accessToken).then(permissions => {
              resolve({
                fbUserValid: true,
                permissionGranted: permissions.includes('ads_management')
              });
            }, () => {
              resolve({
                fbUserValid: true,
                permissionGranted: false
              });
            });
          } else {
            resolve({
              fbUserValid: true,
              permissionGranted: false
            });
          }
        }, {
          scope: 'ads_management',
          auth_type: 'rerequest'
        });
      }
    });
  }

  async checkAdsManagementPermission (): Promise<{
    fbUserValid: boolean,
    permissionGranted: boolean
  }> {
    const fbLoginStatus = await this.getFbLoginStatus();
    const fbUserInfo = await this.getFbUserInfo();
    if (fbLoginStatus.status !== 'connected' || fbUserInfo.error || fbUserInfo.email !== this.authenticationManager.account?.email) {
      return {
        fbUserValid: false,
        permissionGranted: false
      };
    } else {
      const permissions = await this.authenticationManager.validateFbAdsManagement(fbLoginStatus.authResponse.accessToken);
      return {
        fbUserValid: true,
        permissionGranted: permissions.includes('ads_management')
      };
    }
  }

  async getFbLoginStatus (): Promise<fb.StatusResponse> {
    return new Promise(resolve => {
      FB.getLoginStatus((response) => {
        if (response.status !== 'connected') {
          FB.login(resolve);
        } else {
          resolve(response);
        }
      });
    });
  }

  async getFbUserInfo (): Promise<any> {
    return new Promise(resolve => {
      FB.api('/me', { fields: 'name,email' }, function (userInfo: any) {
        resolve(userInfo);
      });
    });
  }
}
