import React, { useState } from 'react';
import i18n from 'i18n';
import styles from './segmentCountButton.module.scss';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { DefaultGoSegmentManager, GoSegmentManager } from 'core/goSegment/GoSegmentManager';
import { useCallAPI } from 'hooks/useCallAPI';

const defaultGoSegmentManager: GoSegmentManager = new DefaultGoSegmentManager();

export const SegmentCountButton = ({
  audienceBrief
}) => {

  const { loading, callAPIs } = useCallAPI();
  const [segmentCount, setSegmentCount] = useState<any>(undefined);

  const getSegmentCountInfo = async () => {
    callAPIs([
      defaultGoSegmentManager.getGoSegmentCount.bind(defaultGoSegmentManager, audienceBrief)
    ], setSegmentCount);
  };

  return (
    <div className={styles.countEstimateArea}>
      {loading && <LoadingIndicator/>}
      <button
        type='button'
        className={`btn btn-secondary btn-sm ${styles.createBtn}`}
        onClick={getSegmentCountInfo}
      >
        {i18n.t<string>('goSegmentForm.buttons.segmentCountBtn')}
      </button>
      {segmentCount &&
        <div className={styles.countHint}>
          {i18n.t<string>('goSegmentForm.info.deviceIdCount', { count: segmentCount.deviceIdCount })} <br/>
          {i18n.t<string>('goSegmentForm.info.customerIdCount', { count: segmentCount.customerIdCount })}
        </div>
      }
    </div>
  );
};
