import { renderColumn } from 'components/TableColumn/TableColumn';
import { getAdvertiserListFormatters } from './advertiserListFormatters';
import styles from './advertiserList.module.scss';

export enum ADVERTISER_COLUMNS {
  NAMEID = 'id',
  WEBSITE = 'website',
  CATEGORY = 'category',
  EDITBTNS = 'editBtns'
}

export const advertiserListColumnSettings = () => {
  const getColumnSetting = (column: ADVERTISER_COLUMNS, formatter) => {
    const columnClassGetter = () => {
      return styles[column];
    };
    return renderColumn({
      sort: true,
      text: `advertiserList.headers.${column}`,
      dataField: column,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    }, formatter);
  };
  const formatters = getAdvertiserListFormatters();
  return [
    getColumnSetting(
      ADVERTISER_COLUMNS.NAMEID,
      formatters.nameFormatter
    ),
    getColumnSetting(
      ADVERTISER_COLUMNS.CATEGORY,
      formatters.categoryFormatter
    ),
    getColumnSetting(
      ADVERTISER_COLUMNS.WEBSITE,
      formatters.urlFormatter
    ),
    {
      ...getColumnSetting(
        ADVERTISER_COLUMNS.EDITBTNS,
        formatters.floatingEditBtnsFormatter
      ),
      text: '',
      sort: false
    }
  ];
};
