import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import React from 'react';
import styles from './productSegmentList.module.scss';
import i18n from 'i18n';
import { addOnEnabled } from 'core/permission/PermissionDSL';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Link } from 'react-router-dom';
import { SelectOptions } from 'components/common/commonType';
import { SearchBar } from 'components/common/SearchBar';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { useProductSegmentListModel } from './productSegmentListModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { RecentGroupDropdown } from 'components/RecentGroupDropdown/RecentGroupDropdown';
import { SessionStorageItemKeys } from 'helper/StorageHelper';
import Select from 'components/common/Select/Select';
import { Modal } from 'components/common/Modal/Modal';
import { isEmpty, partial } from 'lodash';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { ProductSegmentStatus } from 'core/product/ProductSegment';

export type ProductSegmentListProps = {
  advertisers: SelectOptions[];
  retailers: SelectOptions[];
};

const statusFilterOptions = createSelectOptionsFromEnum(ProductSegmentStatus, 'productSegment.status.');

export const ProductSegmentList: React.FC<ProductSegmentListProps> = ({
  advertisers,
  retailers
}) => {

  const {
    loading,
    columns,
    modalData,
    showActive,
    pagination,
    retailerId,
    advertiserId,
    searchString,
    productSegments,
    setShowActive,
    setModalData,
    setRetailerId,
    handleOnSearch,
    setAdvertiserId,
    handleTableChange
  } = useProductSegmentListModel(retailers);

  const renderModal = () => {
    if (!modalData) {
      return;
    }
    const usageType = modalData.usageType;
    const detail = modalData.detail.split(',').map((detail, index) => (
      <li key={index}>
        {detail}
      </li>
    ));
    return (
      <Modal
        className={styles.archiveProductSegmentModal}
        title=''
        primaryButton={modalData.confirmBtnData}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.cancel'),
          callback: partial(setModalData, undefined)
        }}
        dismiss={partial(setModalData, undefined)}
      >
        <div>
          <div className={styles.modalHint}>{i18n.t<string>(`productSegmentList.labels.modalHint_${usageType}`)}</div>
          {!isEmpty(modalData.detail) && <ul>{detail}</ul>}
        </div>
      </Modal>
    );
  };

  const onStatusFilterChanged = (status: ProductSegmentStatus) => {
    setShowActive(status === ProductSegmentStatus.ACTIVE);
  };

  return (
    <div className={styles.productSegmentList}>
      {loading && <LoadingIndicator />}
      {renderModal()}
      <div className={styles.titleArea}>
        {i18n.t<string>('productSegmentList.labels.title')}
        <div className={styles.filterArea}>
          <div className={styles.advertiserList}>
            <div className={styles.title}>
              {i18n.t<string>('productSegmentList.labels.advertiserListTitle')}
            </div>
            <RecentGroupDropdown
              value={advertiserId}
              options={advertisers}
              storageKey={SessionStorageItemKeys.ADVERTISER}
              callback={setAdvertiserId}
              useCustomStyle={true}
            />
          </div>
          <div className={styles.retailerList}>
            <div className={styles.title}>
              {i18n.t<string>('productSegmentList.labels.retailerListTitle')}
            </div>
            <Select
              simpleValue
              value={retailerId}
              options={retailers}
              name='retailerOptions'
              onChange={setRetailerId}
              placeholder={i18n.t<string>('productSegmentList.placeholders.retailers')}
            />
          </div>
          <div className={styles.statusList}>
            <div className={styles.title}>
              {i18n.t<string>('productSegmentList.labels.statusFilter')}
            </div>
            <Select
              simpleValue
              value={showActive ? ProductSegmentStatus.ACTIVE : ProductSegmentStatus.ARCHIVED}
              options={statusFilterOptions}
              name='statusFilterOptions'
              onChange={onStatusFilterChanged}
              placeholder={i18n.t<string>('productSegmentList.placeholders.retailers')}
            />
          </div>
        </div>
      </div>
      <div className={styles.operationArea}>
        <PermissionChecker permissionAware={addOnEnabled(ADDONFEATURE.CHANNEL.RETAIL_MEDIA)}>
          <Link
            to={'/product-segments/new'}
            className={'btn btn-tertiary btn-sm'}
          >
            {i18n.t<string>('productSegmentList.labels.newProductSegment')}
          </Link>
        </PermissionChecker>
        <SearchBar
          model={{
            placeholder: i18n.t<string>('productSegmentList.placeholders.searchbar'),
            search: handleOnSearch,
            defaultValue: searchString
          }}
        />
      </div>
      <div className={styles.contentArea}>
        <StickableBootstrapTable
          stickFirstColumn
          stickLastColumn
          remote={true}
          keyField='segmentId'
          data={productSegments}
          columns={columns}
          noDataIndication={i18n.t<string>('productSegmentList.labels.noData')}
          onTableChange={handleTableChange}
          defaultSorted={[{
            dataField: 'segmentId',
            order: 'desc'
          }]}
          pagination={{
            page: pagination.page,
            sizePerPage: pagination.size,
            totalSize: pagination.totalCount
          }}
        />
      </div>
    </div>
  );
};
