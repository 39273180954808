import { DefaultGoSegmentManager, GoSegmentManager } from 'core/goSegment/GoSegmentManager';
import { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import i18n from 'i18n';
import { GoSegmentFormData } from 'core/goSegment/GoSegment';
import { SingleSelectComponentModel } from 'components/CampaignLimitation/SelectComponent/SingleSelectComponentModel';
import { defaultItemSetting } from 'components/CampaignLimitation/SelectItemComponent';
import { GoSegmentFormModelData, useCreateGoSegmentFormModel, useEditGoSegmentFormModel } from './GoSegmentFormModel';
import { useCallAPI } from 'hooks/useCallAPI';

const useGetGojekSegmentHint = () => {

  const [goSegmentProductFocus, setGoSegmentProductFocus] = useState<any>([]);
  const { callAPIs } = useCallAPI();

  useEffect(() => {
    const manager: GoSegmentManager = new DefaultGoSegmentManager();
    callAPIs([
      manager.getGoSegmentProductFocus.bind(manager)
    ], goSegmentProductFocus => setGoSegmentProductFocus(goSegmentProductFocus));
  }, [callAPIs]);

  const selectComponentModel = useMemo(() => {
    const options = goSegmentProductFocus.map(productFocus => ({
      label: productFocus.product_focus,
      value: productFocus.product_focus,
      isGroup: true,
      options: productFocus.segments.map(segment => ({
        label: segment,
        value: segment
      }))
    }));
    return new SingleSelectComponentModel(
      'name',
      false,
      {
        ...defaultItemSetting(),
        readonly: true
      },
      i18n.t<string>('goSegmentForm.placeholders.segmentHint'),
      () => {
        // This is intentional
      },
      options,
      [],
      undefined
    );
  }, [goSegmentProductFocus]);

  return {
    goSegmentProductFocus,
    selectComponentModel
  };
};

export const useCreateGoSegmentLogicFormModel = (): GoSegmentFormModelData => {

  const { validate: basicValidate, ...basicProps } = useCreateGoSegmentFormModel();

  const { goSegmentProductFocus, selectComponentModel } = useGetGojekSegmentHint();

  const validateAudienceBrief = (audienceBrief) => {
    const emptyError = validateEmpty(audienceBrief);
    if (emptyError) {
      return emptyError;
    }
    // Convert a well-formatted audience brief to JSON
    const elements = audienceBrief.replaceAll(/\b(AND|OR)\b/g, ',"$1",')
                                  .replaceAll('(', '[')
                                  .replaceAll(')', ']')
                                  .replaceAll(new RegExp('(?<!")(\\w[^"]*\\w)(?!")', 'g'), '"$1"');

    const jsonString = '[' + elements + ']';
    try {
      const elements = _.flattenDeep(JSON.parse(jsonString));
      const segments = _.flatten(goSegmentProductFocus.map(productFocus => productFocus.segments));
      const invalidElements = elements.filter(element => {
        return element !== 'OR' && element !== 'AND' && !segments.includes(element);
      });
      if (invalidElements.length > 0) {
        return i18n.t<string>('goSegmentForm.errors.invalidAudienceName');
      }
    } catch (e) {
      return i18n.t<string>('goSegmentForm.errors.invalidAudienceBrief');
    }
  };

  const validate = (value: Partial<GoSegmentFormData>) => {
    const basicError = basicValidate(value);
    return _.omitBy({
      ...basicError,
      audienceBrief: validateAudienceBrief(value.audienceBrief)
    }, _.isEmpty);
  };

  return {
    ...basicProps,
    extraData: {
      selectComponentModel
    },
    formType: 'create',
    validate
  };
};

export const useEditGoSegmentLogicFormModel = (
  segmentId: number | string
): GoSegmentFormModelData => {

  const { validate: basicValidate, ...basicProps } = useEditGoSegmentFormModel(segmentId);

  const { selectComponentModel } = useGetGojekSegmentHint();

  const validate = (value: Partial<GoSegmentFormData>) => {
    const basicError = basicValidate(value);
    return _.omitBy({
      ...basicError,
      audienceBrief: validateEmpty(value.audienceBrief)
    }, _.isEmpty);
  };

  return {
    ...basicProps,
    formType: 'edit',
    extraData: {
      selectComponentModel
    },
    validate
  };
};
