import React from 'react';

type StateDataContextType = {
  data: any,
  setData (data: any): void
};

export const StateDataContext = React.createContext<StateDataContextType>({
  data: {},
  setData: (data) => {}
});
