import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import i18n from 'i18next';
import { CreativeSetupTab } from './CreativeSetupStepModel';
import _ from 'lodash';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { SelectOptions } from 'components/common/commonType';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { toast } from 'react-toastify';
import { toServerStructure } from 'utils/LimitationUtil';
import { CreativeFormData } from './SubSteps/FormContent/FormContentModel';
import { CreativeSummaryModel } from './SubSteps/SummaryContent/CreativeSummaryModel';
import { CreativeSetupFlowDataContextType } from '../CreativeSetupFlowDataContext';
import { CreativeType } from 'core/creative/Creative';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { AdLogoType } from 'core/adLogo/AdLogo';
import defaultAdLogo from 'assets/campaign/tenmax-ad-logo.svg';

export enum CreateCreativeSetupFlowStep {
  CHOOSE_ADVERTISER,
  SETUP_CREATIVE,
  SUMMARY
}

export enum EditCreativeSetupFlowStep {
  SETUP_CREATIVE,
  SUMMARY
}

export type CreativeSummaryStepState = {
  readonly loading: boolean;
  readonly basicSummaryData: any;
  readonly submitAlertModalData: any;
};
export interface CreativeSummaryStepModel {
  readonly state: CreativeSummaryStepState;
  readonly event: UpdateEventListener<CreativeSummaryStepModel>;
  readonly mediaSummaryComponent?: any;
  readonly mediaSummaryData?: any;
  getAdvertiserSummaryData: (advertisers: Array<SelectOptions>, advertiserId: number) => any;
  getLimitationsSummaryData: () => any;
  getAdLogoSummaryData: () => any;
  getAdLogoPreviewData: () => any;
  goLast: () => void;
  goStep: (stepIndex: number, subStepIndex: number) => void;
  submit: (event, callback?: () => void) => void;
  initContextData (contextData: CreativeSetupFlowDataContextType);
  initSummaryData (): Promise<any>;
  setBasicSummaryData (basicSummaryData);
  finishedCallback?: (redirectData: {
    pathname: string,
    search?: string,
    state?: any
  }) => void;
}

export type CreativeSummaryStepProps = {
  readonly model: CreativeSummaryStepModel;
};

abstract class DefaultCreativeSummaryStepModel implements CreativeSummaryStepModel {
  event: FireableUpdateEventListener<CreativeSummaryStepModel>;
  loading: boolean;
  finishedCallback?: (redirectData: {
    pathname: string,
    search?: string,
    state?: any
  }) => void;
  creativeFormData?: CreativeFormData;
  summaryContentModel?: CreativeSummaryModel;
  basicSummaryData?: any;
  mediaSummaryComponent?: any;
  mediaSummaryData?: any;
  submitAlertModalData?: any;

  constructor (
    protected type: string,
    private canChooseAdvertiser: boolean,
    public goLast: () => void,
    public goStep: (stepIndex: number, subStepIndex: number) => void,
    protected getUploadedFileData: (file: File) => Promise<any>,
    protected addUploadedFilesData: (file: File, data: any) => Promise<void>,
    protected creativeManager: CreativeManager = new DefaultCreativeManager(),
    protected campaignId?: string,
    protected orderNumber?: string,
    protected l1ObjectId?: string | null,
    protected draftId?: string | null
  ) {
    this.event = new FireableUpdateEventListener<CreativeSummaryStepModel>();
    this.loading = false;
  }

  get state (): CreativeSummaryStepState {
    return {
      loading: this.loading,
      basicSummaryData: this.basicSummaryData,
      submitAlertModalData: this.submitAlertModalData
    };
  }

  get creativeSetupStepIndex () {
    if (this.type === 'create' && this.canChooseAdvertiser) {
      return CreateCreativeSetupFlowStep.SETUP_CREATIVE;
    } else {
      return EditCreativeSetupFlowStep.SETUP_CREATIVE;
    }
  }

  get creativeManagementSearchParams () {
    const campaignParam = this.draftId ? `draftIds=${this.draftId}` : `campaignIds=${this.campaignId}`;
    return `?${campaignParam}&action=manage`;
  }

  initContextData (contextData: CreativeSetupFlowDataContextType) {
    if (contextData.creative !== this.creativeFormData) {
      this.summaryContentModel = contextData.getSummaryModel(contextData.creative.basic);
      this.creativeFormData = contextData.creative;
      this.finishedCallback = contextData.setFinishedRedirectData;
    }
  }

  async initSummaryData () {
    if (this.summaryContentModel) {
      this.updateState(true);
      try {
        const summaryData = await this.summaryContentModel.getCreativeBasicSummaryData(this.getUploadedFileData, this.addUploadedFilesData);
        this.basicSummaryData = {
          title: i18n.t<string>('stepSideBar.labels.creativeBasic'),
          backStep: this.creativeSetupStepIndex,
          backSubStep: CreativeSetupTab.BASIC,
          data: {
            general: {
              title: i18n.t<string>('summary.titles.generalInfo'),
              content: summaryData
            }
          }
        };
        this.mediaSummaryComponent = this.getMediaSummaryComponent();
        this.mediaSummaryData = this.getCreativeMediaSummaryData();
      } catch (e) {}
      this.updateState(false);
    }
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }

  getAdvertiserSummaryData (advertisers, advertiserId) {
    const advertiser = _.find(advertisers, advertiser => advertiser.value === advertiserId);
    return {
      title: i18n.t<string>('stepSideBar.labels.creativeChooseAdvertiser'),
      data: {
        advertiser: {
          title: undefined,
          content: [
            {
              label: i18n.t<string>('creativeSetupFlow.labels.advertiser'),
              value: advertiser ? advertiser.label : ''
            }
          ]
        }
      }
    };
  }

  // getBasicSetupSummaryData () {
  //   const basicSetupSummary = this.summaryContentModel ? this.summaryContentModel.getCreativeBasicSummaryData() : {};
  //   return {
  //     title: i18n.t<string>('stepSideBar.labels.creativeBasic'),
  //     backStep: this.creativeSetupStepIndex,
  //     backSubStep: CreativeSetupTab.BASIC,
  //     data: {
  //       general: {
  //         title: i18n.t<string>('summary.titles.generalInfo'),
  //         content: basicSetupSummary
  //       }
  //     }
  //   };
  // }

  getMediaSummaryComponent () {
    return this.summaryContentModel ? this.summaryContentModel.getMediaSummaryComponent() : undefined;
  }

  getLimitationsSummaryData () {
    if (!this.creativeFormData) {
      return;
    }
    const limitations = this.creativeFormData.limitations;
    if (!limitations) {
      return;
    }

    const summaryData = this.creativeManager.getLimitationSummaryData(limitations);

    return {
      title: i18n.t<string>('stepSideBar.labels.creativeLimitation'),
      backStep: this.creativeSetupStepIndex,
      backSubStep: CreativeSetupTab.LIMITATION,
      data: {
        include: {
          title: i18n.t<string>('campaignSummary.titles.inc'),
          titlePrefixColor: SummaryTitleColor.GREEN,
          content: summaryData.include
        },
        preferred: {
          title: i18n.t<string>('campaignSummary.titles.preferred'),
          titlePrefixColor: SummaryTitleColor.BLUE,
          content: summaryData.preferred
        },
        nonPreferred: {
          title: i18n.t<string>('campaignSummary.titles.nonPreferred'),
          titlePrefixColor: SummaryTitleColor.YELLOW,
          content: summaryData.nonPreferred
        },
        exclude: {
          title: i18n.t<string>('campaignSummary.titles.exc'),
          titlePrefixColor: SummaryTitleColor.RED,
          content: summaryData.exclude
        },
        other: {
          title: i18n.t<string>('campaignSummary.titles.other'),
          content: summaryData.other
        }
      }
    };
  }

  getFileSummaryData (image) {
    const file = _.get(image, 'file');
    const url = _.get(image, 'url');
    if (file || url) {
      return {
        file,
        url
      };
    }
  }

  getAdLogoSummaryData () {
    if (!this.creativeFormData) {
      return {};
    }
    const adLogo = this.creativeFormData.basic.adLogo;
    if (!adLogo) {
      return {};
    }
    return {
      title: i18n.t<string>('campaignSummary.titles.adLogo'),
      backStep: this.creativeSetupStepIndex,
      backSubStep: CreativeSetupTab.ADLOGO,
      data: {
        adLogoBasic: {
          content: _.compact([
            {
              label: i18n.t<string>('adLogoForm.labels.logoType'),
              value: i18n.t<string>(`adLogoForm.labels.${adLogo.type.toLowerCase()}`)
            },
            adLogo.type === AdLogoType.CUSTOM ? {
              label: i18n.t<string>('adLogoForm.labels.logoLink'),
              value: adLogo.link
            } : undefined
          ])
        }
      }
    };
  }

  getAdLogoPreviewData () {
    if (!this.creativeFormData) {
      return undefined;
    }
    const adLogo = this.creativeFormData.basic.adLogo;
    if (!adLogo) {
      return undefined;
    }
    if (adLogo.type !== AdLogoType.NULL) {
      let imageData = adLogo.image;
      const config = {
        showName: true,
        showSize: true
      };
      if (adLogo.type === AdLogoType.DEFAULT) {
        imageData = { url: defaultAdLogo };
        config.showName = false;
      }
      return {
        title: i18n.t<string>('adLogoForm.labels.logoPreview'),
        backStep: this.creativeSetupStepIndex,
        backSubStep: CreativeSetupTab.ADLOGO,
        data: {
          imageData,
          config
        }
      };
    }
  }

  getCreativeMediaSummaryData () {
    const mediaData = this.summaryContentModel ? this.summaryContentModel.getMediaSummary() : {};
    if (_.isEmpty(mediaData)) {
      return undefined;
    }
    return {
      title: i18n.t<string>('creativeSetupFlow.labels.creativeBinding'),
      backStep: this.creativeSetupStepIndex,
      backSubStep: CreativeSetupTab.BASIC,
      goStep: this.goStep,
      data: mediaData
    };
  }

  async getFormDataToSubmit () {
    if (!this.summaryContentModel || !this.creativeFormData) {
      return;
    }
    const formData = await this.summaryContentModel.getBasicSubmitData();
    if (!this.creativeFormData.limitations || _.isEmpty(this.creativeFormData.limitations)) {
      return formData;
    }
    if (this.creativeFormData.basic.creativeType === CreativeType.IMAGE && this.type === 'create') {
      const limitations = toServerStructure(this.creativeFormData.limitations);
      formData.forEach(data => {
        data.limitation = limitations;
      });
      return formData;
    }
    const limitations = toServerStructure(this.creativeFormData.limitations);
    limitations.forEach((limitation, index) => {
      formData.append(`limitations[${index}].op`, limitation.op);
      formData.append(`limitations[${index}].isGroup`, limitation.isGroup.toString());
      formData.append(`limitations[${index}].type`, limitation.type);
      limitation.limits.forEach((limit, limitIndex) => {
        formData.append(`limitations[${index}].limits[${limitIndex}].label`, limit.label);
        formData.append(`limitations[${index}].limits[${limitIndex}].value`, limit.value);
      });
    });
    return formData;
  }

  setBasicSummaryData (basicSummaryData) {
    this.basicSummaryData = basicSummaryData;
    this.updateState(false);
  }

  abstract submitMethod (submitData): Promise<void>;

  checkSubmitAlert = () => {
    if (!this.summaryContentModel || !this.creativeFormData) {
      return;
    }
    const alertMessage = this.summaryContentModel.getSubmitAlertMessage();
    if (alertMessage) {
      this.submitAlertModalData = {
        title: i18n.t<string>('common.warning'),
        message: alertMessage,
        submit: async () => {
          this.updateState(true);
          const submitData = await this.getFormDataToSubmit();
          if (!submitData) {
            this.updateState(false);
            return;
          }
          await this.submitMethod(submitData);
          this.updateState(false);
        },
        cancel: async () => {
          this.submitAlertModalData = undefined;
          this.updateState(false);
        }
      };
      this.updateState(false);
    }
    return alertMessage !== undefined;
  }

  submit = async (evt, callback) => {
    if (this.checkSubmitAlert()) {
      return;
    }
    this.updateState(true);
    _.defer(async () => {
      try {
        const submitData = await this.getFormDataToSubmit();
        if (!submitData) {
          this.updateState(false);
          return;
        }

        await this.submitMethod(submitData);
        this.updateState(false);
      } catch (e) {
        (e instanceof Error) && toast.error(e.message);
        this.updateState(false);
      }
      callback && callback();
    });
  }
}

export class CreateCreativeSummaryStepModel extends DefaultCreativeSummaryStepModel {
  async submitMethod (submitData) {
    if (this.creativeFormData && this.creativeFormData.basic.creativeType === CreativeType.IMAGE) {
      await this.createCreativeByJson(submitData);
    } else {
      await this.createCreative(submitData);
    }
  }

  createCreative = async (creative: FormData) => {
    await this.creativeManager.createCreative(creative);
    toast.success(i18n.t<string>('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: '/creatives'
    });
  }

  createCreativeByJson = async (creativeJson: any) => {
    const data = await this.creativeManager.createCreativesByJson(creativeJson);
    toast.success(i18n.t<string>('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: '/creatives',
      state: {
        errors: _.get(data, 'error')
      }
    });
  }
}

export class EditCreativeSummaryStepModel extends DefaultCreativeSummaryStepModel {
  async submitMethod (submitData) {
    await this.creativeManager.updateCreative(submitData);
    toast.success(i18n.t<string>('creativeSetupFlow.labels.updateSuccess'));
    if (_.isNil(this.orderNumber) || (_.isNil(this.campaignId) && _.isNil(this.draftId))) {
      this.finishedCallback && this.finishedCallback({
        pathname: '/creatives'
      });
    } else {
      this.finishedCallback && this.finishedCallback({
        pathname: `/orders/${this.orderNumber}/campaign-groups/${this.l1ObjectId}`,
        search: this.creativeManagementSearchParams
      });
    }
  }
}

abstract class CreateCreativeAndBindSummaryStepModel extends DefaultCreativeSummaryStepModel {

  abstract get channel ();

  async submitMethod (submitData) {
    const isDraft = !!this.draftId;
    if (this.creativeFormData && this.creativeFormData.basic.creativeType === CreativeType.IMAGE) {
      await this.creativeManager.createCreativesByJsonAndBind(
        this.channel,
        submitData,
        isDraft ? this.draftId! : this.campaignId!,
        isDraft
      );
    } else {
      await this.creativeManager.createCreativeAndBind(
        this.channel,
        submitData,
        isDraft ? this.draftId! : this.campaignId!,
        isDraft
      );
    }
    toast.success(i18n.t<string>('creativeSetupFlow.labels.createSuccess'));
    this.finishedCallback && this.finishedCallback({
      pathname: `/orders/${this.orderNumber}/campaign-groups/${this.l1ObjectId}`,
      search: this.creativeManagementSearchParams
    });
  }
}

export class CreateCreativeAndBindRTBCampaignSummaryStepModel extends CreateCreativeAndBindSummaryStepModel {
  get channel () {
    return L1ObjectChannel.RTB;
  }
}

export class CreateCreativeAndBindAdSetSummaryStepModel extends CreateCreativeAndBindSummaryStepModel {
  get channel () {
    return L1ObjectChannel.FB;
  }
}

export class CreateCreativeAndBindAdGroupSummaryStepModel extends CreateCreativeAndBindSummaryStepModel {
  get channel () {
    return L1ObjectChannel.TIKTOK;
  }
}
