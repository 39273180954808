import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import styles from './productSegments.module.scss';
import { ProductSegmentList } from './ProductSegmentList/ProductSegmentList';
import { addOnEnabled } from 'core/permission/PermissionDSL';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { ROUTE_PATH } from 'enum/RoutePath';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { useProductSegmentsModel } from './ProductSegmentsModel';
import { ProductSegmentForm } from './ProductSegmentForm/ProductSegmentForm';
import { useCreateProductSegmentFormModel, useEditProductSegmentFormModel } from './ProductSegmentForm/ProductSegmentFormModel';
import { partial } from 'lodash';

export const ProductSegments: React.FC<any> = () => {

  const modifyPermission = addOnEnabled(ADDONFEATURE.CHANNEL.RETAIL_MEDIA);
  const {
    loading,
    advertisers,
    retailers
  } = useProductSegmentsModel();

  const renderCreateProductSegment = (props) => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={modifyPermission}
        renderOtherwise={renderRedirect}
      >
        <ProductSegmentForm
          {...props}
          advertisers={advertisers}
          retailers={retailers}
          useModel={useCreateProductSegmentFormModel}
        />
      </PermissionChecker>
    );
  };

  const renderEditProductSegment = (props) => {
    const segmentId = props.match.params.id;
    const retail = props.match.params.retail;
    const retailer = retailers.find(option => option.label.toLowerCase() === retail);
    if (!retailer) {
      return <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR404}`} />;
    }
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={modifyPermission}
        renderOtherwise={renderRedirect}
      >
        <ProductSegmentForm
          {...props}
          advertisers={advertisers}
          retailers={retailers}
          useModel={partial(useEditProductSegmentFormModel, segmentId, retailer.value.toString())}
        />
      </PermissionChecker>
    );
  };

  const renderHomePage = () => (
    <div className={styles.container}>
      <ProductSegmentList
        advertisers={advertisers}
        retailers={retailers}
      />
    </div>
  );

  if (!retailers || retailers.length === 0) {
    return <LoadingIndicator />;
  }

  return (
    <Fragment>
      {loading && <LoadingIndicator />}
      <Switch>
        <Route
          path={'/product-segments/new'}
          render={renderCreateProductSegment}
        />
        <Route
          exact
          path={'/product-segments/:retail/:id/edit'}
          render={renderEditProductSegment}
        />
        <Route
          render={renderHomePage}
        />
      </Switch>
    </Fragment>
  );
};
