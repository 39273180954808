import React from 'react';
import { FormikField } from 'components/common/form/field/FormikField';
import { FormikProps } from 'formik';
import i18n from 'i18n';
import { GoSegmentFormData } from 'core/goSegment/GoSegment';
import styles from './goSegmentForm.module.scss';
import { numberWithCommas } from 'utils/StringUtil';
import countryOptions from '../../lookalikeCountryOptions';

export const GoSegmentLookalikeFields: React.FC<{
  formType: string,
  formikProps: FormikProps<GoSegmentFormData>,
  extraData: any
}> = ({
  formType,
  extraData
}) => {

  const renderFbApproximateReachField = value => numberWithCommas(value * extraData.fbApproximateReach / 100);
  const renderTiktokApproximateReachField = value => {
    if (value <= 5) {
      return 'Narrow';
    } else if (value <= 10) {
      return 'Balanced';
    }
    return 'Broad';
  };

  const countryFormatter = value => {
    const country = countryOptions.find(option => option.value === value);
    return country ? country.label : '';
  };
  const seedAudienceFormatter = () => extraData.seedSegmentName;
  const ratioFormatter = value => `${value}%`;

  return (
    <>
      <FormikField.Label
        label={i18n.t<string>('lookalike.fields.seedAudience')}
        name='lookalike.seed_segment_id'
        formatter={seedAudienceFormatter}
      />
      {formType === 'create' ?
        <FormikField.Select
          label={i18n.t<string>('lookalike.fields.country')}
          name='lookalike.country'
          simpleValue
          options={countryOptions}
          className={styles.selectField}
          onChange={extraData.onCountryChange}
        /> :
        <FormikField.Label
          label={i18n.t<string>('lookalike.fields.country')}
          name='lookalike.country'
          formatter={countryFormatter}
        />
      }
      {formType === 'create' ?
        <FormikField.InputGroup
          label={i18n.t<string>('lookalike.fields.lookalikeRatio')}
          name='lookalike.ratio'
          type={'number'}
          min={1}
          max={20}
          postfix={'%'}
          className={styles.ratio}
        /> :
        <FormikField.Label
          label={i18n.t<string>('lookalike.fields.lookalikeRatio')}
          name='lookalike.ratio'
          formatter={ratioFormatter}
        />
      }
      <FormikField.Label
        label={i18n.t<string>('lookalike.fields.fbApproximateReach')}
        name='lookalike.ratio'
        formatter={renderFbApproximateReachField}
      />
      <FormikField.Label
        label={i18n.t<string>('lookalike.fields.tiktokApproximateReach')}
        name='lookalike.ratio'
        formatter={renderTiktokApproximateReachField}
      />
    </>
  );
};
