import React from 'react';
import classnames from 'classnames/bind';
import styles from './selectField.module.scss';
import { SelectOptions } from 'components/common/commonType';
import NativeSelect from 'components/common/Select/Select';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';
import { FormikFieldProps, withFormikField } from 'hoc/withFormikField';

const cx = classnames.bind(styles);

interface SelectProps {
  labelKey?: string;
  valueKey?: string;
  isMulti?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
  options: SelectOptions[];
  simpleValue?: boolean;
  name: string;
  className?: string;
  onChange?: (value: any) => void;
  value?: SelectOptions | string | number;
  isClearable?: boolean;
  placeholder?: string;
  defaultValue?: any;
  maxHeight?;
  getValue?;
  closeMenuOnSelect?: boolean;
  maxMenuHeight?: number;
  optionComponent?: any;
  singleValue?: any;
  multiValueLabel?: any;
  componentWidthFitParent?: boolean;
  error?: any;
  menuPlacement?: 'top' | 'bottom' | 'auto';
}

export interface SelectFieldProps extends SelectProps, Omit<FieldLayoutProps, 'name'> {}
export interface FormikSelectProps extends FormikFieldProps, SelectProps {}
export interface FormikSelectWithFieldLayoutProps extends FormikFieldProps, Omit<FieldLayoutProps, 'name'>, SelectProps {}
export type FormikSelectFieldProps = ({ withFieldLayout?: true } & FormikSelectWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikSelectProps);

const SelectContent: React.FC<SelectProps> = ({
  error,
  className,
  disabled,
  ...props
}) => {

  const hasError = error !== undefined;
  const classNameWithError = cx(className, {
    error: hasError
  });

  return (
    <NativeSelect
      {...props}
      className={classNameWithError}
      isDisabled={disabled}
    />
  );
};
const MemorizedSelectContent = React.memo(SelectContent);

export const SelectField = withFieldLayout<SelectFieldProps>(SelectContent);
export const FormikSelect = withFormikField<FormikFieldProps & SelectProps>(MemorizedSelectContent);
export const FormikSelectWithFieldLayout = withFormikField<FormikSelectWithFieldLayoutProps>(
  withFieldLayout<FieldLayoutProps & SelectProps>(SelectContent)
);

export const FormikSelectField: React.FC<FormikSelectFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikSelect {...props} />;
  } else {
    return <FormikSelectWithFieldLayout {...props} />;
  }
};
