import { Source } from 'core/tenmaxSegment/TenmaxSegment';
import { DefaultTenmaxSegmentManager } from 'core/tenmaxSegment/TenmaxSegmentManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { useState, useCallback, useEffect, useMemo } from 'react';
import i18n from 'i18n';
import { useParams } from 'react-router-dom';

const tenmaxSegmentManager = new DefaultTenmaxSegmentManager();

export const useSourceDetailModel = () => {

  const [source, setSource] = useState<Source | null>(null);
  const { loading, callAPIs } = useCallAPI();
  const { sourceId } = useParams<{sourceId: string}>();

  const fetchSouce = useCallback(() => {
    callAPIs([() => tenmaxSegmentManager.getSource(+sourceId)], (source: Source) => {
      setSource(source);
    });
  }, [sourceId, callAPIs]);

  useEffect(() => {
    fetchSouce();
  }, [fetchSouce]);

  const viewData = useMemo(() =>
    source ? ([
      { label: i18n.t<string>('source.labels.duration'), value: `${source.duration} ${i18n.t<string>('common.day')}` },
      { label: i18n.t<string>('source.labels.tags'), value: source.tags.join(', ') },
      { label: i18n.t<string>('source.labels.countries'), value: source.countries.join(', ') }
    ]) : null,
    [source]
  );

  return {
    loading,
    source,
    viewData
  };
};
