import moment from 'moment-timezone';
import { unitOfTime } from 'moment';
import { ReactText } from 'react';
/**
 * LocalDateTimeUtil use in agency time zone date time
 */

export const DEFAULT_FORMAT = 'YYYY-MM-DD_HH:mm:ss';
const IGNOR_TIMEZONE_FORMAT = 'YYYY-MM-DD HH:mm:ss';

/**
 * This method ignore time zone.
 * If you want to date time of another time zone, you can use this method.
 * You will get user browser date time if you use moment().toDate().
 *
 * example:
 *
 * moment.tz.setDefault('America/Los_Angeles');
 * moment().toDate();  // Wed Nov 27 2019 15:00:00 GMT+0800
 * new Date(moment().format('YYYY-MM-DD HH:mm:ss')) // Tue Nov 26 2019 23:00:00 GMT+0800
 */
function momentToDate (momentObj) {
  return new Date(momentObj.format(IGNOR_TIMEZONE_FORMAT));
}

function str2Date (date: string, format: string = DEFAULT_FORMAT): Date {
  return momentToDate(moment(date, format));
}

function startOfStr2Date (date: string, unit: unitOfTime.Base, format: string = DEFAULT_FORMAT) {
  return momentToDate(moment(date, format).startOf(unit));
}

function endOfStr2Date (date: string, unit: unitOfTime.Base, format: string = DEFAULT_FORMAT) {
  return momentToDate(moment(date, format).endOf(unit));
}

function getYMDHMS (): string {
  return moment().format('YYYYMMDDHHmmss');
}

function getTimezone (date: string) {
  const offset = moment.parseZone(date).utcOffset();
  const positiveOffset = Math.abs(offset);
  let hour: ReactText = Math.floor(positiveOffset / 60);
  let minutes: ReactText = positiveOffset % 60;
  hour = hour < 10 ? '0' + hour : hour;
  minutes = minutes < 10 ? '0' + minutes : minutes;
  const mark = offset >= 0 ? '+' : '-';
  return `${mark}${hour}:${minutes}`;
}

const utilFunctions = {
  str2Date,
  startOfStr2Date,
  endOfStr2Date,
  getTimezone,
  getYMDHMS
};
export default utilFunctions;
