import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import { ItemType, defaultItemSetting } from 'components/CampaignLimitation/SelectItemComponent';
import { DEFAULT_INVENTORY } from 'containers/Limitations/LimitationSetting/LimitationSettingData';
import { MessageSegmentFormData } from 'core/messageSegment/MessageSegment';
import { DefaultMessageSegmentManager, MessageSegmentManager } from 'core/messageSegment/MessageSegmentManager';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { useCallAPI } from 'hooks/useCallAPI';
import i18n from 'i18n';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import { validateEmpty } from 'utils/ValidateUtils';

type InventorySetting = {
  name: string,
  cb: Function,
  title?: string,
  singleSelect: boolean,
  searchPlaceholder: string,
  itemSetting: {
    type: ItemType,
    categorySelectable: boolean,
    readonly: boolean
  }
  component: any
};

export type MessageSegmentFormModelData = {
  title: string;
  breadcrumbsRoutes: BreadcrumbsRoute[];
  formType: string;
  loading: boolean;
  initMessageSegment?: Partial<MessageSegmentFormData>;
  redirectPath?: string;
  extraData?: any;
  validate: (values: Partial<MessageSegmentFormData>) => any;
  save: (values: Partial<MessageSegmentFormData>) => Promise<void>;
  setRedirectPath: (redirectPath?: string) => void;
};

const defaultMessageSegmentManager = new DefaultMessageSegmentManager();

function basicValidate (value: Partial<MessageSegmentFormData>) {
  return _.omitBy({
    name: validateEmpty(value.name),
    advertiserId: validateEmpty(value.advertiserId)
  }, _.isEmpty);
}

const useFetchSegmentLimitationTypeObject = (audienceBrief = '') => {

  const [inventorySettings, setInventorySettings] = useState<any[]>([]);
  const [limitationTypeObject, setLimitationTypeObject] = useState<any>({});
  const { callAPIs } = useCallAPI();

  const initLimitationTypeObject = useCallback((audienceBrief, inventorySettings) => {
    const itemGroups = audienceBrief.split('AND');
    const items = _.flatMap(itemGroups
      .map(itemGroup => itemGroup
        .split('OR')
        .map(item => item.replace(/\(|\)/gi, '').trim())
      )
    );
    let limitationTypeObject = {};
    for (let inventorySetting of inventorySettings) {
      const options = inventorySetting.cb();
      if (!Array.isArray(options)) {
        continue;
      }
      const selectedOptions = options.filter(option => items.includes(option.label));
      if (selectedOptions.length > 0) {
        limitationTypeObject[inventorySetting.name] = {
          type: inventorySetting.name,
          value: selectedOptions
        };
      }
    }
    return limitationTypeObject;
  }, []);

  useEffect(() => {
    const manager: MessageSegmentManager = new DefaultMessageSegmentManager();
    callAPIs([
      () => manager.getPICCoreSegments()
    ], messageSegmentFocus => {
      const inventorySettings: InventorySetting[] = [
        {
          name: DEFAULT_INVENTORY,
          cb: _.noop,
          singleSelect: false,
          itemSetting: defaultItemSetting(),
          searchPlaceholder: '',
          component: SelectComponent
        }
      ];
      messageSegmentFocus.forEach(data => {
        inventorySettings.push({
          name: data.label,
          title: `limitation.labels.${data.label}`,
          cb: () => {
            return data.options?.map(option => {
              return {
                ...option,
                label: i18n.t<string>(`limitation.labels.${option.label}`),
                type: option.label
              };
            });
          },
          singleSelect: false,
          itemSetting: defaultItemSetting(),
          searchPlaceholder: '',
          component: SelectComponent
        });
      });
      setInventorySettings(inventorySettings);
      setLimitationTypeObject(initLimitationTypeObject(audienceBrief, inventorySettings));
    });
  }, [initLimitationTypeObject, audienceBrief, callAPIs]);

  return {
    inventorySettings,
    limitationTypeObject,
    setLimitationTypeObject
  };
};

export const useCreateMessageSegmentFormModel = (
  messageSegmentManager: MessageSegmentManager = defaultMessageSegmentManager
): MessageSegmentFormModelData => {

  const { loading, callAPIs } = useCallAPI();
  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);
  const extraData = useFetchSegmentLimitationTypeObject();

  const validate = (value: Partial<MessageSegmentFormData>) => {
    const basicError = basicValidate(value);
    return _.omitBy({
      ...basicError,
      audienceBrief: validateEmpty(value.audienceBrief)
    }, _.isEmpty);
  };

  const save = async (saveValue) => {
    callAPIs([() => messageSegmentManager.createMessageSegment(saveValue)], () => {
      setRedirectPath('/message-segments');
    });
  };

  return {
    title: i18n.t<string>('messageSegmentForm.titles.create'),
    breadcrumbsRoutes: [
      { path: '/message-segments', breadcrumb: i18n.t<string>('messageSegments.title') },
      { path: '/message-segments/new', breadcrumb: i18n.t<string>('messageSegmentForm.titles.create') }
    ],
    formType: 'create',
    loading,
    extraData,
    redirectPath,
    initMessageSegment: {
      name: '',
      description: '',
      advertiserId: SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER)
    },
    save,
    validate,
    setRedirectPath
  };
};

export const useEditMessageSegmentFormModel = (
  segmentId: number | string,
  messageSegmentManager: MessageSegmentManager = defaultMessageSegmentManager
): MessageSegmentFormModelData => {

  const { loading, callAPIs } = useCallAPI();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [audienceBrief, setAudienceBrief] = useState<string | undefined>();

  const [initMessageSegment, setInitMessageSegment] = useState<MessageSegmentFormData | undefined>({
    name: '',
    description: '',
    advertiserId: SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER)
  });

  const validate = (value: Partial<MessageSegmentFormData>) => {
    const basicError = basicValidate(value);
    return _.omitBy({
      ...basicError,
      audienceBrief: validateEmpty(value.audienceBrief)
    }, _.isEmpty);
  };

  const extraData = useFetchSegmentLimitationTypeObject(audienceBrief);

  useEffect(() => {
    callAPIs([
      () => messageSegmentManager.getMessageSegment(segmentId)
    ], messageSegment => {
      setInitMessageSegment(messageSegment);
      setAudienceBrief(messageSegment.audienceBrief);
    });
  }, [segmentId, messageSegmentManager, callAPIs, setInitMessageSegment]);

  const save = useCallback(async (saveValue) => {
    callAPIs([() => messageSegmentManager.updateMessageSegment(segmentId, saveValue)], () => {
      setRedirectPath('/message-segments');
    });
  }, [callAPIs, messageSegmentManager, segmentId]);

  return {
    title: i18n.t<string>('messageSegmentForm.titles.edit'),
    breadcrumbsRoutes: [
      { path: '/message-segments', breadcrumb: i18n.t<string>('messageSegments.title') },
      { path: '/message-segments/:segmentId/edit', breadcrumb: DynamicBreadcrumb, props: { label: initMessageSegment ? initMessageSegment.name : '', matchParam: 'segmentId' } }
    ],
    formType: 'edit',
    loading,
    extraData,
    redirectPath,
    initMessageSegment,
    save,
    validate,
    setRedirectPath
  };
};
