import React from 'react';
import { ConversionFormState, ConversionFormProps, MAX_CONVERSION_NAME_LENGTH } from './ConversionFormModel';
import { Formik, FormikProps } from 'formik';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Form, Button } from 'react-bootstrap';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { ConversionFormDTO } from 'core/conversion/Conversion';
import { getByteLength } from 'utils/StringUtil';
import styles from './conversionForm.module.scss';
import i18n from 'i18next';
import _ from 'lodash';
import { notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { getFieldErrors } from 'utils/FormikUtils';
import { FormikField } from 'components/common/form/field/FormikField';

export class ConversionForm extends React.PureComponent<RouteComponentProps<any> & ConversionFormProps, ConversionFormState> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.setState(this.props.model.state);
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleSubmit = (formData) => {
    this.props.model.submit(formData);
  }

  cancelSubmit = () => {
    this.props.history.goBack();
  }

  validate = (conversion: ConversionFormDTO): any => {
    return this.props.model.validate(conversion);
  }

  renderForm = (formProps: FormikProps<ConversionFormDTO>) => {
    const conversionNameInvalid = !!getFieldErrors(formProps, 'name');
    const conversionName = _.get(formProps.values, 'name', '');
    const remainLengh = MAX_CONVERSION_NAME_LENGTH - getByteLength(conversionName);
    const conversionNameTip = conversionNameInvalid ? '' : i18n.t<string>('formValidate.labels.remainingCharacters', { num: remainLengh });
    return (
      <Form onSubmit={formProps.handleSubmit}>
        <div className={styles.formArea}>
          <PermissionChecker
            permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport)}
          >
            {
              formProps.values.advertiserName ?
              <FormikField.Label
                label={i18n.t<string>('conversionForm.labels.advertiser')}
                name='advertiserName'
              /> :
              <FormikField.Select
                className={styles.advId}
                label={i18n.t<string>('conversionForm.labels.advertiser')}
                name='advertiserId'
                options={this.props.model.advertisers}
                simpleValue
              />
            }
          </PermissionChecker>
          <FormikField.Input
            name='name'
            label={i18n.t<string>('conversionForm.labels.name')}
            hint={conversionNameTip}
          />
          <FormikField.Select
            className={styles.conversionType}
            label={i18n.t<string>('conversionForm.labels.type')}
            name='type'
            options={this.props.model.conversionTypeOptions}
            simpleValue
          />
        </div>
        <div className={styles.buttonArea}>
          <Button type='submit' size='sm'>
            {i18n.t<string>('common.buttons.submit')}
          </Button>
          <Button variant='secondary' size='sm' onClick={this.cancelSubmit}>
            {i18n.t<string>('common.buttons.cancel')}
          </Button>
        </div>
      </Form>
    );
  }

  render () {
    const model = this.props.model;
    const state = model.state;
    if (state.redirectPath) {
      return <Redirect
        to={{
          pathname: state.redirectPath,
          state: {
            refresh: true
          }
        }}
      />;
    }
    if (state.loading) {
      return <LoadingIndicator />;
    }
    return (
      <div className={styles.conversionFormContainer}>
        <NavigationPrompt/>
        <div className={styles.title}>
          {model.title}
        </div>
        <div className={styles.titleBottomLine} />
        <Formik
          initialValues={model.conversion}
          onSubmit={this.handleSubmit}
          validate={this.validate}
          validateOnBlur={false}
        >
          {this.renderForm}
        </Formik>
      </div>
    );
  }
}
