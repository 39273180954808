import { CreativeFormData, AbstractFormContentModel, CreativeFormBasicData } from './FormContentModel';
import _ from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import i18n from 'i18next';
import { getByteLength, formatBytes } from 'utils/StringUtil';
import NativeForm from './NativeForm';
import { Creative } from 'core/creative/Creative';
import { NativeBannerPreviewModel } from 'containers/Creatives/NativeBannerPreview/NativeBannerPreviewModel';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { BasicFormProps } from './BasicFormProps';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { AdLogo } from 'core/adLogo/AdLogo';

const mimeDB = require('mime-db');

export const MAX_SPONSOR_LENGTH = 50;
export const MAX_ADTITLE_LENGTH = 50;
export const MAX_ADDESC_LENGTH = 180;

export type NativeFormProps = {
  readonly model: NativeFormModel;
} & BasicFormProps;

export class NativeFormModel extends AbstractFormContentModel {

  public needBannerUrl: boolean = true;
  sponsorRemainLength: number = MAX_SPONSOR_LENGTH;
  adTitleRemainLength: number = MAX_ADTITLE_LENGTH;
  adDescriptionRemainLength: number = MAX_ADDESC_LENGTH;
  nativeBannerPreviewModel?: NativeBannerPreviewModel;
  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel,
    public nativeToBannerTemplates: { [size: string]: { templatePath?: string, htmlContent?: string } }
  ) {
    super();
    const typeProperties = _.get(flowPageModel.state.creative, 'basic.typeProperties');
    const title = _.get(typeProperties, 'title', '');
    const desc = _.get(typeProperties, 'desc', '');
    const sponsor = _.get(typeProperties, 'sponsor', '');
    this.handleAdTitleChange(title);
    this.handleAdDescriptionChange(desc);
    this.handleSponsorChange(sponsor);
  }

  public get defaultAdLogo (): AdLogo | undefined {
    if (this.flowPageModel.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.CUSTOMIZE_ADLOGO)) {
      return this.flowPageModel.defaultAdLogo;
    }
    return undefined;
  }

  isAdLogoSupported (creative: CreativeFormBasicData): boolean {
    return !!this.defaultAdLogo && creative.enableNativeBanner === true;
  }

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getInitTypeProperties (): any {
    return undefined;
  }

  onEnableNativeBannerChange = (enable: boolean) => {
    this.flowPageModel.setEnableAdLogo(enable);
  }

  handleSponsorChange = (sponsor) => {
    this.sponsorRemainLength = MAX_SPONSOR_LENGTH - getByteLength(sponsor);
  }

  handleAdTitleChange = (adTitle) => {
    this.adTitleRemainLength = MAX_ADTITLE_LENGTH - getByteLength(adTitle);
  }

  handleAdDescriptionChange = (adDescription) => {
    this.adDescriptionRemainLength = MAX_ADDESC_LENGTH - getByteLength(adDescription);
  }

  getFormContent = (): any => {
    return NativeForm;
  }

  getFormModelData (creative: Creative): CreativeFormData {
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const typeProperties = _.omitBy({
      title: _.get(creativeValue, 'title'),
      desc: _.get(creativeValue, 'desc'),
      sponsor: _.get(creativeValue, 'sponsored.name'),
      sponsorLink: _.get(creativeValue, 'sponsored.link')
    }, _.isUndefined);
    const medias = _.omitBy({
      rectImg: _.omitBy({ url: _.get(creativeValue, 'img_l.url') }, _.isUndefined),
      squareImg: _.omitBy({ url: _.get(creativeValue, 'icon_l.url') }, _.isUndefined)
    }, _.isEmpty);
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    return {
      basic: {
        ...creativeBasic,
        typeProperties,
        medias
      },
      limitations: creative.limitations
    };
  }

  validateRemainingCharacters = (title, maxLength) => {
    const error = validateEmpty(title);
    if (error) {
      return error;
    }

    const remainingCharacters = maxLength - getByteLength(title);
    if (remainingCharacters < 0) {
      return i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: remainingCharacters });
    }
  }

  validateImage = (fileData, validWitdh, validHeight) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];
    const width = fileData.width;
    const height = fileData.height;
    if (width < validWitdh || height < validHeight) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.sizeErrorHint', { size1: `${width} x ${height}`, size2: `${validWitdh} x ${validHeight}` }));
    }

    const proportion = (width / height).toFixed(2);
    const targetProportion = (validWitdh / validHeight).toFixed(2);
    if (proportion !== targetProportion) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.proportionErrorHint', { proportion1: proportion, proportion2: targetProportion }));
    }

    if (file.size > 2097152) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }
}
