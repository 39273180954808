import React, { useCallback, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';

const defaultL1ObjectManager: L1ObjectManager = new DefaultL1ObjectManager();
export const BindingRedirect: React.FC<{
  l1ObjectId: string | number,
  campaignId: string | number,
  l1ObjectManager?: L1ObjectManager
}> = ({
  l1ObjectId,
  campaignId,
  l1ObjectManager = defaultL1ObjectManager
}) => {

  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);

  const getBindngPathInfo = useCallback(async () => {
    try {
      const orderNumber = await l1ObjectManager.getOrderNumber(l1ObjectId);
      setRedirectPath(`/orders/${orderNumber}/campaign-groups/${l1ObjectId}?campaignIds=${campaignId}&action=manage`);
    } catch (e) {
      setRedirectPath('/');
    }
  }, [l1ObjectManager, l1ObjectId, campaignId]);

  useEffect(() => {
    getBindngPathInfo();
  }, [getBindngPathInfo]);

  if (redirectPath) {
    return <Redirect to={redirectPath} />;
  }
  return (
    <LoadingIndicator />
  );
};
