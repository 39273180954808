import { L1Object } from 'core/l1Object/L1Object';
import { Order } from 'core/order/Order';
import React from 'react';
import { AdGroupSetupFlowPageModelData, TiktokAdGroupFormData } from './TiktokAdGroupSetupFlowPageModel';

export type TiktokAdGroupSetupFlowContextType = AdGroupSetupFlowPageModelData & {
  order: Order,
  l1Object: L1Object,
  adGroup: TiktokAdGroupFormData
};

export const TiktokAdGroupSetupFlowContext = React.createContext<TiktokAdGroupSetupFlowContextType | null>(null);
