import { LoadingIndicator } from 'components/common/LoadingIndicator';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { StepPage } from 'containers/StepPage/StepPage';
import i18n from 'i18n';
import { useCallback } from 'react';
import { useCustomLayoutSetupFlowPageModel } from './CustomLayoutSetupFlowPageModel';
import { Redirect } from 'react-router-dom';
import { UploadStep } from './UploadStep';
import { SetupStep } from './SetupStep';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import styles from './customLayoutSetupFlowPage.module.scss';
import { PreviewStep } from './PreviewStep';

export const CustomLayoutSetupFlowPage = () => {

  const {
    loading,
    finished,
    redirectPath,
    customLayoutSetupFlowData,
    onSubmit,
    onCancel,
    onPromptCancel,
    setCustomLayoutSetupFlowData
  } = useCustomLayoutSetupFlowPageModel();

  const renderTitle = useCallback(() => {
    return (
      <div className={styles.topArea}>
        <TitleWithBreadcrumbs
          title={i18n.t('customLayoutSetupFlowPage.titles.create')}
          routes={[
            { path: '/custom-layouts', breadcrumb: i18n.t<string>('customLayoutSetupFlowPage.breadcrumbs.home') },
            { path: '/custom-layouts/new', breadcrumb: i18n.t<string>('customLayoutSetupFlowPage.breadcrumbs.create') }
          ]}
        />
      </div>
    );
  }, []);

  const renderUploadStep = useCallback(({ goNext }) => {
    return (
      <>
        {renderTitle()}
        <div className={styles.contentContainer}>
          <UploadStep
            goNext={goNext}
            customLayoutSetupFlowData={customLayoutSetupFlowData}
            setCustomLayoutSetupFlowData={setCustomLayoutSetupFlowData}
          />
        </div>
      </>
    );
  }, [customLayoutSetupFlowData, setCustomLayoutSetupFlowData, renderTitle]);

  const renderSetupStep = useCallback(({
    goLast,
    goNext
  }) => {
    return (
      <>
        {renderTitle()}
        <div className={styles.contentContainer}>
          <SetupStep
            goLast={goLast}
            goNext={goNext}
            customLayoutSetupFlowData={customLayoutSetupFlowData}
            setCustomLayoutSetupFlowData={setCustomLayoutSetupFlowData}
          />
        </div>
      </>
    );
  }, [customLayoutSetupFlowData, setCustomLayoutSetupFlowData, renderTitle]);

  const renderPreviewStep = useCallback(({
    goLast
  }) => {
    const defaultDeviceType = customLayoutSetupFlowData.device || 'mobile';
    return (
      <PreviewStep
        goLast={goLast}
        onSubmit={onSubmit}
        defaultDeviceType={defaultDeviceType}
        customLayoutSetupFlowData={customLayoutSetupFlowData}
      />
    );
  }, [customLayoutSetupFlowData, onSubmit]);

  return (
    <div style={{ height: '100%' }}>
      {loading && <LoadingIndicator />}
      {redirectPath && <Redirect to={redirectPath}/>}
      <NavigationPrompt when={!finished} onCancel={onPromptCancel}/>
      <StepPage cancel={onCancel}>
        <StepPage.Step
          label={i18n.t<string>('customLayoutSetupFlowPage.labels.upload')}
          renderStepContent={renderUploadStep}
        />
        <StepPage.Step
          label={i18n.t<string>('customLayoutSetupFlowPage.labels.setup')}
          renderStepContent={renderSetupStep}
        />
        <StepPage.Step
          label={i18n.t<string>('customLayoutSetupFlowPage.labels.preview')}
          renderStepContent={renderPreviewStep}
        />
      </StepPage>
    </div>
  );
};
