import React from 'react';
import { RecentGroupDropdown } from 'components/RecentGroupDropdown/RecentGroupDropdown';
import { ConversionHomeProps } from './ConversionHomeModel';
import { ConversionList } from 'components/ConversionList/ConversionList';
import { SessionStorageItemKeys } from 'helper/StorageHelper';
import styles from './conversionHome.module.scss';
import i18n from 'i18next';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasRoles, notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';

export class ConversionHome extends React.Component<ConversionHomeProps> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  onAgencySelected = (agencyId?: number) => {
    this.props.model.setAgencyId && this.props.model.setAgencyId(agencyId);
  }

  onAdvertiserSelected = (advertiserId?: number) => {
    this.props.model.setAdvertiserId(advertiserId);
  }

  render () {
    const model = this.props.model;
    return (
      <div className={styles.container}>
        {model.state.loading && <LoadingIndicator/>}
        <div className={styles.titleArea}>
          {i18n.t<string>('conversionHome.labels.title')}
          <div className={styles.filterArea}>
            <PermissionChecker permissionAware={hasRoles(RoleNames.sysAdmin)}>
              <div className={styles.agencyList}>
                <div className={styles.title}>
                  {i18n.t<string>('segmentHome.labels.agencyListTitle')}
                </div>
                <RecentGroupDropdown
                  value={model.state.agencyId}
                  options={model.state.agencies}
                  storageKey={SessionStorageItemKeys.AGENCY}
                  callback={this.onAgencySelected}
                  showShowAllBtn={false}
                />
              </div>
            </PermissionChecker>
            <PermissionChecker
              permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)}
            >
              <div className={styles.advertiserList}>
                <div className={styles.title}>
                  {i18n.t<string>('conversionHome.labels.advertiserListTitle')}
                </div>
                <RecentGroupDropdown
                  value={model.state.advertiserId}
                  options={model.advertisers}
                  storageKey={SessionStorageItemKeys.ADVERTISER}
                  callback={this.onAdvertiserSelected}
                />
              </div>
            </PermissionChecker>
          </div>
        </div>
        <div className={styles.listArea}>
          <ConversionList model={this.props.model.getConversionListModel()}/>
        </div>
      </div>
    );
  }
}
