import { useCallback, useEffect, useState } from 'react';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { SelectOptions } from 'components/common/commonType';
import { Retail } from 'core/product/Product';

const defaultAdvertiserManager: AdvertiserManager = new DefaultAdvertiserManager();
const defaultRdpManager: RdpManager = new DefaultRdpManager();

export type ProductSegmentsModelData = {
  loading: boolean;
  advertisers: SelectOptions[];
  retailers: SelectOptions[];
};

export const useProductSegmentsModel = (): ProductSegmentsModelData => {
  const [advertisers, setAdvertisers] = useState<SelectOptions[]>([]);
  const [retailers, setRetailers] = useState<SelectOptions[]>([]);
  const {
    loading,
    callAPIs
  } = useCallAPI();

  const fetchAdvertiserList = useCallback(async () => {
    callAPIs(
      [defaultAdvertiserManager.getAdvertiserOptions.bind(defaultAdvertiserManager)],
      (advertisers: SelectOptions[]) => {
        setAdvertisers(advertisers);
      }
    );
  }, [callAPIs]);

  useEffect(() => {
    fetchAdvertiserList();
  }, [fetchAdvertiserList]);

  const fetchRetailerOptions = useCallback(async () => {
    callAPIs(
      [defaultRdpManager.getRetails.bind(defaultRdpManager)],
      (retailers: Retail[]) => {
        setRetailers(retailers.map(retailer => ({
          label: retailer.name,
          value: retailer.id
        })));
      }
    );
  }, [callAPIs]);

  useEffect(() => {
    fetchRetailerOptions();
  }, [fetchRetailerOptions]);

  return {
    loading,
    advertisers,
    retailers
  };
};
