import { SelectOptions } from 'components/common/commonType';
import { FbAdSetFormData } from 'containers/FbAdSets/FbAdSetSetupFlowPage/FbAdSetSetupFlowPageModel';
import { L1Object, L1ObjectObjective } from 'core/l1Object/L1Object';
import { FbAdSetWebService, RestfulFbAdSetWebService, wrapFbDayparting } from 'ws/FbAdSetWebService';
import { ADSET_DEFAULT_AGE_MAX, ADSET_DEFAULT_AGE_MIN, FbBillingEvent, FbAdSet, FbAppEvent, FbOptimizationGoal, FbPacingType } from './FbAdSet';
import _ from 'lodash';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { getPriceValue } from 'helper/CurrencyHelper';
import i18n from 'i18n';
import { Order, OrderType } from 'core/order/Order';
import { ageMaxOptions, ageMinOptions, genderOptions } from 'core/limitation/l2ObjectTAOptions';
import { fbTAInventories } from 'containers/Limitations/LimitationSetting/fbTAInventory';

export interface FbAdSetManager {
  getAdSet (adSetId: number | string): Promise<FbAdSet>;
  getAdSets (l1ObjectId: number | string): Promise<FbAdSet[]>;
  createAdSet (order: Order, group: L1Object, fbAdSetFormData: FbAdSetFormData): Promise<void>;
  editAdSet (
    orderId: string | number,
    group: L1Object,
    fbAdSetId: string | number,
    fbAdSetFormData: FbAdSetFormData
  ): Promise<void>;
  deleteAdSets (adSetIds: (number | string)[]): Promise<void>;
  updateAdSetState (adSetsData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  getMinBudget (adSetId: number | string): Promise<number>;
  getAdSetOptions (from?: string, to?: string): Promise<SelectOptions[]>;
  getDefaultBillingEvent (l1Object);
  getBilliingEventOptions (optimizationGoal);
  getDefaultOptimizationGoal (l1Object);
  getOptimizationGoalOptions (l1Object);
  getAppEventOptions (optimizationGoal);
  getDefaultDestinationType (l1Object);
  wrapTargetingForServer (
    targetingFormData: {[key: string]: {
      type: string,
      value: any
    }[]}
  ): any;
  getMinBudgetOfFBObject (
    currencyRate: number,
    bidAmount: number,
    bidStrategy: BidStrategy,
    billingEvent: FbBillingEvent,
    scheduleDateCount: number,
    order: Order,
    fbRecommendBudget?: number
  ): number;
  getCreateAdSetPayloadForServer (
    orderId: string | number,
    orderType: OrderType,
    group: L1Object,
    fbAdSetFormData: FbAdSetFormData
  ): any;
  getTASummaryData (targeting: any, showGenderAll?: boolean, showNotSupportTA?: boolean): any;
}

export const objectiveOptimizeMap = {
  [L1ObjectObjective.AWARENESS]: [
    L2ObjectOptimizationGoal.REACH,
    L2ObjectOptimizationGoal.VIDEO_VIEWS,
    L2ObjectOptimizationGoal.IMPRESSIONS
  ],
  [L1ObjectObjective.TRAFFIC]: [
    L2ObjectOptimizationGoal.CLICKS,
    L2ObjectOptimizationGoal.LANDING_PAGE_VIEWS
  ],
  [L1ObjectObjective.SALES]: [
    L2ObjectOptimizationGoal.SALES
  ],
  [L1ObjectObjective.UNSPECIFIED]: [
    L2ObjectOptimizationGoal.UNSPECIFIED
  ]
};

const optimizationGoalBillingEventMap = {
  [L2ObjectOptimizationGoal.REACH]: [
    FbBillingEvent.IMPRESSIONS
  ],
  [L2ObjectOptimizationGoal.VIDEO_VIEWS]: [
    FbBillingEvent.IMPRESSIONS,
    FbBillingEvent.THRUPLAY
  ],
  [L2ObjectOptimizationGoal.IMPRESSIONS]: [
    FbBillingEvent.IMPRESSIONS
  ],
  [L2ObjectOptimizationGoal.CLICKS]: [
    FbBillingEvent.IMPRESSIONS,
    FbBillingEvent.LINK_CLICKS
  ],
  [L2ObjectOptimizationGoal.LANDING_PAGE_VIEWS]: [
    FbBillingEvent.IMPRESSIONS
  ],
  [L2ObjectOptimizationGoal.SALES]: [
    FbBillingEvent.IMPRESSIONS
  ]
};

const fbDefaultGoals = {
  [L1ObjectObjective.AWARENESS]: L2ObjectOptimizationGoal.REACH.toString(),
  [L1ObjectObjective.TRAFFIC]: L2ObjectOptimizationGoal.CLICKS.toString(),
  [L1ObjectObjective.SALES]: L2ObjectOptimizationGoal.SALES.toString()
};
export class DefaultFbAdSetManager implements FbAdSetManager {

  constructor (
    private webService: FbAdSetWebService = new RestfulFbAdSetWebService()
  ) {
  }

  async getAdSet (adSetId: number | string): Promise<FbAdSet> {
    return this.webService.getAdSet(adSetId);
  }

  async getAdSets (l1ObjectId: number | string): Promise<FbAdSet[]> {
    return this.webService.getAdSets(l1ObjectId);
  }

  async createAdSet (order: Order, group: L1Object, fbAdSetFormData: FbAdSetFormData): Promise<void> {
    const orderId: string | number = order.id;
    const orderType: OrderType = order.orderType;
    const createFbAdSetPayload = this.getCreateAdSetPayloadForServer(orderId, orderType, group, fbAdSetFormData);
    return this.webService.createAdSet(createFbAdSetPayload);
  }

  async editAdSet (orderId: string | number, group: L1Object, fbAdSetId: string | number, fbAdSetFormData: FbAdSetFormData): Promise<void> {
    return this.webService.editAdSet(orderId, group, fbAdSetId, {
      ...fbAdSetFormData,
      optimization_goal: this.getNativeOptimizationValue(fbAdSetFormData.optimization_goal!),
      targeting: fbAdSetFormData.targeting ? this.wrapTargetingForServer(fbAdSetFormData.targeting) : undefined
    });
  }

  async deleteAdSets (adSetIds: (number | string)[]): Promise<void> {
    return this.webService.deleteAdSets(adSetIds);
  }

  async updateAdSetState (adSetsData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    return this.webService.updateAdSetState(adSetsData, state);
  }

  async getMinBudget (adSetId: number | string): Promise<number> {
    return this.webService.getMinBudget(adSetId);
  }

  async getAdSetOptions (from?: string, to?: string): Promise<SelectOptions[]> {
    return this.webService.getAdSetOptions(from, to);
  }

  getDefaultOptimizationGoal (l1Object) {
    const objective = l1Object.objective;
    return fbDefaultGoals[objective] ? fbDefaultGoals[objective] : L2ObjectOptimizationGoal.UNSPECIFIED;
  }

  getOptimizationGoalOptions (l1Object) {
    const objective = l1Object.objective;
    const optimizationGoals = objectiveOptimizeMap[objective];
    return optimizationGoals ? optimizationGoals.map(goal => ({
      label: i18n.t<string>(`optimizationGoal.${goal.toLowerCase()}`),
      value: goal
    })) : [];
  }

  getDefaultBillingEvent (l1Object) {
    const objective = l1Object.objective;
    const optimizationGoal = fbDefaultGoals[objective];
    const billingEvents = optimizationGoalBillingEventMap[optimizationGoal];
    return billingEvents && billingEvents.length > 0 ?
      billingEvents[0] : FbBillingEvent.IMPRESSIONS;
  }

  getBilliingEventOptions (optimizationGoal) {
    const billingEvents = optimizationGoalBillingEventMap[optimizationGoal];
    return billingEvents ? billingEvents.map(event => ({
      label: i18n.t<string>(`adSet.billingEvent.${event.toLowerCase()}`),
      value: event
    })) : [];
  }

  getMinBudgetOfFBObject (
    currencyRate: number,
    bidAmount: number,
    bidStrategy: BidStrategy,
    billingEvent: FbBillingEvent,
    scheduleDateCount: number,
    order: Order,
    fbRecommendBudget?: number
  ) {
    const minDailyBudgetOfAutoBidMap = {
      [FbBillingEvent.IMPRESSIONS]: 0.5 * currencyRate,
      [FbBillingEvent.LINK_CLICKS]: 2.50 * currencyRate
    };
    const minDailyBudgetOfBidCapMap = {
      [FbBillingEvent.IMPRESSIONS]: bidAmount,
      [FbBillingEvent.LINK_CLICKS]: bidAmount * 5
    };
    const minDailyBudget = bidStrategy === BidStrategy.LOWEST_COST_WITHOUT_CAP ?
      minDailyBudgetOfAutoBidMap[billingEvent] :
      Math.max(minDailyBudgetOfBidCapMap[billingEvent], minDailyBudgetOfAutoBidMap[billingEvent]);
    let minBudget = minDailyBudget * scheduleDateCount * 2;
    if (fbRecommendBudget !== undefined) {
      minBudget = Math.max(fbRecommendBudget, minBudget);
    }
    return getPriceValue(order.currency, minBudget / (1 - order.orderMargin - order.sysMargin));
  }

  getAppEventOptions (optimizationGoal) {
    if (optimizationGoal === L2ObjectOptimizationGoal.SALES) {
      return Object.keys(FbAppEvent).map(value => ({ label: _.startCase(_.lowerCase(value)), value }));
    }
    return undefined;
  }

  getDefaultDestinationType = (l1Object) => {
    const defaultDestinationType = {
      [L1ObjectObjective.AWARENESS]: '',
      [L1ObjectObjective.TRAFFIC]: '',
      [L1ObjectObjective.SALES]: ''
    };
    const objective = _.get(l1Object, 'fb.objective');
    return defaultDestinationType[objective] ? defaultDestinationType[objective] : '';
  }

  getFbGeoLocationData (geoLocationTA: any) {
    const limits = _.get(geoLocationTA, 'value');
    if (!Array.isArray(limits) || geoLocationTA.type !== 'geo_locations') {
      return {
        regions: [],
        countries: [],
        cities: []
      };
    }

    const {
      region: regions,
      country: countries,
      city: cities
    } = limits.reduce<any>((acc, option) => {
      if (['city', 'region'].includes(option.extra)) {
        acc[option.extra].push({ key: option.value });
      } else {
        acc['country'].push(option.value);
      }
      return acc;
    }, {
      region: [],
      country: [],
      city: []
    });

    return {
      cities,
      regions,
      countries
    };
  }

  wrapTargetingForServer (
    targetingFormData: {[key: string]: {
      type: string,
      value: any
    }[]}
  ) {
    const result = {};
    const includeTargeting = _.defaultTo(targetingFormData.include, []);
    const ageMin = includeTargeting.find(targeting => targeting.type === 'age_min');
    const ageMax = includeTargeting.find(targeting => targeting.type === 'age_max');
    result['age_min'] = _.get(ageMin, 'value', ADSET_DEFAULT_AGE_MIN);
    result['age_max'] = _.get(ageMax, 'value', ADSET_DEFAULT_AGE_MAX);

    const osTargeting = includeTargeting.find(targeting => targeting.type === 'user_os');
    includeTargeting.forEach(targeting => {
      this.collectIncludeTargetingForServer(targeting, osTargeting, result);
    });

    const excludeTargeting = _.defaultTo(targetingFormData.exclude, []);
    excludeTargeting.forEach(targeting => {
      this.collectExcludeTargetingForServer(targeting, result);
    });
    return result;
  }

  collectIncludeTargetingForServer = (targeting, osTargeting, result) => {
    const fbOSDeviceMap = {
      iOS: {
        Smartphone: 'iPhone',
        Tablet: 'iPad'
      },
      Android: {
        Smartphone: 'Android_Smartphone',
        Tablet: 'Android_Tablet'
      }
    };

    if (targeting.type === 'geo_locations') {
      const values = this.getFbGeoLocationData(targeting);
      result[targeting.type] = _.omitBy({
        countries: _.uniq(values.countries),
        cities: values.cities,
        regions: values.regions
      }, _.isEmpty);
    } else if (targeting.type === 'publisher_platforms') {
      result[targeting.type] = targeting.value.map(v => v.value);
    } else if (targeting.type === 'segment') {
      result['custom_audiences'] = targeting.value.map(s => ({ id: s.value }));
    } else if (targeting.type === 'user_device') {
      const deviceValues: string[] = [];
      if (osTargeting) {
        osTargeting.value.forEach(osValue => {
          targeting.value.forEach(deviceValue => {
            const device = _.get(fbOSDeviceMap, `${osValue.value}.${deviceValue.value}`, '');
            deviceValues.push(device);
          });
        });
      } else {
        targeting.value.forEach(deviceValue => {
          ['Android', 'iOS'].forEach(osValue => {
            const device = _.get(fbOSDeviceMap, `${osValue}.${deviceValue.value}`, '');
            deviceValues.push(device);
          });
        });
      }
      result[targeting.type] = _.compact(deviceValues);
    } else if (targeting.type === 'genders') {
      result[targeting.type] = targeting.value === -1 ? [] : [targeting.value];
    } else {
      result[targeting.type] = Array.isArray(targeting.value) ? targeting.value.map(value => value.value) : targeting.value;
    }
  }

  collectExcludeTargetingForServer = (targeting, result) => {
    if (targeting.type === 'geo_locations') {
      const values = this.getFbGeoLocationData(targeting);
      result['excluded_geo_locations'] = _.omitBy({
        countries: _.uniq(values.countries),
        cities: values.cities.length > 0 ? values.cities : undefined,
        regions: values.regions.length > 0 ? values.regions : undefined
      }, _.isUndefined);
    } else if (targeting.type === 'segment') {
      result['excluded_custom_audiences'] = targeting.value.map(s => ({ id: s.value }));
    } else {
      result[targeting.type] = targeting.value;
    }
  }

  getCreateAdSetPayloadForServer (orderId: string | number, orderType: OrderType, l1Object: L1Object, fbAdSetFormData: FbAdSetFormData) {
    return {
      ..._.omit(fbAdSetFormData, ['lifetime_budget', 'bid_amount', 'hasSpendLimits', 'lifetime_min_spend_target', 'lifetime_spend_cap', 'dayPart']),
      optimization_goal: this.getNativeOptimizationValue(fbAdSetFormData.optimization_goal!),
      orderId,
      l1ObjectId: l1Object.l1ObjectId,
      configured_status: 'ACTIVE',
      effective_status: 'ACTIVE',
      campaign_id: _.get(l1Object, 'fb.id'),
      l2ObjectLifetimeBudget: fbAdSetFormData.lifetime_budget === '' ? null : fbAdSetFormData.lifetime_budget,
      l2ObjectBidAmount: fbAdSetFormData.bid_amount,
      bid_strategy: l1Object.autoOptimise ? null : fbAdSetFormData.bid_strategy,
      l2ObjectLifetimeMinSpendTarget: fbAdSetFormData.lifetime_min_spend_target === '' ? 0 : fbAdSetFormData.lifetime_min_spend_target,
      l2ObjectLifetimeSpendCap: fbAdSetFormData.lifetime_spend_cap === '' ? 0 : fbAdSetFormData.lifetime_spend_cap,
      frequency_control_specs: fbAdSetFormData.frequency_control_specs ? [fbAdSetFormData.frequency_control_specs] : undefined,
      promoted_object: this.getGojeckPromotedObject(fbAdSetFormData, orderType),
      destination_type: _.defaultTo(fbAdSetFormData.destination_type, ''),
      adset_schedule: fbAdSetFormData.dayPart ? wrapFbDayparting(fbAdSetFormData) : '[]',
      pacing_type: this.getPacingTypeValue(l1Object, fbAdSetFormData),
      targeting: fbAdSetFormData.targeting ? this.wrapTargetingForServer(fbAdSetFormData.targeting) : undefined
    };
  }

  getPacingTypeValue (l1Object, fbAdSetFormData) {
    if (l1Object.autoOptimise) {
      return;
    }

    if (fbAdSetFormData.dayPart) {
      return [FbPacingType.DAY_PARTING];
    }

    return fbAdSetFormData.pacing_type ?
      [fbAdSetFormData.pacing_type] :
      [];
  }

  getGojeckPromotedObject (fbAdSetFormData: FbAdSetFormData, orderType: OrderType) {
    return fbAdSetFormData.optimization_goal === L2ObjectOptimizationGoal.SALES ? {
      application_id: '830829963645099',
      custom_event_type: _.get(fbAdSetFormData, 'promoted_object.custom_event_type', 'PURCHASE'),
      object_store_url: orderType === OrderType.GOJEK ? (_.get(fbAdSetFormData, 'targeting.user_os', [])[0] === 'Android' ?
        'http://play.google.com/store/apps/details?id=com.gojek.app' :
        'http://itunes.apple.com/app/id944875099') : ''
    } : undefined;
  }

  getNativeOptimizationValue (pmax3OptimizationGoal: L2ObjectOptimizationGoal) {
    const nativeOptimizeMap = {
      [L2ObjectOptimizationGoal.REACH]: FbOptimizationGoal.REACH,
      [L2ObjectOptimizationGoal.VIDEO_VIEWS]: FbOptimizationGoal.THRUPLAY,
      [L2ObjectOptimizationGoal.IMPRESSIONS]: FbOptimizationGoal.IMPRESSIONS,
      [L2ObjectOptimizationGoal.CLICKS]: FbOptimizationGoal.LINK_CLICKS,
      [L2ObjectOptimizationGoal.LANDING_PAGE_VIEWS]: FbOptimizationGoal.LANDING_PAGE_VIEWS,
      [L2ObjectOptimizationGoal.SALES]: FbOptimizationGoal.OFFSITE_CONVERSIONS
    };
    return nativeOptimizeMap[pmax3OptimizationGoal];
  }

  getTASummaryData (targeting, showGenderAll: boolean = true, showNotSupportTA: boolean = false) {
    const includeTA = targeting.include ? targeting.include : [];
    const ageMin = includeTA.find(limitation => limitation.type === 'age_min');
    const ageMax = includeTA.find(limitation => limitation.type === 'age_max');
    const ageMinOption = ageMinOptions.find(option => option.value === _.get(ageMin, 'value', ADSET_DEFAULT_AGE_MIN));
    const ageMaxOption = ageMaxOptions.find(option => option.value === _.get(ageMax, 'value', ADSET_DEFAULT_AGE_MAX));
    const genderOption = this.getGenderOption(includeTA, showGenderAll);

    return _.omitBy({
      general: _.compact([{
        label: i18n.t<string>('limitation.labels.age'),
        value: `${_.get(ageMinOption, 'label', ageMin)} ~ ${_.get(ageMaxOption, 'label', ageMax)}`
      }, genderOption ? {
        label: i18n.t<string>('limitation.labels.gender'),
        value: genderOption.label
      } : undefined]),
      include: this.getTASummaryOfOpt(targeting.include, showNotSupportTA),
      exclude: this.getTASummaryOfOpt(targeting.exclude, showNotSupportTA)
    }, _.isEmpty);
  }

  private getGenderOption = (includeTA: any, showGenderAll) => {
    const gender = includeTA.find(limitation => limitation.type === 'genders');
    const genderValue = _.get(gender, 'value', -1);
    return genderValue === - 1 && !showGenderAll ?
      undefined :
      genderOptions.find(option => option.value === genderValue);
  }

  private getTASummaryOfOpt = (limitationData, showNotSupportTA: boolean) => {
    if (!limitationData) {
      return undefined;
    }

    return _.compact(_.flatten(limitationData.filter(data => !['age_min', 'age_max', 'genders'].includes(data.type)).map((data) => {
      const value = _.defaultTo(data.value, []);
      if (value.length === 0) {
        return undefined;
      }

      const settingData: any = _.defaultTo(fbTAInventories[data.type], {});
      if (!showNotSupportTA && _.isEmpty(settingData)) {
        return undefined;
      }

      const taValueWithI18nFormatter = _.partial(this.taValueFormatter, settingData.i18nPrefix);
      if (data.type === 'geo_locations') {
        return this.getLocationSummary(value, taValueWithI18nFormatter);
      }
      return {
        label: settingData.title ? i18n.t<string>(settingData.title) : data.type,
        value: Array.isArray(data.value) ?
          data.value.map(taValueWithI18nFormatter).join(', ') :
          data.value
      };
    })));
  }

  private getLocationSummary = (value, taValueWithI18nFormatter) => {
    const {
      country: countries,
      city: cities,
      region: regions
    } = value.reduce((acc, limit) => {
      acc[limit.extra].push(limit);
      return acc;
    }, {
      country: [],
      city: [],
      region: []
    });

    return [
      {
        label: i18n.t<string>('adSetSetupFlow.summaryStep.countries'),
        value: countries.map(taValueWithI18nFormatter).join(', ')
      },
      {
        label: i18n.t<string>('adSetSetupFlow.summaryStep.cities'),
        value: cities.map(taValueWithI18nFormatter).join(', ')
      },
      {
        label: i18n.t<string>('adSetSetupFlow.summaryStep.regions'),
        value: regions.map(taValueWithI18nFormatter).join(', ')
      }
    ].filter(item => !_.isEmpty(item.value));
  }

  private taValueFormatter = (i18nPrefix, option) => {
    const i18nKey = `${i18nPrefix}.${option.value.toString().toLowerCase().replace(/-|\s/g, '_')}`;
    if (i18nPrefix && i18n.exists(i18nKey)) {
      return i18n.t<string>(i18nKey);
    }
    return option.value.toString();
  }
}
