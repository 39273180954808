import client from './RestClient';
import _ from 'lodash';
import { OutdoorBkData, OutdoorBkRecord } from 'core/outdoorBK/OutdoorBkRecord';
import { MOMENT_TIMEZONE } from 'core';
import moment from 'moment-timezone';
import { AxiosInstance } from 'axios';

export interface OutdoorBkWebService {
  getOutdoorBkData (agencyId: string | number, unit: string, from: string, to: string, timeZone: string): Promise<OutdoorBkData>;
}

function wrapOutdoorData (json): OutdoorBkData {
  return {
    total: _.omit(_.get(json, 'total', {}), ['id', 'name', 'type']),
    remaining: _.omit(_.get(json, 'remaining', {}), ['id', 'name', 'type']),
    orders: _.get(json, 'orders', []).map(wrapOutdoorRecord)
  };
}

function wrapOutdoorRecord (json): OutdoorBkRecord {
  return {
    ...json,
    id: _.get(json, 'id'),
    name: _.get(json, 'name')
  };
}

export class RestfulOutdoorBkWebService implements OutdoorBkWebService {

  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  getDateWithTimezone (date, timeZone: string) {
    return moment.tz(date, MOMENT_TIMEZONE[timeZone]).format();
  }

  async getOutdoorBkData (agencyId: string | number, unit: string, from: string, to: string, timeZone: string): Promise<OutdoorBkData> {
    const response = await this.restClient.get(`/v2/outdoorBk?agencyID=${agencyId}&unit=${unit}&from=${encodeURIComponent(this.getDateWithTimezone(from, timeZone))}&to=${encodeURIComponent(this.getDateWithTimezone(to, timeZone))}`);
    return wrapOutdoorData(response.data);
  }
}
