import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { PilotTVFormProps } from './PilotTVFormModel';
import { FormConfig } from 'components/common/form/FormConfig';
import { FormContent } from 'components/common/form/Form';

export const PilotTVForm: React.FunctionComponent<PilotTVFormProps & { formik: FormikContextType<any> }> = props => {

  const [formConfig, setFormConfig] = useState<FormConfig>(props.getInitFormConfig());

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addFormikFileInput({
              type: 'video',
              label: i18n.t<string>('videoForm.labels.tenMaxVideoInput'),
              name: 'medias.fileVideo',
              maxWidth: 300,
              hints: [
                i18n.t<string>('creativeSetupFlow.labels.videoTypeHint'),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '10MB' }),
                i18n.t<string>('creativeSetupFlow.labels.videoDurationHint')
              ],
              validate: props.model.validateVideo
            })
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      ).build()
    );
  }, [
    props.basicFields,
    props.model.validateVideo
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(PilotTVForm);
