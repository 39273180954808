import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { useCallback, useEffect, useState } from 'react';
import formatters from './listFormatters';
import styles from './storedValueList.module.scss';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { useCallAPI } from 'hooks/useCallAPI';
import { DefaultStoredValueManager } from 'core/storedValue/StoredValueManager';
import { StoredValueLog, StoredValueSummary } from 'core/storedValue/StoredValueLog';
import { AgencyDetail, DefaultAgencyManager } from 'core';

export enum StoredValueListColumns {
  ID = 'adAgencyId',
  USED = 'used',
  TOTAL = 'stored',
  REMAIN = 'remain'
}

export type StoredValueListState = {
  readonly searchString: string;
  readonly filteredList: StoredValueSummary[];
  readonly agencies: StoredValueSummary[];
};

const defaultAgencyManager = new DefaultAgencyManager();
const defaultStoredValueManager = new DefaultStoredValueManager();

export const useStoredValueListModel = (
  storedValueManager = defaultStoredValueManager,
  agencyManager = defaultAgencyManager
) => {

  const [state, setState] = useState({
    searchString: '',
    agencies: [],
    filteredList: []
  } as StoredValueListState);

  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    const filteredList = state.agencies.filter(agency => {
      const nameMatch = agency.adAgencyName.toLowerCase().includes(state.searchString.toLowerCase());
      const idMatch = agency.adAgencyId.toString().includes(state.searchString);
      return nameMatch || idMatch;
    });
    setState(prevState => ({ ...prevState, filteredList }));
  }, [state.searchString, state.agencies]);

  const initData = useCallback(async () => {
    callAPIs([
      async () => storedValueManager.getStoredValueLogs(),
      async () => agencyManager.getAgencies('company.storedValue')
    ], (storedValueLogs: StoredValueLog[], agencies: AgencyDetail[]) => {
      const storedValueSummary = storedValueManager.getStoredValueSummary(storedValueLogs);
      const agencyWithEmptyStoredValue = agencies.filter(agency => !storedValueSummary.find(summary => summary.adAgencyId === agency.agencyId));
      const emptyStoredValueSummary = agencyWithEmptyStoredValue.map(agency => ({
        adAgencyId: agency.agencyId,
        adAgencyName: agency.companyName,
        currency: agency.currency,
        stored: 0,
        used: 0,
        remain: 0,
        logs: []
      })) as StoredValueSummary[];
      const allSummary = [
        ...storedValueSummary,
        ...emptyStoredValueSummary
      ];
      setState(prevState => ({
        ...prevState,
        agencies: allSummary,
        filteredList: allSummary
      }));
    });
  }, [storedValueManager, agencyManager, callAPIs]);

  useEffect(() => {
    initData();
  }, [initData]);

  const onHandleSearch = (searchString: string) => setState({ ...state, searchString });

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `storedValueList.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const priceFormatter = (value, rowData: StoredValueSummary) => formatPriceWithCurrency(rowData.currency, value ? value : 0);
  const columns = [
    renderColumn(columnDefinition(StoredValueListColumns.ID), formatters.nameFormatter),
    renderColumn(columnDefinition(StoredValueListColumns.USED), priceFormatter),
    renderColumn(columnDefinition(StoredValueListColumns.TOTAL), priceFormatter),
    renderColumn(columnDefinition(StoredValueListColumns.REMAIN), priceFormatter)
  ];

  return {
    state,
    columns,
    loading,
    onHandleSearch
  };
};
