import { FormikField } from 'components/common/form/field/FormikField';
import { FormikProps } from 'formik';
import { validateEmpty } from 'utils/ValidateUtils';
import i18n from 'i18n';
import _ from 'lodash';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { toast } from 'react-toastify';

type FormFieldsConfig = {
  fieldComponent: any;
  fieldProps: any;
};

abstract class ProductSetFormModel {

  constructor (
    private advertiserOptions,
    private onAdvertiserChange: (advertiser) => void,
    protected rdpManager: RdpManager = new DefaultRdpManager()
  ) {}

  abstract get submitBtnLabel ();
  abstract get initValue ();

  abstract submit (values, agency, retailId, selectedProducts);
  abstract validate (values);
  abstract ruleChanged (values, selectedProducts): boolean;
  abstract getProductSetUsageData (productSet: number): Promise<any[]>;

  getFormFieldsConfig (formikProps: FormikProps<any>): FormFieldsConfig[] {
    const onAdvertiserChange = (advertiser) => {
      this.onAdvertiserChange(advertiser);
      formikProps.setFieldValue('productSet', undefined);
    };
    return [
      {
        fieldComponent: FormikField.Select,
        fieldProps: {
          name: 'advertiser',
          label: i18n.t<string>('productSetCreateModal.labels.advertiser'),
          simpleValue: true,
          options: this.advertiserOptions,
          onChange: onAdvertiserChange
        }
      }
    ];
  }
}

export class NewProductSetFormModel extends ProductSetFormModel {

  get submitBtnLabel () {
    return i18n.t<string>('productSetCreateModal.buttons.new');
  }

  get initValue () {
    return {
      advertiser: undefined,
      name: ''
    };
  }

  async getProductSetUsageData () { return []; }

  ruleChanged = (): boolean => {
    return true;
  }

  submit = async (values, agency, retailId, selectedProducts) => {
    console.log('create product set with params', values, selectedProducts.map(product => product.value));
    const productSet = await this.rdpManager.createProductSet(retailId, {
      agency,
      advertiser: values.advertiser,
      name: values.name,
      rule: {
        include: selectedProducts.map(product => product.value)
      }
    });
    console.log('new product set', productSet);
    toast.success(i18n.t<string>('productSetCreateModal.labels.createSuccess'));
  }

  validate = (values) => {
    return _.omitBy({
      advertiser: validateEmpty(values.advertiser),
      name: validateEmpty(values.name)
    }, _.isUndefined);
  }

  getFormFieldsConfig (formikProps: FormikProps<any>): FormFieldsConfig[] {
    const baseFormFields = super.getFormFieldsConfig(formikProps);
    return [
      ...baseFormFields,
      {
        fieldComponent: FormikField.Input,
        fieldProps: {
          name: 'name',
          label: i18n.t<string>('productSetCreateModal.labels.name')
        }
      }
    ];
  }
}

export class AddToExistProductSetFormModel extends ProductSetFormModel {

  constructor (
    advertiserOptions,
    private productSetOptions,
    fetchProductSetsOfAdvertiser
  ) {
    super(advertiserOptions, fetchProductSetsOfAdvertiser);
  }

  get submitBtnLabel () {
    return i18n.t<string>('productSetCreateModal.buttons.addTo');
  }

  get initValue () {
    return {
      advertiser: undefined,
      productSet: undefined
    };
  }

  ruleChanged = (values, selectedProducts): boolean => {
    const selectedProductSet = this.productSetOptions.filter(product => product.value === values.productSet)[0];
    if (!selectedProductSet) {
      return true;
    }
    const newIds = selectedProducts.map(product => product.value);
    const oldIds = selectedProductSet.extra.include;
    if (_.difference(newIds, oldIds).length === 0) {
      return false;
    }
    return true;
  }

  async getProductSetUsageData (productSet: number) {
    return this.rdpManager.getProductSetUsageInfo(productSet);
  }

  submit = async (values, agency, retailId, selectedProducts) => {
    console.log(`add to product set ${values.productSet} with params`, values, selectedProducts.map(product => product.value));
    const payload = {
      id: values.productSet,
      agency,
      advertiser: values.advertiser,
      name: this.productSetOptions.filter(product => product.value === values.productSet)[0].label,
      rule: {
        include: selectedProducts.map(product => product.value)
      }
    };
    const productSet = await this.rdpManager.updateProductSetAdd(retailId, payload);
    console.log('updated product set', productSet);
    toast.success(i18n.t<string>('productSetCreateModal.labels.updateSuccess'));
  }

  validate = (values) => {
    return _.omitBy({
      advertiser: validateEmpty(values.advertiser),
      productSet: validateEmpty(values.productSet)
    }, _.isUndefined);
  }

  getFormFieldsConfig (formikProps: FormikProps<any>): FormFieldsConfig[] {
    const baseFormFields = super.getFormFieldsConfig(formikProps);
    return [
      ...baseFormFields,
      {
        fieldComponent: FormikField.Select,
        fieldProps: {
          name: 'productSet',
          label: i18n.t<string>('productSetCreateModal.labels.productSet'),
          simpleValue: true,
          options: this.productSetOptions
        }
      }
    ];
  }
}
