import React from 'react';
import { NavLink } from 'react-router-dom';
import useBreadcrumbs, { BreadcrumbsRoute } from 'use-react-router-breadcrumbs';
import styles from './breadcrumbs.module.scss';

export const Breadcrumbs: React.FC<{ routes: BreadcrumbsRoute[]}> = ({
  routes
}) => {
  const breadcrumbs = useBreadcrumbs(routes, { disableDefaults: true, excludePaths: ['/'] });

  return (
    <div className={styles.breadcrumbs}>
      {breadcrumbs.map(({
        match,
        breadcrumb
      }, index) => (
        <span key={match.url}>
          <NavLink to={match.url}>{breadcrumb}</NavLink>
          <span className={styles.slash}>
            {index !== breadcrumbs.length - 1 && '/'}
          </span>
        </span>
      ))}
    </div>
  );
};
