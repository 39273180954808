import React from 'react';
import { CreativeSetupFlowProps, CreativeSetupFlowState } from './CreativeSetupFlowPageModel';
import { RouteComponentProps, Redirect } from 'react-router-dom';
import styles from './creativeSetupFlowPage.module.scss';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import i18n from 'i18next';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { CreativeSetupFlowDataContext } from './CreativeSetupFlowDataContext';
import { StepPage } from 'containers/StepPage/StepPage';
import { ChooseAdvertiserStep } from './FlowSteps/ChooseAdvertiserStep';
import { DefaultChooseAdvertiserStepModel } from './FlowSteps/ChooseAdvertiserStepModel';
import { CreativeSetUpStep } from './FlowSteps/CreativeSetupStep';
import { CreativeSummaryStep } from './FlowSteps/CreativeSummaryStep';
import { AppFooter } from 'components/AppFooter';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import _ from 'lodash';

export class CreativeSetupFlowPage extends React.Component<RouteComponentProps<{}> & CreativeSetupFlowProps, CreativeSetupFlowState> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  onPromptCancel = () => {
    this.props.model.setRedirectData(undefined);
  }

  renderTitle = () => {
    return (
      <div className={styles.topArea}>
        <TitleWithBreadcrumbs
          title={this.props.model.type === 'edit' ?
            i18n.t<string>('creativeSetupFlow.labels.editCreative') :
            i18n.t<string>('creativeSetupFlow.labels.createCreative')}
          routes={[
            { path: '/creatives', breadcrumb: i18n.t<string>('creativeSetupFlow.labels.creatives') },
            { path: '/creatives/new', breadcrumb: i18n.t<string>('creativeSetupFlow.labels.createCreative') },
            {
              path: '/creatives/:creativeId/edit',
              breadcrumb: DynamicBreadcrumb,
              props: {
                prefix: i18n.t<string>('common.labels.edit'),
                label: _.get(this.props.model.state.creative, 'basic.name'),
                matchParam: 'creativeId'
              }
            }
          ]}
        />
      </div>
    );
  }

  renderAdvertiserStep = ({
    goNext,
    registerValidateMethod
  }) => {
    const goNextAndGetFbPageOptions = (advertiserId) => {
      goNext(async () => {
        advertiserId && await this.props.model.setUpAdvertiserData(advertiserId, true, true);
      });
    };
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <ChooseAdvertiserStep model={new DefaultChooseAdvertiserStepModel(goNextAndGetFbPageOptions, registerValidateMethod)} />
        </div>
      </>
    );
  }

  renderMainStep = ({
    subStepIndex,
    goSubStep,
    goNext,
    goLast,
    registerValidateMethod
  }) => {
    const creative = this.props.model.state.creative;
    if (!creative) {
      return <div />;
    }
    const hasChooseAdvertiserStep = this.props.model.type === 'create' && this.props.model.canChooseAdvertiser;
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <CreativeSetUpStep
            model={this.props.model.getCreativeSetupStepModel(
              creative.basic.creativeType,
              this.props.model.type,
              subStepIndex,
              goSubStep,
              hasChooseAdvertiserStep ? goLast : undefined,
              goNext,
              this.props.model.addonFeatureManager,
              registerValidateMethod
            )}
          />
        </div>
      </>
    );
  }

  renderSummaryStep = ({
    goLast,
    goStep
  }) => {
    return (
      <>
        {this.renderTitle()}
        <div className={styles.contentContainer}>
          <CreativeSummaryStep model={this.props.model.getCreativeSummaryModel(goLast, goStep)} />
        </div>
      </>
    );
  }

  render () {
    const model = this.props.model;
    const redirectData = model.state.redirectData;
    if (!model.state.creative) {
      return <LoadingIndicator />;
    }
    const hasChooseAdvertiserStep = this.props.model.type === 'create' && this.props.model.canChooseAdvertiser;
    return (
      <div className={styles.creativeSetupFlowPage}>
        {model.state.loading && <LoadingIndicator />}
        <CreativeSetupFlowDataContext.Provider value={model.dataContenxt}>
          <NavigationPrompt when={!model.state.finished} onCancel={this.onPromptCancel} />
          <StepPage cancel={this.props.model.cancel}>
            {hasChooseAdvertiserStep &&
              <StepPage.Step
                label={i18n.t<string>('stepSideBar.labels.creativeChooseAdvertiser')}
                renderStepContent={this.renderAdvertiserStep}
              />
            }
            <StepPage.Step
              label={i18n.t<string>('stepSideBar.labels.creativeSetUp')}
              renderStepContent={this.renderMainStep}
            >
              <StepPage.SubStep label={i18n.t<string>('stepSideBar.labels.basic')} />
              {this.props.model.needSetupLimitation && <StepPage.SubStep label={i18n.t<string>('stepSideBar.labels.targeting')} />}
              {this.props.model.state.enableAdLogo && <StepPage.SubStep label={i18n.t<string>('stepSideBar.labels.adLogo')}/>}
            </StepPage.Step>
            <StepPage.Step
              label={i18n.t<string>('stepSideBar.labels.creativeSummary')}
              renderStepContent={this.renderSummaryStep}
            />
            <StepPage.SideBarFooter>
              <AppFooter/>
            </StepPage.SideBarFooter>
          </StepPage>
          {redirectData &&
            <Redirect
              to={redirectData}
            />
          }
        </CreativeSetupFlowDataContext.Provider>
      </div>
    );
  }
}
