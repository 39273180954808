import React from 'react';
import { PPSCreativeDetail } from 'components/CreativeDetail/PPSCreativeDetail';

type mediaSummaryData = {
  htmlSnippet: string
};

export const PPSMediaSummary: React.FunctionComponent<{ mediaSummary: mediaSummaryData }> = props => {
  return <PPSCreativeDetail creative={{ creativeValues: props.mediaSummary }} />;
};
