import React from 'react';
import _ from 'lodash';
import { connect } from 'formik';
import { DailyBudgetPlan, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import RtbCampaignInfo from 'components/RtbCampaignBasicForm/RtbCampaignInfo';
import moment from 'moment';
import CoreContext from 'contexts/coreContext';
import RtbCampaignOptimize from 'components/RtbCampaignBasicForm/RtbCampaignOptimize';
import { getFieldErrors } from 'utils/FormikUtils';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { BidStrategy } from 'core/l2Object/L2Object';

export class RtbCampaignBasicForm extends React.Component<any> {
  handler: number;
  constructor (props) {
    super(props);
    this.handleChangeDailyBudgetPlan = this.handleChangeDailyBudgetPlan.bind(
      this
    );
    this.handleGetBidFloor = this.handleGetBidFloor.bind(
      this
    );
    this.handleGetRemainBudget = this.handleGetRemainBudget.bind(this);
    this.handleGetCampaignTotalDay = this.handleGetCampaignTotalDay.bind(this);
    this.handleGetDailyBudgetState = this.handleGetDailyBudgetState.bind(this);
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state, () => {
        const { dailyBudgetType } = this.props.model.state;
        if (dailyBudgetType === _.get(this.props, 'formik.values.dailyBudgetType')) {
          return;
        }
        let dailyTargetBudget: any = undefined;
        if (dailyBudgetType === DailyBudgetPlan.DAILY.toString()) {
          dailyTargetBudget = '0';
        }

        this.props.formik.setFieldValue('dailyTargetBudget', dailyTargetBudget);
      });
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentDidMount () {
    let dailyTargetBudget = _.get(this.props, 'formik.values.dailyTargetBudget');
    if (dailyTargetBudget === null) {
      dailyTargetBudget = undefined;
    }
    // wait for formik didMount flag set to true
    setTimeout(() => {
      this.props.formik.setFieldValue('dailyTargetBudget', dailyTargetBudget);
    }, 0);
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleChangeDayPartSwitch = (dayPartEnable: boolean) => {
    if (!dayPartEnable) {
      this.props.formik.setFieldValue('dayPart', undefined);
    }
  }

  handleOptimizeChanged = (newOptimize) => {
    if (this.props.model.showFrequencyControl(newOptimize)) {
      this.props.formik.setFieldValue('frequency', {
        intervalDays: 1,
        maxFrequency: 5
      });
    } else {
      this.props.formik.setFieldValue('frequency', undefined);
    }
  }

  handleChangeDailyBudgetPlan = (newBudgetPlan) => {
    this.props.model.changeDailyBudgetOptions(newBudgetPlan);
  }

  handleChangeSchedule = (startDate, endDate) => {
    const l1Object = this.props.model.l1Object;
    const order = this.props.model.order;
    if (l1Object && l1Object.autoOptimise && this.props.model.type === 'create') {
      const scheduleDateCount = moment(endDate).diff(moment(startDate), 'days') + 1;
      this.props.formik.setFieldValue('budget', order.campaignConstraint.budgetMinimum * scheduleDateCount);
    }
  }

  handleChangePriceModel = (model) => {
    this.props.model.setCurrentPriceModel(model);
    this.props.model.onPriceModelChangeCallback(model, this.props.formik.values);
  }

  handleGetBidFloor () {
    const optimize = _.get(this.props, 'formik.values.optimize');
    return this.props.model.getBidFloor(optimize);
  }

  handleGetRemainBudget () {
    const campaignBudget = _.get(this.props, 'formik.values.budget', '');
    return this.props.model.getRemainBudget(campaignBudget);
  }

  handleGetCampaignTotalDay () {
    const startDate = _.get(this.props, 'formik.values.startDate');
    const endDate = _.get(this.props, 'formik.values.endDate');

    return this.props.model.getCampaignTotalDay(startDate, endDate);
  }

  handleGetDailyBudgetState () {
    const dailyBudget = _.get(
      this.props,
      'formik.values.dailyTargetBudget'
    );
    const budget = _.get(this.props, 'formik.values.budget');
    const campaignTotalDay = this.handleGetCampaignTotalDay();
    return this.props.model.getDailyBudgetState(
      +budget,
      +dailyBudget,
      campaignTotalDay
    );
  }

  handleChagneOrderPrice = (orderPrice) => {
    const formikValue = _.get(this.props, 'formik.values');
    const priceModel = _.get(formikValue, 'priceModel');
    const optimize = _.get(formikValue, 'optimize');
    const bidStrategy = _.get(formikValue, 'bidStrategy');
    if (bidStrategy === BidStrategy.LOWEST_COST_WITHOUT_CAP) {
      return;
    }
    const rtbCampaignManager = new DefaultRtbCampaignManager();
    const optimizeSameAsPriceModel = rtbCampaignManager.checkOptimizeSameAsPriceModel(priceModel, optimize);
    const bidCapEqualOrderPrice = !this.props.model.optimizeAddonEnable && optimizeSameAsPriceModel;
    bidCapEqualOrderPrice && this.props.formik.setFieldValue('bidPrice', orderPrice);
  }

  render () {
    const {
      order,
      actionType,
      l1Object,
      priceModelOptions,
      optimizeOptions,
      campaignDeliverTypeOptions,
      creativeDeliverTypeOptions,
      canEditOptimize,
      orderName,
      agencyProfit,
      currency,
      minDate,
      maxDate,
      canEditPriceModel,
      canEditBudgetPlan,
      canUseDailyBudget,
      videoAdMetricEventOptions,
      canEditVideoViewObjective,
      showVideoProgressRadio,
      campaignAdType,
      campaignBasic: defaultCampaign,
      showOptimizeSection,
      retailerOptions,
      showFrequencyControl,
      optimizeAddonEnable,
      getBidFloor,
      needVideoAdViewObjective
    } = this.props.model;

    const { dailyBudgetType } = this.props.model.state;
    const formikValue = _.get(this.props, 'formik.values');

    this.props.model.setFormikValue(formikValue);
    const dayPartEnable = _.get(
      formikValue,
      'dayPart.enabled',
      0
    );
    const priceModel = _.get(formikValue, 'priceModel');
    const optimize = _.get(formikValue, 'optimize');
    const dailyBudget = _.get(
      formikValue,
      'dailyTargetBudget'
    );
    const budget = _.get(formikValue, 'budget');
    const isCPVModel = priceModel === RtbCampaignPlanType.FCPV;
    const isCPMModel = priceModel === RtbCampaignPlanType.FCPM;
    const isCPCModel = priceModel === RtbCampaignPlanType.FCPC;

    const offset = _.get(
      formikValue,
      'videoAdViewObjective.offset'
    );
    const videoAdMetric = _.get(
      formikValue,
      'videoAdViewObjective.videoAdEvent'
    );
    const videoAdMetricEvent = _.get(
      formikValue,
      'videoAdViewObjective.videoAdMetricEvent'
    );
    const orderPrice = _.get(formikValue, 'orderPrice');
    const startDate = _.get(formikValue, 'startDate');
    const endDate = _.get(formikValue, 'endDate');
    const isNewCampaign = _.get(formikValue, 'id') === undefined || _.get(formikValue, 'isDraft', false);
    const isCampaignStart = !isNewCampaign && moment(defaultCampaign.startDate).isBefore(moment());
    // const isOutOfOutdoorBk = _.get(formikValue, 'isOutOfOutdoorBk', false);
    const tags = _.get(formikValue, 'tags', []);
    const dailyBudgetState = this.handleGetDailyBudgetState();
    const remainBudget = this.handleGetRemainBudget();
    const showOrderPriceHint = !getFieldErrors(this.props.formik, 'orderPrice');
    const showBudgetHint = !getFieldErrors(this.props.formik, 'budget');
    const bidFloor = getBidFloor(optimize);
    const orderPriceMinimum = optimizeAddonEnable ? getBidFloor(priceModel) : bidFloor;
    const showVideoViewObjective = needVideoAdViewObjective(priceModel);
    return (
      <CoreContext.Consumer>
        {(coreContext) => {
          const renderCampaignInfo = () => {
            return (
              <RtbCampaignInfo
                formType={actionType}
                autoOptimise={l1Object.autoOptimise}
                editL1ObjectPath={`/orders/${order.orderNumber}/campaign-groups/${l1Object.l1ObjectId}/edit`}
                errors={this.props.formik.errors}
                orderName={orderName}
                startDate={startDate}
                endDate={endDate}
                minDate={minDate}
                maxDate={maxDate}
                isCampaignStart={isCampaignStart}
                canEditPriceModel={canEditPriceModel}
                priceModelOptions={priceModelOptions}
                campaignDeliverTypeOptions={campaignDeliverTypeOptions}
                isCPCModel={isCPCModel}
                isCPMModel={isCPMModel}
                isCPVModel={isCPVModel}
                retailerOptions={retailerOptions}
                orderPrice={orderPrice}
                showOrderPriceHint={showOrderPriceHint}
                agencyProfit={agencyProfit}
                currency={currency}
                priceModel={priceModel}
                canUseDailyBudget={canUseDailyBudget}
                canEditBudgetPlan={canEditBudgetPlan}
                dailyBudgetType={dailyBudgetType}
                onChangeDailyBudgetPlan={this.handleChangeDailyBudgetPlan}
                onChangePriceModel={this.handleChangePriceModel}
                onChangeSchedule={this.handleChangeSchedule}
                onChangeOrderPrice={this.handleChagneOrderPrice}
                videoAdMetricEventOptions={videoAdMetricEventOptions}
                dailyBudget={dailyBudget}
                budget={budget}
                videoAdMetric={videoAdMetric}
                videoAdMetricEvent={videoAdMetricEvent}
                offset={offset}
                dailyBudgetState={dailyBudgetState}
                remainBudget={remainBudget}
                orderPriceMinimum={orderPriceMinimum}
                showBudgetHint={showBudgetHint}
                showTagsPlaceholder={tags.length === 0}
                canEditVideoViewObjective={canEditVideoViewObjective}
                showVideoProgressRadio={showVideoProgressRadio}
                campaignAdType={campaignAdType}
                showOrderPriceField={_.get(formikValue, 'orderPriceEnable')}
                showVideoViewObjective={showVideoViewObjective}
              />
            );
          };
          const renderCampaignOptimize = () => {

            if (!showOptimizeSection) {
              return undefined;
            }

            return (
              <RtbCampaignOptimize
                formType={actionType}
                campaignAdType={campaignAdType}
                l1Object={l1Object}
                currentL2Object={formikValue}
                optimizeOptions={optimizeOptions}
                currency={currency}
                canEditOptimize={canEditOptimize(priceModel)}
                optimizeAddonEnable={optimizeAddonEnable}
                onOptimizeChanged={this.handleOptimizeChanged}
                dayPartEnable={dayPartEnable}
                onDaypartSwitchChanged={this.handleChangeDayPartSwitch}
                bidFloor={bidFloor}
                creativeDeliverTypeOptions={creativeDeliverTypeOptions}
                showFrequencyControl={showFrequencyControl(optimize)}
              />
            );
          };
          return (
            <div>
              {renderCampaignInfo()}
              {renderCampaignOptimize()}
            </div>
          );
        }}
      </CoreContext.Consumer>
    );
  }
}

export default connect(RtbCampaignBasicForm);
