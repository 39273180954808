import { ColumnDefinition, getNameIdColumn, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { useCallback, useEffect, useState } from 'react';
import { useCallAPI } from 'hooks/useCallAPI';
import { MessageSegmentListRecord } from 'core/messageSegment/MessageSegment';
import { DefaultMessageSegmentManager, MessageSegmentManager } from 'core/messageSegment/MessageSegmentManager';

export enum MessageSegmentListColumns {
  ID = 'id',
  DESCRIPTION = 'description'
}

const defaultMessageSegmentManager: MessageSegmentManager = new DefaultMessageSegmentManager();

export const useMessageSegmentListModel = (
  isAdmin: boolean,
  agencyId: number | string | undefined,
  advertiserId: number | string | undefined,
  messageSegmentManager: MessageSegmentManager = defaultMessageSegmentManager
) => {

  const [messageSegments, setMessageSegments] = useState<MessageSegmentListRecord[]>([]);
  const [filteredList, setFilteredList] = useState<MessageSegmentListRecord[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const { loading, callAPIs } = useCallAPI();

  const fetchMessageSegmentData = useCallback(async () => {
    if (!isAdmin || (isAdmin && agencyId)) {
      callAPIs([
        () => messageSegmentManager.getMessageSegments(agencyId!)
      ], messageSegments => {
        setMessageSegments(messageSegments);
      });
    } else {
      setMessageSegments([]);
    }
  }, [agencyId, messageSegmentManager, isAdmin, callAPIs]);

  useEffect(() => {
    fetchMessageSegmentData();
  }, [fetchMessageSegmentData]);

  useEffect(() => {
    const filteredList = messageSegments.filter(messageSegment => {
      const nameInclude = messageSegment.name.toLowerCase().includes(searchString.toLowerCase());
      const idInclude = messageSegment.id.toString().includes(searchString);
      const advertiserMatch = messageSegment.advertiserId === advertiserId || advertiserId === undefined;
      return (nameInclude || idInclude) && advertiserMatch;
    });
    setFilteredList(filteredList);
  }, [advertiserId, searchString, messageSegments]);

  const columnDefinition = (columnName): ColumnDefinition => (
    sortableColumn(columnName, `messageSegmentList.headers.${columnName}`, true)
  );

  const columns = [
    getNameIdColumn(columnDefinition(MessageSegmentListColumns.ID), segment => `/message-segments/${segment.id}/edit`),
    renderColumn(columnDefinition(MessageSegmentListColumns.DESCRIPTION))
  ];

  return {
    loading,
    columns,
    filteredList,
    searchString,
    setSearchString
  };
};
