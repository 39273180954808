import { createSelectOptions } from 'utils/SelectOptionsUtils';
import i18n from 'i18n';

const generateAges = (range: number, ageMin: number): number[] => {
  return Array.from({ length: range }, (_, index) => index + ageMin);
};

const ages = generateAges(53, 13);
const tiktokAges = generateAges(43, 13);

export const ageMinOptions = createSelectOptions(ages);
export const ageMaxOptions = [...ageMinOptions.filter(option => option.value !== 65), {
  label: '65+',
  value: 65
}];

export const tiktokAgeMinOptions = createSelectOptions(tiktokAges);
export const tiktokAgeMaxOptions = [...tiktokAgeMinOptions.filter(option => option.value !== 55), {
  label: '55+',
  value: 55
}];

export const genderOptions = [{
  label: i18n.t<string>('limitation.genders.unlimited'),
  value: -1
},{
  label: i18n.t<string>('limitation.genders.male'),
  value: 1
}, {
  label: i18n.t<string>('limitation.genders.female'),
  value: 2
}];

// For PMax3.0 TenMax -> RtbCampaign
export const pmax2RtbCampaignAgeGroups = [
  { value: '1', label: '0~17' },
  { value: '2', label: '18~24' },
  { value: '3', label: '25~34' },
  { value: '4', label: '35~44' },
  { value: '5', label: '45~54' },
  { value: '6', label: '55~64' },
  { value: '7', label: '65+' }
];

// For PMax3.0 non-TenMax -> RtbCampaign
export const pmax3RtbCampaignAgeGroups = [
  { value: '1', label: '0~18' },
  { value: '2', label: '19~22' },
  { value: '3', label: '23~26' },
  { value: '4', label: '27~35' },
  { value: '5', label: '36~45' },
  { value: '6', label: '46~60' },
  { value: '7', label: '61+' }
];

export const tiktokCampaignAgeGroups = [
  { value: '1', label: '13~17' },
  { value: '2', label: '18~24' },
  { value: '3', label: '25~34' },
  { value: '4', label: '35~44' },
  { value: '5', label: '45~54' },
  { value: '6', label: '55+' }
];

const pmax2RtbGroupRangeMap = (function () {
  const results = {};
  for (let i = 0; i < ages.length; ++i) {
    if (ages[i] <= 17) {
      results[ages[i]] = '1';
    } else if (ages[i] <= 24) {
      results[ages[i]] = '2';
    } else if (ages[i] <= 34) {
      results[ages[i]] = '3';
    } else if (ages[i] <= 44) {
      results[ages[i]] = '4';
    } else if (ages[i] <= 54) {
      results[ages[i]] = '5';
    } else if (ages[i] <= 64) {
      results[ages[i]] = '6';
    } else {
      results[ages[i]] = '7';
    }
  }
  return results;
}());

const pmax3RtbGroupRangeMap = (function () {
  const results = {};
  for (let i = 0; i < ages.length; ++i) {
    if (ages[i] <= 18) {
      results[ages[i]] = '1';
    } else if (ages[i] <= 22) {
      results[ages[i]] = '2';
    } else if (ages[i] <= 26) {
      results[ages[i]] = '3';
    } else if (ages[i] <= 35) {
      results[ages[i]] = '4';
    } else if (ages[i] <= 45) {
      results[ages[i]] = '5';
    } else if (ages[i] <= 60) {
      results[ages[i]] = '6';
    } else {
      results[ages[i]] = '7';
    }
  }
  return results;
}());

const tiktokGroupRangeMap = (function (ages) {
  const results = {};
  for (let i = 0; i < ages.length; ++i) {
    if (ages[i] <= 17) {
      results[ages[i]] = '1';
    } else if (ages[i] <= 24) {
      results[ages[i]] = '2';
    } else if (ages[i] <= 34) {
      results[ages[i]] = '3';
    } else if (ages[i] <= 44) {
      results[ages[i]] = '4';
    } else if (ages[i] <= 54) {
      results[ages[i]] = '5';
    } else {
      results[ages[i]] = '6';
    }
  }
  return results;
}(tiktokAges));

export const getPmax2RtbAgeGroupsByAgeRange = (minAge, maxAge) => {
  const minGroupId = pmax2RtbGroupRangeMap[minAge];
  const maxGroupId = pmax2RtbGroupRangeMap[maxAge];
  return pmax2RtbCampaignAgeGroups.filter(ageGroup => ageGroup.value >= minGroupId && ageGroup.value <= maxGroupId);
};

export const getPmax3RtbAgeGroupsByAgeRange = (minAge, maxAge) => {
  const minGroupId = pmax3RtbGroupRangeMap[minAge];
  const maxGroupId = pmax3RtbGroupRangeMap[maxAge];
  return pmax3RtbCampaignAgeGroups.filter(ageGroup => ageGroup.value >= minGroupId && ageGroup.value <= maxGroupId);
};

export const getTiktokAgeGroupsByAgeRange = (minAge, maxAge) => {
  const minGroupId = tiktokGroupRangeMap[minAge];
  const maxGroupId = tiktokGroupRangeMap[maxAge];
  return tiktokCampaignAgeGroups.filter(ageGroup => ageGroup.value >= minGroupId && ageGroup.value <= maxGroupId);
};
