import React from 'react';
import { withRouter, Prompt, RouteComponentProps } from 'react-router-dom';
import { Modal } from 'components/common/Modal/Modal';
import i18n from 'i18n';
import { ROUTE_PATH } from 'enum/RoutePath';
import * as H from 'history';

type NavigationPromptProps = {
  message?: string;
  when?: boolean;
  onConfirm?: () => void;
  onCancel?: () => void;
};

export class NavigationPrompt extends React.Component<NavigationPromptProps & RouteComponentProps, {openModal: boolean, nextLocation: H.Location}> {

  static defaultProps = {
    when: true,
    message: 'prompt.labels.message'
  };

  root: any;

  constructor (props) {
    super(props);
    this.state = {
      openModal: false,
      nextLocation: this.props.location
    };
  }

  showModal = (nextLocation: H.Location) => {
    this.setState({ openModal: true, nextLocation });
  }

  hideModal = () => {
    this.setState({ openModal: false });
  }

  messageHandler = (nextLocation: H.Location) => {
    if (nextLocation.pathname.includes(ROUTE_PATH.ERROR403) || nextLocation.pathname.includes(ROUTE_PATH.ERROR404)) {
      return true;
    }
    this.showModal(nextLocation);
    return false;
  }

  onConfirm = () => {
    this.props.onConfirm && this.props.onConfirm();
    this.props.history.push(this.state.nextLocation.pathname + this.state.nextLocation.search, this.state.nextLocation.state);
    this.hideModal();
  }

  onCancel = () => {
    this.props.onCancel && this.props.onCancel();
    this.props.history.replace(this.props.location.pathname + this.props.location.search, this.props.location.state);
    this.hideModal();
  }

  render () {
    return (
      <>
        <Prompt message={this.messageHandler} when={!this.state.openModal && this.props.when}/>
        {
          this.state.openModal &&
          <Modal
            backdrop='static'
            title={i18n.t<string>('prompt.labels.title')}
            dismiss={this.hideModal}
            primaryButton={{
              title: i18n.t<string>('common.buttons.leave'),
              callback: this.onConfirm
            }}
            secondaryButton={{
              title: i18n.t<string>('common.buttons.cancel'),
              callback: this.onCancel
            }}
          >
            {this.props.message && i18n.t<string>(this.props.message)}
          </Modal>
        }
      </>
    );
  }
}

export default withRouter(NavigationPrompt);
