import React from 'react';
import { SegmentFormState, SegmentFormProps } from './SegmentFormModel';
import { Formik, FormikProps, getIn } from 'formik';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Form, Button } from 'react-bootstrap';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { SegmentFormDTO } from 'core/segment/Segment';
import i18n from 'i18next';
import styles from './segmentForm.module.scss';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { FormikField } from 'components/common/form/field/FormikField';

export class SegmentForm extends React.PureComponent<RouteComponentProps<any> & SegmentFormProps, SegmentFormState> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleSubmit = (formData) => {
    this.props.model.submit(formData);
  }

  cancelSubmit = () => {
    this.props.history.goBack();
  }

  validate = (segment: SegmentFormDTO): any => {
    return this.props.model.validate(segment);
  }

  renderForm = (formProps: FormikProps<SegmentFormDTO>) => {
    const durationValid = !getIn(formProps.errors, 'duration') || !getIn(formProps.touched, 'duration');
    return (
      <Form onSubmit={formProps.handleSubmit}>
        <div className={styles.formArea}>
          <PermissionChecker
            permissionAware={notRoles(RoleNames.adsAdmin, RoleNames.adsSales, RoleNames.adsReport)}
          >
            {
              formProps.values.advertiserName ?
              <FormikField.Label
                label={i18n.t<string>('segmentForm.labels.advertiser')}
                name='advertiserName'
              /> :
              <FormikField.Select
                className={styles.advId}
                label={i18n.t<string>('segmentForm.labels.advertiser')}
                name='advertiserId'
                options={this.props.model.advertisers}
                simpleValue
              />
            }
          </PermissionChecker>
          <FormikField.Input
            label={i18n.t<string>('segmentForm.labels.name')}
            name='name'
          />
          <FormikField.InputGroup
            label={i18n.t<string>('segmentForm.labels.duration')}
            name='duration'
            className={styles.segmentDuration}
            type='number'
            postfix={i18n.t<string>('segmentForm.labels.durationUnit')}
            hint={durationValid ? <span className={styles.durationHint}>{i18n.t<string>('segmentForm.labels.durationLimit')}</span> : undefined}
          />
        </div>
        <div className={styles.buttonArea}>
          <Button type='submit' size='sm'>
            {i18n.t<string>('common.buttons.submit')}
          </Button>
          <Button variant='secondary' size='sm' onClick={this.cancelSubmit}>
            {i18n.t<string>('common.buttons.cancel')}
          </Button>
        </div>
      </Form>
    );
  }

  render () {
    const model = this.props.model;
    const state = model.state;
    if (state.redirectPath) {
      return <Redirect to={state.redirectPath} />;
    }
    if (state.loading || !model.segment) {
      return <LoadingIndicator />;
    }
    return (
      <div className={styles.segmentFormContainer}>
        <NavigationPrompt/>
        <TitleWithBreadcrumbs
          title={model.title}
          routes={model.breadcrumbs}
        />
        <div className={styles.titleBottomLine} />
        <Formik
          initialValues={model.segment}
          onSubmit={this.handleSubmit}
          validate={this.validate}
          validateOnBlur={false}
        >
          {this.renderForm}
        </Formik>
      </div>
    );
  }
}
