import { withActivateState, withDangerStatus, withDeactivateStatus, withEndState, withIssuesStatus, withNotStartState, withPendingReviewState } from 'helper/AdObjectStatusHelper';

export const deliveryStatusDataSetting = {
  'ADGROUP_STATUS_DELETE': withDangerStatus('Deleted'),
  'ADGROUP_STATUS_CAMPAIGN_DELETE': withDangerStatus('Campaign deleted'),
  'ADGROUP_STATUS_ADVERTISER_AUDIT_DENY': withIssuesStatus('Advertiser review failed'),
  'ADGROUP_STATUS_ADVERTISER_AUDIT': withIssuesStatus('Advertiser review in progress'),
  'ADVERTISER_CONTRACT_PENDING': withIssuesStatus('Advertiser contract has not taken effect'),
  'ADGROUP_STATUS_CAMPAIGN_EXCEED': withDeactivateStatus('Campaign over budget'),
  'ADGROUP_STATUS_BUDGET_EXCEED': withDeactivateStatus('Ad group over budget'),
  'ADGROUP_STATUS_BALANCE_EXCEED': withIssuesStatus('Insufficient account balance'),
  'ADGROUP_STATUS_AUDIT_DENY': withIssuesStatus('Ad group review failed'),
  'ADGROUP_STATUS_REAUDIT': withPendingReviewState('Review of modifications in progress'),
  'ADGROUP_STATUS_AUDIT': withPendingReviewState('New review created'),
  'ADGROUP_STATUS_CREATE': withPendingReviewState('New ad group created'),
  'ADGROUP_STATUS_NOT_START': withNotStartState('The scheduled delivery time has not started'),
  'ADGROUP_STATUS_TIME_DONE': withEndState('Completed'),
  'ADGROUP_STATUS_CAMPAIGN_DISABLE': withDeactivateStatus('Campaign suspended'),
  'ADGROUP_STATUS_DISABLE': withDeactivateStatus('Suspended'),
  'ADGROUP_STATUS_DELIVERY_OK': withActivateState('Advertising in delivery')
};
