import SummaryDetail from 'components/SummaryDetail/SummaryDetail';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import i18n from 'i18n';
import React from 'react';
import { Button } from 'react-bootstrap';
import { MessageCampaignSummaryStepProps, MessageCampaignSummaryStepState } from './MessageCampaignSummaryStepModel';
import styles from './messageCampaignSummaryStep.module.scss';

export class MessageCampaignSummaryStep extends React.Component<MessageCampaignSummaryStepProps, MessageCampaignSummaryStepState> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  renderSummaryDetails = (data) => {
    return Object.keys(data).map((key) =>
      data[key] ?
      <SummaryDetail
        key={key}
        title={data[key].title}
        data={data[key].content}
        prefixColor={data[key].titlePrefixColor}
      /> : <div key={key}/>
    );
  }

  renderSummaryTitle = (summaryData) => {
    return (
      <div className={styles.categoryTitle}>
        {summaryData.title}
        { summaryData.backStep !== undefined &&
          <div className={styles.backBtn} onClick={this.props.model.goStep.bind(undefined, summaryData.backStep, summaryData.backSubStep)}>
            {i18n.t<string>('campaignSummary.labels.backButtonTitle')}
          </div>
        }
      </div>
    );
  }

  renderSummary = (summaryData, renderOtherInfo?: any) => {
    if (!summaryData) {
      return <div/>;
    }
    return (
      <section>
        {this.renderSummaryTitle(summaryData)}
        <div>
          {this.renderSummaryDetails(summaryData.data)}
        </div>
        {renderOtherInfo && renderOtherInfo()}
      </section>
    );
  }

  render () {
    const model = this.props.model;
    const objectType = model.flowModel.objectType;
    return (
      <div className={styles.container}>
        {model.state.loading && <LoadingIndicator />}
        <div className={styles.title}>
          {i18n.t<string>('campaignSummary.titles.checkYourSettings')}
        </div>
        <div className={styles.content}>
          {this.renderSummary(model.getAdTypeSummaryData())}
          <div className={styles.sepLine} />
          <div className={styles.basicSummary}>
            {this.renderSummary(model.getSetUpSummaryData())}
          </div>
          <div className={styles.sepLine} />
          {this.renderSummary(model.getLimitationsSummaryData())}
        </div>
        <div className={styles.buttonArea}>
          <Button variant='primary' size='sm' onClick={model.submit}>
            {objectType === 'campaign' ? i18n.t<string>('common.buttons.submit') : i18n.t<string>('common.buttons.publish')}
          </Button>
          <Button variant='secondary' size='sm' onClick={model.goLast}>
            {i18n.t<string>('campaign.buttons.back')}
          </Button>
        </div>
      </div>
    );
  }
}
