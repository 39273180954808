import React from 'react';
import _, { LoDashStatic } from 'lodash';
import classNames from 'classnames/bind';
import { Inventory, DefaultInventoryModel } from '../Inventory';
import { KeywordComponent } from 'components/CampaignLimitation/KeywordComponent/KeywordComponent';
import { DefaultSelectComponentModel } from 'components/CampaignLimitation/SelectComponent';
import i18n from 'i18n';
import styles from './limitationSetting.module.scss';
import LimitationSet from 'components/CampaignLimitation/LimitationSet/LimitationSet';
import { OPERATE, OPERATES } from 'enum/Operate';
import { DefaultKeywordComponentModel } from 'components/CampaignLimitation/KeywordComponent/KeywordComponentModel';
import { SelectOptions } from 'components/common/commonType';
import {
  DEFAULT_INVENTORY,
  operateTitles,
  keywordInventorySetting
} from './LimitationSettingData';
import { AsyncSourceSpan } from 'components/AsyncSourceSpan/AsyncSourceSpan';
import { SearchKeywordsComponent } from 'components/CampaignLimitation/SearchKeywordsComponent/SearchKeywordsComponent';
import { DefaultSearchKeywordsComponentModel } from 'components/CampaignLimitation/SearchKeywordsComponent/SearchKeywordsComponentModel';

const cx = classNames.bind(styles);
type LimitationValue = {
  type: string;
  value: SelectOptions[];
};

export interface LimitationSettingProps {
  value: LimitationValue[] | [];
  operate: OPERATES;
  taOptionsCache: {[key: string]: SelectOptions[]};
  onChange: (type: string, value: SelectOptions[] | LoDashStatic) => void;
  onChangeInventory: (type: string, operate?: OPERATES) => void;
  showInventory: boolean;
  inventorySelected: string;
  onDeleteLimitation: (operate: string, type: string | LoDashStatic) => void;
  onChangeOperate: (
    orgOperate: string,
    type: string,
    newOperate: string | LoDashStatic
  ) => void;
  permission: string[];
  limitationSetting: any;
  errors: any;
}

class LimitationSetting extends React.PureComponent<LimitationSettingProps> {
  avaliablePermission: string[];
  inventorySetting: any[];
  limitationValuesObj = {};

  static defaultProps = {
    value: [],
    operate: OPERATE.INCLUDE,
    onChange: _.noop,
    onChangeInventory: _.noop,
    showInventory: false,
    inventorySelected: DEFAULT_INVENTORY,
    onDeleteLimitation: _.noop,
    onChangeOperate: _.noop,
    permission: []
  };
  constructor (props) {
    super(props);
    this.avaliablePermission = [];
    this.inventorySetting = [];
    this.limitationValuesObj = {};
    this.handleClickEditAction = this.handleClickEditAction.bind(this);
  }

  getPreComment = (preCommentPropsGetter, operate, name, typeObject) => {
    const preCommentProps = preCommentPropsGetter && preCommentPropsGetter(typeObject);
    return preCommentProps ?
      <AsyncSourceSpan
        key={i18n.language}
        {...preCommentProps}
        className={[operate, `${name}LimitationPrecomment`].join(' ')}
      /> :
      undefined;
  }

  getPostComment = (errorTip, postCommentPropsGetter, name, typeObject, isRequiredOperate) => {
    const postCommentProps = !errorTip && postCommentPropsGetter && postCommentPropsGetter(typeObject, isRequiredOperate);
    return postCommentProps ?
      <AsyncSourceSpan
        key={i18n.language}
        {...postCommentProps}
        className={`${name}LimitationPostcomment`}
      /> :
      undefined;
  }

  renderLimitationSet (inventorySettingsSource) {
    const {
      operate,
      onChange: handleChange,
      onDeleteLimitation: handleDeleteLimitation,
      onChangeOperate: handleChangeOperate,
      onChangeInventory: handleChangeInventory,
      value: limitationOfOperate,
      errors
    } = this.props;
    const ignoreDefault = ({ name }) => name !== DEFAULT_INVENTORY;
    const inventorySettings = inventorySettingsSource.slice();
    if (['campaign_keyword', 'creative_keyword'].some(permission => this.getAvaliablePermission().includes(permission))) {
      inventorySettings.push(...keywordInventorySetting);
    }
    const typeObject = this.getLimitationValuesObj();
    return inventorySettings.filter(ignoreDefault).map(({ name, title, i18nPrefix, preCommentPropsGetter, postCommentPropsGetter, disable, disableReason, supportOperates, requiredOperate, showWithLimitation, renderTips }) => {
      const handleChangeLimitationSet = _.curry(handleChange)(name, _);
      const value: Array<SelectOptions> = _.get(typeObject[name], 'value', []);
      const handleAdd = _.partial(handleChangeInventory, name, operate);
      let errorTip;
      const error = _.get(errors, name);
      if (error) {
        errorTip = <span className={'errorTip'}>{error}</span>;
      }
      const limitationToShowWith = showWithLimitation ? limitationOfOperate.find(limitation => showWithLimitation.includes(limitation.type)) : undefined;
      const isRequiredOperate = requiredOperate && requiredOperate.includes(operate);
      const needShow = !!limitationToShowWith || isRequiredOperate;
      const postComment = this.getPostComment(errorTip, postCommentPropsGetter, name, typeObject, isRequiredOperate);
      const setClassName = postComment || errorTip ? styles.withPostComment : undefined;
      return (
        <LimitationSet
          className={setClassName}
          key={title + value}
          value={value}
          operate={operate}
          title={title}
          needShow={needShow}
          errorTip={errorTip}
          onChange={handleChangeLimitationSet}
          deleteLimitation={handleDeleteLimitation}
          changeOperate={handleChangeOperate}
          add={handleAdd}
          type={name}
          preComment={this.getPreComment(preCommentPropsGetter, operate, name, typeObject)}
          postComment={postComment}
          i18nPrefix={i18nPrefix}
          disable={disable}
          disableReason={disableReason}
          supportOperates={supportOperates}
          renderTips={renderTips}
        />
      );
    });
  }

  getLimitationValuesObj (): { [type: string]: LimitationValue } {
    const { value: limitationValues } = this.props;
    return _.keyBy(limitationValues, 'type');
  }

  renderInventoryComponent (inventorySettings) {
    const typeObject = this.getLimitationValuesObj();
    return inventorySettings.map(inventorySetting => {
      const InventoryComponent: typeof React.Component =
        inventorySetting.component;
      const {
        name,
        singleSelect,
        itemSetting,
        searchPlaceholder,
        customInputModelGetter,
        disable,
        extra
      } = inventorySetting;
      const { onChange } = this.props;
      const value = _.get(typeObject[name], 'value', []);
      let model;
      const handleInventoryChange = _.curry(onChange)(name, _);
      if (InventoryComponent === KeywordComponent) {
        const keywordValue = [
          typeObject['unicornkeyword1'],
          typeObject['unicornkeyword2']
        ];
        model = new DefaultKeywordComponentModel(name, keywordValue, onChange);
      } else if (InventoryComponent === SearchKeywordsComponent) {
        const value = typeObject['searchKeywords'] ? typeObject['searchKeywords'].value : [];
        model = new DefaultSearchKeywordsComponentModel(name, value, extra.retailType, onChange);
      } else {
        model = new DefaultSelectComponentModel(
          name,
          singleSelect,
          itemSetting,
          i18n.t<string>(searchPlaceholder),
          handleInventoryChange,
          [],
          value,
          customInputModelGetter,
          disable
        );
      }
      return <InventoryComponent key={name} model={model} />;
    });
  }

  getAvaliablePermission (): string[] {
    const { permission = [] } = this.props;
    if (this.avaliablePermission.length === 0 || _.xor(this.avaliablePermission, permission).length !== 0) {
      this.avaliablePermission = permission;
      this.avaliablePermission.push(DEFAULT_INVENTORY);
    }
    return this.avaliablePermission;
  }

  getInventorySetting () {
    const { operate } = this.props;
    const avaliablePermission = this.getAvaliablePermission();
    this.inventorySetting = this.props.limitationSetting.filter(setting => {
      if (setting.name === 'default') {
        return true;
      }
      if (!setting.supportOperates || !(setting.supportOperates.includes(operate))) {
        return false;
      }
      return setting.ignoreAddonFeature || avaliablePermission.includes(setting.name) || (setting.addonFeature && avaliablePermission.includes(setting.addonFeature));
    });
    return this.inventorySetting;
  }

  handleClickEditAction () {
    const {
      onChangeInventory: handleChangeInventory,
      operate,
      showInventory
    } = this.props;

    handleChangeInventory(
      DEFAULT_INVENTORY,
      showInventory ? undefined : operate
    );
  }

  getDefaultInventoryModel (inventory: string, inventorySettings) {
    const { operate, showInventory, taOptionsCache, onChangeInventory } = this.props;
    const inventorySetting = _.find(inventorySettings, setting => setting.name === inventory);
    if (!inventorySetting || (!this.getAvaliablePermission().includes(inventorySetting.addonFeature) && !inventorySetting.ignoreAddonFeature) || !showInventory) {
      return new DefaultInventoryModel(
        DEFAULT_INVENTORY,
        inventorySettings,
        taOptionsCache
      );
    }
    return new DefaultInventoryModel(
      inventory,
      inventorySettings,
      taOptionsCache,
      'limitation.labels.trafficAttribute',
      newInventory => onChangeInventory(newInventory, operate)
    );
  }

  render () {
    const {
      operate = OPERATE.INCLUDE,
      showInventory,
      inventorySelected,
      value
    } = this.props;
    const titleClassName = cx({
      title: true,
      [operate]: true
    });
    const haveValue = value.length === 0 ? 0 : 1;
    const title = i18n.t<string>(operateTitles[operate][haveValue]);
    const limitationSetClass = cx({
      limitationSet: true,
      inventoryActive: showInventory
    });
    const inventorySettings = this.getInventorySetting();
    return (
      <div className={styles.limitationSetting}>
        <div className={styles.titleContainer}>
          <div className={titleClassName}>
            <span>{title}</span>
          </div>
          <div
            className={styles.editTitle}
            onClick={this.handleClickEditAction}
          >
            <span>
              {showInventory
                ? i18n.t<string>('limitation.labels.finishEditing')
                : i18n.t<string>('limitation.labels.edit')}
            </span>
          </div>
        </div>
        <div className={styles.settingContainer}>
          <div className={showInventory ? styles.active : styles.hide}>
            <Inventory model={this.getDefaultInventoryModel(inventorySelected, inventorySettings)}>
              {this.renderInventoryComponent(inventorySettings)}
            </Inventory>
          </div>
          <div className={limitationSetClass}>{this.renderLimitationSet(inventorySettings)}</div>
        </div>
      </div>
    );
  }
}

export default LimitationSetting;
