import { Form } from 'components/common/form/Form';
import { FormConfig } from 'components/common/form/FormConfig';
import { Formik } from 'formik';
import { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import i18n from 'i18n';
import { faFileArchive } from '@fortawesome/free-solid-svg-icons';
import { DefaultCustomLayoutManager } from 'core/customLayout/CustomLayoutManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import config from 'config';
import styles from './uploadStep.module.scss';

const defaultCustomLayoutManager = new DefaultCustomLayoutManager();

export const UploadStep = ({
  goNext,
  customLayoutSetupFlowData,
  setCustomLayoutSetupFlowData
}) => {

  const [formConfig, setFormConfig] = useState<FormConfig>();
  const { loading, callAPIs } = useCallAPI();

  const validateTemplate = useCallback(async (templateZip) => {
    const errors = await defaultCustomLayoutManager.validateTemplate(templateZip.file);
    const errorDoms = errors ? errors.map(error => (
      <div key={error}>{error}</div>
    )) : undefined;
    return errors ? (
      <>
        {errorDoms}
      </>
    ) : undefined;
  }, []);

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder()
            .addFormikFileInput({
              type: 'other',
              label: i18n.t<string>('customLayoutSetupFlowPage.labels.template'),
              name: 'templateZip',
              icon: faFileArchive,
              hints: [
                i18n.t<string>('customLayoutSetupFlowPage.hints.templateContent')
              ],
              permanentHint: (
                <span className={styles.hint}>
                  <Trans i18nKey='html5Form.labels.fileHint'>
                    ...<a href={`https://storage.googleapis.com/${config.cloudStorageConfig.bucket}/custom_layout_sample.zip`} download>Click here</a>...
                  </Trans>
                </span>
              ),
              validate: validateTemplate
            })
            .build()
        )
        .build()
      )
      .build()
    );
  }, [validateTemplate]);

  const renderForm = useCallback((formikProps) => {
    const renderFormBtns = () => (
      <Form.SubmitButton>{i18n.t<string>('common.buttons.next')}</Form.SubmitButton>
    );
    return (
      <Form
        formikProps={formikProps}
        formConfig={formConfig}
        renderFormBtns={renderFormBtns}
        withNavigationPrompt={false}
      />
    );
  }, [formConfig]);

  const handleSubmit = useCallback(async (values) => {
    if (customLayoutSetupFlowData.zipFile === values.templateZip.file) {
      goNext();
      return;
    }

    callAPIs([() => defaultCustomLayoutManager.uploadTemplate(values.templateZip.file)], ({
      sessionId,
      macros
    }) => {
      setCustomLayoutSetupFlowData(() => ({
        sessionId,
        macros: macros.reduce((acc, macro) => {
          acc[macro] = {};
          return acc;
        }, {}),
        zipFile: values.templateZip.file
      }));
      goNext();
    });
  }, [customLayoutSetupFlowData.zipFile, setCustomLayoutSetupFlowData, goNext, callAPIs]);

  return (
    <>
      {loading && <LoadingIndicator />}
      <Formik
        initialValues={{ templateZip: { file: customLayoutSetupFlowData.zipFile } }}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {renderForm}
      </Formik>
    </>
  );
};
