import React from 'react';
import { StepPageContext } from './StepPageContext';
import styles from './stepPage.module.scss';
import cx from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faLock } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import i18n from 'i18n';
import { Page, PageProps } from 'components/Page/Page';

const classNames = cx.bind(styles);

enum StepState {
  Editing = 0,
  Finished = 1,
  Locked = 2
}

type StepPageState = {
  activeStepIndex: number;
  activeSubStepIndex: number;
  finishedStepIndex: number;
};

export type StepRenderProps = {
  subStepIndex: number;
  goNext: (onGoNext?: () => void) => void;
  goLast: () => void;
  goStep: (stepIndex: number, subStepIndex: number) => void;
  goSubStep: (subStepIndex) => void;
  registerValidateMethod: (validateMethod: () => void) => void;
};

type StepProps = {
  label: string;
  index?: number;
  state?: StepState;
  isActive?: boolean;
  children?: React.ReactNode;
  activeSubStepIndex?: number;
  validateMethod?: () => Promise<string[]>;
  renderBtns?: (props: StepRenderProps) => JSX.Element;
  renderStepContent?: (props: StepRenderProps) => React.ReactNode;
};

type SubStepProps = {
  label: string;
  index?: number;
  isActive?: boolean;
  parentStepIndex?: number;
};

const SubStep: React.FC<SubStepProps> = ({
  index,
  parentStepIndex,
  label
}) => {
  const context = React.useContext(StepPageContext);
  const isActive = context ?
    context.activeStepIndex === parentStepIndex && index === context.activeSubStepIndex :
    false;
  const onStepClick = context ? async (e) => {
    if (isActive) {
      return;
    }
    parentStepIndex !== undefined && index !== undefined &&
      context.setActiveStep(parentStepIndex, index);
  } : undefined;
  const stepClassName = classNames('subStep', {
    active: isActive
  });
  return (
    <div onClick={onStepClick} className={stepClassName}>
      {label}
    </div>
  );
};
SubStep.displayName = 'StepPage.SubStep';

const Step: React.FC<StepProps> = ({
  state,
  index: stepIndex,
  children,
  label
}) => {
  const context = React.useContext(StepPageContext);
  let index = 0;
  const subSteps: any = [];
  React.Children.forEach(
    children,
    child => {
      if (!React.isValidElement(child) || child.type !== SubStep) {
        return;
      }
      subSteps.push(React.cloneElement(child as React.ReactElement<SubStepProps>, {
        key: index,
        index,
        parentStepIndex: stepIndex
      }));
      index++;
    }
  );
  const onClick = context ? () => {
    if (context.activeStepIndex === stepIndex) {
      return;
    }
    stepIndex !== undefined && context.setActiveStep(stepIndex, 0);
  } : undefined;
  const isActive = context ? context.activeStepIndex === stepIndex : false;
  const stepClassName = classNames('step', {
    active: isActive && _.get(children, 'length', 0) === 0,
    locked: state === StepState.Locked
  });
  const renderIcon = () => {
    if (state === StepState.Locked) {
      return (
        <FontAwesomeIcon
          icon={faLock}
          className={`${styles.icon} ${styles.locked}`}
        />
      );
    } else if (state === StepState.Finished) {
      return (
        <FontAwesomeIcon
          icon={faCheck}
          className={`${styles.icon} ${styles.finished}`}
        />
      );
    }
  };
  return (
    <div className={stepClassName}>
      <div onClick={onClick} className={styles.mainStep}>
        {renderIcon()}
        {label}
      </div>
      <div>
        {subSteps}
      </div>
    </div>
  );
};
Step.displayName = 'StepPage.Step';

const SideBarFooter: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <div className={styles.sidebarFooter}>
      {children}
    </div>
  );
};
SideBarFooter.displayName = 'StepPage.SideBarFooter';

export class StepPage extends React.Component<{
  cancel: () => void;
  withSidebar?: boolean;
  title?: string;
} & Omit<PageProps, 'title'> & React.PropsWithChildren, StepPageState> {

  static Step = Step;
  static SubStep = SubStep;
  static SideBarFooter = SideBarFooter;
  static defaultProps = {
    withSidebar: true
  };
  stepRenderProps: any = undefined;

  state = {
    activeStepIndex: 0,
    activeSubStepIndex: 0,
    finishedStepIndex: -1
  };

  getStepState = (stepIndex) => {
    if (stepIndex <= this.state.finishedStepIndex) {
      return StepState.Finished;
    }
    if (stepIndex === this.state.finishedStepIndex + 1) {
      return StepState.Editing;
    }
    return StepState.Locked;
  }

  onValidateFailed = () => {
    this.setState((prevState) => {
      const finishedStepIndex = prevState.activeStepIndex - 1;
      return {
        ...prevState,
        finishedStepIndex
      };
    });
  }

  goLastStep = (activeStepIndex) => {
    if (activeStepIndex - 1 < 0) {
      return;
    }
    this.setState({
      activeStepIndex: activeStepIndex - 1,
      activeSubStepIndex: 0
    });
  }

  goNextStep = async (activeStep, stepCount, onGoNext?: any) => {
    const error = activeStep.validateMethod ? await activeStep.validateMethod() : [];
    const activeStepIndex = activeStep.index;
    if (error.length > 0) {
      this.onValidateFailed();
      return;
    }
    if (activeStepIndex + 1 === stepCount) {
      return;
    }
    onGoNext && typeof onGoNext === 'function' && await onGoNext();
    this.setState({
      activeStepIndex: activeStepIndex + 1,
      activeSubStepIndex: 0,
      finishedStepIndex: activeStepIndex
    });
  }

  goStep = (stepIndex, subStepIndex) => {
    if (stepIndex > this.state.finishedStepIndex) {
      return;
    }
    this.setState((prevState) => ({
      ...prevState,
      activeStepIndex: stepIndex,
      activeSubStepIndex: subStepIndex
    }));
  }

  goSubStep = (subStepIndex) => {
    this.setState((prevState) => {
      return {
        ...prevState,
        activeSubStepIndex: subStepIndex
      };
    });
  }

  getStepRenderProps (activeStep, stepCount) {
    const activeStepIndex = activeStep.index;
    if (
      !this.stepRenderProps ||
      this.stepRenderProps.stepIndex !== activeStepIndex
    ) {
      this.stepRenderProps = {
        stepIndex: activeStepIndex,
        subStepIndex: this.state.activeSubStepIndex,
        goLast: () => {
          this.goLastStep(activeStepIndex);
        },
        goNext: async (onGoNext?: any, stepData?: any) => {
          await this.goNextStep(activeStep, stepCount, onGoNext);
        },
        goStep: this.goStep,
        goSubStep: this.goSubStep,
        registerValidateMethod: (validateMethod: () => Promise<string[]>) => {
          activeStep.validateMethod = validateMethod;
        }
      };
    }
    if (this.stepRenderProps.subStepIndex !== this.state.activeSubStepIndex) {
      this.stepRenderProps.subStepIndex = this.state.activeSubStepIndex;
    }
    return this.stepRenderProps;
  }

  getContextValue (activeStep) {
    return {
      activeStepIndex: this.state.activeStepIndex,
      activeSubStepIndex: this.state.activeSubStepIndex,
      setActiveStep: async (newActiveStepIndex, newActiveSubStepIndex) => {
        const needValidate = newActiveStepIndex > this.state.activeStepIndex;
        const error = needValidate && activeStep.validateMethod ? await activeStep.validateMethod() : [];
        if (error.length > 0) {
          this.onValidateFailed();
          return;
        }
        this.setState({
          activeStepIndex: newActiveStepIndex,
          activeSubStepIndex: newActiveSubStepIndex
        });
      }
    };
  }

  render () {
    const stepsInfo: any = [];
    const steps: any = [];
    let index = 0;
    let footer;
    React.Children.forEach(
      this.props.children,
      child => {
        if (!React.isValidElement(child)) {
          return;
        }
        if (child.type === Step) {
          stepsInfo.push({
            index,
            validateMethod: child.props.validateMethod,
            renderBtns: child.props.renderBtns,
            renderStepContent: child.props.renderStepContent
          });
          steps.push(React.cloneElement(child as React.ReactElement<StepProps>, {
            key: index,
            index,
            state: this.getStepState(index)
          }));
          index++;
        } else if (child.type === SideBarFooter) {
          footer = child;
        }
      }
    );
    const activeStep = stepsInfo[this.state.activeStepIndex];
    const renderMethod = activeStep.renderStepContent;
    const stepRenderProps = activeStep ? this.getStepRenderProps(activeStep, stepsInfo.length) : undefined;
    const renderBtnsMethod: (() => React.ReactNode) | undefined = activeStep && activeStep.renderBtns ?
      _.partial(activeStep.renderBtns, stepRenderProps) :
      undefined;
    const stepContentClassName = classNames(styles.stepContent, {
      withSidebar: this.props.withSidebar
    });

    return (
      <StepPageContext.Provider
        value={this.getContextValue(activeStep)}
      >
        <div className={styles.stepPage}>
          {this.props.withSidebar &&
            <div className={styles.sideBar}>
              <button
                className={`btn btn-secondary btn-sm ${styles.cacelBtn}`}
                onClick={this.props.cancel}
              >
                {i18n.t<string>('common.buttons.cancel')}
              </button>
              <div className={styles.steps}>
                {steps}
              </div>
              {footer}
            </div>
          }
          <div className={stepContentClassName}>
            {
              this.props.title ?
                <Page
                  title={this.props.title}
                  breadcrumbsRoutes={this.props.breadcrumbsRoutes}
                  topAreaWithPadding={this.props.topAreaWithPadding}
                  topAreaEndWithShadow={this.props.topAreaEndWithShadow}
                  spaceBetweenTopAreaAndContent={this.props.spaceBetweenTopAreaAndContent}
                  renderBtns={renderBtnsMethod}
                >
                  {renderMethod && renderMethod(stepRenderProps)}
                </Page> :
                renderMethod && renderMethod(stepRenderProps)
            }
          </div>
        </div>
      </StepPageContext.Provider>
    );
  }
}
