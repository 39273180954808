import { AdType } from 'core/rtbCampaign/RtbCampaign';
import { DefaultMessageCampaignBasicFormModel, MessageCampaignBasicFormModel, MessageCampaignBasicFormModelConstructorParams } from './MessageCampaignBasicFormModel';

export interface MessageFormModel extends MessageCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}

abstract class AbstractMessageFormModel extends DefaultMessageCampaignBasicFormModel implements MessageFormModel {

  showVideoProgressRadio = false;

  get campaignAdType () {
    return AdType.MESSAGE;
  }

  getBidPriceFloorData () {
    const data = this.order.campaignBidPrice.find(data => data.type === AdType.MESSAGE);
    return data ? data.bidFloor : {};
  }
}

export class EditMessageFormModel extends AbstractMessageFormModel {
  constructor (...args: MessageCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }

  get canEditBudgetPlan () {
    return false;
  }

  get canEditPriceModel () {
    return false;
  }

  canEditOptimize () {
    return false;
  }
}

export class CreateMessageFormModel extends AbstractMessageFormModel {

  constructor (...args: MessageCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }

  get canEditBudgetPlan () {
    return true;
  }

  get canEditPriceModel () {
    return true;
  }
}
