import { CategoryListColumns, useCategoryListModel } from './CategoryListModel';
import i18n from 'i18n';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Link } from 'react-router-dom';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faCloudDownloadAlt, faCloudUploadAlt, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { get, partial } from 'lodash';
import { useCallback, useRef } from 'react';
import { SegmentImportType } from 'core/segment/SegmentManager';

export const CategoryList: React.FC<{
  sourceId: number;
}> = ({ sourceId }) => {
  const {
    loading,
    columns,
    searchString,
    filterdCategories,
    onHandleSearch,
    importAudiences,
    exportAudiences
  } = useCategoryListModel(sourceId);

  const inputRefs: any = useRef({});

  const renderRowBtns = useCallback((category) => {

    let importType = SegmentImportType.COOKIE;
    const onImportBtnClick = (type: SegmentImportType) => {
      importType = type;
      inputRefs.current[category.id] && inputRefs.current[category.id].click();
    };
    const onExportBtnClick = () => {
      exportAudiences(category.id);
    };
    const onFileChange = (event) => {
      const files = get(event, 'currentTarget.files', []);
      if (!files[0]) {
        return;
      }
      importAudiences(category.id, importType, files[0], () => {
        if (inputRefs.current[category.id]) {
          inputRefs.current[category.id].value = '';
        }
      });
    };

    return [
      <Link
        key={`edit-category-${category.id}`}
        to={`/tenmax-segments/sources/${category.sourceId}/categories/${category.id}/edit`}
      >
        <IconWithTooltip
          icon={faPencilAlt}
          tooltipProps={{
            id: `edit-category-${category.id}`,
            tooltip: i18n.t<string>('categoryForm.titles.edit')
          }}
        />
      </Link>,
      <IconWithTooltip
        key={`import-cookie-${category.id}`}
        icon={faCloudUploadAlt}
        onClick={partial(onImportBtnClick, SegmentImportType.COOKIE)}
        tooltipProps={{
          id: `import-cookie-${category.id}`,
          tooltip: i18n.t<string>('categoryList.hints.importCookie')
        }}
      />,
      <IconWithTooltip
        key={`import-deviceId-${category.id}`}
        icon={faCloudUploadAlt}
        onClick={partial(onImportBtnClick, SegmentImportType.DEVICE_ID)}
        tooltipProps={{
          id: `import-deviceId-${category.id}`,
          tooltip: i18n.t<string>('categoryList.hints.importDeviceId')
        }}
      />,
      <IconWithTooltip
        key={`export-category-${category.id}`}
        icon={faCloudDownloadAlt}
        onClick={onExportBtnClick}
        tooltipProps={{
          id: `export-category-${category.id}`,
          tooltip: i18n.t<string>('categoryList.hints.export')
        }}
      />,
      <input
        key={`file-input-${category.id}`}
        accept='text/csv'
        style={{ display: 'none' }}
        type='file'
        ref={el => inputRefs.current[category.id] = el}
        onChange={onFileChange}
      />
    ];
  }, [importAudiences, exportAudiences, inputRefs]);

  const renderListOperations = useCallback(() => [(
    <Link
      key='new-btn'
      to={`/tenmax-segments/sources/${sourceId}/categories/new`}
      className={'btn btn-tertiary btn-sm'}
    >
      {i18n.t<string>('categoryList.buttons.create')}
    </Link>
  )], [sourceId]);

  return (
    <>
      {loading && <LoadingIndicator/>}
      <StickableBootstrapTable
        customPadding={false}
        keyField={CategoryListColumns.NAME}
        data={filterdCategories}
        columns={columns}
        hidePagination={true}
        noDataIndication={i18n.t<string>('categoryList.placeholders.noData')}
        defaultSorted={[{
          dataField: CategoryListColumns.NAME,
          order: 'desc'
        }]}
        renderRowBtns={renderRowBtns}
        defaultSearchString={searchString}
        searchbarPlaceholder={i18n.t<string>('categoryList.placeholders.searchbar')}
        onHandleSearch={onHandleSearch}
        renderListOperations={renderListOperations}
      />
    </>
  );
};
