import _ from 'lodash';
import { ReportDataProvider } from './ReportDataProvider';
import { Metrics, ReportDimension, ReportRecord } from 'core/report/ReportData';
import { divideValue } from './ReportDataHelper';
import { tableColumnSettings } from './reportTableColumnSettings';
import { Actor, RoleNames } from 'core';
import { hasRoles } from 'core/permission/PermissionDSL';

enum COLUMNS {
  NAME = 'name',
  IMPRES = 'impres',
  VIEW = 'adView',
  VIEW_RATE = 'viewRate',
  VIEWABLE = 'viewable',
  VIEWABLE_VIEW_RATE = 'viewableViewRate',
  VCTR = 'vctr',
  CPV = 'cpv',
  CLICKS = 'clicks',
  SKIP_COUNT = 'skipCount',
  FIRST_QUARTILE= 'firstQuartile',
  MID_POINT = 'midpoint',
  THIRD_QUARTILE = 'thirdQuartile',
  COMPLETE = 'complete',
  CONVS = 'convs',
  CONV_1 = 'conv_1',
  CONV_7 = 'conv_7',
  CVR = 'cvr',
  MEDIA_COST = 'mediaCost',
  MEDIA_SPENT = 'mediaSpent',
  AGENCY_PROFIT = 'agcProfit',
  SYS_PROFIT = 'sysProfit',
  DATA_COST = 'dataCost',
  SPENT = 'spent',
  TAGS = 'tags',
  EDITBTNS = 'editBtns'
}

export class VideoReportDataProvider implements ReportDataProvider {

  onDimensionNameClick: any;
  onDateClick: any;
  onEditClick: any;
  constructor (onDimensionNameClick, onDateClick, onEditClick) {
    this.onDimensionNameClick = onDimensionNameClick;
    this.onDateClick = onDateClick;
    this.onEditClick = onEditClick;
  }

  get avalableDimensions () {
    return [
      ReportDimension.DAY,
      ReportDimension.AGENCY,
      ReportDimension.ADVERTISER,
      ReportDimension.ORDER,
      ReportDimension.L1_OBJECT,
      ReportDimension.L2_OBJECT,
      ReportDimension.CAMPAIGN_TYPE,
      ReportDimension.SALES_CHANNEL,
      ReportDimension.L3_CHANNEL,
      ReportDimension.CREATIVE,
      ReportDimension.CREATIVE_TYPE,
      ReportDimension.SELF_BUY
    ];
  }

  getReportDimensions (actor: Actor | null): Array<ReportDimension> {
    const isSysAdmin = hasRoles(RoleNames.sysAdmin).visible({ actor });
    const isAdvertiser = hasRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales).visible({ actor });
    const forbidDimensions = isSysAdmin ?
    [ReportDimension.MONTH, ReportDimension.HOUR, ReportDimension.DEVICE_TYPE] :
    [ReportDimension.MONTH, ReportDimension.HOUR, ReportDimension.DEVICE_TYPE, ReportDimension.AGENCY];
    isAdvertiser && forbidDimensions.push(ReportDimension.ADVERTISER);
    return this.avalableDimensions.filter(dimension => forbidDimensions.indexOf(dimension) === -1);
  }

  getReportTableColumnSettings (allowMetrics: Array<Metrics>, tableData: any, dimension: ReportDimension, currency: string) {
    const allColumnSettings = tableColumnSettings(tableData, dimension, currency, this.onDimensionNameClick, this.onDateClick);
    const tableAllowMetrics = [
      ...allowMetrics,
      COLUMNS.NAME,
      COLUMNS.EDITBTNS
    ];
    if (allowMetrics.indexOf(Metrics.VIEW) !== -1) {
      allowMetrics.indexOf(Metrics.IMPRES) !== -1 && tableAllowMetrics.push(COLUMNS.VIEW_RATE);
      allowMetrics.indexOf(Metrics.SPENT) !== -1 && tableAllowMetrics.push(COLUMNS.CPV);
    }
    if (allowMetrics.indexOf(Metrics.VIEWABLE) !== -1) {
      allowMetrics.indexOf(Metrics.VIEW) !== -1 && tableAllowMetrics.push(COLUMNS.VIEWABLE_VIEW_RATE);
      allowMetrics.indexOf(Metrics.CLICKS) !== -1 && tableAllowMetrics.push(COLUMNS.VCTR);
    }
    dimension === ReportDimension.CAMPAIGN && tableAllowMetrics.push(COLUMNS.TAGS);
    return _.compact(
      Object.values(COLUMNS).map(column => {
        return tableAllowMetrics.indexOf(column) !== -1 &&
          allColumnSettings[column];
      })
    );
  }

  getReportTableData (records: Array<ReportRecord>) {
    return records.map(record => {
      return {
        key: record.dimensionName + _.get(record, 'dimensionID', 0),
        id: _.get(record, 'dimensionID', 0).toString(),
        tags: record.tags,
        extra: record.extra,
        [COLUMNS.NAME]: record.dimensionName,
        [COLUMNS.IMPRES]: record.impres,
        [COLUMNS.VIEW]: _.get(record, 'view', 0),
        [COLUMNS.VIEW_RATE]: divideValue(_.get(record, 'view', 0) * 100, _.get(record, 'impres', 0)),
        [COLUMNS.VIEWABLE]: record.viewable,
        [COLUMNS.VIEWABLE_VIEW_RATE]: divideValue(_.get(record, 'view', 0) * 100, _.get(record, 'viewable', 0)),
        [COLUMNS.VCTR]: divideValue(_.get(record, 'clicks', 0) * 100, _.get(record, 'viewable', 0)),
        [COLUMNS.CPV]: divideValue(_.get(record, 'spent', 0), _.get(record, 'view', 0)),
        [COLUMNS.CLICKS]: record.clicks,
        [COLUMNS.SKIP_COUNT]: _.get(record, 'skipCount', 0),
        [COLUMNS.FIRST_QUARTILE]: _.get(record, 'firstQuartile', 0),
        [COLUMNS.MID_POINT]: _.get(record, 'midpoint', 0),
        [COLUMNS.THIRD_QUARTILE]: _.get(record, 'thirdQuartile', 0),
        [COLUMNS.COMPLETE]: _.get(record, 'complete', 0),
        [COLUMNS.CONVS]: record.convs,
        [COLUMNS.CONV_1]: record.conv_1,
        [COLUMNS.CONV_7]: record.conv_7,
        [COLUMNS.CVR]: divideValue(_.get(record, 'convs', 0) * 100, _.get(record, 'clicks', 0)),
        [COLUMNS.MEDIA_COST]: _.get(record, 'mediaCost', 0),
        [COLUMNS.MEDIA_SPENT]: _.get(record, 'mediaSpent', 0),
        [COLUMNS.AGENCY_PROFIT]: _.get(record, 'agcProfit', 0),
        [COLUMNS.SYS_PROFIT]: _.get(record, 'sysProfit', 0),
        [COLUMNS.DATA_COST]: _.get(record, 'dataCost', 0),
        [COLUMNS.SPENT]: _.get(record, 'spent', 0)
      };
    });
  }
}
