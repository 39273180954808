import { CreativeFormData, AbstractFormContentModel } from './FormContentModel';
import { Creative, CREATIVE_LOGO_POSITION, CREATIVE_DISPLAYSIZE } from 'core/creative/Creative';
import i18n from 'i18n';
import _ from 'lodash';
import { renderErrors } from './CreativeFormHintRenderFunction';
import RetailRichMediaProductForm from './RetailRichMediaProductForm';
import { formatBytes } from 'utils/StringUtil';
import { CallToAction } from 'enum/CallToAction';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { BasicFormProps } from './BasicFormProps';

const mimeDB = require('mime-db');

export type RetailRichMediaProductFormProps = {
  readonly model: RetailRichMediaProductFormModel;
} & BasicFormProps;

export class RetailRichMediaProductFormModel extends AbstractFormContentModel {

  public needBannerUrl: boolean = false;

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel
  ) {
    super();
  }

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = () => {
    return RetailRichMediaProductForm;
  }

  validateVideo = (fileData) => {
    const validTypes = ['video/mp4'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];

    const width = fileData.width;
    const height = fileData.height;
    const ratio = (width / height).toFixed(2);
    const targetRatio = (16 / 9).toFixed(2);
    if (targetRatio !== ratio) {
      errors.push(i18n.t<string>('outdoorForm.labels.videoProportionErrorHint', { proportion: '16:9' }));
    }

    if (file.size > 10485760) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '10MB' }));
    }

    const duration = fileData.duration;
    if (duration > 120 || duration < 0) {
      errors.push(i18n.t<string>('fbVideoForm.hints.duration', { min: 0, max: 120 }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validateImage = (fileData) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!fileData || !fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];

    if (file.size > 2097152) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getFormModelData = (creative: Creative): CreativeFormData => {
    const creativeExtra = _.get(creative.basic, 'bannerExtra');
    const callToActionText = _.get(creativeExtra, 'callToAction');
    let callToActionValue;
    Object.values(CallToAction).forEach(callToAction => {
      const enLabel = i18n.getResource('en', 'translation', `callToAction.${_.snakeCase(callToAction)}`);
      const zhLabel = i18n.getResource('zh-TW', 'translation', `callToAction.${_.snakeCase(callToAction)}`);
      if (callToActionText === enLabel || callToActionText === zhLabel) {
        callToActionValue = callToAction;
      }
    });
    const productSetData = _.get(creative.basic, 'productSet');
    const typeProperties = {
      retailer: _.get(creative.basic, 'retail'),
      productSet: _.get(productSetData, 'productSet'),
      productSetId: _.get(productSetData, 'productSetId'),
      callToAction: callToActionValue,
      layout: _.get(creativeExtra, 'layoutId'),
      retailLandingUrl: _.get(creativeExtra, 'retailLandingUrl'),
      displaySize: _.get(creativeExtra, 'displaySize'),
      logoPosition: _.get(creativeExtra, 'logoPosition'),
      maxCount: _.get(creativeExtra, 'maxCount'),
      autoAnimationSeconds: _.get(creativeExtra, 'autoAnimationSeconds')
    };
    const medias = {
      logo: {
        url: _.get(creativeExtra, 'logoUrl')
      },
      video: {
        url: _.get(creativeExtra, 'videoUrl'),
        videoAssetId: _.get(creativeExtra, 'videoAssetId'),
        videoSize: _.get(creativeExtra, 'videoSize'),
        videoChecksum: _.get(creativeExtra, 'checksum')
      }
    };
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues', 'retail', 'productSet', 'productSetId', 'bannerExtra']);
    return {
      basic: {
        ...creativeBasic,
        typeProperties,
        medias
      },
      limitations: creative.limitations
    };
  }

  getInitTypeProperties (): any {
    return {
      layout: undefined,
      productSet: undefined,
      productSetId: undefined,
      maxCount: 4,
      autoAnimationSeconds: 4,
      callToAction: CallToAction.LEARN_MORE,
      displaySize: CREATIVE_DISPLAYSIZE.DEFAULT,
      logoPosition: CREATIVE_LOGO_POSITION.MIDDLE
    };
  }
}
