import React, { useState } from 'react';
import { FormField, FormSection as FormSectionProps } from './FormConfig';
import { compact } from 'lodash';
import cx from 'classnames/bind';
import styles from './formSection.module.scss';
import i18n from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Page } from 'components/Page/Page';
const classNames = cx.bind(styles);

export const FormSection: React.FC<{
  formSection: FormSectionProps
}> = ({
  formSection
}) => {

  const [showAdvanceSection, setShowAdvanceSection] = useState(false);

  const triggerAdvanceSection = () => {
    setShowAdvanceSection(value => !value);
  };

  const formContentClass = classNames('formContent', {
    showHint: !!formSection.hintModal
  });

  const renderFields = (fields: FormField[]) => {
    return compact(fields.map((field: any) => {
      const { component: FieldComponent, props, hide } = field;
      return hide ? undefined : (
        <FieldComponent key={props.name} {...props}/>
      );
    }));
  };

  return (
    <Page.Section title={formSection.title}>
      <div className={formContentClass}>
        <div className={styles.formMainPart}>
          {renderFields(formSection.basicFields)}
        </div>
        {formSection.hintModal}
      </div>
      {formSection.advancedFields &&
        <div className={styles.advancedSection}>
          <div className={styles.titleContainer}>
            <div className={styles.title}>
              {i18n.t<string>('creativeSetupFlow.titles.advancedSetting')}
            </div>
            <div
              className={styles.triggerAdvanceSectionBtn}
              onClick={triggerAdvanceSection}
            >
              <FontAwesomeIcon
                icon={showAdvanceSection ? faAngleUp : faAngleDown}
              />
              <div>
                {showAdvanceSection
                ? i18n.t<string>('creativeSetupFlow.labels.closeAdvanceSetting')
                : i18n.t<string>('creativeSetupFlow.labels.showAdvanceSetting')}
              </div>
            </div>
          </div>
          {showAdvanceSection &&
            renderFields(formSection.advancedFields)
          }
        </div>
      }
    </Page.Section>
  );
};
