import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import styles from './contentWithEyeColumn.module.scss';
import { faEye } from '@fortawesome/free-solid-svg-icons';

export type ContentWithEyeColumnProps = {
  contentData?: {
    component: any;
    props: any;
  },
  tooltipProps: {
    id: string,
    tooltip: string
  },
  onClickBtn: () => void;
};

export const ContentWithEyeColumn = ({
  contentData,
  tooltipProps,
  onClickBtn
}: ContentWithEyeColumnProps) => {
  const Component = contentData?.component;
  return (
    <div className={styles.cell}>
      {contentData && <Component {...contentData.props} />}
      <IconWithTooltip
        placement='right'
        className={styles.hoverBtns}
        onClick={onClickBtn}
        icon={faEye}
        tooltipProps={tooltipProps}
      />
    </div>
  );
};
