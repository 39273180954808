import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { TiktokAdGroup } from 'core/tiktokAdGroup/TiktokAdGroup';

export type CreativeBindData = {
  id: string;
  startDate;
  endDate;
  name;
  adType;
  channel;
  retailer: string;
  draftId?: number;
  billingEvent: string;
};

export function createCreativeBindDataByCampaign (campaign): CreativeBindData {
  return {
    id: campaign.id,
    startDate: campaign.startDate,
    endDate: campaign.endDate,
    name: campaign.name,
    adType: campaign.adType,
    channel: L1ObjectChannel.RTB,
    draftId: campaign.draftId,
    retailer: campaign.retailType,
    billingEvent: 'UNSPECIFIED'
  } as CreativeBindData;
}

export function createCreativeBindDataByFbAdSet (fbAdSet): CreativeBindData {
  return {
    id: fbAdSet.id,
    startDate: fbAdSet.start_time,
    endDate: fbAdSet.end_time,
    name: fbAdSet.name,
    adType: undefined,
    channel: L1ObjectChannel.FB,
    draftId: fbAdSet.draftId,
    billingEvent: fbAdSet.billing_event
  } as CreativeBindData;
}

export function createCreativeBindDataByTiktokAdGroup (tiktokAdGroup: TiktokAdGroup): CreativeBindData {
  return {
    id: tiktokAdGroup.adgroup_id,
    startDate: tiktokAdGroup.schedule_start_time,
    endDate: tiktokAdGroup.schedule_end_time,
    name: tiktokAdGroup.adgroup_name,
    channel: L1ObjectChannel.TIKTOK,
    draftId: tiktokAdGroup.draftId,
    billingEvent: tiktokAdGroup.billing_event
  } as CreativeBindData;
}
