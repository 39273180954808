import { CategoryFormType } from 'core/tenmaxSegment/TenmaxSegment';
import { DefaultTenmaxSegmentManager } from 'core/tenmaxSegment/TenmaxSegmentManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from 'i18n';

const tenmaxSegmentManager = new DefaultTenmaxSegmentManager();

export type CategoryFormModel = {
  title: string,
  loading: boolean,
  initCategory: CategoryFormType | undefined,
  redirectPath: string | undefined,
  handleSubmit: (category: CategoryFormType) => void
};

export const useCreateCategoryFormModel = (sourceId: number) => {
  const { loading, callAPIs } = useCallAPI();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();

  const handleSubmit = useCallback((category: CategoryFormType) => {
    callAPIs([
      () => tenmaxSegmentManager.createCategory(category)
    ], () => {
      setRedirectPath(`/tenmax-segments/sources/${sourceId}`);
    });
  }, [sourceId, callAPIs]);

  const initCategory = useMemo(() => ({
    name: '',
    sourceId: 0
  }), []);

  return {
    title: i18n.t<string>('categoryForm.titles.create'),
    loading,
    initCategory,
    redirectPath,
    handleSubmit
  };
};

export const useEditCategoryFormModel = (sourceId: number) => {
  const { loading, callAPIs } = useCallAPI();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [initCategory, setInitCategory] = useState<CategoryFormType | undefined>();
  const { categoryId } = useParams<{ categoryId: string }>();

  const handleSubmit = useCallback((category: CategoryFormType) => {
    callAPIs([
      () => tenmaxSegmentManager.updateCategory({
        ...category,
        id: +categoryId
      })
    ], () => {
      setRedirectPath(`/tenmax-segments/sources/${sourceId}`);
    });
  }, [sourceId, categoryId, callAPIs]);

  useEffect(() => {
    callAPIs([
      () => tenmaxSegmentManager.getCategory(sourceId, +categoryId)
    ], category => {
      setInitCategory(category);
    });
  }, [sourceId, categoryId, callAPIs]);

  return {
    title: i18n.t<string>('categoryForm.titles.edit'),
    loading,
    initCategory,
    redirectPath,
    handleSubmit
  };
};
