import { LoadingIndicator } from 'components/common/LoadingIndicator';
import React from 'react';
import { useCreditDetailModel } from './CreditDetailModel';
import styles from './creditDetail.module.scss';
import i18n from 'i18n';
import { InfoDisplaySection } from 'components/InfoDisplaySection/InfoDisplaySection';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';

export const CreditDetail: React.FC<{ agencyId: number }> = ({ agencyId }) => {

  const {
    loading,
    creditInfo,
    billInfo,
    columns,
    creditLog
  } = useCreditDetailModel(agencyId);

  return (
    <div className={styles.creditDetail}>
      {loading && <LoadingIndicator />}
      <div className={styles.title}>
        {i18n.t<string>('creditDetail.labels.title')}
      </div>
      <div className={styles.titleBottomLine} />
      <div className={styles.detailArea}>
        <InfoDisplaySection data={creditInfo} columnWidth={300} />
        <div className={styles.sepLine} />
        <InfoDisplaySection data={billInfo} columnWidth={300} />
      </div>
      <div className={styles.table}>
        <StickableBootstrapTable
          stickFirstColumn
          keyField='id'
          data={creditLog}
          columns={columns}
          noDataIndication={i18n.t<string>('creditLog.labels.noData')}
          defaultSorted={[{
            dataField: 'id',
            order: 'desc'
          }]}
        />
      </div>
    </div>
  );
};
