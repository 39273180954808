import { CreativeListRecord, CreativeOfCampaign, CreativeType } from 'core/creative/Creative';
import { NativeCreativeImagePreview } from './NativeCreativeImagePreview';
import { VideoCreativePreview } from './VideoCreativePreview';
import { NormalCreativePreview } from './NormalCreativePreview';
import { PPSCreativePreview } from './PPSCreativePreview';
import { HTML5CreativePreview } from './HTML5CreativePreview';
import { OutdoorCreativePreview } from './OutdoorCreativePreview';
import { OneForAllVideoCreativePreview } from './OneForAllVideoCreativePreview';
import { RetailProductCreativePreview } from './RetailProductCreativePreview';
import { OneForAllDisplayCreativePreview } from './OneForAllDisplayCreativePreview';
import { CustomCreativePreview } from './CustomCreativePreview';

const previewComponentSettings = {
  [CreativeType.NATIVE]: NativeCreativeImagePreview,
  [CreativeType.VIDEO]: VideoCreativePreview,
  [CreativeType.IMAGE]: NormalCreativePreview,
  [CreativeType.HTML5]: HTML5CreativePreview,
  [CreativeType.THIRDPARTY]: PPSCreativePreview,
  [CreativeType.COMBO]: OutdoorCreativePreview,
  [CreativeType.OUTDOOR]: OutdoorCreativePreview,
  [CreativeType.ONE_FOR_ALL]: NormalCreativePreview,
  [CreativeType.ONE_FOR_ALL_DISPLAY]: OneForAllDisplayCreativePreview,
  [CreativeType.ONE_FOR_ALL_DISPLAY_IOS]: OneForAllDisplayCreativePreview,
  [CreativeType.ONE_FOR_ALL_DISPLAY_ANDROID]: OneForAllDisplayCreativePreview,
  [CreativeType.ONE_FOR_ALL_VIDEO]: OneForAllVideoCreativePreview,
  [CreativeType.ONE_FOR_ALL_VIDEO_IOS]: OneForAllVideoCreativePreview,
  [CreativeType.ONE_FOR_ALL_VIDEO_ANDROID]: OneForAllVideoCreativePreview,
  [CreativeType.RETAIL_RICH_MEDIA_PRODUCT]: RetailProductCreativePreview,
  [CreativeType.RETAIL_NATIVE_PRODUCT]: RetailProductCreativePreview,
  [CreativeType.PILOT_TV]: VideoCreativePreview,
  [CreativeType.CTV]: VideoCreativePreview,
  [CreativeType.CUSTOM_RECTANGLE]: PPSCreativePreview,
  [CreativeType.CUSTOM_BOTTOM]: PPSCreativePreview
};

export interface CreativePreviewModel {
  creative: CreativeOfCampaign | CreativeListRecord;
  previewComponent: any;
}

export type CreativePreviewProps = {
  model: CreativePreviewModel
};

export class DefaultCreativePreviewModel implements CreativePreviewModel {

  creative: CreativeOfCampaign | CreativeListRecord;
  previewComponent: any;

  constructor (creative: CreativeOfCampaign | CreativeListRecord) {
    this.creative = creative;
    if (!this.creative.creativeType) {
      this.previewComponent = CustomCreativePreview;
    } else {
      this.previewComponent = previewComponentSettings[this.creative.creativeType];
    }
  }
}
