import { useCallback, useEffect, useMemo, useState } from 'react';
import { RdpManager, DefaultRdpManager } from 'core/rdp/RdpManager';
import { ProductSet } from 'core/product/Product';
import _ from 'lodash';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { useCallAPI } from 'hooks/useCallAPI';
import { useCoreContext } from 'contexts/coreContext';

const defaultRdpManager: RdpManager = new DefaultRdpManager();

export const useProductSetDetailModel = (
  retailId: number | string,
  advertiserId: number | string,
  productSetId: number | string
) => {

  const {
    loading,
    callAPIs
  } = useCallAPI();
  const [productSet, setProductSet] = useState<ProductSet>();
  const [deleteProducts, setDeleteProducts] = useState<() => Promise<void> | undefined>();
  const [submitAlertModalData, setSubmitAlertModalData] = useState<any>();
  const core = useCoreContext();
  const agency = _.get(core, 'accountManager.localeMeta.agencyId', 0);

  const fetchProductSet = useCallback(async () => {
    callAPIs(
      [defaultRdpManager.getProductSetDetail.bind(defaultRdpManager, retailId, productSetId)],
      (productSet: ProductSet) => {
        setProductSet(productSet);
      }
    );
  }, [retailId, productSetId, callAPIs]);

  useEffect(() => {
    fetchProductSet();
  }, [fetchProductSet]);

  const onDeleteModalClose = useCallback((refresh: boolean) => {
    setDeleteProducts(undefined);
    refresh && fetchProductSet();
  }, [fetchProductSet]);

  const deleteFunc = useCallback(async (productsToRemove: (number | string)[], cb?: () => void) => {
    const payload = {
      id: _.get(productSet, 'id', 0),
      name: _.get(productSet, 'name', ''),
      agency: agency,
      advertiser: advertiserId,
      rule: {
        include: productsToRemove
      }
    };
    const removeFunc = defaultRdpManager.updateProductSetRemove.bind(defaultRdpManager, retailId, payload);
    const afterRemove = (productSet: ProductSet) => {
      console.log('productset removed products', productSet);
      toast.success(i18n.t<string>('productSetDetail.labels.removeProductSuccess'));
      onDeleteModalClose(true);
      cb && cb();
    };
    callAPIs([() => defaultRdpManager.getProductSetUsageInfo(productSetId)], (usageData) => {
      if (usageData.length === 0) {
        setDeleteProducts(undefined);
        callAPIs([removeFunc], afterRemove);
      } else {
        setSubmitAlertModalData({
          title: i18n.t<string>('common.warning'),
          usageData: usageData,
          submit: () => {
            callAPIs([removeFunc], (productSet: ProductSet) => {
              afterRemove(productSet);
              setSubmitAlertModalData(undefined);
            });
          },
          cancel: () => {
            onDeleteModalClose(false);
            setSubmitAlertModalData(undefined);
          }
        });
      }
    });
  }, [retailId, productSet, agency, advertiserId, productSetId, onDeleteModalClose, callAPIs]);

  const editActions = useMemo(() => ([{
    label: 'productSetDetail.labels.removeProduct',
    onClick: (selectedProducts: (number | string)[], handleRemoveSelect?: () => void) => {
      setDeleteProducts(_1 => _.partial(deleteFunc, selectedProducts, handleRemoveSelect));
    }
  }]), [deleteFunc]);

  return {
    loading,
    productSet,
    editActions,
    submitAlertModalData,
    deleteProducts,
    onDeleteModalClose
  };
};
