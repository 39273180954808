import React, { Fragment } from 'react';
import { FormControl } from 'react-bootstrap';
import _ from 'lodash';
import styles from './imageInput.module.scss';
import imagePlaceholder from 'assets/image-placeholder.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import i18n from 'i18next';
import classnames from 'classnames/bind';

export class ImageInput extends React.Component<any, any> {

  inputRef: any;
  classNames: any;

  constructor (props) {
    super(props);
    this.inputRef = React.createRef();
    this.classNames = classnames.bind(styles);
    this.state = {
      previewSrc: undefined,
      hovering: false
    };
  }

  componentDidMount () {
    if (this.props.file) {
      this.getImageReader().readAsDataURL(this.props.file);
    } else if (this.props.url) {
      this.setState({
        previewSrc: this.props.url,
        hovering: false
      });
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.file && prevProps.file !== this.props.file) {
      this.getImageReader().readAsDataURL(this.props.file);
    } else if (this.props.url && prevProps.url !== this.props.url) {
      this.setState({
        previewSrc: this.props.url,
        hovering: false
      });
    }
  }

  getImageReader = () => {
    const imageReader = new FileReader();
    imageReader.onload = () => {
      const image = new Image();
      image.src = imageReader.result as string;
      image.onload = () => {
        this.setState({
          previewSrc: image.src,
          hovering: false
        });
      };
    };
    return imageReader;
  }

  onImgPreviewClick = () => {
    this.inputRef.current && this.inputRef.current.click();
  }

  onImgChange = (event) => {
    const files = _.get(event, 'currentTarget.files', []);
    const imageFile = files[0];
    if (!imageFile) {
      return;
    }
    this.props.onChange && this.props.onChange(imageFile);
    this.getImageReader().readAsDataURL(imageFile);
    event.target.value = null;
  }

  onPreviewAreaMouseEnter = () => {
    this.setState({ hovering: true });
  }

  onPreviewAreaMouseLeave = () => {
    this.setState({ hovering: false });
  }

  onClearBtnClicked = (e) => {
    if (this.inputRef.current) {
      this.inputRef.current.value = '';
    }
    this.props.onChange && this.props.onChange(undefined);
    this.setState({
      previewSrc: undefined,
      hovering: false
    });
    e.stopPropagation();
  }

  onDragOver = (event) => {
    event.dataTransfer.dropEffect = 'copy';
    event.preventDefault();
  }

  onDragEnter = (event) => {
    event.stopPropagation();
    event.preventDefault();
  }

  onImgDrop = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const files = _.get(event, 'dataTransfer.files', []);
    const file = files[0];
    if (!file) {
      return;
    }

    this.props.onChange && this.props.onChange(file);
    const validTypes = this.props.validTypes || ['image/jpeg', 'image/jpg', 'image/png'];
    if (validTypes.indexOf(file.type) === -1) {
      this.setState({
        previewSrc: undefined,
        hovering: false
      });
      return;
    }

    this.getImageReader().readAsDataURL(file);
  }

  renderPlaceholder = () => {
    return (
      <div
        className={styles.imagePlaceholder}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDrop={this.onImgDrop}
      >
        <img src={imagePlaceholder} alt={'preview area'}/>
        <div className={styles.hint}>{i18n.t<string>('creativeSetupFlow.labels.dragdropHint')}</div>
      </div>
    );
  }

  renderImagePreview = () => {
    return (
      <div
        className={styles.previewArea}
        onMouseEnter={this.onPreviewAreaMouseEnter}
        onMouseLeave={this.onPreviewAreaMouseLeave}
        onDragOver={this.onDragOver}
        onDragEnter={this.onDragEnter}
        onDrop={this.onImgDrop}
      >
        {
          this.state.hovering &&
          <Fragment>
            <div className={styles.mask}/>
            {this.renderPlaceholder()}
            <div className={styles.closeBtnContainer} onClick={this.onClearBtnClicked}>
              <FontAwesomeIcon
                className={styles.closeBtn}
                icon={faTimes}
              />
            </div>
          </Fragment>
        }
        <img
          style={{
            maxWidth: _.defaultTo(this.props.maxWidth, 500),
            maxHeight: _.defaultTo(this.props.maxHeight, 500)
          }}
          src={this.state.previewSrc}
          alt={'preview area'}
        />
      </div>
    );
  }

  render () {
    const pickProps = _.pick(this.props, [
      'disabled',
      'name'
    ]);
    const classNames = this.classNames('imgInputContainer', {
      [this.props.className]: !!this.props.className
    });
    const accept = this.props.validTypes ? this.props.validTypes.join(',') : 'image/png,image/jpeg,image/jpg';
    return (
      <div className={classNames}>
        <FormControl accept={accept} className={styles.imageInput} type='file' ref={this.inputRef} {...pickProps} onChange={this.onImgChange}/>
        <div
          className={styles.imgPreview}
          onClick={this.onImgPreviewClick}
        >
          {
            this.state.previewSrc ?
              this.renderImagePreview() :
              this.renderPlaceholder()
          }
        </div>
      </div>
    );
  }
}
