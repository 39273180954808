import React, { useCallback } from 'react';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import i18n from 'i18n';
import { SearchBar } from 'components/common/SearchBar';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Link } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { TablePlaceholder } from 'components/TablePlaceholder/TablePlaceholder';
import { notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { compact } from 'lodash';
import { MessageSegmentListColumns, useMessageSegmentListModel } from './MessageSegmentListModel';
import styles from './messageSegmentList.module.scss';

export type MessageSegmentListProps = {
  isAdmin: boolean,
  agencyId: number | string | undefined,
  advertiserId: number | string | undefined,
  canCreateSegment: boolean
};

export const MessageSegmentList: React.FC<MessageSegmentListProps> = ({
  isAdmin,
  agencyId,
  advertiserId,
  canCreateSegment
}) => {

  const {
    loading,
    filteredList,
    columns,
    searchString,
    setSearchString
  } = useMessageSegmentListModel(isAdmin, agencyId, advertiserId);

  const renderSysAdminPlaceholder = () => {
    if (SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY)) {
      return <TablePlaceholder placeholder={i18n.t<string>('messageSegmentList.hints.noData')}/>;
    }
    return <TablePlaceholder placeholder={i18n.t<string>('segmentList.labels.chooseAgency')}/>;
  };

  const renderListNoDataIndication = () => {
    return (
      <PermissionChecker
        permissionAware={notRoles(RoleNames.sysAdmin)}
        renderOtherwise={renderSysAdminPlaceholder}
      >
        <TablePlaceholder placeholder={i18n.t<string>('messageSegmentList.hints.noData')}/>
      </PermissionChecker>
    );
  };

  const renderRowBtns = useCallback((segment) => {
    return compact([
      <Link
        to={`/message-segments/${segment.id}/edit`}
        key='edit-message-segment-btn'
      >
        <IconWithTooltip
          icon={faPencilAlt}
          tooltipProps={{
            id: `messageSegmentList-editTip-${segment.id}`,
            tooltip: i18n.t<string>('messageSegmentList.hints.edit')
          }}
        />
      </Link>
    ]);
  }, []);

  const renderListOperations = useCallback(() => compact([
    canCreateSegment ?
    <Link
      to={'message-segments/new'}
      className='btn btn-tertiary btn-sm'
      key='create-message-segment-btn'
    >
      {i18n.t<string>('messageSegmentList.buttons.create')}
    </Link> :
    undefined,
    <SearchBar
      key='search-message-segment'
      model={{
        placeholder: i18n.t<string>('messageSegmentList.placeholders.searchbar'),
        search: setSearchString,
        defaultValue: searchString
      }}
    />
  ]), [canCreateSegment, searchString, setSearchString]);

  return (
    <div className={styles.tableContainer}>
      {loading && <LoadingIndicator />}
      <StickableBootstrapTable
        stickFirstColumn
        stickLastColumn
        customPadding={false}
        keyField={MessageSegmentListColumns.ID}
        data={filteredList}
        columns={columns}
        noDataIndication={renderListNoDataIndication}
        defaultSorted={[{
          dataField: MessageSegmentListColumns.ID,
          order: 'desc'
        }]}
        renderRowBtns={renderRowBtns}
        renderListOperations={renderListOperations}
        defaultSearchString={searchString}
        onHandleSearch={setSearchString}
      />
    </div>
  );
};
