import { SeparationLine } from 'components/SeparationLine/SeparationLine';
import { InfoDisplaySection, InfoDisplaySectionProps } from './InfoDisplaySection';
import { useCallback } from 'react';
import styles from './infoDisplay.module.scss';

export type InfoDisplayProps = {
  title: string;
  infoSections: InfoDisplaySectionProps[];
  renderEditBtns?: () => React.ReactNode;
};

export const InfoDisplay: React.FC<InfoDisplayProps> = ({
  title,
  infoSections,
  renderEditBtns
}) => {

  const renderSections = useCallback(() => {
    return infoSections.map((infoSection, index) => (
      <InfoDisplaySection
        className={styles.section}
        data={infoSection.data}
        title={infoSection.title}
        columnWidth={infoSection.columnWidth}
        key={index}
      />
    ));
  }, [infoSections]);

  return (
    <div className={styles.infoDisplay}>
      <div className={styles.topArea}>
        <div className={styles.title}>
          {title}
        </div>
        {renderEditBtns &&
          <div className={styles.editArea}>
            {renderEditBtns()}
          </div>
        }
      </div>
      <SeparationLine bold={false}/>
      <div className={styles.information}>
        {renderSections()}
      </div>
    </div>
  );
};
