import { Account } from 'core';
import React from 'react';
import styles from './contactUs.module.scss';
import contactUsImg from 'assets/contact_us.png';

export const ContactUs: React.FC<{
  account: Account
}> = ({
  account
}) => {

  const email = 'customer.service@gogan.io';

  return (
    <div className={styles.contactUs}>
      <div className={styles.content}>
        <img src={contactUsImg} alt={'contact us'}/>
        <p>
          Hi <b>{account.name}</b>,<br/><br/>

          You've successfully signed in PerforMax using facebook Login.<br/>
          However, PerforMax platform is currently limited to our partners.<br/>
          For advertising with PerforMax platform, please contact us: <b>{email}</b>
        </p>
        <a
          className='btn btn-primary btn-sm'
          href={`mailto:${email}`}
        >
          Contact Us
        </a>
      </div>
    </div>
  );
};
