import React from 'react';
import { AdLogoFormProps } from './AdLogoFormModel';
import { connect, FormikContextType, getIn } from 'formik';
import styles from './adLogoForm.module.scss';
import i18n from 'i18next';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import classNames from 'classnames/bind';
import { ImagePreview } from 'components/common/Image/ImagePreview';
import previewBackground from 'assets/campaign/adLogoBackground.jpg';
import defaultAdLogo from 'assets/campaign/tenmax-ad-logo.svg';
import _ from 'lodash';
import { AdLogo, AdLogoImageData, AdLogoType } from 'core/adLogo/AdLogo';
import { FormikField } from 'components/common/form/field/FormikField';

const adLogoTypes = createSelectOptionsFromEnum(AdLogoType, 'adLogoForm.labels.');
const adLogoValidTypes = ['image/jpeg', 'image/jpg', 'image/png'];
const adLogoHint = [
  i18n.t<string>('creativeSetupFlow.labels.typeHint'),
  i18n.t<string>('adLogoForm.labels.sizeHint')
];
export class AdLogoForm extends React.Component<{formik: FormikContextType<{ adLogo: AdLogo }>} & AdLogoFormProps> {

  cssClassNames: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
  }

  openLink = (link) => {
    window.open(link, '_blank', 'noopener');
  }

  renderPreview = ({
    values,
    errors,
    touched
  }) => {
    const adLogo = values.adLogo;
    const type = adLogo.type;
    const customLogo = type === AdLogoType.CUSTOM ? adLogo.image : undefined;
    const error = getIn(errors, 'adLogo.image');
    const touch = getIn(touched, 'adLogo.image');
    const hasError = error !== undefined && touch;
    const defaultLogo = (
      <img
        className={styles.defaultLogo}
        src={defaultAdLogo}
        alt={'default-logo'}
        onClick={_.partial(this.openLink, 'https://www.tenmax.io')}
      />
    );
    return (
      <div className={styles.previewArea}>
        <img src={previewBackground} alt={'logo-background'} />
        {
          type === AdLogoType.DEFAULT ?
            defaultLogo :
            customLogo && !hasError &&
            <div
              className={styles.logoPreview}
              onClick={_.partial(this.openLink, adLogo.link)}
            >
              <ImagePreview
                size={{ width: 32, height: 32 }}
                border={false}
                url={customLogo.url}
                file={customLogo.file}
                config={{
                  showName: false,
                  showSize: false
                }}
              />
            </div>
        }
        <span className={styles.tip}>{i18n.t<string>('adLogoForm.labels.logoHint')}</span>
      </div>
    );
  }

  onTypeChange = (type) => {
    let newAdLogo;
    if (type === AdLogoType.CUSTOM) {
      newAdLogo = {
        link: '',
        image: {}
      };
    } else {
      newAdLogo = {
        link: undefined,
        image: undefined
      };
    }
    this.props.formik.setValues(prev => {
      const oldAdLogo = _.get(prev, 'adLogo', {});
      return {
        ...prev,
        adLogo: {
          ...oldAdLogo,
          ...newAdLogo
        }
      };
    });
    this.props.formik.setFieldTouched('adLogo.link');
    this.props.formik.setFieldTouched('adLogo.image');
  }

  validateLink = (value: string) => {
    const type = this.props.formik.values.adLogo.type;
    return this.props.model.validateLink(value, type);
  }

  validateImage = (value: AdLogoImageData) => {
    const type = this.props.formik.values.adLogo.type;
    return this.props.model.validateAdLogoImage(value, type);
  }

  render () {
    if (!this.props.formik.values.adLogo) {
      return <div />;
    }
    const type = this.props.formik.values.adLogo.type;
    return (
      <fieldset>
        <legend>
          <span>{i18n.t<string>('adLogoForm.labels.title')}</span>
        </legend>
        <div className={styles.component}>
          <FormikField.Select
            className={styles.adLogoType}
            label={i18n.t<string>('adLogoForm.labels.logoType')}
            name='adLogo.type'
            simpleValue
            options={adLogoTypes}
            onChange={this.onTypeChange}
          />
          {
            type === AdLogoType.CUSTOM &&
            <FormikField.Input
              label={i18n.t<string>('adLogoForm.labels.logoLink')}
              name='adLogo.link'
              validate={this.validateLink}
            />
          }
          {
            type === AdLogoType.CUSTOM &&
            <FormikField.FileInput
              label={i18n.t<string>('adLogoForm.labels.logoUpload')}
              name='adLogo.image'
              type='image'
              className={styles.logoInput}
              validTypes={adLogoValidTypes}
              hints={adLogoHint}
              validate={this.validateImage}
            />
          }
          <FormikField.Custom
            label={i18n.t<string>('adLogoForm.labels.logoPreview')}
            render={this.renderPreview}
          />
        </div>
      </fieldset>
    );
  }
}

export default connect<AdLogoFormProps, { adLogo: AdLogo }>(AdLogoForm);
