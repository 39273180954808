import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { MAX_ADDESC_LENGTH, MAX_ADTITLE_LENGTH, MAX_MESSAGE_LENGTH, OneForAllVideoFormProps } from './OneForAllVideoFormModel';
import styles from './oneForAllVideoForm.module.scss';
import _, { defaultTo } from 'lodash';
import cx from 'classnames/bind';
import { FormConfig } from 'components/common/form/FormConfig';
import { CALL_TO_ACTION } from 'core/creative/Creative';
import { FBPageOption } from 'components/FBPageSelect/FBPageOption';
import { FBPageSelectSingleValue } from 'components/FBPageSelect/FBPageSelectSingleValue';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { useFetchFbPageOptions } from './FbPageOptionsHook';
import { FormContent } from 'components/common/form/Form';
import { StorySpacePreview } from './StorySpacePreview';
import { validateEmpty } from 'utils/ValidateUtils';
const classNames = cx.bind(styles);

const callToActionOptions = Object.values(CALL_TO_ACTION).map(callToAction => ({
  label: _.startCase(_.lowerCase(callToAction.toString())),
  value: callToAction
}));

export const OneForAllVideoForm: React.FC<OneForAllVideoFormProps & { formik: FormikContextType<any> }> = (props) => {

  const [formConfig, setFormConfig] = useState<FormConfig>(props.getInitFormConfig());
  const storyVideo = _.get(props.formik.values, 'medias.storyVideo');
  const [useStoryVideo, setUseStoryVideo] = useState(storyVideo ? true : false);
  const advertiserId = props.formik.values.advertiserId;
  const { setFieldValue, setValues } = props.formik;
  const { loading, defaultFbPage, fbPageOptions } = useFetchFbPageOptions(advertiserId, props.model.flowPageModel);

  const onUseStoryVideoChange = useCallback(checked => {
    setValues(prev => ({
      ...prev,
      medias: {
        ...defaultTo(prev.medias, {}),
        storyVideo: checked ? {} : undefined,
        storyVideoThumbnail: checked ? {} : undefined
      }
    }));
    setUseStoryVideo(checked);
  }, [setValues]);

  useEffect(() => {
    if (defaultFbPage) {
      setFieldValue('typeProperties.pageId', defaultFbPage);
    }
  }, [defaultFbPage, setFieldValue]);

  const {
    validateRemainingCharacters,
    validateMinimumTextLength,
    validateVideo,
    validateStoryVideo,
    validateVideoThumbnail
  } = props.model;

  const medias = props.formik.values.medias;
  const videoWidth = _.get(medias, 'video.width', 1);
  const videoHeight = _.get(medias, 'video.height', 1);
  const videoProportion = (videoWidth / videoHeight).toFixed(2);

  useEffect(() => {
    const basicFields = [...props.basicFields];
    const bannerUrlField = basicFields.find(field => field.props.name === 'bannerUrl');
    if (bannerUrlField) {
      bannerUrlField.props.label = i18n.t<string>('creativeSetupFlow.labels.oneForAllClickUrl');
    }
    const storyVideoThumbClassName = classNames(styles.imageInput, {
      isStory: true
    });
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder(basicFields)
            .addFormikSelect({
              label: i18n.t<string>('creativeSetupFlow.labels.fbPage'),
              simpleValue: true,
              name: 'typeProperties.pageId',
              optionComponent: FBPageOption,
              singleValue: FBPageSelectSingleValue,
              fieldContentWidth: 'auto',
              options: fbPageOptions,
              validate: validateEmpty
            })
            .addFormikSelect({
              label: i18n.t<string>('creativeSetupFlow.labels.callToAction'),
              simpleValue: true,
              name: 'typeProperties.callToAction',
              options: callToActionOptions,
              validate: validateEmpty
            })
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.fbVideo.title'),
              name: 'typeProperties.title',
              hint: i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: props.model.adTitleRemainLength }),
              onChange: props.model.handleAdTitleChange,
              validate: value => validateRemainingCharacters(value, MAX_ADTITLE_LENGTH, false)
            })
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.message'),
              name: 'typeProperties.message',
              hint: i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: props.model.adMessageRemainLength }),
              onChange: props.model.handleAdMessageChange,
              validate: value => validateRemainingCharacters(value, MAX_MESSAGE_LENGTH, true)
            })
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.shortMessage'),
              name: 'typeProperties.shortMessage',
              hint: i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: props.model.adShortMessageRemainLength }),
              onChange: props.model.handleAdShortMessageChange,
              validate: value => validateMinimumTextLength(value, 12)
            })
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.fbVideo.desc'),
              name: 'typeProperties.description',
              hint: i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: props.model.adDescriptionRemainLength }),
              onChange: props.model.handleAdDescriptionChange,
              validate: value => validateRemainingCharacters(value, MAX_ADDESC_LENGTH, false)
            })
            .addFormikFileInput({
              type: 'video',
              className: styles.videoInput,
              label: i18n.t<string>('creativeSetupFlow.labels.fbVideo.video'),
              name: 'medias.video',
              maxWidth: 300,
              hints: [
                i18n.t<string>('creativeSetupFlow.labels.videoTypeHint'),
                i18n.t<string>('creativeSetupFlow.hints.oneForAllVideo.ratio'),
                i18n.t<string>('creativeSetupFlow.hints.oneForAllVideo.resolution'),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '500MB' }),
                i18n.t<string>('fbVideoForm.hints.duration', { min: 5, max: 60 })
              ],
              validate: validateVideo
            })
            .addFormikFileInput({
              type: 'image',
              label: i18n.t<string>('creativeSetupFlow.labels.fbVideo.thumbnail'),
              name: 'medias.videoThumbnail',
              className: styles.imageInput,
              fieldContentWidth: 'max-content',
              hints: [
                i18n.t<string>('creativeSetupFlow.labels.typeHint'),
                i18n.t<string>('creativeSetupFlow.hints.fbVideo.thumbnailProportion'),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
              ],
              validate: value => validateVideoThumbnail(value, videoProportion)
            })
            .addCheckbox({
              label: '',
              name: 'useStoryVideo',
              checkboxLabel: i18n.t<string>('oneForAllDisplayForm.labels.useStoryLikeImage'),
              checked: useStoryVideo,
              onChange: onUseStoryVideoChange,
              postfix: <StorySpacePreview/>
            })
            .addFormikFileInput({
              type: 'video',
              maxWidth: 180,
              maxHeight: 320,
              className: styles.storyVideoInput,
              label: i18n.t<string>('creativeSetupFlow.labels.fbStoryVideo.video'),
              name: 'medias.storyVideo',
              hints: [
                i18n.t<string>('creativeSetupFlow.labels.videoTypeHint'),
                i18n.t<string>('creativeSetupFlow.hints.oneForAllVideo.storyRatio'),
                i18n.t<string>('creativeSetupFlow.hints.oneForAllVideo.storyResolution'),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '250MB' }),
                i18n.t<string>('fbVideoForm.hints.duration', { min: 9, max: 60 })
              ],
              validate: value => value ? validateStoryVideo(value) : undefined
            }, !useStoryVideo)
            .addFormikFileInput({
              type: 'image',
              className: storyVideoThumbClassName,
              label: i18n.t<string>('creativeSetupFlow.labels.fbStoryVideo.thumbnail'),
              name: 'medias.storyVideoThumbnail',
              fieldContentWidth: 'max-content',
              hints: [
                i18n.t<string>('creativeSetupFlow.labels.typeHint'),
                i18n.t<string>('creativeSetupFlow.hints.fbVideo.thumbnailProportion'),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
              ],
              validate: value => validateVideoThumbnail(value, (9 / 16).toFixed(2))
            }, !useStoryVideo)
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      )
      .build()
    );
  }, [
    fbPageOptions,
    props.basicFields,
    props.model.adDescriptionRemainLength,
    props.model.adMessageRemainLength,
    props.model.adTitleRemainLength,
    props.model.adShortMessageRemainLength,
    props.model.handleAdDescriptionChange,
    props.model.handleAdMessageChange,
    props.model.handleAdTitleChange,
    props.model.handleAdShortMessageChange,
    useStoryVideo,
    videoProportion,
    onUseStoryVideoChange,
    setFormConfig,
    validateRemainingCharacters,
    validateMinimumTextLength,
    validateVideo,
    validateStoryVideo,
    validateVideoThumbnail
  ]);

  return (
    <div>
      {loading && <LoadingIndicator/>}
      <FormContent
        formConfig={formConfig}
      />
    </div>
  );
};

export default connect(OneForAllVideoForm);
