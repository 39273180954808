import React from 'react';
import i18n from 'i18n';

import { CustomInputComponentProps, CustomInputComponentState } from './CustomInputComponentModel';
import styles from './customInputComponent.module.scss';

class CustomInputInternalModel {

  input: string;

  constructor () {
    this.input = '';
  }

  get state (): CustomInputComponentState {
    return {
      input: this.input
    };
  }

  onChanged (input: string): CustomInputComponentState {
    this.input = input;
    return this.state;
  }
}

export class CustomInputComponent extends React.Component<CustomInputComponentProps, CustomInputComponentState> {

  ref: any;
  stateModel: CustomInputInternalModel;

  constructor (props: any) {
    super(props);
    this.ref = React.createRef();
    this.stateModel = new CustomInputInternalModel();
    this.state = this.stateModel.state;
  }

  changed = () => {
    this.setState(this.stateModel.onChanged(this.ref.current.value));
  }

  clear = () => {
    this.ref.current.value = '';
    this.setState(this.stateModel.onChanged(''));
  }

  send = () => {
    this.props.model.onChange(this.ref.current.value);
    this.clear();
  }

  render () {
    return (
      <div className={styles.customInputContainer}>
        <div className={styles.descriptionArea}>
          <div className={styles.leftDescription}>
            {this.props.model.leftDescription}
          </div>
          <div className={styles.rightDescription}>
            {this.props.model.rightDescription}
          </div>
        </div>
        <textarea
          ref={this.ref}
          onChange={this.changed}
          placeholder={this.props.model.inputPlaceholder}
          value={this.state.input}
        />
        <div className={styles.buttonArea}>
        <div className={styles.addButton} onClick={this.send}>
            {i18n.t<string>('limitation.buttons.addDomain')}
          </div>
          <div className={styles.cancelButton} onClick={this.clear}>
            {i18n.t<string>('limitation.buttons.cancelAddDomain')}
          </div>
        </div>
      </div>
    );
  }
}
