import React from 'react';
import styles from './doctors.module.scss';
import { DoctorChart } from 'components/DoctorChart/DoctorChart';
import { Button } from 'react-bootstrap';
import i18n from 'i18n';
import { DoctorsProps, DoctorsState } from './DoctorsModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { DoctorTable } from 'containers/DoctorTable/DoctorTable';
import { ErrorBoundary } from 'components/common/ErrorBoundary/ErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { InputField } from 'components/common/form/field/InputField';
import { SelectField } from 'components/common/form/field/SelectField';
import { CustomField } from 'components/common/form/field/CustomField';

export class Doctors extends React.Component<DoctorsProps, DoctorsState> {

  handler?: number;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
  }

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  render () {
    const tableModel = this.props.model.getTableModel();
    return (
      <ErrorBoundary errorCode={ERROR_CODE.DOCTOR}>
        <div className={styles.doctors}>
          {this.state.loading && <LoadingIndicator/>}
          <div className={styles.container}>
            <div className={styles.titleArea}>
              <div className={styles.title}>
                {i18n.t<string>('doctors.labels.title')}
              </div>
            </div>
            <div className={styles.contentArea}>
              <div className={styles.inputArea}>
                <InputField
                  label={i18n.t<string>('doctor.labels.campaignId')}
                  name='doctorCampaignId'
                  className={styles.campaignId}
                  onBlur={this.props.model.setCampaign}
                  placeholder={i18n.t<string>('doctors.labels.campaignIdPlaceholder')}
                />
                {
                  this.state.creativeId &&
                  <SelectField
                    label={i18n.t<string>('doctorTable.labels.creativeTitle')}
                    name='doctorCreativeId'
                    options={this.state.creativeOptions ? this.state.creativeOptions : []}
                    simpleValue
                    value={this.state.creativeId}
                    onChange={this.props.model.setCreative}
                  />
                }
                <CustomField
                  label=''
                  name='checkButton'
                  formGroupClassName={styles.checkBtnContainer}
                >
                  <Button
                    variant='primary'
                    size='sm'
                    disabled={!this.state.campaignId || !this.state.creativeId}
                    onClick={this.props.model.check}
                  >
                    {i18n.t<string>('report.buttons.search')}
                  </Button>
                </CustomField>
              </div>
              {
                this.state.doctorTableData.length > 0 &&
                <>
                  <div className={styles.chartArea}>
                    <DoctorChart chartData={this.state.doctorTableData}/>
                  </div>
                  { tableModel &&
                    <DoctorTable model={tableModel}/>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </ErrorBoundary>
    );
  }
}
