import { L1ObjectObjective } from 'core/l1Object/L1Object';

export enum FbObjective {
  OUTCOME_AWARENESS = 'OUTCOME_AWARENESS',
  OUTCOME_TRAFFIC = 'OUTCOME_TRAFFIC',
  OUTCOME_SALES = 'OUTCOME_SALES',
  OUTCOME_ENGAGEMENT = 'OUTCOME_ENGAGEMENT',
  OUTCOME_LEADS = 'OUTCOME_LEADS',
  // OUTCOME_APP_PROMOTION = 'OUTCOME_APP_PROMOTION'
  // legacy objectives
  REACH = 'REACH',
  LINK_CLICKS = 'LINK_CLICKS',
  CONVERSIONS = 'CONVERSIONS'
}

export enum FbEffectiveStatus {
  ACTIVE = 'ACTIVE',
  PAUSED = 'PAUSED',
  DELETED = 'DELETED',
  ARCHIVED = 'ARCHIVED',
  IN_PROCESS = 'IN_PROCESS',
  WITH_ISSUES = 'WITH_ISSUES'
}

export type FBCampaign = {
  campaign_id: string | number | null,
  account_id: string | number;
  bid_strategy: string;
  objective: L1ObjectObjective;
  effective_status: FbEffectiveStatus;
};
