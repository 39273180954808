import client from './RestClient';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { AxiosInstance } from 'axios';

export type BindingCreateRequest = {
  l2ChannelIds?: string[],
  l3ChannelIds?: string[],
  goCreativeIds: string[],
  active: boolean,
  draft: boolean
}[];

export interface BindingWebService {
  createBinding (channel: L1ObjectChannel, createRequest: BindingCreateRequest): Promise<void>;
  updateBinding (channel: L1ObjectChannel, updateRequest: BindingCreateRequest): Promise<void>;
}

export class RestfulBindingWebService implements BindingWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async createBinding (channel: L1ObjectChannel, createRequest: BindingCreateRequest): Promise<void> {
    return this.restClient.post(`/v2/l3-object/${channel}/create`, createRequest);
  }

  async updateBinding (channel: L1ObjectChannel, updateRequest: BindingCreateRequest): Promise<void> {
    return this.restClient.put(`/v2/l3-object/${channel}/update`, updateRequest);
  }
}
