import i18n from 'i18n';
import _ from 'lodash';

import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { SelectOptions } from 'components/common/commonType';

export interface CustomInputComponentModel {
  readonly leftDescription: string;
  readonly rightDescription: string;
  readonly inputPlaceholder: string;
  readonly event: UpdateEventListener<CustomInputComponentModel>;
  readonly customSelectOptions: SelectOptions[];
  onChange: (value: string) => void;
}

export type CustomInputComponentProps = {
  readonly model: CustomInputComponentModel;
};

export type CustomInputComponentState = {
  readonly input: string;
};

abstract class AbstractCustomInputComponentModel implements CustomInputComponentModel {

  leftDescription: string;
  rightDescription: string;
  inputPlaceholder: string;
  event: FireableUpdateEventListener<CustomInputComponentModel>;
  customSelectOptions: SelectOptions[];

  constructor (leftDescription: string, rightDescription: string, inputPlaceholder: string) {
    this.leftDescription = leftDescription;
    this.rightDescription = rightDescription;
    this.inputPlaceholder = inputPlaceholder;
    this.event = new FireableUpdateEventListener<CustomInputComponentModel>();
    this.customSelectOptions = [];
  }

  onChange (input) {
    const handledInput = input.replace(/\n$/, '').trim();
    if (!handledInput || handledInput === '') {
      return;
    }
    this.customSelectOptions = _.map(handledInput.split('\n'), (customInput) => {
      let customInputs = customInput.split(',');
      if (customInputs.length === 2) {
        return {
          label: customInputs[1].trim(),
          value: customInputs[0].trim()
        };
      }

      return {
        label: customInput,
        value: customInput
      };
    });

    this.event.fireEvent(this);
  }
}

export class SpaceCustomInputComponentModel extends AbstractCustomInputComponentModel {

  constructor () {
    super(i18n.t<string>('limitation.addSpace'), i18n.t<string>('limitation.addSpaceTip'), i18n.t<string>('limitation.placeholders.addSpace'));
  }
}

export class DomainCustomInputComponentModel extends AbstractCustomInputComponentModel {

  constructor () {
    super(i18n.t<string>('limitation.addDomain'), i18n.t<string>('limitation.addDomainTip'), i18n.t<string>('limitation.placeholders.addDomain'));
  }
}
