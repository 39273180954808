import { Agency } from 'core';
import { ColumnDefinition, sortableColumn, SortDescriptor } from 'components/TableColumn/TableColumn';
import { Pagination } from 'core/pagination/Pagination';
import { TableChangeHandler } from 'react-bootstrap-table-next';

export interface AgencyListModel {

  readonly idColumn: ColumnDefinition;
  readonly nameColumn: ColumnDefinition;
  readonly currencyColumn: ColumnDefinition;
  readonly timeZoneColumn: ColumnDefinition;
  readonly editBtnsColumn: ColumnDefinition;

  readonly agencies: Array<Agency>;
  readonly pagination: Pagination;
  readonly onListChange?: TableChangeHandler<Agency>;
  readonly noDataDescription: string;
  readonly defaultSorts: SortDescriptor;
}

export type AgencyListProps = {

  readonly model: AgencyListModel;
};

export enum AgencyListColumns {
  AGENCY_ID = 'id',
  VENDOR_NUMBER = 'vendorNumber',
  TIME_ZONE = 'timeZone',
  CURRENCY = 'currency',
  EDITBTN = 'editBtn'
}

export class DefaultAgencyListModel implements AgencyListModel {
  agencies: Array<Agency>;
  pagination: Pagination;
  onListChange?: TableChangeHandler<Agency>;

  constructor (agencies: Array<Agency>, pagination: Pagination, onListChange?: TableChangeHandler<Agency>) {
    this.agencies = agencies;
    this.pagination = pagination;
    this.onListChange = onListChange;
  }

  get noDataDescription (): string {
    return 'agencies.noDataAvailable';
  }

  get idColumn (): ColumnDefinition {
    return sortableColumn(AgencyListColumns.AGENCY_ID, 'agencies.headers.id');
  }

  get nameColumn (): ColumnDefinition {
    return sortableColumn(AgencyListColumns.AGENCY_ID, 'agencies.headers.name');
  }

  get timeZoneColumn (): ColumnDefinition {
    return sortableColumn(AgencyListColumns.TIME_ZONE, 'agencies.headers.timeZone');
  }

  get currencyColumn (): ColumnDefinition {
    return sortableColumn(AgencyListColumns.CURRENCY, 'agencies.headers.currency');
  }

  get editBtnsColumn (): ColumnDefinition {
    return sortableColumn(AgencyListColumns.EDITBTN, '', false);
  }

  get defaultSorts (): SortDescriptor {
    return [{
      dataField: 'id',
      order: 'asc'
    }];
  }
}
