import { useCallback, useEffect, useMemo, useState } from 'react';
import { SelectOptions } from 'components/common/commonType';
import { DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { RdpManager, DefaultRdpManager } from 'core/rdp/RdpManager';
import { ProductSet } from 'core/product/Product';
import { NewProductSetFormModel, AddToExistProductSetFormModel } from './ProductSetFormModel';
import { useCallAPI } from 'hooks/useCallAPI';
import i18n from 'i18n';
import { useCoreContext } from 'contexts/coreContext';
import { get } from 'lodash';

export enum PRODUCT_SET_CREATE_TYPE {
  NEW = 'new',
  ADD_TO_EXIST = 'addToExist'
}

const advertiserManager = new DefaultAdvertiserManager();
const defaultRdpManager: RdpManager = new DefaultRdpManager();
export const useProductSetCreateModalModel = (
  retailId: string,
  selectedProducts: any[],
  closeModal: () => void
) => {

  const {
    loading,
    callAPIs
  } = useCallAPI();
  const [createType, setCreateType] = useState(PRODUCT_SET_CREATE_TYPE.NEW);
  const [advertiserOptions, setAdvertiserOptions] = useState<SelectOptions[]>([]);
  const [productSetOptions, setProductSetOptions] = useState<SelectOptions[]>([]);
  const [submitAlertModalData, setSubmitAlertModalData] = useState<any>();
  const core = useCoreContext();
  const agency = get(core, 'accountManager.localeMeta.agencyId', 0);

  const fetchAdvertisers = useCallback(async () => {
    callAPIs(
      [advertiserManager.getAdvertiserOptions.bind(advertiserManager)],
      (advertisers: SelectOptions[]) => {
        setAdvertiserOptions(advertisers);
      }
    );
  }, [callAPIs]);

  const fetchProductSetsOfAdvertiser = useCallback(async (advertiser: number | string | undefined) => {
    if (!advertiser) {
      setProductSetOptions([]);
    } else {
      if (createType === PRODUCT_SET_CREATE_TYPE.ADD_TO_EXIST) {
        callAPIs(
          [defaultRdpManager.getRetailProductSets.bind(defaultRdpManager, retailId, advertiser)],
          (productSets: ProductSet[]) => {
            setProductSetOptions(productSets.map(productSet => ({
              label: productSet.name,
              value: productSet.id,
              extra: productSet.rule
            })));
          }
        );
      }
    }
  }, [retailId, createType, callAPIs]);

  const formModel = useMemo(() => {
    if (createType === PRODUCT_SET_CREATE_TYPE.NEW) {
      return new NewProductSetFormModel(
        advertiserOptions,
        fetchProductSetsOfAdvertiser
      );
    } else {
      return new AddToExistProductSetFormModel(
        advertiserOptions,
        productSetOptions,
        fetchProductSetsOfAdvertiser
      );
    }
  }, [createType, advertiserOptions, productSetOptions, fetchProductSetsOfAdvertiser]);

  useEffect(() => {
    fetchAdvertisers();
  }, [fetchAdvertisers]);

  const submit = useCallback((values) => {
    if (!formModel.ruleChanged(values, selectedProducts)) {
      closeModal();
      return;
    }
    callAPIs([() => formModel.getProductSetUsageData(values.productSet)], (usageData) => {
      if (usageData.length === 0) {
        callAPIs([() => formModel.submit(values, agency, retailId, selectedProducts)], () => closeModal());
      } else {
        setSubmitAlertModalData({
          title: i18n.t<string>('common.warning'),
          usageData: usageData,
          submit: () => {
            callAPIs([() => formModel.submit(values, agency, retailId, selectedProducts)], () => {
              setSubmitAlertModalData(undefined);
              closeModal();
            });
          },
          cancel: async () => {
            setSubmitAlertModalData(undefined);
          }
        });
      }
    });
  }, [callAPIs, closeModal, formModel, agency, retailId, selectedProducts]);

  return {
    loading: loading,
    formModel,
    createType,
    submitAlertModalData,
    submit,
    setCreateType: (createType) => {
      setCreateType(createType);
      setProductSetOptions([]);
    }
  };
};
