import React from 'react';
import { faChartArea, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { Link } from 'react-router-dom';
import styles from './l1ObjectList.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { L1ObjectState, L1Object, L1ObjectChannel, L1ObjectObjective, L2ObjectRunningStatus } from 'core/l1Object/L1Object';
import { Status, getEffectiveStatusDefaultColor } from 'components/Status/Status';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { RoleNames } from 'core/auth/Account';
import { hasFuncs, isPmax3Order, notRoles, notSelfServeAdObject } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { TiktokOptStatus, TiktokStatus, TiktokStatusDetail } from 'core/tiktokCampaign/TiktokCampaign';
import moment from 'moment';
import Progress from 'components/common/Progress/Progress';
import { Currency } from 'core';
import { Order } from 'core/order/Order';

export const getRtbCampaignGroupStatusDesData = (l1Object: L1Object, order: Order) => {
  let des = '';
  let color: any;
  const budget = l1Object.budget ? l1Object.budget : 0;
  const spent = _.get(l1Object, 'spent', 0);
  switch (l1Object.state) {
    case L1ObjectState.DEACTIVATE:
      des = i18n.t<string>('l1ObjectList.labels.deactivateState');
      color = 'black';
      break;
    case L1ObjectState.DELETE:
      des = i18n.t<string>('l1ObjectList.labels.deleteState');
      color = 'danger';
      break;
    case L1ObjectState.ACTIVE:
      if (moment().isAfter(moment(order.endDate).endOf('day'))) {
        des = i18n.t<string>('l1ObjectList.labels.endState');
        color = 'light';
        break;
      }
      if (!l1Object.l2ObjectMinStartDate && !l1Object.l2ObjectMaxEndDate) {
        des = i18n.t<string>('l1ObjectList.labels.notStartState');
        color = 'light';
      } else if (moment().isBefore(moment(_.get(l1Object, 'l2ObjectMinStartDate')))) {
        des = i18n.t<string>('l1ObjectList.labels.notStartState');
        color = 'light';
      } else if (moment().isAfter(moment(_.get(l1Object, 'l2ObjectMaxEndDate'))) && (spent >= +budget)) {
        des = i18n.t<string>('l1ObjectList.labels.endState');
        color = 'light';
      } else {
        des = i18n.t<string>('l1ObjectList.labels.activeState');
      }
      break;
    default:
      des = i18n.t<string>('l1ObjectList.labels.activeState');
      break;
  }

  return {
    des,
    color
  };
};

export const getRtbCampaignGroupDeliveryDesDatas = (l1Object: L1Object) => {
  let des = '';
  let color: string;
  const deliveries = _.omit(l1Object.l2ObjectRunningStatus, [
    L2ObjectRunningStatus.BUDGET_NOT_ALLOCATED,
    L2ObjectRunningStatus.BUDGET_REMAIN,
    L2ObjectRunningStatus.ACTUAL_PROGRESS,
    L2ObjectRunningStatus.EXPECTED_PROGRESS,
    L2ObjectRunningStatus.BUDGET,
    L2ObjectRunningStatus.SPENT
  ]);
  const sortedDeliveries = _.keys(deliveries)
    .filter(key => deliveries[key] as number > 0)
    .sort((dataA, dataB) => {
      const stateDes: string[] = [
        L2ObjectRunningStatus.AHEAD,
        L2ObjectRunningStatus.SEVERELY_BEHIND,
        L2ObjectRunningStatus.BEHIND,
        L2ObjectRunningStatus.NO_CREATIVES,
        L2ObjectRunningStatus.NOT_START,
        L2ObjectRunningStatus.NORMAL,
        L2ObjectRunningStatus.INACTIVE,
        L2ObjectRunningStatus.END
      ];
      const dataAToCompare = stateDes.indexOf(dataA);
      const dataBToCompare = stateDes.indexOf(dataB);
      return dataAToCompare > dataBToCompare ? 1 : -1;
    });

  return _.map(sortedDeliveries, (key) => {
    switch (key) {
      case L2ObjectRunningStatus.NOT_START:
        des = i18n.t<string>('l1ObjectList.labels.delivery.notStart');
        color = 'light';
        break;
      case L2ObjectRunningStatus.NO_CREATIVES:
        des = i18n.t<string>('l1ObjectList.labels.delivery.noCreatives');
        color = 'black';
        break;
      case L2ObjectRunningStatus.NORMAL:
        des = i18n.t<string>('l1ObjectList.labels.delivery.normal');
        color = 'success';
        break;
      case L2ObjectRunningStatus.BEHIND:
        des = i18n.t<string>('l1ObjectList.labels.delivery.behind');
        color = 'warning';
        break;
      case L2ObjectRunningStatus.SEVERELY_BEHIND:
        des = i18n.t<string>('l1ObjectList.labels.delivery.severelyBehind');
        color = 'deep-warning';
        break;
      case L2ObjectRunningStatus.AHEAD:
        des = i18n.t<string>('l1ObjectList.labels.delivery.ahead');
        color = 'danger';
        break;
      case L2ObjectRunningStatus.INACTIVE:
        des = i18n.t<string>('l1ObjectList.labels.delivery.inactive');
        color = 'black';
        break;
      case L2ObjectRunningStatus.END:
        des = i18n.t<string>('l1ObjectList.labels.delivery.end');
        color = 'light';
        break;
      default:
        break;
    }

    return {
      des,
      color,
      value: deliveries[key]
    };
  });
};

export const getMessageCampaignGroupDeliveryDesDatas = (l1Object) => {
  let des = '';
  let color: string;
  const deliveries = _.omit(l1Object.l2ObjectRunningStatus, [
    L2ObjectRunningStatus.ACTUAL_PROGRESS,
    L2ObjectRunningStatus.EXPECTED_PROGRESS,
    L2ObjectRunningStatus.BUDGET,
    L2ObjectRunningStatus.SPENT
  ]);
  const sortedDeliveries = _.keys(deliveries)
    .filter(key => deliveries[key] as number > 0)
    .sort((dataA, dataB) => {
      const stateDes: string[] = [
        L2ObjectRunningStatus.ABANDON,
        L2ObjectRunningStatus.ABNORMAL,
        L2ObjectRunningStatus.DRAFT,
        L2ObjectRunningStatus.ACTIVE,
        L2ObjectRunningStatus.INACTIVE,
        L2ObjectRunningStatus.SCHEDULED,
        L2ObjectRunningStatus.FINISHED
      ];
      const dataAToCompare = stateDes.indexOf(dataA);
      const dataBToCompare = stateDes.indexOf(dataB);
      return dataAToCompare > dataBToCompare ? 1 : -1;
    });

  return _.map(sortedDeliveries, (key) => {
    switch (key) {
      case L2ObjectRunningStatus.DRAFT:
        des = i18n.t<string>('l1ObjectList.labels.delivery.notStart');
        color = 'light';
        break;
      case L2ObjectRunningStatus.ACTIVE:
        des = i18n.t<string>('l1ObjectList.labels.delivery.active');
        color = 'theme1';
        break;
      case L2ObjectRunningStatus.INACTIVE:
        des = i18n.t<string>('l1ObjectList.labels.delivery.inactive');
        color = 'black';
        break;
      case L2ObjectRunningStatus.SCHEDULED:
        des = i18n.t<string>('l1ObjectList.labels.delivery.scheduled');
        color = 'light';
        break;
      case L2ObjectRunningStatus.ABANDON:
        des = i18n.t<string>('l1ObjectList.labels.delivery.abandon');
        color = 'danger';
        break;
      case L2ObjectRunningStatus.ABNORMAL:
        des = i18n.t<string>('l1ObjectList.labels.delivery.abnormal');
        color = 'warning';
        break;
      case L2ObjectRunningStatus.FINISHED:
        des = i18n.t<string>('l1ObjectList.labels.delivery.end');
        color = 'light';
        break;
      default:
        break;
    }

    return {
      des,
      color,
      value: deliveries[key]
    };
  });
};

const getFbCampaignStatusDesData = (l1Object) => {
  const configuredStatus = _.get(l1Object, 'fb.configured_status', '');
  let des = _.startCase(_.lowerCase(configuredStatus));
  let color = getEffectiveStatusDefaultColor(configuredStatus);
  switch (configuredStatus) {
    case 'PAUSED':
      des = i18n.t<string>('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case 'DELETED':
    case 'ARCHIVED':
      des = i18n.t<string>('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case 'ACTIVE':
      des = i18n.t<string>('campaignList.labels.activateState');
      break;
    default:
      break;
  }

  return {
    des,
    color
  };
};

const getFbCampaignEffectiveStatusDesData = (l1Object) => {
  const effectiveStatusDesData: {
    des: string,
    color: string,
    value?: string | number | boolean
  }[] = getRtbCampaignGroupDeliveryDesDatas(l1Object);

  const effectiveStatus = _.get(l1Object, 'fb.effective_status', '');
  // already show in configured_status
  if (['PAUSED', 'ACTIVE', 'DELETED', 'ARCHIVED'].includes(effectiveStatus)) {
    return effectiveStatusDesData;
  }
  effectiveStatusDesData.push({
    des: _.startCase(_.lowerCase(effectiveStatus)),
    color: getEffectiveStatusDefaultColor(effectiveStatus)
  });
  return effectiveStatusDesData;
};

const getTiktokCampaignStatusDesData = (l1Object) => {
  const optStatus = _.get(l1Object, 'tiktok.opt_status', '');
  let des = _.startCase(_.lowerCase(optStatus));
  let color = getEffectiveStatusDefaultColor(optStatus);
  switch (optStatus) {
    case TiktokOptStatus.DISABLE:
      des = i18n.t<string>('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case TiktokOptStatus.DELETE:
      des = i18n.t<string>('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case TiktokOptStatus.ENABLE:
      des = i18n.t<string>('campaignList.labels.activateState');
      break;
    default:
      break;
  }

  return {
    des,
    color
  };
};

const getTiktokCampaignDeliveryStatusDesData = (l1Object) => {
  const status = _.get(l1Object, 'tiktok.status', '');
  let des = _.startCase(_.lowerCase(status));
  let color = getEffectiveStatusDefaultColor(status);
  switch (status) {
    case TiktokStatus.CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY:
    case TiktokStatus.CAMPAIGN_STATUS_ADVERTISER_AUDIT:
    case TiktokStatus.ADVERTISER_CONTRACT_PENDING:
      des = i18n.t<string>('campaignList.labels.withIssues');
      color = 'danger';
      break;
    case TiktokStatus.CAMPAIGN_STATUS_BUDGET_EXCEED:
    case TiktokStatus.CAMPAIGN_STATUS_DISABLE:
      des = i18n.t<string>('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case TiktokStatus.CAMPAIGN_STATUS_DELETE:
      des = i18n.t<string>('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case TiktokStatus.CAMPAIGN_STATUS_ENABLE:
      des = i18n.t<string>('campaignList.labels.activateState');
      break;
    default:
      break;
  }

  return [{
    des,
    color
  }];
};

const getTiktokStatusTooltip = (l1Object) => {
  const status = _.get(l1Object, 'tiktok.status', '');
  return status ? `${TiktokStatusDetail[status]}` : undefined;
};

const nameHeaderFormatter = (
  editableL1ObjectLength: number,
  selectedL1Objects: (number | string)[],
  orderId,
  onSelectAll,
  column: any,
  _1,
  { sortElement }
) => {
  return (
    <div className={styles.nameHeader}>
      <div className={styles.selectCheckBox}>
        {editableL1ObjectLength > 0 &&
          <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE).and(isPmax3Order(orderId))}>
            <input
              type='checkbox'
              checked={editableL1ObjectLength === selectedL1Objects.length}
              ref={el => el && (el.indeterminate = editableL1ObjectLength !== selectedL1Objects.length && selectedL1Objects.length > 0)}
              onChange={onSelectAll}
              id={'input0'}
            />
            <label htmlFor='input0' />
          </PermissionChecker>
        }
      </div>
      {i18n.t<string>(column.text)}
      {sortElement}
    </div>
  );
};

const nameFormatter = (l1ObjectId: any, l1Object, _2, formatExtraData: any): any => {
  const { selectedL1Objects, onSelect, permissionAwareGetter } = formatExtraData;
  const l1ObjectDetailReadPermission = notRoles(RoleNames.agencyReport);
  const renderLabel = () => <span className={styles.name}>{l1Object.name}</span>;
  return (
    <div className={styles.nameCell}>
      <div className={styles.selectCheckBox}>
        {l1Object.state !== L1ObjectState.DELETE &&
          <PermissionChecker permissionAware={permissionAwareGetter(l1Object)}>
            <input
              type='checkbox'
              checked={selectedL1Objects.indexOf(l1ObjectId.toString()) > -1}
              onChange={_.partial(onSelect, l1ObjectId.toString())}
              id={`input${l1ObjectId}`}
            />
            <label htmlFor={`input${l1ObjectId}`} />
          </PermissionChecker>
        }
      </div>
      <div className={styles.info}>
        <PermissionChecker permissionAware={l1ObjectDetailReadPermission} renderOtherwise={renderLabel}>
          {l1Object.state !== L1ObjectState.DELETE ?
            <Link to={`${formatExtraData.currentUrl}/campaign-groups/${l1ObjectId}`} className={styles.name}>
              {l1Object.name}
            </Link> :
            renderLabel()
          }
        </PermissionChecker>
        <div className={styles.id}>
          {'ID: ' + l1ObjectId}
        </div>
        <div className={styles.capsules}>
          {l1Object.drafts > 0 &&
            <div className={styles.capsule}>
              {i18n.t<string>('l1ObjectList.labels.drafts', { count: l1Object.drafts })}
            </div>
          }
          <div className={styles.capsule}>
            {l1Object.autoOptimise ? i18n.t<string>('l1ObjectList.labels.cbo') : i18n.t<string>('l1ObjectList.labels.non-cbo')}
          </div>
        </div>
      </div>
    </div>
  );
};

const stateFormatter = (state, l1Object: L1Object, _2, formatExtraData: any) => {
  const { order } = formatExtraData;
  const channelStatusDesMap = {
    [L1ObjectChannel.RTB]: () => getRtbCampaignGroupStatusDesData(l1Object, order),
    [L1ObjectChannel.RETAIL_MEDIA]: () => getRtbCampaignGroupStatusDesData(l1Object, order),
    [L1ObjectChannel.EDIMAX]: () => getRtbCampaignGroupStatusDesData(l1Object, order),
    [L1ObjectChannel.MESSAGE]: () => getRtbCampaignGroupStatusDesData(l1Object, order),
    [L1ObjectChannel.FB]: () => getFbCampaignStatusDesData(l1Object),
    [L1ObjectChannel.TIKTOK]: () => getTiktokCampaignStatusDesData(l1Object)
  };
  const stateData = channelStatusDesMap[l1Object.channel] ? channelStatusDesMap[l1Object.channel]() : {
    des: state,
    color: 'black'
  };

  return (
    <Status
      label={stateData.des}
      color={stateData.color}
    />
  );
};

const deliveryFormatter = (state: string, l1Object: L1Object, _2, formatExtraData: any) => {
  const progressData = formatExtraData(l1Object);
  const showProgress = progressData && l1Object.channel !== L1ObjectChannel.TIKTOK;
  const channelStatusDesMap = {
    [L1ObjectChannel.RTB]: () => getRtbCampaignGroupDeliveryDesDatas(l1Object),
    [L1ObjectChannel.RETAIL_MEDIA]: () => getRtbCampaignGroupDeliveryDesDatas(l1Object),
    [L1ObjectChannel.EDIMAX]: () => getRtbCampaignGroupDeliveryDesDatas(l1Object),
    [L1ObjectChannel.MESSAGE]: () => getMessageCampaignGroupDeliveryDesDatas(l1Object),
    [L1ObjectChannel.FB]: () => getFbCampaignEffectiveStatusDesData(l1Object),
    [L1ObjectChannel.TIKTOK]: () => getTiktokCampaignDeliveryStatusDesData(l1Object)
  };
  const stateData = channelStatusDesMap[l1Object.channel] ? channelStatusDesMap[l1Object.channel]() : [{
    des: state,
    color: 'black'
  }];
  const tiktokTooltip = l1Object.channel === L1ObjectChannel.TIKTOK ?
    getTiktokStatusTooltip(l1Object) : undefined;

  const hasExtraInfo = _.get(l1Object, 'fb.issues_info') || tiktokTooltip;
  const renderExtraInfo = hasExtraInfo ? () => {
    return _.get(l1Object, 'fb.issues_info') ?
      _.get(l1Object, 'fb.issues_info.error_summary') :
      tiktokTooltip;
  } : undefined;
  return (
    <div className={styles.deliveryCell}>
      {showProgress &&
        <div className={styles.progressCell}>
          <div className={styles.progressContent}>
            <div className={styles.progress}>
              <Progress
                progress={progressData.executeRate}
                danger={progressData.danger}
                deepWarning={progressData.deepWarning}
                warning={progressData.warning}
              />
            </div>
            <span>
              {`${(progressData.executeRate * 100).toFixed(2)}% / ${(progressData.predictRate * 100).toFixed(2)}%`}
            </span>
          </div>
        </div>
      }
      {!_.isEmpty(stateData) &&
        <div className={styles.tagsCell}>
          {stateData.map(data => {
            const label = _.get(data, 'value') ? `${data.des} ${_.get(data, 'value')}` : data.des;
            return (
              <Status
                key={data.des}
                label={label}
                color={data.color}
                renderExtraInfo={renderExtraInfo}
              />
            );
          })}
        </div>
      }
    </div>
  );
};

const resultsFormatter = (value, l1Object: L1Object) => {
  const channelResultsFormatterMap = {
    [L1ObjectChannel.RTB]: rtbResultsFormatter,
    [L1ObjectChannel.RETAIL_MEDIA]: rtbResultsFormatter,
    [L1ObjectChannel.EDIMAX]: rtbResultsFormatter,
    [L1ObjectChannel.MESSAGE]: rtbResultsFormatter,
    [L1ObjectChannel.FB]: fbResultsFormatter,
    [L1ObjectChannel.TIKTOK]: tiktokResultsFormatter
  };
  const formatter = channelResultsFormatterMap[l1Object.channel];
  if (!formatter) {
    return <div/>;
  }
  return formatter(value, l1Object);
};

const rtbResultsFormatter = (value, l1Object: L1Object) => {
  const rtbObjective = _.get(l1Object, 'objective');
  return (
    <div className={styles.resultCell}>
      <div className={styles.value}>
        {value === undefined ? 0 : value}
      </div>
      <div className={styles.objective}>
        {_.startCase(_.lowerCase(rtbObjective))}
      </div>
    </div>
  );
};

const fbResultsFormatter = (value, l1Object: L1Object) => {
  const fbObjective = _.get(l1Object, 'fb.objective');
  return (
    <div className={styles.resultCell}>
      <div className={styles.value}>
        {
          fbObjective === L1ObjectObjective.SALES ?
            '—' :
            value === undefined ? 0 : value
        }
      </div>
      <div className={styles.objective}>
        {_.startCase(_.lowerCase(fbObjective))}
      </div>
    </div>
  );
};

const tiktokResultsFormatter = () => {
  return (
    <div className={styles.resultCell}>
      <div className={styles.value}>
        —
      </div>
    </div>
  );
};

const floatingEditBtnsFormatter = (_1, l1Object, _2, formatExtraData: any): any => {
  const onDeleteBtnClick = () => formatExtraData.onDeleteBtnClick(l1Object.l1ObjectId);
  const channel = _.get(l1Object, 'channel', L1ObjectChannel.RTB);
  const actionable = _.get(l1Object, 'actionable', false);
  const canDelete = _.get(l1Object, 'campaignAmount', 0) === 0 && actionable;
  const canEdit = l1Object.objective !== L1ObjectObjective.UNSPECIFIED && l1Object.state !== L1ObjectState.DELETE;
  const from = encodeURIComponent(moment(l1Object.l2ObjectMinStartDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
  const to = encodeURIComponent(moment(l1Object.l2ObjectMaxEndDate).startOf('day').format('YYYY-MM-DD HH:mm:ss'));
  const reportUrl = `/reports/performance?dimension=l1ObjectId&from=${from}&to=${to}&l1ObjectId=${l1Object.l1ObjectId}`;
  return (
    <TableRowToolBar className={styles.floatingEditArea}>
      {canEdit &&
        <PermissionChecker permissionAware={notSelfServeAdObject(l1Object).and(formatExtraData.permissionAwareGetter(l1Object))}>
          <IconWithTooltip
            icon={faPencilAlt}
            tooltipProps={{
              id: `campaignGroupList-editTip-${l1Object.l1ObjectId}`,
              tooltip: i18n.t<string>('l1Object.labels.editTitle'),
              link: `${formatExtraData.currentUrl}/campaign-groups/${l1Object.l1ObjectId}/edit`
            }}
          />
        </PermissionChecker>
      }
      {l1Object.state !== L1ObjectState.DELETE &&
        <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_DEL)}>
          <IconWithTooltip
            disabled={!canDelete}
            icon={faTrashAlt}
            onClick={onDeleteBtnClick}
            tooltipProps={{
              id: `campaignGroupList-deleteTip-${l1Object.l1ObjectId}`,
              tooltip: canDelete
                ? i18n.t<string>('l1Object.labels.deleteTitle') : actionable
                  ? i18n.t<string>('l1Object.labels.cannotDeleteTitle')
                  : i18n.t<string>(`l1Object.labels.${channel.toString().toLowerCase()}_cannotDeleteTitle`)
            }}
          />
        </PermissionChecker>
      }
      <PermissionChecker permissionAware={hasFuncs(Permission.REPORT_ADS)}>
        <IconWithTooltip
          icon={faChartArea}
          tooltipProps={{
            id: `campaignListReportTip-${l1Object.l1ObjectId}`,
            link: reportUrl,
            tooltip: i18n.t<string>('campaignList.labels.reportHint')
          }}
        />
      </PermissionChecker>
    </TableRowToolBar>
  );
};

const channelFormatter = (_1, campaginGroup: L1Object) => {
  const channel = _.get(campaginGroup, 'channel');

  return (
    <div>{i18n.t<string>(`l1Object.labels.channel_${channel.toLowerCase()}`)}</div>
  );
};

const budgetFormatter = (
  getBudgetValue: (budget: any, currency: any) => string,
  currency: Currency,
  value: any,
  l1Object: L1Object
) => {
  const budgetValue = getBudgetValue(value, currency);
  const budgetInfo = _.pick(l1Object.l2ObjectRunningStatus, [
    L2ObjectRunningStatus.BUDGET_REMAIN,
    L2ObjectRunningStatus.BUDGET_NOT_ALLOCATED
  ]);

  const needAlertStatus = _.keys(budgetInfo)
    .filter(key => budgetInfo[key] as number > 0 || budgetInfo[key] as boolean)
    .reduce((acc: any[], key) => {
      let stateData = {
        des: '',
        color: 'black'
      };
      switch (key) {
        case L2ObjectRunningStatus.BUDGET_REMAIN:
          stateData.des = `${i18n.t<string>('l1ObjectList.labels.remainBudget')} ${budgetInfo[key]}`;
          stateData.color = 'danger';
          break;
        case L2ObjectRunningStatus.BUDGET_NOT_ALLOCATED:
          stateData.des = i18n.t<string>('l1ObjectList.labels.undistributedBudget');
          stateData.color = 'danger';
          break;
        default:
          break;
      }
      return [
        ...acc,
        stateData
      ];
    }, []);

  return (
    <div className={styles.budgetCell}>
      {budgetValue}
      {!_.isEmpty(needAlertStatus) &&
        <div className={styles.tagsCell}>
          {needAlertStatus.map(status => (
          <Status
            key={status.des}
            label={status.des}
            color={status.color}
          />
        ))}
        </div>
      }
    </div>
  );
};

const formatters = {
  nameHeaderFormatter,
  nameFormatter,
  stateFormatter,
  deliveryFormatter,
  budgetFormatter,
  floatingEditBtnsFormatter,
  resultsFormatter,
  channelFormatter
};
export default formatters;
