import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { StateDataContext } from 'containers/Common/StateDataContext';
import React, { useContext } from 'react';
import { DefaultDraftSetupStateContentModel } from './DraftSetupStateContentModel';
import { DraftSetupTarget } from './DraftSetupTarget';
import styles from './draftSetupStateContent.module.scss';
import i18n from 'i18n';
import { compact, get, partial } from 'lodash';
import { DraftSourceDetail } from './DraftSourceDetail';
import { CustomField } from 'components/common/form/field/CustomField';

export const DraftSetupStateContent: React.FC<{
  model: DefaultDraftSetupStateContentModel
}> = ({
  model
}) => {

  const { data, setData } = useContext(StateDataContext);

  const onChange = () => {
    const invalidData = data.targets.find(target => !target.order || !target.l1Object || target.hasError);
    model.setCanGoNext(!invalidData);
    setData({
      ...data,
      targets: [...data.targets]
    });
  };

  const onTargetChange = (newTarget) => {
    const index = data.targets.findIndex(target => target.id === newTarget.id);
    if (index === -1) {
      return;
    }
    data.targets[index] = newTarget;
    onChange();
  };

  const addTarget = () => {
    let largestId = 0;
    data.targets.forEach(target => {
      if (largestId < target.id) {
        largestId = target.id;
      }
    });
    data.targets.push({
      id: largestId + 1,
      numberOfCopies: 1,
      includeBinding: true
    });
    onChange();
  };

  const removeTarget = (targetId) => {
    const index = data.targets.findIndex(target => targetId === target.id);
    if (index > -1) {
      data.targets.splice(index, 1);
      onChange();
    }
  };

  const renderTargets = () => {
    const targets = data.targets;
    const selectedL1Objects: number[] = compact(data.targets.map(target => get(target.l1Object, 'value')));
    return targets.map((target, index) => {
      return (
        <div key={target.id}>
          <DraftSetupTarget
            draftTarget={target}
            orderOptions={model.orderOptions}
            l1ObjectsOptionsCache={model.l1ObjectOptionsCache}
            l1ObjectsFilter={model.getL1ObjectsOfChannel}
            onTargetChange={onTargetChange}
            onRemove={target.id > 0 ? partial(removeTarget, target.id) : undefined}
            selectedL1Objects={selectedL1Objects}
          />
          {index !== targets.length - 1 && <div className={styles.sepLine}/>}
        </div>
      );
    });
  };

  const renderNewTargetButton = () => (
    <div className={styles.filterAdder} onClick={addTarget}>
      <FontAwesomeIcon icon={faPlus} />
      <div className={styles.filterToAddList}>
        {i18n.t<string>('draftCreateModal.buttons.addTarget')}
      </div>
    </div>
  );

  return (
    <div className={styles.draftSetupStateContent}>
      <DraftSourceDetail/>
      <div className={styles.title}>
        {i18n.t<string>('draftCreateModal.titles.chooseTargetState')}
      </div>
      {renderTargets()}
      <CustomField
        label=''
        render={renderNewTargetButton}
      />
    </div>
  );
};
