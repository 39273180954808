import React from 'react';
import i18n from 'i18n';
import _ from 'lodash';
import { Status } from 'components/Status/Status';
import styles from './CreativeList.module.scss';
import defaultListFormatters from './defaultListFormatters';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { withActivateState, withDangerStatus, withDeactivateStatus, withEndState, withIssuesStatus, withNotStartState, withPendingReviewState } from 'helper/AdObjectStatusHelper';

const statusDataSetting = {
  'DELETE': {
    label: 'campaignList.labels.deleteState',
    color: 'danger'
  },
  'FROZEN': {
    label: 'campaignList.labels.deleteState',
    color: 'warning'
  },
  'DISABLE': {
    label: 'campaignList.labels.deactivateState',
    color: 'black'
  },
  'ENABLE': {
    label: 'campaignList.labels.activateState',
    color: 'success'
  }
};

const deliveryStatusDataSetting = {
  'AD_STATUS_DELETE': withDangerStatus('Deleted'),
  'AD_STATUS_CAMPAIGN_DELETE': withDangerStatus('Campaign deleted'),
  'AD_STATUS_ADGROUP_DELETE': withDangerStatus('Ad group deleted'),
  'AD_STATUS_ADVERTISER_AUDIT_DENY': withIssuesStatus('Account review failed'),
  'AD_STATUS_ADVERTISER_AUDIT': withIssuesStatus('Account review summary released'),
  'ADVERTISER_CONTRACT_PENDING': withIssuesStatus('Advertiser contract has not taken effect'),
  'AD_STATUS_BALANCE_EXCEED': withDeactivateStatus('Insufficient account balance'),
  'AD_STATUS_CAMPAIGN_EXCEED': withDeactivateStatus('Campaign over budget'),
  'AD_STATUS_BUDGET_EXCEED': withDeactivateStatus('Ad group over budget'),
  'AD_STATUS_ADGROUP_AUDIT_DENY': withIssuesStatus('Ad group review failed'),
  'AD_STATUS_AUDIT_DENY': withDeactivateStatus('Review failed'),
  'AD_STATUS_REAUDIT': withPendingReviewState('Modifications under review'),
  'AD_STATUS_AUDIT': withPendingReviewState('Under review'),
  'ADGROUP_STATUS_CREATE': withPendingReviewState('New ad group created'),
  'AD_STATUS_NOT_START': withNotStartState('The scheduled delivery time has not started'),
  'AD_STATUS_DONE': withEndState('Completed'),
  'AD_STATUS_CAMPAIGN_DISABLE': withDeactivateStatus('Campaign suspended'),
  'ADGROUP_STATUS_DISABLE': withDeactivateStatus('Ad group suspended'),
  'ADGROUP_STATUS_DELIVERY_OK': withActivateState('Advertising in delivery'),
  'AD_STATUS_ADGROUP_DISABLE': withDeactivateStatus('Ad group suspended'),
  'AD_STATUS_DISABLE': withDeactivateStatus('Ad suspended'),
  'AD_STATUS_DELIVERY_OK': withActivateState('Advertising in delivery')
};

const getAdStatusDesData = (bindingState) => {
  const statusData = statusDataSetting[bindingState];
  if (!statusData) {
    return {
      label: _.startCase(_.lowerCase(bindingState)),
      color: ''
    };
  }
  return {
    label: i18n.t<string>(statusData.label),
    color: statusData.color
  };
};

const getAdEffectiveStatusDesData = (effectiveStatus, creativeData: any) => {
  const deliveryStatusData = deliveryStatusDataSetting[effectiveStatus];
  if (!deliveryStatusData) {
    return {
      label: _.startCase(_.lowerCase(effectiveStatus)),
      color: ''
    };
  }
  const reasons = _.get(creativeData, 'approvalStatus.tiktok.reason', [])
                   .map(reasonObj => reasonObj.reason);
  const rejectReasonsDom = reasons.map(rejectReason => (
    <li key={rejectReason}>
      {rejectReason}
    </li>
  ));
  return {
    label: i18n.t<string>(deliveryStatusData.label),
    color: deliveryStatusData.color,
    renderExtraInfo: () => {
      return reasons && reasons.length > 0 ? (
        <div>
          <div className={styles.deliveryAdReviewSection}>{deliveryStatusData.des}</div>
          <div className={styles.deliveryAdReviewSection}>
            <div>Reasons:</div>
            <ul>
              {rejectReasonsDom}
            </ul>
          </div>
        </div>
      ) : `${deliveryStatusData.des}`;
    }
  };
};

const stateFormatter = (bindingState: any, creative: any, _1, formatExtraData): any => {
  if (formatExtraData.isDraft) {
    return defaultListFormatters.stateFormatter(bindingState, creative);
  }
  const stateData = getAdStatusDesData(bindingState);
  return (
    creative.id !== 0 ?
    <Status
      {...stateData}
    /> : <div/>
  );
};

const deliveryFormatter = (status: any, creative: any, _1, formatExtraData): any => {
  if (formatExtraData.isDraft) {
    const renderExtraInfo = () => i18n.t<string>('campaignList.labels.draftStateHint');
    return creative.id !== 0 ? (
      <Status
        label={i18n.t<string>('campaignList.labels.deactivateState')}
        color={'black'}
        renderExtraInfo={renderExtraInfo}
      />
    ) : <div/>;
  }
  const stateData = getAdEffectiveStatusDesData(status, creative);
  return (
    creative.id !== 0 ?
    <Status
      {...stateData}
    /> : <div/>
  );
};

const resultsFormatter = (value, creativeData: any, _1, formatExtraData) => {
  if (!creativeData || creativeData.id === 0) {
    return <div/>;
  }
  const l1Object = formatExtraData.l1Object;
  let objective = _.get(l1Object, 'tiktok.objective_type');
  return (
    <div>
      <div className={styles.value}>
        {value === undefined ? 0 : value}
      </div>
      <div className={styles.objective}>
        {objective === L1ObjectObjective.AWARENESS ? 'Reach' : 'Link Clicks'}
      </div>
    </div>
  );
};

const uuFormatter = (value: any) => {
  const isSummary = value === '';
  if (isSummary) {
    return '';
  }
  return defaultListFormatters.numberFormatter(value);
};

const formatters = {
  ...defaultListFormatters,
  stateFormatter,
  deliveryFormatter,
  resultsFormatter,
  uuFormatter
};

export default formatters;
