import React from 'react';
import i18n from 'i18n';
import styles from './ppsMacroInstruction.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export const PPSMacroInstruction: React.FunctionComponent<{onClose: () => void}> = (props) => {
  const renderRow = (macroData) => {
    return macroData.map(data => {
      return (
        <tr key={data.macro}>
          <td>
            {data.macro}
          </td>
          <td>
            {data.desc}
          </td>
        </tr>
      );
    });
  };
  return (
    <div className={styles.ppsMacroInstruction}>
      <FontAwesomeIcon className={styles.closeBtn} icon={faTimes} onClick={props.onClose}/>
      <section>
        <div className={styles.sessionTitle}>{i18n.t<string>('macro.titles.clickMacroDesc')}</div>
        <table className={styles.macroTable}>
          <thead>
            <tr>
              <th>{i18n.t<string>('macro.headers.clickMacro')}</th>
              <th>{i18n.t<string>('macro.headers.desc')}</th>
            </tr>
          </thead>
          <tbody>
            {renderRow([
              { macro: '%%CLICK_URL_UNESC%%', desc: i18n.t<string>('macro.unescapedClickURL') },
              { macro: '%%CLICK_URL_ESC%%', desc: i18n.t<string>('macro.escapedClickURL') },
              { macro: '%%CLICK_URL_ESC_ESC%%', desc: i18n.t<string>('macro.doubledEscapedClickURL') },
              { macro: '%%VAST_URL_UNESC%%', desc: i18n.t<string>('macro.vastUrl') }
            ])}
          </tbody>
        </table>
      </section>
      <section>
        <div className={styles.sessionTitle}>{i18n.t<string>('macro.titles.viewableMacroDesc')}</div>
        <table className={styles.macroTable}>
          <thead>
            <tr>
              <th>{i18n.t<string>('macro.headers.viewableMacro')}</th>
              <th>{i18n.t<string>('macro.headers.desc')}</th>
            </tr>
          </thead>
          <tbody>
            {renderRow([
              { macro: '%%VIEWABLE_IMPRESSION_URL_UNESC%%', desc: i18n.t<string>('macro.dspViewableURL') },
              { macro: '%%SSP_VIEWABLE_IMPRESSION_URL_UNESC%%', desc: i18n.t<string>('macro.sspViewableURL') },
              { macro: '%%ADX_VIEWABLE_IMPRESSION_URL_UNESC%%', desc: i18n.t<string>('macro.adxViewableURL') }
            ])}
          </tbody>
        </table>
      </section>
    </div>
  );
};
