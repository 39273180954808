import React from 'react';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { ProductSetListColumns, useProductSetListModel } from './ProductSetListModel';
import i18n from 'i18n';
import _ from 'lodash';
import styles from './productSetList.module.scss';
import { SearchBar } from 'components/common/SearchBar';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Modal } from 'components/common/Modal/Modal';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { TablePlaceholder } from 'components/TablePlaceholder/TablePlaceholder';
import { notRoles } from 'core/permission/PermissionDSL';
import { RoleNames } from 'core';

export type ProductSetListProps = {
  advertiserId: number | string | undefined,
  startDate: string,
  endDate: string
};

export const ProductSetList: React.FC<ProductSetListProps> = ({
  advertiserId,
  startDate,
  endDate
}) => {

  const {
    loading,
    columns,
    searchString,
    filteredList,
    productSetDetail,
    deleteProductSet,
    setSearchString,
    onDeleteModalClose,
    onDetailModalClose
  } = useProductSetListModel(advertiserId, startDate, endDate);

  const renderSysAdminPlaceholder = () => {
    if (SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY)) {
      return <TablePlaceholder placeholder={i18n.t<string>('productSetList.hints.noData')}/>;
    }
    return <TablePlaceholder placeholder={i18n.t<string>('productSetList.labels.chooseAgency')}/>;
  };

  const renderListNoDataIndication = () => {
    return (
      <PermissionChecker
        permissionAware={notRoles(RoleNames.sysAdmin)}
        renderOtherwise={renderSysAdminPlaceholder}
      >
        <TablePlaceholder placeholder={i18n.t<string>('productSetList.hints.noData')}/>
      </PermissionChecker>
    );
  };

  const renderProducts = () => {
    if (!productSetDetail) {
      return;
    }

    return productSetDetail.products.map(product => {
      return <li key={product.productId}>{_.get(product, 'name', '')}</li>;
    });
  };

  return (
    <div className={styles.productSetList}>
      {loading && <LoadingIndicator />}
      {deleteProductSet &&
        <Modal
          title={i18n.t<string>('productSetList.labels.deleteModalTitle')}
          secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: onDeleteModalClose }}
          dangerButton={{ title: i18n.t<string>('common.buttons.delete'), callback: deleteProductSet }}
          dismiss={onDeleteModalClose}
        >
          {i18n.t<string>('productSetList.labels.deleteModalContent')}
        </Modal>
      }
      {
        productSetDetail &&
        <Modal
          className={styles.detailModal}
          title={productSetDetail.name}
          secondaryButton={{ title: i18n.t<string>('common.buttons.confirm'), callback: onDetailModalClose }}
          dismiss={onDetailModalClose}
        >
          <ul>
            {renderProducts()}
          </ul>
        </Modal>
      }
      <div className={styles.filterArea}>
        <SearchBar
          model={{
            placeholder: i18n.t<string>('productSetList.placeholders.searchbar'),
            search: setSearchString,
            defaultValue: searchString
          }}
        />
      </div>
      <div className={styles.contentArea}>
        <StickableBootstrapTable
          stickFirstColumn
          stickLastColumn
          keyField={ProductSetListColumns.ID}
          data={filteredList}
          columns={columns}
          noDataIndication={renderListNoDataIndication}
          defaultSorted={[{
            dataField: ProductSetListColumns.ID,
            order: 'desc'
          }]}
        />
      </div>
    </div>
  );
};
