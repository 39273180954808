export type SegmentListDTO = {
  id: number;
  name: string;
  duration: number;
  segmentCount: number;
  subSegmentAmount?: number;
  advertiserId: number;
  dmpSegmentId: string;
};

export type SegmentFormDTO = {
  id?: number;
  name: string;
  duration: number;
  advertiserId?: number;
  advertiserName?: string;
};

export type SubSegmentFormDTO = {
  id?: number;
  name: string;
  ruleContents?: Array<SegmentRuleContent>;
};

export type SegmentDTO = SegmentListDTO & {
  segmentCode?: string;
  ruleContents?: Array<SegmentRuleContent>;
  subSegments?: Array<SegmentDTO>;
};

export type SegmentRuleContent = {
  pattern: string;
  comparator: SegmentRuleComparator;
};

export enum SegmentRuleComparator {
  CONTAINS = 'contains',
  EQUALS = 'equals',
  STARTS_WITH = 'starts_with',
  ENDS_WITH = 'ends_with',
  NOT_EQUAL = 'does_not_equal',
  NOT_CONTAIN = 'does_not_contain',
  NOT_START_WITH = 'does_not_start_with',
  NOT_END_WITH = 'does_not_end_with',
  REGEX = 'regex'
}
