import { CampaignState } from 'core/rtbCampaign/RtbCampaign';

export type EventRule = {
  ruleId: number;
  selectedFilterField?: FILTER_FIELDS;

  durationDays: number;
  eventType: string;
  filterSet?: {
    operator: PRODUCT_SEGMENT_OPERATOR;
    filters: FilterField[]
  };
  aggregation?: AggregationField;
};

export type FilterField = {
  field: FILTER_FIELDS | '';
  operator: PRODUCT_SEGMENT_OPERATOR | '';
  value: string[]
};

export type AggregationField = {
  type: AGGREGATION_TYPES;
  field?: FILTER_FIELDS;
  operator: PRODUCT_SEGMENT_OPERATOR;
  value: string[];
};

export enum PRODUCT_SEGMENT_OPERATOR {
  OR = 'or',
  AND = 'and',
  EQ = 'eq',
  NEQ = 'neq',
  I_CONTAINS = 'i_contains',
  I_NOT_CONTAINS = 'i_not_contains',
  GTE = 'gte',
  GT = 'gt',
  LT = 'lt',
  LTE = 'lte',
  IN_RANGE = 'in_range'
}

export enum EVENT_TYPES {
  ALL_PRODUCT_EVENTS = 'allProductEvents',
  SEARCH = 'search',
  VIEW_CONTENT = 'viewContent',
  VIEW_CART = 'viewCart',
  ADD_TO_CART = 'addToCart',
  PURCHASE = 'purchase',
  PAGE_VIEW = 'pageView',
  UTM = 'utm' // wrapped to 'pageView'
}

export enum FILTER_FIELDS {
  SEARCH_TERM = 'searchTerm',
  BRAND = 'brand',
  CATEGORY = 'category',
  NAME = 'name',
  PRODUCT_ID = 'productId',
  PRODUCT_SET_ID = 'productSetId',
  SALE_PRICE = 'salePrice',
  TOTAL_PRICE = 'totalPrice',
  URI = 'uri',
  UTM_SOURCE = 'utm_source',
  UTM_MEDIUM = 'utm_medium',
  UTM_CAMPAIGN = 'utm_campaign',
  UTM_TERM = 'utm_term',
  UTM_CONTENT = 'utm_content',
  CUSTOM_LABEL_0 = 'custom_label_0',
  CUSTOM_LABEL_1 = 'custom_label_1',
  CUSTOM_LABEL_2 = 'custom_label_2',
  CUSTOM_LABEL_3 = 'custom_label_3',
  CUSTOM_LABEL_4 = 'custom_label_4'
}

export enum AGGREGATION_TYPES {
  COUNT = 'count',
  SUM = 'sum'
}

export type ProductSegment = {
  name: string,
  agency: number,
  advertiser: number,
  segmentId: string,
  feedId: string,
  visitors: number,
  customers: number,
  createdTime: string,
  lastUpdatedTime: string,
  combination: {
    exclusion?: {
      operator: PRODUCT_SEGMENT_OPERATOR,
      rules: EventRule[]
    },
    inclusion: {
      operator: PRODUCT_SEGMENT_OPERATOR,
      rules: EventRule[]
    }
  }
};

export type ProductSegmentRecord = {
  name: string,
  segmentId: string,
  advertiserName: string,
  visitors: number,
  status: ProductSegmentStatus,
  execStatus: ProductSegmentExecStatus,
  lastUpdatedTime: string,
  campaigns: {
    name: string,
    campaignId: number,
    startDate: string,
    endDate: string,
    state: CampaignState
  }[]
};

export enum ProductSegmentStatus {
  ACTIVE = 1,
  ARCHIVED = 2
}

export enum ProductSegmentExecStatus {
  READY = 1,
  USING = 2,
  PROCESSING = 3,
  ARCHIVED = 4
}
