import React, { useCallback, useContext, useMemo } from 'react';
import { FormikField } from 'components/common/form/field/FormikField';
import { Form as BootstrapForm } from 'react-bootstrap';
import { FormikContextType, FormikProps } from 'formik';
import { TiktokAdGroupFormData } from '../TiktokAdGroupSetupFlowPageModel';
import _ from 'lodash';
import i18n from 'i18n';
import cx from 'classnames/bind';
import styles from './tiktokAdGroupSetupMainStep.module.scss';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { Link } from 'react-router-dom';
import { Pacing, TiktokBidStrategy } from 'core/tiktokAdGroup/TiktokAdGroup';
import { Trans } from 'react-i18next';
import { Dayparts } from 'components/Dayparts/Dayparts';
import moment from 'moment';
import { DefaultTiktokAdGroupManager } from 'core/tiktokAdGroup/TiktokAdGroupManager';
import { TiktokAdGroupSetupFlowContext, TiktokAdGroupSetupFlowContextType } from '../TiktokAdGroupSetupFlowContext';
import { getFieldErrors } from 'utils/FormikUtils';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import { LabelField } from 'components/common/form/field/LabelField';
import { SwitchField } from 'components/common/form/field/SwitchField';

const classNames = cx.bind(styles);

const tiktokAdGroupManager = new DefaultTiktokAdGroupManager();
const deliveryOptions = createSelectOptionsFromEnum(
  Pacing, 'adGroup.pacing.'
);

const renderDaypart = (props: FormikContextType<any> & { name: string }) => (
  <Dayparts
    form={{
      values: props.values,
      setFieldValue: props.setFieldValue
    }}
    field={{
      name: props.name
    }}
  />
);

export const TiktokAdGroupForm: React.FC<FormikProps<TiktokAdGroupFormData>> = (props) => {

  const {
    values,
    errors,
    setValues,
    setFieldValue,
    setFieldTouched
  } = props;

  const {
    operate,
    order,
    l1Object,
    adGroup,
    initAdGroup,
    canEditOptimizationGoal,
    canEditBidStrategy,
    getBasicFormConfig,
    adGroupFormModel
  } = useContext(TiktokAdGroupSetupFlowContext) as TiktokAdGroupSetupFlowContextType;

  const { currency } = order;
  const {
    billingEventOptions,
    optimizationGoalOptions,
    onOptimizationGoalChanged
  } = adGroupFormModel(order, l1Object, adGroup, tiktokAdGroupManager);

  const onBidAmountChanged = useCallback(() => {
    setFieldTouched('lifetime_budget');
  }, [setFieldTouched]);
  const dayPart = _.get(adGroup, 'dayPart');
  const onDaypartSwitchChanged = useCallback((event) => {
    const dayPartEnable = event.target.checked;
    if (!dayPartEnable) {
      setFieldValue('dayPart', undefined);
    } else {
      setFieldValue('dayPart', {
        ...dayPart,
        enabled: '1'
      });
    }
  }, [dayPart, setFieldValue]);

  const {
    minDate,
    maxDate,
    isAdGroupStart,
    showBidStrategyFixHint,
    showOptimiationGoalHint
  } = getBasicFormConfig(adGroup);

  const campaignBidType = _.get(l1Object, 'tiktok.bid_type');
  const initBudget = +(_.get(adGroup, 'budget', 0).toString());
  const totalBudget = _.get(initAdGroup, 'isDraft', false) ?
    l1Object.budgetBalance :
    l1Object.budgetBalance + initBudget;
  const budget = +(_.get(values, 'budget', 0).toString());
  const budgetIsInvalid = !!getFieldErrors(props, 'budget');
  const dayPartEnable = _.get(values, 'dayPart.enabled', 0);

  const optimizationGoalLabelFormatter = useCallback(goal => i18n.t<string>(`optimizationGoal.${goal.toLowerCase()}`), []);
  const billingEventFormatter = useCallback(billingEvent => i18n.t<string>(`adGroup.billingEvent.${billingEvent.toLowerCase()}`), []);
  const CBOBudgetFormatter = useCallback(() => {
    return i18n.t<string>('adGroupSetupFlow.mainStep.CBOAdGroupBudgetHint');
  }, []);

  const onBidTypeChange = useCallback((e) => {
    setValues(prev => ({
      ...prev,
      bid_type: e.target.value,
      pacing: Pacing.PACING_MODE_SMOOTH,
      bid: undefined
    }));
  }, [setValues]);

  const renderBidControl = (formikProps) => {
    const { value } = formikProps.getFieldProps(formikProps.name);

    return (
      <div className={styles.bidControl}>
        <BootstrapForm.Check
          type={'radio'}
          label={i18n.t<string>('adGroup.bidStrategy.bid_type_no_bid')}
          value={TiktokBidStrategy.BID_TYPE_NO_BID}
          name={'bid_type'}
          id={'autoBid'}
          onChange={onBidTypeChange}
          checked={value === TiktokBidStrategy.BID_TYPE_NO_BID}
          disabled={!canEditBidStrategy}
        />
        <BootstrapForm.Check
          type={'radio'}
          label={i18n.t<string>('adGroup.bidStrategy.bid_type_custom')}
          value={TiktokBidStrategy.BID_TYPE_CUSTOM}
          name={'bid_type'}
          id={'bidCap'}
          onChange={onBidTypeChange}
          checked={value === TiktokBidStrategy.BID_TYPE_CUSTOM}
          disabled={!canEditBidStrategy}
        />
        {showBidStrategyFixHint && (
          <span className={styles.bidStrategyHint}>
            {i18n.t<string>('adGroupSetupFlow.mainStep.bidStrategyFixHint')}
          </span>
        )}
        {((campaignBidType && campaignBidType === TiktokBidStrategy.BID_TYPE_CUSTOM) ||
          value === TiktokBidStrategy.BID_TYPE_CUSTOM) &&
            <FormikField.InputGroup
              name='bid'
              prefix={currency}
              type='number'
              min={0}
              className={styles.bidAmount}
              onChange={onBidAmountChanged}
            />
        }
      </div>
    );
  };

  const renderAdGroupTotalDaysDes = useCallback((startTime, endTime) => {
    const startDateMoment = moment(startTime);
    const endDateMoment = moment(endTime);
    const days = endDateMoment.diff(startDateMoment, 'days') + 1;
    const hours = endDateMoment.diff(startDateMoment, 'hours') + 1;
    if (days > 1) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
          Total <span className='text-dark'><>{{ days }} days</></span>
        </Trans>
      );
    } else if (days === 1) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingHour'>
          Total <span className='text-dark'><>{{ hours }} hours</></span>
        </Trans>
      );
    }
  }, []);

  const dayHint = useMemo(() => {
    return renderAdGroupTotalDaysDes(values.schedule_start_time, values.schedule_end_time);
  }, [renderAdGroupTotalDaysDes, values.schedule_start_time, values.schedule_end_time]);

  const afterOptimizationGoalChanged = useCallback((_1, newDefaultBillingEvent) => {
    setFieldValue('billing_event', newDefaultBillingEvent);
  }, [setFieldValue]);

  const handleOptimizationGoalChanged = useCallback((newOptimizationGoal) => {
    onOptimizationGoalChanged(afterOptimizationGoalChanged, newOptimizationGoal);
  }, [onOptimizationGoalChanged, afterOptimizationGoalChanged]);

  const pacingFormatter = useCallback((pacing) => i18n.t<string>(`adGroup.pacing.${pacing.toLowerCase()}`), []);

  return (
    <div>
      <fieldset>
        <legend>
          <span>{i18n.t<string>('adGroupSetupFlow.mainStep.fieldset.basicTitle')}</span>
        </legend>
        <div className={styles.component}>
          <LabelField
            className={classNames('label', ['col-form-label'])}
            label={i18n.t<string>('adGroupSetupFlow.mainStep.field.tiktokCampaignName')}
            value={l1Object.name}
          />
          <FormikField.Input
            label={i18n.t<string>('adGroupSetupFlow.mainStep.field.name')}
            name='adgroup_name'
          />
          {!!l1Object.budget &&
            <FormikField.Label
              label={i18n.t<string>('adGroupSetupFlow.mainStep.field.budget')}
              name='budget'
              formatter={CBOBudgetFormatter}
              hint={errors.budget ?
                <div className={styles.lifetimeBudgetError}>
                  <div className='errorTip'>{errors.budget}</div>
                  <Link to={`/orders/${order.orderNumber}/campaign-groups/${l1Object.l1ObjectId}/edit`}>
                    {i18n.t<string>('adGroupSetupFlow.mainStep.hints.backToEditTiktokCamapign')}
                  </Link>
                </div> :
                ''
              }
            />
          }
          {!l1Object.budget &&
            <FormikField.InputGroup
              label={i18n.t<string>('adGroupSetupFlow.mainStep.field.budget')}
              name='budget'
              prefix={currency}
              type='number'
              min={0}
              hint={budgetIsInvalid ? '' : `${i18n.t<string>('l1Object.labels.budgetBalance')} : ${totalBudget - budget}`}
            />
          }
          <FormikField.DateRangePicker
            label={i18n.t<string>('adGroupSetupFlow.mainStep.field.dateRange')}
            className={styles.orderInput}
            minDate={minDate}
            maxDate={maxDate}
            startDateFormikName='schedule_start_time'
            endDateFormikName='schedule_end_time'
            name='dateRange'
            format={'YYYY-MM-DD HH:mm:ss'}
            timeInterval={60}
            startDatePickerDisabled={isAdGroupStart}
            fieldContentWidth='auto'
            hint={dayHint}
            hoverHint={i18n.t<string>('campaignInfo.labels.dateCannotModifyHint')}
          />
        </div>
      </fieldset>
      <fieldset>
        <legend>
          <span>{i18n.t<string>('adGroupSetupFlow.mainStep.fieldset.optimizationTitle')}</span>
        </legend>
        <div className={styles.component}>
          {canEditOptimizationGoal ?
            <FormikField.Select
              label={i18n.t<string>('adGroupSetupFlow.mainStep.field.optimizationGoal')}
              name='optimize_goal'
              simpleValue
              options={optimizationGoalOptions}
              onChange={handleOptimizationGoalChanged}
              hint={showOptimiationGoalHint ?
              <div style={{ maxWidth: 500 }}>
                {i18n.t<string>('adGroupSetupFlow.mainStep.firstCBOAdGroupOptimizationGoalHint')}
              </div> : ''}
            /> :
            <FormikField.Label
              label={i18n.t<string>('adGroupSetupFlow.mainStep.field.optimizationGoal')}
              name='optimize_goal'
              formatter={optimizationGoalLabelFormatter}
              hint={showOptimiationGoalHint ?
              <div style={{ maxWidth: 500 }}>
                {i18n.t<string>('adGroupSetupFlow.mainStep.optimizationGoalFixHint')}
              </div> : ''}
            />
          }
          <FormikField.Custom
            label={i18n.t<string>('adGroupSetupFlow.mainStep.field.bidControl')}
            name='bid_type'
            render={renderBidControl}
            fieldContentWidth={500}
            hint={
              <div style={{ maxWidth: 500 }}>
                {`${i18n.t<string>(`adGroupSetupFlow.mainStep.hint.${values.bid_type.toLowerCase()}`, {
                  optimize: i18n.t<string>(`optimizationGoal.${values.optimize_goal?.toLowerCase()}`).toLowerCase(),
                  bid: formatPriceWithCurrency(order.currency, values.bid ? +(values.bid) : 0)
                })}`}
              </div>
            }
          />
          {operate === 'create' ?
            <FormikField.Select
              label={i18n.t<string>('adGroupSetupFlow.mainStep.field.billingEvent')}
              name='billing_event'
              simpleValue
              options={billingEventOptions}
            /> :
            <FormikField.Label
              label={i18n.t<string>('adGroupSetupFlow.mainStep.field.billingEvent')}
              name='billing_event'
              formatter={billingEventFormatter}
            />
          }
          {
            l1Object.budget || values.bid_type === TiktokBidStrategy.BID_TYPE_NO_BID ?
              <FormikField.Label
                label={i18n.t<string>('campaign.labels.deliverType')}
                name='pacing'
                formatter={pacingFormatter}
              /> :
              <FormikField.ToggleButton
                label={i18n.t<string>('campaign.labels.deliverType')}
                name='pacing'
                options={deliveryOptions}
              />
          }
          <div className={styles.section}>
            <SwitchField
              label={i18n.t<string>('campaignInfo.labels.daypart')}
              name='dayPartEnable'
              value={!!dayPartEnable}
              onChange={onDaypartSwitchChanged}
            />
            {!!dayPartEnable &&
              <FormikField.Custom
                label=''
                name='dayPart'
                render={renderDaypart}
              />
            }
          </div>
        </div>
      </fieldset>
    </div>
  );
};
