export interface CampaignAdTypeButtonModel {
  readonly data: adTypeButtonData;
  readonly selected: boolean;
  onClick: () => void;
}

export type CampaignAdTypeButtonProps = {
  readonly model: CampaignAdTypeButtonModel;
};

export type adTypeButtonData = {
  icon: any;
  icon2x?: any;
  title: string;
  description: string;
};

export class DefaultCampaignAdTypeButtonModel implements CampaignAdTypeButtonModel {
  selected: boolean;
  data: adTypeButtonData;
  onClick: () => void;

  constructor (data: adTypeButtonData, selected: boolean, onClick: () => void) {
    this.selected = selected;
    this.onClick = onClick;
    this.data = data;
  }
}
