import styles from './selectableColumn.module.scss';
import { ChangeEvent, PropsWithChildren, useCallback } from 'react';
import { PermissionAware } from 'core';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';

export type SelectableColumnProps = {
  id: string | number;
  isSelected: boolean;
  selectPermissionAware?: PermissionAware;
  onSelect: (id: string | number, select: boolean) => void;
};

export const SelectableColumn: React.FC<SelectableColumnProps & PropsWithChildren> = ({
  id,
  isSelected,
  selectPermissionAware,
  children,
  onSelect
}) => {

  const onCheckboxChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    onSelect && onSelect(id, e.target.checked);
  }, [id, onSelect]);

  const renderCheckbox = useCallback(() => (
    <>
      <input
        type='checkbox'
        checked={isSelected}
        onChange={onCheckboxChange}
        id={`checkbox-input-${id}`}
      />
      <label htmlFor={`checkbox-input-${id}`} />
    </>
  ), [id, isSelected, onCheckboxChange]);

  return (
    <div className={`${styles.cell}`}>
      <div className={styles.selectCheckBox}>
        {
          selectPermissionAware ?
            <PermissionChecker permissionAware={selectPermissionAware}>
              {renderCheckbox()}
            </PermissionChecker> :
            renderCheckbox()
        }
      </div>
      {children}
    </div>
  );
};
