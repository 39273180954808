import Toggle from 'components/common/Toggle/Toggle';
import moment from 'moment';
import React from 'react';
import styles from './creativeBasicForm.module.scss';
import { FormikField } from 'components/common/form/field/FormikField';

export const renderStartTime = (showStartTime, setShowStartTime, formikProps) => {
  const switchChanged = (e) => {
    const enable = e.target.checked;
    let defaultStartTime = moment().startOf('day');
    if (formikProps.values.enableEndTime &&
      moment(formikProps.values.enableEndTime).isBefore(defaultStartTime)
    ) {
      defaultStartTime = moment(formikProps.values.enableEndTime).subtract(1, 'day').startOf('day');
    }

    formikProps.setFieldValue(
      'enableStartTime',
      enable ?
        defaultStartTime.format('YYYY-MM-DD HH:mm:ss') :
        undefined
    );
    setShowStartTime(enable);
  };
  return (
    <>
      <div className={styles.timeSwitch}>
        <Toggle
          checked={showStartTime}
          onChange={switchChanged}
        />
      </div>
      {showStartTime && (
        <FormikField.DatePicker
          name={formikProps.name}
          timeInterval={30}
          maxDate={formikProps.values.enableEndTime}
        />
      )}
    </>
  );
};

export const renderEndTime = (showEndTime, setShowEndTime, formikProps) => {
  const switchChanged = (e) => {
    const enable = e.target.checked;
    let defaultEndTime = moment().add(1, 'day').startOf('day');
    if (formikProps.values.enableStartTime &&
      moment(formikProps.values.enableStartTime).isAfter(moment())
    ) {
      defaultEndTime = moment(formikProps.values.enableStartTime).add(1, 'day').startOf('day');
    }

    formikProps.setFieldValue(
      'enableEndTime',
      enable ?
        defaultEndTime.format('YYYY-MM-DD HH:mm:ss') :
        undefined
    );
    setShowEndTime(enable);
  };
  return (
    <>
      <div className={styles.timeSwitch}>
        <Toggle
          checked={showEndTime}
          onChange={switchChanged}
        />
      </div>
      {showEndTime && (
        <FormikField.DatePicker
          name={formikProps.name}
          timeInterval={30}
          minDate={formikProps.values.enableStartTime}
        />
      )}
    </>
  );
};
