import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { Order } from 'core/order/Order';
import { useEffect, useMemo, useState } from 'react';
import styles from './l1ObjectBudgetManagement.module.scss';
import _ from 'lodash';
import moment from 'moment';
import formatters from './listFormatter';
import { formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { validateMinimum } from 'utils/ValidateUtils';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';
import i18n from 'i18n';
import { CampaignState, RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';
import { useCallAPI } from 'hooks/useCallAPI';
import { DefaultFbAdSetManager } from 'core/fbAdSet/FbAdSetManager';
import { getEffectiveStatusDefaultColor } from 'components/Status/Status';

export enum L1ObjectBudgetManagementColumns {
  NAME = 'id',
  STATUS = 'status',
  SPENT = 'spent',
  BUDGET = 'budget'
}

type StatusDesData = {
  des: string;
  color: string;
};

export type L2ObjectBudgetListRow = {
  channel: L1ObjectChannel;
  id: number;
  name: string;
  status: StatusDesData;
  spent: number;
  budget: number;
  minBudget: number;
  error?: string;
};

const defaultL1ObjectManager: L1ObjectManager = new DefaultL1ObjectManager();

export const useL1ObjectBudgetManagementModel = (
  channel: L1ObjectChannel,
  order: Order,
  l1Object: Partial<L1Object>,
  initL1Object: Partial<L1Object> | undefined,
  l2ObjectList: FbAdSet[] | RtbCampaignBasic[],
  budgetDataList?: {id: string, budget: number }[],
  l1ObjectManager: L1ObjectManager = defaultL1ObjectManager
) => {

  const { loading, callAPIs } = useCallAPI();
  const [viewData, setViewData] = useState<L2ObjectBudgetListRow[]>([]);
  const [budgets, setBudgets] = useState<{[key: number]: number}>({});

  useEffect(() => {
    let fetchL2ObjectLifetimeSpents = channel === L1ObjectChannel.FB ?
      fetchFbAdSetLifetimeSpents :
      fetchRtbCampaignLifetimeSpents;
    callAPIs([
      async () => fetchL2ObjectLifetimeSpents(
        order,
        l1Object,
        budgetDataList,
        l2ObjectList,
        l1ObjectManager
      )
    ], ({
      viewData,
      budgets
    }) => {
      setViewData(viewData);
      setBudgets(budgets);
    });
  }, [channel, order, l1Object, l1ObjectManager, l2ObjectList, budgetDataList, callAPIs]);

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `l1ObjectBudgetManagement.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const onBudgetChange = (id, newBudget) => {
    const index = viewData.findIndex(data => data.id === id);
    if (index === -1) {
      return;
    }
    viewData[index].error = validateMinimum(newBudget, viewData[index].minBudget);
    setBudgets({
      ...budgets,
      [id]: +newBudget
    });
    setViewData([...viewData]);
  };

  const remainBudget = useMemo(() => {
    const budget = (initL1Object && initL1Object.budget) ? initL1Object.budget : order.budgetBalance;
    return +budget -
      Object.keys(budgets).reduce((acc, key) => acc + (+budgets[key]), 0);
  }, [order.budgetBalance, initL1Object, budgets]);

  const columns = [
    renderColumn<L2ObjectBudgetListRow, L2ObjectBudgetListRow[L1ObjectBudgetManagementColumns.NAME]>({
      ...columnDefinition(L1ObjectBudgetManagementColumns.NAME),
      footer: ''
    }, (_1, fbAdset) => fbAdset.name),
    renderColumn<L2ObjectBudgetListRow, L2ObjectBudgetListRow[L1ObjectBudgetManagementColumns.STATUS]>({
      ...columnDefinition(L1ObjectBudgetManagementColumns.STATUS),
      footer: ''
    }, formatters.stateFormatter),
    renderColumn<L2ObjectBudgetListRow, L2ObjectBudgetListRow[L1ObjectBudgetManagementColumns.SPENT]>({
      ...columnDefinition(L1ObjectBudgetManagementColumns.SPENT),
      footer: ''
    }, value => formatPriceWithCurrency(order.currency, value)),
    renderColumn<L2ObjectBudgetListRow, L2ObjectBudgetListRow[L1ObjectBudgetManagementColumns.BUDGET], {
      currency: string,
      onChange: (id, newBudget) => void
    }>({
      ...columnDefinition(L1ObjectBudgetManagementColumns.BUDGET),
      formatExtraData: {
        currency: order.currency,
        onChange: onBudgetChange
      },
      footer: i18n.t<string>(`l1ObjectBudgetManagement.hints.campaignBudget`, { total: remainBudget }),
      footerClasses: remainBudget < 0 ? styles.dangerHint : undefined
    }, formatters.budgetFormatter)
  ];

  const canSubmit = useMemo(() => {
    let hasError = remainBudget < 0;
    viewData.forEach(data => {
      if (data.error) {
        hasError = true;
      }
    });
    return !hasError;
  }, [remainBudget, viewData]);

  return {
    loading,
    viewData,
    columns,
    budgets,
    canSubmit
  };
};

const getAdSetStatusDesData = (adSet): StatusDesData => {
  let des = _.startCase(_.lowerCase(adSet.status));
  let color = getEffectiveStatusDefaultColor(adSet.status);
  switch (adSet.status) {
    case 'PAUSED':
      des = i18n.t<string>('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case 'DELETED':
    case 'ARCHIVED':
      des = i18n.t<string>('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case 'ACTIVE':
      des = i18n.t<string>('campaignList.labels.activateState');
      break;
    default:
      break;
  }

  return {
    des,
    color
  };
};

const getRtbCampaignStatusDesData = (campaign): StatusDesData => {
  let des = '';
  let color;
  switch (campaign.state) {
    case CampaignState.DEACTIVATE:
      des = i18n.t<string>('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case CampaignState.DELETE:
      des = i18n.t<string>('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case CampaignState.ACTIVATE:
      des = i18n.t<string>('campaignList.labels.activateState');
      break;
    default:
      des = 'In Process';
      color = 'whiteTheme4';
      break;
  }

  return {
    des,
    color
  };
};

async function fetchFbAdSetLifetimeSpents (
  order,
  l1Object,
  budgetDataList,
  l2ObjectList,
  l1ObjectManager
): Promise<{
  viewData: L2ObjectBudgetListRow[],
  budgets: {[key: number]: number}
}> {
  const fbAdSetManager = new DefaultFbAdSetManager();
  try {
    const spentData = l1Object.l1Objectid ? await l1ObjectManager.getAdSetLifetimeBudgetOfCampaign(l1Object.l1Objectid) : {};
    const budgetDataMap: {[id: number]: number} = budgetDataList ? budgetDataList.reduce((acc, budget) => {
      return {
        ...acc,
        [budget.id]: budget.budget
      };
    }, {}) : {};
    const viewData = l2ObjectList.map(l2Object => {
      const spent = spentData[l2Object.id] ? spentData[l2Object.id] : 0;
      const startDateMoment = moment(l2Object.start_time);
      const endDateMoment = moment(l2Object.end_time);
      const scheduleDateCount = endDateMoment.diff(startDateMoment, 'days') + 1;
      let minBudget = fbAdSetManager.getMinBudgetOfFBObject(
        _.defaultTo(l1Object.currencyRate, 1),
        +_.get(l2Object, 'bid_amount', 0),
        _.get(l1Object, 'fb.bid_strategy') ? _.get(l1Object, 'fb.bid_strategy') : _.get(l2Object, 'bid_strategy'),
        _.get(l2Object, 'billing_event'),
        scheduleDateCount,
        order,
        spent * 1.1
      );
      const budget = budgetDataMap[l2Object.id] ? budgetDataMap[l2Object.id] : minBudget;
      budgetDataMap[l2Object.id] = budget;
      return {
        channel: L1ObjectChannel.FB,
        id: l2Object.id,
        name: l2Object.name,
        status: getAdSetStatusDesData(_.get(l2Object, 'configured_status')),
        spent,
        budget,
        minBudget
      };
    });
    return {
      viewData,
      budgets: budgetDataMap
    };
  } catch (e) {
    return {
      viewData: [],
      budgets: {}
    };
  }
}

export async function fetchRtbCampaignLifetimeSpents (
  order,
  l1Object,
  budgetDataList,
  l2ObjectList
): Promise<{
  viewData: L2ObjectBudgetListRow[],
  budgets: {[key: number]: number}
}> {

  let totalBudget = l1Object.budget;
  const currencyRate = _.get(l1Object, 'currencyRate', 1);
  const increaseBudgetToTarget = (origin: number, target: number) => {
    if (totalBudget <= 0) {
      return origin;
    }
    if (origin >= target) {
      return origin;
    }
    let amount = target - origin;
    if (totalBudget < amount) {
      amount = totalBudget;
    }
    totalBudget -= amount;
    return origin + amount;
  };
  try {
    const budgetDataMap = budgetDataList ? budgetDataList.reduce((acc, budget) => {
      return {
        ...acc,
        [budget.id]: budget.budget
      };
    }, {}) : {};
    const spentMap = l2ObjectList.reduce((acc, l2Object) => {
      const spent = Math.max(
        _.defaultTo(l2Object.olapActualSpent, 0),
        _.defaultTo(l2Object.olapExpectSpent, 0)
      ) * currencyRate;
      acc[l2Object.id] = spent;
      return acc;
    }, {});

    const l2LevelNoRemainBudget = l2ObjectList.every(l2Object => {
      return spentMap[l2Object.id] > l2Object.budget;
    });
    const canNotAssignBudget = l2ObjectList.reduce((acc, l2Object) => acc + l2Object.budget, 0) >= l1Object.budget;
    let initBudgetMap;
    if (l2LevelNoRemainBudget && canNotAssignBudget) {
      initBudgetMap = l2ObjectList.reduce((acc, l2Object) => {
        acc[l2Object.id] = l2Object.budget;
        return acc;
      }, {});
    } else {
      // first initialize budget with min(budget, spent)
      initBudgetMap = l2ObjectList.reduce((acc, l2Object) => {
        acc[l2Object.id] = increaseBudgetToTarget(0, Math.min(spentMap[l2Object.id], l2Object.budget));
        return acc;
      }, {});

      // increase budget to spent if has remain budget
      l2ObjectList.forEach(l2Object => {
        initBudgetMap[l2Object.id] = increaseBudgetToTarget(initBudgetMap[l2Object.id], spentMap[l2Object.id]);
      });

      // increase budget to minimum required budget if has remain budget
      if (totalBudget > 0) {
        let minL2ObjectBudget = order.campaignConstraint.campaignBudgetMinimum;
        const l2ObjectLessThanMinBudget = l2ObjectList.filter(l2Object => initBudgetMap[l2Object.id] < minL2ObjectBudget);
        let remainBudget = totalBudget + l2ObjectLessThanMinBudget.reduce((acc, l2Object) => acc + initBudgetMap[l2Object.id], 0);
        let allBudgetToAssign = minL2ObjectBudget * l2ObjectLessThanMinBudget.length;
        if (remainBudget < allBudgetToAssign) {
          minL2ObjectBudget = getPriceValue(l1Object.currency, remainBudget / l2ObjectLessThanMinBudget.length);
          allBudgetToAssign = remainBudget;
        }
        l2ObjectLessThanMinBudget.forEach((l2Object, index) => {
          const lastOne = index === l2ObjectLessThanMinBudget.length - 1;
          initBudgetMap[l2Object.id] = lastOne ? allBudgetToAssign : minL2ObjectBudget;
          allBudgetToAssign -= minL2ObjectBudget;
        });
      }
    }

    const viewData = l2ObjectList.map(l2Object => {
      const budget = _.defaultTo(budgetDataMap[l2Object.id], getPriceValue(l1Object.currency, initBudgetMap[l2Object.id]));
      budgetDataMap[l2Object.id] = budget;
      return {
        channel: L1ObjectChannel.RTB,
        id: l2Object.id,
        name: l2Object.name,
        status: getRtbCampaignStatusDesData(_.get(l2Object, 'state')),
        spent: spentMap[l2Object.id],
        budget,
        minBudget: 0
      };
    });
    return {
      viewData,
      budgets: budgetDataMap
    };
  } catch (e) {
    return {
      viewData: [],
      budgets: {}
    };
  }
}
