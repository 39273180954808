import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { TiktokBillingEvent, TiktokAdGroup, TiktokPlacementMapping, ADGROUP_DEFAULT_AGE_MAX, ADGROUP_DEFAULT_AGE_MIN, TiktokBidStrategy, Pacing } from 'core/tiktokAdGroup/TiktokAdGroup';
import { FinalReportGender, Order } from 'core/order/Order';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { TiktokAdGroupFormModelData, useTiktokCreateAdGroupFormModel, useTiktokEditAdGroupFormModel } from './steps/TiktokAdGroupFormModel';
import { DefaultTiktokAdGroupManager, TiktokAdGroupManager } from 'core/tiktokAdGroup/TiktokAdGroupManager';
import { useRouteMatch } from 'react-router-dom';
import moment from 'moment';
import i18n from 'i18n';
import _ from 'lodash';
import { getDayPartSummary } from 'components/Dayparts/Dayparts';
import { SelectOptions } from 'components/common/commonType';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { LimitationManager, DefaultLimitationManager } from 'core/limitation/LimitationManager';
import { getTiktokLocationIDByAlpha3 } from 'utils/CountryUtil';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { SummaryTitleColor } from 'components/SummaryDetail/SummaryDetail';
import { useCallAPI } from 'hooks/useCallAPI';
import { validateEmpty, validateMinimum } from 'utils/ValidateUtils';
import { renderOverBudgetWording } from 'containers/L2Objects/FormHintRenderFunctions';
import { formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import { tiktokAgeMaxOptions } from 'core/limitation/l2ObjectTAOptions';
import { useCoreContext } from 'contexts/coreContext';
import { DefaultEditLimitationModel, EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { adGroupInventorySetting } from 'containers/Limitations/LimitationSetting/LimitationSettingData';
import { OPERATE } from 'enum/Operate';
import { SavedTargeting } from 'core/limitation/Limitation';
import { EstimatedAudience } from 'core/goSegment/GoSegment';
import { useGetSavedTargeting } from 'hooks/useGetSavedTargeting';
import { DraftManager, TiktokAdGroupDraftManager } from 'core/draft/DraftManager';
import { toast } from 'react-toastify';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { DefaultL2ObjectManager, L2ObjectManager } from 'core/l2Object/L2ObjectManager';

const MAIN_STEP_INDEX = 0;

export enum TiktokAdGroupMainStepTab {
  BASIC,
  TARGETING
}

export type TiktokAdGroupFormData = {
  advertiser_id: number;
  adgroup_name: string;
  budget: number | string;
  schedule_start_time: string;
  schedule_end_time: string;
  optimize_goal: L2ObjectOptimizationGoal;
  billing_event: TiktokBillingEvent;
  bid_type: TiktokBidStrategy;
  bid?: number;
  targeting?: {
    [key: string]: any
  },
  ageMin?: any,
  ageMax?: any,
  gender?: any,
  location?: any,
  placement?: any,
  operation_system?: any,
  audience?: any,
  excluded_audience?: any,
  pacing?: Pacing,
  dayPart?: { [key: string]: string[] | number[] | string };
};

export type AdGroupSetupFlowPageModelData = {
  adGroupId?: string,
  objectType: 'adGroup' | 'draft',
  operate: 'create' | 'edit',
  title: string,
  loading: boolean,
  finished: boolean,
  tiktokCountries: SelectOptions[],
  goSegments: SelectOptions[],
  adGroup: TiktokAdGroupFormData | undefined,
  initAdGroup: TiktokAdGroupFormData | undefined,
  redirectPath: string | undefined,
  canEditOptimizationGoal: boolean,
  canEditBidStrategy: boolean,
  otherAdGroupMinBudget: number,
  breadcrumbs: any[],
  limitationModel?: EditLimitationModel,
  audienceLowestThreshold: number,
  savedTAList: SavedTargeting[],
  appliedSavedTAInfo?: SelectOptions,
  showTAManagement: boolean,
  estimatedData?: EstimatedAudience,
  showPublishBindingFailed: boolean,
  defaultOptimizationGoal?: L2ObjectOptimizationGoal,
  showAddSaveTargetingModal: boolean;
  setFinished: (finish: boolean) => void,
  onSaveDraft?: (adGroup: TiktokAdGroupFormData) => void,
  setEsitimatedData: (estimatedData: EstimatedAudience) => void,
  onImportSavedTA: (savedTAInfo: SelectOptions, limitationValue) => void,
  onDeleteSavedTA: (deletedTAId: number) => void,
  setShowTAManagement: (show: boolean) => void,
  validate: (adGroup: TiktokAdGroupFormData) => any,
  submit: () => Promise<void>,
  setAdGroup: (adGroup: TiktokAdGroupFormData) => void,
  setInitAdGroup: (adGroup: TiktokAdGroupFormData) => void,
  getSummaryData: (adGroup: TiktokAdGroupFormData) => any,
  getTASummaryData: (limitationValue: any) => any,
  setRedirectPath: (redirectPath?: string) => void,
  getBasicFormConfig: (adGroup: TiktokAdGroupFormData) => any,
  backToL1ObjectDetail: () => void,
  adGroupFormModel: (order: Order, l1Object: L1Object, adGroup: TiktokAdGroupFormData, tiktokAdGroupManager: TiktokAdGroupManager) => TiktokAdGroupFormModelData
  onShowAddSaveTargetingModal: (showModal: boolean) => void;
};

const defaultTiktokAdGroupManager = new DefaultTiktokAdGroupManager();
const defaultDraftManager = new TiktokAdGroupDraftManager();
const defaultAdRequestSourceManager = new DefaultAdRequestSourceManager();
const defaultLimitationManager = new DefaultLimitationManager();
const defaultL2ObjectManager = new DefaultL2ObjectManager();

const useTiktokAdGroupSetupFlowPageModel = (
  operate,
  order: Order,
  l1Object: L1Object,
  otherTiktokAdGroupList: TiktokAdGroup[],
  tiktokAdGroupManager: TiktokAdGroupManager = defaultTiktokAdGroupManager,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager
) => {

  const [appliedSavedTAInfo, setAppliedSavedTAInfo] = useState<SelectOptions | undefined>();
  const [redirectPath, setRedirectPath] = useState<string | undefined>();
  const [finished, setFinished] = useState<boolean>(false);
  const [tiktokCountries, setTiktokCountries] = useState<SelectOptions[]>([]);
  const [goSegments, setGoSegments] = useState<SelectOptions[]>([]);
  const [estimatedData, setEsitimatedData] = useState<EstimatedAudience | undefined>();
  const [showAddSaveTargetingModal, setShowAddSaveTargetingModal] = useState<boolean>(false);
  const {
    loading,
    callAPIs
  } = useCallAPI();

  const [showTAManagement, setShowTAManagement] = useState(false);

  const inventorySetting = useMemo(() => {
    return adGroupInventorySetting(operate, _.get(l1Object, 'objective', ''), tiktokCountries, goSegments, order.orderType);
  }, [operate, l1Object, tiktokCountries, goSegments, order.orderType]);

  const fetchTiktokCountries = useCallback(async () => {
    try {
      const tiktokCountries = await adRequestSourceManager.getTiktokCountries();
      setTiktokCountries(tiktokCountries);
      return tiktokCountries;
    } catch (e) {}
  }, [adRequestSourceManager]);

  const fetchTiktokSegments = useCallback(async () => {
    try {
      const goSegments = await adRequestSourceManager.getGoSegments(order.advertiserId, L1ObjectChannel.TIKTOK);
      setGoSegments(goSegments);
    } catch (e) {}
  }, [order.advertiserId, adRequestSourceManager]);

  const backToL1ObjectDetail = () => {
    const redirectPath =
      `/orders/${order.orderNumber}/campaign-groups/${l1Object.l1ObjectId}`;
    setRedirectPath(redirectPath);
  };

  const otherAdGroupMinBudget = useMemo(() => {
    const otherList = _.defaultTo(otherTiktokAdGroupList, []);
    const minBudget = otherList.reduce((acc, tiktokAdGroup) => {
      const startDateMoment = moment(tiktokAdGroup.schedule_start_time);
      const endDateMoment = moment(tiktokAdGroup.schedule_end_time);
      const scheduleDateCount = endDateMoment.diff(startDateMoment, 'days') + 1;
      return acc +
        tiktokAdGroupManager.getMinBudgetOfAdGroup(
          l1Object.currencyRate,
          scheduleDateCount,
          order
        );
    }, 0);

    return minBudget;
  }, [otherTiktokAdGroupList, l1Object, order, tiktokAdGroupManager]);

  const validate = (initAdGroup, uniqueAdGroupNames, adGroup) => {
    const parentBidStrategy: TiktokBidStrategy | undefined = _.get(l1Object, 'tiktok.bid_type');
    const adGroupBidStrategy = _.get(adGroup, 'bid_type');
    const startDateMoment = moment(adGroup.schedule_start_time);
    const endDateMoment = moment(adGroup.schedule_end_time);
    const scheduleDateCount = endDateMoment.diff(startDateMoment, 'days') + 1;
    const minBudget = tiktokAdGroupManager.getMinBudgetOfAdGroup(
      l1Object.currencyRate,
      scheduleDateCount,
      order
    );

    return _.omitBy({
      adgroup_name: validateAdGroupName(adGroup.adgroup_name, uniqueAdGroupNames),
      budget: l1Object.budget ?
        validateCampaignBudget(order.currency, minBudget, l1Object, otherAdGroupMinBudget) :
        validateBudget(order, l1Object, initAdGroup, adGroup, minBudget),
      bid: parentBidStrategy === TiktokBidStrategy.BID_TYPE_CUSTOM ||
        adGroupBidStrategy === TiktokBidStrategy.BID_TYPE_CUSTOM ?
          validateMinimum(adGroup.bid, 1, 'campaign.descriptions.priceMinimum', order.currency) :
          undefined
    }, _.isEmpty);
  };

  const {
    loading: loadingSavedTAList,
    savedTAList,
    fetchSavedTargeting
  } = useGetSavedTargeting(order.advertiserId, L1ObjectChannel.TIKTOK, order.orderType);

  const onDeleteSavedTA = (deletedTAId: number) => {
    if (_.get(appliedSavedTAInfo, 'value') === deletedTAId) {
      setAppliedSavedTAInfo(undefined);
    }
    fetchSavedTargeting();
  };

  const onShowAddSaveTargetingModal = (showModal: boolean) => {
    setShowAddSaveTargetingModal(showModal);
  };

  const orderStartDate = moment(_.get(order, 'startDate'));
  const thisHour = moment().startOf('hour').format('YYYY-MM-DD_HH:mm:ss');
  const baseBreadcrumb = useMemo(() => [
    { path: '/orders', breadcrumb: i18n.t<string>('orderDetail.labels.title') },
    {
      path: '/orders/:orderNumber',
      breadcrumb: DynamicBreadcrumb,
      props: {
        label: _.get(order, 'projectName'),
        matchParam: 'orderNumber'
      }
    },
    {
      path: '/orders/:orderNumber/campaign-groups/:l1ObjectId',
      breadcrumb: DynamicBreadcrumb,
      props: {
        label: _.get(l1Object, 'name'),
        matchParam: 'l1ObjectId'
      }
    }
  ], [order, l1Object]);

  return {
    loading: loading || loadingSavedTAList,
    savedTAList,
    tiktokCountries,
    redirectPath,
    finished,
    goSegments,
    inventorySetting,
    otherAdGroupMinBudget,
    audienceLowestThreshold: 3000000,
    showTAManagement,
    appliedSavedTAInfo,
    estimatedData,
    defaultMinDate: moment().isAfter(orderStartDate) ? thisHour : orderStartDate.format('YYYY-MM-DD_HH:mm:ss'),
    baseBreadcrumb,
    setEsitimatedData,
    validate,
    callAPIs,
    setFinished,
    onDeleteSavedTA,
    setRedirectPath,
    setShowTAManagement,
    getSummaryData: _.partial(getSummaryData, l1Object, order.currency, tiktokCountries, tiktokAdGroupManager),
    getTASummaryData: _.partial(getTASummaryData, tiktokCountries, tiktokAdGroupManager),
    fetchTiktokCountries,
    fetchTiktokSegments,
    backToL1ObjectDetail,
    setAppliedSavedTAInfo,
    showAddSaveTargetingModal,
    onShowAddSaveTargetingModal
  };
};

export const useCreateTiktokAdGroupSetupFlowPageModel = (
  order: Order,
  l1Object: L1Object,
  tiktokAdGroupList: TiktokAdGroup[],
  tiktokAdGroupManager: TiktokAdGroupManager = defaultTiktokAdGroupManager,
  limitationManager: LimitationManager = defaultLimitationManager,
  l2ObjectManager: L2ObjectManager = defaultL2ObjectManager
): AdGroupSetupFlowPageModelData => {

  const {
    callAPIs,
    fetchTiktokCountries,
    fetchTiktokSegments,
    validate,
    setAppliedSavedTAInfo,
    setShowTAManagement,
    otherAdGroupMinBudget,
    tiktokCountries,
    goSegments,
    inventorySetting,
    audienceLowestThreshold,
    defaultMinDate,
    baseBreadcrumb,
    ...basicProps
  } = useTiktokAdGroupSetupFlowPageModel(
    'create', order, l1Object, tiktokAdGroupList, tiktokAdGroupManager
  );

  const defaultOptimizationGoal = useMemo(() => {
    // TODO if campaign group turn on budget optimize, it should has optimize_goal!
    const allList = _.defaultTo(tiktokAdGroupList, []);
    const notDraftList = allList.filter(adGroup => !adGroup.draftId);
    if (notDraftList.length === 0 || !l1Object.autoOptimise) {
      return undefined;
    }

    return notDraftList[0].optimize_goal;
  }, [tiktokAdGroupList, l1Object.autoOptimise]);

  const defaultAdGroup = getDefaultTiktokAdGroup(order, l1Object, tiktokAdGroupList, tiktokAdGroupManager, l2ObjectManager);
  const [adGroup, setAdGroup] = useState<TiktokAdGroupFormData>(defaultAdGroup);
  const [initAdGroup, setInitAdGroup] = useState(adGroup);
  const [uniqueAdGroupNames, setUniqueAdGroupNames] = useState<string[]>([]);
  const core = useCoreContext();
  const campaignId = _.get(l1Object, 'tiktok.campaign_id', '');

  const limitationModel = useMemo(() => {
    if (!core || !adGroup.targeting) {
      return;
    }
    return new DefaultEditLimitationModel(
      inventorySetting,
      adGroup.targeting,
      {
        need: [OPERATE.INCLUDE],
        notNeed: [OPERATE.EXCLUDE]
      },
      core.addonFeatureManager.addonFeature,
      _.partial(setShowTAManagement, true),
      {
        channel: L1ObjectChannel.TIKTOK,
        audienceLowestThreshold,
        accountId: adGroup.advertiser_id.toString(),
        estimatedRequiredFields: ['location'],
        channelTargetingGetter: (limitationValue) => ({
          campaign_id: campaignId,
          advertiser_id: adGroup.advertiser_id,
          optimize_goal: tiktokAdGroupManager.getNativeOptimizeGoalValue(adGroup.optimize_goal),
          ...tiktokAdGroupManager.getAdGroupTargeting(limitationValue)
        })
      }
    );
  }, [campaignId, adGroup.targeting, adGroup.advertiser_id, adGroup.optimize_goal, core, inventorySetting, audienceLowestThreshold, setShowTAManagement, tiktokAdGroupManager]);

  useEffect(() => {
    callAPIs(
      [tiktokAdGroupManager.getUniqueAdGroupNames.bind(tiktokAdGroupManager, campaignId)],
      (names: string[]) => {
        setUniqueAdGroupNames(names);
      }
    );
  }, [campaignId, callAPIs, tiktokAdGroupManager]);

  const fetchLimitationPreset = useCallback(async () => {
    try {
      return await limitationManager.getLimitationPreSet('campaign');
    } catch (e) {}
  }, [limitationManager]);

  useEffect(() => {
    callAPIs([fetchTiktokCountries, fetchLimitationPreset, fetchTiktokSegments], (tiktokCountries, limitationPreset) => {
      if (tiktokCountries) {
        const changeAdGroupState = _.partial(onTiktokCountriesFetched, limitationPreset, tiktokCountries);
        setAdGroup(changeAdGroupState);
        setInitAdGroup(changeAdGroupState);
      }
    });
  }, [fetchTiktokCountries, fetchTiktokSegments, fetchLimitationPreset, callAPIs]);

  useEffect(() => {
    const changeAdGroupState = adGroup => ({
      ...adGroup,
      optimize_goal: _.defaultTo(defaultOptimizationGoal, tiktokAdGroupManager.getDefaultOptimizationGoal(l1Object))
    });
    setAdGroup(changeAdGroupState);
    setInitAdGroup(changeAdGroupState);
  }, [defaultOptimizationGoal, l1Object, tiktokAdGroupManager]);

  const submit = async () => {
    callAPIs([tiktokAdGroupManager.createAdGroup.bind(tiktokAdGroupManager, l1Object, adGroup)], () => {
      basicProps.setFinished(true);
      basicProps.backToL1ObjectDetail();
    });
  };

  const canEditBidStrategy = !l1Object.autoOptimise;
  const onImportSavedTA = (savedTAInfo: SelectOptions, limitationValue) => {
    if (!limitationModel) {
      return;
    }
    setAppliedSavedTAInfo(savedTAInfo);
    limitationModel.updateLimitationValue(limitationValue);
  };

  return {
    ...basicProps,
    audienceLowestThreshold,
    limitationModel,
    goSegments,
    tiktokCountries,
    objectType: 'adGroup',
    operate: 'create',
    adGroup,
    initAdGroup,
    otherAdGroupMinBudget,
    title: i18n.t<string>('adGroupSetupFlow.mainStep.createTitle'),
    defaultOptimizationGoal,
    canEditOptimizationGoal: !defaultOptimizationGoal,
    canEditBidStrategy,
    showPublishBindingFailed: false,
    breadcrumbs: [
      ...baseBreadcrumb,
      { path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/campaigns/new', breadcrumb: i18n.t<string>('adGroupSetupFlow.mainStep.createTitle') }
    ],
    validate: _.partial(validate, initAdGroup, uniqueAdGroupNames),
    submit,
    setAdGroup,
    setInitAdGroup,
    onImportSavedTA,
    setShowTAManagement,
    getBasicFormConfig: () => {
      return {
        minDate: defaultMinDate,
        maxDate: moment(_.get(order, 'endDate')).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        isAdGroupStart: false,
        showBidStrategyFixHint: !canEditBidStrategy,
        showOptimiationGoalHint: l1Object.autoOptimise
      };
    },
    adGroupFormModel: useTiktokCreateAdGroupFormModel
  };
};

export const useEditTiktokAdGroupSetupFlowPageModel = (
  order: Order,
  l1Object: L1Object,
  tiktokAdGroupList: TiktokAdGroup[],
  tiktokAdGroupManager: TiktokAdGroupManager = defaultTiktokAdGroupManager
): AdGroupSetupFlowPageModelData => {

  const match = useRouteMatch<{adGroupId: string}>();

  const adGroupId = match.params.adGroupId;
  const {
    callAPIs,
    validate,
    fetchTiktokCountries,
    fetchTiktokSegments,
    setRedirectPath,
    setAppliedSavedTAInfo,
    setShowTAManagement,
    otherAdGroupMinBudget,
    goSegments,
    tiktokCountries,
    inventorySetting,
    audienceLowestThreshold,
    defaultMinDate,
    baseBreadcrumb,
    ...basicProps
  } = useTiktokAdGroupSetupFlowPageModel(
    'edit',
    order,
    l1Object,
    tiktokAdGroupList.filter(tiktokAdGroup => tiktokAdGroup.adgroup_id.toString() !== adGroupId),
    tiktokAdGroupManager
  );

  const [adGroup, setAdGroup] = useState<TiktokAdGroupFormData | undefined>(undefined);
  const [initAdGroup, setInitAdGroup] = useState(adGroup);
  const [uniqueAdGroupNames, setUniqueAdGroupNames] = useState<string[]>([]);
  const parentBidStrategy: TiktokBidStrategy | undefined = _.get(l1Object, 'tiktok.bid_strategy');
  const campaignId = _.get(l1Object, 'tiktok.campaign_id', '');

  useEffect(() => {
    if (!adGroupId) {
      return;
    }
    callAPIs([
      tiktokAdGroupManager.getAdGroup.bind(tiktokAdGroupManager, adGroupId),
      tiktokAdGroupManager.getUniqueAdGroupNames.bind(tiktokAdGroupManager, campaignId),
      fetchTiktokCountries,
      fetchTiktokSegments
    ], (tiktokAdGroup, adGroupNames: string[]) => {
      const adGroupFormData = {
        ...tiktokAdGroup,
        bid: tiktokAdGroup.bid ? tiktokAdGroup.bid : '',
        bid_type: parentBidStrategy ? parentBidStrategy as TiktokBidStrategy : tiktokAdGroup.bid_type
      };
      setAdGroup(adGroupFormData);
      setInitAdGroup(adGroupFormData);
      setUniqueAdGroupNames(_.filter(adGroupNames, (name) => name !== _.get(adGroupFormData, 'adgroup_name')));
      if (_.get(l1Object, 'tiktok.campaign_id') !== tiktokAdGroup.campaign_id || tiktokAdGroup.opt_status === 'DELETE') {
        const redirectPath =
          `/orders/${order.orderNumber}/campaign-groups/${l1Object.l1ObjectId}/campaigns/${adGroupId}/edit/error404`;
        setRedirectPath(redirectPath);
      }
    });
  }, [l1Object, adGroupId, tiktokAdGroupManager, fetchTiktokCountries, callAPIs, campaignId, parentBidStrategy, order.orderNumber, setRedirectPath, fetchTiktokSegments]);

  const submit = async () => {
    if (!adGroup) {
      return;
    }
    callAPIs([tiktokAdGroupManager.editAdGroup.bind(tiktokAdGroupManager, adGroupId, adGroup)], () => {
      basicProps.setFinished(true);
      basicProps.backToL1ObjectDetail();
    });
  };

  const core = useCoreContext();

  const targeting = _.get(adGroup, 'targeting');
  const advertiserId = _.get(adGroup, 'advertiser_id');
  const optimizeGoal = _.get(adGroup, 'optimize_goal');
  const limitationModel = useMemo(() => {
    return core && targeting && advertiserId ? new DefaultEditLimitationModel(
      inventorySetting,
      targeting,
      {
        need: [OPERATE.INCLUDE],
        notNeed: [OPERATE.EXCLUDE]
      },
      core.addonFeatureManager.addonFeature,
      _.partial(setShowTAManagement, true),
      {
        channel: L1ObjectChannel.TIKTOK,
        audienceLowestThreshold,
        accountId: advertiserId.toString(),
        estimatedRequiredFields: ['location'],
        channelTargetingGetter: (limitationValue) => ({
          campaign_id: campaignId,
          advertiser_id: advertiserId,
          optimize_goal: optimizeGoal ? tiktokAdGroupManager.getNativeOptimizeGoalValue(optimizeGoal) : undefined,
          ...tiktokAdGroupManager.getAdGroupTargeting(limitationValue)
        })
      }
    ) : undefined;
  }, [core, targeting, advertiserId, inventorySetting, setShowTAManagement, audienceLowestThreshold, campaignId, optimizeGoal, tiktokAdGroupManager]);

  const onImportSavedTA = (savedTAInfo: SelectOptions, limitationValue) => {
    if (!limitationModel) {
      return;
    }
    setAppliedSavedTAInfo(savedTAInfo);
    limitationModel.updateLimitationValue(limitationValue);
  };

  return {
    ...basicProps,
    audienceLowestThreshold,
    limitationModel,
    goSegments,
    tiktokCountries,
    objectType: 'adGroup',
    operate: 'edit',
    adGroup,
    initAdGroup,
    title: i18n.t<string>('adGroupSetupFlow.mainStep.editTitle'),
    canEditOptimizationGoal: false,
    canEditBidStrategy: false,
    otherAdGroupMinBudget,
    showPublishBindingFailed: false,
    breadcrumbs: [
      ...baseBreadcrumb,
      {
        path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/campaigns/:adGroupId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t<string>('common.labels.edit'),
          label: _.get(initAdGroup, 'adgroup_name'),
          matchParam: 'adGroupId'
        }
      }
    ],
    submit,
    validate: _.partial(validate, initAdGroup, uniqueAdGroupNames),
    setAdGroup,
    setInitAdGroup,
    setRedirectPath,
    onImportSavedTA,
    setShowTAManagement,
    getBasicFormConfig: (adGroup) => {
      const thisHour = moment().startOf('hour').format('YYYY-MM-DD_HH:mm:ss');
      let minDate;
      if (adGroup && adGroup.schedule_start_time) {
        minDate = moment(adGroup.schedule_start_time).isBefore() ? moment(adGroup.schedule_start_time).format('YYYY-MM-DD_HH:mm:ss') : thisHour;
      } else {
        minDate = defaultMinDate;
      }
      return {
        minDate,
        maxDate: moment(_.get(order, 'endDate')).endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        isAdGroupStart: adGroup && adGroup.schedule_start_time ? moment(adGroup.schedule_start_time).isBefore() : false,
        showBidStrategyFixHint: false,
        showOptimiationGoalHint: false
      };
    },
    adGroupFormModel: useTiktokEditAdGroupFormModel
  };
};

export const useEditTiktokAdGroupDraftSetupFlowPageModel = (
  order: Order,
  l1Object: L1Object,
  tiktokAdGroupList: TiktokAdGroup[],
  draftManager: DraftManager = defaultDraftManager,
  tiktokAdGroupManager: TiktokAdGroupManager = defaultTiktokAdGroupManager
): AdGroupSetupFlowPageModelData => {

  const match = useRouteMatch<{draftId: string}>();
  const draftId = match.params.draftId;

  const data = useCreateTiktokAdGroupSetupFlowPageModel(
    order,
    l1Object,
    tiktokAdGroupList
  );

  const { loading, callAPIs } = useCallAPI();
  const { defaultOptimizationGoal, setAdGroup, setInitAdGroup } = data;
  const [showPublishBindingFailed, setShowPublishBindingFailed] = useState(false);

  useEffect(() => {
    callAPIs([() => draftManager.getDraft(draftId)], (draft) => {
      const optimizationGoalOptions = tiktokAdGroupManager.getOptimizationGoalOptions(l1Object);
      const optimizeGoalOption = optimizationGoalOptions.find(option => option.value === draft.optimize_goal);
      const optimizeGoal = defaultOptimizationGoal ?
        defaultOptimizationGoal :
        optimizeGoalOption ?
        optimizeGoalOption.value :
          tiktokAdGroupManager.getDefaultOptimizationGoal(l1Object);
      const billingEventOptions = tiktokAdGroupManager.getBilliingEventOptions(optimizeGoal);
      const billingEventOption = billingEventOptions.find(option => option.value === draft.billing_event);
      const billingEvent = billingEventOption ?
        billingEventOption.value :
        tiktokAdGroupManager.getDefaultBillingEvent(l1Object);
      const newDraft = {
        ...draft,
        optimize_goal: optimizeGoal,
        billing_event: billingEvent
      };
      setAdGroup(newDraft);
      setInitAdGroup(newDraft);
    });
  }, [draftId, draftManager, setAdGroup, setInitAdGroup, callAPIs, defaultOptimizationGoal, tiktokAdGroupManager, l1Object]);

  const onSaveDraft = (adGroup: TiktokAdGroupFormData) => {
    callAPIs([() => draftManager.updateDraft(draftId, tiktokAdGroupManager.wrapAdGroupForServer(adGroup))], () => {
      toast.success(i18n.t<string>('adGroupSetupFlow.messages.updateDraftSuccess'));
    }, () => {
      toast.error(i18n.t<string>('adGroupSetupFlow.messages.updateDraftFailed'));
    });
  };

  const onPublishDraft = async () => {
    const adGroup = data.adGroup;
    if (!adGroup) {
      return;
    }
    callAPIs([draftManager.publishDraft.bind(draftManager, draftId, tiktokAdGroupManager.wrapAdGroupForServer(adGroup))], ({
      includeBinding,
      result
    }) => {
      if (result === 'SUCCESS') {
        if (includeBinding) {
          toast.success(i18n.t<string>('adGroupSetupFlow.messages.publishL2L3Success'));
        } else {
          toast.success(i18n.t<string>('adGroupSetupFlow.messages.publishL2Success'));
        }
        data.setFinished(true);
        data.backToL1ObjectDetail();
      }
      if (result === 'PARTIAL_SUCCESS') {
        setShowPublishBindingFailed(true);
      } else if (result === 'FAIL') {
        toast.error(i18n.t<string>('adGroupSetupFlow.messages.publishL2L3Failed'));
      }
    });
  };

  return {
    ...data,
    objectType: 'draft',
    title: i18n.t<string>('campaign.labels.editCampaignDraftTitle'),
    loading: data.loading || loading,
    showPublishBindingFailed,
    breadcrumbs: [
      ...data.breadcrumbs.splice(0, 3),
      {
        path: '/orders/:orderNumber/campaign-groups/:l1ObjectId/drafts/:draftId/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          prefix: i18n.t<string>('common.labels.edit'),
          label: _.get(data.initAdGroup, 'adgroup_name'),
          matchParam: 'draftId'
        }
      }
    ],
    onSaveDraft,
    submit: onPublishDraft,
    getSummaryData: (adGroup: any) => {
      const summaryData = data.getSummaryData(adGroup);
      if (adGroup.bindings && adGroup.bindings.length > 0) {
        const now = moment();
        const isDeliverying = now.isAfter(moment(adGroup.schedule_start_time)) && now.isBefore(moment(adGroup.schedule_end_time));
        const isActive = adGroup.opt_status === 'ENABLE';
        const hasActiveBinding = adGroup.bindings.find(binding => binding.active) !== undefined;
        const showAlert = isDeliverying && isActive && hasActiveBinding;
        summaryData['draftBindingSummary'] = {
          title: i18n.t<string>('adGroupSetupFlow.summaryStep.draftBindingTitle'),
          data: {
            draftBinding: {
              content: _.compact([
                {
                  label: i18n.t<string>('adGroupSetupFlow.summaryStep.labels.draftBinding'),
                  value: adGroup.bindings.length,
                  hintColor: 'red',
                  hint: showAlert ? i18n.t<string>('adGroupSetupFlow.summaryStep.message.willDeliverImmediately') : undefined
                }
              ])
            }
          }
        };
      }
      return summaryData;
    }
  };
};

const getTASummaryData = (tiktokCountries, tiktokAdGroupManager, targeting) => {
  const summaryData = tiktokAdGroupManager.getTASummaryData(targeting, tiktokCountries.map(country => country.value));
  return _.omitBy({
    general: {
      title: i18n.t<string>('campaignSummary.titles.general'),
      titlePrefixColor: SummaryTitleColor.DARK,
      content: summaryData.general
    },
    include: {
      title: i18n.t<string>('campaignSummary.titles.inc'),
      titlePrefixColor: SummaryTitleColor.GREEN,
      content: summaryData.include
    }, exclude: {
      title: i18n.t<string>('campaignSummary.titles.exc'),
      titlePrefixColor: SummaryTitleColor.RED,
      content: summaryData.exclude
    }
  }, _.isEmpty);
};

const getGeneralSummary = (l1Object, adGroup) => {
  return {
    title: i18n.t<string>('adGroupSetupFlow.mainStep.fieldset.basicTitle'),
    content: _.compact([
      {
        label: i18n.t<string>('adGroupSetupFlow.mainStep.field.name'),
        value: adGroup.adgroup_name
      },
      l1Object.budget ?
        undefined : {
          label: i18n.t<string>('adGroupSetupFlow.mainStep.field.budget'),
          value: adGroup.budget
        },
      {
        label: i18n.t<string>('adGroupSetupFlow.mainStep.field.dateRange'),
        value: `${moment(adGroup.schedule_start_time).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(adGroup.schedule_end_time).format('YYYY-MM-DD HH:mm:ss')}`
      }
    ])
  };
};

const getOptimizeSummary = (currency, adGroup) => {
  return {
    title: i18n.t<string>('adGroupSetupFlow.mainStep.fieldset.optimizationTitle'),
    content: _.compact([
      {
        label: i18n.t<string>('adGroupSetupFlow.mainStep.field.optimizationGoal'),
        value: i18n.t<string>(`optimizationGoal.${adGroup.optimize_goal.toLowerCase()}`)
      },
      _.get(adGroup, 'promoted_object.custom_event_type') ? {
        label: i18n.t<string>('adGroupSetupFlow.mainStep.field.promoted_object_custom_event_type'),
        value: _.startCase(_.lowerCase(_.get(adGroup, 'promoted_object.custom_event_type')))
      } : undefined,
      adGroup.bid ? {
        label: i18n.t<string>('adGroupSetupFlow.mainStep.field.bidControl'),
        value: formatPriceWithCurrency(currency, +(adGroup.bid))
      } : undefined,
      {
        label: i18n.t<string>('adGroupSetupFlow.mainStep.field.bidStrategy'),
        value: i18n.t<string>(`adGroup.bidStrategy.${adGroup.bid_type.toLowerCase()}`)
      },
      {
        label: i18n.t<string>('adGroupSetupFlow.mainStep.field.billingEvent'),
        value: i18n.t<string>(`adGroup.billingEvent.${adGroup.billing_event.toLowerCase()}`)
      },
      {
        label: i18n.t<string>('adGroupSetupFlow.mainStep.field.pacing'),
        value: i18n.t<string>(`adGroup.pacing.${adGroup.pacing.toLowerCase()}`)
      },
      adGroup.dayPart && {
        label: i18n.t<string>('campaignSummary.labels.dayPart'),
        value: getDayPartSummary(adGroup.dayPart)
      }
    ])
  };
};

const getSummaryData = (l1Object, currency, tiktokCountries, tiktokAdGroupManager, adGroup) => {
  if (!adGroup) {
    return undefined;
  }

  const basicSummary = {
    title: i18n.t<string>('adGroupSetupFlow.summaryStep.basicTitle'),
    backStep: MAIN_STEP_INDEX,
    backSubStep: TiktokAdGroupMainStepTab.BASIC,
    data: _.omitBy({
      general: getGeneralSummary(l1Object, adGroup),
      optimization: getOptimizeSummary(currency, adGroup)
    }, _.isUndefined)
  };

  const targetingSummary = () => {
    const targeting = adGroup.targeting;
    if (!_.isEmpty(targeting)) {
      return undefined;
    }

    return {
      title: i18n.t<string>('adGroupSetupFlow.summaryStep.targetingTitle'),
      backStep: MAIN_STEP_INDEX,
      backSubStep: TiktokAdGroupMainStepTab.TARGETING,
      data: getTASummaryData(targeting, tiktokCountries, tiktokAdGroupManager)
    };
  };

  return ({
    basicSummary,
    targetingSummary
  });
};

const getDefaultTiktokAdGroup = (order, l1Object, tiktokAdGroupList, tiktokAdGroupManager, l2ObjectManager: L2ObjectManager) => {
  const allList = _.defaultTo(tiktokAdGroupList, []);
  const notDraftList = allList.filter(adGroup => !adGroup.draftId);
  let defaultOptimizationGoal;
  if (notDraftList.length > 0 && l1Object.autoOptimise) {
    defaultOptimizationGoal = notDraftList[0].optimize_goal;
  }

  const initDateRange = l2ObjectManager.getInitDateRange(order);

  const orderAgeMin: number = (typeof order.ageMin === 'string')
    ? _.defaultTo(_.parseInt(order.ageMin), ADGROUP_DEFAULT_AGE_MIN)
    : _.defaultTo(order.ageMin, ADGROUP_DEFAULT_AGE_MIN);
  const orderAgeMax: number = (typeof order.ageMax === 'string')
    ? _.defaultTo(_.parseInt(order.ageMax), ADGROUP_DEFAULT_AGE_MAX)
    : _.defaultTo(order.ageMax, ADGROUP_DEFAULT_AGE_MAX);
  const orderGender: FinalReportGender = _.defaultTo(order.gender, FinalReportGender.ALL);
  const maxAge = +tiktokAgeMaxOptions[tiktokAgeMaxOptions.length - 1].value;
  const initAgeAndGender = {
    ageMin: orderAgeMin,
    ageMax: Math.min(orderAgeMax, maxAge),
    gender: orderGender
  };

  const optimizeGoal = defaultOptimizationGoal ? defaultOptimizationGoal : tiktokAdGroupManager.getDefaultOptimizationGoal(l1Object);
  const parentBidStrategy: TiktokBidStrategy | undefined = _.get(l1Object, 'tiktok.bid_type');
  return {
    adgroup_name: l1Object.name,
    advertiser_id: +_.get(l1Object, 'tiktok.advertiser_id', ''),
    schedule_start_time: initDateRange.startTime,
    schedule_end_time: initDateRange.endTime,
    budget: '',
    optimize_goal: optimizeGoal,
    pacing: Pacing.PACING_MODE_SMOOTH,
    billing_event: tiktokAdGroupManager.getDefaultBillingEvent(l1Object),
    bid_type: _.defaultTo(parentBidStrategy, TiktokBidStrategy.BID_TYPE_NO_BID),
    targeting: {
      include: _.compact([{
        type: 'tiktok_placement',
        value: [{
          label: TiktokPlacementMapping.TIKTOK,

          value: TiktokPlacementMapping.TIKTOK
        }]
      }, {
        type: 'age_min',
        value: initAgeAndGender.ageMin
      }, {
        type: 'age_max',
        value: initAgeAndGender.ageMax
      }, initAgeAndGender.gender !== FinalReportGender.ALL ? {
        type: 'genders',
        value: initAgeAndGender.gender
      } : undefined])
    },
    dayPart: undefined
  };
};

const onTiktokCountriesFetched = (limitationPreset, tiktokCountries, adGroup) => {
  let countries = [];
  const preset = _.get(limitationPreset, 'include', []);
  const countryPreset = preset.find(limitation => limitation.type === 'geography');
  const countryPresetCodes = _.compact(_.get(countryPreset, 'value', []).map(country => getTiktokLocationIDByAlpha3(country.value)));
  if (tiktokCountries) {
    const tiktokCountryPreset = tiktokCountries.filter(tiktokCountry => countryPresetCodes.includes(tiktokCountry.value));
    countries = tiktokCountryPreset.map(country => country.value);
  }
  return {
    ...adGroup,
    targeting: {
      ..._.get(adGroup, 'targeting'),
      include: [
        ..._.get(adGroup, 'targeting.include', []),
        {
          type: 'tiktok_location',
          value: countries.map(option => ({ label: option, value: option }))
        }
      ]
    }
  };
};

const validateBudget = (order: Order, l1Object: L1Object, initAdGroup, adGroup: TiktokAdGroup, minBudget: number) => {
  const durationUnchanged =
    _.get(initAdGroup, 'schedule_start_time') === adGroup.schedule_start_time &&
    _.get(initAdGroup, 'schedule_end_time') === adGroup.schedule_end_time;

  const budget = +(_.get(adGroup, 'budget', 0).toString());
  const error = validateEmpty(budget);
  if (error) {
    return error;
  }
  const initBudget = +(_.get(initAdGroup, 'budget', '0'));
  const budgetNotNeedCheck = !initAdGroup.isDraft &&
    initBudget === budget &&
    durationUnchanged;
  if (budgetNotNeedCheck &&
    validateMinimum(_.get(adGroup, 'budget'), 1) === undefined
  ) {
    return;
  }
  const minBudgetChange = getPriceValue(order.currency, (7 * l1Object.currencyRate) / (1 - order.orderMargin - order.sysMargin));
  if (Math.abs(initBudget - budget) < minBudgetChange) {
    if (budgetNotNeedCheck) {
      return;
    } else if (!initAdGroup.isDraft) {
      return i18n.t<string>('adGroupSetupFlow.mainStep.errors.minCampaignDiff', { budget: formatPriceWithCurrency(order.currency, minBudgetChange) });
    }
  }
  const totalBudget = initAdGroup.isDraft ?
    l1Object.budgetBalance :
    l1Object.budgetBalance + initBudget;
  const remainBudget = totalBudget - budget;
  if (remainBudget < 0) {
    return renderOverBudgetWording(order.currency, totalBudget);
  }
  return validateMinimum(
    adGroup.budget,
    getPriceValue(order.currency, minBudget),
    'campaign.descriptions.smallerThanBudgetMinimum',
    order.currency
  );
};
const validateCampaignBudget = (currency: string, minBudget: number, l1Object: L1Object, otherAdGroupMinBudget: number) => {
  const tiktokCampaignMinBudget = otherAdGroupMinBudget + minBudget;
  if (tiktokCampaignMinBudget > +_.defaultTo(l1Object.budget, 0)) {
    return i18n.t<string>('adGroupSetupFlow.mainStep.hints.tiktokCampaignBudget', { budget: formatPriceWithCurrency(currency, tiktokCampaignMinBudget) });
  }
};
const validateAdGroupName = (adGroupName: string, uniqueAdGroupNames: string[]) => {
  if (_.find(uniqueAdGroupNames, (uniqueName) => uniqueName === adGroupName)) {
    return i18n.t<string>('adGroupSetupFlow.mainStep.hints.tiktokAdGroupNameUniqueness');
  } else {
    return validateEmpty(adGroupName);
  }
};
