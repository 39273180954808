import React from 'react';
import styles from './comboMediaSummary.module.scss';
import { ImagePreview } from 'components/common/Image/ImagePreview';
import { VideoPreview } from 'components/common/Video/VideoPreview';

type mediaSummaryData = {
  medias: {
    image?: {
      url: string,
      file?: File
    },
    video: {
      url: string,
      file?: File
    }
  }
};

export const ComboMediaSummary: React.FunctionComponent<{ mediaSummary: mediaSummaryData }> = props => {
  const medias = props.mediaSummary.medias;

  return (
    <div className={styles.comboPreview}>
      <VideoPreview
        maxWidth={300}
        src={medias.video.file || medias.video.url}
      />
      {medias.image &&
        <ImagePreview
          size={{ width: 300 }}
          border={false}
          url={medias.image.url}
          file={medias.image.file}
          config={{
            showName: false,
            showSize: false
          }}
        />
      }
    </div>
  );
};
