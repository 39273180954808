import { Link } from 'react-router-dom';
import styles from './nameWithIdColumn.module.scss';

export type NameWithIdColumnProps = {
  id: string | number;
  name: string;
  clickUrl?: string;
};

export const NameWithIdColumn = ({
  id,
  name,
  clickUrl
}: NameWithIdColumnProps) => {

  return (
    <div className={styles.info}>
      {
        clickUrl ?
          <Link to={clickUrl} className={`${styles.name} ${styles.link}`}>
            {name}
          </Link> :
          <span className={styles.name}>{name}</span>
      }
      <div className={styles.id}>
        {'ID: ' + id}
      </div>
    </div>
  );
};
