import React, { useCallback } from 'react';
import i18n from 'i18n';
import { Page } from 'components/Page/Page';
import { CustomLayoutListColumns, DeleteAlertModalData, useCustomLayoutListModel } from './CustomLayoutListModel';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { Link } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { CustomLayout } from 'core/customLayout/CustomLayout';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'components/common/Modal/Modal';
import { partial } from 'lodash';
import styles from './customLayoutList.module.scss';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';

export const CustomLayoutList = () => {

  const {
    loading,
    searchString,
    deleteAlertModalData,
    pageable,
    columns,
    filteredCustomLayouts,
    onHandleSearch,
    onHandleDelete,
    handleTableChange
  } = useCustomLayoutListModel();

  const renderAlertModalData = useCallback((deleteAlertModalData: DeleteAlertModalData) => {
    const { title, message, dismiss, onConfirm, onCancel } = deleteAlertModalData;
    return (
      <Modal
        title={title}
        dismiss={dismiss}
        primaryButton={{
          title: i18n.t<string>('common.buttons.confirm'),
          callback: onConfirm
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.cancel'),
          callback: onCancel
        }}
      >
        {message}
      </Modal>
    );
  }, []);

  const renderListOperations = useCallback(() => [
    <Link
      key={'new-custom-layout-btn'}
      to={'/custom-layouts/new'}
      className={'btn btn-tertiary btn-sm'}
    >
      {i18n.t<string>('customLayoutList.buttons.create')}
    </Link>
  ], []);

  const renderRowBtns = useCallback((customLayout: CustomLayout) => {
    const canDelete = true;
    return [(
      <IconWithTooltip
        key={'delete-custom-layout-btn'}
        disabled={!canDelete}
        icon={faTrash}
        onClick={partial(onHandleDelete, customLayout)}
        tooltipProps={{
          id: `delete-custom-layout-tooltip-${customLayout.id}`,
          tooltip: canDelete ?
            i18n.t<string>('customLayoutList.tooltips.delete') :
            i18n.t<string>('customLayoutList.tooltips.deleteDisabled')
        }}
      />
    )];
  }, [onHandleDelete]);

  return (
    <Page title={i18n.t<string>('customLayoutList.title')}>
      {loading && <LoadingIndicator />}
      {deleteAlertModalData && renderAlertModalData(deleteAlertModalData)}
      <div className={styles.contentArea}>
        <StickableBootstrapTable
          remote
          customPadding={false}
          keyField={CustomLayoutListColumns.ID}
          data={filteredCustomLayouts}
          columns={columns}
          noDataIndication={i18n.t<string>('customLayoutList.placeholders.noData')}
          pagination={{
            page: pageable.page,
            sizePerPage: pageable.sizePerPage,
            totalSize: pageable.totalCount
          }}
          defaultSearchString={searchString}
          searchbarPlaceholder={i18n.t<string>('customLayoutList.placeholders.searchbar')}
          onHandleSearch={onHandleSearch}
          renderListOperations={renderListOperations}
          renderRowBtns={renderRowBtns}
          onTableChange={handleTableChange}
        />
      </div>
    </Page>
  );
};
