import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import React from 'react';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import styles from './l1ObjectBudgetManagement.module.scss';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { L1ObjectBudgetManagementColumns, useL1ObjectBudgetManagementModel } from './L1ObjectBudgetManagementModel';
import { Order } from 'core/order/Order';
import i18n from 'i18n';
import { Modal } from 'components/common/Modal/Modal';
import { RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';

export const L1ObjectBudgetManagement: React.FC<{
  channel: L1ObjectChannel,
  order: Order,
  l1Object: Partial<L1Object>,
  initL1Object: Partial<L1Object> | undefined,
  l2ObjectList: FbAdSet[] | RtbCampaignBasic[],
  budgetDataList?: {id: string, budget: number }[],
  submit: (budgetMap) => void,
  cancel: () => void
}> = ({
  channel,
  order,
  l1Object,
  initL1Object,
  l2ObjectList,
  budgetDataList,
  submit,
  cancel
}) => {

  const {
    loading,
    viewData,
    columns,
    budgets,
    canSubmit
  } = useL1ObjectBudgetManagementModel(
    channel,
    order,
    l1Object,
    initL1Object,
    l2ObjectList,
    budgetDataList
  );

  const onSubmit = () => {
    if (!canSubmit) {
      return;
    }
    submit(budgets);
  };

  return (
    <>
      {loading && <LoadingIndicator />}
      <Modal
        fullScreen={true}
        title={i18n.t<string>('l1ObjectBudgetManagement.title', { num:  l2ObjectList.length })}
        primaryButton={{
          title: i18n.t<string>('common.buttons.confirm'),
          disabled: !canSubmit,
          callback: onSubmit
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.cancel'),
          callback: cancel
        }}
        dismiss={cancel}
        animation={false}
      >
        <div className={styles.contentArea}>
          <StickableBootstrapTable
            stickFirstColumn
            keyField={L1ObjectBudgetManagementColumns.NAME}
            data={viewData}
            columns={columns}
            hidePagination={true}
            noDataIndication={i18n.t<string>('common.labels.noData')}
            defaultSorted={[{
              dataField: L1ObjectBudgetManagementColumns.NAME,
              order: 'asc'
            }]}
          />
        </div>
      </Modal>
    </>
  );
};
