import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { TiktokAdGroupSetupFlowPage } from './TiktokAdGroupSetupFlowPage/TiktokAdGroupSetupFlowPage';
import { useCreateTiktokAdGroupSetupFlowPageModel, useEditTiktokAdGroupSetupFlowPageModel } from './TiktokAdGroupSetupFlowPage/TiktokAdGroupSetupFlowPageModel';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Order } from 'core/order/Order';
import { L1Object } from 'core/l1Object/L1Object';
import { TiktokAdGroup } from 'core/tiktokAdGroup/TiktokAdGroup';

const AdGroupSetupView = withErrorBoundary(ERROR_CODE.ADSET_CREATE, TiktokAdGroupSetupFlowPage);
export const TiktokAdGroups: React.FC<RouteComponentProps<any> & {
  order: Order,
  l1Object: L1Object,
  tiktokAdGroupList: TiktokAdGroup[]
}> = ({
  match,
  order,
  l1Object,
  tiktokAdGroupList
}) => {

  const modifyPermission = hasFuncs(Permission.CAMPAIGN_WRITE).and(addOnEnabled(ADDONFEATURE.CHANNEL.TIKTOK));

  const renderCreateAdGroupView = props => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={modifyPermission}
        renderOtherwise={renderRedirect}
      >
        <AdGroupSetupView
          order={order}
          l1Object={l1Object}
          tiktokAdGroupList={tiktokAdGroupList}
          useModel={useCreateTiktokAdGroupSetupFlowPageModel}
        />
      </PermissionChecker>
    );
  };

  const renderEditAdGroupView = props => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={modifyPermission}
        renderOtherwise={renderRedirect}
      >
        <AdGroupSetupView
          order={order}
          l1Object={l1Object}
          tiktokAdGroupList={tiktokAdGroupList}
          useModel={useEditTiktokAdGroupSetupFlowPageModel}
        />
      </PermissionChecker>
    );
  };

  const redirectToL1Object = (props) => {
    const match = props.match.url.match(/\/campaign-groups\/\d+\//);
    return <Redirect to={`${match[0].slice(0, -1)}`} />;
  };

  return (
    <Switch>
      <Route
        path={`${match.url}/new`}
        render={renderCreateAdGroupView}
      />
      <Route
        exact
        path={`${match.url}/:adGroupId/edit`}
        render={renderEditAdGroupView}
      />
      <Route
        render={redirectToL1Object}
      />
    </Switch>
  );
};
