import React, { Fragment } from 'react';
import { AdvertiserFormState, AdvertiserFormProps, AdvertiserFormData } from './AdvertiserFormModel';
import { Formik, FormikProps } from 'formik';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Form } from 'react-bootstrap';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import i18n from 'i18next';
import styles from './advertiserForm.module.scss';
import _ from 'lodash';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { FormikField } from 'components/common/form/field/FormikField';
import { FBPageOption } from 'components/FBPageSelect/FBPageOption';
import { FBPageSelectMultiValueLabel } from 'components/FBPageSelect/FBPageSelectMultipleValueLabel';
import { AdAccountOption } from 'components/AdAccountSelect/AdAccountOption';
import { AdAccountSingleValue } from 'components/AdAccountSelect/AdAccountSingleValue';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled } from 'core/permission/PermissionDSL';
import { ADDONFEATURE } from 'core/agency/AddonFeature';

export class AdvertiserForm extends React.PureComponent<RouteComponentProps<any> & AdvertiserFormProps, AdvertiserFormState> {

  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleSubmit = () => {
    const errors = this.props.model.validateAndSetError();
    if (!_.isEmpty(errors)) {
      return;
    }
    this.props.model.submit();
  }

  cancelSubmit = () => {
    this.props.history.goBack();
  }

  validate = (advertiser: AdvertiserFormData): any => {
    return this.props.model.validate(advertiser);
  }

  renderForm = (formProps: FormikProps<AdvertiserFormData>) => {
    const model = this.props.model;
    model.setFormikProps(formProps);
    return (
      <Fragment>
        <Form>
          {
            formProps.values.id &&
            <FormikField.Label
              label={i18n.t<string>('advertiserForm.labels.id')}
              name='id'
            />
          }
          <FormikField.Input
            label={i18n.t<string>('advertiserForm.labels.name')}
            name='advertiserName'
          />
          <FormikField.Input
            label={i18n.t<string>('advertiserForm.labels.website')}
            name='website'
          />
          <FormikField.Select
            label={i18n.t<string>('advertiserForm.labels.category')}
            name='category'
            options={model.state.categories ? model.state.categories : []}
            className={styles.categories}
            simpleValue
          />
          <PermissionChecker permissionAware={addOnEnabled(ADDONFEATURE.CHANNEL.FB)}>
            <FormikField.Select
              label={i18n.t<string>('advertiserFBAdAccount.labels.accountFieldLabel')}
              simpleValue
              name='fbAdAccountId'
              optionComponent={AdAccountOption}
              singleValue={AdAccountSingleValue}
              options={model.fbAdAccountOptions}
            />
            <FormikField.Select
              label={i18n.t<string>('creativeSetupFlow.labels.fbPage')}
              simpleValue
              name='fbPageIds'
              optionComponent={FBPageOption}
              multiValueLabel={FBPageSelectMultiValueLabel}
              fieldContentWidth='auto'
              options={model.fbPageOptions}
              isMulti
            />
          </PermissionChecker>
          <PermissionChecker permissionAware={addOnEnabled(ADDONFEATURE.CHANNEL.TIKTOK)}>
            <FormikField.Select
              label={i18n.t<string>('creativeSetupFlow.labels.tiktokAccountField')}
              simpleValue
              name='tiktokAdAccountId'
              optionComponent={AdAccountOption}
              singleValue={AdAccountSingleValue}
              options={model.tiktokAdAccountOptions}
            />
          </PermissionChecker>
          <FormikField.TextArea
            label={i18n.t<string>('advertiserForm.labels.note')}
            name='note'
          />
        </Form>
        <div className={styles.buttonArea}>
          <button
            type='button'
            className='btn btn-primary btn-sm'
            onClick={this.handleSubmit}
          >
            {i18n.t<string>('common.buttons.submit')}
          </button>
          <button
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={this.cancelSubmit}
          >
            {i18n.t<string>('common.buttons.cancel')}
          </button>
        </div>
      </Fragment>
    );
  }

  render () {
    const model = this.props.model;
    const state = model.state;
    if (state.redirectPath) {
      return <Redirect to={state.redirectPath} />;
    }
    if (state.loading || !model.advertiser) {
      return <LoadingIndicator />;
    }
    return (
      <div className={styles.advertiserFormContainer}>
        <NavigationPrompt/>
        <div className={styles.titleArea}>
          <TitleWithBreadcrumbs
            title={model.title}
            routes={model.breadcrumbs}
          />
        </div>
        <div className={styles.titleBottomLine} />
        <Formik
          initialValues={model.advertiser}
          onSubmit={this.handleSubmit}
          validate={this.validate}
          validateOnBlur={false}
        >
          {this.renderForm}
        </Formik>
      </div>
    );
  }
}
