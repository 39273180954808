export type AuditLogRecord = {
  id: number;
  email: string;
  ctime: string;
  msg: { [key: string]: {
    adminOnly?: boolean,
    org: string,
    to: string
  } }[] | null;
  srcType: AUDITLOG_SRC,
  srcID: number,
  funcType: string
};

export enum AUDITLOG_SRC {
  CAMPAIGN = 'Campaign',
  L1Object = 'L1Object',
  ORDER = 'AdsOrder'
}
