import React from 'react';
import classnames from 'classnames/bind';
import styles from './selectField.module.scss';
import { SelectOptions } from 'components/common/commonType';
import NativeToggleButton from 'components/common/ToggleButtons/ToggleButtons';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';
import { FormikFieldProps, withFormikField } from 'hoc/withFormikField';

const cx = classnames.bind(styles);

interface ToggleButtonFieldProps {
  type?: any;
  value: string | number;
  name: string;
  options: SelectOptions[];
  onChange: (value: string | number) => void;
  className?: string;
  error?: any;
}

export interface FormikToggleButtonProps extends FormikFieldProps, Omit<ToggleButtonFieldProps, 'value' | 'onChange'> {}
export interface FormikToggleButtonWithFieldLayoutProps extends FormikFieldProps, Omit<FieldLayoutProps, 'name'>, Omit<ToggleButtonFieldProps, 'value' | 'onChange'> {}
export type FormikToggleButtonFieldProps = ({ withFieldLayout?: true } & FormikToggleButtonWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikToggleButtonProps);

const ToggleButtonContent: React.FC<ToggleButtonFieldProps> = ({
  error,
  className,
  ...props
}) => {

  const hasError = error !== undefined;
  const classNameWithError = cx(className, {
    error: hasError
  });

  return (
    <NativeToggleButton
      {...props}
      className={classNameWithError}
    />
  );
};
const MemorizedToggleButtonContent = React.memo(ToggleButtonContent);
export const ToggleButtonField = withFieldLayout<FieldLayoutProps & ToggleButtonFieldProps>(ToggleButtonContent);

export const FormikToggleButton = withFormikField<FormikToggleButtonProps>(MemorizedToggleButtonContent);
export const FormikToggleButtonWithFieldLayout = withFormikField<FormikToggleButtonWithFieldLayoutProps>(
  withFieldLayout<FieldLayoutProps & ToggleButtonFieldProps>(ToggleButtonContent)
);

export const FormikToggleButtonField: React.FC<FormikToggleButtonFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikToggleButton {...props} />;
  } else {
    return <FormikToggleButtonWithFieldLayout {...props} />;
  }
};
