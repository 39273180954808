import React from 'react';
import { components } from 'react-select';
import styles from './draftL1ObjectOption.module.scss';
import cx from 'classnames/bind';
import i18n from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const classNames = cx.bind(styles);

export const DraftL1ObjectOption = props => {
  const optionClass = classNames('draftL1ObjectOption', {
    disabled: props.isDisabled
  });
  const renderDraftL1ObjectOption = () => (
    <div className={optionClass}>
      <div className={styles.l1ObjectName}>{props.label}</div>
      {props.isDisabled &&
        <div className={styles.selected}>
          <FontAwesomeIcon icon={faCheck}/>
          {i18n.t<string>('draftCamapignGroupOption.hints.selected')}
        </div>
      }
    </div>
  );
  return !props.isDisabled ? (
      <components.Option {...props}>
        {renderDraftL1ObjectOption()}
      </components.Option>
    ) :
    renderDraftL1ObjectOption();
};
