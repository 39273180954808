import React, { useContext, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import SummaryDetail from 'components/SummaryDetail/SummaryDetail';
import i18n from 'i18n';
import styles from './tiktokAdGroupSetupSummaryStep.module.scss';
import { StepRenderProps } from 'containers/StepPage/StepPage';
import { TiktokAdGroupSetupFlowContext, TiktokAdGroupSetupFlowContextType } from '../TiktokAdGroupSetupFlowContext';
import { TAInfoCard } from 'components/TAInfoCard/TAInfoCard';
import { partial } from 'lodash';

export const TiktokAdGroupSummaryStep: React.FC<StepRenderProps> = ({
  goLast,
  goStep
}) => {

  const {
    adGroup,
    order,
    objectType,
    l1Object,
    limitationModel,
    audienceLowestThreshold,
    savedTAList,
    estimatedData,
    appliedSavedTAInfo,
    onSaveDraft,
    getSummaryData,
    submit,
    showAddSaveTargetingModal,
    onShowAddSaveTargetingModal
  } = useContext(TiktokAdGroupSetupFlowContext) as TiktokAdGroupSetupFlowContextType;

  const {
    basicSummary,
    targetingSummary,
    draftBindingSummary
  } = getSummaryData(adGroup);

  const savedTargetingList = useMemo(() => {
    return savedTAList.map(savedTA => ({
      label: savedTA.name,
      value: savedTA.id
    }));
  }, [savedTAList]);

  const renderSummary = (summaryData, renderOtherInfo?: any) => {
    if (!summaryData) {
      return <div/>;
    }

    const onGoStep = () => {
      goStep(summaryData.backStep, summaryData.backSubStep);
    };

    const keys = Object.keys(summaryData.data);
    const details = keys.length > 0 ? keys.map((key) =>
      summaryData.data[key] ?
      <SummaryDetail
        key={key}
        title={summaryData.data[key].title}
        data={summaryData.data[key].content}
        prefixColor={summaryData.data[key].titlePrefixColor}
      /> : <div key={key}/>) :
      <div>
        {i18n.t<string>('common.labels.noData')}
      </div>;
    return (
      <section>
        <div className={styles.categoryTitle}>
          {summaryData.title}
          {summaryData.backStep !== undefined &&
            <div className={styles.backBtn} onClick={onGoStep}>
              {i18n.t<string>('campaignSummary.labels.backButtonTitle')}
            </div>
          }
        </div>
        <div>
          {details}
        </div>
        {renderOtherInfo && renderOtherInfo()}
      </section>
    );
  };

  return (
    <div className={styles.tiktokAdGroupSummaryStep}>
      <div className={styles.title}>
        {i18n.t<string>('campaignSummary.titles.checkYourSettings')}
      </div>
      <div className={styles.content}>
        <div className={styles.basicSummary}>
          {renderSummary(basicSummary)}
        </div>
        <div className={styles.sepLine} />
        {renderSummary(targetingSummary(), limitationModel ?
          () => (
            <TAInfoCard
              orderType={order.orderType}
              channel={l1Object.channel}
              advertiserId={order.advertiserId}
              appliedSavedTAInfo={appliedSavedTAInfo}
              targetingValue={limitationModel.limitationValue}
              estimatedAudience={estimatedData}
              audienceLowestThreshold={audienceLowestThreshold}
              savedTargetingList={savedTargetingList}
              showAddSaveTargetingModal={showAddSaveTargetingModal}
              onShowAddSaveTargetingModal={onShowAddSaveTargetingModal}
            />
          ) : undefined
        )}
        {draftBindingSummary && (
          <>
            <div className={styles.sepLine} />
            {renderSummary(draftBindingSummary)}
          </>
        )}
      </div>
      <div className={styles.buttonArea}>
        <Button variant='primary' size='sm' onClick={submit}>
          {objectType === 'adGroup' ? i18n.t<string>('common.buttons.submit') : i18n.t<string>('common.buttons.publish')}
        </Button>
        {onSaveDraft && (
          <Button
            variant='secondary'
            size='sm'
            onClick={partial(onSaveDraft, adGroup)}
          >
            {i18n.t<string>('campaign.buttons.saveDraft')}
          </Button>
        )}
        <Button variant='secondary' size='sm' onClick={goLast}>
          {i18n.t<string>('campaign.buttons.back')}
        </Button>
      </div>
    </div>
  );
};
