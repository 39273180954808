import React, { Fragment, RefObject } from 'react';
import { Formik } from 'formik';
import i18n from 'i18next';
import { AdvertiserMemberFormProps } from './AdvertiserMemberFormModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import styles from './advertiserMemberForm.module.scss';
import { FormikField } from 'components/common/form/field/FormikField';

export class AdvertiserMemberForm extends React.Component<AdvertiserMemberFormProps> {
  handler?: number;
  formikRef: RefObject<any>;
  formikEventHandler?: number;

  constructor (props) {
    super(props);
    this.renderFormContent = this.renderFormContent.bind(this);
    this.formikRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.formikEventHandler = this.props.model.formikEvent.add(event => {
      if (event.isSubmit === 'FORMIK_SUBMIT') {
        this.formikRef.current.submitForm();
      }
    });
    this.props.model.init();
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler!);
    this.props.model.formikEvent.remove(this.formikEventHandler!);
  }

  handleSubmit (role) {
    this.props.model.submit(role);
  }

  renderFormContent () {
    const roleOptions = this.props.model.roleOptions;
    return (
      <div className={styles.advertiserMemberForm}>
        {
          this.props.model.emailEditable ?
          <FormikField.Input
            label={i18n.t<string>('advertiserMemberForm.labels.email')}
            type='email'
            name='email'
            labelColSm={2}
            inputColSm={4}
          /> :
          <FormikField.Label
            label={i18n.t<string>('advertiserMemberForm.labels.email')}
            name='email'
            labelColSm={2}
            inputColSm={4}
          />
        }
        <FormikField.Select
          name='role'
          label={i18n.t<string>('advertiserMemberForm.labels.role')}
          labelColSm={2}
          inputColSm={4}
          options={roleOptions}
          simpleValue
          className={styles.roleSelect}
        />
      </div>
    );
  }

  render () {
    return (
      <Fragment>
        {this.props.model.state.loading && <LoadingIndicator />}
        <Formik
          innerRef={this.formikRef}
          initialValues={this.props.model.companyRole}
          enableReinitialize
          onSubmit={this.handleSubmit}
          validate={this.props.model.validate}
          validateOnBlur={false}
        >
          {this.renderFormContent}
        </Formik>
      </Fragment>
    );
  }
}
