import _ from 'lodash';
import client from './RestClient';
import { ProductFormValue, ProductGroup } from 'core/limitation/ProductGroup';
import { AxiosInstance } from 'axios';

export interface ProductGroupWebService {
  createProductGroup (productGroup: ProductFormValue): Promise<void>;
  updateProductGroup (productGroupId: number | string, productGroup: ProductFormValue): Promise<void>;
  getProductGroup (): Promise<ProductGroup[]>;
  getProductGroupById (groupId: number): Promise<ProductGroup>;
  getCampaignByGroupId (groupId: number);
  deleteProductGroupById (groupId: number): Promise<void>;
}

const limitationGroupUrl = '/v2/limitation-groups';
export class RestfulProductGroupWebService implements ProductGroupWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async createProductGroup (productGroup: ProductFormValue): Promise<void> {
    return client.post(limitationGroupUrl, {
      ...productGroup,
      state: 1
    });
  }
  async updateProductGroup (productGroupId: string | number, productGroup: ProductFormValue): Promise<void> {
    return client.put(`${limitationGroupUrl}/${productGroupId}`, {
      ...productGroup,
      state: 1
    });
  }
  async getProductGroup (): Promise<ProductGroup[]> {
    const response = await client.get(`${limitationGroupUrl}`);
    return this.wrapProductGroupList(response.data.records, this.wrapProductGroup);
  }
  async getProductGroupById (groupId: number): Promise<ProductGroup> {
    const response = await client.get(`${limitationGroupUrl}/${groupId}`);
    return this.wrapProductGroup(response.data);
  }
  async getCampaignByGroupId (groupId: number) {
    const response = await client.get(`${limitationGroupUrl}/${groupId}/manage`);
    return _.get(response, 'data.inUsedCampaign', []).map(data => ({ value: data.id, label: data.name }));
  }

  async deleteProductGroupById (groupId: number): Promise<void> {
    return client.delete(`${limitationGroupUrl}/${groupId}`);
  }

  wrapProductGroup (json: any): ProductGroup {
    return {
      id: _.get(json, 'id'),
      groupName: _.get(json, 'groupName'),
      state: _.get(json, 'state'),
      groupType: _.get(json, 'groupType'),
      description: _.get(json, 'description'),
      groupValues: _.uniqBy(_.get(json, 'groupValues'), 'limitationValue'),
      agencys: _.get(json, 'agencys'),
      agencyIds: _.get(json, 'agencyIds')
    };
  }

  wrapProductGroupList (json: any, wrapCb): ProductGroup[] {
    return _.defaultTo(json, []).flatMap((json: any) =>
      wrapCb(json)
    );
  }

}
