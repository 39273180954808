import React, { Fragment, useState } from 'react';
import { Button } from 'react-bootstrap';
import i18n from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faTimes } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import classNames from 'classnames/bind';
import styles from './limitationTableCell.module.scss';
import { SelectOptions } from 'components/common/commonType';

const cx = classNames.bind(styles);

interface TaSummaryData {
  [limitedTrafficType: string]: SelectOptions[];
}

export const LimitationTableCell: React.FC<{
  summaryData: TaSummaryData;
  l2ChannelId: string | number;
}> = ({ summaryData, l2ChannelId }) => {
  const [showDetail, setShowDetail] = useState(false);

  const handleLimitationDetail = () => {
    setShowDetail(!showDetail);
  };

  const limitedTrafficTypes = summaryData ? Object.keys(summaryData) : [];

  const renderLimitation = () => {
    return limitedTrafficTypes.map(limitedTrafficType => {
      const types = summaryData[limitedTrafficType].map(data => data.label);
      return (
        <Fragment key={limitedTrafficType}>
          <div className={styles.limitationTitle} key={`${limitedTrafficType}Title`}>
            <span className={styles[limitedTrafficType]}>{i18n.t<string>(`limitation.${limitedTrafficType}`)}</span>
          </div>
          <div className={styles.colon}>{' : '}</div>
          <div className={styles.limitationValue} key={`${limitedTrafficType}Value`}>
            {types.join(', ')}
          </div>
        </Fragment>
      );
    });
  };

  const renderLimitationDetail = () => {
    return (
      <div id='limitationDetail' className={styles.limitationDetailBox}>
        <div className={styles.toggleDetail}>
          <Button variant='link' className={styles.closeBtn} onClick={handleLimitationDetail}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>
        <div className={styles.limitationDetail}>
          {limitedTrafficTypes.map(limitedTrafficType => {
            const titleClassName = cx({
              title: true,
              [limitedTrafficType]: true
            });
            return (
              <Fragment key={limitedTrafficType}>
                <div className={titleClassName} key={`${limitedTrafficType}DetailTitle`}>
                  {i18n.t<string>(`limitation.${limitedTrafficType}`)}
                </div>
                <div className={styles.content}>
                  {summaryData[limitedTrafficType].map(data => {
                    return (
                      <div className={styles.statement} key={`${limitedTrafficType}${data.label}Statement`}>
                        {data.label} : {data.value}
                      </div>
                    );
                  })}
                </div>
              </Fragment>
            );
          })}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.tableCell}>
      {showDetail && renderLimitationDetail()}
      {
        limitedTrafficTypes.length > 0 ?
          <div className={styles.limitationContent}>
            {renderLimitation()}
          </div> :
          i18n.t<string>('campaignList.labels.emptyContent')
      }
      {Object.keys(summaryData).length > 0 &&
      <div className={styles.detailBtn}>
        <IconWithTooltip
          icon={faEye}
          tooltipProps={{
            id: `campaignListLimitationDetailTip-${l2ChannelId}`,
            tooltip: i18n.t<string>('campaignList.labels.limitationDetail')
          }}
          onClick={handleLimitationDetail}
        />
      </div>}
    </div>
  );
};
