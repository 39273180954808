import _, { round } from 'lodash';
import { toast } from 'react-toastify';
import i18n from 'i18n';

import { AgencyDetail, AgencyRebate } from 'core';
import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AgencyManager, DefaultAgencyManager } from 'core/agency/AgencyManager';
import {
  CountryManager,
  DefaultCountryManager
} from 'core/country/CountryManager';
import { toDecimal, multiply100 } from 'utils/Math';
import {
  DefaultAdRequestSourceManager,
  AdRequestSourceManager
} from 'core/adRequestSource/AdRequestSourceManager';
import { AdRequestSourceSpace } from 'core/adRequestSource/AdRequestSource';
import { SelectOptions } from 'components/common/commonType';
import { AdLogoType } from 'core/adLogo/AdLogo';
import { validateEmpty, validateUrl, validateMinimum } from 'utils/ValidateUtils';
import moment from 'moment';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { CurrencyRate } from 'core/currencyRate/CurrencyRate';
import agencyData from './agencyData';
const mimeDB = require('mime-db');

export type AgencyFormState = {
  isLoading: boolean;
  isOptionsLoading: boolean;
  redirectUrl?: string;
  currentTab: string;
  errors?: any;
};
export interface AgencyFormModel {
  readonly agencyId?: number;
  readonly defaultAgency: AgencyDetail;
  readonly timeZoneOptions: SelectOptions[];
  readonly languageOptions: SelectOptions[];
  readonly currencyOptions: SelectOptions[];
  readonly priorityOptions: SelectOptions[];
  readonly publisherCountryOptions: SelectOptions[];
  readonly adxOptions: SelectOptions[];
  readonly countryOptions: SelectOptions[];
  readonly spaceOptions: AdRequestSourceSpace[];
  readonly segmentCountryOptions: SelectOptions[];
  readonly segmentLabelOptions: SelectOptions[];
  readonly isNew: boolean;
  readonly cancelPath: string;
  readonly title: string;
  readonly breadcrumbs: any;
  readonly currencyRates: CurrencyRate[];
  state: AgencyFormState;
  event: UpdateEventListener<AgencyFormModel>;
  changePublisherIds (publisherIds: string[]): Promise<void>;
  validate (basic: AgencyDetail): any;
  save (value: any): void;
  init (): Promise<void>;
  initOptions (): Promise<void>;
  onUnmount (handler): void;
  onChangeTab (newTab: string | null): void;
  setErrors (errors?: any): void;
}

export interface AgencyFormProps {
  readonly model: AgencyFormModel;
}

abstract class DefaultAgencyFormModel implements AgencyFormModel {
  agencyDetail: AgencyDetail & AgencyRebate;
  modelEvent: FireableUpdateEventListener<AgencyFormModel>;
  isLoading: boolean;
  isOptionsLoading: boolean;
  agencyManager: AgencyManager;
  countryManager: CountryManager;
  adRequestSourceManager: AdRequestSourceManager;
  publisherCountries: SelectOptions[];
  adxs: SelectOptions[];
  countries: SelectOptions[];
  publisherSpacies: AdRequestSourceSpace[];
  segmentLabels: SelectOptions[];
  redirectUrl?: string;
  currentTab: string = 'basic';
  errors?: any;
  currencyRates: CurrencyRate[] = [];

  constructor (
    agencyManager: AgencyManager = new DefaultAgencyManager(),
    countryManager: CountryManager = new DefaultCountryManager(),
    adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager()
  ) {
    this.agencyManager = agencyManager;
    this.countryManager = countryManager;
    this.adRequestSourceManager = adRequestSourceManager;
    this.modelEvent = new FireableUpdateEventListener<AgencyFormModel>();
    this.isLoading = false;
    this.isOptionsLoading = true;
    this.agencyDetail = agencyData.defaultAgency;
    this.publisherCountries = [];
    this.adxs = [];
    this.countries = [];
    this.publisherSpacies = [];
    this.segmentLabels = [];
  }

  abstract get cancelPath (): string;
  abstract get isNew (): boolean;
  abstract get breadcrumbs (): any[];
  abstract init (): Promise<void>;
  abstract save (agencyDetail: any): void;

  async initOptions (): Promise<void> {
    try {
      const [
        publisherCountries,
        adxs,
        countries,
        segmentLabels,
        publisherSpacies
      ] = await Promise.all([
        this.adRequestSourceManager.getAsiaMaxCountries(),
        this.adRequestSourceManager.getAdx(),
        this.adRequestSourceManager.getCountries(),
        this.adRequestSourceManager.getSegmentLabel(),
        this.adRequestSourceManager.getSSPSpaces()
      ]);
      this.publisherCountries = publisherCountries;
      this.adxs = adxs;
      this.publisherSpacies = publisherSpacies;
      this.countries = countries.map((countryData: SelectOptions) => {
        let cities: SelectOptions[] = _.get(countryData, 'options', []);
        let country: SelectOptions = _.omit(countryData, 'options');
        cities.unshift(country);
        countryData['options'] = cities;
        return countryData;
      });
      this.segmentLabels = segmentLabels;
    } catch (e) {
      this.publisherCountries = [];
      this.adxs = [];
      this.publisherSpacies = [];
      this.countries = [];
      this.segmentLabels = [];
      (e instanceof Error) && toast.error(e.message);
    }
  }

  get event (): UpdateEventListener<AgencyFormModel> {
    return this.modelEvent;
  }

  // stringify(agencyDetail: any) {
  //   const pickFields = [
  //     "limitPublisherCountry",
  //     "limitAdx",
  //     "defaultCountry",
  //     "defaultSpace"
  //   ];
  //   const fields = _.pick(agencyDetail, pickFields);
  //   return Object.keys(fields).reduce((acc, currentField) => {
  //     return {
  //       ...acc,
  //       [currentField]: fields[currentField].map(JSON.stringify)
  //     };
  //   }, {});
  // }

  // pickValue(agencyDetail: any) {
  //   const pickFields = ["limitAdx"];
  //   const fields = _.pick(agencyDetail, pickFields);
  //   return Object.keys(fields).reduce((acc, fieldValue) => {
  //     return {
  //       ...acc,
  //       [fieldValue]: fields[fieldValue].map(({ label }) => {
  //         return { name: label };
  //       })
  //     };
  //   }, {});
  // }

  // renameKey(agencyDetail: any) {
  //   const pickFields = [
  //     "defaultCountry",
  //     "defaultSpace",
  //     "limitPublisherCountry"
  //   ];
  //   const fields = _.pick(agencyDetail, pickFields);
  //   Object.keys(fields).forEach(fieldValue => {
  //     fields[fieldValue].forEach(v => {
  //       v["name"] = v["label"];
  //       delete v["label"];
  //     });
  //   });
  //   return fields;
  // }

  onChangeTab = (newTab: string | null) => {
    if (newTab === null) {
      return;
    }
    this.currentTab = newTab;
    this.updateState(false);
  }

  convertToDecimal ({
    sysPercentIn,
    sysPercentOut,
    sysPercent,
    sysDataPercent,
    agcPercent,
    agcDataPercent,
    taxPercent,
    addonProps,
    currentRate,
    futureRate
  }: AgencyDetail & AgencyRebate) {
    const { maxOrderProfit, sysProfitMonitorPercent } = addonProps;
    return {
      sysPercentIn: toDecimal(sysPercentIn),
      sysPercentOut: toDecimal(sysPercentOut),
      sysPercent: toDecimal(sysPercent),
      sysDataPercent: toDecimal(sysDataPercent),
      agcPercent: toDecimal(agcPercent),
      agcDataPercent: toDecimal(agcDataPercent),
      taxPercent: toDecimal(taxPercent),
      currentRate: toDecimal(currentRate),
      futureRate: futureRate ? toDecimal(futureRate) : futureRate,
      addonProps: {
        ...addonProps,
        maxOrderProfit: toDecimal(maxOrderProfit),
        sysProfitMonitorPercent: toDecimal(sysProfitMonitorPercent)
      }
    };
  }

  convertToPercent ({
    sysPercentIn,
    sysPercentOut,
    sysPercent,
    sysDataPercent,
    agcPercent,
    agcDataPercent,
    taxPercent,
    addonProps,
    currentRate,
    futureRate
  }: AgencyDetail & AgencyRebate) {
    const { maxOrderProfit, sysProfitMonitorPercent } = addonProps;
    const multiply = (value) => round(multiply100(value), 2);
    return {
      sysPercentIn: multiply(sysPercentIn),
      sysPercentOut: multiply(sysPercentOut),
      sysPercent: multiply(sysPercent),
      sysDataPercent: multiply(sysDataPercent),
      agcPercent: multiply(agcPercent),
      agcDataPercent: multiply(agcDataPercent),
      taxPercent: multiply(taxPercent),
      currentRate: multiply(currentRate),
      futureRate: futureRate ? multiply(futureRate) : futureRate,
      addonProps: {
        ...addonProps,
        maxOrderProfit: multiply(maxOrderProfit),
        sysProfitMonitorPercent: multiply(sysProfitMonitorPercent)
      }
    };
  }

  get defaultAgency (): AgencyDetail {
    return this.agencyDetail;
  }

  validateImage = (fileData, required: boolean, validateSize?: any) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return required ?
        i18n.t<string>('formValidate.labels.emptyError') :
        undefined;
    }

    if (!fileData.file) {
      return;
    }
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('adLogoForm.labels.typeErrorHint', { type: extensions[0] });
    }

    if (!validateSize) {
      return;
    }

    const validWitdh = validateSize.width;
    const validHeight = validateSize.height;
    const width = fileData.width;
    const height = fileData.height;
    if (width !== validWitdh || height !== validHeight) {
      return i18n.t<string>('adLogoForm.labels.sizeErrorHint', { size1: `${width} x ${height}`, size2: `${validWitdh} x ${validHeight}` });
    }
  }

  validateSysPercent = (agency) => {
    const maxMargin = 80;
    const sysPercentIn = Number(_.get(agency, 'sysPercentIn', 0));
    const sysPercentOut = Number(_.get(agency, 'sysPercentOut', 0));
    const sysMargin = sysPercentIn + sysPercentOut;
    if (sysMargin > maxMargin) {
      return i18n.t<string>('agencyForm.labels.sysMarginMaximumError', { value: `${maxMargin}%` });
    }
    const maxAgcMargin = maxMargin - sysMargin;
    if (maxAgcMargin < agency.agcPercent) {
      return i18n.t<string>('agencyForm.labels.smallerThanAgcMarginError', { value: `${agency.agcPercent}%` });
    }
    const maxOrderProfit = _.get(agency, 'addonProps.maxOrderProfit', 0);
    if (maxAgcMargin < maxOrderProfit) {
      return i18n.t<string>('agencyForm.labels.smallerThanOrderProfitError', { value: `${maxOrderProfit}%` });
    }
  }

  validateFutureRate = (agency) => {
    const sysPercentIn = Number(_.get(agency, 'sysPercentIn', 0));
    const sysPercentOut = Number(_.get(agency, 'sysPercentOut', 0));
    const sysMargin = sysPercentIn + sysPercentOut;
    if (agency.futureRate && agency.futureRate > sysMargin) {
      return i18n.t<string>('agencyForm.labels.rebateRateMaximumError', { margin: `${sysMargin}%` });
    }
  }

  validateAgencyPercent = (basicValue: AgencyDetail) => {
    const maxOrderProfit = _.get(basicValue, 'addonProps.maxOrderProfit', 0);
    const error = validateMinimum(basicValue.agcPercent, 0);
    if (error) {
      return error;
    }

    return basicValue.agcPercent > maxOrderProfit ? i18n.t<string>('agencyForm.labels.orderDefaultProfitBiggerThanMaxProfit') : undefined;
  }

  validateMaxAgencyPercent = (basicValue: AgencyDetail) => {
    const maxOrderProfit = _.get(basicValue, 'addonProps.maxOrderProfit', 0);
    const error = validateMinimum(maxOrderProfit, 0);
    if (error) {
      return error;
    }

    return basicValue.agcPercent > maxOrderProfit ? i18n.t<string>('agencyForm.labels.orderDefaultProfitBiggerThanMaxProfit') : undefined;
  }

  validate = (basicValue: AgencyDetail): any => {
    const adLogo = _.get(basicValue, 'adLogo');
    const logoData = _.get(basicValue, 'logoData');
    return _.omitBy({
      companyName: validateEmpty(basicValue.companyName),
      adLogo: adLogo && adLogo.type === AdLogoType.CUSTOM ? _.omitBy({
        link: validateUrl(adLogo.link),
        image: this.validateImage(adLogo.image, true, { width: 50, height: 50 })
      }, _.isEmpty) : undefined,
      logoData: logoData ? this.validateImage(logoData, false) : undefined,
      addonProps: _.omitBy({
        maxOrderProfit: this.validateMaxAgencyPercent(basicValue)
      }, _.isEmpty),
      sysPercentIn: this.validateSysPercent(basicValue),
      sysPercentOut: this.validateSysPercent(basicValue),
      agcPercent: this.validateAgencyPercent(basicValue),
      futureRate: this.isNew ? undefined : this.validateFutureRate(basicValue),
      targetBudgetMinimum: validateMinimum(basicValue.targetBudgetMinimum, 1),
      campaignBudgetMinimum: validateMinimum(basicValue.campaignBudgetMinimum, 1)
    }, _.isEmpty);
  }

  get title (): string {
    return 'agency.form.titles.edit';
  }

  get state (): AgencyFormState {
    return {
      isLoading: this.isLoading,
      isOptionsLoading: this.isOptionsLoading,
      redirectUrl: this.redirectUrl,
      currentTab: this.currentTab,
      errors: this.errors
    };
  }

  get timeZoneOptions (): SelectOptions[] {
    return agencyData.timeZoneOptions;
  }

  get currencyOptions (): SelectOptions[] {
    return agencyData.currencyOptions;
  }

  get languageOptions (): SelectOptions[] {
    return agencyData.languageOptions;
  }

  get priorityOptions (): SelectOptions[] {
    return Array.from(Array(11).keys())
      .map(x => x - 5)
      .reverse()
      .reduce((all: any, currentValue) => {
        return [...all, { value: currentValue, label: '' + currentValue }];
      }, []);
  }

  get publisherCountryOptions (): SelectOptions[] {
    return this.publisherCountries;
  }

  get adxOptions (): SelectOptions[] {
    return this.adxs;
  }

  get countryOptions (): SelectOptions[] {
    return this.countries;
  }

  get spaceOptions (): AdRequestSourceSpace[] {
    return this.publisherSpacies;
  }

  get segmentLabelOptions (): SelectOptions[] {
    return this.segmentLabels;
  }

  get segmentCountryOptions (): SelectOptions[] {
    return this.publisherCountries;
  }

  setErrors (errors?: any): void {
    this.errors = errors;
    this.updateState(false);
  }

  async changePublisherIds (publisherIds: string[]): Promise<void> {
    this.updateState(false, true);
    const publisherSpacies = await this.adRequestSourceManager.getSSPSpaces(
      publisherIds
    );
    this.publisherSpacies = publisherSpacies;
    this.updateState(false, false);
  }

  updateState (
    isLoading: boolean,
    isOptionsLoading: boolean = false,
    redirectUrl?: string
  ) {
    this.isLoading = isLoading;
    this.isOptionsLoading = isOptionsLoading;
    this.redirectUrl = redirectUrl;
    this.modelEvent.fireEvent(this);
  }

  onUnmount (handler): void {
    handler && this.event.remove(handler);
    this.redirectUrl = undefined;
  }
}

export class EditAgencyFormModel extends DefaultAgencyFormModel {
  agencyId: number;

  constructor (
    agencyId: number,
    private updateLocalMeta: () => Promise<void>,
    agencyManager: AgencyManager = new DefaultAgencyManager(),
    countryManager: CountryManager = new DefaultCountryManager(),
    adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager()
  ) {
    super(agencyManager, countryManager, adRequestSourceManager);
    this.agencyId = agencyId;
  }

  async init (): Promise<void> {
    this.updateState(true);
    const { detail, rebate } = await this.agencyManager.fetchAgency(this.agencyId);
    const percentFields = this.convertToPercent({ ...detail, ...rebate });
    this.agencyDetail = { ...detail, ...percentFields, effectDate: rebate.effectDate };
    await this.initOptions();
    this.updateState(false);
  }

  get title (): string {
    return 'agency.form.titles.edit';
  }

  get cancelPath (): string {
    return `/agencies/${this.agencyId}`;
  }

  get isNew (): boolean {
    return false;
  }

  get breadcrumbs () {
    return [
      { path: '/agencies', breadcrumb: i18n.t<string>('agencies.home.title') },
      { path: '/agencies/:agencyId', breadcrumb: DynamicBreadcrumb, props: { label: _.get(this.agencyDetail, 'companyName'), matchParam: 'agencyId' } },
      { path: '/agencies/:agencyId/edit', breadcrumb: DynamicBreadcrumb, props: { prefix: i18n.t<string>('common.labels.edit'), label: _.get(this.agencyDetail, 'companyName'), matchParam: 'agencyId' } }
    ];
  }

  async save (agencyDetail: any) {
    this.updateState(true);
    const decimalFields = this.convertToDecimal(agencyDetail);
    try {
      await this.agencyManager.updateAgency({
        ...agencyDetail,
        ...decimalFields
        // ...stringifyFields
      });

      decimalFields.futureRate && await this.agencyManager.updateRebate(agencyDetail.id, {
        id: agencyDetail.futureRebateID,
        rate: decimalFields.futureRate,
        effectDate: moment(agencyDetail.effectDate).format('YYYY-MM-DDTHH:mm:ss[Z]')
      });
      await this.updateLocalMeta();
    } catch (e) {}
    toast.success(i18n.t<string>('common.messages.succeeded'));
    this.updateState(false, false, `/agencies/${this.agencyId}`);
  }
}

export class CreateAgencyFormModel extends DefaultAgencyFormModel {

  constructor (
    agencyManager: AgencyManager = new DefaultAgencyManager(),
    countryManager: CountryManager = new DefaultCountryManager(),
    adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager()
  ) {
    super(agencyManager, countryManager, adRequestSourceManager);
  }

  async init (): Promise<void> {
    this.updateState(true);
    this.agencyDetail.targetBudgetMinimum = this.agencyManager.getRTBDefaultMinBudgetPerDay(this.agencyDetail.currency);
    this.agencyDetail.campaignBudgetMinimum = this.agencyManager.getRTBDefaultMinBudget(this.agencyDetail.currency);
    await this.initOptions();
    this.updateState(false);
  }

  get cancelPath (): string {
    return '/agencies';
  }

  // readonly by field get
  get isNew (): boolean {
    return true;
  }

  get title (): string {
    return 'agency.form.titles.new';
  }

  get breadcrumbs () {
    return [
      { path: '/agencies', breadcrumb: i18n.t<string>('agencies.home.title') },
      { path: '/agencies/new', breadcrumb:  i18n.t<string>('agency.form.titles.new') }
    ];
  }

  async save (agencyDetail: any) {
    this.updateState(true);
    try {
      const decimalFields = this.convertToDecimal(agencyDetail);
      const agencyId = await this.agencyManager.createAgency({
        ...agencyDetail,
        ...decimalFields
      });
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.updateState(false, false, `/agencies/${agencyId}`);
    } catch (e) {
      this.updateState(false, false);
    }
  }
}
