import {
  PRODUCT_SEGMENT_OPERATOR,
  EVENT_TYPES,
  FILTER_FIELDS,
  AGGREGATION_TYPES
} from './ProductSegment';

export const MAX_INCLUSION_RULES_LENGTH = 5;
export const MAX_EXCLUSION_RULES_LENGTH = 1;
export const MAX_FILTER_FIELDS_LENGTH = 5;
export const DURATION_DAYS = [7, 14, 30, 90 ,180];

export const CombinedEventTypes = [
  EVENT_TYPES.ALL_PRODUCT_EVENTS
];

export const CombinedEventTypesConfig = {
  [EVENT_TYPES.ALL_PRODUCT_EVENTS]: [
    EVENT_TYPES.VIEW_CONTENT,
    EVENT_TYPES.ADD_TO_CART,
    EVENT_TYPES.VIEW_CART,
    EVENT_TYPES.PURCHASE
  ]
};

export const NonEmptySubConditionTypes = [
  EVENT_TYPES.ALL_PRODUCT_EVENTS,
  EVENT_TYPES.SEARCH,
  EVENT_TYPES.UTM
];

export const InitSubConditionTypes = [
  EVENT_TYPES.SEARCH,
  EVENT_TYPES.UTM
];

export const OperatorInitValuesMapConfig = {
  [PRODUCT_SEGMENT_OPERATOR.EQ]: [''],
  [PRODUCT_SEGMENT_OPERATOR.NEQ]: [''],
  [PRODUCT_SEGMENT_OPERATOR.GT]: [''],
  [PRODUCT_SEGMENT_OPERATOR.GTE]: [''],
  [PRODUCT_SEGMENT_OPERATOR.LT]: [''],
  [PRODUCT_SEGMENT_OPERATOR.LTE]: [''],
  [PRODUCT_SEGMENT_OPERATOR.I_CONTAINS]: [''],
  [PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS]: [''],
  [PRODUCT_SEGMENT_OPERATOR.IN_RANGE]: ['', '']
};

export const FilterFieldOperatorsConfig = {
  [FILTER_FIELDS.SEARCH_TERM]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.BRAND]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.CATEGORY]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.NAME]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.PRODUCT_ID]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ
  ],
  [FILTER_FIELDS.PRODUCT_SET_ID]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ
  ],
  [FILTER_FIELDS.SALE_PRICE]: [
    PRODUCT_SEGMENT_OPERATOR.GTE,
    PRODUCT_SEGMENT_OPERATOR.GT,
    PRODUCT_SEGMENT_OPERATOR.LT,
    PRODUCT_SEGMENT_OPERATOR.LTE,
    PRODUCT_SEGMENT_OPERATOR.IN_RANGE
  ],
  [FILTER_FIELDS.TOTAL_PRICE]: [
    PRODUCT_SEGMENT_OPERATOR.GTE,
    PRODUCT_SEGMENT_OPERATOR.GT,
    PRODUCT_SEGMENT_OPERATOR.LT,
    PRODUCT_SEGMENT_OPERATOR.LTE,
    PRODUCT_SEGMENT_OPERATOR.IN_RANGE
  ],
  [FILTER_FIELDS.URI]: [
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.UTM_SOURCE]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.UTM_MEDIUM]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.UTM_CAMPAIGN]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.UTM_TERM]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.UTM_CONTENT]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.CUSTOM_LABEL_0]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.CUSTOM_LABEL_1]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.CUSTOM_LABEL_2]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.CUSTOM_LABEL_3]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ],
  [FILTER_FIELDS.CUSTOM_LABEL_4]: [
    PRODUCT_SEGMENT_OPERATOR.EQ,
    PRODUCT_SEGMENT_OPERATOR.NEQ,
    PRODUCT_SEGMENT_OPERATOR.I_CONTAINS,
    PRODUCT_SEGMENT_OPERATOR.I_NOT_CONTAINS
  ]
};

export const AggregationTypeOperatorsConfig = {
  [AGGREGATION_TYPES.COUNT]: [
    PRODUCT_SEGMENT_OPERATOR.GTE,
    PRODUCT_SEGMENT_OPERATOR.GT,
    PRODUCT_SEGMENT_OPERATOR.LT,
    PRODUCT_SEGMENT_OPERATOR.LTE,
    PRODUCT_SEGMENT_OPERATOR.IN_RANGE
  ],
  [AGGREGATION_TYPES.SUM]: [
    PRODUCT_SEGMENT_OPERATOR.GTE,
    PRODUCT_SEGMENT_OPERATOR.GT,
    PRODUCT_SEGMENT_OPERATOR.LT,
    PRODUCT_SEGMENT_OPERATOR.LTE,
    PRODUCT_SEGMENT_OPERATOR.IN_RANGE
  ]
};

export const EventTypeConfig = {
  [EVENT_TYPES.ALL_PRODUCT_EVENTS]: {
    filterFields: [
      FILTER_FIELDS.BRAND,
      FILTER_FIELDS.CATEGORY,
      FILTER_FIELDS.NAME,
      FILTER_FIELDS.PRODUCT_ID,
      FILTER_FIELDS.PRODUCT_SET_ID
      // FILTER_FIELDS.SALE_PRICE TODO: some of the fields name aren't coherent
      // FILTER_FIELDS.CUSTOM_LABEL_0,
      // FILTER_FIELDS.CUSTOM_LABEL_1,
      // FILTER_FIELDS.CUSTOM_LABEL_2,
      // FILTER_FIELDS.CUSTOM_LABEL_3,
      // FILTER_FIELDS.CUSTOM_LABEL_4
    ],
    aggregationTypes: []
  },
  [EVENT_TYPES.SEARCH]: {
    filterFields: [
      FILTER_FIELDS.SEARCH_TERM
    ],
    aggregationTypes: [
      {
        type: AGGREGATION_TYPES.COUNT
      }
    ]
  },
  [EVENT_TYPES.VIEW_CONTENT]: {
    filterFields: [
      FILTER_FIELDS.BRAND,
      FILTER_FIELDS.CATEGORY,
      FILTER_FIELDS.NAME,
      FILTER_FIELDS.PRODUCT_ID,
      FILTER_FIELDS.PRODUCT_SET_ID,
      FILTER_FIELDS.SALE_PRICE
      // FILTER_FIELDS.CUSTOM_LABEL_0,
      // FILTER_FIELDS.CUSTOM_LABEL_1,
      // FILTER_FIELDS.CUSTOM_LABEL_2,
      // FILTER_FIELDS.CUSTOM_LABEL_3,
      // FILTER_FIELDS.CUSTOM_LABEL_4
    ],
    aggregationTypes: [
      {
        type: AGGREGATION_TYPES.COUNT
      }
    ]
  },
  [EVENT_TYPES.ADD_TO_CART]: {
    filterFields: [
      FILTER_FIELDS.BRAND,
      FILTER_FIELDS.CATEGORY,
      FILTER_FIELDS.NAME,
      FILTER_FIELDS.PRODUCT_ID,
      FILTER_FIELDS.PRODUCT_SET_ID,
      FILTER_FIELDS.SALE_PRICE
      // FILTER_FIELDS.CUSTOM_LABEL_0,
      // FILTER_FIELDS.CUSTOM_LABEL_1,
      // FILTER_FIELDS.CUSTOM_LABEL_2,
      // FILTER_FIELDS.CUSTOM_LABEL_3,
      // FILTER_FIELDS.CUSTOM_LABEL_4
    ],
    aggregationTypes: [
      {
        type: AGGREGATION_TYPES.COUNT
      }
    ]
  },
  [EVENT_TYPES.VIEW_CART]: {
    filterFields: [
      FILTER_FIELDS.BRAND,
      FILTER_FIELDS.CATEGORY,
      FILTER_FIELDS.NAME,
      FILTER_FIELDS.PRODUCT_ID,
      FILTER_FIELDS.PRODUCT_SET_ID,
      FILTER_FIELDS.TOTAL_PRICE
      // FILTER_FIELDS.CUSTOM_LABEL_0,
      // FILTER_FIELDS.CUSTOM_LABEL_1,
      // FILTER_FIELDS.CUSTOM_LABEL_2,
      // FILTER_FIELDS.CUSTOM_LABEL_3,
      // FILTER_FIELDS.CUSTOM_LABEL_4
    ],
    aggregationTypes: [
      {
        type: AGGREGATION_TYPES.COUNT
      }
    ]
  },
  [EVENT_TYPES.PURCHASE]: {
    filterFields: [
      FILTER_FIELDS.BRAND,
      FILTER_FIELDS.CATEGORY,
      FILTER_FIELDS.NAME,
      FILTER_FIELDS.PRODUCT_ID,
      FILTER_FIELDS.PRODUCT_SET_ID,
      FILTER_FIELDS.TOTAL_PRICE
      // FILTER_FIELDS.CUSTOM_LABEL_0,
      // FILTER_FIELDS.CUSTOM_LABEL_1,
      // FILTER_FIELDS.CUSTOM_LABEL_2,
      // FILTER_FIELDS.CUSTOM_LABEL_3,
      // FILTER_FIELDS.CUSTOM_LABEL_4
    ],
    aggregationTypes: [
      {
        type: AGGREGATION_TYPES.COUNT
      },
      {
        type: AGGREGATION_TYPES.SUM,
        field: FILTER_FIELDS.TOTAL_PRICE
      }
    ]
  },
  [EVENT_TYPES.PAGE_VIEW]: {
    filterFields: [
      FILTER_FIELDS.URI
    ],
    aggregationTypes: [
      {
        type: AGGREGATION_TYPES.COUNT
      }
    ]
  },
  [EVENT_TYPES.UTM]: {
    filterFields: [
      FILTER_FIELDS.UTM_SOURCE,
      FILTER_FIELDS.UTM_MEDIUM,
      FILTER_FIELDS.UTM_CAMPAIGN,
      FILTER_FIELDS.UTM_TERM,
      FILTER_FIELDS.UTM_CONTENT
    ],
    aggregationTypes: [
      {
        type: AGGREGATION_TYPES.COUNT
      }
    ]
  }
};
