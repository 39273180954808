import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import _ from 'lodash';
import tokenSource from 'utils/AuthInfoSource';
import i18n from 'i18n';
import config from 'config';
import { getServerErrorMessageConfig } from 'core/ServerErrorMessages';
import { ServerError } from 'core/ServerError';

let source = axios.CancelToken.source();

export function injectToken (config: AxiosRequestConfig): AxiosRequestConfig {
  if (!config.headers) {
    config.cancelToken = source.token;
    return config;
  }
  const token: string = tokenSource.getToken();
  if (token) {
    config.headers['x-auth-token'] = token;
  }
  config.headers['accept-language'] = i18n.language.toLowerCase().replace('-', '_');
  config.cancelToken = source.token;
  return config;
}

export function updateToken (response: AxiosResponse<any>): AxiosResponse<any> {
  const token: string = response.headers['x-auth-token'];
  if (token) {
    const expiresIndex = 1;
    const parts = token.split(':');
    const expires = parts[expiresIndex];
    if (Number(expires) < new Date().getTime() && tokenSource.invalidTokenHandler) {
      tokenSource.invalidTokenHandler.tokenInvalidated();
      source.cancel();
      source = axios.CancelToken.source();
    } else {
      tokenSource.setToken(token, expires);
    }
  }
  return response;
}

function logout () {
  if (tokenSource.invalidTokenHandler) {
    tokenSource.invalidTokenHandler.tokenInvalidated();
    source.cancel();
    source = axios.CancelToken.source();
  }
}

export function autoLogout (error: any): any {
  const code = _.get(error, 'response.status');
  if (code === 401) {
    logout();
  }
  return Promise.reject(error);
}

export function errorHandler (error: AxiosError): any {
  const apiError: ErrorEventInit = { error };
  const apiErrorEvent = new ErrorEvent('api-error', apiError);
  window.dispatchEvent(apiErrorEvent);
  const status = _.get(error.response, 'data.status');
  const message = _.get(error.response, 'data.message');
  const detailObj = _.get(error.response, 'data.detailObj');
  const code = _.get(error, 'response.status');

  if (status && !(code === 403 || code === 404)) {
    const errorMessageConfig = getServerErrorMessageConfig(status, message ? message : i18n.t<string>('serverResponse.500000'));
    const messageToShow = errorMessageConfig.message;
    let errorUserTitle = null;
    let errorUserMsg = null;

    if (detailObj) {
      errorUserTitle = _.get(detailObj, 'error.error_user_title');
      errorUserMsg = _.get(detailObj, 'error.error_user_msg');
    }
    const isInvalidTokenError = status === 404012;
    errorMessageConfig.showAlert ?
      window.dispatchEvent(new CustomEvent<any>('show-error-modal', { detail: {
        errorMessageConfig: {
          ...errorMessageConfig,
          callback: isInvalidTokenError ? logout : null
        }
      }})) :
      window.dispatchEvent(new CustomEvent<any>('show-error-toast', { detail: {
        messageToShow,
        errorUserTitle,
        errorUserMsg
      }}));
    return Promise.reject(null);
  } else {
    if (code) {
      window.dispatchEvent(new CustomEvent<any>('show-error-page', { detail: { code } }));
      const errorMessage = getServerErrorMessageConfig(+`${code}000`);
      return Promise.reject(new ServerError(code, errorMessage.message, message));
    }
    return Promise.reject(error);
  }
}

export function cancelRequest (message: string = '') {
  source.cancel(message);
  source = axios.CancelToken.source();
}

const client = axios.create({
  baseURL: config.baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});

client.interceptors.request.use(injectToken);
client.interceptors.response.use(updateToken, autoLogout);
client.interceptors.response.use(
  res => res,
  errorHandler
);

export const clientWithoutErrorHandler = axios.create({
  baseURL: config.baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
});
clientWithoutErrorHandler.interceptors.request.use(injectToken);
clientWithoutErrorHandler.interceptors.response.use(updateToken, autoLogout);

export default client;
