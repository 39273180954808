import React from 'react';
import { StepPage } from 'containers/StepPage/StepPage';
import { Redirect } from 'react-router-dom';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import styles from './goSegmentSetupFlowPage.module.scss';
import i18n from 'i18n';
import { AppFooter } from 'components/AppFooter';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { ChooseCreateMethodStep } from './steps/chooseCreateMethodStep/ChooseCreateMethodStep';
import { GoSegmentSetupFlowPageModelData } from './GoSegmentSetupFlowPageModel';
import { GoSegmentForm } from './steps/mainStep/GoSegmentForm';

export const GoSegmentSetupFlowPage: React.FC<{
  advertisers,
  useModel: () => any
}> = ({
  advertisers,
  useModel
}) => {

  const {
    title,
    needChooseType,
    breadcrumbs,
    loading,
    redirectPath,
    initGoSegment,
    backToGoSegmentList,
    getMainStepData,
    onGoSegmentTypeChange
  }: GoSegmentSetupFlowPageModelData = useModel();

  const renderTitle = () => {
    return (
      <div className={styles.topArea}>
        <div className={styles.title}>
          <TitleWithBreadcrumbs
            title={title}
            routes={breadcrumbs}
          />
        </div>
      </div>
    );
  };

  const renderChooseMethedStep = ({ goNext }) => {
    return (
      <>
        {renderTitle()}
        <div className={styles.contentContainer}>
          <ChooseCreateMethodStep
            goNext={goNext}
            initGoSegment={initGoSegment}
            onGoSegmentTypeChange={onGoSegmentTypeChange}
          />
        </div>
      </>
    );
  };

  const renderMainStep = ({ goLast }) => {
    if (!initGoSegment) {
      return <div/>;
    }
    const mainStepData = getMainStepData();
    if (!mainStepData) {
      return <div/>;
    }
    return (
      <>
        {renderTitle()}
        <div className={styles.contentContainer}>
          <GoSegmentForm
            goLast={goLast}
            advertisers={advertisers}
            initGoSegment={mainStepData.initGoSegment}
            useModel={mainStepData.model}
            Fields={mainStepData.fields}
          />
        </div>
      </>
    );
  };

  if (!initGoSegment) {
    return <LoadingIndicator/>;
  }
  return (
    <div className={styles.goSegmentSetupFlowPages}>
      {loading && <LoadingIndicator/>}
      <StepPage cancel={backToGoSegmentList}>
        { needChooseType &&
          <StepPage.Step
            label={i18n.t<string>('goSegmentSetupFlow.sidebar.methodStep')}
            renderStepContent={renderChooseMethedStep}
          />
        }
        <StepPage.Step
          label={i18n.t<string>('goSegmentSetupFlow.sidebar.mainStep')}
          renderStepContent={renderMainStep}
        />
        <StepPage.SideBarFooter>
          <AppFooter/>
        </StepPage.SideBarFooter>
      </StepPage>
      {redirectPath && <Redirect to={redirectPath} />}
    </div>
  );
};
