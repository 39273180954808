import { SelectOptions } from 'components/common/commonType';
import { TiktokAdGroupFormData } from 'containers/TiktokAdGroups/TiktokAdGroupSetupFlowPage/TiktokAdGroupSetupFlowPageModel';
import { L1Object, L1ObjectObjective } from 'core/l1Object/L1Object';
import { Order } from 'core/order/Order';
import { getPriceValue } from 'helper/CurrencyHelper';
import { TiktokAdGroupWebService, RestfulTiktokAdGroupWebService } from 'ws/TiktokAdGroupWebService';
import { ADGROUP_DEFAULT_AGE_MAX, ADGROUP_DEFAULT_AGE_MIN, TiktokBillingEvent, TiktokAdGroup, TiktokAdGroupListRecord, TiktokAgeMapping, TiktokGenderMapping, TiktokOptimizationGoal } from './TiktokAdGroup';
import i18n from 'i18n';
import _ from 'lodash';
import { getTiktokAgeGroupsByAgeRange, tiktokAgeMaxOptions, tiktokAgeMinOptions } from 'core/limitation/l2ObjectTAOptions';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { tiktokTAInventories } from 'containers/Limitations/LimitationSetting/tiktokTAInventory';

export interface TiktokAdGroupManager {
  getAdGroup (adGroupId: number | string): Promise<TiktokAdGroup>;
  getAdGroups (tiktokCampaignId: number | string): Promise<TiktokAdGroupListRecord[]>;
  getUniqueAdGroupNames (tiktokCampaignId: number | string): Promise<String[]>;
  createAdGroup (group: L1Object, tiktokAdGroupFormData: TiktokAdGroupFormData): Promise<void>;
  editAdGroup (tiktokAdGroupId: string | number, tiktokAdGroupFormData: TiktokAdGroupFormData): Promise<void>;
  deleteAdGroups (adGroupIds: (number | string)[]): Promise<void>;
  updateAdGroupState (adGroupData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void>;
  getAdGroupOptions (from?: string, to?: string): Promise<SelectOptions[]>;
  getParentInfo (campaignId: string | number): Promise<any>;
  getOptimizationGoalOptions (l1ObjectId: L1Object): SelectOptions[];
  getDefaultOptimizationGoal (l1ObjectId: L1Object);
  getDefaultBillingEvent (l1ObjectId: L1Object): TiktokBillingEvent;
  getBilliingEventOptions (optimizationGoal: L2ObjectOptimizationGoal): SelectOptions[];
  getMinBudgetOfAdGroup (
    currencyRate: number,
    scheduleDateCount: number,
    order: Order
  ): number;
  wrapTiktokDayparting (tiktokAdGroupFormData: TiktokAdGroupFormData): string;
  wrapAdGroupForServer (tiktokAdGroupFormData: TiktokAdGroupFormData): any;
  getAdGroupTargeting (targetingValue: any): any;
  getNativeOptimizeGoalValue (optimizationGoal: L2ObjectOptimizationGoal): TiktokOptimizationGoal;
  getTASummaryData (targeting: any, tiktokCountries: (string | number)[], showGenderAll?: boolean, showNotSupportTA?: boolean): any;
}

export const objectiveOptimizeMap = {
  [L1ObjectObjective.AWARENESS]: [
    L2ObjectOptimizationGoal.REACH,
    L2ObjectOptimizationGoal.VIDEO_VIEWS
  ],
  [L1ObjectObjective.TRAFFIC]: [
    L2ObjectOptimizationGoal.CLICKS
  ],
  [L1ObjectObjective.SALES]: [
    L2ObjectOptimizationGoal.SALES
  ],
  [L1ObjectObjective.UNSPECIFIED]: [
    L2ObjectOptimizationGoal.UNSPECIFIED
  ]
};

const optimizationGoalBillingEventMap = {
  [L2ObjectOptimizationGoal.REACH]: [
    TiktokBillingEvent.CPM
  ],
  [L2ObjectOptimizationGoal.VIDEO_VIEWS]: [
    TiktokBillingEvent.CPV
  ],
  [L2ObjectOptimizationGoal.CLICKS]: [
    TiktokBillingEvent.CPC
  ],
  [L2ObjectOptimizationGoal.SALES]: [
    TiktokBillingEvent.OCPM
  ]
};

const tiktokDefaultGoals = {
  [L1ObjectObjective.AWARENESS]: L2ObjectOptimizationGoal.REACH.toString(),
  [L1ObjectObjective.TRAFFIC]: L2ObjectOptimizationGoal.CLICKS.toString(),
  [L1ObjectObjective.SALES]: L2ObjectOptimizationGoal.SALES.toString()
};

export class DefaultTiktokAdGroupManager implements TiktokAdGroupManager {

  webService: TiktokAdGroupWebService;

  constructor (
    webService: TiktokAdGroupWebService = new RestfulTiktokAdGroupWebService()
  ) {
    this.webService = webService;
  }

  async getAdGroup (adGroupId: number | string): Promise<TiktokAdGroup> {
    return this.webService.getAdGroup(adGroupId);
  }

  async getAdGroups (tiktokCampaignId: number | string): Promise<TiktokAdGroupListRecord[]> {
    return this.webService.getAdGroups(tiktokCampaignId);
  }

  async getUniqueAdGroupNames (tiktokCampaignId: string | number): Promise<String[]> {
    return this.webService.getUniqueAdGroupNames(tiktokCampaignId);
  }

  async createAdGroup (group: L1Object, tiktokAdGroupFormData: TiktokAdGroupFormData): Promise<void> {
    return this.webService.createAdGroup(group, this.wrapAdGroupForServer(tiktokAdGroupFormData));
  }

  async editAdGroup (tiktokAdGroupId: string | number, tiktokAdGroupFormData: TiktokAdGroupFormData): Promise<void> {
    return this.webService.editAdGroup(tiktokAdGroupId, this.wrapAdGroupForServer(tiktokAdGroupFormData));
  }

  async deleteAdGroups (adGroupIds: (number | string)[]): Promise<void> {
    return this.webService.deleteAdGroups(adGroupIds);
  }

  async updateAdGroupState (adGroupData: {
    l2ChannelId: (number | string),
    isDraft: boolean
  }[], state: 'activate' | 'deactivate'): Promise<void> {
    return this.webService.updateAdGroupState(adGroupData, state);
  }

  async getAdGroupOptions (from?: string, to?: string): Promise<SelectOptions[]> {
    return this.webService.getAdGroupOptions(from, to);
  }

  async getParentInfo (campaignId: string | number): Promise<any> {
    return this.webService.getParentInfo(campaignId);
  }

  getDefaultOptimizationGoal (l1ObjectId) {
    const objective = l1ObjectId.objective;
    return tiktokDefaultGoals[objective];
  }

  getOptimizationGoalOptions (l1ObjectId) {
    const objective = l1ObjectId.objective;
    const optimizationGoals = objectiveOptimizeMap[objective];
    return optimizationGoals ? optimizationGoals.map(goal => ({
      label: i18n.t<string>(`optimizationGoal.${goal.toLowerCase()}`),
      value: goal
    })) : [];
  }

  getDefaultBillingEvent (l1ObjectId) {
    const objective = l1ObjectId.objective;
    const optimizationGoal = tiktokDefaultGoals[objective];
    const billingEvents = optimizationGoalBillingEventMap[optimizationGoal];
    return billingEvents && billingEvents.length > 0 ?
      billingEvents[0] : TiktokBillingEvent.CPM;
  }

  getBilliingEventOptions (optimizationGoal) {
    const billingEvents = optimizationGoalBillingEventMap[optimizationGoal];
    return billingEvents ? billingEvents.map(event => ({
      label: i18n.t<string>(`adGroup.billingEvent.${event.toLowerCase()}`),
      value: event
    })) : [];
  }

  getMinBudgetOfAdGroup (
    currencyRate: number,
    scheduleDateCount: number,
    order: Order
  ) {
    const minDailyBudget = 20 * currencyRate;
    const minBudget = minDailyBudget * scheduleDateCount;
    return getPriceValue(order.currency, minBudget / (1 - order.orderMargin - order.sysMargin));
  }

  wrapAdGroupForServer (tiktokAdGroupFormData: TiktokAdGroupFormData) {
    const dayparting = this.wrapTiktokDayparting(tiktokAdGroupFormData);
    return {
      ..._.omit(tiktokAdGroupFormData, ['bid', 'budget', 'targeting', 'dayPart']),
      l2ObjectLifetimeBudget: tiktokAdGroupFormData.budget === '' ? null : tiktokAdGroupFormData.budget,
      l2ObjectBidAmount: tiktokAdGroupFormData.bid,
      dayparting: !_.isEmpty(dayparting) ? dayparting : undefined,
      dayPartSwitch: !_.isEmpty(dayparting) ? 'ON' : 'OFF',
      optimize_goal: this.getNativeOptimizeGoalValue(tiktokAdGroupFormData.optimize_goal),
      ...this.getAdGroupTargeting(tiktokAdGroupFormData.targeting)
    };
  }

  wrapTiktokDayparting (tiktokAdGroupFormData: TiktokAdGroupFormData): string {
    const dayPart = _.isEmpty(tiktokAdGroupFormData.dayPart) ? '' : _.omit(tiktokAdGroupFormData.dayPart, ['enabled']);
    let wrappedValue = '';
    !_.isEmpty(dayPart) && _.forEach(Object.keys(dayPart), (key) => {
      let day = '0'.repeat(48);
      _.forEach(dayPart[key], (hour: number) => {
        let startIndex = hour * 2;
        day = day.substring(0, startIndex) + '11' + day.substring(startIndex + 2, day.length);
      });
      wrappedValue = wrappedValue.concat(day);
    });
    return wrappedValue;
  }

  getNativeOptimizeGoalValue (pmax3OptimizationGoal: L2ObjectOptimizationGoal) {
    const nativeOptimizeMap = {
      [L2ObjectOptimizationGoal.REACH]: TiktokOptimizationGoal.REACH,
      [L2ObjectOptimizationGoal.VIDEO_VIEWS]: TiktokOptimizationGoal.VIDEO_VIEW,
      [L2ObjectOptimizationGoal.CLICKS]: TiktokOptimizationGoal.CLICK,
      [L2ObjectOptimizationGoal.SALES]: TiktokOptimizationGoal.CONVERT
    };
    return nativeOptimizeMap[pmax3OptimizationGoal];
  }

  getAdGroupTargeting = (limitationValue: any) => {
    const taIncludeTypeNameMap = {
      tiktok_location: 'location',
      tiktok_placement: 'placement',
      tiktok_audience: 'audience',
      tiktok_os: 'operation_system',
      genders: 'gender'
    };
    const taExcludeTypeNameMap = {
      tiktok_audience: 'excluded_audience'
    };
    const finalTargeting = {};
    if (!limitationValue) {
      return finalTargeting;
    }

    const includeTargeting = _.defaultTo(limitationValue.include, []);
    includeTargeting.forEach(targeting => {
      if (['tiktok_location', 'tiktok_placement', 'tiktok_audience', 'tiktok_os'].includes(targeting.type)) {
        finalTargeting[taIncludeTypeNameMap[targeting.type]] = _.uniq(targeting.value.map(option => option.value));
      } else if (targeting.type === 'genders') {
        finalTargeting[taIncludeTypeNameMap[targeting.type]] = Object.keys(TiktokGenderMapping).find(key => TiktokGenderMapping[key] === targeting.value) ||
          Object.keys(TiktokGenderMapping)[0];
        return;
      }
      finalTargeting[targeting.type] = Array.isArray(targeting.value) ? targeting.value.map(option => option.value) : targeting.value;
    });
    const excludeTargeting = _.defaultTo(limitationValue.exclude, []);
    excludeTargeting.forEach(targeting => {
      if (targeting.type === 'tiktok_audience') {
        finalTargeting[taExcludeTypeNameMap[targeting.type]] = targeting.value.map(option => option.value);
      }
    });
    const ageMin = _.get(finalTargeting, 'age_min', ADGROUP_DEFAULT_AGE_MIN);
    const ageMax = _.get(finalTargeting, 'age_max', ADGROUP_DEFAULT_AGE_MAX);
    const ageData = getTiktokAgeGroupsByAgeRange(ageMin, ageMax);
    finalTargeting['age'] = Object.keys(TiktokAgeMapping).filter(key => ageData.some(range => TiktokAgeMapping[key] === range.label));
    finalTargeting['ageMin'] = ageMin;
    finalTargeting['ageMax'] = ageMax;
    if (!finalTargeting['gender']) {
      finalTargeting['gender'] = Object.keys(TiktokGenderMapping)[0];
    }
    return {
      ..._.omit(finalTargeting, ['age_min', 'age_max', 'genders'])
    };
  }

  getTASummaryData (targeting: any, tiktokCountries: (string | number)[], showGenderAll: boolean = true, showNotSupportTA: boolean = false) {
    const ageMin = targeting.include.find(limitation => limitation.type === 'age_min');
    const ageMax = targeting.include.find(limitation => limitation.type === 'age_max');
    const genderValue = this.getGenderTAValue(targeting, showGenderAll);
    const ageMinOption = tiktokAgeMinOptions.find(option => option.value === _.get(ageMin, 'value', ADGROUP_DEFAULT_AGE_MIN));
    const ageMaxOption = tiktokAgeMaxOptions.find(option => option.value === _.get(ageMax, 'value', ADGROUP_DEFAULT_AGE_MAX));
    const ageGroupsHint = getTiktokAgeGroupsByAgeRange(_.get(ageMinOption, 'value', 0), _.get(ageMaxOption, 'value', 0))
      .map(group => group.label)
      .join(', ');

    return _.omitBy({
      general: _.compact([{
        label: i18n.t<string>('limitation.labels.age'),
        value: `${_.get(ageMinOption, 'label', targeting.ageMin)} ~ ${_.get(ageMaxOption, 'label', targeting.ageMax)}`,
        hint: i18n.t<string>('limitation.hints.ageGroups', { groups: ageGroupsHint })
      }, genderValue ? {
        label: i18n.t<string>('limitation.labels.gender'),
        value: i18n.t<string>(`targeting.tiktok.gender.${genderValue.toLowerCase()}`)
      } : undefined]),
      include: this.getTASummaryOfOpt(targeting.include, tiktokCountries, showNotSupportTA),
      exclude: this.getTASummaryOfOpt(targeting.exclude, tiktokCountries, showNotSupportTA)
    }, _.isEmpty);
  }

  private getGenderTAValue = (targeting: any, showGenderAll) => {
    const gender = targeting.include.find(limitation => limitation.type === 'genders');
    return (!gender || gender.value === -1) && !showGenderAll ?
      undefined :
      Object.keys(TiktokGenderMapping).find(key => TiktokGenderMapping[key] === (gender ? gender.value : -1)) ||
      Object.keys(TiktokGenderMapping)[0];
  }

  private getTASummaryOfOpt = (limitationData: any, tiktokCountries: (string | number)[], showNotSupportTA: boolean) => {
    if (!limitationData) {
      return undefined;
    }

    return _.compact(_.flatten(limitationData.filter(data => !['age_min', 'age_max', 'genders'].includes(data.type)).map((data) => {
      const value = _.defaultTo(data.value, []);
      if (value.length === 0) {
        return undefined;
      }

      const settingData: any = _.defaultTo(tiktokTAInventories[data.type], {});
      if (!showNotSupportTA && _.isEmpty(settingData)) {
        return undefined;
      }

      const taValueWithI18nFormatter = _.partial(this.taValueFormatter, settingData.i18nPrefix);
      if (data.type === 'tiktok_location') {
        return this.getLocationSummary(value, tiktokCountries, taValueWithI18nFormatter);
      }
      return {
        label: i18n.t<string>(_.defaultTo(settingData.title, data.type)),
        value: Array.isArray(value) ?
          value.map(taValueWithI18nFormatter).join(', ') :
          value
      };
    })));
  }

  private getLocationSummary = (value, tiktokCountries, taValueWithI18nFormatter) => {
    const countries: any[] = [];
    const provinces: any[] = [];
    value.forEach((location) => {
      if (tiktokCountries.includes(location.value)) {
        countries.push(location);
      } else {
        provinces.push(location);
      }
    });

    return [
      {
        label: i18n.t<string>('adGroupSetupFlow.summaryStep.countries'),
        value: countries.map(taValueWithI18nFormatter).join(', ')
      },
      {
        label: i18n.t<string>('adGroupSetupFlow.summaryStep.provinces'),
        value: provinces.map(taValueWithI18nFormatter).join(', ')
      }
    ].filter(item => !_.isEmpty(item.value));
  }

  private taValueFormatter = (i18nPrefix, option) => {
    const i18nKey = `${i18nPrefix}.${option.value.toLowerCase()}`;
    if (i18nPrefix && i18n.exists(i18nKey)) {
      return i18n.t<string>(i18nKey);
    }
    return option.value.toString();
  }
}
