import { CompanyMember, Actor } from 'core';
import { ColumnDefinition, sortableColumn, SortDescriptor } from 'components/TableColumn/TableColumn';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import i18n from 'i18n';
import styles from './agencyMemberList.module.scss';

export interface PermissionActionHandler {

  (actor: Actor): void;
}

export type PermissionAction = {

  readonly icon: IconProp;
  readonly handler: PermissionActionHandler;
};

export interface AgencyMemberListModel {

  getColumnDefinition (columnName: string): ColumnDefinition;

  readonly members: Array<CompanyMember>;
  readonly noDataDescription: string;
  readonly defaultSorts: SortDescriptor;
  readonly roleActions: any[];
  readonly editBtnsColumn: ColumnDefinition;
}

export type AgencyMemberListProps = {

  readonly agencyId: number;
  readonly model: AgencyMemberListModel;
};

export class DefaultAgencyMemberListModel implements AgencyMemberListModel {
  members: Array<any>;
  roleActions: any[];

  constructor (companyMembers: Array<CompanyMember>, roleActions: any[]) {
    this.roleActions = roleActions;
    this.members = companyMembers.map(member => {
      return {
        id: member.id,
        name: member.account.name,
        email: member.account.email,
        status: member.account.activated ? i18n.t<string>('agency.member.active') : i18n.t<string>('agency.member.inactive'),
        permission: i18n.t<string>(`agency.member.permissions.${member.roleName}`),
        account: member.account,
        roleName: member.roleName
      };
    });
  }

  get noDataDescription (): string {
    return 'agency.member.noDataAvailable';
  }

  getColumnDefinition (columnName: string): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };
    return {
      dataField: columnName,
      text: `agency.member.headers.${columnName}`,
      sort: true,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    };
  }

  get editBtnsColumn (): ColumnDefinition {
    return sortableColumn('editBtn', '', false);
  }

  get defaultSorts (): SortDescriptor {
    return [{
      dataField: 'index',
      order: 'asc'
    }];
  }
}
