import defaults, { UserDefaults } from 'utils/UserDefaults';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';

export interface LanguageManager {

  currentLanguage: string;
  readonly availableLanguages: Array<string>;

  readonly event: UpdateEventListener<LanguageManager>;
  setCurrentLanguageAndReloadPage (lang: string): void;
}

export class DefaultLanguageManager implements LanguageManager {
  defaults: UserDefaults;
  event: FireableUpdateEventListener<LanguageManager>;

  constructor (userDefaults: UserDefaults = defaults) {
    this.defaults = userDefaults;
    this.event = new FireableUpdateEventListener<LanguageManager>();
  }

  get currentLanguage (): string {
    return this.defaults.language;
  }

  set currentLanguage (lang: string) {
    if (lang === this.defaults.language) {
      return;
    }
    this.defaults.language = lang;
    this.event.fireEvent(this);
  }

  setCurrentLanguageAndReloadPage (lang: string) {
    if (lang === this.defaults.language) {
      return;
    }
    this.defaults.language = lang;
    window.location.reload();
  }

  get availableLanguages (): Array<string> {
    return ['zh-TW', 'en'];
  }
}
