import React, { Fragment } from 'react';
import classNames from 'classnames/bind';
import { SearchBar } from 'components/common/SearchBar';
import i18n from 'i18next';
import { BindCreativeStateContentProps, BindCreativeStateContentState } from './BindCreativeStateContentModel';
import styles from './bindCreativeStateContent.module.scss';
import { CreativeType } from 'core/creative/Creative';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { CreativeCard } from 'components/CreativeCard/CreativeCard';
import { DefaultCreativeCardModel } from 'components/CreativeCard/CreativeCardModel';
import { StateDataContext } from 'containers/Common/StateDataContext';
import { TablePlaceholder } from 'components/TablePlaceholder/TablePlaceholder';
import { CreativeManagementStateContext } from './CreativeManagementStateContext';

export class BindCreativeStateContent extends React.Component<BindCreativeStateContentProps, BindCreativeStateContentState> {

  handler: number;
  cssClassNames: any;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  renderTypeFilters = () => {
    return this.props.model.creativeTypes.map(creativeType => {
      const onClick = () => {
        this.props.model.handleOnTypeFilter(creativeType);
      };
      const className = this.cssClassNames('filterBtn', {
        selected: creativeType === this.props.model.state.selectedType
      });
      return (
        <div key={creativeType} className={className} onClick={onClick}>
          {i18n.t<string>(`bindCreativeStateContent.labels.${CreativeType[creativeType].toLowerCase()}`)}
        </div>
      );
    });
  }

  renderSelectFilter = () => {
    const onClick = () => {
      this.props.model.handleOnSelectedFilter();
    };
    const className = this.cssClassNames('filterBtn', {
      selected: this.props.model.state.selectedType === undefined
    });
    return (
      <div className={className} onClick={onClick}>
        {i18n.t<string>('bindCreativeStateContent.buttons.selected')}
      </div>
    );
  }

  renderCreatives = () => {
    return this.props.model.state.filteredCreatives.map(creative => {
      const selected = this.props.model.state.selectedCreatives.indexOf(creative.id) >= 0;
      return (
        <CreativeCard key={creative.id} model={new DefaultCreativeCardModel(creative, selected, this.props.model.handleOnSelect)}/>
      );
    });
  }

  renderSortBtns = () => {
    const newToOldClassName = this.cssClassNames('sortBtn', {
      selected: this.props.model.state.sortFromNewToOld
    });
    const oldToNewClassName = this.cssClassNames('sortBtn', {
      selected: !this.props.model.state.sortFromNewToOld
    });
    return (
      <div className={styles.sortBtns}>
        <div className={newToOldClassName} onClick={this.sortByCTimeNewToOld}>
          {i18n.t<string>('bindCreativeStateContent.buttons.newToOld')}
        </div>
        <div className={oldToNewClassName} onClick={this.sortByCTimeOldToNew}>
          {i18n.t<string>('bindCreativeStateContent.buttons.oldToNew')}
        </div>
      </div>
    );
  }

  sortByCTimeNewToOld = () => {
    this.props.model.handleOnSort(true);
  }

  sortByCTimeOldToNew = () => {
    this.props.model.handleOnSort(false);
  }

  render () {
    const model = this.props.model;
    if (model.state.loading) {
      return <LoadingIndicator />;
    }

    return (
      <StateDataContext.Consumer>
        {(stateContext: CreativeManagementStateContext) => {
          model.setStateContext(stateContext);
          return (
            <div className={styles.bindCreativeStateContent}>
              <div className={styles.title}>
                {i18n.t<string>('bindCreativeStateContent.labels.title')}
              </div>
              <div className={styles.searchAndSortArea}>
                <SearchBar
                  model={{
                    placeholder: i18n.t<string>('bindCreativeStateContent.placeholders.searchbar'),
                    search: model.handleOnSearch
                  }}
                />
                {this.renderSortBtns()}
              </div>
              <div className={styles.filterArea}>
                {this.renderTypeFilters()}
                {this.renderSelectFilter()}
              </div>
              <div className={styles.selectAllCheckBox}>
                { model.state.selectedType && (
                  <Fragment>
                    <input
                      type='checkbox'
                      checked={model.creatives.length !== 0 && model.state.selectedFilteredCreatives.length === model.state.filteredCreatives.length}
                      ref={el => el && (el.indeterminate = model.state.selectedFilteredCreatives.length !== model.state.filteredCreatives.length && model.state.selectedFilteredCreatives.length > 0)}
                      onChange={model.handleOnSelectAll}
                      id={'BinCreativeSelectAll'}
                    />
                    <label htmlFor='BinCreativeSelectAll'>
                      {i18n.t<string>('bindCreativeStateContent.buttons.allSelected')}
                    </label>
                  </Fragment>
                  )
                }
              </div>
              {
                this.props.model.state.filteredCreatives.length === 0 ?
                  <TablePlaceholder placeholder={i18n.t<string>('campaignCreativeList.headers.noDataAvailable')}/> :
                  <div className={styles.creatives}>
                    {this.renderCreatives()}
                  </div>
              }
            </div>
          );
        }}
      </StateDataContext.Consumer>
    );
  }
}
