import { Link, Route, Switch } from 'react-router-dom';
import { useSourceDetailModel } from './SourceDetailModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Page } from 'components/Page/Page';
import { CategoryList } from '../Category/CategoryList';
import i18n from 'i18n';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { SeparationLine } from 'components/SeparationLine/SeparationLine';
import { InfoDisplay } from 'components/InfoDisplaySection/InfoDispaly';
import { CategoryForm } from '../Category/CategoryForm';
import { useCreateCategoryFormModel, useEditCategoryFormModel } from '../Category/CategoryFormModel';
import { useCallback } from 'react';

export const SourceDetail = () => {

  const {
    loading,
    source,
    viewData
  } = useSourceDetailModel();

  const renderCategoryCreateForm = useCallback(() => {
    if (!viewData || !source) {
      return <div/>;
    }
    return <CategoryForm sourceId={source.id} useModel={useCreateCategoryFormModel}/>;
  }, [viewData, source]);

  const renderCategoryEditForm = useCallback(() => {
    if (!viewData || !source) {
      return <div/>;
    }
    return <CategoryForm sourceId={source.id} useModel={useEditCategoryFormModel}/>;
  }, [viewData, source]);

  const renderEditBtns = useCallback(() => {
    if (!source) {
      return <div/>;
    }
    return (
      <Link to={`/tenmax-segments/sources/${source.id}/edit`}>
        {i18n.t<string>('sourceDetail.buttons.edit')}
      </Link>
    );
  }, [source]);

  const renderContent = useCallback(() => {
    if (!viewData || !source) {
      return <div/>;
    }

    const renderTopSection = () => (
      <>
        <SeparationLine/>
        <InfoDisplay
          title={i18n.t<string>('sourceDetail.titles.info', { name: source.name })}
          infoSections={[{ data: viewData }]}
          renderEditBtns={renderEditBtns}
        />
      </>
    );

    return (
      <Page
        title={i18n.t<string>('sourceDetail.title')}
        breadcrumbsRoutes={[
          { path: '/tenmax-segments/sources', breadcrumb: i18n.t<string>('sourceList.title') },
          { path: '/tenmax-segments/sources/:sourceId', breadcrumb: DynamicBreadcrumb, props: { label: source.name, matchParam: 'sourceId' } }
        ]}
        topAreaWithPadding={false}
        renderInTopArea={renderTopSection}
      >
        <CategoryList sourceId={source.id}/>
      </Page>
    );
  }, [viewData, source, renderEditBtns]);

  if (loading || !source) {
    return <LoadingIndicator/>;
  }

  return (
    <Switch>
      <Route
        path={`/tenmax-segments/sources/${source.id}/categories/new`}
        render={renderCategoryCreateForm}
      />
      <Route
        path={`/tenmax-segments/sources/${source.id}/categories/:categoryId/edit`}
        render={renderCategoryEditForm}
      />
      <Route
        render={renderContent}
      />
    </Switch>
  );
};
