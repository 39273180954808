import React from 'react';
import i18n from 'i18n';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import styles from './formHints.module.scss';

export const renderOverBudgetWording = (currency, overBudget) => {
  const wording = i18n.t<string>('campaign.descriptions.overed');
  return (
    <Trans i18nKey='campaign.descriptions.overBudget'>
      {{ wording }}<span className='tip_normal'>Total budget</span>
      <span className='text-dark'><> : {{ currency }} {{ overBudget }}</></span>
    </Trans>
  );
};

export const renderIncreaseFBCampaignBudgetHint = (order, l1ObjectId, fbCampaignMinBudget) => {
  return (
    <div className={styles.lifetimeBudgetError}>
      <div className='errorTip'>
        {i18n.t<string>('adSetSetupFlow.mainStep.hints.fbCampaignBudget', { budget: formatPriceWithCurrency(order.currency, fbCampaignMinBudget) })}
      </div>
      <Link to={`/orders/${order.orderNumber}/campaign-groups/${l1ObjectId}/edit`}>
        {i18n.t<string>('adSetSetupFlow.mainStep.hints.backToEditFbCamapign')}
      </Link>
    </div>
  );
};
