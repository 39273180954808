import React, { useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { Html5FormProps } from './Html5FormModel';
import styles from './html5Form.module.scss';
import { faFileArchive } from '@fortawesome/free-regular-svg-icons';
import config from 'config';
import { Trans } from 'react-i18next';
import { FormConfig } from 'components/common/form/FormConfig';
import { FormContent } from 'components/common/form/Form';

export const Html5Form: React.FunctionComponent<Html5FormProps & { formik: FormikContextType<any> }> = (props) => {

  const [formConfig, setFormConfig] = useState<FormConfig>(props.getInitFormConfig());

  const bannerSizeOptions = useMemo(() => {
    const recommendSize = ['320x50', '728x90', '300x250', '300x600', '300x100'];
    const otherSize = ['160x600', '336x280', '234x60', '320x100', '468x60',
      '970x90', '970x250', '970x325', '640x1000', '120x600',
      '300x1050', '250x250', '200x200', '180x150', '125x125',
      '320x250', '336x480', '320x480'];
    const recommendSizeOptions = recommendSize.map(size => {
      const widthHeight = size.split('x');
      return {
        label: `${widthHeight[0]} x ${widthHeight[1]} (${i18n.t<string>('html5Form.labels.recommended')})`,
        value: size
      };
    });

    const otherSizeOptions = otherSize.map(size => {
      const widthHeight = size.split('x');
      return {
        label: `${widthHeight[0]} x ${widthHeight[1]}`,
        value: size
      };
    });
    return recommendSizeOptions.concat(otherSizeOptions);
  }, []);

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addFormikSelect({
              label: i18n.t<string>('html5Form.labels.bannerSize'),
              name: 'typeProperties.bannerSize',
              simpleValue: true,
              options: bannerSizeOptions
            })
            .addFormikFileInput({
              type: 'other',
              label: i18n.t<string>('html5Form.labels.htmlZip'),
              name: 'medias.htmlZip',
              className: styles.fileInput,
              icon: faFileArchive,
              hints: [
                i18n.t<string>('html5Form.labels.typeHint'),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
              ],
              permanentHint: (
                <span className={styles.hint}>
                  <Trans i18nKey='html5Form.labels.fileHint'>
                    ...<a href={`https://storage.googleapis.com/${config.cloudStorageConfig.bucket}/index.html.zip`} download>Click here</a>...
                  </Trans>
                </span>
              ),
              validate: props.model.validateZip
            })
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      ).build()
    );
  }, [
    props.basicFields,
    props.model.validateZip,
    bannerSizeOptions
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(Html5Form);
