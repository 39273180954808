import { AdType, CampaignState } from 'core/rtbCampaign/RtbCampaign';
import { CampaignDashboardWebService, RestfulCampaignDashboardWebService } from 'ws/CampaignDashboardWebService';
import { CampaignDashboardRecord, DashboardSummaryInfo } from './CampaignDashboard';

export interface CampaignDashboardManager {
  getSummaryInfo: (
    adTypes?: AdType[]
  ) => Promise<DashboardSummaryInfo[]>;

  getCampaignList: (
    day: number,
    adTypes?: AdType[],
    campaignState?: CampaignState
  ) => Promise<CampaignDashboardRecord[]>;
}

export class DefaultCampaignDashboardManager implements CampaignDashboardManager {

  webService: CampaignDashboardWebService;

  constructor (
    webService: CampaignDashboardWebService = new RestfulCampaignDashboardWebService()
  ) {
    this.webService = webService;
  }

  async getSummaryInfo (
    adTypes?: AdType[]
  ) {
    return this.webService.getSummaryInfo(adTypes);
  }

  async getCampaignList (
    day: number,
    adTypes?: AdType[],
    campaignState?: CampaignState
  ) {
    return this.webService.getCampaignList(day, adTypes, campaignState);
  }
}
