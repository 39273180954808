import React from 'react';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { FbAdSetListColumns, useFbAdSetListModel } from './FbAdSetListModel';
import i18n from 'i18n';
import styles from './fbAdSetList.module.scss';
import { SearchBar } from 'components/common/SearchBar';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Link } from 'react-router-dom';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { Modal } from 'components/common/Modal/Modal';
import { AddonFeatureManager } from 'core';
import { Order } from 'core/order/Order';
import cx from 'classnames/bind';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, hasFuncs, notSelfServeAdObject } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { L1Object } from 'core/l1Object/L1Object';
import Select from 'components/common/Select/Select';
import { ListEditBar } from 'components/ListEditBar/ListEditBar';
import { FilterMenuTab } from 'components/common/FilterMenuTab/FilterMenuTab';
import { DraftCreateModal } from 'containers/Draft/DraftCreateModal';
import _ from 'lodash';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
const classNames = cx.bind(styles);

export type FbAdSetListProps = {
  order: Order;
  adSets: FbAdSet[];
  budgetBalance: number;
  l1Object?: L1Object;
  addonFeatureManager: AddonFeatureManager;
  refreshList: () => void;
};

export const FbAdSetList: React.FC<FbAdSetListProps> = ({
  order,
  adSets,
  l1Object,
  budgetBalance,
  addonFeatureManager,
  refreshList
}) => {

  const {
    loading,
    columns,
    currentUrl,
    filteredList,
    searchString,
    selectedAdSets,
    selectedDrafts,
    summaryData,
    selectedMetricsGroup,
    metricsGroupOptions,
    canNotCreateMessage,
    showDraftCreateModal,
    onMetricsGroupChange,
    rowClasses,
    onHandleSearch,
    deleteAdSets,
    deleteDraft,
    activeAdSet,
    deactiveAdSet,
    bindCreatives,
    activateCreatives,
    deactivateCreatives,
    showDeleteConfirmModal,
    canDeleteSelectedAdSets,
    handleRemoveSelect,
    onDeleteModalClose,
    onDeleteDraftModalClose,
    showDeleteDraftConfirmModal,
    onShowDeleteDraftConfirmModal,
    hideDraftCreateModal,
    setShowDraftCreateModal,
    filterMenuTabConfigs
  } = useFbAdSetListModel(order, l1Object, budgetBalance, adSets, refreshList, addonFeatureManager);

  const onShowDeleteConfirmModal = (event?: any) => {
    event && event.stopPropagation();
    showDeleteConfirmModal();
  };

  const handleShowDeleteDraftConfirmModal = (event?: any) => {
    event && event.stopPropagation();
    onShowDeleteDraftConfirmModal();
  };

  const renderEditBar = () => {
    const selectedObjects = [...selectedAdSets, ...selectedDrafts];
    const handleNavEvent = (event) => {
      event && event.stopPropagation();
    };
    const showDeleteTip = !canDeleteSelectedAdSets(selectedAdSets);
    const deleteButtonContainerClassName = classNames('deleteButtonContainer', {
      block: showDeleteTip
    });
    return (
      <ListEditBar selectedItems={selectedObjects} handleRemoveSelect={handleRemoveSelect}>
        <PermissionChecker permissionAware={notSelfServeAdObject(l1Object)}>
          <div className={styles.dropdownContainer}>
            <ListEditBar.Dropdown
              title={i18n.t<string>('campaignList.labels.creative')}
              id='creativeManagement'
              onClick={handleNavEvent}
            >
              <ListEditBar.Dropdown.Item onClick={bindCreatives}>{i18n.t<string>('campaignList.labels.bindCreative')}</ListEditBar.Dropdown.Item>
              <ListEditBar.Dropdown.Item onClick={deactivateCreatives}>{i18n.t<string>('campaignList.labels.deactivateCreative')}</ListEditBar.Dropdown.Item>
              <ListEditBar.Dropdown.Item onClick={activateCreatives}>{i18n.t<string>('campaignList.labels.activeCreative')}</ListEditBar.Dropdown.Item>
            </ListEditBar.Dropdown>
          </div>
        </PermissionChecker>
        <ListEditBar.Item>
          <ListEditBar.Link onClick={activeAdSet}>
            {i18n.t<string>('campaignList.labels.activeCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <ListEditBar.Item >
          <ListEditBar.Link onClick={deactiveAdSet}>
            {i18n.t<string>('campaignList.labels.deactivateCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <PermissionChecker permissionAware={notSelfServeAdObject(l1Object)}>
          <ListEditBar.Item>
            <ListEditBar.Link onClick={_.partial(setShowDraftCreateModal, true)}>
              {i18n.t<string>('campaignList.labels.duplicate')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        </PermissionChecker>
        <div className={deleteButtonContainerClassName}>
          <div className={styles.tooltip}>
            {i18n.t<string>('campaignList.labels.cannotDelete')}
          </div>
          <ListEditBar.Item >
            <ListEditBar.Link onClick={onShowDeleteConfirmModal}>
              {i18n.t<string>('campaignList.labels.deleteCampaign')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        </div>
        <div className={styles.deleteDraftTab}>
          <ListEditBar.Item>
            <ListEditBar.Link disabled={!_.isEmpty(selectedAdSets)} onClick={handleShowDeleteDraftConfirmModal}>
              <FontAwesomeIcon icon={faTrashAlt} />
              {i18n.t<string>('campaignList.labels.deleteDraft')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        </div>
      </ListEditBar>
    );
  };

  const createContainerClassName = classNames('createAdSet', 'tipContainer', {
    block: canNotCreateMessage !== ''
  });

  const renderDraftCreateModal = () => {
    if (!l1Object) {
      return <div/>;
    }

    const adSetsToCreateDraft = adSets
      .filter(adSet =>
        (!adSet.isDraft && adSet.id && selectedAdSets.includes(adSet.id)) ||
        (adSet.draftId && selectedDrafts.includes(adSet.draftId.toString())))
      .map(adSet => {
        return adSet.isDraft ? {
          id: adSet.draftId ? adSet.draftId : 0,
          isDraft: true
        } : {
          id: adSet.id ? adSet.id : 0,
          isDraft: false
        };
      });
    return (
      <DraftCreateModal
        order={order}
        l1Object={l1Object}
        l2ObjectsToCreateDraft={adSetsToCreateDraft}
        onClose={hideDraftCreateModal}
      />
    );
  };

  return (
    <div className={styles.fbAdSetList}>
      {loading && <LoadingIndicator />}
      {deleteAdSets &&
        <Modal
          title={i18n.t<string>('adSet.deleteTitle')}
          secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: () => onDeleteModalClose(false) }}
          primaryButton={{ title: i18n.t<string>('common.buttons.delete'), callback: deleteAdSets }}
          dismiss={onDeleteModalClose}
        >
          {i18n.t<string>('adSet.deleteModalContent')}
        </Modal>
      }
      {showDeleteDraftConfirmModal &&
        <Modal
          title={i18n.t<string>('campaignList.labels.deleteDraftModalTitle')}
          secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: onDeleteDraftModalClose }}
          primaryButton={{ title: i18n.t<string>('campaignList.labels.deleteCampaign'), callback: deleteDraft }}
          dismiss={onDeleteDraftModalClose}
        >
          {_.map(i18n.t<string>('campaignList.labels.deleteDraftModalContent').split(/\r?\n/), (hint, index) => (
            <div key={index}>{hint}</div>
          ))}
        </Modal>
      }
      <div className={styles.titleBar}>
        {(selectedAdSets.length > 0 || selectedDrafts.length > 0) ?
          renderEditBar() :
          <div className={styles.filterArea}>
            <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE).and(addOnEnabled(ADDONFEATURE.CHANNEL.FB).and(notSelfServeAdObject(l1Object)))}>
              <div className={createContainerClassName}>
                {canNotCreateMessage &&
                  <span className={styles.tooltip}>
                    {canNotCreateMessage}
                  </span>
                }
                <Link
                  to={`${currentUrl}/campaigns/new`}
                  className={`btn btn-tertiary btn-sm ${styles.createBtn}`}
                >
                  {i18n.t<string>('fbAdSetList.buttons.create')}
                </Link>
              </div>
            </PermissionChecker>
            <FilterMenuTab
              filterMenuTabConfigs={filterMenuTabConfigs}
            />
            <SearchBar
              model={{
                placeholder: i18n.t<string>('fbAdSetList.placeholders.searchbar'),
                search: onHandleSearch,
                defaultValue: searchString
              }}
            />
          </div>
        }
      </div>
      <div className={styles.contentArea}>
        <div className={styles.metricsOptionsContainer}>
          <Select
            className={styles.metricsOptions}
            options={metricsGroupOptions}
            name='metricsGroupOptions'
            simpleValue
            value={selectedMetricsGroup}
            onChange={onMetricsGroupChange}
          />
        </div>
        <StickableBootstrapTable
          stickFirstColumn
          stickLastColumn
          key={selectedMetricsGroup}
          keyField={FbAdSetListColumns.LISTID}
          data={filteredList}
          columns={columns}
          summaryData={summaryData}
          rowClasses={rowClasses}
          hidePagination={true}
          noDataIndication={i18n.t<string>('fbAdSetList.labels.noData')}
          defaultSorted={[{
            dataField: FbAdSetListColumns.LISTID,
            order: 'desc'
          }]}
        />
      </div>
      {showDraftCreateModal &&
        renderDraftCreateModal()
      }
    </div>
  );
};
