import {
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType, RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { SupportVideoViewsFormModel } from './SupportVideoViewsFormModel';

export interface ComboFormModel extends RtbCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}

abstract class AbstractComboFormModel extends SupportVideoViewsFormModel
  implements ComboFormModel {

  showVideoProgressRadio = true;

  getBidPriceFloorData (): any {
    const data = this.order.campaignBidPrice.find(data => data.type === AdType.COMBO);
    return data ? data.bidFloor : {};
  }

  get campaignAdType (): AdType {
    return AdType.COMBO;
  }

  needVideoAdViewObjective (priceModel: RtbCampaignPlanType): boolean {
    return priceModel === RtbCampaignPlanType.FCPV;
  }
}

export class EditComboFormModel extends AbstractComboFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateComboFormModel extends AbstractComboFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
