import React from 'react';
import styles from './creativePreview.module.scss';
import { Slider } from 'components/common/Slider/Slider';
import { DefaultSliderModel } from 'components/common/Slider/SliderModel';
import _ from 'lodash';
import { CreativeListRecord, CreativeOfCampaign } from 'core/creative/Creative';

export const NativeCreativeImagePreview: React.FunctionComponent<{creative: CreativeOfCampaign | CreativeListRecord}> = (props) => {
  const previewAreaWidth = 218;
  const previewAreaHeight = 115;
  const creativeValue: any = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div/>;
  }
  const key = _.get(creativeValue, 'img.url', '') + _.get(creativeValue, 'icon.url', '');
  return (
    <Slider key={key} model={new DefaultSliderModel(previewAreaWidth, previewAreaHeight, true, true, 0, true)}>
      <div className={styles.nativeCreativeImagePreview}>
        <img src={creativeValue.img_l ? creativeValue.img_l.url : ''} alt={'img_review'}/>
      </div>
      <div className={[styles.nativeCreativeImagePreview, styles.icon].join(' ')}>
        <img src={creativeValue.icon_l ? creativeValue.icon_l.url : ''} alt={'icon_review'}/>
      </div>
    </Slider>
  );
};
