import { AbstractFormContentModel } from './FormContentModel';
import _ from 'lodash';
import CustomLayoutForm from './CustomLayoutForm';
import { Creative, CreativeType } from 'core/creative/Creative';
import { BasicFormProps } from './BasicFormProps';
import { CustomLayoutManager, DefaultCustomLayoutManager } from 'core/customLayout/CustomLayoutManager';
import { CustomLayout, CustomLayoutStatus, CustomLayoutType } from 'core/customLayout/CustomLayout';

export type CustomLayoutFormProps = {
  readonly model: CustomLayoutFormModel;
} & BasicFormProps;

export class CustomLayoutFormModel extends AbstractFormContentModel {

  public needBannerUrl: boolean = false;

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;
  layoutData: CustomLayout[] = [];

  constructor (
    private type: CreativeType.CUSTOM_RECTANGLE | CreativeType.CUSTOM_BOTTOM,
    private customLayoutManager: CustomLayoutManager = new DefaultCustomLayoutManager()
  ) {
    super();
  }

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = (): any => {
    return CustomLayoutForm;
  }

  getInitTypeProperties () {
    return {};
  }

  async init () {
    const relatedLayoutType = this.type === CreativeType.CUSTOM_RECTANGLE ? CustomLayoutType.CUSTOM_RECTANGLE : CustomLayoutType.CUSTOM_BOTTOM;
    const customLayoutsWithPagination = await this.customLayoutManager.getCustomLayouts(undefined, [CustomLayoutStatus.ACTIVE], [relatedLayoutType]);
    this.layoutData = customLayoutsWithPagination.customLayouts;
  }

  getFormModelData (creative: Creative) {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues', 'bannerExtra']);
    const creativeExtra = _.get(creative, 'basic.bannerExtra', {});
    let customLayoutId = _.get(creativeExtra, 'customLayoutId');
    const customLayout = this.layoutData.find(data => data.id === customLayoutId);
    if (!customLayout) {
      return {
        basic: {
          ...creativeBasic
        },
        limitations: creative.limitations
      };
    }

    const macroValueMap = _.get(creativeExtra, 'macros', {});
    let typeProperties = {
      customLayoutId,
      customLayout: { ...customLayout }
    };
    let medias = {};
    Object.keys(macroValueMap).forEach(macroName => {
      const macro = customLayout.macros[macroName];
      const value = macroValueMap[macroName];
      if (_.isNil(macro)) return;
      switch (macro.type) {
        case 'image':
        case 'video':
          _.set(medias, `${macro.type}.${macroName}`, { url: value });
          break;
        default:
          _.set(typeProperties, `macros.${macroName}`, value);
          break;
      }
    });
    return {
      basic: {
        ...creativeBasic,
        typeProperties,
        medias
      },
      limitations: creative.limitations
    };
  }
}
