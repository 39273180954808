export const withDangerStatus = des => ({
  des,
  label: 'campaignList.labels.deleteState',
  color: 'danger'
});

export const withIssuesStatus = des => ({
  des,
  label: 'campaignList.labels.withIssues',
  color: 'warning'
});

export const withDeactivateStatus = des => ({
  des,
  label: 'campaignList.labels.deactivateState',
  color: 'black'
});

export const withActivateState = des => ({
  des,
  label: 'campaignList.labels.activateState',
  color: 'success'
});

export const withPendingReviewState = des => ({
  des,
  label: 'campaignList.labels.pendingReviewState',
  color: 'white'
});

export const withNotStartState = des => ({
  label: 'campaignList.labels.notStartState',
  color: 'light'
});

export const withEndState = des => ({
  label: 'campaignList.labels.endState',
  color: 'light'
});
