import { EditLimitation } from 'containers/Limitations/EditLimitation';
import CampaignBasicForm from 'containers/MessageCampaigns/MessageCampaignSetupFlow/MessageCampaignForm/MessageCampaignBasicForm';
import { MessageCampaignPlanType } from 'core/messageCampaign/MessageCampaign';
import { OrderType } from 'core/order/Order';
import { Formik } from 'formik';
import i18n from 'i18next';
import React from 'react';
import { Button, OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';
import ReactDOM from 'react-dom';
import { CampaignSetupTab, MessageCampaignSetupStepProps } from './MessageCampaignSetupStepModel';
import styles from './messageCampaignSetupStep.module.scss';

export class MessageCampaignSetupStep extends React.Component<MessageCampaignSetupStepProps> {

  componentDidUpdate () {
    this.scrollToErrorField();
  }

  componentWillUnmount () {
    this.props.model.onUnmount();
  }

  handleSubmit = async (values) => {};

  validate = (campaign) => {
    return this.props.model.validateCampaignBasic(campaign);
  }

  scrollToErrorField () {
    const node = ReactDOM.findDOMNode(this);
    if (node instanceof HTMLElement) {
      const child = node.querySelector('.errorTip');
      child && child.scrollIntoView({
        block: 'center'
      });
    }
  }

  getOptValueOfTA = (includeTA, preferredTA, orderType: OrderType) => {
    const defaultOptValue = orderType === OrderType.TENMAX ? 'preferred' : 'include';
    return includeTA ?
      'include' :
      preferredTA ?
        'preferred' :
        defaultOptValue;
  }

  renderFormContent = (formikProps) => {
    const model = this.props.model;
    const order = model.flowModel.order;
    const campaignData = model.flowModel.state.campaign;
    const basicFormModel = model.getMessageCampaignBasicFormModel(campaignData.basic.adType);
    const priceModel = MessageCampaignPlanType.FIXED_VIEWABLE_IMPRESSION;
    const limitationModel = model.getLimitationModel(priceModel);
    model.setFormikProps(formikProps);
    const onTabSelect = (tab) => {
      if (tab === null) {
        return;
      }
      model.goSubStep(+tab);
    };
    const canGoNext = !basicFormModel?.state.validatingMessageContent;

    return (
      <>
        <Tabs
          id='campaignFromTab'
          activeKey={model.activeTab}
          onSelect={onTabSelect}
        >
          <Tab eventKey={CampaignSetupTab.BASIC} title={i18n.t<string>('stepSideBar.labels.basic')}>
            <CampaignBasicForm model={basicFormModel} />
          </Tab>
          <Tab eventKey={CampaignSetupTab.LIMITATION} title={i18n.t<string>('stepSideBar.labels.targeting')}>
            <EditLimitation model={limitationModel} order={order} />
          </Tab>
        </Tabs>
        <div className={styles.buttonArea}>
          {!canGoNext ?
            <OverlayTrigger
              placement='top'
              trigger={['hover', 'focus']}
              overlay={
                <Tooltip id={'go-next-tooltip'}>
                  {i18n.t<string>('messageCampaignSetupFlow.descriptions.validatingContent')}
                </Tooltip>
              }
            >
              <Button variant='primary' size='sm' onClick={model.goNext} disabled={true}>
                {i18n.t<string>('campaign.buttons.completeAndCheck')}
              </Button>
            </OverlayTrigger>
            :
            <Button variant='primary' size='sm' onClick={model.goNext}>
              {i18n.t<string>('campaign.buttons.completeAndCheck')}
            </Button>
          }
          {model.flowModel.type === 'create' && <Button variant='secondary' size='sm' onClick={model.goLast}>
            {i18n.t<string>('campaign.buttons.back')}
          </Button>}
        </div>
      </>
    );
  }

  render () {
    const model = this.props.model;
    const campaignData = model.flowModel.state.campaign;
    return (
      <div className={styles.setupStep}>
        <Formik
          initialValues={campaignData.basic}
          enableReinitialize
          onSubmit={this.handleSubmit}
          validate={this.validate}
          validateOnBlur={false}
        >
          {this.renderFormContent}
        </Formik>
      </div>
    );
  }
}
