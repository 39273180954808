import { useCallback } from 'react';
import { ImageMediaSummary } from './ImageMediaSummary';
import { VideoMediaSummary } from './VideoMediaSummary';
import styles from './customLayoutMediaSummary.module.scss';

type FileInfo = {
  file?: File,
  url?: string
};

type MediaSummaryData = {
  medias: {
    image: {[key: string]: FileInfo},
    video: {[key: string]: FileInfo}
  }
};

export const CustomLayoutMediaSummary: React.FC<{ mediaSummary: MediaSummaryData }> = ({
  mediaSummary
}) => {
  const medias = mediaSummary.medias;

  const renderImages = useCallback(() => {
    return medias.image && Object.keys(medias.image).map(name => {
      const image = medias.image[name];
      return (
        <ImageMediaSummary
          key={name}
          mediaSummary={{
            medias: {
              image
            }
          }}
        />
      );
    });
  }, [medias]);

  const renderVideos = useCallback(() => {
    return medias.video && Object.keys(medias.video).map(name => {
      const video = medias.video[name];
      return (
        <VideoMediaSummary
          key={name}
          mediaSummary={{
            fileVideo: video
          }}
        />
      );
    });
  }, [medias]);

  return (
    <div className={styles.customLayoutMediaSummary}>
      {renderImages()}
      {renderVideos()}
    </div>
  );
};
