import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType } from 'core/rtbCampaign/RtbCampaign';

export interface OneForAllDisplayFormModel extends RtbCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractOneForAllDisplayFormModel extends DefaultRtbCampaignBasicFormModel
  implements OneForAllDisplayFormModel {

  showVideoProgressRadio = false;

  get campaignAdType (): AdType {
    return AdType.ONE_FOR_ALL_DISPLAY;
  }

  getBidPriceFloorData (): any {
    const data = this.order.campaignBidPrice.find(data => data.type === AdType.ONE_FOR_ALL_DISPLAY);
    return data ? data.bidFloor : {};
  }
}

export class EditOneForAllDisplayFormModel extends AbstractOneForAllDisplayFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }

  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateOneForAllDisplayFormModel extends AbstractOneForAllDisplayFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
