import i18n from 'i18n';

const errorNeedAlert: number[] = [400010, 401010, 400013, 403010, 404010, 404012, 406010];
const hasI18n = [
  /* System/Service level */
  400000,
  401000,
  403000,
  404000,

  405000,
  408000,// 'Connection Time out',
  409000,// 'Conflict',

  500000,// 'Unknown Error',

  /* Role level 500 */
  401500,// 'Function not authorized',
  402500,// 'No actor in used or using invalid actor',

  /* Resource level 600 */
  400600,// 'Invalid operation',
  404600,// 'Resource not found',

  /* Account level 010 */
  400010,// 'Email already in use',
  400013,
  401010,// 'Account not login',
  403010,// 'Account cannot access the resource',
  404010,// 'Account not found',
  404012,// 'Token invalid',
  406010,// 'Account is disable',

  /* Company Level 020 */
  404020,// 'Company not found',

  /* Agency Level 030 */
  400030,
  404030,
  400031,

  /* Advertiser Level 040 */
  404040,

  /* AdsOrder Level 050 */
  400050,
  404050,
  409050,

  /* Campaign Level 060 */
  404060,
  400061,
  404062,

  /* Creative Level 070 */
  400070,
  400071,
  400072,
  400073,
  404070,
  400074,

  /* AdXCreativeReview Level 080 */
  400080,
  408080,

  /* Segment Level 090 */
  404090,
  404091,

  /* Common Level 100 */
  400100,

  /* Conversion Level 110 */
  404110,
  404111,

  /* Conversion Level 110 */
  404120,
  400120,

  500080,
  500081,

  /* 3th party 900 */
  401900,

  /* PVMax 910 */
  500910,
  /* IMax 920 */
  500920,
  500921,
  500922,
  500923,

  /* Edimax 930 */
  404930,
  401930,
  500930,

  /* Yahoo 950 */
  401950,
  /* Yahoo Gemini */
  500951,
  500952,
  /* Google API 960 */
  500960,
  500961,

  /* Azure  970 */
  500970,
  401970,
  /* Azure - mediaService 971 */
  500971,

  /* DMP 980 */
  500980,
  500981,
  500982,
  500983,
  500984,
  500985,
  500986,
  500987,

  /* FB */
  500941,
  5001000, // Get FB AD Account fail
  5001001, // Get FB Token fail
  5001002, // Create FB Campaign fail
  5001003, // Update FB Campaign fail
  5001004, // Create FB AdSet fail
  5001005, // Update FB AdSet fail
  5001006, // List FB Campaigns fail
  5001007, // List FB AdSets fail
  5001008, // List FB Ads fail
  5001009, // Update FB object status fail
  5001010, // Create FB Creative fail
  5001011, // Generate FB Preview fail
  5001012, // Create FB Ad fail
  5001013, // Update FB Ad fail
  5001014  // Get TA Description fail
];

export const getServerErrorMessageConfig = (
  errorCode: number,
  defaultMessage: string = i18n.t<string>('serverResponse.500000')
) => {

  return {
    message: errorCode !== 500000 && hasI18n.includes(errorCode) ?
      i18n.t<string>(`serverResponse.${errorCode}`) :
      defaultMessage,
    showAlert: errorNeedAlert.indexOf(errorCode) !== -1
  };
};
