import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { ConversionFormDTO, ConversionType } from 'core/conversion/Conversion';
import { ConversionManager, DefaultConversionManager } from 'core/conversion/ConversionManager';
import { toast } from 'react-toastify';
import { validateEmpty, validateStringMaxLength } from 'utils/ValidateUtils';
import { SelectOptions } from 'components/common/commonType';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import i18n from 'i18next';
import _ from 'lodash';

export type ConversionFormState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly selectedAdvertiser?: SelectOptions;
};
export interface ConversionFormModel {
  readonly state: ConversionFormState;
  readonly event: UpdateEventListener<ConversionFormModel>;
  readonly title: string;
  readonly conversion: ConversionFormDTO;
  readonly advertisers: Array<SelectOptions>;
  readonly conversionTypeOptions: Array<SelectOptions>;
  validate (conversion: ConversionFormDTO): any;
  submit (conversion: ConversionFormDTO): void;
  onUnmount (eventHandler): void;
}

export type ConversionFormProps = {
  readonly model: ConversionFormModel;
};

export const MAX_CONVERSION_NAME_LENGTH = 200;

abstract class DefaultConversionFormModel implements ConversionFormModel {
  event: FireableUpdateEventListener<ConversionFormModel>;
  loading: boolean;
  conversion: ConversionFormDTO;
  manager: ConversionManager;
  redirectPath?: string;
  advertisers: Array<SelectOptions>;
  conversionTypeOptions: Array<SelectOptions>;
  constructor (
    advertisers: Array<SelectOptions>,
    conversion: ConversionFormDTO,
    manager: ConversionManager = new DefaultConversionManager()
  ) {
    this.advertisers = advertisers;
    this.event = new FireableUpdateEventListener<ConversionFormModel>();
    this.loading = false;
    this.manager = manager;
    this.conversionTypeOptions = _.map(Object.values(ConversionType), value => {
      return {
        label: i18n.t<string>(`conversionType.labels.${_.camelCase(value)}`),
        value
      };
    });
    this.conversion = conversion;
  }

  abstract get title ();

  abstract submit (conversion: ConversionFormDTO): void;

  validate = (conversion: ConversionFormDTO) => {
    return _.omitBy(
      {
        advertiserId: validateEmpty(_.get(conversion, 'advertiserId')),
        name: validateStringMaxLength(_.get(conversion, 'name'), MAX_CONVERSION_NAME_LENGTH)
      }, (value) => value === undefined);
  }

  onUnmount = (eventHandler) => {
    this.event.remove(eventHandler);
    this.redirectPath = undefined;
  }

  get state (): ConversionFormState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath
    };
  }

  updateState (loading: boolean, redirectPath?: string) {
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this);
  }
}

export class CreateConversionFormModel extends DefaultConversionFormModel {

  constructor (
    advertisers: Array<SelectOptions>,
    manager: ConversionManager = new DefaultConversionManager()
  ) {
    super(advertisers, {
      name: '',
      advertiserId: SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER),
      type: ConversionType.PURCHASE
    }, manager);
  }

  async submit (newConversion: ConversionFormDTO) {
    this.updateState(true);
    try {
      await this.manager.createConversion(newConversion);
      this.updateState(false, '/conversions');
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  get title () {
    return i18n.t<string>('conversionForm.labels.createTitle');
  }
}

export class EditConversionFormModel extends DefaultConversionFormModel {

  constructor (
    advertisers: Array<SelectOptions>,
    conversion: ConversionFormDTO,
    manager: ConversionManager = new DefaultConversionManager()
  ) {
    super(advertisers, conversion, manager);
    const advertiserOption = this.advertisers.find(advertiserOption => !!conversion.advertiserId && advertiserOption.value.toString() === conversion.advertiserId.toString());
    this.conversion = advertiserOption ? {
      ...conversion,
      advertiserName: advertiserOption ? advertiserOption.label : ''
    } : conversion;
  }

  get title () {
    return i18n.t<string>('conversionForm.labels.editTitle');
  }

  async submit (editedConversion: ConversionFormDTO) {
    this.updateState(true);
    try {
      await this.manager.updateConversion(editedConversion);
      this.updateState(false, '/conversions');
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }
}
