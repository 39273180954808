import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { DefaultMessageSegmentManager, MessageSegmentManager } from 'core/messageSegment/MessageSegmentManager';
import { useCallAPI } from 'hooks/useCallAPI';
import i18n from 'i18n';
import { useState } from 'react';
import { MessageSegmentCount } from 'ws/MessageSegmentWebService';
import styles from './messageSegmentCountButton.module.scss';

const defaultMessageSegmentManager: MessageSegmentManager = new DefaultMessageSegmentManager();

export const MessageSegmentCountButton = ({
  audienceSummary
}: {
  audienceSummary: string
}) => {

  const { loading, callAPIs } = useCallAPI();
  const [segmentCount, setSegmentCount] = useState<MessageSegmentCount>();

  const getSegmentCountInfo = async () => {
    callAPIs([
      () => defaultMessageSegmentManager.getSegmentCountBySQL(audienceSummary)
    ], setSegmentCount);
  };

  return (
    <div className={styles.countEstimateArea}>
      {loading && <LoadingIndicator/>}
      <button
        type='button'
        className={`btn btn-secondary btn-sm ${styles.createBtn}`}
        onClick={getSegmentCountInfo}
      >
        {i18n.t<string>('messageSegmentForm.buttons.segmentCountBtn')}
      </button>
      {segmentCount &&
        <div className={styles.countHint}>
          {i18n.t<string>('messageSegmentForm.hints.gIdCount', { count: segmentCount.num_gids })} <br/>
          {i18n.t<string>('messageSegmentForm.hints.emailCount', { count: segmentCount.num_emails })} <br/>
          {i18n.t<string>('messageSegmentForm.hints.phoneCount', { count: segmentCount.num_phones })} <br/>
        </div>
      }
    </div>
  );
};
