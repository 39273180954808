import { SelectOptions } from 'components/common/commonType';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { SegmentListModel, DefaultSegmentListModel } from 'components/SegmentList/SegmentListModel';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { AgencyManager, DefaultAgencyManager } from 'core';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';

export interface SegmentHomeModel {
  readonly event: UpdateEventListener<SegmentHomeModel>;
  readonly agencyId?: number;
  readonly advertiserId?: number;
  readonly state: SegmentHomeState;
  init (): Promise<void>;
  getSegmentListModel (): SegmentListModel;
  setAdvertiserId (advertiserId?: number): void;
  setAgencyId? (agencyId?: number): void;
}

export type SegmentHomeProps = {
  readonly model: SegmentHomeModel
};

export type SegmentHomeState = {
  readonly loading: boolean;
  readonly agencyId?: number;
  readonly agencies: Array<SelectOptions>;
  readonly advertiserId?: number;
  readonly advertisers: Array<SelectOptions>;
};

export class DefaultSegmentHomeModel implements SegmentHomeModel {

  advertiserId?: number;
  advertisers: Array<SelectOptions>;
  agencies: Array<SelectOptions>;
  segmentListModel?: SegmentListModel;
  event: FireableUpdateEventListener<SegmentHomeModel>;

  constructor (advertisers: Array<SelectOptions>) {
    this.agencies = [];
    this.advertisers = advertisers;
    this.advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    this.event = new FireableUpdateEventListener<SegmentHomeModel>();
  }

  get state () {
    return {
      loading: false,
      agencies: this.agencies,
      advertiserId: this.advertiserId,
      advertisers: this.advertisers
    };
  }

  async init () {
    // This is intentional
  }

  getSegmentListModel (): SegmentListModel {
    if (this.segmentListModel) {
      return this.segmentListModel;
    }

    this.segmentListModel = new DefaultSegmentListModel(this.advertiserId);
    return this.segmentListModel;
  }

  setAdvertiserId (advertiserId: number) {
    this.advertiserId = advertiserId;
    this.segmentListModel && this.segmentListModel.setAdvertiserId(this.advertiserId);
    this.updateState();
  }

  updateState () {
    this.event.fireEvent(this);
  }
}

export class SysAdminSegmentHomeModel implements SegmentHomeModel {

  advertiserId?: number;
  advertisers: Array<SelectOptions>;
  agencyId?: number;
  agencies: Array<SelectOptions>;
  segmentListModel?: SegmentListModel;
  loading: boolean;
  event: FireableUpdateEventListener<SegmentHomeModel>;
  agencyManager: AgencyManager;
  advertiserManager: AdvertiserManager;

  constructor (
    agencyManager: AgencyManager = new DefaultAgencyManager(),
    advertiserManager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.loading = true;
    this.advertisers = [];
    this.agencies = [];
    this.agencyId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY);
    this.advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    this.event = new FireableUpdateEventListener<SegmentHomeModel>();
    this.agencyManager = agencyManager;
    this.advertiserManager = advertiserManager;
  }

  get state () {
    return {
      loading: this.loading,
      agencyId: this.agencyId,
      agencies: this.agencies,
      advertiserId: this.advertiserId,
      advertisers: this.advertisers
    };
  }

  async init () {
    this.updateState(true);
    try {
      this.agencies = await this.agencyManager.getAgenciesOptions();
      if (this.agencyId) {
        this.advertisers = await this.advertiserManager.getAdvertiserOptions(this.agencyId);
      }
    } catch (e) {}
    this.updateState(false);
  }

  async getAdvertisers (agencyId) {
    this.updateState(true);
    try {
      this.advertisers = await this.advertiserManager.getAdvertiserOptions(agencyId);
    } catch (e) {}
    this.updateState(false);
  }

  getSegmentListModel (): SegmentListModel {
    if (this.segmentListModel) {
      return this.segmentListModel;
    }

    this.segmentListModel = new DefaultSegmentListModel(this.advertiserId);
    return this.segmentListModel;
  }

  setAgencyId (agencyId: number) {
    this.agencyId = agencyId;
    this.advertiserId = undefined;
    SessionStorageHelper.removeItem(SessionStorageItemKeys.ADVERTISER);
    this.segmentListModel && this.segmentListModel.setAgencyId(this.agencyId);
    this.getAdvertisers(this.agencyId);
  }

  setAdvertiserId (advertiserId: number) {
    this.advertiserId = advertiserId;
    this.segmentListModel && this.segmentListModel.setAdvertiserId(this.advertiserId);
    this.updateState(false);
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
