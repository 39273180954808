import React from 'react';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { TiktokAdGroupListColumns, useTiktokAdGroupListModel } from './TiktokAdGroupListModel';
import i18n from 'i18n';
import styles from './tiktokAdGroupList.module.scss';
import { SearchBar } from 'components/common/SearchBar';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Link } from 'react-router-dom';
import { Modal } from 'components/common/Modal/Modal';
import { TiktokAdGroupListRecord } from 'core/tiktokAdGroup/TiktokAdGroup';
import { Order } from 'core/order/Order';
import cx from 'classnames/bind';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, hasFuncs, notSelfServeAdObject } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { L1Object } from 'core/l1Object/L1Object';
import Select from 'components/common/Select/Select';
import { ListEditBar } from 'components/ListEditBar/ListEditBar';
import { FilterMenuTab } from 'components/common/FilterMenuTab/FilterMenuTab';
import { DraftCreateModal } from 'containers/Draft/DraftCreateModal';
import _ from 'lodash';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
const classNames = cx.bind(styles);

export type TiktokAdGroupListProps = {
  order: Order;
  adGroups: TiktokAdGroupListRecord[];
  l1Object?: L1Object;
  refreshList: () => void;
};

export const TiktokAdGroupList: React.FC<TiktokAdGroupListProps> = ({
  order,
  adGroups,
  l1Object,
  refreshList
}) => {

  const {
    loading,
    columns,
    currentUrl,
    summaryData,
    filteredList,
    searchString,
    selectedAdGroups,
    selectedDrafts,
    selectedMetricsGroup,
    metricsGroupOptions,
    canNotCreateMessage,
    showDraftCreateModal,
    onMetricsGroupChange,
    rowClasses,
    onHandleSearch,
    deleteDraft,
    activeAdGroup,
    deactiveAdGroup,
    deleteAdGroups,
    bindCreatives,
    activateCreatives,
    deactivateCreatives,
    handleRemoveSelect,
    onDeleteDraftModalClose,
    showDeleteDraftConfirmModal,
    onShowDeleteDraftConfirmModal,
    hideDraftCreateModal,
    setShowDraftCreateModal,
    filterMenuTabConfigs,
    onDeleteModalClose,
    canDeleteSelectedAdGroups,
    showDeleteConfirmModal
  } = useTiktokAdGroupListModel(order, l1Object, adGroups, refreshList);

  const onShowDeleteConfirmModal = (event?: any) => {
    event && event.stopPropagation();
    showDeleteConfirmModal();
  };

  const handleShowDeleteDraftConfirmModal = (event?: any) => {
    event && event.stopPropagation();
    onShowDeleteDraftConfirmModal();
  };

  const renderEditBar = () => {
    const selectedObjects = [...selectedAdGroups, ...selectedDrafts];
    const handleNavEvent = (event) => {
      event && event.stopPropagation();
    };
    const showDeleteTip = !canDeleteSelectedAdGroups(selectedAdGroups);
    const deleteButtonContainerClassName = classNames('deleteButtonContainer', {
      block: showDeleteTip
    });
    return (
      <ListEditBar selectedItems={selectedObjects} handleRemoveSelect={handleRemoveSelect}>
        <PermissionChecker permissionAware={notSelfServeAdObject(l1Object)}>
          <div className={styles.dropdownContainer}>
            <ListEditBar.Dropdown
              title={i18n.t<string>('campaignList.labels.creative')}
              id='creativeManagement'
              onClick={handleNavEvent}
            >
              <ListEditBar.Dropdown.Item onClick={bindCreatives}>{i18n.t<string>('campaignList.labels.bindCreative')}</ListEditBar.Dropdown.Item>
              <ListEditBar.Dropdown.Item onClick={deactivateCreatives}>{i18n.t<string>('campaignList.labels.deactivateCreative')}</ListEditBar.Dropdown.Item>
              <ListEditBar.Dropdown.Item onClick={activateCreatives}>{i18n.t<string>('campaignList.labels.activeCreative')}</ListEditBar.Dropdown.Item>
            </ListEditBar.Dropdown>
          </div>
        </PermissionChecker>
        <ListEditBar.Item>
          <ListEditBar.Link onClick={activeAdGroup}>
            {i18n.t<string>('campaignList.labels.activeCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <ListEditBar.Item>
          <ListEditBar.Link onClick={deactiveAdGroup}>
            {i18n.t<string>('campaignList.labels.deactivateCampaign')}
          </ListEditBar.Link>
        </ListEditBar.Item>
        <PermissionChecker permissionAware={notSelfServeAdObject(l1Object)}>
          <ListEditBar.Item>
            <ListEditBar.Link onClick={_.partial(setShowDraftCreateModal, true)}>
              {i18n.t<string>('campaignList.labels.duplicate')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        </PermissionChecker>
        <div className={deleteButtonContainerClassName}>
          <div className={styles.tooltip}>
            {i18n.t<string>('campaignList.labels.cannotDelete')}
          </div>
          <ListEditBar.Item >
            <ListEditBar.Link onClick={onShowDeleteConfirmModal}>
              {i18n.t<string>('campaignList.labels.deleteCampaign')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        </div>
        <div className={styles.deleteDraftTab}>
          <ListEditBar.Item>
            <ListEditBar.Link disabled={!_.isEmpty(selectedAdGroups)} onClick={handleShowDeleteDraftConfirmModal}>
              <FontAwesomeIcon icon={faTrashAlt} />
              {i18n.t<string>('campaignList.labels.deleteDraft')}
            </ListEditBar.Link>
          </ListEditBar.Item>
        </div>
      </ListEditBar>
    );
  };

  const createContainerClassName = classNames('createAdGroup', 'tipContainer', {
    block: canNotCreateMessage !== ''
  });

  const renderDraftCreateModal = () => {
    if (!l1Object) {
      return <div/>;
    }
    const adGroupsToCreateDraft = adGroups
      .filter(adGroup =>
        (!adGroup.isDraft && selectedAdGroups.includes(adGroup.adgroup_id)) ||
        (adGroup.draftId && selectedDrafts.includes(adGroup.draftId.toString())))
      .map(adGroup => {
        return adGroup.isDraft ? {
          id: adGroup.draftId ? adGroup.draftId : 0,
          isDraft: true
        } : {
          id: adGroup.adgroup_id ? adGroup.adgroup_id : 0,
          isDraft: false
        };
      });
    return (
      <DraftCreateModal
        order={order}
        l1Object={l1Object}
        l2ObjectsToCreateDraft={adGroupsToCreateDraft}
        onClose={hideDraftCreateModal}
      />
    );
  };

  return (
    <div className={styles.tiktokAdGroupList}>
      {loading && <LoadingIndicator />}
      {deleteAdGroups &&
        <Modal
          title={i18n.t<string>('adGroup.deleteTitle')}
          secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: () => onDeleteModalClose(false) }}
          primaryButton={{ title: i18n.t<string>('common.buttons.delete'), callback: deleteAdGroups }}
          dismiss={onDeleteModalClose}
        >
          {i18n.t<string>('adGroup.deleteModalContent')}
        </Modal>
      }
      {showDeleteDraftConfirmModal &&
        <Modal
          title={i18n.t<string>('campaignList.labels.deleteDraftModalTitle')}
          secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: onDeleteDraftModalClose }}
          primaryButton={{ title: i18n.t<string>('campaignList.labels.deleteCampaign'), callback: deleteDraft }}
          dismiss={onDeleteDraftModalClose}
        >
          {_.map(i18n.t<string>('campaignList.labels.deleteDraftModalContent').split(/\r?\n/), (hint, index) => (
            <div key={index}>{hint}</div>
          ))}
        </Modal>
      }
      <div className={styles.titleBar}>
        {(selectedAdGroups.length > 0 || selectedDrafts.length > 0) ?
          renderEditBar() :
          <div className={styles.filterArea}>
            <PermissionChecker permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE).and(addOnEnabled(ADDONFEATURE.CHANNEL.TIKTOK)).and(notSelfServeAdObject(l1Object))}>
              <div className={createContainerClassName}>
                {canNotCreateMessage &&
                  <span className={styles.tooltip}>
                    {canNotCreateMessage}
                  </span>
                }
                <Link
                  to={`${currentUrl}/campaigns/new`}
                  className={`btn btn-tertiary btn-sm ${styles.createBtn}`}
                >
                  {i18n.t<string>('tiktokAdGroupList.buttons.create')}
                </Link>
              </div>
            </PermissionChecker>
            <FilterMenuTab
              filterMenuTabConfigs={filterMenuTabConfigs}
            />
            <SearchBar
              model={{
                placeholder: i18n.t<string>('tiktokAdGroupList.placeholders.searchbar'),
                search: onHandleSearch,
                defaultValue: searchString
              }}
            />
          </div>
        }
      </div>
      <div className={styles.contentArea}>
        <div className={styles.metricsOptionsContainer}>
          <Select
            className={styles.metricsOptions}
            options={metricsGroupOptions}
            name='metricsGroupOptions'
            simpleValue
            value={selectedMetricsGroup}
            onChange={onMetricsGroupChange}
          />
        </div>
        <StickableBootstrapTable
          stickFirstColumn
          stickLastColumn
          key={selectedMetricsGroup}
          keyField={TiktokAdGroupListColumns.LISTID}
          data={filteredList}
          columns={columns}
          rowClasses={rowClasses}
          summaryData={summaryData}
          hidePagination={true}
          noDataIndication={i18n.t<string>('tiktokAdGroupList.labels.noData')}
          defaultSorted={[{
            dataField: TiktokAdGroupListColumns.LISTID,
            order: 'desc'
          }]}
        />
      </div>
      {showDraftCreateModal &&
        renderDraftCreateModal()
      }
    </div>
  );
};
