import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { Button } from 'react-bootstrap';
import styles from './multiSelectCheckbox.module.scss';
import { SelectOptions } from '../commonType';
import i18n from 'i18n';
import cx from 'classnames/bind';

export type MultiSelectCheckboxProps = {
  title?: string;
  options: SelectOptions[];
  values?: (string | number)[];
  className?: string;
  onApply: (values: (string | number)[]) => void;
  onClose: () => void;
};

export const MultiSelectCheckbox: React.FC<MultiSelectCheckboxProps> = (props) => {

  const [values, setValues] = useState(props.values ? props.values : []);
  const classNames = cx.bind(styles);

  useEffect(() => {
    setValues(props.values ? props.values : []);
  }, [props.values]);

  const onSelect = (value) => {
    if (values.includes(value)) {
      setValues(prevValues =>
        _.filter(prevValues, prevValue => prevValue !== value)
      );
    } else {
      setValues(prevValues => [...prevValues, value]);
    }
  };

  const onApply = () => {
    props.onApply(values);
  };

  const renderCheckItems = () => {
    return props.options.map(option => (
      <li key={option.value}>
        <input
          type='checkbox'
          checked={values.includes(option.value)}
          onChange={_.partial(onSelect, option.value)}
          id={`input${option.value}`}
        />
        <label htmlFor={`input${option.value}`}>{option.label}</label>
      </li>
    ));
  };

  const multiSelectCheckboxClassName = classNames('multiSelectCheckbox', props.className);
  return (
    <div className={multiSelectCheckboxClassName}>
      <div>
        {props.title &&
          <div className={styles.title}>
            {props.title}
          </div>
        }
        <ul>
          {renderCheckItems()}
        </ul>
      </div>
      <div className={styles.buttonArea}>
        <Button className={styles.miniButton} size='sm' onClick={onApply}>
          {i18n.t<string>('common.buttons.apply')}
        </Button>
        <Button className={styles.miniButton} variant='secondary' size='sm' onClick={props.onClose}>
          {i18n.t<string>('common.buttons.cancel')}
        </Button>
      </div>
    </div>
  );
};
