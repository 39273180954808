import {
  RdpWebService,
  RestfulRdpWebService
} from 'ws/RdpWebService';
import { Retail, Product, ProductSet } from 'core/product/Product';
import { generateI18nOfSelectOptions } from 'utils/I18nUtils';
import _ from 'lodash';
import { Pageable } from 'ws/Pageable';
import { ProductSegment, ProductSegmentRecord, PRODUCT_SEGMENT_OPERATOR } from 'core/product/ProductSegment';
import { Pagination } from 'core/pagination/Pagination';

export interface RdpManager {
  getRetails (): Promise<Retail[]>;
  getRetailProducts (retailId: number | string, queries?: any): Promise<Product[]>;
  getAllProducts (queries?: any): Promise<Product[]>;
  getRetailProductSets (
    retailId: string,
    advertiserId: number | string
  ): Promise<ProductSet[]>;
  getAllRetailProductSets (
    agencyId: number | string,
    advertiserId?: number | string
  ): Promise<ProductSet[]>;
  getProductSetDetail (retailId: number | string, productSetId: number | string): Promise<ProductSet>;
  createProductSet (retailId: number | string, productSetFormData): Promise<ProductSet>;
  updateProductSetAdd (retailId: number | string, productSetFormData): Promise<ProductSet>;
  updateProductSetRemove (retailId: number | string, productSetFormData): Promise<ProductSet>;
  getProductSegment (retailId: number | string, segmentId: string): Promise<ProductSegment>;
  getProductSegments (
    agencyId: number | string,
    retailerId: number | string,
    pageable: Pageable,
    searchString: string,
    showActive: boolean,
    adveritserId?: number | string
  ): Promise<{
    productSegments: ProductSegmentRecord[],
    pagination: Pagination
  }>;
  createProductSegment (agencyId: number | string, retailId: string, productSegmentFormData): Promise<void>;
  updateProductSegment (agencyId: number | string, retailId: string, segmentId: string, productSegmentFormData): Promise<void>;
  deleteProductSegment (retailId: string, segmentId: string): Promise<void>;
  getProductSetUsageInfo (productSetId: number | string): Promise<{
    id: string,
    name: string,
    creatives: {
      id: string,
      name: string
    }[]
  }[]>;
}

export class DefaultRdpManager implements RdpManager {
  webService: RdpWebService;

  constructor (webService: RdpWebService = new RestfulRdpWebService()) {
    this.webService = webService;
  }

  async getRetails (): Promise<Retail[]> {
    const retails = await this.webService.getRetails();
    return retails.map(retail => {
      const result = {
        id: retail.id,
        name: retail.name
      };
      generateI18nOfSelectOptions(({
        label: result.name,
        value: result.id
      }), 'retailers');
      return result;
    });
  }

  async getRetailProducts (retailId, queries: any = {}): Promise<Product[]> {
    return this.webService.getRetailProducts(retailId, queries);
  }

  async getAllProducts (queries: any = {}): Promise<Product[]> {
    return this.webService.getAllProducts(queries);
  }

  async getRetailProductSets (
    retailId: string,
    advertiserId: number | string
  ): Promise<ProductSet[]> {
    const retailProductSets = await this.webService.getRetailProductSets(retailId, advertiserId);
    retailProductSets.forEach(productSet => generateI18nOfSelectOptions({
      label: productSet.name,
      value: productSet.id
    }, 'retail.productSets'));
    return retailProductSets;
  }

  async getAllRetailProductSets (
    agencyId: number | string,
    advertiserId?: number | string
  ): Promise<ProductSet[]> {
    return this.webService.getAllRetailProductSets(agencyId, advertiserId);
  }

  async getProductSetDetail (retailId: number | string, productSetId: number | string): Promise<ProductSet> {
    return this.webService.getProductSetDetail(retailId, productSetId);
  }

  async createProductSet (retailId: number | string, productSetFormData): Promise<ProductSet> {
    return this.webService.createProductSet(retailId, productSetFormData);
  }

  async updateProductSetAdd (retailId: number | string, productSetFormData: any): Promise<ProductSet> {
    return this.webService.updateProductSetAdd(retailId, productSetFormData);
  }

  async updateProductSetRemove (retailId: number | string, productSetFormData: any): Promise<ProductSet> {
    return this.webService.updateProductSetRemove(retailId, productSetFormData);
  }

  async getProductSegment (retailId: string, segmentId: string): Promise<ProductSegment> {
    return this.webService.getProductSegment(retailId, segmentId);
  }

  async getProductSegments (
    agencyId: number | string,
    retailerId: number | string,
    pageable: Pageable,
    searchString: string,
    showActive: boolean,
    adveritserId?: number | string
  ): Promise<{
    productSegments: ProductSegmentRecord[],
    pagination: Pagination
  }> {
    return this.webService.getProductSegments(agencyId, retailerId, pageable, searchString, showActive, adveritserId);
  }

  async createProductSegment (agencyId: number | string, retailId: string, productSegmentFormData: any): Promise<void> {
    const productSegmentPayload = this.wrapProductSegmentForServer(productSegmentFormData);

    return this.webService.createProductSegment(retailId, {
      agency: agencyId,
      ...productSegmentPayload
    });
  }

  async updateProductSegment (agencyId: number | string, retailId: string, segmentId: string, productSegmentFormData) {
    const productSegmentPayload = this.wrapProductSegmentForServer(productSegmentFormData);

    return this.webService.updateProductSegment(retailId, segmentId, {
      agency: agencyId,
      segmentId,
      ...productSegmentPayload
    });
  }

  async deleteProductSegment (retailId: string, segmentId: string): Promise<void> {
    return this.webService.deleteProductSegment(retailId, segmentId);
  }

  wrapProductSegmentForServer (productSegmentFormData: any) {
    return {
      name: productSegmentFormData.segmentName,
      advertiser: productSegmentFormData.advertiser,
      combination: {
        inclusion: {
          operator: productSegmentFormData.operator,
          rules: productSegmentFormData.inclusion.rules.map(eventRule => ({
            ..._.omit(eventRule, ['ruleId']),
            filterSet: eventRule.filterSet ? eventRule.filterSet : null,
            aggregation: eventRule.aggregation ? eventRule.aggregation : null
          }))
        },
        exclusion: productSegmentFormData.exclusion ? {
          operator: PRODUCT_SEGMENT_OPERATOR.AND,
          rules: productSegmentFormData.exclusion.rules.map(eventRule => ({
            ..._.omit(eventRule, ['ruleId']),
            filterSet: eventRule.filterSet ? eventRule.filterSet : null,
            aggregation: eventRule.aggregation ? eventRule.aggregation : null
          }))
        } : undefined
      }
    };
  }
  async getProductSetUsageInfo (productSetId: number | string): Promise<{
    id: string,
    name: string,
    creatives: {
      id: string,
      name: string
    }[]
  }[]> {
    const usageList = await this.webService.getProductSetUsageInfo(productSetId);
    const usageData = usageList.reduce((acc, usageInfo) => {
      const camapignDetail = acc[usageInfo.campaign.id] ? acc[usageInfo.campaign.id] : {
        ...usageInfo.campaign,
        creatives: []
      };
      camapignDetail.creatives.push(usageInfo.creative);
      acc[usageInfo.campaign.id] = camapignDetail;
      return acc;
    }, {});
    return Object.values(usageData);
  }
}
