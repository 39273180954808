import React, { useMemo } from 'react';
import { Slider } from 'components/common/Slider/Slider';
import { DefaultSliderModel } from 'components/common/Slider/SliderModel';
import _ from 'lodash';
import styles from './nativeCreativeDetail.module.scss';
import { CreativeListRecord } from 'core/creative/Creative';
import { useFBPreview } from 'hooks/GoCreativePreview/useFBPreview';
import { useTiktokVideoPreview } from 'hooks/GoCreativePreview/useTiktokVideoPreview';
import { renderFBPreviews, renderTiktokPreviews } from 'components/AdPreview/adPreviewRenderer';

export const OneForAllVideoCreativeDetail: React.FC<{ creative: CreativeListRecord }> = ({
  creative
}) => {

  const previewAreaWidth = 600;
  const previewAreaHeight = 680;
  const typeProperties = useMemo(() => ({
    title: _.get(creative, 'bannerExtra.title'),
    description: _.get(creative, 'bannerExtra.description'),
    linkUrl: _.get(creative, 'bannerExtra.fbLandingUrl'),
    pageId: _.get(creative, 'bannerExtra.pageId'),
    callToAction: _.get(creative, 'bannerExtra.callToAction'),
    videoId: _.get(creative, 'bannerExtra.videoId'),
    thumbnailUrl: _.get(creative, 'bannerExtra.thumbnailUrl'),
    storyVideoId: _.get(creative, 'bannerExtra.storyVideoId'),
    storyThumbnailUrl: _.get(creative, 'bannerExtra.storyThumbnailUrl'),
    tiktokVideoId: _.get(creative, 'bannerExtra.tiktokVideoId'),
    shortMessage: _.get(creative, 'bannerExtra.shortMessage')
  }), [creative]);

  const previewProps = useMemo(() => ({
    type: 'VIDEO',
    advertiserId: creative.advertiserId.toString()
  }), [creative.advertiserId]);

  const {
    previewData: fbPreviewData
  } = useFBPreview(
    typeProperties,
    previewProps
  );
  const {
    previewData: tiktokPreviewData
  } = useTiktokVideoPreview(
    creative.advertiserId,
    typeProperties
  );

  return (
    <div className={styles.nativeCreativeDetail}>
      <Slider model={new DefaultSliderModel(previewAreaWidth, previewAreaHeight, true, false, 0, false)}>
        {renderFBPreviews(fbPreviewData)}
        {renderTiktokPreviews(tiktokPreviewData)}
      </Slider>
    </div>
  );
};
