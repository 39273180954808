import { Pagination } from 'core/pagination/Pagination';
import { FieldType } from 'components/common/form/field/FieldConfig';

export type CustomLayout = {
  id: number;
  name: string;
  layoutId: string;
  previewUrl: string;
  device: CustomLayoutDeviceType;
  type: CustomLayoutType;
  macros: {[macroName: string]: Macro};
  status: CustomLayoutStatus;
};

export type Macro = {
  name: string;
  description?: string;
  type: FieldType;
  limits?: {
    fileSize?: string;
    fileRatio?: string;
    fileStorage?: number;
    fileType?: string[];
    videoDuration?: number;
    textMinLength?: number;
    textMaxLength?: number;
  }
};

export enum CustomLayoutStatus {
  ACTIVE = 1,
  DELETED = 99
}

export type CustomLayoutFormType = Omit<CustomLayout, 'id' | 'status'>;

export type CustomLayoutWithPagination = {
  pagination: Pagination;
  customLayouts: CustomLayout[];
};

export enum CustomLayoutType {
  CUSTOM_RECTANGLE = 'custom-rectangle-layout',
  CUSTOM_BOTTOM = 'custom-bottom-layout'
}

export enum CustomLayoutDeviceType {
  DESKTOP = 'desktop',
  MOBILE = 'mobile'
}
