import { StoredValueLog } from 'core/storedValue/StoredValueLog';
import client from './RestClient';
import { AxiosInstance } from 'axios';

export interface StoredValueWebService {
  getStoredValueLogs (): Promise<StoredValueLog[]>;
  getStoredValueLogsByAgencyId (agencyId: number): Promise<StoredValueLog[]>;
  editStoredValue (agencyId: number, amount: number, description: string, comment?: string): Promise<void>;
}

export class RestfulStoredValueWebService implements StoredValueWebService {

  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getStoredValueLogs (): Promise<StoredValueLog[]> {
    const response = await this.restClient.get('/stored-value');
    return response.data.records;
  }

  async getStoredValueLogsByAgencyId (agencyId: number): Promise<StoredValueLog[]> {
    const response = await this.restClient.get(`/stored-value/${agencyId}`);
    return response.data.records;
  }

  async editStoredValue (adAgencyId: number, amount: number, description: string, comment: string = ''): Promise<void> {
    return this.restClient.post(`/stored-value`, {
      amount,
      comment,
      description,
      adAgencyId
    });
  }
}
