export enum MessageCampaignListColumns {
  NAME = 'nameColumn',
  STATE = 'stateColumn',
  OPTIMIZE = 'optimize',
  DELIVERY = 'deliveryColumn',
  CREATIVE = 'creativeColumn',
  SCHEDULE = 'scheduleColumn',
  PROGRESS = 'progressColumn',
  BUDGET = 'budgetColumn',
  PRICE = 'priceColumn',
  LIMITATION = 'limitationColumn',
  RESULTS = 'resultsColumn',
  IMPRES = 'impresColumn',
  VIEWABLE = 'viewableColumn',
  CLICKS = 'clicksColumn',
  CPC = 'cpcColumn',
  CTR = 'ctrColumn',
  VCTR = 'vctrColumn',
  CONVERT = 'convertColumn',
  CPA = 'cpaColumn',
  CVR = 'cvrColumn',
  VIEW = 'viewColumn',
  VIEWRATE = 'viewRateColumn',
  VIEWABLE_VIEWRATE = 'viewableViewRateColumn',
  TAGS = 'tagsColumn',
  SPENT = 'spents',
  EDITBTNS = 'editBtnsColumn'
}

export const basicColumns = [
  MessageCampaignListColumns.NAME,
  MessageCampaignListColumns.STATE,
  MessageCampaignListColumns.DELIVERY,
  MessageCampaignListColumns.CREATIVE,
  MessageCampaignListColumns.SCHEDULE,
  MessageCampaignListColumns.PROGRESS,
  MessageCampaignListColumns.BUDGET,
  MessageCampaignListColumns.PRICE,
  MessageCampaignListColumns.OPTIMIZE,
  MessageCampaignListColumns.LIMITATION,
  MessageCampaignListColumns.TAGS,
  MessageCampaignListColumns.EDITBTNS
];

export const performanceColumns = [
  MessageCampaignListColumns.NAME,
  MessageCampaignListColumns.PROGRESS,
  MessageCampaignListColumns.BUDGET,
  MessageCampaignListColumns.LIMITATION,
  MessageCampaignListColumns.IMPRES,
  MessageCampaignListColumns.CLICKS,
  MessageCampaignListColumns.CTR,
  MessageCampaignListColumns.SPENT,
  MessageCampaignListColumns.TAGS,
  MessageCampaignListColumns.EDITBTNS
];

export const performanceColumnsWithViewable = [
  MessageCampaignListColumns.NAME,
  MessageCampaignListColumns.PROGRESS,
  MessageCampaignListColumns.BUDGET,
  MessageCampaignListColumns.LIMITATION,
  MessageCampaignListColumns.IMPRES,
  MessageCampaignListColumns.VIEWABLE,
  MessageCampaignListColumns.CLICKS,
  MessageCampaignListColumns.CTR,
  MessageCampaignListColumns.VCTR,
  MessageCampaignListColumns.SPENT,
  MessageCampaignListColumns.TAGS,
  MessageCampaignListColumns.EDITBTNS
];
