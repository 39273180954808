import { SelectOptions } from 'components/common/commonType';
import { Fragment, useCallback } from 'react';
import styles from './labelValueColumn.module.scss';

export const LabelValueColumn: React.FC<{ options: SelectOptions[]}> = ({
  options
}) => {

  const renderContent = useCallback(() => (
    options.map((option) => (
      <Fragment key={option.label}>
        <div>{option.label}</div>
        <div style={{ whiteSpace: 'pre' }}>{` : `}</div>
        <div>{option.value}</div>
      </Fragment>
    ))
  ), [options]);

  return (
    <div className={styles.labelValueColumn}>
      {renderContent()}
    </div>
  );
};
