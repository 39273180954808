import { StoredValueLog } from 'core/storedValue/StoredValueLog';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import styles from './storedValueDetail.module.scss';

export const priceFormatter = (value: number, record: StoredValueLog) => {
  if (value < 0) {
    return (
      <span className={styles.danger}>
        {formatPriceWithCurrency(record.currency, value)}
      </span>
    );
  }
  return formatPriceWithCurrency(record.currency, value);
};
