import React from 'react';
import { Trans } from 'react-i18next';

export const renderAdSetTotalDaysDes = (days, hours) => {
  if (days > 1) {
    return (
      <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
        Total <span className='text-dark'><>{{ days }} days</></span>
      </Trans>
    );
  } else if (days === 1) {
    return (
      <Trans i18nKey='campaign.descriptions.campaignSchedulingHour'>
        Total <span className='text-dark'><>{{ hours }} hours</></span>
      </Trans>
    );
  }
};
