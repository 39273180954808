import { ReportType } from 'core/report/ReportData';
import { VideoReportDataProvider } from './VideoReportDataProvider';
import { ReportDataProvider } from './ReportDataProvider';
import { RebateReportDataProvider } from './RebateReportDataProvider';
import { GeneralReportDataProvider } from './GeneralReportDataProvider';
import { MessageReportDataProvider } from './MessageReportDataProvider';

export function getDataProvider (reportType: ReportType, onDimensionNameClick, onDateClick, onEditClick): ReportDataProvider {
  if (reportType === ReportType.REBATE) {
    return new RebateReportDataProvider(onDimensionNameClick, onDateClick, onEditClick);
  }
  if (reportType === ReportType.MESSAGE) {
    return new MessageReportDataProvider(onDimensionNameClick, onDateClick, onEditClick);
  }
  return reportType === ReportType.PERFORMANCE ?
    new GeneralReportDataProvider(onDimensionNameClick, onDateClick, onEditClick) :
    new VideoReportDataProvider(onDimensionNameClick, onDateClick, onEditClick);
}
