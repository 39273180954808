import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { TiktokAdGroup, TiktokAdGroupListRecord } from 'core/tiktokAdGroup/TiktokAdGroup';
import { TiktokAdGroupManager, DefaultTiktokAdGroupManager } from 'core/tiktokAdGroup/TiktokAdGroupManager';
import { DraftManager, TiktokAdGroupDraftManager } from 'core/draft/DraftManager';
import { useEffect, useMemo, useState, useCallback } from 'react';
import styles from './tiktokAdGroupList.module.scss';
import { useRouteMatch, useHistory, useLocation } from 'react-router-dom';
import formatters, { getAdGroupEffectiveStatusDesData, getAdGroupOptStatusDesData } from './listFormatters';
import _ from 'lodash';
import { CampaignState } from 'core/rtbCampaign/RtbCampaign';
import i18n from 'i18n';
import { Order, State } from 'core/order/Order';
import moment from 'moment';
import { L1Object } from 'core/l1Object/L1Object';
import { useCallAPI } from 'hooks/useCallAPI';
import { numberWithCommas } from 'utils/StringUtil';
import { formatPrice, getPriceValue } from 'helper/CurrencyHelper';
import {
  getCpc,
  getCtr,
  getViewRate,
  getDivideValue,
  getPercentValue,
  metricsAdder,
  metricsFormatter
} from 'helper/MetricsHelper';
import { FilterMenuTabConfig } from 'components/common/FilterMenuTab/FilterMenuTab';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { SelectOptions } from 'components/common/commonType';

export enum TiktokAdGroupListColumns {
  LISTID = 'listId',
  ID = 'adgroup_id',
  BUDGET = 'budget',
  STATE = 'state',
  DELIVERY = 'delivery',
  CREATIVE = 'enableCount',
  OPTIMIZATION = 'optimize_goal',
  SCHEDULING = 'schedule_start_time',
  TARGETING = 'targeting',
  RESULTS = 'result',
  IMPRES = 'impressions',
  CLICKS = 'clicks',
  CPC = 'cpc',
  CTR = 'ctr',
  UU = 'lifetimeReach',
  SPENT = 'spend',
  VIDEO_VIEW_RATE = 'videoViewRate',
  TIKTOK_VIDEO_PLAY_ACTIONS = 'videoPlayActions',
  TIKTOK_VIDEO_WATCHED_2S = 'videoWatched2s',
  TIKTOK_VIDEO_WATCHED_6S = 'videoWatched6s',
  TIKTOK_VIDEO_VIEWS_P25 = 'videoViewsP25',
  TIKTOK_VIDEO_VIEWS_P50 = 'videoViewsP50',
  TIKTOK_VIDEO_VIEWS_P75 = 'videoViewsP75',
  TIKTOK_VIDEO_VIEWS_P100 = 'videoViewsP100',
  TIKTOK_PROFILE_VISITS = 'profileVisits',
  TIKTOK_LIKES = 'likes',
  TIKTOK_COMMENTS = 'comments',
  TIKTOK_SHARES = 'shares',
  TIKTOK_FOLLOWS = 'follows',
  TIKTOK_CLICKS_ON_MUSIC_DISC = 'clicksOnMusicDisc',
  EDITBTNS = 'editBtns'
}

export enum TiktokAdGroupListType {
  BASIC = 'basic',
  PERFORMANCE = 'performance',
  VIDEO_PLAY = 'tiktok_video_play',
  ENGAGEMENT = 'tiktok_engagement'
}

export type TiktokAdGroupListState = {
  readonly loading: boolean;
  readonly rate: string;
  readonly currentCurrency: string;
  readonly currencyRates: { [key: string]: TiktokAdGroup[] };
};

const defaultTiktokAdGroupManager: TiktokAdGroupManager = new DefaultTiktokAdGroupManager();
const defaultDraftManager: DraftManager = new TiktokAdGroupDraftManager();
const defaultAdRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager();

export const useTiktokAdGroupListModel = (
  order: Order,
  l1Object: L1Object | undefined,
  tiktokAdGroups: TiktokAdGroupListRecord[],
  refreshList: () => void,
  tiktokAdGroupManager: TiktokAdGroupManager = defaultTiktokAdGroupManager,
  tiktokAdGroupDraftManager: DraftManager = defaultDraftManager,
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager
) => {

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const defaultSearchString = searchParams.get('search');
  const { loading, callAPIs } = useCallAPI();
  const [filteredList, setFilteredList] = useState<TiktokAdGroup[]>([]);
  const [searchString, setSearchString] = useState<string>(_.defaultTo(defaultSearchString, ''));
  const [selectedAdGroups, setSelectedAdGroups] = useState<(number | string)[]>([]);
  const [selectedDrafts, setSelectedDrafts] = useState<(number | string)[]>([]);
  const [selectedMetricsGroup, setSelectedMetricsGroup] = useState<TiktokAdGroupListType>(TiktokAdGroupListType.BASIC);
  const [adGroupToDelete, setAdGroupToDelete] = useState<(number | string)[] | undefined>();
  const [deleteDraftIds, setDeleteDraftIds] = useState<(number | string)[]>([]);
  const [showDeleteDraftConfirmModal, setShowDeleteDraftConfirmModal] = useState<boolean>(false);
  const [summaryData, setSummaryData] = useState<any>();
  const [showDraftCreateModal, setShowDraftCreateModal] = useState<boolean>(false);
  const [objectTypes, setObjectTypes] = useState<string[]>([]);
  const [selectedObjectTypeFilter, setSelectedObjectTypeFilter] = useState<string[]>([]);
  const [adGroupsDeliveryStatus, setAdGroupsDeliveryStatus] = useState<string[]>([]);
  const [adGroupsOptStatus, setAdGroupsOptStatus] = useState<string[]>([]);
  const [selectedDeliveryStatusFilter, setSelectedDeliveryStatusFilter] = useState<string[]>([]);
  const [selectedOptStatusFilter, setSelectedOptStatusFilter] = useState<string[]>([]);
  const [tiktokCountries, setTiktokCountries] = useState<SelectOptions[] | undefined>(undefined);
  const match = useRouteMatch();
  const history = useHistory();

  const onHandleSearch = (searchString: string) => setSearchString(searchString);

  useEffect(() => {
    const initTiktokCountriesI18n = async () => {
      callAPIs([() => adRequestSourceManager.getTiktokCountries()], tiktokCountries => setTiktokCountries(tiktokCountries));
    };
    initTiktokCountriesI18n();
  }, [adRequestSourceManager, callAPIs]);

  const tiktokAdGroupWithPerformanceList = useMemo(() => {
    const list = tiktokAdGroups.map(tiktokAdGroup => {
      const isDraft: boolean = _.get(tiktokAdGroup, 'isDraft', false);
      const report = _.get(tiktokAdGroup, 'report');
      const listId = isDraft ? `${tiktokAdGroup.adgroup_id}_draft_${tiktokAdGroup.draftId}` : tiktokAdGroup.adgroup_id;
      const delivery = getAdGroupEffectiveStatusDesData(tiktokAdGroup);
      const bidType = _.defaultTo(tiktokAdGroup.bid_type, _.get(l1Object, 'tiktok.bid_type'));
      if (report) {
        return {
          ..._.omit(tiktokAdGroup, ['report']),
          ...report,
          state: getAdGroupOptStatusDesData(tiktokAdGroup),
          delivery,
          listId,
          bid_type: bidType,
          budget: _.defaultTo(tiktokAdGroup.budget, -1),
          [TiktokAdGroupListColumns.TARGETING]: getTAToShow(tiktokAdGroup.targeting, tiktokCountries),
          [TiktokAdGroupListColumns.CPC]: getPriceValue(order.currency, getCpc(_.get(report, 'spend', 0), report.clicks)),
          [TiktokAdGroupListColumns.CTR]: getCtr(report.impressions, report.clicks),
          [TiktokAdGroupListColumns.VIDEO_VIEW_RATE]: getViewRate(report.videoPlayActions, report.impressions)
        };
      }
      return {
        ...tiktokAdGroup,
        state: getAdGroupOptStatusDesData(tiktokAdGroup),
        delivery,
        listId,
        bid_type: bidType,
        budget: _.defaultTo(tiktokAdGroup.budget, -1),
        [TiktokAdGroupListColumns.TARGETING]: getTAToShow(tiktokAdGroup.targeting, tiktokCountries),
        [TiktokAdGroupListColumns.CPC]: getPriceValue(order.currency, 0),
        [TiktokAdGroupListColumns.CTR]: 0,
        [TiktokAdGroupListColumns.SPENT]: 0,
        [TiktokAdGroupListColumns.VIDEO_VIEW_RATE]: 0
      };
    });
    setAdGroupsDeliveryStatus(_.uniq(_.compact(list.map(adGroup => {
      return _.get(adGroup, 'delivery.label', '');
    }))));
    setAdGroupsOptStatus(_.uniq(list.map(adGroup => {
      return adGroup.state.label;
    })));
    return list;
  }, [order.currency, tiktokAdGroups, l1Object, tiktokCountries]);

  useEffect(() => {
    const filteredList = tiktokAdGroupWithPerformanceList.filter(tiktokAdGroup => {
      return tiktokAdGroupListFilter(
        tiktokAdGroup,
        searchString,
        selectedDeliveryStatusFilter,
        selectedOptStatusFilter,
        selectedObjectTypeFilter
      );
    });
    setFilteredList(filteredList);

    const objectTypes = _.uniq<string>(
      tiktokAdGroupWithPerformanceList.map(getObjectTypeDesc)
    );
    setObjectTypes(objectTypes);

    const clickSum = filteredList.reduce<number>((partial, adgroup) => metricsAdder(partial, adgroup.clicks), 0);
    const spentSum = filteredList.reduce<number>((partial, adgroup) => metricsAdder(partial, getPriceValue(order.currency, adgroup.spend)), 0);
    const impresSum = filteredList.reduce<number>((partial, adgroup) => metricsAdder(partial, adgroup.impressions), 0);
    const videoViewsSum = filteredList.reduce<number>((partial, adgroup) => metricsAdder(partial, +adgroup[TiktokAdGroupListColumns.TIKTOK_VIDEO_PLAY_ACTIONS]), 0);

    const videoPlayColumns = [
      TiktokAdGroupListColumns.TIKTOK_VIDEO_WATCHED_2S,
      TiktokAdGroupListColumns.TIKTOK_VIDEO_WATCHED_6S,
      TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P25,
      TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P50,
      TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P75,
      TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P100
    ];
    const engagementColumns = [
      TiktokAdGroupListColumns.TIKTOK_PROFILE_VISITS,
      TiktokAdGroupListColumns.TIKTOK_LIKES,
      TiktokAdGroupListColumns.TIKTOK_COMMENTS,
      TiktokAdGroupListColumns.TIKTOK_SHARES,
      TiktokAdGroupListColumns.TIKTOK_FOLLOWS,
      TiktokAdGroupListColumns.TIKTOK_CLICKS_ON_MUSIC_DISC
    ];
    const otherSummaryData = [...videoPlayColumns, ...engagementColumns].reduce((acc, column) => {
      const sum = filteredList.reduce<number>((partial, tiktokAdGroup) => metricsAdder(partial, +tiktokAdGroup[column]), 0);
      acc[column] = numberWithCommas(sum);
      return acc;
    }, {});

    const summaryData = {
      [TiktokAdGroupListColumns.LISTID]: i18n.t<string>('l1ObjectList.labels.l1ObjectCount', { count: filteredList.length }),
      [TiktokAdGroupListColumns.IMPRES]: numberWithCommas(impresSum),
      [TiktokAdGroupListColumns.CLICKS]: numberWithCommas(clickSum),
      [TiktokAdGroupListColumns.CPC]: formatPrice(order.currency, getDivideValue(spentSum, clickSum)),
      [TiktokAdGroupListColumns.CTR]: getPercentValue(clickSum, impresSum),
      [TiktokAdGroupListColumns.SPENT]: numberWithCommas(spentSum),
      [TiktokAdGroupListColumns.VIDEO_VIEW_RATE]: getPercentValue(videoViewsSum, impresSum),
      [TiktokAdGroupListColumns.TIKTOK_VIDEO_PLAY_ACTIONS]: numberWithCommas(videoViewsSum),
      ...otherSummaryData
    };
    setSummaryData(summaryData);
  }, [searchString, tiktokAdGroupWithPerformanceList, order.currency, selectedObjectTypeFilter, selectedOptStatusFilter, selectedDeliveryStatusFilter]);

  const onSelect = (adGroup: TiktokAdGroup) => {
    const isDraft: boolean = _.get(adGroup, 'isDraft', false);
    let objectId: number | string = adGroup.adgroup_id;
    let selectedArray = selectedAdGroups;
    let setSelectedArray = setSelectedAdGroups;
    if (isDraft) {
      objectId = _.get(adGroup, 'draftId', 0);
      selectedArray = selectedDrafts;
      setSelectedArray = setSelectedDrafts;
    }

    setSelectedArray(handleOnSelect(selectedArray, objectId));
  };

  const onSelectAll = () => {
    const selectedArrays = [...selectedAdGroups, ...selectedDrafts];
    const {
      selectedAdGroups: newSelectedAdGroups,
      selectedDrafts: newSelectedDrafts
    } = handleOnSelectAll(selectedArrays, filteredList);
    setSelectedAdGroups(newSelectedAdGroups);
    setSelectedDrafts(newSelectedDrafts);
  };

  const invokeRefreshList = useCallback((invoke: boolean) => {
    invoke && refreshList();
  }, [refreshList]);

  const handleRemoveSelect = () => {
    setSelectedAdGroups([]);
    setSelectedDrafts([]);
  };

  const onDeleteModalClose = useCallback((refresh: boolean) => {
    setAdGroupToDelete(undefined);
    invokeRefreshList(refresh);
  }, [invokeRefreshList]);

  const deleteAdGroups = useCallback(async (adGroupIds: (number | string)[]) => {
    callAPIs([
      tiktokAdGroupManager.deleteAdGroups.bind(tiktokAdGroupManager, adGroupIds)
    ], () => {
      setSelectedAdGroups([]);
      setSelectedDrafts([]);
      onDeleteModalClose(true);
    });
  }, [tiktokAdGroupManager, onDeleteModalClose, callAPIs]);

  const onDeleteDraftModalClose = useCallback((refresh: boolean) => {
    setShowDeleteDraftConfirmModal(false);
    setDeleteDraftIds([]);
    invokeRefreshList(refresh);
  }, [invokeRefreshList]);

  const deleteDraft = useCallback(async (draftIds: (number | string)[]) => {
    setShowDeleteDraftConfirmModal(false);
    callAPIs([
      tiktokAdGroupDraftManager.deleteDrafts.bind(tiktokAdGroupDraftManager, draftIds)
    ], () => {
      setSelectedAdGroups([]);
      setSelectedDrafts([]);
      onDeleteDraftModalClose(true);
    });
  }, [tiktokAdGroupDraftManager, onDeleteDraftModalClose, callAPIs]);

  const columnDefinition = (columnName, customLabel?: string): ColumnDefinition => ({
    ...sortableColumn(columnName, _.defaultTo(customLabel, `tiktokAdGroupList.headers.${columnName}`), true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const metricsWithCommasFormatter = _.partial(metricsFormatter, numberWithCommas);

  const idColumn = renderColumn({
    ...columnDefinition(TiktokAdGroupListColumns.LISTID),
    sortFunc: idSorter,
    formatExtraData: {
      currentUrl: match.url,
      selectedAdGroups,
      selectedDrafts,
      onSelect,
      l1Object: _.defaultTo(l1Object, { isSelfServe: true })
    }
  },
  formatters.nameFormatter,
  _.partial(
    formatters.nameHeaderFormatter,
    filteredList.length,
    [...selectedAdGroups, ...selectedDrafts],
    onSelectAll,
    selectedOptStatusFilter.includes(i18n.t<string>('campaignList.labels.deleteState'))
  ));

  const budgetColumn = renderColumn({
    ...columnDefinition(
      TiktokAdGroupListColumns.BUDGET,
      i18n.t<string>(`tiktokAdGroupList.headers.${TiktokAdGroupListColumns.BUDGET}`) + ` (${order.currency})`
    ),
    formatExtraData: {
      currency: order.currency
    }
  }, formatters.budgetFormatter);

  const optimizationColumn = renderColumn({
    ...columnDefinition(TiktokAdGroupListColumns.OPTIMIZATION),
    formatExtraData: {
      currency: order.currency
    }
  }, formatters.optimizationFormatter);

  const onDeleteBtnClick = (adGroupToDelete: number | string) => {
    setAdGroupToDelete([adGroupToDelete]);
  };

  const editBtnsColumn = renderColumn({
    ...columnDefinition(TiktokAdGroupListColumns.EDITBTNS),
    text: '',
    sort: false,
    formatExtraData: {
      currentUrl: match.url,
      onDeleteBtnClick,
      canDeleteSelectedAdGroups: _.partial(canDeleteSelectedAdGroups, tiktokAdGroups),
      l1Object: _.defaultTo(l1Object, { isSelfServe: true })
    }
  }, formatters.floatingEditBtnsFormatter);

  const basicColumns = useMemo(() => ([
    idColumn,
    renderColumn({
      ...columnDefinition(TiktokAdGroupListColumns.STATE),
      sortFunc: stateSorter
    }, formatters.stateFormatter),
    renderColumn({
      ...columnDefinition(TiktokAdGroupListColumns.DELIVERY),
      sortFunc: deliverySorter
    }, formatters.deliveryFormatter),
    renderColumn({
      ...columnDefinition(TiktokAdGroupListColumns.CREATIVE),
      formatExtraData: {
        onClick: _.partial(onCreativeBindingBtnClick, match.url, history),
        l1Object: _.defaultTo(l1Object, { isSelfServe: true })
      }
    }, formatters.creativeBindingFormatter),
    budgetColumn,
    renderColumn(columnDefinition(TiktokAdGroupListColumns.SCHEDULING), formatters.scheduleFormatter),
    optimizationColumn,
    renderColumn({
      ...columnDefinition(TiktokAdGroupListColumns.TARGETING),
      sort: false
    }, formatters.targetingFormatter),
    editBtnsColumn
  ]), [
    idColumn,
    budgetColumn,
    editBtnsColumn,
    l1Object,
    optimizationColumn,
    history,
    match.url
  ]);

  const spentColumn = renderColumn(
    columnDefinition(
      TiktokAdGroupListColumns.SPENT,
      i18n.t<string>(`tiktokAdGroupList.headers.${TiktokAdGroupListColumns.SPENT}`) + ` (${order.currency})`),
      _.partial(metricsFormatter, _.partial(formatPrice, order.currency)
    )
  );

  const impresColumn = renderColumn(
    columnDefinition(TiktokAdGroupListColumns.IMPRES),
    metricsWithCommasFormatter
  );

  const performanceColumns = _.compact([
    idColumn,
    budgetColumn,
    optimizationColumn,
    renderColumn({
      ...columnDefinition(TiktokAdGroupListColumns.RESULTS),
      formatExtraData: {
        objective: _.get(l1Object, 'tiktok.objective_type')
      }
    }, formatters.resultsFormatter),
    impresColumn,
    renderColumn(columnDefinition(TiktokAdGroupListColumns.CLICKS), metricsWithCommasFormatter),
    renderColumn(
      columnDefinition(TiktokAdGroupListColumns.CPC, i18n.t<string>(`tiktokAdGroupList.headers.${TiktokAdGroupListColumns.CPC}`) + ` (${order.currency})`),
      _.partial(formatPrice, order.currency)
    ),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.CTR), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`)),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.UU), metricsWithCommasFormatter),
    spentColumn,
    editBtnsColumn
  ]);

  const videoPlayColumns = useMemo(() => ([
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_PLAY_ACTIONS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_WATCHED_2S), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_WATCHED_6S), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P25), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P50), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P75), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_VIDEO_VIEWS_P100), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.VIDEO_VIEW_RATE), _.partial(metricsFormatter, value => `${value.toFixed(2)}%`)),
    editBtnsColumn
  ]), [
    idColumn,
    impresColumn,
    spentColumn,
    editBtnsColumn,
    metricsWithCommasFormatter
  ]);

  const engagementColumns = useMemo(() => ([
    idColumn,
    impresColumn,
    spentColumn,
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_PROFILE_VISITS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_LIKES), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_COMMENTS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_SHARES), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_FOLLOWS), metricsWithCommasFormatter),
    renderColumn(columnDefinition(TiktokAdGroupListColumns.TIKTOK_CLICKS_ON_MUSIC_DISC), metricsWithCommasFormatter),
    editBtnsColumn
  ]), [
    idColumn,
    impresColumn,
    spentColumn,
    editBtnsColumn,
    metricsWithCommasFormatter
  ]);

  const typeColumnsMap = useMemo(() => ({
    [TiktokAdGroupListType.BASIC]: basicColumns,
    [TiktokAdGroupListType.PERFORMANCE]: performanceColumns,
    [TiktokAdGroupListType.VIDEO_PLAY]: videoPlayColumns,
    [TiktokAdGroupListType.ENGAGEMENT]: engagementColumns
  }), [
    basicColumns,
    performanceColumns,
    videoPlayColumns,
    engagementColumns
  ]);

  const metricsGroupOptions = useMemo(() => {
    return Object.keys(typeColumnsMap).map(metricsGroup => ({
      label: i18n.t<string>(`campaignList.tabs.${metricsGroup}`),
      value: metricsGroup
    }));
  }, [typeColumnsMap]);

  const stopEventPropagation = useCallback(event => {
    event && event.stopPropagation();
  }, []);

  const getCreativesManagementPath = useCallback(() => {
    return getManageCreativesPath(location.pathname, selectedAdGroups, selectedDrafts);
  }, [location.pathname, selectedAdGroups, selectedDrafts]);

  const bindCreatives = useCallback((event) => {
    stopEventPropagation(event);
    history.push(`${getCreativesManagementPath()}&action=bind`);
  }, [history, stopEventPropagation, getCreativesManagementPath]);

  const activateCreatives = useCallback((event) => {
    stopEventPropagation(event);
    history.push(`${getCreativesManagementPath()}&action=activate`);
  }, [history, stopEventPropagation, getCreativesManagementPath]);

  const deactivateCreatives = useCallback((event) => {
    stopEventPropagation(event);
    history.push(`${getCreativesManagementPath()}&action=deactivate`);
  }, [history, stopEventPropagation, getCreativesManagementPath]);

  const getUpdateStatusPayload = useCallback(() => {
    return [
      ...generateUpdateStatePayload(tiktokAdGroups, selectedAdGroups, 'adGroup'),
      ...generateUpdateStatePayload(tiktokAdGroups, selectedDrafts, 'draft')
    ];
  }, [tiktokAdGroups, selectedAdGroups, selectedDrafts]);

  const activeAdGroup = useCallback(async () => {
    callAPIs([
      tiktokAdGroupManager.updateAdGroupState.bind(tiktokAdGroupManager, getUpdateStatusPayload(), 'activate')
    ], () => {
      setSelectedAdGroups([]);
      setSelectedDrafts([]);
      refreshList();
    });
  }, [tiktokAdGroupManager, getUpdateStatusPayload, refreshList, callAPIs]);

  const deactiveAdGroup = useCallback(async () => {
    callAPIs([
      tiktokAdGroupManager.updateAdGroupState.bind(tiktokAdGroupManager, getUpdateStatusPayload(), 'deactivate')
    ], () => {
      setSelectedAdGroups([]);
      setSelectedDrafts([]);
      refreshList();
    });
  }, [tiktokAdGroupManager, getUpdateStatusPayload, refreshList, callAPIs]);

  const onShowDeleteDraftConfirmModal = useCallback(() => {
    setShowDeleteDraftConfirmModal(true);
    setDeleteDraftIds(selectedDrafts);
  }, [selectedDrafts]);

  const hideDraftCreateModal = useCallback((dirty: boolean) => {
    setShowDraftCreateModal(false);
    setSelectedAdGroups([]);
    setSelectedDrafts([]);
    invokeRefreshList(dirty);
  }, [invokeRefreshList]);

  const handleOnDeliveryStatusFilterApply = useCallback((stateList: string[]) => {
    setSelectedDeliveryStatusFilter([...stateList]);
  }, []);

  const handleOnOptStatusFilterApply = useCallback((stateList: string[]) => {
    setSelectedOptStatusFilter([...stateList]);
  }, []);

  const handleOnObjectTypeFilterApply = useCallback((objectTypeList) => {
    setSelectedObjectTypeFilter([...objectTypeList]);
  }, []);

  const filterMenuTabConfigs: FilterMenuTabConfig[] = useMemo(() => ([
    {
      filterType: i18n.t<string>('l2ObjectList.labels.statusFilter'),
      menuTitle: i18n.t<string>('l2ObjectList.labels.statusFilterMenuTitle'),
      tag: i18n.t<string>('l2ObjectList.labels.statusTag'),
      selectedValues: selectedOptStatusFilter,
      options: adGroupsOptStatus,
      applyMethod: handleOnOptStatusFilterApply
    },
    {
      filterType: i18n.t<string>('l2ObjectList.labels.deliveryFilter'),
      menuTitle: i18n.t<string>('l2ObjectList.labels.deliveryFilterMenuTitle'),
      tag: i18n.t<string>('l2ObjectList.labels.deliveryTag'),
      selectedValues: selectedDeliveryStatusFilter,
      options: adGroupsDeliveryStatus,
      applyMethod: handleOnDeliveryStatusFilterApply
    },
    {
      filterType: i18n.t<string>('l2ObjectList.labels.objectTypeFilter'),
      menuTitle: i18n.t<string>('l2ObjectList.labels.objectTypeFilterMenuTitle'),
      tag: i18n.t<string>('l2ObjectList.labels.objectTypeTag'),
      selectedValues: selectedObjectTypeFilter,
      options: objectTypes,
      applyMethod: handleOnObjectTypeFilterApply
    }
  ]), [
    adGroupsDeliveryStatus,
    selectedDeliveryStatusFilter,
    adGroupsOptStatus,
    selectedOptStatusFilter,
    objectTypes,
    selectedObjectTypeFilter,
    handleOnDeliveryStatusFilterApply,
    handleOnOptStatusFilterApply,
    handleOnObjectTypeFilterApply
  ]);

  const showDeleteConfirmModal = useCallback(() => {
    setAdGroupToDelete(selectedAdGroups);
  }, [selectedAdGroups]);

  return {
    loading,
    columns: typeColumnsMap[selectedMetricsGroup],
    summaryData,
    rowClasses,
    filteredList,
    searchString,
    currentUrl: match.url,
    selectedAdGroups,
    selectedDrafts,
    metricsGroupOptions,
    selectedMetricsGroup,
    canNotCreateMessage: canNotCreateMessage(order, l1Object),
    showDraftCreateModal,
    hideDraftCreateModal,
    setShowDraftCreateModal,
    onMetricsGroupChange: selectedMetricsGroup => setSelectedMetricsGroup(selectedMetricsGroup),
    onHandleSearch,
    handleRemoveSelect,
    onDeleteDraftModalClose,
    showDeleteDraftConfirmModal,
    onShowDeleteDraftConfirmModal,
    deleteDraft: _.partial(deleteDraft, deleteDraftIds),
    bindCreatives,
    activateCreatives,
    deactivateCreatives,
    activeAdGroup,
    deactiveAdGroup,
    deleteAdGroups: adGroupToDelete !== undefined ? _.partial(deleteAdGroups, adGroupToDelete) : undefined,
    filterMenuTabConfigs,
    onDeleteModalClose,
    canDeleteSelectedAdGroups: _.partial(canDeleteSelectedAdGroups, tiktokAdGroups),
    showDeleteConfirmModal
  };
};

const getObjectTypeDesc = (tiktokAdGroup) => {
  if (tiktokAdGroup.isDraft) {
    return i18n.t<string>('l2ObjectList.labels.draft');
  } else {
    return i18n.t<string>('l2ObjectList.labels.ordinaryCampaign_tiktok');
  }
};

const tiktokAdGroupListFilter = (
  tiktokAdGroup,
  searchString: string,
  selectedDeliveryStatusFilter: string[],
  selectedOptStatusFilter: string[],
  selectedObjectTypeFilter: string[]
) => {
  const objectTypeDes = getObjectTypeDesc(tiktokAdGroup);
  const nameInclude = tiktokAdGroup.adgroup_name.toLowerCase().includes(searchString.toLowerCase());
  const listIdInclude = tiktokAdGroup.listId.toString().includes(searchString);
  const deliveryStatusInclude = selectedDeliveryStatusFilter.length === 0 || (tiktokAdGroup.delivery && selectedDeliveryStatusFilter.includes(tiktokAdGroup.delivery.label));
  const optStatusInclude = (selectedOptStatusFilter.length === 0 && tiktokAdGroup.opt_status !== 'DELETE') || selectedOptStatusFilter.includes(tiktokAdGroup.state.label);
  const objectTypeInclude = selectedObjectTypeFilter.length === 0 || selectedObjectTypeFilter.includes(objectTypeDes);
  return (nameInclude || listIdInclude) && objectTypeInclude && optStatusInclude && deliveryStatusInclude;
};

const idSorter = (adGroup1, adGroup2, order) => {
  const adGroup1IsDraft = adGroup1.includes('draft');
  const adGroup2IsDraft = adGroup2.includes('draft');
  const sameObjectType = adGroup1IsDraft === adGroup2IsDraft;
  const compareListId = adGroup1.toString().localeCompare(adGroup2.toString());
  if (!sameObjectType) {
    return adGroup1IsDraft ? -1 : 1;
  }
  if (compareListId === 0) {
    return 0;
  }
  if (order === 'desc') {
    return compareListId > 0 ? -1 : 1;
  } else {
    return compareListId < 0 ? -1 : 1;
  }
};

const stateSorter = (stateA, stateB, order) => {
  const stateDes = [
    i18n.t<string>('campaignList.labels.activateState'),
    i18n.t<string>('campaignList.labels.deactivateState'),
    i18n.t<string>('campaignList.labels.deleteState')
  ];
  const dataAToCompare = stateDes.indexOf(stateA.label);
  const dataBToCompare = stateDes.indexOf(stateB.label);
  if (order === 'desc') {
    return dataAToCompare > dataBToCompare ? 1 : -1;
  } else {
    return dataAToCompare < dataBToCompare ? 1 : -1;
  }
};

const deliverySorter = (stateA, stateB, order) => {
  const stateDes = [
    i18n.t<string>('campaignList.labels.budgetRemainState'),
    i18n.t<string>('campaignList.labels.withIssues'),
    i18n.t<string>('campaignList.labels.pendingReviewState'),
    i18n.t<string>('campaignList.labels.activateState'),
    i18n.t<string>('campaignList.labels.deactivateState'),
    i18n.t<string>('campaignList.labels.noCreativesState'),
    i18n.t<string>('campaignList.labels.notStartState'),
    i18n.t<string>('campaignList.labels.endState'),
    i18n.t<string>('campaignList.labels.deleteState')
  ];
  const dataAToCompare = stateDes.indexOf(stateA.label);
  const dataBToCompare = stateDes.indexOf(stateB.label);
  if (order === 'desc') {
    return dataAToCompare > dataBToCompare ? 1 : -1;
  } else {
    return dataAToCompare < dataBToCompare ? 1 : -1;
  }
};

const getManageCreativesPath = (pathname, selectedAdGroups, selectedDrafts) => {
  const campaignIds = selectedAdGroups.join(',');
  const draftIds = selectedDrafts.join(',');
  let path = `${pathname}?`;
  if (campaignIds) {
    path = path + `campaignIds=${campaignIds}`;
  }
  if (draftIds) {
    path = campaignIds ? `${path}&draftIds=${draftIds}` : `${path}draftIds=${draftIds}`;
  }
  return path;
};

const generateUpdateStatePayload = (tiktokAdGroups, selectedObjects: (number | string)[], objectType: 'adGroup' | 'draft') => {
  const key = objectType === 'adGroup' ? 'adgroup_id' : 'draftId';
  const payload = selectedObjects.map(objectId => {
    const object = tiktokAdGroups.find(adGroup => _.get(adGroup, key, '').toString() === objectId.toString());
    return {
      l2ChannelId: objectId,
      isDraft: _.get(object, 'isDraft', false)
    };
  });
  return payload;
};

const rowClasses = (adGroup) => {
  let classes: any = null;
  if (adGroup.state === CampaignState.DEACTIVATE && adGroup.budget_remaining > 0) {
    classes = [classes, styles.lightDanger].join(' ');
  }
  if (adGroup.isDraft) {
    classes = [classes, styles.draftBg].join(' ');
  }
  return classes;
};

const canNotCreateMessage = (order, l1Object) => {
  const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
  const state = order.state;
  if (canNotCreateState.includes(state)) {
    return i18n.t<string>('tiktokAdGroupList.labels.orderStateCannotCreate');
  }

  const isEnd = moment(order.endDate).isBefore(moment().startOf('day'));
  if (isEnd) {
    return i18n.t<string>('tiktokAdGroupList.labels.isEndCannotCreate');
  }

  if (l1Object && l1Object.budgetBalance <= 0) {
    return i18n.t<string>('orderDetail.labels.lessThanBudgetMinimum', { budget: 0 });
  }

  return '';
};

const getCanDeleteAdGroups = (adGroupIds, tiktokAdGroups) =>
  _.filter(tiktokAdGroups, tiktokAdGroup => {
    const selected = adGroupIds.indexOf(tiktokAdGroup.adgroup_id) > -1;
    if (!selected) {
      return false;
    }
    const notBindCreative = _.get(tiktokAdGroup, 'bindingCount', 0) === 0;
    return notBindCreative;
  });

const canDeleteSelectedAdGroups = (tiktokAdGroups, adGroupIds: (string | number)[]) => {
  if (adGroupIds.length === 0) {
    return false;
  }

  const canDeleteAdGroups = getCanDeleteAdGroups(adGroupIds, tiktokAdGroups);
  return canDeleteAdGroups.length === adGroupIds.length;
};

const onCreativeBindingBtnClick = (url, history, id, isDraft) => {
  let toUrl = `${url}?campaignIds=${id}&action=manage`;
  if (isDraft) {
    toUrl = `${url}?draftIds=${id}&action=manage`;
  }
  history.replace(toUrl);
};

const handleOnSelect = (selectedArray, objectId) => {
  if (selectedArray.indexOf(objectId.toString()) > -1) {
    return selectedArray.filter(id => id.toString() !== objectId.toString());
  }
  return [...selectedArray, objectId.toString()];
};

const handleOnSelectAll = (selectedArrays, filteredList) => {
  if (selectedArrays.length === filteredList.length) {
    return {
      selectedAdGroups: [],
      selectedDrafts: []
    };
  }

  return {
    selectedAdGroups: filteredList.filter(object => !_.get(object, 'isDraft', false)).map(adGroup => adGroup.adgroup_id.toString()),
    selectedDrafts: filteredList.filter(object => _.get(object, 'isDraft', true)).map(draft => _.get(draft, 'draftId', 0).toString())
  };
};

const getTAToShow = (targeting, tiktokCountries) => {
  if (!tiktokCountries) {
    return {};
  }
  return defaultTiktokAdGroupManager.getTASummaryData(targeting, tiktokCountries.map(country => country.value), false, true);
};
