import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';
import { AdType } from 'core/rtbCampaign/RtbCampaign';

export interface RetailFormModel extends RtbCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractRetailFormModel extends DefaultRtbCampaignBasicFormModel
 implements RetailFormModel {

  showVideoProgressRadio = false;

  get campaignAdType (): AdType {
    return AdType.RETAIL;
  }

  getBidPriceFloorData (): any {
    // TODO: Not yet add Add-on Feature Retail AdType bid price setting
    const data = this.order.campaignBidPrice.find(data => data.type === AdType.RETAIL);
    return data ? data.bidFloor : {};
  }
}

export class EditRetailFormModel extends AbstractRetailFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
  canEditOptimize (): boolean {
    return false;
  }
}

export class CreateRetailFormModel extends AbstractRetailFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
