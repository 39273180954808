import { FBCampaign } from 'core/fbCampaign/FbCampaign';
import { TiktokCampaign } from 'core/tiktokCampaign/TiktokCampaign';

export type L1Object = {
  l1ObjectId: number;
  name: string;
  adsOrderId: number;
  state: L1ObjectState;
  budget?: number | string | null;
  dailyBudget?: number | string | null;
  channel: L1ObjectChannel;
  autoOptimise: boolean;
  budgetBalance: number;
  currencyRate: number;
  objective: L1ObjectObjective;
  fb?: FBCampaign;
  tiktok?: TiktokCampaign;
  rtb?: any;
  isSelfServe: boolean;
  l2ObjectMinStartDate?: string;
  l2ObjectMaxEndDate?: string;
  l2ObjectRunningStatus: {
    [key: string]: number | string | boolean;
  }
  actionable: boolean;
};

export enum L2ObjectRunningStatus {
  AHEAD = 'ahead',
  BEHIND = 'behind',
  BUDGET_NOT_ALLOCATED = 'budgetNotAllocated',
  BUDGET_REMAIN = 'budgetRemain',
  END = 'end',
  INACTIVE = 'inactive',
  NO_CREATIVES = 'noCreatives',
  NORMAL = 'normal',
  NOT_START = 'notStart',
  SEVERELY_BEHIND = 'severelyBehind',
  ACTUAL_PROGRESS = 'actualProgress',
  EXPECTED_PROGRESS = 'expectedProgress',
  BUDGET = 'budget',
  SPENT = 'spent',
  DRAFT = 'draft', // draft in message campaign
  ACTIVE = 'active',
  SCHEDULED = 'scheduled',
  ABANDON = 'abandon',
  ABNORMAL = 'abnormal',
  FINISHED = 'finished'
}

export enum L1ObjectChannel {
  RTB = 'RTB',
  FB = 'FB',
  TIKTOK = 'TIKTOK',
  RETAIL_MEDIA = 'RETAIL_MEDIA',
  EDIMAX = 'EDIMAX',
  MESSAGE = 'MESSAGE'
}

export enum L1ObjectState {
  ACTIVE = 1,
  DEACTIVATE = 2,
  DELETE = 99
}

// enum AwarenessObjective {
//   REACH = 'REACH'
//   // BRAND_AWARENESS = 'BRAND_AWARENESS'
// }

// enum ConsiderationObjective {
//   LINK_CLICKS = 'LINK_CLICKS'
//   // Engagement
//   // POST_ENGAGEMENT = 'POST_ENGAGEMENT', // Post engagement
//   // PAGE_LIKES = 'PAGE_LIKES', // Page likes
//   // EVENT_RESPONSES = 'EVENT_RESPONSES', // Event responses
//   // //
//   // APP_INSTALLS = 'APP_INSTALLS', // App installs
//   // VIDEO_VIEWS = 'VIDEO_VIEWS', // Video views
//   // LEAD_GENERATION = 'LEAD_GENERATION', // Lead generation
//   // MESSAGES = 'MESSAGES' // Messages
// }

// enum ConversionObjective {
//   CONVERSIONS = 'CONVERSIONS'
//   // PRODUCT_CATALOG_SALES = 'PRODUCT_CATALOG_SALES', // Catalog sales
//   // STORE_VISITS = 'STORE_VISITS' // Store traffic
// }

// export const L1ObjectObjective = {
//   AwarenessObjective,
//   ConsiderationObjective,
//   ConversionObjective
//   // LOCAL_AWARENESS = 'LOCAL_AWARENESS',
//   // OFFER_CLAIMS = 'OFFER_CLAIMS',
// };

export enum L1ObjectObjective {
  AWARENESS = 'AWARENESS',
  TRAFFIC = 'TRAFFIC',
  SALES = 'SALES',
  UNSPECIFIED = 'UNSPECIFIED'
}
