import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { DefaultRtbCampaignBasicFormModel } from './RtbCampaignBasicFormModel';
import { RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';

export abstract class SupportVideoViewsFormModel extends DefaultRtbCampaignBasicFormModel {

  getRtbOptionsMap () {
    const optionsMap = super.getRtbOptionsMap();
    const enableOptimizedPrice = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.ENABLE_OPTIMIZED_PRICE);
    return {
      ...optionsMap,
      [L1ObjectObjective.AWARENESS]: {
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPM]: enableOptimizedPrice ? [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ] : [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPV]: enableOptimizedPrice ? [
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ] : [
          L2ObjectOptimizationGoal.VIDEO_VIEWS
        ],
        [RtbCampaignPlanType.FVCPM]: [
          L2ObjectOptimizationGoal.VIDEO_VIEWS
        ],
        [RtbCampaignPlanType.DCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      },
      [L1ObjectObjective.UNSPECIFIED]: {
        [RtbCampaignPlanType.RS]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPV]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.FVCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        [RtbCampaignPlanType.DCPM]: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ],
        rb: [
          L2ObjectOptimizationGoal.REACH,
          L2ObjectOptimizationGoal.CLICKS,
          L2ObjectOptimizationGoal.VIDEO_VIEWS,
          L2ObjectOptimizationGoal.IMPRESSIONS
        ]
      }
    };
  }
}
