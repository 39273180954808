import { AdType } from 'core/rtbCampaign/RtbCampaign';

type AddonFeatures = {[category: string]: {[key: string]: boolean}};

type AgencyProps = {
  targetBudgetMinimum: number;
  campaignBudgetMinimum: number;
  addonProps: {
    creditLimit: number;
    sysProfitMonitorPercent: number;
    maxOrderProfit: number;
    fixedExchangeRate: number;
    defaultMonitorBudget: number;
    orderBudgetMaximum: number;
    outdoorAdImpresMultiply: number;
    outdoorMachineCount: number;
    campaignBidPrice: {
      type: AdType;
      autoBidCap: {[key: string]: number};
      bidFloor: {[key: string]: number};
    };
    agencySegmentLimitationName: string;
  }
};

export type LocaleMeta = {
  readonly agencyId?: number,
  readonly timezone: string;
  readonly currency: string;
  readonly currencyRate: number;
  readonly language: string;
  readonly agcPercent: number;
  readonly maxOrderProfit: number;
  readonly maxOrderBudget: number;
  readonly credit: number;
  readonly addonFeatures: AddonFeatures;
  readonly property: AgencyProps;
};

export const MOMENT_TIMEZONE = {
  '+09:00': 'Asia/Tokyo',
  '+08:00': 'Asia/Taipei',
  '+07:00': 'Asia/Ho_Chi_Minh'
};
