import React, { Fragment, useState } from 'react';
import { AudienceInfo } from 'components/AudienceInfo/AudienceInfo';
import styles from './taInfoCard.module.scss';
import { EstimatedAudience } from 'core/goSegment/GoSegment';
import i18n from 'i18n';
import { SelectOptions } from 'components/common/commonType';
import _ from 'lodash';
import { Modal } from 'components/common/Modal/Modal';
import { Col, Form, Row } from 'react-bootstrap';
import classnames from 'classnames/bind';
import { Trans } from 'react-i18next';
import { DefaultLimitationManager, LimitationManager } from 'core/limitation/LimitationManager';
import { toServerStructure } from 'utils/LimitationUtil';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { toast } from 'react-toastify';
import { OrderType } from 'core/order/Order';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
const cx = classnames.bind(styles);

enum SavedTASaveType {
  NEW = 'new',
  REPLACE = 'replace'
}

const defaultLimitationManager = new DefaultLimitationManager();

export const TAInfoCard: React.FC<{
  orderType: OrderType,
  channel: string,
  advertiserId: number,
  targetingValue: any,
  appliedSavedTAInfo?: SelectOptions,
  savedTargetingList: SelectOptions[],
  estimatedAudience?: EstimatedAudience,
  audienceLowestThreshold: number
  limitationManager?: LimitationManager,
  showAddSaveTargetingModal: boolean,
  onShowAddSaveTargetingModal: (showModal: boolean) => void
}> = ({
  orderType,
  channel,
  advertiserId,
  targetingValue,
  appliedSavedTAInfo,
  savedTargetingList,
  estimatedAudience,
  audienceLowestThreshold,
  limitationManager = defaultLimitationManager,
  showAddSaveTargetingModal,
  onShowAddSaveTargetingModal
}) => {

  const { loading, callAPIs } = useCallAPI();
  const [saveType, setSaveType] = useState<SavedTASaveType | undefined>();
  const [newTAName, setNewTAName] = useState('');
  const [taNameHasBeenUsed, setTANameHasBeenUsed] = useState(false);
  const newSavedTA = !saveType || saveType === SavedTASaveType.NEW;
  const hideCard: boolean = (orderType === OrderType.TENMAX) && _.includes([
    L1ObjectChannel.RTB,
    L1ObjectChannel.RETAIL_MEDIA,
    L1ObjectChannel.EDIMAX
  ], channel);

  const closeAddSaveTargetingModal = () => onShowAddSaveTargetingModal(false);

  const saveTargeting = () => {
    if (newSavedTA) {
      callAPIs([() => limitationManager.createSavedTargeting({
        name: newTAName,
        channel,
        advertiserId,
        targeting: toServerStructure(targetingValue, {})
      }, orderType)], () => {
        toast.success(i18n.t<string>('taInfoCard.messages.createSuccess'));
        closeAddSaveTargetingModal();
      }, () => {
        toast.error(i18n.t<string>('taInfoCard.messages.createFailed'));
      });
    } else {
      if (!appliedSavedTAInfo) {
        return;
      }
      callAPIs([() => limitationManager.updateSavedTargeting({
        id: appliedSavedTAInfo.value,
        name: appliedSavedTAInfo.label,
        channel,
        advertiserId,
        targeting: toServerStructure(targetingValue, {})
      }, orderType)], () => {
        toast.success(i18n.t<string>('taInfoCard.messages.updateSuccess'));
        closeAddSaveTargetingModal();
      }, () => {
        toast.error(i18n.t<string>('taInfoCard.messages.updateFailed'));
      });
    }
  };

  const onSaveTypeChange = (e) => {
    setSaveType(e.target.value);
  };

  const onChangeNewTAName = (e) => {
    setNewTAName(e.target.value);
    const names = savedTargetingList.map(savedTA => savedTA.label);
    if (names.includes(e.target.value) && !taNameHasBeenUsed) {
      setTANameHasBeenUsed(true);
    } else if (taNameHasBeenUsed) {
      setTANameHasBeenUsed(false);
    }
  };

  const renderNameInput = () => {
    const inputClassName = cx({
      error: taNameHasBeenUsed
    });
    return (
      <div className={styles.nameInput}>
        <Form.Control
          className={inputClassName}
          type='text'
          value={newTAName}
          onChange={onChangeNewTAName}
          placeholder={i18n.t<string>('taInfoCard.placeholders.taName')}
        />
        {taNameHasBeenUsed &&
          <span className={styles.errorMessage}>
            {i18n.t<string>('taInfoCard.errors.taNameHasBeenUsed')}
          </span>
        }
      </div>
    );
  };

  return (
    <Fragment>
      {showAddSaveTargetingModal &&
        <Modal
          className={styles.saveModal}
          title={i18n.t<string>('taInfoCard.labels.title')}
          primaryButton={{
            title: i18n.t<string>('common.buttons.save'),
            callback: saveTargeting,
            disabled: newSavedTA && (taNameHasBeenUsed || _.isEmpty(newTAName))
          }}
          secondaryButton={{
            title: i18n.t<string>('common.buttons.close'),
            callback: closeAddSaveTargetingModal
          }}
          dismiss={closeAddSaveTargetingModal}
        >
          {loading && <LoadingIndicator/>}
          {appliedSavedTAInfo ?
            <>
              <div className={styles.saveHint}>{i18n.t<string>('taInfoCard.labels.hint')}</div>
              <Form.Check
                type={'radio'}
                label={i18n.t<string>('taInfoCard.labels.new')}
                value={SavedTASaveType.NEW}
                id={`radio-${SavedTASaveType.NEW}`}
                onChange={onSaveTypeChange}
                checked={saveType === SavedTASaveType.NEW}
              />
              {saveType === SavedTASaveType.NEW && renderNameInput()}
              <Form.Check
                type={'radio'}
                label={i18n.t<string>('taInfoCard.labels.replace')}
                value={SavedTASaveType.REPLACE}
                id={`radio-${SavedTASaveType.REPLACE}`}
                onChange={onSaveTypeChange}
                checked={saveType === SavedTASaveType.REPLACE}
              />
              {saveType === SavedTASaveType.REPLACE &&
                <Form.Label className={styles.toReplacedTAName}>
                  <Trans i18nKey='taInfoCard.labels.toReplacedTAName' tOptions={{ name: appliedSavedTAInfo.label }}>
                    ...<span className={styles.textBlack}>...</span>
                  </Trans>
                </Form.Label>
              }
            </> :
            <Form.Group as={Row} controlId={'taName'}>
              <Col sm={3}>
                <Form.Label column >{i18n.t<string>('taInfoCard.labels.taName')}</Form.Label>
              </Col>
              <Col sm={9}>
                {renderNameInput()}
              </Col>
            </Form.Group>
          }
        </Modal>
      }
      {!hideCard &&
        <div className={styles.taInfo}>
          {estimatedAudience &&
            <AudienceInfo
              loading={false}
              layoutDirection='column'
              lowerBound={estimatedAudience.lowerBound}
              upperBound={estimatedAudience.upperBound}
              threshold={audienceLowestThreshold}
              estimateReady={estimatedAudience.estimateReady}
            />
          }
          <div className={styles.saveHint}>{i18n.t<string>('taInfoCard.labels.saveTargeting')}</div>
          <button className={'btn btn-secondary btn-sm'} onClick={_.partial(onShowAddSaveTargetingModal, true)}>
            {i18n.t<string>('taInfoCard.buttons.saveTargeting')}
          </button>
        </div>
      }
    </Fragment>
  );
};
