import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { RtbCampaignsProps } from './RtbCampaignsModel';
import { RtbCampaignSetupFlowPage } from './RtbCampaignSetupFlow/RtbCampaignSetupFlowPage';
import styles from './rtbCampaigns.module.scss';
import { addOnEnabled, hasFuncs, isPmax3Order } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import CoreContext from 'contexts/coreContext';
import _ from 'lodash';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { PermissionItem } from 'core';

const CreateCampaignView = withErrorBoundary(ERROR_CODE.CAMPAIGN_CREATE, RtbCampaignSetupFlowPage);
const EditCampaignView = withErrorBoundary(ERROR_CODE.CAMPAIGN_EDIT, RtbCampaignSetupFlowPage);
const CopyCampaignView = withErrorBoundary(ERROR_CODE.CAMPAIGN_COPY, RtbCampaignSetupFlowPage);
const SplitCampaignView = withErrorBoundary(ERROR_CODE.CAMPAIGN_SPLIT, RtbCampaignSetupFlowPage);
export class RtbCampaigns extends React.Component<RouteComponentProps<any> & RtbCampaignsProps> {

  modifyPermission: PermissionItem;

  constructor (
    props: RouteComponentProps<any> & RtbCampaignsProps
  ) {
    super(props);
    this.modifyPermission = hasFuncs(Permission.CAMPAIGN_WRITE).and(addOnEnabled(ADDONFEATURE.CHANNEL[props.model.channel])).and(isPmax3Order(props.model.order.id));
  }

  renderCreateCampaignView = (localeMeta, props) => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={this.modifyPermission}
        renderOtherwise={renderRedirect}
      >
        <CreateCampaignView {...props} model={this.props.model.getCampaignCreateModel(localeMeta)} />
      </PermissionChecker>
    );
  }

  renderCampaignDetailView = (localeMeta, props) => {
    const campaignId = props.match.params.campaignId;
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={this.modifyPermission}
        renderOtherwise={renderRedirect}
      >
        <EditCampaignView {...props} model={this.props.model.getCampaignEditModel(campaignId, localeMeta)} />
      </PermissionChecker>
    );
  }

  renderCampaignCopyView = (localeMeta, props) => {
    const campaignId = props.match.params.campaignId;
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={this.modifyPermission}
        renderOtherwise={renderRedirect}
      >
        <CopyCampaignView {...props} model={this.props.model.getCampaignCopyModel(campaignId, localeMeta)} />
      </PermissionChecker>
    );
  }

  renderCampaignSplitView = (localeMeta, props) => {
    const campaignId = props.match.params.campaignId;
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={this.modifyPermission}
        renderOtherwise={renderRedirect}
      >
        <SplitCampaignView {...props} model={this.props.model.getCampaignSplitModel(campaignId, localeMeta)} />
      </PermissionChecker>
    );
  }

  redirectToL1Object = (props) => {
    const match = props.match.url.match(/\/campaign-groups\/\d+\//);
    return <Redirect to={`${match[0].slice(0, -1)}`} />;
  }

  render () {
    return (
      <div className={styles.container}>
        <CoreContext.Consumer>
          {(coreContext) => {
            const localeMeta = coreContext?.accountManager.localeMeta;
            return (
              <Switch>
                <Route
                  path={`${this.props.match.url}/new`}
                  render={_.partial(this.renderCreateCampaignView, localeMeta)}
                />
                <Route
                  exact
                  path={`${this.props.match.url}/:campaignId/edit`}
                  render={_.partial(this.renderCampaignDetailView, localeMeta)}
                />
                <Route
                  exact
                  path={`${this.props.match.url}/:campaignId/copy`}
                  render={_.partial(this.renderCampaignCopyView, localeMeta)}
                />
                <Route
                  exact
                  path={`${this.props.match.url}/:campaignId/split`}
                  render={_.partial(this.renderCampaignSplitView, localeMeta)}
                />
                <Route
                  render={this.redirectToL1Object}
                />
              </Switch>
            );
          }}
        </CoreContext.Consumer>
      </div>
    );
  }
}
