import React from 'react';
import { Link } from 'react-router-dom';

import './AppMenuItem.scss';

import i18n from 'i18n';
import { AppMenuItemProps } from './AppMenuBarModel';

export class AppMenuItem extends React.Component<AppMenuItemProps> {
  render () {
    const model = this.props.model;
    const current = window.location.pathname.startsWith(model.path);
    const classes = current ? 'app-menu-item now' : 'app-menu-item';
    return (
      <div className={classes}>
        <Link to={model.path}>
          <span>{i18n.t<string>(model.title)}</span>
        </Link>
      </div>
    );
  }
}
