import React from 'react';
import _, { get } from 'lodash';
import styles from './goSegmentStatus.module.scss';
import cx from 'classnames/bind';
import { GoSegmentListRecord, GoSegmentType } from 'core/goSegment/GoSegment';
import i18n from 'i18n';
import moment from 'moment';
import { CopyableOverlayTrigger } from 'components/CopyableOverlayTrigger/CopyableOverlayTrigger';

const classNames = cx.bind(styles);

const SingleGoSegmentStatus: React.FC<{
  type: string,
  status: string,
  color: string,
  unavailable?: boolean,
  renderStatusDetail?: Function
}> = ({
  type,
  status,
  color,
  unavailable,
  renderStatusDetail
}) => {

  const stateDesc = _.startCase(_.lowerCase(status));

  const containerClassName = classNames('goSegmentStatusContainer', {
    hasTip: !!renderStatusDetail
  });

  const statusDesClassName = classNames('goSegmentStatus', color);

  return (
    <div className={containerClassName}>
      <span className={styles.goSegmentType}>
        {type}:
      </span>
      {unavailable ? 'N/A' :
        <CopyableOverlayTrigger
          placement='bottom'
          overlay={
            renderStatusDetail ? renderStatusDetail() : undefined
          }
        >
          <span className={statusDesClassName}>
            {stateDesc}
          </span>
        </CopyableOverlayTrigger>
      }
    </div>
  );
};

const getFbStatusColorByCustomAudience = (customAudienceDeliveryStatus?: {
  code: number;
  description: string;
}) => {
  if (!customAudienceDeliveryStatus) {
    return 'black';
  }
  if (customAudienceDeliveryStatus.code === 200) {
    return 'success';
  }
  return 'warning';
};

export const GoSegmentStatus: React.FC<{
  goSegment: GoSegmentListRecord
}> = ({
  goSegment
}) => {
  const customAudienceDeliveryStatus = get(goSegment, 'customAudience.delivery_status');
  const fbStatusColor = getFbStatusColorByCustomAudience(customAudienceDeliveryStatus);

  const renderFBStatusDetail = customAudienceDeliveryStatus ? () => {
    return (
      <div style={{ maxWidth: '250px', whiteSpace: 'pre-wrap' }}>
        <div>
          {`Status Code: ${customAudienceDeliveryStatus.code}`}
        </div>
        <div>
          {customAudienceDeliveryStatus.description}
        </div>
        <div>
          {`${i18n.t<string>('creativeReview.labels.stateUpdateTime')} ${moment(goSegment.customAudienceUpdatedAt).format('YYYY-MM-DD HH:mm:ss')}`}
        </div>
      </div>
    );
  } : undefined;

  const tiktokAudienceDetails = get(goSegment, 'tiktokAudience.audience_details');
  const tiktokStatusColor = tiktokAudienceDetails ? (
    (
      goSegment.tiktokAudienceStatus === 'READY_FOR_DELIVERY' ||
      goSegment.tiktokAudienceStatus === 'READY_FOR_RESYNC'
    ) && tiktokAudienceDetails.is_valid ?
      'success' :
      'warning'
  ) : 'black';
  const renderTiktokStatusDetail = tiktokAudienceDetails ? () => {
    const isValid = tiktokAudienceDetails.is_valid;
    return (
      <div style={{ maxWidth: '250px', whiteSpace: 'pre-wrap' }}>
        {
          !isValid &&
            <div>
              {i18n.t<string>('creativeReview.lables.titkokWaitingForCreate')}
            </div>
        }
        <div>
          {`${i18n.t<string>('creativeReview.labels.stateUpdateTime')} ${moment(goSegment.tiktokAudienceUpdatedAt).format('YYYY-MM-DD HH:mm:ss')}`}
        </div>
      </div>
    );
  } : undefined;
  const userList = goSegment.userList;
  const dcStatusColor = userList ? (
    goSegment.userListStatus === 'READY_FOR_DELIVERY' || goSegment.userListStatus === 'READY_FOR_RESYNC' ? 'success' : 'warning'
  ) : 'black';
  const renderRTBStatusDetail = userList ? () => {
    return (
      <div>
        {`${i18n.t<string>('creativeReview.labels.stateUpdateTime')} ${moment(goSegment.userListUpdatedAt).format('YYYY-MM-DD HH:mm:ss')}`}
      </div>
    );
  } : undefined;
  const dcUnAvailable = goSegment.type === GoSegmentType.LOOKALIKE;
  return (
    <div>
      <SingleGoSegmentStatus
        type={'FB'}
        status={goSegment.customAudienceStatus}
        color={fbStatusColor}
        renderStatusDetail={renderFBStatusDetail}
      />
      <SingleGoSegmentStatus
        type={'DC'}
        unavailable={dcUnAvailable}
        status={goSegment.userListStatus}
        color={dcStatusColor}
        renderStatusDetail={renderRTBStatusDetail}
      />
      <SingleGoSegmentStatus
        type={'TIKTOK'}
        status={goSegment.tiktokAudienceStatus}
        color={tiktokStatusColor}
        unavailable={!goSegment.tiktokAudienceStatus}
        renderStatusDetail={renderTiktokStatusDetail}
      />
    </div>
  );
};
