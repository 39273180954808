import { Account, AccountState } from 'core';
import { renderColumn, ColumnDefinition, SortDescriptor, sortableColumn, getCheckedColumn } from 'components/TableColumn/TableColumn';
import styles from './accountList.module.scss';
import { nameFormatter, statusFormatter, oemNameFormatter } from './accountListFormatters';
import _ from 'lodash';

export interface AccountListModel {

  getColumnDefinition (columnName: string): ColumnDefinition;

  readonly accounts: Array<Account>;
  readonly noDataDescription: string;
  readonly defaultSorts: SortDescriptor;
  readonly columns: any[];
}

export type AccountListProps = {

  readonly model: AccountListModel;
};

export class DefaultAccountListModel implements AccountListModel {
  accounts: any[];

  constructor (accounts: Array<Account>, protected isSysAdmin: boolean) {
    this.accounts = accounts.map(account => ({
      ...account,
      pending: account.status === AccountState.PENDING
    }));
  }

  get noDataDescription (): string {
    return 'accounts.placeholder.noDataAvailable';
  }

  getColumnDefinition (columnName: string): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };
    return {
      ...sortableColumn(columnName === 'status' ? 'activated' : columnName, `accounts.headers.${columnName}`),
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    };
  }

  get columns () {
    return _.compact([
      renderColumn(this.getColumnDefinition('name'), nameFormatter),
      renderColumn(this.getColumnDefinition('email')),
      renderColumn(this.getColumnDefinition('status'), statusFormatter),
      this.isSysAdmin ? getCheckedColumn(this.getColumnDefinition('isAdmin')) : undefined,
      getCheckedColumn(this.getColumnDefinition('pending'))
    ]);
  }

  get defaultSorts (): SortDescriptor {
    return [
      this.isSysAdmin ? {
        dataField: 'isAdmin',
        order: 'desc'
      } : {
        dataField: 'id',
        order: 'desc'
      }
    ];
  }
}
export class OEMAccountListModel implements AccountListModel {
  accounts: Array<Account>;

  constructor (accounts: Array<Account>) {
    this.accounts = accounts;
  }

  get noDataDescription (): string {
    return 'accounts.placeholder.noDataAvailable';
  }

  getColumnDefinition (columnName: string): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };
    return {
      ...sortableColumn(columnName === 'status' ? 'activated' : columnName, `accounts.headers.${columnName}`),
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    };
  }

  get columns () {
    return [
      renderColumn(this.getColumnDefinition('name'), oemNameFormatter),
      renderColumn(this.getColumnDefinition('email')),
      renderColumn(this.getColumnDefinition('companyName')),
      renderColumn(this.getColumnDefinition('status'), statusFormatter)
    ];
  }

  get defaultSorts (): SortDescriptor {
    return [{
      dataField: 'id',
      order: 'desc'
    }];
  }
}
