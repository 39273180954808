import React from 'react';
import { NativePreview } from '../FormContent/NativePreview';
import styles from './nativeMediaSummary.module.scss';
import { PreviewItem } from 'components/common/PreviewItem/PreviewItem';
import i18n from 'i18n';
import { NativeBanner } from 'containers/Creatives/NativeBannerPreview/NativeBanner';
import _ from 'lodash';

type mediaSummaryData = {
  enableNativeBanner: boolean,
  typeProperties: any,
  bannerUrl: string,
  nativeToBannertemplates: { [size: string]: string };
  medias: {
    rectImg: {
      url: string,
      file?: File
    },
    squareImg: {
      url: string,
      file?: File
    }
  }
};

export const NativeMediaSummary: React.FunctionComponent<{ mediaSummary: mediaSummaryData }> = props => {
  const bannerUrl = props.mediaSummary.bannerUrl;
  const typeProperties = props.mediaSummary.typeProperties;
  const medias = props.mediaSummary.medias;
  const nativeToBannertemplates = props.mediaSummary.nativeToBannertemplates;

  const renderBanners = () => {
    return Object.keys(nativeToBannertemplates).map((size) => {
      const htmlContent = _.get(nativeToBannertemplates[size], 'htmlContent', '');
      return (
        <PreviewItem key={size} itemName={size} itemWidth={400} itemHeight={300}>
          <NativeBanner
            size={size}
            typeProperties={typeProperties}
            medias={medias}
            bannerUrl={bannerUrl}
            htmlContent={htmlContent}
          />
        </PreviewItem>
      );
    });
  };

  return (
    props.mediaSummary.enableNativeBanner ?
      <>
        <div className={styles.title}>
          <div className={styles.titlePrefix} />
          {i18n.t<string>('nativeMediaSummary.titles.native')}
        </div>
        <div className={styles.nativePreview}>
          <NativePreview
            url={medias.rectImg.url}
            file={medias.rectImg.file}
            bannerUrl={bannerUrl}
            creativeValues={typeProperties}
          />
          <NativePreview
            square={true}
            url={medias.squareImg.url}
            file={medias.squareImg.file}
            bannerUrl={bannerUrl}
            creativeValues={typeProperties}
          />
        </div>
        <div className={styles.title}>
          <div className={styles.titlePrefix} />
          {i18n.t<string>('nativeMediaSummary.titles.nativeBanner')}
        </div>
        <div className={styles.nativeBannerPreview}>
          {renderBanners()}
        </div>
      </> :
      <div className={styles.nativePreview}>
        <NativePreview
          url={medias.rectImg.url}
          file={medias.rectImg.file}
          bannerUrl={bannerUrl}
          creativeValues={typeProperties}
        />
        <NativePreview
          square={true}
          url={medias.squareImg.url}
          file={medias.squareImg.file}
          bannerUrl={bannerUrl}
          creativeValues={typeProperties}
        />
      </div>
  );
};
