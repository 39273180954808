import { SelectOptions } from 'components/common/commonType';
import { AdLogo } from 'core/adLogo/AdLogo';
import { Pagination } from 'core/pagination/Pagination';
export type Agency = {
  readonly id: number;
  readonly name: string;
  readonly currency: string;
  readonly timeZone: string;
  readonly addOnFeatures: any;
  readonly isOEMAgency: boolean;
  readonly loginTitle: string | null;
  readonly loginDomain: string | null;
  readonly detail: AgencyDetail;
  readonly rebate: AgencyRebate;
};

export type AgencyWithPagination = {
  pagination: Pagination;
  agencies: Agency[];
};

export type AgencyDetail = {
  readonly addonFeatures: any;
  id?: number;
  addonProps: AddonProps;
  bidPriceBase: any;
  oem: boolean;
  companyName: string;
  language: Language;
  currency: Currency;
  timeZone: TimeZone;
  taxPercent: number;
  comComment: string;
  targetBudgetMinimum?: number;
  campaignBudgetMinimum?: number;
  priority: number;
  limitPublisherCountry: Array<{ name: string; value: string } | {}>;
  limitPublisher: Array<number>;
  limitAdx: Array<SelectOptions>;
  defaultCountryOp: string;
  agcDataPercent: number;
  agcPercent: number;
  agencyId?: number;
  defaultCountry: Array<{ name: string; value: string } | {}>;
  defaultSpace: Array<{ name: string; value: string } | {}>;
  defaultSpaceOp: string;
  logoUrl?: string;
  interstitial: number;
  limitAdxOp: string;
  noLimitSelfBuyStatus: boolean;
  segmentCountry: Array<SelectOptions>;
  segmentLabel: Array<SelectOptions>;
  selfBuy: boolean;
  sysDataPercent: number;
  sysPercent: number;
  sysPercentIn: number;
  sysPercentOut: number;
  adLogo: AdLogo;
  logoData?: {
    url?: string;
    file?: any;
  }
};

export type AddonProps = {
  creditLimit: number;
  fixedExchangeRate: number | null;
  maxOrderProfit: number;
  orderBudgetMaximum: number;
  outdoorAdImpresMultiply: number;
  sysProfitMonitorPercent: number;
  outdoorMachineCount?: number;
  campaignBidPrice?: {
    type: string;
    autoBidCap: {[key: string]: string};
    bidFloor: {[key: string]: string};
  }[]
};

export enum Currency {
  NTD = 'NTD',
  USD = 'USD',
  MYR = 'MYR',
  JPY = 'JPY',
  VND = 'VND',
  EUR = 'EUR',
  IDR = 'IDR'
}

export enum Language {
  ZHTW = 'zh_TW',
  ENUS = 'en_US'
}

export enum TimeZone {
  TOKYO = '+09:00',
  TAIPEI = '+08:00',
  HOCHIMINH = '+07:00' // combine with JAKARTA
}

export type AgencyRebate = {
  currentRate: number,
  effectDate?: string,
  futureRate?: number
};
