import display from 'assets/campaign/adType-display.png';
import display2x from 'assets/campaign/adType-display@2x.png';
import thirdParty from 'assets/campaign/adType-thirdParty.png';
import thirdParty2x from 'assets/campaign/adType-thirdParty@2x.png';
import { CampaignAdTypeButtonModel, DefaultCampaignAdTypeButtonModel } from 'components/CampaignAdTypeButton/CampaignAdTypeButtonModel';
import { AddonFeatureType } from 'core/agency/AddonFeature';
import { CreativeType } from 'core/creative/Creative';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { OrderType } from 'core/order/Order';
import { AD_TYPE_MAP_CREATIVE_TYPE, AdType } from 'core/rtbCampaign/RtbCampaign';
import i18n from 'i18next';
import _ from 'lodash';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { MessageCampaignSetupFlowPageModel } from '../MessageCampaignSetupFlowPageModel';

export const ORDER_TYPE_MAP_CAMPAIGN_TYPE = {
  [OrderType.GOJEK]: {
    [AdType.ONE_FOR_ALL_DISPLAY]: [CreativeType.ONE_FOR_ALL_DISPLAY, CreativeType.IMAGE]
  },
  [OrderType.TENMAX]: AD_TYPE_MAP_CREATIVE_TYPE
};

export const CHANNEL_MAP_CAMPAIGN_TYPE = {
  [L1ObjectChannel.MESSAGE]: [AdType.MESSAGE]
};

export interface ChooseMessageAdTypeStepModel {
  readonly event: UpdateEventListener<ChooseMessageAdTypeStepModel>;
  readonly state: ChooseMessageAdTypeStepState;
  readonly flowModel: MessageCampaignSetupFlowPageModel;
  readonly getAdTypeButtonModels: () => CampaignAdTypeButtonModel[];
  readonly showChangeAdTypeAlertModal: (confirmFuc) => void;
  init (): void;
  onAdTypeChange: (adType: AdType) => void;
  backToL1ObjectDetail: () => void;
}

export type ChooseMessageAdTypeStepProps = {
  readonly model: ChooseMessageAdTypeStepModel;
};

export type ChooseMessageAdTypeStepState = {
  readonly changeAdTypeAlertModal?: any;
};

export class DefaultChooseMessageAdTypeStepModel implements ChooseMessageAdTypeStepModel {
  event: FireableUpdateEventListener<ChooseMessageAdTypeStepModel>;
  adType?: AdType;
  changeAdTypeAlertModal?: any;
  flowModel: MessageCampaignSetupFlowPageModel;

  constructor (
    flowModel: MessageCampaignSetupFlowPageModel,
    private goNext: () => void
  ) {
    this.flowModel = flowModel;
    this.adType = flowModel.state.campaign.basic.adType;
    this.event = new FireableUpdateEventListener<ChooseMessageAdTypeStepModel>();
  }

  init () {
    this.onAdTypeChange(AdType.MESSAGE);
  }

  get state () {
    return {
      changeAdTypeAlertModal: this.changeAdTypeAlertModal
    };
  }

  get adTypeOptions () {
    const addonFeatureManager = this.flowModel.addonFeatureManager;
    const channel = _.get(this.flowModel.l1Object, 'channel');
    const orderType = _.get(this.flowModel.order, 'orderType');

    return Object.keys(AD_TYPE_MAP_CREATIVE_TYPE).reduce((allowAdType, adType) => {
      const allowCreativeTypes = AD_TYPE_MAP_CREATIVE_TYPE[adType];
      const allowOrderTypeAdTypes = ORDER_TYPE_MAP_CAMPAIGN_TYPE[orderType];
      const hasPermission = allowCreativeTypes.some((creativeType) => {
        if (creativeType === CreativeType.ONE_FOR_ALL) {
          return false;
        }
        if (!allowOrderTypeAdTypes[adType] || !allowOrderTypeAdTypes[adType].includes(creativeType)) {
          return false;
        }
        return addonFeatureManager.isFeatureEnable(`option_${creativeType}` as AddonFeatureType);
      });

      const channelCompatible = !(channel in CHANNEL_MAP_CAMPAIGN_TYPE) || CHANNEL_MAP_CAMPAIGN_TYPE[channel].includes(adType);
      if (hasPermission && channelCompatible) {
        allowAdType.push(AdType[adType]);
      }
      return allowAdType;
    }, [AdType.MESSAGE] as Array<AdType>);
  }

  getAdTypeButtonModels = () => {
    return this.adTypeOptions.map((adTypeOption) => {
      return new DefaultCampaignAdTypeButtonModel(
        this.getAdTypeButtonData(adTypeOption),
        adTypeOption === this.adType,
        _.partial(this.onAdTypeButtonClick, adTypeOption)
      );
    });
  }

  onAdTypeButtonClick = (adType: AdType) => {
    const campaign = this.flowModel.state.campaign;
    const currentAdType = _.get(campaign, 'basic.adType');
    if (currentAdType !== undefined && adType !== currentAdType) {
      this.showChangeAdTypeAlertModal(() => {
        this.onAdTypeChange(adType);
      });
    } else {
      this.onAdTypeChange(adType);
    }
  }

  onAdTypeChange (adType: AdType) {
    this.adType = adType;
    this.flowModel.onAdTypeChange(adType);
    this.goNext();
  }

  getAdTypeButtonData (adTypeOption: AdType) {
    switch (adTypeOption) {
      case AdType.MESSAGE:
        return {
          icon: thirdParty,
          icon2x: thirdParty2x,
          title: 'campaign.buttons.adTypeMessageTitle',
          description: 'campaign.buttons.adTypeMessageDesc'
        };
      default:
        return {
          icon: display,
          icon2x: display2x,
          title: 'campaign.buttons.adTypeDisplayTitle',
          description: 'Image / html 5 / Google Native / Dynamic / Product'
        };
    }
  }

  showChangeAdTypeAlertModal = (confirmFuc) => {
    this.changeAdTypeAlertModal = {
      title: i18n.t<string>('common.warning'),
      message: i18n.t<string>('campaign.descriptions.adTypeChangeAlert'),
      primaryBtn: {
        title: i18n.t<string>('common.buttons.ok'),
        callback: () => {
          confirmFuc();
          this.hideModal();
        }
      },
      secondaryBtn: {
        title: i18n.t<string>('common.buttons.cancel'),
        callback: this.hideModal
      }
    };
    this.updateState();
  }

  hideModal = () => {
    this.changeAdTypeAlertModal = undefined;
    this.updateState();
  }

  backToL1ObjectDetail = () => {
    this.flowModel.setFinishedRedirectPath(`/orders/${this.flowModel.order.orderNumber}/campaign-groups/${this.flowModel.l1Object.l1ObjectId}`);
  }

  updateState () {
    this.event.fireEvent(this);
  }
}
