import React, { Fragment } from 'react';
import styles from './creativeReviewItem.module.scss';
import { ADX, ApprovalStatus } from 'core/creative/Creative';
import i18n from 'i18next';
import { faHandPointUp } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import _ from 'lodash';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import cx from 'classnames/bind';
import { CopyableOverlayTrigger } from 'components/CopyableOverlayTrigger/CopyableOverlayTrigger';

export class CreativeReviewItem extends React.PureComponent<any, any> {

  canReviewAdxList: any;
  classNames: any;

  constructor (props) {
    super(props);
    this.state = {
      show: false,
      target: undefined
    };
    this.canReviewAdxList = [ADX.asiamax, ADX.doubleclick];
    this.classNames = cx.bind(styles);
  }

  reviewCreative = () => {
    this.props.reviewCreative(this.props.creativeId);
  }

  renderStateWarningContent = (header, content, footer) => {
    return (
      <div className={styles.warningContent}>
        <div>
          <span className={styles.warning}><FontAwesomeIcon icon={faExclamationTriangle}/></span>
          <span>{header}</span>
        </div>
        <div className={`${styles.section} ${styles.warningHint}`}>
          {content}
        </div>
        <div className={styles.section}>
          {footer}
        </div>
      </div>
    );
  }

  renderStateDetailInfo = (hasBanAttr, categoryChanged) => {
    const approvalData = this.props.approvalData;
    const stateDesc = i18n.t<string>(`creativeReview.labels.${ApprovalStatus[approvalData.state].toLowerCase()}`);
    const rejectReasons = _.get(approvalData, 'reason', []).map(reasonObj => reasonObj.reason);
    const rejectReasonsDom = rejectReasons.map(rejectReason => (
      <div key={rejectReason}>
        {rejectReason}
      </div>
    ));

    const approvalDataReviewDom = hasBanAttr ? approvalData.attr.map(attr => (
      <div key={attr}>
        {i18n.t<string>(`creativeReview.labels.banAttribute${attr}`)}
      </div>
    )) : undefined;

    return (
      <div className={styles.stateDetailInfo}>
        <div className={styles.section}>
          {`${i18n.t<string>('creativeReview.labels.stateTitle')} ${stateDesc}`}
        </div>
        {rejectReasons.length > 0 &&
          <div className={styles.section}>
            {rejectReasonsDom}
          </div>
        }
        {hasBanAttr &&
          this.renderStateWarningContent(
            i18n.t<string>('creativeReview.labels.partialBanTitle'),
            approvalDataReviewDom,
            i18n.t<string>('creativeReview.labels.partialBanHint')
          )
        }
        {categoryChanged &&
          this.renderStateWarningContent(
            i18n.t<string>('creativeReview.labels.tenmaxCategoryChange'),
            <Fragment>
              {`${i18n.t<string>('creativeReview.labels.tenmaxCategoryFrom')} ${i18n.t<string>(`tenmaxCategory.labels.${this.props.origTenmaxCategory}`)}`}
              <br/>
              {`${i18n.t<string>('creativeReview.labels.tenmaxCategoryTo')} ${i18n.t<string>(`tenmaxCategory.labels.${this.props.tenmaxCategory}`)}`}
            </Fragment>,
            i18n.t<string>('creativeReview.labels.categoryChangeHint')
          )
        }
        {approvalData.udt &&
          <div>
            {`${i18n.t<string>('creativeReview.labels.stateUpdateTime')} ${approvalData.udt}`}
          </div>
        }
      </div>
    );
  }

  renderState = (categoryChanged, hasBanAttr) => {
    const approvalData = this.props.approvalData;
    const stateDesc = i18n.t<string>(`creativeReview.labels.${ApprovalStatus[approvalData.state].toLowerCase()}`);
    const approvalContentClass = this.classNames('approvalContent', ApprovalStatus[approvalData.state].toLowerCase(), {
      marginLeft: this.props.showName
    });
    return (
      <CopyableOverlayTrigger
        placement='bottom'
        overlay={this.renderStateDetailInfo(hasBanAttr, categoryChanged)}
      >
        <span className={approvalContentClass}>
          {stateDesc}
        </span>
      </CopyableOverlayTrigger>
    );
  }

  render () {
    const categoryChanged = this.props.approvalData.state === ApprovalStatus.APPROVED && this.props.origTenmaxCategory;
    const hasBanAttr = this.props.approvalData.state === ApprovalStatus.APPROVED && this.props.approvalData.attr && this.props.approvalData.attr.length > 0;
    const needShowWarning = categoryChanged || hasBanAttr;
    const showReviewBtn = this.props.reviewCreative && this.props.approvalData.state !== ApprovalStatus.APPROVED && this.canReviewAdxList.indexOf(ADX[this.props.adx]) !== -1;
    const mainClass = this.classNames('creativeReview', this.props.className);
    return (
      <div className={mainClass}>
        {this.props.showName &&
          <span>
            {ADX[this.props.adx]}
          </span>
        }
        {this.renderState(categoryChanged, hasBanAttr)}
        {
          showReviewBtn &&
          <PermissionChecker permissionAware={hasFuncs(Permission.CREATIVE_WRITE)}>
            <IconWithTooltip
              className={styles.reviewBtn}
              onClick={this.reviewCreative}
              icon={faHandPointUp}
              tooltipProps={{
                id: `creativeReviewBtn-${this.props.creativeId}`,
                tooltip: i18n.t<string>('creativeReview.labels.reviewHint')
              }}
            />
          </PermissionChecker>
        }
        {
          needShowWarning &&
          <span className={styles.warning}><FontAwesomeIcon icon={faExclamationTriangle}/></span>
        }
      </div>
    );
  }
}
