import i18n from 'i18n';
import _ from 'lodash';
import { getByteLength } from './StringUtil';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';

export function validateEmpty (value) {
  if (
    value === null ||
    value === undefined ||
    (typeof value === 'string' && _.isEmpty(value)) ||
    (Array.isArray(value) && _.isEmpty(value))
  ) {
    return i18n.t<string>('formValidate.labels.emptyError');
  }
}

export function validateStringMaxLength (
  value: string,
  maxLength: number
) {
  let error = validateEmpty(value);
  if (error) {
    return error;
  }
  const stringLength = getByteLength(value);
  if (stringLength > maxLength) {
    return i18n.t<string>('formValidate.labels.remainingCharacters', { num: maxLength - stringLength });
  }
}

export function validateMinimum (
  value,
  minValue,
  i18nKey = 'formValidate.labels.minimumError',
  currency = ''
) {
  let error = validateEmpty(value);
  if (error) {
    return error;
  }

  const valueNumber = typeof value === 'string' ? parseFloat(value) : value;
  if (valueNumber < minValue) {
    let min = currency + minValue;
    return i18n.t<string>(i18nKey, { min });
  }
}

export function validateMinMax (value, min, max) {
  let error = validateMinimum(value, min);
  if (error) {
    return error;
  }

  const valueNumber = typeof value === 'string' ? parseFloat(value) : value;
  if (valueNumber > max) {
    return i18n.t<string>('formValidate.labels.maximumError', { max });
  }
}

export function validateMax (value, max, currency?: string, i18nKey = 'formValidate.labels.maximumError') {
  let error = validateEmpty(value);
  if (error) {
    return error;
  }

  const valueNumber = typeof value === 'string' ? parseFloat(value) : value;
  if (valueNumber > max) {
    return i18n.t<string>(i18nKey, { max: currency ? formatPriceWithCurrency(currency, max) : max });
  }
}

export function validateUrl (url) {
  let error = validateEmpty(url);
  if (error) {
    return error;
  }

  const urlExpression = /(^https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|^https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,})/;
  const regex = new RegExp(urlExpression);
  if (!url.match(regex)) {
    return i18n.t<string>('formValidate.labels.needUrl');
  }
}

export function validateEmail (email: string): string | undefined {
  if (_.isEmpty(email)) {
    return i18n.t<string>('formValidate.labels.emailRequired');
  }

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  if (!emailRegex.test(email)) {
    return i18n.t<string>('formValidate.labels.invalidEmail');
  }
  return undefined;
}

export function validateInteger (value) {
  let error = validateEmpty(value);
  if (error) {
    return error;
  }

  const valueNumber = typeof value === 'string' ? parseFloat(value) : value;
  if (!Number.isInteger(valueNumber)) {
    return i18n.t<string>('formValidate.labels.integerError');
  }
}
