import React from 'react';
import { FormikField } from 'components/common/form/field/FormikField';
import { FormikProps } from 'formik';
import i18n from 'i18n';
import { GoSegmentFormData } from 'core/goSegment/GoSegment';
import styles from './goSegmentForm.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faTimes } from '@fortawesome/free-solid-svg-icons';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import _ from 'lodash';

export const GoSegmentLogicFields: React.FC<{
  formType: string,
  formikProps: FormikProps<GoSegmentFormData>,
  extraData: any,
  hasExtraInfo: boolean,
  setExtraInfo: (extraInfo: any) => void
}> = ({
  formType,
  hasExtraInfo,
  extraData,
  setExtraInfo
}) => {

  const triggerExtraInfoVisible = () => {
    setExtraInfo(hasExtraInfo ? undefined : (
      <div className={styles.segmentHint}>
        <FontAwesomeIcon
          className={styles.closeBtn}
          icon={faTimes}
          onClick={_.partial(setExtraInfo, undefined)}
        />
        <div className={styles.segmentHintContent}>
          <SelectComponent
            model={extraData.selectComponentModel}
          />
        </div>
      </div>
    ));
  };

  return (
    formType === 'create' ?
      <FormikField.TextArea
        className={styles.logic}
        label={i18n.t<string>('goSegmentForm.fields.logic')}
        name='audienceBrief'
        placeholder={i18n.t<string>('goSegmentForm.hints.logicHint')}
        hint={
          <div className={styles.extraInfoBtn} onClick={triggerExtraInfoVisible}>
            {i18n.t<string>('goSegmentForm.hints.gojekSegmentList')}
            <FontAwesomeIcon icon={faInfoCircle}/>
          </div>
        }
      /> :
      <FormikField.Label
        label={i18n.t<string>('goSegmentForm.fields.logic')}
        name='audienceBrief'
      />
  );
};
