import React from 'react';
import { NativePreview } from '../FormContent/NativePreview';
import styles from './oneForAllDisplayMediaSummary.module.scss';
import _ from 'lodash';
import { useOneForAllDisplayPreview } from 'hooks/GoCreativePreview/useOneForAllDisplayPreview';
import { renderFBPreviews } from 'components/AdPreview/adPreviewRenderer';

export const OneForAllDisplayMediaSummary: React.FC<{ mediaSummary: any }> = (props) => {

  const bannerUrl = props.mediaSummary.bannerUrl;
  const typeProperties = props.mediaSummary.typeProperties;
  const medias = props.mediaSummary.medias;
  const squareImgFile = _.get(medias, 'squareImg.file');
  const squareImgUrl = squareImgFile ? URL.createObjectURL(squareImgFile) : _.get(medias, 'squareImg.url');
  const rectImgFile = _.get(medias, 'rectImg.file');
  const rectImgUrl = rectImgFile ? URL.createObjectURL(rectImgFile) : _.get(medias, 'rectImg.url');

  const {
    previewData
  } = useOneForAllDisplayPreview(
    props.mediaSummary.advertiserId,
    typeProperties,
    props.mediaSummary.medias
  );

  return (
    <div className={styles.oneForAllDisplayPreview}>
      <div className={styles.previewSection}>
        {renderFBPreviews(previewData, { height: 750 })}
      </div>
      <div className={styles.previewSection}>
        <NativePreview
          url={rectImgUrl || squareImgUrl}
          bannerUrl={bannerUrl}
          creativeValues={typeProperties}
        />
        <NativePreview
          square={true}
          url={squareImgUrl}
          bannerUrl={bannerUrl}
          creativeValues={typeProperties}
        />
      </div>
    </div>
  );
};
