import { ThirdPartyCreativePreview } from 'components/ThirdPartyCreativePreview/ThirdPartyCreativePreview';
import { CreativeBasic } from 'core/creative/Creative';
import _ from 'lodash';
import React from 'react';

export const PPSCreativeDetail: React.FunctionComponent<{creative: Partial<CreativeBasic>}> = (props) => {

  const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div/>;
  }
  const htmlSnippet = _.get(creativeValue, 'htmlSnippet', '');
  if (!htmlSnippet) {
    return <div/>;
  }

  const regularExpression = /"%%CLICK_URL_UNESC%%(.+?)"/g;
  const clickMacroMatch = htmlSnippet.matchAll(regularExpression);
  let htmlContent = htmlSnippet;
  for (const match of clickMacroMatch) {
    if (match[1]) {
      htmlContent = htmlContent.replace(match[1], decodeURIComponent(match[1]));
    }
  }
  htmlContent = htmlContent.replace(/%%WIDTH%%/g, "'20vw'")
                          .replace(/%%HEIGHT%%/g, "'30vh'")
                          .replace(/%%CLICK_URL_UNESC%%/g, '')
                          .replace(/%%VAST_URL_UNESC%%/g, '')
                          .replace(/%%VIEWABLE_IMPRESSION_URL_UNESC%%/g, '')
                          .replace(/%%SSP_VIEWABLE_IMPRESSION_URL_UNESC%%/g, '')
                          .replace(/%%ADX_VIEWABLE_IMPRESSION_URL_UNESC%%/g, '');

  return (
    <ThirdPartyCreativePreview iframeSrcDoc={htmlContent} showPreviewOptions={true} />
  );
};
