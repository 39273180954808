import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { NativeFormProps, MAX_ADTITLE_LENGTH, MAX_ADDESC_LENGTH, MAX_SPONSOR_LENGTH } from './NativeFormModel';
import styles from './nativeForm.module.scss';
import { NativeBannerPreview } from 'containers/Creatives/NativeBannerPreview/NativeBannerPreview';
import { FormConfig } from 'components/common/form/FormConfig';
import { FormContent } from 'components/common/form/Form';
import { DefaultNativeBannerPreviewModel } from 'containers/Creatives/NativeBannerPreview/NativeBannerPreviewModel';
import { validateUrl } from 'utils/ValidateUtils';

export const NativeForm: React.FunctionComponent<NativeFormProps & { formik: FormikContextType<any> }> = (props) => {

  const [formConfig, setFormConfig] = useState<FormConfig>(props.getInitFormConfig());

  const {
    defaultAdLogo,
    nativeToBannerTemplates,
    adDescriptionRemainLength,
    adTitleRemainLength,
    sponsorRemainLength,
    handleAdDescriptionChange,
    handleAdTitleChange,
    handleSponsorChange,
    validateRemainingCharacters,
    validateImage,
    onEnableNativeBannerChange
  } = props.model;

  const { setFieldValue } = props.formik;

  useEffect(() => {
    const handleEnableNativeBannerChange = (enableNativeBanner) => {
      setFieldValue('adLogo', enableNativeBanner ? defaultAdLogo : undefined);
      onEnableNativeBannerChange(enableNativeBanner);
    };
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.title'),
              name: 'typeProperties.title',
              hint: i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: adTitleRemainLength }),
              onChange: handleAdTitleChange,
              validate: value => validateRemainingCharacters(value, MAX_ADTITLE_LENGTH)
            })
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.desc'),
              name: 'typeProperties.desc',
              hint: i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: adDescriptionRemainLength }),
              onChange: handleAdDescriptionChange,
              validate: value => validateRemainingCharacters(value, MAX_ADDESC_LENGTH)
            })
            .addFormikInput({
              label: i18n.t<string>('creativeSetupFlow.labels.sponsor'),
              name: 'typeProperties.sponsor',
              hint: i18n.t<string>('creativeSetupFlow.labels.remainingCharacters', { num: sponsorRemainLength }),
              onChange: handleSponsorChange,
              validate: value => validateRemainingCharacters(value, MAX_SPONSOR_LENGTH)
            })
            .addFormikUrlInput({
              label: i18n.t<string>('creativeSetupFlow.labels.sponsorLink'),
              name: 'typeProperties.sponsorLink',
              validate: validateUrl
            })
            .addFormikFileInput({
              type: 'image',
              className: styles.rectImageInput,
              label: i18n.t<string>('creativeSetupFlow.labels.rectImg'),
              name: 'medias.rectImg',
              maxWidth: 382,
              maxHeight: 200,
              hints: [
                i18n.t<string>('creativeSetupFlow.labels.typeHint'),
                i18n.t<string>('creativeSetupFlow.labels.sizeHint', { size: '1200 x 627', proportion: '1.91:1' }),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
              ],
              validate: value => validateImage(value, 1200, 627)
            })
            .addFormikFileInput({
              type: 'image',
              className: styles.squareImageInput,
              label: i18n.t<string>('creativeSetupFlow.labels.squareImg'),
              name: 'medias.squareImg',
              maxWidth: 200,
              maxHeight: 200,
              hints: [
                i18n.t<string>('creativeSetupFlow.labels.typeHint'),
                i18n.t<string>('creativeSetupFlow.labels.sizeHint', { size: '627 x 627', proportion: '1:1' }),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
              ],
              validate: value => validateImage(value, 627, 627)
            })
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      )
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder()
          .addFormikSwitch({
            label: i18n.t<string>('nativeForm.labels.enableNativeBanner'),
            name: 'enableNativeBanner',
            disabled: props.formik.values.creativeId !== undefined,
            hoverHint: i18n.t<string>('nativeForm.labels.cannotModifyHint'),
            onChange: handleEnableNativeBannerChange
          })
          .addFormikCustom({
            withFieldLayout: false,
            name: 'preview',
            render: () => (
              <div className={styles.nativeBannerPreviewArea}>
                {<NativeBannerPreview model={new DefaultNativeBannerPreviewModel(props.formik.values, nativeToBannerTemplates)}/>}
              </div>
            )
          }, !props.formik.values.enableNativeBanner)
          .build()
        )
        .withTitle(i18n.t<string>('nativeForm.labels.nativeBannerTitle'))
        .build()
      )
      .build()
    );
  }, [
    props.basicFields,
    defaultAdLogo,
    props.formik.values,
    nativeToBannerTemplates,
    adDescriptionRemainLength,
    adTitleRemainLength,
    sponsorRemainLength,
    setFieldValue,
    handleAdDescriptionChange,
    handleAdTitleChange,
    handleSponsorChange,
    onEnableNativeBannerChange,
    validateRemainingCharacters,
    validateImage,
    setFormConfig
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(NativeForm);
