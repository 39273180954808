import React from 'react';
import styles from './videoCreativeDetail.module.scss';
import _ from 'lodash';
import { ThirdPartyVideoPreview } from 'components/common/Video/ThirdPartyVideoPreview';
import { CreativeListRecord } from 'core/creative/Creative';

export const VideoCreativeDetail: React.FunctionComponent<{creative: CreativeListRecord}> = (props) => {
  const MAX_WIDTH = 600;
  const MAX_HEIGHT = 400;
  const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div/>;
  }
  const isThirdParty = creativeValue.adServingUrl ? true : false;
  const source = isThirdParty ? creativeValue.adServingUrl as string : creativeValue.videoUrl as string;
  let width = creativeValue.w as number;
  let height = creativeValue.h as number;
  const ratio = height / width;
  if (width > MAX_WIDTH) {
    width = MAX_WIDTH;
    height = width * ratio;
  }
  if (height > MAX_HEIGHT) {
    height = MAX_HEIGHT;
    width = height / ratio;
  }
  return (
    <div className={styles.videoCreativeDetailContainer}>
      <div className={styles.videoCreativeDetail}>
        {isThirdParty ?
          <div className={styles.thirdVideoDetail}>
            <ThirdPartyVideoPreview url={source} canClick={true}/>
          </div> :
          <video controls width={width} height={height}>
            <source src={source} type='video/mp4'/>
          </video>
        }
      </div>
    </div>
  );
};
