import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { ReactElement, useCallback, useMemo, useRef, useState } from 'react';
import classnames from 'classnames/bind';
import { Modal } from 'components/common/Modal/Modal';
import { CustomField } from 'components/common/form/field/CustomField';
import { FormikField } from 'components/common/form/field/FormikField';
import { LabelField } from 'components/common/form/field/LabelField';
import { CLICK_URL_MACRO } from 'containers/MessageCampaigns/MessageCampaignSetupFlow/MessageCampaignForm/MessageCampaignBasicFormModel';
import { MessageFormModel } from 'containers/MessageCampaigns/MessageCampaignSetupFlow/MessageCampaignForm/MessageFormModel';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { RoleNames } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { MAX_HOUR, MIN_HOUR, MessageCampaignPlanType } from 'core/messageCampaign/MessageCampaign';
import { addOnEnabled, notRoles } from 'core/permission/PermissionDSL';
import i18n from 'i18n';
import _ from 'lodash';
import moment from 'moment';
import { Trans } from 'react-i18next';
import { getFieldErrors } from 'utils/FormikUtils';
import styles from './messageCampaignInfo.module.scss';
import { LoadingCircle } from 'components/LoadingCircle/LoadingCircle';

const cx = classnames.bind(styles);

export const MessageCampaignInfo: React.FunctionComponent<{
  model: MessageFormModel,
  formik: any,
  remainBudget: any,
  orderPriceMinimum: number,
  onChangeOrderPrice: (orderPrice: any) => void,
  onChangePriceModel: (priceModel: string) => void
}> = ({
  model,
  formik,
  remainBudget,
  orderPriceMinimum,
  onChangeOrderPrice,
  onChangePriceModel: handleChangePriceModel
}) => {

  const {
    state,
    orderName,
    minDate,
    maxDate,
    priceModelOptions,
    canEditPriceModel,
    currency,
    agencyProfit,
    campaignBasic: defaultCampaign,
    computeRemainCharacters,
    setClickUrlInputModalData,
    fetchInvalidKeywords,
    getInvalidMessageHint
  } = model;
  const formikValues = _.get(formik, 'values');
  const message = _.get(formikValues, 'message', '');
  const clickUrl = _.get(formikValues, 'clickUrl');
  const showOrderPriceField = _.get(formikValues, 'orderPriceEnable');
  const priceModel = _.get(formikValues, 'priceModel');
  const isNewCampaign = _.get(formikValues, 'id') === undefined || _.get(formikValues, 'isDraft', false);
  const isCampaignStart = !isNewCampaign && moment(defaultCampaign.startDate).isBefore(moment());
  const tags = _.get(formikValues, 'tags', []);
  const showOrderPriceHint = !getFieldErrors(formik, 'orderPrice');
  const showBudgetHint = !getFieldErrors(formik, 'budget');
  const onChangeDate = useCallback((startDate: string) => {
    formik.setFieldValue('endDate', moment(startDate).endOf('day').format('YYYY-MM-DD HH:mm:ss'));
  }, [formik]);

  const [validatedMessageContent, setValidatedMessageContent] = useState<string | undefined>(undefined);
  const messageRef = useRef<HTMLTextAreaElement>(null);
  const clickUrlRef = useRef<string>(clickUrl);
  const canInsertShortUrl = useMemo(() => !message.includes(CLICK_URL_MACRO), [message]);

  const validateMessageContent = useCallback(async (message: string, clickUrl: string) => {
    const content = message.replace(CLICK_URL_MACRO, clickUrl);
    if (content && content !== validatedMessageContent) {
      formik.setFieldError('message', undefined);
      await fetchInvalidKeywords(content);
      setValidatedMessageContent(content);
      const invalidMessageHint = getInvalidMessageHint();
      if (invalidMessageHint) {
        formik.setFieldError('message', invalidMessageHint);
      } else {
        formik.setFieldTouched('message', true);
        return;
      }
    }
  }, [fetchInvalidKeywords, getInvalidMessageHint, validatedMessageContent, formik]);

  const handleClickUrlButton = useCallback(() => {
    const onCloseClickUrlField = () => {
      formik.setFieldValue('clickUrl', clickUrl);
      setClickUrlInputModalData(undefined);
      validateMessageContent(message, clickUrl);
    };
    const onConfirmClickUrlField = () => {
      const pos = messageRef.current ? messageRef.current.selectionStart : undefined;
      if (pos === undefined) {
        return;
      }
      const newMessage = `${message.slice(0, pos)}${CLICK_URL_MACRO}${message.slice(pos)}`;
      const currentClickUrl = clickUrlRef.current ? clickUrlRef.current : clickUrl;
      formik.setFieldValue('message', newMessage);
      setClickUrlInputModalData(undefined);
      validateMessageContent(newMessage, currentClickUrl);
    };
    setClickUrlInputModalData({
      title: i18n.t<string>('messageCampaignInfo.labels.insertShortUrl'),
      dismiss: onCloseClickUrlField,
      onConfirm: onConfirmClickUrlField,
      onCancel: onCloseClickUrlField
    });
  }, [formik, clickUrl, message, setClickUrlInputModalData, validateMessageContent]);

  const handleOnBlurMessage = useCallback(async (event: React.FocusEvent<HTMLInputElement>) => {
    validateMessageContent(event.target.value, clickUrl);
  }, [validateMessageContent, clickUrl]);

  const handleOnChangeClickUrl = useCallback((clickUrl: string) => {
    clickUrlRef.current = clickUrl;
  }, [clickUrlRef]);

  const renderOrderPrice = useCallback((priceModel: MessageCampaignPlanType, canEditPriceModel: boolean) => {
    switch (priceModel) {
      case MessageCampaignPlanType.FIXED_VIEWABLE_IMPRESSION:
        const price = `${currency}${orderPriceMinimum}`;
        const tips = showOrderPriceHint
          ? <Trans i18nKey='campaign.descriptions.orderPriceTips'>
              The price have to over <span className='text-dark'>{{ price }}</span>
            </Trans>
          : '';
        const label =
          i18n.t<string>(`campaign.labels.${priceModel}`) +
          i18n.t<string>('campaign.labels.orderPrice');
        return canEditPriceModel ? (
          <FormikField.InputGroup
            label={label}
            name='orderPrice'
            prefix={currency}
            hint={tips}
            type='number'
            fieldContentWidth={184}
            onChange={onChangeOrderPrice}
          />
        ) : (
          <FormikField.InputGroup
            as='label'
            label={label}
            name='orderPrice'
            prefix={currency}
            prefixBorder={false}
            type='number'
            className={styles.priceLabel}
          />
        );
      default:
        return;
    }
  }, [currency, orderPriceMinimum, showOrderPriceHint, onChangeOrderPrice]);

  const renderTargetBudgetInput = useCallback(() => {
    let hint: ReactElement | undefined;
    if (showBudgetHint) {
      hint = (
        <Trans i18nKey='campaign.descriptions.remainBudget'>
          Remain budget <span className='text-dark'><>{{ currency }}{{ remainBudget }}</></span>
        </Trans>
      );
    }

    const inputProps = {
      label: i18n.t<string>('campaignInfo.labels.totalBudget'),
      prefix: currency,
      name: 'budget',
      type: 'number',
      min: 0,
      autoComplete: 'off',
      onBlur: () => {},
      onChange: () => {},
      hint,
      fieldContentWidth: 184
    };

    return (
      <FormikField.InputGroup {...inputProps}/>
    );
  }, [currency, remainBudget, showBudgetHint]);

  const renderClickUrlInput = useCallback((clickUrlInputModalData) => {
    const { title, dismiss, onConfirm, onCancel } = clickUrlInputModalData;
    return (
      <Modal
        title={title}
        dismiss={dismiss}
        primaryButton={{
          title: i18n.t<string>('common.buttons.confirm'),
          callback: onConfirm,
          disabled: !!formik.errors.clickUrl
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.cancel'),
          callback: onCancel
        }}
      >
        <div tabIndex={0} className={styles.clickUrlInput}>
          <FormikField.UrlInput
            label={i18n.t<string>('messageCampaignInfo.labels.clickUrl')}
            name='clickUrl'
            onChange={handleOnChangeClickUrl}
            fieldContentWidth={320}
          />
        </div>
      </Modal>
    );
  }, [formik.errors, handleOnChangeClickUrl]);

  const dateRangePickerClassName = cx('componentWithTips', {
    disableStart: isCampaignStart
  });
  const priceModelClassName = cx('priceModel', {
    'componentWithTips': true
  });
  const clickUrlOpenButtonClassName = cx('clickUrlOpenButton', {
    disabled: !canInsertShortUrl
  });
  const priceModelFormatter = useCallback((priceModel: any) => i18n.t<string>(`campaign.labels.${priceModel}`), []);

  return (
    <fieldset>
      {state.clickUrlInputModalData && renderClickUrlInput(state.clickUrlInputModalData)}
      <legend>
        <span>{i18n.t<string>('campaignInfo.labels.title')}</span>
      </legend>
      <div className={styles.component}>
        <LabelField
          label={i18n.t<string>('campaignInfo.labels.order')}
          name='orderName'
          value={orderName}
        />
        <FormikField.Input
          label={i18n.t<string>('campaignInfo.labels.campaignName')}
          name='name'
        />
        {isCampaignStart ?
          <FormikField.Label
            label={i18n.t<string>('campaignInfo.labels.deliverDate')}
            name='startDate'
          /> :
          <FormikField.DatePicker
            label={i18n.t<string>('campaignInfo.labels.deliverDate')}
            className={dateRangePickerClassName}
            name='startDate'
            showTimePicker={true}
            timeInterval={60}
            minDate={minDate}
            maxDate={maxDate}
            minHour={MIN_HOUR}
            maxHour={MAX_HOUR}
            onChange={onChangeDate}
          />
        }
        {isCampaignStart ?
          <FormikField.Label
            label={i18n.t<string>('campaignInfo.labels.message')}
            name='message'
          /> :
          <FormikField.TextArea
            label={i18n.t<string>('campaignInfo.labels.message')}
            name='message'
            innerRef={messageRef}
            onBlur={handleOnBlurMessage}
            hint={state.validatingMessageContent
              ? <div className={styles.messageHint}>
                  <LoadingCircle className={styles.loadingCircle} width={15} height={15} />
                  <span>
                    {i18n.t<string>('messageCampaignSetupFlow.descriptions.validatingContent')}
                  </span>
                </div>
              : i18n.t<string>('campaignInfo.labels.messageHint', {
                number: computeRemainCharacters(message, clickUrl)
              })}
          />
        }
        {!isCampaignStart &&
          <CustomField
            label=''
            formGroupClassName={styles.selectedClickUrlFormGroup}
          >
            <div className={styles.clickUrlContainer}>
              <div className={clickUrlOpenButtonClassName}>
                <button
                  type='button'
                  className='btn'
                  disabled={!canInsertShortUrl}
                  onClick={handleClickUrlButton}
                >
                  <FontAwesomeIcon
                    icon={faPencilAlt}
                    size='sm'
                  />
                  {i18n.t<string>('messageCampaignInfo.labels.insertShortUrl')}
                </button>
              </div>
              <FormikField.Label
                isFlexibleContent={true}
                fieldContentWidth={0}
                name='clickUrlField'
              />
            </div>
          </CustomField>
        }
        {canEditPriceModel ?
          <FormikField.Select
            className={priceModelClassName}
            name='priceModel'
            label={i18n.t<string>('campaignInfo.labels.priceModel')}
            simpleValue
            options={priceModelOptions}
            onChange={handleChangePriceModel}
            hoverHint={i18n.t<string>('campaignInfo.labels.cannotModifyPriceModelHint')}
          /> :
          <FormikField.Label
            name='priceModel'
            label={i18n.t<string>('campaignInfo.labels.priceModel')}
            formatter={priceModelFormatter}
          />
        }
        {showOrderPriceField && renderOrderPrice(priceModel, canEditPriceModel)}
        {renderTargetBudgetInput()}
        <FormikField.Tags
          label={i18n.t<string>('campaign.labels.tags')}
          name='tags'
          placeholder={tags.length === 0 ? i18n.t<string>('campaign.placeholders.tags') : ''}
        />
        <PermissionChecker
          permissionAware={
            notRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales)
              .and(addOnEnabled(ADDONFEATURE.CAMPAIGN.ORDER_AGENCY_PROFIT_SETTING))
          }
        >
          <LabelField
            label={i18n.t<string>('campaign.labels.agencyProfit')}
            name='agencyProfit'
            value={agencyProfit}
          />
        </PermissionChecker>
      </div>
    </fieldset>
  );
};
