import React from 'react';
import _ from 'lodash';
import { CreativeBasic } from 'core/creative/Creative';
import { ThirdPartyCreativePreview } from 'components/ThirdPartyCreativePreview/ThirdPartyCreativePreview';

export const CustomLayoutCreativeDetail: React.FunctionComponent<{creative: Partial<CreativeBasic>}> = (props) => {

  const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div/>;
  }
  const htmlSnippet = _.get(creativeValue, 'htmlSnippet', '');
  if (!htmlSnippet) {
    return <div/>;
  }
  const bannerExtra = _.get(props.creative, 'bannerExtra', {});

  const regularExpression = /"%%CLICK_URL_UNESC%%(.+?)"/g;
  const clickMacroMatch = htmlSnippet.matchAll(regularExpression);
  let htmlContent = htmlSnippet;
  let defaultDeviceType = _.get(bannerExtra, 'device', undefined);
  let currentTime = new Date().getTime();
  for (const match of clickMacroMatch) {
    if (match[1]) {
      htmlContent = htmlContent.replace(match[1], decodeURIComponent(match[1]));
    }
  }
  htmlContent = htmlContent.replace(/%%WIDTH%%/g, "'20vw'")
                          .replace(/%%HEIGHT%%/g, "'30vh'")
                          .replace(/%%CLICK_URL_UNESC%%/g, '')
                          .replace(/%%VAST_URL_UNESC%%/g, '')
                          .replace(/%%VIEWABLE_IMPRESSION_URL_UNESC%%/g, '')
                          .replace(/%%SSP_VIEWABLE_IMPRESSION_URL_UNESC%%/g, '')
                          .replace(/%%ADX_VIEWABLE_IMPRESSION_URL_UNESC%%/g, '');
  htmlContent = htmlContent.replace(/\.js/g, `.js?v=${currentTime}`)
                          .replace(/\.css/g, `.css?v=${currentTime}`)
                          .replace(/\.html/g, `.html?v=${currentTime}`)
                          .replace(/\.txt/g, `.txt?v=${currentTime}`)
                          .replace(/\.json/g, `.json?v=${currentTime}`);

  htmlContent = `<html><body><div style="display: none;"><iframe srcdoc="${_.escape(htmlContent)}"></iframe></div></body></html>`;

  return (
    <ThirdPartyCreativePreview iframeSrcDoc={htmlContent} defaultDeviceType={defaultDeviceType} showPreviewOptions={false} />
  );
};
