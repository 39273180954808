import React from 'react';
import SummaryDetail from 'components/SummaryDetail/SummaryDetail';
import styles from './rtbCampaignSummaryStep.module.scss';
import { RtbCampaignSummaryStepProps, RtbCampaignSummaryStepState } from './RtbCampaignSummaryStepModel';
import { Button, Tooltip } from 'react-bootstrap';
import i18n from 'i18n';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { TAInfoCard } from 'components/TAInfoCard/TAInfoCard';
import _ from 'lodash';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { alertMessage } from 'components/AlertDialog';
import { withOverlayHoverTip } from 'hoc/withOverlayHoverTip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { LegacyEstimateViewModelData } from 'core/rtbCampaign/RtbCampaignManager';

export class RtbCampaignSummaryStep extends React.Component<RtbCampaignSummaryStepProps, RtbCampaignSummaryStepState> {

  handler?: number;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.getLegacyEstimateData();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  renderSummaryDetails = (data) => {
    return Object.keys(data).map((key) =>
      data[key] ?
      <SummaryDetail
        key={key}
        title={data[key].title}
        data={data[key].content}
        prefixColor={data[key].titlePrefixColor}
      /> : <div key={key}/>
    );
  }

  renderSummaryTitle = (summaryData) => {
    return (
      <div className={styles.categoryTitle}>
        {summaryData.title}
        { summaryData.backStep !== undefined &&
          <div className={styles.backBtn} onClick={this.props.model.goStep.bind(undefined, summaryData.backStep, summaryData.backSubStep)}>
            {i18n.t<string>('campaignSummary.labels.backButtonTitle')}
          </div>
        }
      </div>
    );
  }

  renderSummary = (summaryData, renderOtherInfo?: any) => {
    if (!summaryData) {
      return <div/>;
    }
    return (
      <section>
        {this.renderSummaryTitle(summaryData)}
        <div>
          {this.renderSummaryDetails(summaryData.data)}
        </div>
        {renderOtherInfo && renderOtherInfo()}
      </section>
    );
  }

  renderLegacyEstimateData () {
    const data = this.props.model.state.legacyEstimateData;
    const onShowAddSaveTargetingModal = this.props.model.onShowAddSaveTargetingModal;
    return (
      <div className={styles.estimateData}>
        {data ?
          <>
            {this.renderLegacyEstimateDataOfField('uniqueUser', data)}
            {this.renderLegacyEstimateDataOfField('impression', data)}
            {data.involvedLimitatinoTypes.length > 0 &&
              <div className={styles.estimateLimitation}>
                <div className={styles.estimateLimitationDesc}>
                  {i18n.t<string>('campaignSummary.labels.estimateDesc')}
                </div>
                {this.renderLegacyEstimateLimitations(data.involvedLimitatinoTypes)}
              </div>
            }
          </> :
          <div className={styles.estimateLimitationDesc}>
            {this.props.model.noLegacyEstimateDes}
          </div>
        }
        <>
          <div className={styles.saveHint}>{i18n.t<string>('taInfoCard.labels.saveTargeting')}</div>
          <button className={'btn btn-secondary btn-sm'} onClick={_.partial(onShowAddSaveTargetingModal, true)}>
            {i18n.t<string>('taInfoCard.buttons.saveTargeting')}
          </button>
        </>
      </div>
    );
  }

  renderLegacyEstimateDataOfField (field: string, legacyEstimateData: LegacyEstimateViewModelData) {
    const InformationButtion = withOverlayHoverTip(
      FontAwesomeIcon, (
        <Tooltip id={field}>
          {i18n.t<string>(`campaignSummary.labels.${field}Information`)}
        </Tooltip>
      )
    );
    const data = legacyEstimateData[field];
    return (
      <div className={styles.estimateDataField}>
        <div className={styles.estimateDataTitle}>
          {i18n.t<string>(`campaignSummary.labels.${field}Title`)}
          <InformationButtion icon={faInfoCircle} placement='right'/>
        </div>
        <div className={styles.estimateDataContent}>
          {data >= 50 ? data : (
            <div className={styles.estimateLowHint}>
              <div>{'<50'}</div>
              <div>{i18n.t<string>('campaignSummary.labels.estimateLowHint')}</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  renderLegacyEstimateLimitations (involvedLimitatinoTypes: string[]) {
    return (
      <ul>
        {involvedLimitatinoTypes.map(type => (
          <li key={type}>
            {i18n.t<string>(`limitation.labels.${type}`)}
          </li>
        ))}
      </ul>
    );
  }

  render () {
    const model = this.props.model;
    const estimatedAudience = model.estimatedAudience;
    const objectType = model.flowModel.objectType;
    const onSaveDraft = model.flowModel.onSaveDraft;
    const draftBindingSummary = model.getCreativeBindingSummaryData();
    return (
      <div className={styles.container}>
        {model.state.loading && <LoadingIndicator />}
        {model.state.showPublishBindingFailed && alertMessage(
          i18n.t<string>('common.warning'), i18n.t<string>('campaignSetupFlow.messages.publishBindingFailed'), model.redirectToLastPage
        )}
        <div className={styles.title}>
          {i18n.t<string>('campaignSummary.titles.checkYourSettings')}
        </div>
        <div className={styles.content}>
          {this.renderSummary(model.getAdTypeSummaryData())}
          <div className={styles.sepLine} />
          <div className={styles.basicSummary}>
            {this.renderSummary(model.getSetUpSummaryData())}
            {model.showLegacyEstimation && this.renderLegacyEstimateData()}
          </div>
          <div className={styles.sepLine} />
          {this.renderSummary(
            model.getLimitationsSummaryData(),
            () => (
              <TAInfoCard
                orderType={model.order.orderType}
                channel={_.get(model.l1Object, 'channel', L1ObjectChannel.RTB)}
                advertiserId={model.order.advertiserId}
                appliedSavedTAInfo={model.appliedSavedTAInfo}
                targetingValue={model.targetingValue}
                estimatedAudience={estimatedAudience}
                audienceLowestThreshold={model.audienceLowestThreshold}
                savedTargetingList={model.savedTargetingList}
                showAddSaveTargetingModal={model.state.showAddSaveTargetingModal}
                onShowAddSaveTargetingModal={model.onShowAddSaveTargetingModal}
              />
            )
          )}
          {model.flowModel.needSetupTracking &&
            <>
              <div className={styles.sepLine} />
              {this.renderSummary(model.getTrackingSummaryData())}
            </>
          }
          {draftBindingSummary &&
            <>
              <div className={styles.sepLine} />
              {this.renderSummary(draftBindingSummary)}
            </>
          }
        </div>
        <div className={styles.buttonArea}>
          <Button variant='primary' size='sm' onClick={model.submit}>
            {objectType === 'campaign' ? i18n.t<string>('common.buttons.submit') : i18n.t<string>('common.buttons.publish')}
          </Button>
          {onSaveDraft && (
            <Button
              variant='secondary'
              size='sm'
              onClick={_.partial(onSaveDraft, model.flowModel.state.campaign)}
            >
              {i18n.t<string>('campaign.buttons.saveDraft')}
            </Button>
          )}
          <Button variant='secondary' size='sm' onClick={model.goLast}>
            {i18n.t<string>('campaign.buttons.back')}
          </Button>
        </div>
      </div>
    );
  }
}
