import React from 'react';
import _ from 'lodash';
import styles from './imageCreativeDetail.module.scss';
import { CreativeBasic } from 'core/creative/Creative';
import { ImagePreview } from 'components/common/Image/ImagePreview';

export const ImageCreativeDetail: React.FunctionComponent<{creative: Partial<CreativeBasic>}> = (props) => {
  const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div/>;
  }
  const imageUrl = _.get(creativeValue, 'imageUrl', {});
  const width = _.get(creativeValue, 'w', {});
  const height = _.get(creativeValue, 'h', {});
  const bannerUrl = _.get(props.creative, 'bannerUrl', '');
  const openBannerTarget = () => {
    window.open(bannerUrl, '_blank', 'noopener');
  };
  return (
    <div className={styles.imageCreativeDetailContainer}>
      <div className={styles.imageCreativeDetail} onClick={openBannerTarget}>
        <ImagePreview
          size={{ width, height }}
          border={false}
          url={imageUrl}
          config={{
            showName: false,
            showSize: false
          }}
        />
      </div>
    </div>
  );
};
