import React from 'react';
import styles from './chooseCreateMethodStep.module.scss';
import i18n from 'i18n';
import { GoSegmentFormData, GoSegmentType } from 'core/goSegment/GoSegment';
import cx from 'classnames/bind';
import _ from 'lodash';
import inputAudienceBrief from 'assets/input_audience_brief.png';
import inputAudienceBrief2x from 'assets/input_audience_brief_2x.png';
import searchTA from 'assets/search_ta.png';
import searchTA2x from 'assets/search_ta_2x.png';
import uploadCsv from 'assets/upload_csv.png';
import uploadCsv2x from 'assets/upload_csv_2x.png';

export interface ChooseCreateMethodStepProps {
  initGoSegment?: GoSegmentFormData;
  onGoSegmentTypeChange: (segmentType: GoSegmentType) => void;
  goNext: () => void;
}

const classNames = cx.bind(styles);

export const ChooseCreateMethodStep = ({
  initGoSegment,
  onGoSegmentTypeChange,
  goNext
}: ChooseCreateMethodStepProps) => {

  const iconMap = {
    [GoSegmentType.UPLOAD]: {
      icon: uploadCsv,
      icon2x: uploadCsv2x
    },
    [GoSegmentType.MANUAL]: {
      icon: searchTA,
      icon2x: searchTA2x
    },
    [GoSegmentType.CUSTOM]: {
      icon: inputAudienceBrief,
      icon2x: inputAudienceBrief2x
    }
  };

  const renderButtons = () => {
    const buttons = Object.values(_.omit(GoSegmentType, GoSegmentType.LOOKALIKE)).map(type => {
      const iconSet = iconMap[type];
      const onClick = () => {
        onGoSegmentTypeChange(type);
        goNext();
      };
      const buttonClassName = classNames('queryMethodBtn', {
        selected: type === _.get(initGoSegment, 'type')
      });
      return (
        <div key={type} className={buttonClassName} onClick={onClick}>
          <img src={iconSet.icon} srcSet={`${iconSet.icon2x} 2x`} alt='logo' />
          {i18n.t<string>(`goSegmentSetupFlow.buttons.${type.toLowerCase()}Method`)}
        </div>
      );
    });
    return (<>{buttons}</>);
  };

  return (
    <div className={styles.chooseCreateMethodStep}>
      <div className={styles.title}>
        {i18n.t<string>('goSegmentSetupFlow.methodStep.title')}
      </div>
      <div className={styles.buttonArea}>
        <div className={styles.buttons}>
          {renderButtons()}
        </div>
      </div>
    </div>
  );
};
