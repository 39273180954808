import React from 'react';
import { AdPreview } from './AdPreview';
import { defaultTo, get } from 'lodash';

export const renderFBPreviews = (fbPreviewData, iframeConfig?: any) => {
  return Object.keys(fbPreviewData).map(previewType => (
    <AdPreview
      key={previewType}
      iframeString={fbPreviewData[previewType]}
      iframeConfig={iframeConfig}
    />
  ));
};

export const renderTiktokPreviews = (tiktokPreviewData, iframeConfig?: any) => {
  const defaultIframeConfig = {
    width: 300,
    height: 520,
    style: {
      border: 'none'
    },
    ...defaultTo(iframeConfig, {})
  };
  return Object.keys(tiktokPreviewData).map(previewType => (
    <AdPreview
      key={previewType}
      iframeString={get(tiktokPreviewData[previewType], 'iframe', '')}
      iframeConfig={defaultIframeConfig}
    />
  ));
};
