import { AddonFeatureManager, LocaleMeta } from 'core';
import { L1Object } from 'core/l1Object/L1Object';
import { MessageCampaignBasic, MessageCampaignPlanType } from 'core/messageCampaign/MessageCampaign';
import { Order } from 'core/order/Order';
import { CampaignState, RtbOptimize } from 'core/rtbCampaign/RtbCampaign';
import { DefaultRtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import i18n from 'i18n';
import _ from 'lodash';
import { validateEmpty, validateMinimum, validateUrl } from 'utils/ValidateUtils';
import { renderOverBudgetWording } from '../../../L2Objects/FormHintRenderFunctions';
import { CLICK_URL_MACRO, MessageCampaignBasicFormModel } from './MessageCampaignBasicFormModel';

export interface MessageCampaignBasicFormValidator {
  validate (
    l1Object: L1Object | undefined,
    campaignBasic: Partial<MessageCampaignBasic>,
    order: Order,
    otherCampaignCost: number,
    localeMeta?: LocaleMeta
  ): any;
}

export class DefaultMessageCampaignBasicFormValidator implements MessageCampaignBasicFormValidator {

  bidFloorGetter: (optimize: RtbOptimize, localeMeta?: LocaleMeta) => number;
  addonFeatureManager: AddonFeatureManager;
  validateStrategies: any;
  constructor (
    private model: MessageCampaignBasicFormModel,
    protected action: string,
    protected order: Order,
    protected defaultCampaign: any,
    protected campaignBasic: MessageCampaignBasic,
    bidFloorGetter: (optimize: RtbOptimize | MessageCampaignPlanType, localeMeta?: LocaleMeta) => number,
    addonFeatureManager: AddonFeatureManager
  ) {
    this.bidFloorGetter = bidFloorGetter;
    this.addonFeatureManager = addonFeatureManager;
    this.validateStrategies = {
      [MessageCampaignPlanType.FIXED_VIEWABLE_IMPRESSION]: this.validateNormalCampaign.bind(this)
    };
  }

  validate (l1Object: L1Object | undefined, campaignBasic: MessageCampaignBasic, order: Order, otherCampaignCost: number, localeMeta?: LocaleMeta) {
    const errors = this.validateStrategies[campaignBasic.priceModel](l1Object, campaignBasic, order, otherCampaignCost, localeMeta);
    return _.omitBy(errors, _.isEmpty);
  }

  validateBasic (l1Object: L1Object | undefined, campaignBasic: MessageCampaignBasic) {
    return {
      name: this.validateName(campaignBasic),
      message: this.validateMessage(campaignBasic),
      clickUrl: this.validateClickUrl(campaignBasic),
      clickUrlField: this.validateClickUrlField(campaignBasic)
    };
  }

  validateNormalCampaign (l1Object: L1Object | undefined, campaignBasic: MessageCampaignBasic, order: Order, isDailySchedule: boolean, otherCampaignCost: number, localeMeta?: LocaleMeta) {
    return {
      ...this.validateBasic(l1Object, campaignBasic),
      orderPrice: this.validateOrderPrice(campaignBasic, order.currency),
      budget: this.validateBudget(l1Object, campaignBasic, order, otherCampaignCost)
    };
  }

  validateMessage (campaignBasic: MessageCampaignBasic) {
    const message = campaignBasic.message;
    const clickUrl = campaignBasic.clickUrl;
    const clickUrlInputModalData = this.model.state.clickUrlInputModalData;
    const invalidMessageHint = this.model.getInvalidMessageHint();
    let error = validateEmpty(message);
    if (error) {
      return error;
    }
    if (this.model.computeRemainCharacters(message, clickUrl) < 0) {
      return i18n.t<string>('campaign.descriptions.messageLengthError');
    }
    if (message && invalidMessageHint) {
      return invalidMessageHint;
    }
    // message should contain only and exactly one CLICK_URL_MACRO
    if (message && clickUrl && !clickUrlInputModalData && message.split(CLICK_URL_MACRO).length - 1 !== 1) {
      return i18n.t<string>('campaign.descriptions.messageClickUrlMacroError');
    }
  }

  validateClickUrl (campaignBasic: MessageCampaignBasic) {
    return validateUrl(campaignBasic.clickUrl);
  }

  validateClickUrlField (campaignBasic: MessageCampaignBasic) {
    let error = validateEmpty(campaignBasic.clickUrl);
    if (error) {
      return i18n.t<string>('campaign.descriptions.clickUrlFieldEmpty');
    }
  }

  validateName (campaignBasic: MessageCampaignBasic) {
    const name = campaignBasic.name;
    let error = validateEmpty(name);
    if (error) {
      return error;
    }
    if (name.length > 255) {
      return i18n.t<string>('campaign.descriptions.lengthError');
    }
  }

  validateOrderPrice (campaignBasic, currency: string) {
    if (!campaignBasic.orderPriceEnable) {
      return;
    }
    let orderPriceMinimum = this.bidFloorGetter(campaignBasic.priceModel);
    orderPriceMinimum = orderPriceMinimum ? orderPriceMinimum : 0;
    return validateMinimum(
      campaignBasic.orderPrice,
      orderPriceMinimum,
      'campaign.descriptions.priceMinimum',
      currency
    );
  }

  validateBudget (l1Object: L1Object | undefined, campaignBasic, order: Order, otherCampaignCost: number) {
    const budget = campaignBasic.budget;
    const rtbCampaignManager = new DefaultRtbCampaignManager();
    const minBudgetOfCampaign = rtbCampaignManager.getMinBudgetOfCampaign(campaignBasic, order.campaignConstraint.budgetMinimum);
    if (budget < 0) {
      return validateMinimum(
        budget,
        0,
        'campaign.descriptions.smallerThanBudgetZero',
        order.currency
      );
    }

    if (this.action === 'create') {
      return this.validateBudgetOfCreateAction(l1Object, campaignBasic, order, otherCampaignCost, minBudgetOfCampaign);
    }
    return this.validateBudgetOfEditAction(l1Object, campaignBasic, order, otherCampaignCost, minBudgetOfCampaign);
  }

  validateBudgetOfCreateAction (
    l1Object: L1Object | undefined,
    campaignBasic: MessageCampaignBasic,
    order: Order,
    otherCampaignCost: number,
    minBudgetOfCampaign: number
  ) {
    const budget = campaignBasic.budget;
    const l1ObjectAutoOptimise = _.get(l1Object, 'autoOptimise', false);
    const l1ObjectBudget = +_.defaultTo(_.get(l1Object, 'budget'), 0);
    if (l1ObjectAutoOptimise) {
      const l1ObjectMinBudget = otherCampaignCost + Math.max(minBudgetOfCampaign, budget);
      if (l1ObjectMinBudget > l1ObjectBudget) {
        return i18n.t<string>('campaign.descriptions.minL1ObjectBudget', { budget: formatPriceWithCurrency(order.currency, l1ObjectMinBudget) });
      }
      return;
    }
    const totalBudget = order.budgetBalance;
    const remainBudget = totalBudget - budget;
    if (remainBudget < 0) {
      return renderOverBudgetWording(order.currency, totalBudget);
    }

    return this.validateBudgetBasic(campaignBasic, l1Object, order, minBudgetOfCampaign);
  }

  validateBudgetOfEditAction (
    l1Object: L1Object | undefined,
    campaignBasic: MessageCampaignBasic,
    order: Order,
    otherCampaignCost: number,
    minBudgetOfCampaign: number
  ) {
    const budget = campaignBasic.budget;
    const l1ObjectAutoOptimise = _.get(l1Object, 'autoOptimise', false);
    const l1ObjectBudget = +_.defaultTo(_.get(l1Object, 'budget'), 0);
    if (l1ObjectAutoOptimise) {
      if (budget === 0) { // NOT ALLOCATED CAMPAIGN
        const l1ObjectMinBudget = otherCampaignCost + minBudgetOfCampaign;
        if (l1ObjectMinBudget > l1ObjectBudget) {
          return i18n.t<string>('campaign.descriptions.minL1ObjectBudget', { budget: formatPriceWithCurrency(order.currency, l1ObjectMinBudget) });
        }
      }
      return;
    }
    if (
      !this.defaultCampaign.basic.isDraft &&
      this.defaultCampaign.basic.budget === campaignBasic.budget
    ) {
      return undefined;
    }
    const totalBudget = order.budgetBalance;
    const remainBudget = totalBudget - budget + this.defaultCampaign.basic.budget;
    if (remainBudget < 0) {
      return renderOverBudgetWording(order.currency, totalBudget);
    }
    return this.validateBudgetBasic(campaignBasic, l1Object, order, minBudgetOfCampaign);
  }

  validateBudgetBasic (
    campaignBasic: MessageCampaignBasic,
    l1Object: L1Object | undefined,
    order: Order,
    minBudgetOfCampaign: number
  ) {
    const l1ObjectAutoOptimise = _.get(l1Object, 'autoOptimise', false);
    const budget = campaignBasic.budget;
    const spents = _.get(campaignBasic, 'spents', 0);
    const expectedSpent = _.get(campaignBasic, 'expectedSpent', 0);
    const campaignBudgetMinimum = order.campaignConstraint.campaignBudgetMinimum;
    const finalMinBudgetOfCampaign = l1ObjectAutoOptimise ? minBudgetOfCampaign : campaignBudgetMinimum;
    const minBudget = Math.max(spents, expectedSpent, finalMinBudgetOfCampaign);

    if (campaignBasic.state === CampaignState.DEACTIVATE) {
      return validateMinimum(
        budget,
        getPriceValue(order.currency, spents),
        'campaign.descriptions.smallerThanBudgetMinimum',
        order.currency
      );
    }

    let error = validateEmpty(budget);
    if (error) {
      return error;
    }

    let hintI18n = 'campaign.descriptions.smallerThanBudgetMinimum';
    let i18nParams: any = { min: formatPriceWithCurrency(order.currency, minBudget) };
    switch (minBudget) {
      case spents:
        hintI18n = 'campaign.descriptions.smallerThanSpents';
        break;
      case expectedSpent:
        hintI18n = 'campaign.descriptions.smallerThanExpectSpents';
        break;
      case finalMinBudgetOfCampaign:
        hintI18n = l1ObjectAutoOptimise ? 'campaign.descriptions.smallerThanDailyMnBudgetSum' : 'campaign.descriptions.smallerThanCampaignBudgetMinimum';
        i18nParams = {
          ...i18nParams,
          dmin: formatPriceWithCurrency(order.currency, order.campaignConstraint.budgetMinimum)
        };
        break;
      default:
        break;
    }

    const minValue = getPriceValue(order.currency, minBudget);
    if (budget < minValue) {
      return i18n.t<string>(hintI18n, { ...i18nParams });
    }
  }
}
