import { FormConfig } from 'components/common/form/FormConfig';
import { useState, useEffect, useCallback } from 'react';
import i18n from 'i18n';
import { Form } from 'components/common/form/Form';
import { validateEmpty, validateMinMax } from 'utils/ValidateUtils';
import { Formik } from 'formik';
import { Redirect } from 'react-router-dom';
import { Page } from 'components/Page/Page';
import { SourceFormModel } from './SourceFormModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';

export type SourceFormProps = {
  useModel: () => SourceFormModel
};

export const SourceForm: React.FC<SourceFormProps> = ({
  useModel
}) => {

  const [formConfig, setFormConfig] = useState<FormConfig>();

  const {
    title,
    loading,
    initSource,
    redirectPath,
    countryOptions,
    segmentLabelOptions,
    handleSubmit
  } = useModel();

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder()
            .addFormikInput({
              label: i18n.t<string>('source.labels.name'),
              name: 'name',
              validate: validateEmpty
            })
            .addFormikInputGroup({
              label: i18n.t<string>('source.labels.duration'),
              name: 'duration',
              postfix: i18n.t<string>('common.day'),
              hint: i18n.t<string>('sourceForm.hints.durationLimit'),
              type: 'number',
              validate: value => validateMinMax(value, 30, 365)
            })
            .addFormikTags({
              label: i18n.t<string>('source.labels.tags'),
              name: 'tags',
              suggestions: segmentLabelOptions
            })
            .addFormikTags({
              label: i18n.t<string>('source.labels.countries'),
              name: 'countries',
              suggestions: countryOptions
            })
            .build()
        )
        .build()
      )
      .build()
    );
  }, [countryOptions, segmentLabelOptions]);

  const renderForm = useCallback((formikProps) => {
    const renderFormBtns = () => (
      <>
        <Form.SubmitButton/>
        <Form.CancelButton/>
      </>
    );
    return (
      <Form
        formikProps={formikProps}
        formConfig={formConfig}
        renderFormBtns={renderFormBtns}
      />
    );
  }, [formConfig]);

  if (!initSource) {
    return <LoadingIndicator/>;
  }

  if (redirectPath) {
    return <Redirect to={redirectPath}/>;
  }

  return (
    <Page title={title}>
      {loading && <LoadingIndicator />}
      <Formik
        initialValues={initSource}
        onSubmit={handleSubmit}
        validateOnBlur={false}
      >
        {renderForm}
      </Formik>
    </Page>
  );
};
