import { DefaultCustomLayoutManager } from 'core/customLayout/CustomLayoutManager';
import { Button } from 'react-bootstrap';
import i18n from 'i18n';
import { ThirdPartyCreativePreview } from 'components/ThirdPartyCreativePreview/ThirdPartyCreativePreview';
import styles from './previewStep.module.scss';
import { Page } from 'components/Page/Page';
import { useCallback } from 'react';
import { useCallAPI } from 'hooks/useCallAPI';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { CustomLayoutSetupFlowData } from './CustomLayoutSetupFlowPageModel';

const defaultCustomLayoutManager = new DefaultCustomLayoutManager();

export const PreviewStep = ({
  goLast,
  defaultDeviceType,
  onSubmit,
  customLayoutSetupFlowData
}: {
  goLast,
  defaultDeviceType,
  onSubmit,
  customLayoutSetupFlowData: CustomLayoutSetupFlowData
}) => {

  const { loading, callAPIs } = useCallAPI();
  const handleSubmit = useCallback(() => {
    callAPIs([() => defaultCustomLayoutManager.createCustomLayout({
      macros: customLayoutSetupFlowData.macros!,
      name: customLayoutSetupFlowData.name!,
      previewUrl: customLayoutSetupFlowData.previewUrl!,
      layoutId: customLayoutSetupFlowData.layoutId!,
      type: customLayoutSetupFlowData.type!,
      device: customLayoutSetupFlowData.device!,
      sessionId: customLayoutSetupFlowData.sessionId!
    })], () => {
      onSubmit();
    });
  }, [customLayoutSetupFlowData, onSubmit, callAPIs]);

  const renderBtns = useCallback(() => (
    <>
      <Button variant='primary' size='sm' onClick={handleSubmit}>
        {i18n.t<string>('common.buttons.submit')}
      </Button>
      <Button variant='secondary' size='sm' onClick={goLast}>
        {i18n.t<string>('common.buttons.back')}
      </Button>
    </>
  ), [goLast, handleSubmit]);

  return (
    <div className={styles.previewStep}>
      {loading && <LoadingIndicator />}
      <Page.Content renderBtns={renderBtns}>
        <div className={styles.mainArea}>
          <ThirdPartyCreativePreview iframeSrc={customLayoutSetupFlowData.previewUrlFromServer} defaultDeviceType={defaultDeviceType} showPreviewOptions={false}/>
        </div>
      </Page.Content>
    </div>
  );
};
