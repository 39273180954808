import { FormConfig, FormField } from 'components/common/form/FormConfig';
import { useCallback, useMemo } from 'react';
import i18n from 'i18n';
import { compact } from 'lodash';
import { Modal } from 'components/common/Modal/Modal';
import { Formik } from 'formik';
import { validateEmpty } from 'utils/ValidateUtils';
import styles from './fieldConfigPage.module.scss';
import { FieldConfig, FieldType } from './FieldConfig';

export const FieldConfigPage = ({
  title,
  config,
  setConfig,
  closePage,
  customFields
}: {
  title: string,
  config?: FieldConfig,
  setConfig: (config: FieldConfig) => void,
  closePage: () => void,
  customFields?: FormField[]
}) => {

  const fileCommonFields = useMemo(() => {
    return new FormConfig.FieldsBuilder().addFormikInput({
      label: i18n.t<string>('fieldConfigPage.labels.fileSize'),
      name: 'limits.fileSize',
      hint: 'ex: 300x250'
    })
    .addFormikInput({
      label: i18n.t<string>('fieldConfigPage.labels.fileRatio'),
      name: 'limits.fileRatio',
      hint: 'ex: 16:9'
    })
    .addFormikInputGroup({
      label: i18n.t<string>('fieldConfigPage.labels.fileStorage'),
      name: 'limits.fileStorage',
      type: 'number',
      postfix: 'MB'
    })
    .build();
  }, []);

  const imageConfig = useMemo(() => {
    return new FormConfig.FieldsBuilder(fileCommonFields)
      .addFormikSelect({
        label: i18n.t<string>('fieldConfigPage.labels.fileType'),
        name: 'limits.fileType',
        simpleValue: true,
        isMulti: true,
        options: [{
          value: 'jpg',
          label: 'jpg'
        }, {
          value: 'png',
          label: 'png'
        }, {
          value: 'gif',
          label: 'gif'
        }]
      })
      .build();
  }, [fileCommonFields]);

  const videoConfig = useMemo(() => {
    return new FormConfig.FieldsBuilder(fileCommonFields)
      .addFormikSelect({
        label: i18n.t<string>('fieldConfigPage.labels.fileType'),
        name: 'limits.fileType',
        simpleValue: true,
        isMulti: true,
        options: [{
          value: 'mp4',
          label: 'mp4'
        }]
      })
      .addFormikInputGroup({
        label: i18n.t<string>('fieldConfigPage.labels.videoDuration'),
        name: 'limits.videoDuration',
        type: 'number',
        postfix: 'second(s)'
      })
      .build();
  }, [fileCommonFields]);

  const textConfig = useMemo(() => {
    return new FormConfig.FieldsBuilder()
      .addFormikInput({
        label: i18n.t<string>('fieldConfigPage.labels.textMinLength'),
        name: 'limits.textMinLength',
        type: 'number'
      })
      .addFormikInput({
        label: i18n.t<string>('fieldConfigPage.labels.textMaxLength'),
        name: 'limits.textMaxLength',
        type: 'number'
      })
      .build();
  }, []);

  const configTypeMap = useMemo(() => {
    return {
      image: imageConfig,
      video: videoConfig,
      text: textConfig
    };
  }, [imageConfig, videoConfig, textConfig]);

  const handleSubmit = useCallback((values) => {
    setConfig({ ...values });
    closePage();
  }, [setConfig, closePage]);

  return (
    <Formik
      initialValues={{ ...config }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
    >
      {({ values, submitForm, setValues }) => {
        const fields = new FormConfig.FieldsBuilder()
          .addFormikSelect({
            label: i18n.t<string>('fieldConfigPage.labels.fieldType'),
            name: 'type',
            validate: validateEmpty,
            simpleValue: true,
            options: [{
              value: FieldType.IMAGE,
              label: i18n.t<string>('fieldTypes.image')
            }, {
              value: FieldType.VIDEO,
              label: i18n.t<string>('fieldTypes.video')
            }, {
              value: FieldType.URL,
              label: i18n.t<string>('fieldTypes.url')
            }, {
              value: FieldType.TEXT,
              label: i18n.t<string>('fieldTypes.text')
            }],
            onChange: (newType) => {
              setValues({
                ...values,
                type: newType,
                limits: newType === 'image' ?
                  { fileType: ['jpg', 'png'] } :
                  newType === 'video' ?
                    { fileType: ['mp4'] } :
                    undefined
              });
            }
          })
          .build();
        customFields && fields.push(...customFields);
        const configFields = values.type && configTypeMap[values.type] ? fields.concat([...configTypeMap[values.type]]) : fields;
        return (
          <Modal
            className={styles.configModal}
            title={title}
            primaryButton={{
              title: i18n.t<string>('common.buttons.ok'),
              callback: submitForm
            }}
            secondaryButton={{
              title: i18n.t<string>('common.buttons.cancel'),
              callback: closePage
            }}
          >
            {
              compact(configFields.map((field: any) => {
                const { component: FieldComponent, props, hide } = field;
                return hide ? undefined : (
                  <FieldComponent key={props.name} {...props}/>
                );
              }))
            }
          </Modal>
        );
      }
      }
    </Formik>
  );
};
