import { useField, useFormikContext } from 'formik';
import { defaultTo, get } from 'lodash';
import React, { useCallback } from 'react';

export interface FormikFieldProps {
  name: string;
  onChange?: (value: any) => void;
  validate?: (...args: any[]) => any;
}

export function withFormikField<T extends FormikFieldProps> (
  WrapperComponent: React.FC<any>,
  updateFormikField: (
    name: string,
    valueToUpdate: any,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => any = (name, srcValue, setFieldValue) => {
    const targetValue = get(srcValue, 'target.value', srcValue);
    setFieldValue(name, targetValue);
    return targetValue;
  }
): React.FC<T> {
  const ComponentWithFormikProps: React.FC<T> = ({
    name,
    validate,
    onChange,
    ...props
  }) => {
    const { setFieldValue, setFieldTouched, submitCount } = useFormikContext<any>();

    const handleOnChange = useCallback(value => {
      const targetValue = updateFormikField(name, value, setFieldValue);
      setFieldTouched(name, true);
      // formik issues 2266 workaround
      setTimeout(() => {
        if (onChange) {
          if (typeof targetValue === 'boolean') {
            onChange(targetValue);
          } else {
            onChange(defaultTo(targetValue, ''));
          }
        }
      });
    }, [name, setFieldValue, setFieldTouched, onChange]);

    const [{ value }, { touched: formikTouched, error }] = useField({ name, validate });
    const touched = defaultTo(submitCount, 0) > 0 || formikTouched;
    const formikProps = {
      value: defaultTo(value, ''),
      error: error !== undefined && touched ? error : undefined
    };
    return <WrapperComponent name={name} onChange={handleOnChange} {...props} {...formikProps}/>;
  };
  ComponentWithFormikProps.displayName = `${WrapperComponent.displayName}(withFormikField)`;
  return ComponentWithFormikProps;
}
