import { SortDescriptor } from 'components/TableColumn/TableColumn';
import { SortOrder } from 'react-bootstrap-table-next';

export interface SortableList {
  readonly sortDescriptor: SortDescriptor;
  handleSort (field: string, order: SortOrder): void;
}

export abstract class AbstractSortableList implements SortableList {
  constructor (
    protected sortField: string,
    protected sortOrder: SortOrder
  ) {}

  get sortDescriptor (): SortDescriptor {
    return [{
      dataField: this.sortField,
      order: this.sortOrder
    }];
  }

  handleSort = (field: string, order: SortOrder) => {
    this.sortField = field;
    this.sortOrder = order;
  }
}
