import styles from './separationLine.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

export const SeparationLine: React.FC<{bold?: boolean, className?: string}> = ({
  bold,
  className
}) => {
  const separationLineClass = cx(styles.separationLine, className, {
    bold
  });
  return <div className={separationLineClass}/>;
};

SeparationLine.defaultProps = {
  bold: true
};
