import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { LoginBackground } from 'containers/LoginBackground/LoginBackground';
import { useCallAPI } from 'hooks/useCallAPI';
import React, { useEffect, useRef, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import loginLogo from 'assets/tenmax-logo.png';
import loginLogo2x from 'assets/tenmax-logo@2x.png';
import i18n from 'i18n';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AuthenticationManager } from 'core';
import classnames from 'classnames';
import { validateEmpty } from 'utils/ValidateUtils';
import styles from './registerPage.module.scss';
import _ from 'lodash';
import { alertMessage, DismissFunction } from 'components/AlertDialog';
import { Trans } from 'react-i18next';

export const RegisterPage: React.FC<{
  authenticationManager: AuthenticationManager
}> = ({
  authenticationManager
}) => {
  const passwordRef = useRef<any>(null);
  const confirmPasswordRef = useRef<any>(null);
  const location = useLocation();
  const { loading, callAPIs } = useCallAPI();
  const [ formError, setFormError ] = useState<any>({});
  const [ redirectPath, setRedirectPath ] = useState<string | undefined>();
  const [ passwordVisibility, setPasswordVisibility] = useState(false);
  const [ confirmPasswordVisibility, setConfirmPasswordVisibility] = useState(false);
  const [ userName, setUserName ] = useState('');
  const [ showCompletMessage, setShowCompleteMessage ] = useState<boolean>(false);
  const [ modalData, setModalData ] = useState<{
    title: string,
    message: string,
    onConfirm: DismissFunction
  } | undefined>();

  const query = new URLSearchParams(location.search);
  const token = query.get('token');
  const passwordError = formError['password'];
  const confirmPasswordError = formError['confirmPassword'];

  useEffect(() => {
    if (token === null) {
      return setModalData({
        title: i18n.t<string>('registerPage.titles.invalidLink'),
        message: i18n.t<string>('registerPage.messages.resendEmail'),
        onConfirm: _.partial(setRedirectPath, '/')
      });
    }
    callAPIs([authenticationManager.validateGuestToken.bind(authenticationManager, token)], (result: {
      userName: string,
      registerStatus: number
    }) => {
      setUserName(result.userName);
      if (result.registerStatus === 2) {
        setModalData({
          title: i18n.t<string>('registerPage.titles.notice'),
          message: i18n.t<string>('registerPage.messages.activated'),
          onConfirm: _.partial(setRedirectPath, '/')
        });
      }
    }, () => {
      setModalData({
        title: i18n.t<string>('registerPage.titles.invalidLink'),
        message: i18n.t<string>('registerPage.messages.resendEmail'),
        onConfirm: _.partial(setRedirectPath, '/')
      });
    });
  }, [token, callAPIs, authenticationManager]);

  const toLoginPage = () => {
    authenticationManager.logout();
    setRedirectPath('/');
  };

  const setupPassword = (event) => {
    if (!token) {
      return;
    }
    event.preventDefault();
    const password = passwordRef.current.value;
    callAPIs([authenticationManager.setUpPasswordFirstTime.bind(authenticationManager, token, password)], () => {
      setShowCompleteMessage(true);
    });
  };

  const triggerPasswordVisibility = () => {
    setPasswordVisibility(passwordVisibility => !passwordVisibility);
  };

  const triggerConfirmPasswordVisibility = () => {
    setConfirmPasswordVisibility(confirmPasswordVisibility => !confirmPasswordVisibility);
  };

  const onPasswordChange = (event) => {
    const password = event.target.value;
    const error = validateEmpty(password);
    setFormError(formError => ({
      ...formError,
      password: error,
      confirmPassword: validateConfirmPassword(password, _.get(confirmPasswordRef.current, 'value'))
    }));
  };

  const onConfirmPasswordChange = (event) => {
    const confirmPassword = event.target.value;
    setFormError(formError => ({
      ...formError,
      confirmPassword: validateConfirmPassword(_.get(passwordRef.current, 'value'), confirmPassword)
    }));
  };

  const passwordInputGroupClass = classnames(styles.inputGroup, {
    withError: passwordError
  });

  const confirmPasswordInputGroupClass = classnames(styles.inputGroup, {
    withError: confirmPasswordError
  });

  return (
    <LoginBackground>
      <div className={styles.registerForm}>
        {modalData && alertMessage(modalData.title, modalData.message, modalData.onConfirm)}
        {redirectPath && <Redirect to={redirectPath} />}
        {loading && <LoadingIndicator/>}
        <form onSubmit={setupPassword}>
          <div className={styles.iconGroup}>
            <div>
              <img
                className={styles.formTitle}
                src={loginLogo}
                srcSet={loginLogo2x}
                alt='tenmax icon'
              />
            </div>
          </div>
          {
            showCompletMessage ?
            <>
              <div className={styles.sentCompleteTitle}>
                {i18n.t<string>('registerPage.labels.completeTitle')}
              </div>
              <div className={styles.sentCompleteMessage}>
                {i18n.t<string>('registerPage.labels.completeMessage')}
              </div>
              <div className={styles.underscoreText} onClick={toLoginPage}>
                {i18n.t<string>('forgotPasswordPage.labels.backToLogin')}
              </div>
            </> :
            <>
              <div className={styles.subtitle}>
                <Trans i18nKey='registerPage.labels.setPassword'>
                  Hi {{ name: userName }},<br/>please set your password
                </Trans>
              </div>
              <div className={passwordInputGroupClass}>
                <label>{i18n.t<string>('loginPage.labels.passwordTitle')}</label>
                <input
                  className={styles.passwordInput}
                  type={passwordVisibility ? 'text' : 'password'}
                  ref={passwordRef}
                  placeholder={i18n.t<string>('login.form.placeholders.password')}
                  onChange={onPasswordChange}
                />
                <FontAwesomeIcon icon={faEye} onClick={triggerPasswordVisibility} />
                {passwordError && <div className={styles.inputError}>{passwordError}</div>}
              </div>
              <div className={confirmPasswordInputGroupClass}>
                <label>{i18n.t<string>('loginPage.labels.confirmPasswordTitle')}</label>
                <input
                  className={styles.passwordInput}
                  type={confirmPasswordVisibility ? 'text' : 'password'}
                  placeholder={i18n.t<string>('login.form.placeholders.confirmPassword')}
                  ref={confirmPasswordRef}
                  onChange={onConfirmPasswordChange}
                />
                <FontAwesomeIcon icon={faEye} onClick={triggerConfirmPasswordVisibility} />
                {confirmPasswordError && <div className={styles.inputError}>{confirmPasswordError}</div>}
              </div>
              <button
                type='submit'
                className='btn btn-primary'
                disabled={passwordError || confirmPasswordError}
              >
                {i18n.t<string>('common.buttons.confirm')}
              </button>
            </>
          }
        </form>
      </div>
    </LoginBackground>
  );
};

function validateConfirmPassword (password: string, confirmPassword: string): string | null {
  if (_.isEmpty(password)) {
    return null;
  }
  if (_.isEmpty(confirmPassword)) {
    return i18n.t<string>('accounts.form.messages.confirmPasswordRequired');
  }
  if (password !== confirmPassword) {
    return i18n.t<string>('accounts.form.messages.passwordNotMatched');
  }
  return null;
}
