import React, { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { PPSFormProps } from './PPSFormModel';
import styles from './ppsForm.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { PPSMacroInstruction } from './PPSMacroInstruction';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { SelectOptions } from 'components/common/commonType';
import _, { cloneDeep } from 'lodash';
import { Button } from 'react-bootstrap';
import { AlertDialog } from 'components/AlertDialog';
import { useCallAPI } from 'hooks/useCallAPI';
import { FormConfig } from 'components/common/form/FormConfig';
import { FormContent } from 'components/common/form/Form';
import { THIRD_PARTY_TYPE } from 'core/creative/Creative';
import { FormikField } from 'components/common/form/field/FormikField';

const imageValidTypes = ['image/jpeg', 'image/jpg', 'image/png'];

export const PPSForm: React.FunctionComponent<PPSFormProps & { formik: FormikContextType<any> }> = (props) => {

  const {
    formik,
    hintModalData,
    renderHintModal,
    setHintModalData
  } = props;

  const setFieldValue = formik.setFieldValue;
  const setValues = formik.setValues;
  const layoutIdFieldProps = formik.getFieldProps('typeProperties.layoutId');
  const thirdPartyTypeFieldProps = formik.getFieldProps('typeProperties.thirdPartyType');
  const currentLayoutId = layoutIdFieldProps.value;
  const thirdPartyType = thirdPartyTypeFieldProps.value;
  const { loading, callAPIs } = useCallAPI();
  const [ppsLayoutIdData, setPpsLayoutIdData] = React.useState<SelectOptions[]>([]);
  const [warningModalMessage, setWarningModalMessage] = React.useState('');
  const [formConfig, setFormConfig] = useState<FormConfig>(props.getInitFormConfig());

  useEffect(() => {
    callAPIs([props.model.getPpsLayoutId.bind(props.model)], layoutIdData => {
      setPpsLayoutIdData(layoutIdData);
    });
  }, [props.model, callAPIs]);

  const [macroInstructionModalData, setMacroInstructionModalData] = React.useState<any>(undefined);

  useEffect(() => {
    if (hintModalData !== macroInstructionModalData) {
      setMacroInstructionModalData(undefined);
    }
  }, [hintModalData, macroInstructionModalData]);

  const subLayoutOptions = useMemo(() => {
    const layoutData = ppsLayoutIdData.find(data => data.value === currentLayoutId);
    if (layoutData && layoutData.extra) {
      return layoutData.extra.map(subLayout => ({
        label: subLayout,
        value: subLayout
      }));
    }
  }, [currentLayoutId, ppsLayoutIdData]);

  const showMacroInstruction = useCallback(() => {
    const modalData = {
      component: PPSMacroInstruction,
      props: {
        onClose: _.partial(setHintModalData, undefined)
      }
    };
    setMacroInstructionModalData(modalData);
    setHintModalData(modalData);
  }, [setHintModalData]);

  const closeMacroInstruction = useCallback(() => {
    setMacroInstructionModalData(undefined);
    setHintModalData(undefined);
  }, [setHintModalData]);

  const getPpsThumbnail = props.model.getPpsThumbnail;

  const onPPSHtmlSnippetChange = useCallback((code) => {
    if (!code) {
      return;
    }
    const regexp = /layoutId:\s*'(.*?)'/;
    const regexpForVer3 = /data-layout-id=\s*"(.*?)"/;
    let matches = code.match(regexp);
    if (!matches || !matches[1]) {
      matches = code.match(regexpForVer3);
    }
    if (!matches || !matches[1]) {
      return;
    }
    const layoutId = matches[1];
    const layoutIdData = ppsLayoutIdData.find(data => data.value === layoutId);
    if (!layoutIdData) {
      setValues(prev => ({
        ...prev,
        typeProperties: {
          ...prev.typeProperties,
          layoutId: undefined,
          subLayout: undefined
        }
      }));
    } else {
      setFieldValue('typeProperties.layoutId', layoutId);
    }
  }, [
    ppsLayoutIdData,
    setFieldValue,
    setValues
  ]);

  const imageHints = useMemo(() => [i18n.t<string>('creativeSetupFlow.labels.typeHint')], []);

  const renderImageField = useCallback((formikProps) => {
    const getPpsImage = async () => {
      const code = _.get(formikProps.values, 'typeProperties.htmlSnippet');
      if (!code) {
        setWarningModalMessage(i18n.t<string>('ppsForm.labels.needPPSCreativeID'));
        return;
      }
      const regexp = /creativeId:\s*'(.*?)'/;
      const matches = code.match(regexp);
      if (!matches || !matches[1]) {
        setWarningModalMessage(i18n.t<string>('ppsForm.labels.needPPSCreativeID'));
        return;
      }
      const creativeId = matches[1];
      callAPIs([() => getPpsThumbnail(creativeId)], thumbnails => {
        if (thumbnails.length > 0) {
          formikProps.setFieldValue(formikProps.name, {
            url: thumbnails[0]
          });
        } else {
          setWarningModalMessage(i18n.t<string>('ppsForm.labels.cannotFindPreveiwImage'));
        }
      });
    };

    return (
      <div className={styles.imageFormInputContainer}>
        <FormikField.FileInput
          name={formikProps.name}
          type='image'
          className={styles.imageInput}
          validTypes={imageValidTypes}
          hints={imageHints}
          fitContent={false}
          formGroupClassName='mb-0'
        />
        <Button variant='secondary' size='sm' onClick={getPpsImage}>
          {i18n.t<string>('ppsForm.labels.autoGenImage')}
        </Button>
      </div>
    );
  }, [
    imageHints,
    callAPIs,
    getPpsThumbnail
  ]);

  const labelIdFormatter = useCallback((value) => {
    // re-render when ppsLayoutIdData is loaded
    const data = ppsLayoutIdData.find(data => data.value === value);
    return data ?
      i18n.t<string>(`ppsLayoutId.${data.label.toLowerCase().replace(/-|\s/g, '_')}`) :
      value;
  }, [ppsLayoutIdData]);

  const thirdPartyTypeOptions = useMemo(() => [{
    label: i18n.t<string>('thirdPartyType.normal'),
    value: THIRD_PARTY_TYPE.NORMAL
  }, {
    label: i18n.t<string>('thirdPartyType.adneon'),
    value: THIRD_PARTY_TYPE.ADNEON
  }], []);

  useEffect(() => {
    const formFields = cloneDeep(props.basicFields);
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder(formFields)
            .addFormikSelect({
              label: i18n.t<string>('ppsForm.labels.thirdPartyType'),
              name: 'typeProperties.thirdPartyType',
              options: thirdPartyTypeOptions,
              simpleValue: true
            })
            .addFormikTextarea({
              className: styles.codeInput,
              label: i18n.t<string>('ppsForm.labels.htmlSnippet'),
              name: 'typeProperties.htmlSnippet',
              onChange: onPPSHtmlSnippetChange,
              permanentHint: (
                <div
                  className={styles.macroInstructionBtn}
                  onClick={macroInstructionModalData ?
                    closeMacroInstruction : showMacroInstruction
                  }
                >
                  <span>{i18n.t<string>('ppsForm.labels.macroInstruction')}</span>
                  <FontAwesomeIcon icon={faInfoCircle}/>
                </div>
              ),
              validate: props.model.validateHtmlSnippet
            })
            .addFormikLabel({
              label: i18n.t<string>('ppsForm.labels.layoutId'),
              name: 'typeProperties.layoutId',
              fieldContentWidth: 'auto',
              formatter: labelIdFormatter
            }, !currentLayoutId)
            .addFormikSelect({
              label: i18n.t<string>('ppsForm.labels.subLayout'),
              name: 'typeProperties.subLayout',
              options: subLayoutOptions,
              simpleValue: true
            }, !currentLayoutId)
            .addFormikCustom({
              label: i18n.t<string>('creativeSetupFlow.labels.imagePreview'),
              name: 'medias.image',
              render: renderImageField,
              validate: props.model.validateImage
            })
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .withHintModal(renderHintModal())
        .build()
      ).build()
    );
  }, [
    props.basicFields,
    macroInstructionModalData,
    renderHintModal,
    showMacroInstruction,
    closeMacroInstruction,
    onPPSHtmlSnippetChange,
    renderImageField,
    props.model.validateHtmlSnippet,
    props.model.validateImage,
    setFieldValue,
    currentLayoutId,
    labelIdFormatter,
    subLayoutOptions,
    thirdPartyType,
    thirdPartyTypeOptions
  ]);

  const dismissModal = () => {
    setWarningModalMessage('');
  };

  return (
    <div>
      {!_.isEmpty(warningModalMessage) &&
        <AlertDialog
          title={i18n.t<string>('common.warning')}
          message={warningModalMessage}
          danger={false}
          dismiss={dismissModal}
        />
      }
      {loading && <LoadingIndicator/>}
      <FormContent
        formConfig={formConfig}
      />
    </div>
  );
};

export default connect(PPSForm);
