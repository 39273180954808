import React from 'react';
import NativeCheckbox, { CheckboxProps } from 'components/Checkbox/Checkbox';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';
import { FormikFieldProps, withFormikField } from 'hoc/withFormikField';

type UnControlledCheckboxProps = Omit<CheckboxProps, 'checked' | 'onChange'> & { value?: any, error?: any, onChange?: (value: any) => void };
export interface CheckboxFieldProps extends CheckboxProps, Omit<FieldLayoutProps, 'name'> {}
export interface FormikCheckboxProps extends FormikFieldProps, UnControlledCheckboxProps {}
export interface FormikCheckboxWithFieldLayoutProps extends UnControlledCheckboxProps, FormikFieldProps, Omit<FieldLayoutProps, 'name'> {}
export type FormikCheckboxFieldProps = ({ withFieldLayout?: true } & FormikCheckboxWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikCheckboxProps);

const CheckboxContent: React.FC<UnControlledCheckboxProps> = ({
  value,
  ...props
}: UnControlledCheckboxProps) => {
  return (
    <NativeCheckbox
      {...props}
      checked={value ? value : false}
    />
  );
};
const MemorizedCheckboxContent = React.memo(CheckboxContent);

export const CheckboxField = withFieldLayout<CheckboxFieldProps>(NativeCheckbox);
const FormikCheckbox = withFormikField<FormikFieldProps & UnControlledCheckboxProps>(MemorizedCheckboxContent);
const FormikCheckboxWithFieldLayout = withFormikField<FormikCheckboxFieldProps>(withFieldLayout(CheckboxContent));

export const FormikCheckboxField: React.FC<FormikCheckboxFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikCheckbox {...props} />;
  } else {
    return <FormikCheckboxWithFieldLayout {...props} />;
  }
};
