import { ColumnDefinition } from 'components/TableColumn/TableColumn';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { SegmentManager, DefaultSegmentManager } from 'core/segment/SegmentManager';
import { SegmentListDTO } from 'core/segment/Segment';
import styles from './segmentList.module.scss';
import i18n from 'i18next';
import { toast } from 'react-toastify';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { SortableList, AbstractSortableList } from 'containers/Common/AbstractSortableList';

export type SegmentListState = {
  readonly viewModel: any;
  readonly loading: boolean;
  readonly modalData: any;
};

export interface SegmentListModel extends SortableList {
  readonly event: UpdateEventListener<SegmentListModel>;
  readonly state: SegmentListState;
  readonly searchString: string;
  init (): Promise<void>;
  setAgencyId (agencyId): void;
  setAdvertiserId (advertiserId): void;
  getColumnDefinition (columnName): ColumnDefinition;
  handleOnSearch (searchString): void;
  onUnmount (handler): void;
  showDeleteModal (segmentId: number): void;
  hideModal (): void;
}

export enum SegmentListColumns {
  NAME = 'nameIdColumn',
  DURATION = 'durationColumn',
  SEGMENT_COUNT = 'segmentCountColumn',
  SUB_SEGMENT_AMOUNT = 'subSegmentAmountColumn'
}

export type SegmentListProps = {
  readonly model: SegmentListModel;
};

export class DefaultSegmentListModel extends AbstractSortableList implements SegmentListModel {
  loading: boolean;
  manager: SegmentManager;
  event: FireableUpdateEventListener<SegmentListModel>;
  advertiserId?: number;
  segments: Array<SegmentListDTO>;
  searchString: string;
  viewModel: any;
  modalData: any;
  agencyId?: number;

  constructor (advertiserId: number | undefined, manager: SegmentManager = new DefaultSegmentManager()) {
    super(SegmentListColumns.NAME, 'desc');
    this.manager = manager;
    this.advertiserId = advertiserId;
    this.loading = false;
    this.event = new FireableUpdateEventListener<SegmentListModel>();
    this.searchString = '';
    this.segments = [];
    this.viewModel = [];
    this.agencyId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY);
  }

  get state () {
    return {
      viewModel: this.viewModel,
      loading: this.loading,
      modalData: this.modalData
    };
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      this.segments = await this.manager.getSegments(this.agencyId);
      this.updateViewModelData();
    } catch (error) {
      this.updateState(false);
    }
  }

  setAgencyId (agencyId?: number): void {
    this.agencyId = agencyId;
    this.advertiserId = undefined;
    this.init();
  }

  setAdvertiserId (advertiserId): void {
    this.advertiserId = advertiserId;
    this.updateViewModelData();
  }

  showDeleteModal = (segmentId: number) => {
    this.modalData = {
      title: i18n.t<string>('segmentList.labels.deleteModalTitle'),
      message: i18n.t<string>('segmentList.labels.deleteModalContent'),
      dangerBtnData: {
        title: i18n.t<string>('common.buttons.delete'),
        callback: this.deleteSegment.bind(this, segmentId)
      }
    };
    this.updateState(false);
  }

  hideModal = () => {
    this.modalData = undefined;
    this.updateState(false);
  }

  async deleteSegment (segmentId: number) {
    this.updateState(true);
    this.modalData = undefined;
    try {
      await this.manager.deleteSegment(segmentId);
      this.segments = await this.manager.getSegments(this.agencyId);
      toast.success(i18n.t<string>('segmentList.labels.deleteSuccess'));
      this.updateViewModelData();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  handleOnSearch = (searchString) => {
    this.searchString = searchString;
    this.updateViewModelData();
  }

  updateViewModelData () {
    this.viewModel = this.segments
    .filter(segment => {
      const advertiserMatch = this.advertiserId === undefined || segment.advertiserId === this.advertiserId;
      const searchMatch = segment.name.toLowerCase().includes(this.searchString.toLowerCase()) ||
                          segment.id.toString().includes(this.searchString);
      return advertiserMatch && searchMatch;
    }).map(segment => {
      return {
        id: segment.id,
        name: segment.name,
        [SegmentListColumns.NAME]: segment.id,
        [SegmentListColumns.DURATION]: segment.duration,
        [SegmentListColumns.SEGMENT_COUNT]: segment.segmentCount,
        [SegmentListColumns.SUB_SEGMENT_AMOUNT]: segment.subSegmentAmount
      };
    });
    this.updateState(false);
  }

  getColumnDefinition (columnName): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };
    let text = i18n.t<string>(`segmentList.headers.${columnName}`);
    let sort = true;
    return {
      sort,
      text,
      dataField: columnName,
      classes: columnClassGetter,
      headerClasses: columnClassGetter
    };
  }

  onUnmount (handler) {
    this.event.remove(handler);
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
