import React from 'react';
import { OverlayTrigger } from 'react-bootstrap';
import _ from 'lodash';

export function withOverlayHoverTip (WrappedComponent, tooltipComponent) {
  const WithOverlayHoverTip = (props) => {
    const placement = props.placement || 'bottom';
    const componentProps = _.omit(props, ['placement']);
    return (
      <OverlayTrigger
        placement={placement}
        trigger={['hover', 'focus']}
        overlay={tooltipComponent}
      >
        <WrappedComponent {...componentProps}/>
      </OverlayTrigger>
    );
  };
  WithOverlayHoverTip.displayName = `WithOverlayHoverTip(${getDisplayName(WrappedComponent)})`;
  return WithOverlayHoverTip;
}

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}
