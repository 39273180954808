import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';
import { TiktokAdGroupSetupFlowPage } from './TiktokAdGroupSetupFlowPage/TiktokAdGroupSetupFlowPage';
import { useEditTiktokAdGroupDraftSetupFlowPageModel } from './TiktokAdGroupSetupFlowPage/TiktokAdGroupSetupFlowPageModel';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { Order } from 'core/order/Order';
import { L1Object } from 'core/l1Object/L1Object';
import { TiktokAdGroup } from 'core/tiktokAdGroup/TiktokAdGroup';

const AdGroupDraftSetupView = withErrorBoundary(ERROR_CODE.DRAFT_EDIT, TiktokAdGroupSetupFlowPage);
export const TiktokAdGroupDrafts: React.FC<RouteComponentProps<any> & {
  order: Order,
  l1Object: L1Object,
  tiktokAdGroupList: TiktokAdGroup[]
}> = ({
  match,
  order,
  l1Object,
  tiktokAdGroupList
}) => {

  const renderEditAdGroupDraftView = props => {
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <AdGroupDraftSetupView
          order={order}
          l1Object={l1Object}
          tiktokAdGroupList={tiktokAdGroupList}
          useModel={useEditTiktokAdGroupDraftSetupFlowPageModel}
        />
      </PermissionChecker>
    );
  };

  const redirectToL1Object = (props) => {
    const match = props.match.url.match(/\/campaign-groups\/\d+\//);
    return <Redirect to={`${match[0].slice(0, -1)}`} />;
  };

  return (
    <Switch>
      <RouteWithPermission
        permissionAware={hasFuncs(Permission.CAMPAIGN_WRITE).and(addOnEnabled(ADDONFEATURE.CHANNEL.TIKTOK))}
        redirectPath={`${match.url}/${ROUTE_PATH.ERROR403}`}
        exact
        path={`${match.url}/:draftId/edit`}
        render={renderEditAdGroupDraftView}
      />
      <Route
        render={redirectToL1Object}
      />
    </Switch>
  );
};
