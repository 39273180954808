import React, { useMemo } from 'react';
import { connect, FormikContextType } from 'formik';
import { FormContent } from 'components/common/form/Form';
import { CtvFormProps } from './CtvFormModel';
import { useVideoFormConfig } from './useVideoFormConfig';
import i18n from 'i18n';

export const CtvForm: React.FunctionComponent<CtvFormProps & { formik: FormikContextType<any> }> = props => {

  const { values } = props.formik;
  const videoSrc = values.typeProperties.videoSrc;
  const isThirdPartyVideo = videoSrc === '3rd';
  const isEditing = values.creativeId !== undefined;
  const skippableSetting = values.typeProperties.skippableSetting;
  const skippable = skippableSetting.skippable;

  const validateTenMaxVideo = props.model.validateTenMaxVideo;

  const hints = useMemo(() => [
    i18n.t<string>('creativeSetupFlow.labels.videoTypeHint'),
    i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '100MB' })
  ], []);

  const formConfig = useVideoFormConfig(
    props.basicFields,
    isEditing,
    isThirdPartyVideo,
    skippable,
    hints,
    validateTenMaxVideo,
    props.getInitFormConfig
  );

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(CtvForm);
