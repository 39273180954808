import React from 'react';
import ReactDOM from 'react-dom';
import './LoadingIndicator.scss';

import i18n from 'i18n';

export type LoadingIndicatorProps = {

  readonly text: string;
};

export type LoadingIndicatorState = {

  readonly loadingLayer?: HTMLDivElement;
};
export class LoadingIndicator extends React.Component<LoadingIndicatorProps, LoadingIndicatorState> {

  static defaultProps = {
    text: 'common.messages.loading'
  };

  constructor (props: LoadingIndicatorProps) {
    super(props);
    this.state = { loadingLayer: undefined };
  }

  componentDidMount () {
    const loadingLayer = document.createElement('div');
    document.body.appendChild(loadingLayer);
    this.setState({
      loadingLayer
    });
  }

  componentWillUnmount () {
    this.state.loadingLayer && document.body.removeChild(this.state.loadingLayer);
  }

  render () {
    if (!this.state.loadingLayer) {
      return;
    }

    const content = (
      <div className='loading-indicator'>
        <div className='board'>
          <div className='roller' />
          <div className='message'>{i18n.t<string>(this.props.text)}</div>
        </div>
      </div>
    );
    return ReactDOM.createPortal(content, this.state.loadingLayer);
  }
}
