import React from 'react';
import { ImageInput } from 'components/common/Image/ImageInput';
import styles from './imageFormInput.module.scss';
import classNames from 'classnames/bind';
import _ from 'lodash';

type ImageFormInputType = {
  className?: string,
  onChange: Function,
  validTypes: string[],
  errorBorderHint: boolean,
  url: string,
  file?: File,
  maxWidth?: number,
  maxHeight?: number
};

export const ImageFormInput: React.FunctionComponent<ImageFormInputType> = (props) => {

  const cssClassNames = classNames.bind(styles);

  const onFileChange = (file) => {
    if (!file) {
      props.onChange && props.onChange({
        file: undefined,
        url: undefined
      });
      return;
    }
    const validTypes = props.validTypes;
    if (validTypes.indexOf(file.type) === -1) {
      props.onChange && props.onChange({
        file,
        url: undefined
      });
      return;
    }
    const reader = new FileReader();
    reader.onload = () => {
      const image = new Image();
      const src = reader.result as string;
      image.src = src;
      image.onload = () => {
        props.onChange && props.onChange({
          file,
          width: image.width,
          height: image.height,
          url: undefined,
          imgBase64: src
        });
      };
    };
    reader.readAsDataURL(file);
  };
  const mainClassName = cssClassNames('fileFormInput', props.className, {
    showErrorBorder: props.errorBorderHint,
    showSolidBorder: props.url || props.file
  });

  return (
    <div className={mainClassName}>
      <ImageInput
        {..._.omit(props, ['className'])}
        onChange={onFileChange}
      />
    </div>
  );
};

ImageFormInput.defaultProps = {
  validTypes: ['image/jpeg', 'image/jpg', 'image/png']
};
