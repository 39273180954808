import React from 'react';
import styles from './elementScrollControl.module.scss';
import { SetRefContext } from 'contexts/SetRefContext';

export class ElementScrollControl extends React.Component<React.PropsWithChildren<{}>, { hiddenSpaceWidth: number }> {

  elementRef: any;
  containerRef: any;

  constructor (props) {
    super(props);
    this.elementRef = React.createRef();
    this.containerRef = React.createRef();
    this.state = {
      hiddenSpaceWidth: 0
    };
  }

  handleScroll = (event) => {
    const element = this.elementRef.current;
    if (element && event.target === this.containerRef.current) {
      element.scrollLeft = event.target.scrollLeft;
    }
  }

  setRef = (elementRef) => {
    if (elementRef) {
      this.elementRef.current = elementRef;
      if (this.state.hiddenSpaceWidth !== elementRef.scrollWidth) {
        this.setState({
          hiddenSpaceWidth: elementRef.scrollWidth
        });
      }
    } else if (elementRef === undefined && this.state.hiddenSpaceWidth !== 0) {
      this.setState({
        hiddenSpaceWidth: 0
      });
    }
  }

  render () {
    return (
      <SetRefContext.Provider value={{ setRef: this.setRef }}>
        <div
          className={styles.container}
          ref={this.containerRef}
          onScroll={this.handleScroll}
        >
          <div
            className={styles.hiddenSpace}
            style={{
              width: this.state.hiddenSpaceWidth
            }}
          />
          <div className={styles.stickyPart}>
            {this.props.children}
          </div>
        </div>
      </SetRefContext.Provider>
    );
  }
}
