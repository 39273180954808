import React from 'react';
import { Tabs, Tab, Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { Link, Redirect } from 'react-router-dom';

import style from './agencyForm.module.scss';
import { AgencyFormModel, AgencyFormProps, AgencyFormState } from '../AgencyForm/AgencyFormModel';

import { LoadingIndicator } from 'components/common/LoadingIndicator';
import i18n from 'i18n';
import AdLogoForm from 'components/AdLogoForm/AdLogoForm';
import { DefaultAdLogoFormModel } from 'components/AdLogoForm/AdLogoFormModel';
import AgencyBasicForm from 'components/AgencyForm/AgencyBasicForm';
import AgencyAddonForm from 'components/AgencyForm/AgencyAddonForm';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { ErrorHandler } from 'components/common/form/FormErrorHandler/FormErrorHandler';
import _ from 'lodash';

export class AgencyForm extends React.Component<AgencyFormProps, AgencyFormState> {
  model: AgencyFormModel;
  handler: number;
  mainDomRef: any;
  constructor (props: any) {
    super(props);
    this.model = this.props.model;
    this.state = this.model.state;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePublisherIds = this.handleChangePublisherIds.bind(this);
    this.handler = this.model.event.add(model => {
      this.setState(model.state);
    });
    this.mainDomRef = React.createRef();
  }

  componentDidMount () {
    this.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      this.model = this.props.model;
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleSubmit (request: any) {
    this.model.save(request);
  }

  handleChangePublisherIds (publisherIds) {
    this.model.changePublisherIds(publisherIds);
  }

  afterValidateForm = (formErrors) => {
    if (!formErrors || _.isEmpty(formErrors)) {
      this.model.setErrors(undefined);
      return;
    }
    const goAdLogoTag = formErrors['adLogo'] || formErrors['logoData'];
    if (goAdLogoTag) {
      this.model.onChangeTab('adlogo');
    } else {
      this.model.onChangeTab(formErrors['addonProps'] || formErrors['bidPriceBase'] ? 'addon' : 'basic');
    }
    this.model.setErrors(formErrors);
  }

  render () {
    const {
      defaultAgency,
      validate,
      cancelPath,
      timeZoneOptions,
      languageOptions,
      currencyOptions,
      priorityOptions,
      publisherCountryOptions,
      adxOptions,
      countryOptions,
      spaceOptions,
      segmentCountryOptions,
      segmentLabelOptions,
      title,
      isNew,
      currencyRates
    } = this.model;
    const { isLoading, isOptionsLoading, redirectUrl } = this.state;
    if (isLoading) {
      return (<LoadingIndicator />);
    }

    if (redirectUrl) {
      return (
        <Redirect to={redirectUrl} push />
      );
    }
    const enableAdLogo = defaultAgency.addonFeatures.creatives.customizeAdLogo;
    const adLogoFormModel = enableAdLogo ? new DefaultAdLogoFormModel() : undefined;
    return (
      <div className={style.container}>
        <header className={style.topPanel}>
          <TitleWithBreadcrumbs
            title={i18n.t<string>(title)}
            routes={this.props.model.breadcrumbs}
          />
        </header>
        <div className={style.innerContent} ref={this.mainDomRef}>
          <Formik
            initialValues={defaultAgency}
            enableReinitialize
            onSubmit={this.handleSubmit}
            validate={validate}
            validateOnBlur={false}
          >
            {
              (formikContext) => {
                const onSubmitButtonClick = () => {
                  formikContext.validateForm().then(this.afterValidateForm);
                };
                return (
                  <>
                    <ErrorHandler
                      parentRef={this.mainDomRef}
                      isSubmitting={formikContext.isSubmitting}
                      submitCount={formikContext.submitCount}
                    />
                    <Form>
                      <Tabs activeKey={this.model.state.currentTab} id='agency-detail' onSelect={this.model.onChangeTab}>
                        <Tab eventKey='basic' title={i18n.t<string>('agencyDetail.labels.basicTab')}>
                          <AgencyBasicForm
                            currencyRates={currencyRates}
                            timeZoneOptions={timeZoneOptions}
                            languageOptions={languageOptions}
                            currencyOptions={currencyOptions}
                            priorityOptions={priorityOptions}
                            publisherCountryOptions={publisherCountryOptions}
                            adxOptions={adxOptions}
                            countryOptions={countryOptions}
                            spaceOptions={spaceOptions}
                            segmentCountryOptions={segmentCountryOptions}
                            segmentLabelOptions={segmentLabelOptions}
                            isOptionsLoading={isOptionsLoading}
                            isNew={isNew}
                            changePublisherIds={this.handleChangePublisherIds}
                          />
                        </Tab>
                        <Tab eventKey='addon' title={i18n.t<string>('agencyDetail.labels.addonFeatureTab')} >
                          <AgencyAddonForm />
                        </Tab>
                        {adLogoFormModel &&
                          <Tab eventKey='adlogo' title={i18n.t<string>('agencyDetail.labels.adLogoTab')} >
                            <AdLogoForm model={adLogoFormModel} />
                          </Tab>
                        }
                      </Tabs>
                      <div className={style.buttonArea}>
                        <Button size='sm' type='submit' onClick={onSubmitButtonClick}>
                          {i18n.t<string>('common.buttons.submit')}
                        </Button>
                        <Link to={cancelPath} className='btn btn-secondary btn-sm'>
                          {i18n.t<string>('common.buttons.cancel')}
                        </Link>
                      </div>
                    </Form>
                  </>
                );
              }
            }
          </Formik>
        </div>
      </div>
    );
  }
}
