import React, { RefObject } from 'react';
import styles from './searchKeywordsComponent.module.scss';
import { SearchKeywordsComponentProps, SearchKeywordsComponentState } from './SearchKeywordsComponentModel';
import { Form } from 'react-bootstrap';
import i18n from 'i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

export class SearchKeywordsComponent extends React.Component<SearchKeywordsComponentProps, SearchKeywordsComponentState> {

  searchKeywordsRef: RefObject<HTMLInputElement> = React.createRef();
  handler: number;

  constructor (props) {
    super(props);
    this.state = this.props.model.state;
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.props.model.syncModel(prevProps.model);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  handleOnConfirm = () => {
    this.props.model.onConfirm();
    this.handleOnClear();
  }

  handleOnClear = () => {
    this.props.model.changeSearchKeyword();
    if (this.searchKeywordsRef.current) {
      this.searchKeywordsRef.current.value = '';
    }
  }

  handleChangeSearchKeyword = (e) => {
    this.props.model.changeSearchKeyword(e.target.value);
  }

  renderRelatedKeywords = () => {
    const selectedValue = this.props.model.value.map(option => option.value);
    return this.state.relatedSearchKeywords.map(keywordData => {
      const { keyword, searchCount } = keywordData;
      const onAddKeyword = () => this.props.model.addSearchKeyword(keyword);
      const disabled = selectedValue.includes(keyword);
      return (
        <div
          key={keyword}
          className={
            cx(styles.relatedKeyword, {
              disabled
            })
          }
          onClick={disabled ? undefined : onAddKeyword}
        >
          <div>{keyword}</div>
          <div>{`${i18n.t<string>('common.labels.total')} : ${searchCount}`}</div>
          <div><FontAwesomeIcon icon={faPlus}/></div>
        </div>
      );
    });
  }

  render () {
    return (
      <div className={styles.searchKeywordsComponent}>
        <div className={styles.title}>
          {i18n.t<string>('limitation.labels.searchKeywordsTitle')}
        </div>
        <hr />
        <div className={styles.body}>
          <div className={styles.descript}>
            {i18n.t<string>('limitation.labels.searchKeywordsDes')}
          </div>
          <div className={styles.row}>
            <Form.Control
              ref={this.searchKeywordsRef as RefObject<any>}
              type='text'
              className={styles.input}
              name='searchKeywords'
              onChange={this.handleChangeSearchKeyword}
              autoComplete='off'
              placeholder={i18n.t<string>('limitation.placeholders.searchKeywords')}
              defaultValue={this.props.model.state.searchKeywords}
            />
            <div className={styles.addButton} onClick={this.handleOnConfirm}>
              <FontAwesomeIcon icon={faPlus}/>
            </div>
          </div>
          <div className={styles.relatedKeywordsContainer}>
            {this.state.loading &&
              <div className={styles.loadingIndicator}>
                <div className={styles.roller} />
              </div>
            }
            {this.state.relatedSearchKeywords.length > 0 &&
              <>
                <div className={styles.relatedKeywordsTitle}>
                  {i18n.t<string>('limitation.labels.relatedKeywordsTitle')}
                </div>
                <div className={styles.relatedKeywords}>
                  {this.renderRelatedKeywords()}
                </div>
              </>
            }
          </div>
          {this.state.searchKeywords && this.state.searchKeywords !== '' &&
            <button
              type='button'
              className={`btn btn-secondary btn-sm ${styles.bottom}`}
              onClick={this.handleOnClear}
            >
              {i18n.t<string>('limitation.buttons.clear')}
            </button>
          }
        </div>
      </div>
    );
  }
}
