import React, { useCallback, useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { RetailNativeProductFormProps } from './RetailNativeProductFormModel';
import styles from './retailNativeProductForm.module.scss';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { FormConfig } from 'components/common/form/FormConfig';
import _, { defaultTo } from 'lodash';
import { useRetailOptionsHook } from './useRetailOptionsHook';
import { CreativeType } from 'core/creative/Creative';
import { FormContent } from 'components/common/form/Form';
import { validateEmpty } from 'utils/ValidateUtils';

export const RetailNativeProductForm: React.FC<RetailNativeProductFormProps & { formik: FormikContextType<any> }> = (props) => {

  const { values, setFieldValue, setValues } = props.formik;
  const advertiserId = values.advertiserId;
  const {
    loading,
    defaultRetailer,
    retailerOptions,
    productSetOptions,
    layoutOptions,
    callToActionOptions,
    onRetailerChange
  } = useRetailOptionsHook(advertiserId, props.model.flowPageModel, CreativeType.RETAIL_NATIVE_PRODUCT);

  useEffect(() => {
    setFieldValue('typeProperties.retailer', defaultRetailer);
  }, [defaultRetailer, setFieldValue]);

  const [formConfig, setFormConfig] = useState<FormConfig>(props.getInitFormConfig());

  const retailerChangeHandler = useCallback(value => {
    setValues(prev => ({
      ...prev,
      typeProperties: {
        ...defaultTo(prev.typeProperties, {}),
        productSet: undefined,
        productSetId: undefined,
        layout: undefined
      }
    }));
    onRetailerChange(value);
  }, [setValues, onRetailerChange]);

  const productSetChangeHandler = useCallback(value => {
    let productSetOption = productSetOptions.find(productSet => productSet.value === value);
    let name = _.get(productSetOption, 'label', '');
    let id = _.get(productSetOption, 'value', '');
    setValues(prev => ({
      ...prev,
      typeProperties: {
        ...defaultTo(prev.typeProperties, {}),
        productSet: name,
        productSetId: id
      }
    }));
  }, [productSetOptions, setValues]);

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addFormikSelect({
              label: i18n.t<string>('creativeSetupFlow.labels.retailer'),
              simpleValue: true,
              name: 'typeProperties.retailer',
              options: retailerOptions,
              onChange: retailerChangeHandler,
              validate: validateEmpty
            }, props.model.flowPageModel.type !== 'create')
            .addFormikLabel({
              label: i18n.t<string>('creativeSetupFlow.labels.retailer'),
              name: 'typeProperties.retailer',
              formatter: value => value ? i18n.t<string>(`retailers.${value}`) : ''
            }, props.model.flowPageModel.type === 'create')
            .addFormikSelect({
              label: i18n.t<string>('creativeSetupFlow.labels.productSet'),
              simpleValue: true,
              name: 'typeProperties.productSetId',
              options: productSetOptions,
              onChange: productSetChangeHandler,
              validate: validateEmpty
            })
            .addFormikSelect({
              label: i18n.t<string>('creativeSetupFlow.labels.retailLayout'),
              simpleValue: true,
              name: 'typeProperties.layout',
              options: layoutOptions,
              validate: validateEmpty
            })
            .build()
        )
        .addAdvancedFields(new FormConfig.FieldsBuilder()
          .addFormikFileInput({
            type: 'image',
            label: i18n.t<string>('creativeSetupFlow.labels.retailLogo'),
            name: 'medias.logo',
            hints: [i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })],
            className: styles.logo,
            validate: props.model.validateImage
          })
          .addFormikSelect({
            label: i18n.t<string>('creativeSetupFlow.labels.retailCallToAction'),
            simpleValue: true,
            name: 'typeProperties.callToAction',
            options: callToActionOptions
          })
          .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      ).build()
    );
  }, [
    props.basicFields,
    props.model.flowPageModel.type,
    layoutOptions,
    retailerOptions,
    productSetOptions,
    callToActionOptions,
    setFieldValue,
    onRetailerChange,
    retailerChangeHandler,
    productSetChangeHandler,
    props.model.validateImage
  ]);

  return (
    <div>
      {loading && <LoadingIndicator/>}
      <FormContent
        formConfig={formConfig}
      />
    </div>
  );
};

export default connect(RetailNativeProductForm);
