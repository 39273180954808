import React, { useMemo } from 'react';
import i18n from 'i18n';
import styles from './audienceInfo.module.scss';
import { Status } from 'components/Status/Status';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { numberWithCommas } from 'utils/StringUtil';
import { LoadingCircle } from 'components/LoadingCircle/LoadingCircle';
import classnames from 'classnames/bind';
const cx = classnames.bind(styles);

export const AudienceInfo: React.FC<{
  layoutDirection?: 'row' | 'column'
  loading: boolean,
  lowerBound: number,
  upperBound: number,
  threshold: number,
  estimateReady: boolean
  refreshData?: () => void
  warning?: string,
  estimatedHint?: string
}> = ({
  layoutDirection = 'row',
  loading,
  lowerBound,
  upperBound,
  threshold,
  estimateReady,
  refreshData,
  warning,
  estimatedHint
}) => {

  const viewData = useMemo(() => {
    if (!estimateReady) {
      return {
        status: {
          color: 'warning',
          label: 'Not Ready'
        },
        warning
      };
    }
    if (lowerBound < threshold) {
      return {
        status: {
          color: 'danger',
          label: 'Narrow'
        },
        warning: i18n.t<string>('audienceInfo.labels.audienceSizeTooSmall')
      };
    }
    return {
      status: {
        color: 'success',
        label: 'Broad'
      },
      warning
    };
  }, [
    lowerBound,
    threshold,
    estimateReady,
    warning
  ]);

  const audienceInfoClass = cx(styles.audienceInfo, {
    row: layoutDirection === 'row'
  });
  const audienceSizeConclusionClass = cx(styles.audienceSizeConclusion, {
    row: layoutDirection === 'row'
  });
  const audienceSizeClass = cx(styles.audienceSize, {
    row: layoutDirection === 'row',
    showEstimateHint: !!estimatedHint
  });
  return (
    <div className={audienceInfoClass}>
      <div className={audienceSizeConclusionClass}>
        <span>{i18n.t<string>('audienceInfo.labels.audienceSelection')}</span>
        <Status {...viewData.status}/>
      </div>
      <div className={audienceSizeClass}>
        <span>{i18n.t<string>('audienceInfo.labels.audienceEstimatedSize')}</span>
        <div className={styles.fieldContainer}>
          <div className={styles.sizeContainer}>
            <span>
              {
                !estimateReady ?
                'N/A' :
                upperBound === -1 ?
                  numberWithCommas(lowerBound) :
                  `${numberWithCommas(lowerBound)} - ${numberWithCommas(upperBound)}`
              }
            </span>
            {loading && <LoadingCircle className={styles.loadingCircle} width={15} height={15} />}
            {viewData.warning &&
              <IconWithTooltip
                icon={faInfoCircle}
                tooltipProps={{
                  id: 'audienceSizeWarning',
                  tooltip: viewData.warning
                }}
              />
            }
            {refreshData &&
              <div
                style={{ marginLeft: '15px', cursor: 'pointer' }}
                className='btn btn-secondary btn-group-sm'
                onClick={refreshData}
              >
                {i18n.t<string>('audienceInfo.buttons.estimate')}
              </div>
            }
          </div>
          {estimatedHint &&
            <span className={styles.estimatedHint}>
              {i18n.t<string>('audienceInfo.labels.audienceEstimatedHint')}
            </span>
          }
        </div>
      </div>
    </div>
  );
};
