import React from 'react';

type SetRefContextType = {
  setRef (ref: any): void
};

export const SetRefContext = React.createContext<SetRefContextType>({
  setRef: () => {
    // This is intentional
  }
});
