import { RtbCampaignPlanType } from 'core/rtbCampaign/RtbCampaign';
import {
  DefaultRtbCampaignBasicFormModel,
  RtbCampaignBasicFormModel,
  RtbCampaignBasicFormModelConstructorParams
} from './RtbCampaignBasicFormModel';

export interface UnknowAdTypeFormModel extends RtbCampaignBasicFormModel {
  readonly showVideoProgressRadio: boolean;
  readonly canEditBudgetPlan: boolean;
  readonly canEditPriceModel: boolean;
}
abstract class AbstractUnknowAdTypeFormModel extends DefaultRtbCampaignBasicFormModel
  implements UnknowAdTypeFormModel {

  showVideoProgressRadio = true;

  getBidPriceFloorData (): any {
    return [];
  }

  needVideoAdViewObjective (priceModel: RtbCampaignPlanType): boolean {
    return priceModel === RtbCampaignPlanType.FCPV;
  }
}

export class EditUnknowAdTypeFormModel extends AbstractUnknowAdTypeFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('edit', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return false;
  }
  get canEditPriceModel (): boolean {
    return false;
  }
}

export class CreateUnknowAdTypeFormModel extends AbstractUnknowAdTypeFormModel {
  constructor (...args: RtbCampaignBasicFormModelConstructorParams) {
    super('create', ...args);
  }
  get canEditBudgetPlan (): boolean {
    return true;
  }
  get canEditPriceModel (): boolean {
    return true;
  }
}
