import React from 'react';
import { SegmentDetailState, SegmentDetailProps, IMPORT_FILE_SIZE_MAXIMUM } from './SegmentDetailModel';
import { SubSegmentList } from 'components/SubSegmentList/SubSegmentList';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Link, Route, Switch, Redirect } from 'react-router-dom';
import { Modal } from 'components/common/Modal/Modal';
import styles from './segmentDetail.module.scss';
import i18n from 'i18n';
import { SubSegmentForm } from '../SubSegmentForm/SubSegmentForm';
import _ from 'lodash';
import { withOverlayHoverTip } from 'hoc/withOverlayHoverTip';
import { NavDropdown, Tooltip } from 'react-bootstrap';
import { numberWithCommas } from 'utils/StringUtil';
import { Permission } from 'core/auth/Permission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { SegmentImportType } from 'core/segment/SegmentManager';

export class SegmentDetail extends React.Component<SegmentDetailProps, SegmentDetailState> {

  handler?: number;
  importInputRef: any;
  constructor (props) {
    super(props);
    this.importInputRef = React.createRef();
  }

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.onUnmount(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  renderSegmentData = (segmentViewData) => {
    return Object.keys(segmentViewData).map(key => {
      const title = i18n.t<string>(`segmentDetail.labels.${key}`);
      let value = segmentViewData[key];
      return (
        <div className={styles.field} key={key}>
          <div className={styles.fieldLabel}>
            {title}
          </div>
          <div className={styles.fieldValue}>
            {value}
          </div>
        </div>
      );
    });
  }

  renderModal (modalData) {
    const model = this.props.model;
    const ModalContent: typeof React.Component = modalData.component;
    return (
      <Modal
        fullScreen={modalData.fullScreen}
        title={modalData.title}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.close'),
          callback: model.hideModal
        }}
        dismiss={model.hideModal}
        animation={false}
      >
        {ModalContent ? <ModalContent {...modalData.componentProps} /> : modalData.message}
      </Modal>
    );
  }

  renderSubSegmentCreateForm = () => {
    return (
      <SubSegmentForm model={this.props.model.getCreateSubSegmentFormModel()}/>
    );
  }

  renderSubSegmentEditForm = (props) => {
    const subSegmentId = parseInt(props.match.params.segmentId, 10);
    const model = this.props.model.getEditSubSegmentFormModel(subSegmentId);
    if (!model) {
      const segment = this.props.model.segment;
      return <Redirect to={segment ? `/audience-segments/${segment.id}` : '/audience-segments'}/>;
    }
    return (
      <SubSegmentForm model={model}/>
    );
  }

  onImport = (event) => {
    const files = _.get(event, 'currentTarget.files', []);
    if (!files[0]) {
      return;
    }
    this.props.model.importAudiences(files[0], () => {
      if (this.importInputRef.current) {
        this.importInputRef.current.value = '';
      }
    });
  }

  renderContent = () => {
    const model = this.props.model;
    const segment = model.segment;
    if (!segment) {
      return <LoadingIndicator />;
    }

    const onImportCookiesBtnClick = () => {
      model.setImportType(SegmentImportType.COOKIE);
      this.importInputRef.current && this.importInputRef.current.click();
    };
    const onImportDeviceIdsBtnClick = () => {
      model.setImportType(SegmentImportType.DEVICE_ID);
      this.importInputRef.current && this.importInputRef.current.click();
    };
    const onReImportBtnClick = () => {
      model.reImportAudiences();
    };
    const onExportBtnClick = () => {
      model.exportAudiences();
    };

    const renderImportDropDown = () => {
      const handleNavEvent = (e) => {
        e && e.stopPropagation();
      };

      const DropdownItemWithTip = withOverlayHoverTip(
        NavDropdown.Item, (
        <Tooltip id={`importAudiencesTip-${segment.id}`}>
          {i18n.t<string>('segmentDetail.labels.fileSizeErrorModalContent', { bytes: numberWithCommas(IMPORT_FILE_SIZE_MAXIMUM) })}
        </Tooltip>)
      );

      return (
        <NavDropdown title={i18n.t<string>('segmentDetail.labels.import')} id='importSegmentBtn' onClick={handleNavEvent}>
          <DropdownItemWithTip target='_blank' onClick={onImportCookiesBtnClick}>
            {i18n.t<string>('segmentDetail.labels.importCookies')}
          </DropdownItemWithTip>
          <DropdownItemWithTip target='_blank' onClick={onImportDeviceIdsBtnClick}>
            {i18n.t<string>('segmentDetail.labels.importDeviceIds')}
          </DropdownItemWithTip>
        </NavDropdown>
      );
    };

    return (
      <div className={styles.segmentDetailContainer}>
        {model.state.loading && <LoadingIndicator />}
        <TitleWithBreadcrumbs
          title={i18n.t<string>('segmentDetail.labels.title')}
          routes={[
            { path: '/audience-segments', breadcrumb: i18n.t<string>('segmentDetail.labels.title') },
            {
              path: '/audience-segments/:segmentId',
              breadcrumb: DynamicBreadcrumb,
              props: { label: _.get(model.segment, 'name'), matchParam: 'segmentId' }
            }
          ]}
        />
        <div className={styles.titleBottomLine} />
        <div className={styles.segmentDetail}>
          <div className={styles.topArea}>
            <div className={styles.segmentName}>
              {i18n.t<string>('segmentDetail.labels.name', { name: segment.name })}
            </div>
            <div className={styles.buttonArea}>
              <PermissionChecker permissionAware={hasFuncs(Permission.SEGMENT_WRITE)}>
                <Link to={`${segment.id}/edit`}>{i18n.t<string>('segmentDetail.labels.editSegment')}</Link>
              </PermissionChecker>
              <div onClick={model.showCodeModal}>
                {i18n.t<string>('segmentDetail.labels.code')}
              </div>
              <PermissionChecker permissionAware={hasFuncs(Permission.SEGMENT_WRITE)}>
                <input
                  accept='text/csv'
                  type='file'
                  ref={this.importInputRef}
                  onChange={this.onImport}
                />
                {renderImportDropDown()}
              </PermissionChecker>
              <PermissionChecker permissionAware={hasFuncs(Permission.SEGMENT_WRITE)}>
                <div onClick={onReImportBtnClick}>
                  {i18n.t<string>('segmentDetail.labels.reset')}
                </div>
              </PermissionChecker>
              <div onClick={onExportBtnClick}>
                {i18n.t<string>('segmentDetail.labels.export')}
              </div>
            </div>
          </div>
          <div className={styles.detail}>
            <div className={styles.sepLine} />
            <div className={styles.basic}>
              {this.renderSegmentData(model.state.segmentViewData)}
            </div>
          </div>
        </div>
        <SubSegmentList model={model.getSubSegmentListModel()}/>
        {model.state.modalData && this.renderModal(model.state.modalData)}
      </div>
    );
  }

  render () {
    const model = this.props.model;
    const segment = model.segment;
    if (!segment) {
      return <LoadingIndicator />;
    }

    return (
      <Switch>
        <Route
          path={`/audience-segments/${segment.id}/subSegments/new`}
          render={this.renderSubSegmentCreateForm}
        />
        <Route
          path={`/audience-segments/${segment.id}/subSegments/:segmentId/edit`}
          render={this.renderSubSegmentEditForm}
        />
        <Route
          render={this.renderContent}
        />
      </Switch>
    );
  }
}
