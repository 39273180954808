export const fbTAInventories = {
  geo_locations: {
    name: 'geo_locations',
    title: 'limitation.labels.geography',
    i18nPrefix: 'geoLocations.fb'
  },
  publisher_platforms: {
    name: 'publisher_platforms',
    title: 'targeting.fb.platformsTitle'
  },
  segment: {
    name: 'segment',
    title: 'limitation.labels.gojekSegment',
    i18nPrefix: 'targeting.fb.segment'
  },
  user_os: {
    name: 'user_os',
    title: 'limitation.labels.os'
  },
  user_device: {
    name: 'user_device',
    title: 'limitation.labels.device',
    i18nPrefix: 'limitationOptions.labels.device'
  }
};
