import React from 'react';
import { Link } from 'react-router-dom';
import { faPencilAlt, faTrashAlt, faCloudUploadAlt, faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';
import { SubSegmentListProps, SubSegmentListState, SubSegmentListColumns, IMPORT_FILE_SIZE_MAXIMUM } from './SubSegmentListModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { renderColumn } from 'components/TableColumn/TableColumn';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { SearchBar } from 'components/common/SearchBar';
import { Modal } from 'components/common/Modal/Modal';
import styles from './subSegmentList.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { numberWithCommas } from 'utils/StringUtil';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';

export class SubSegmentList extends React.Component<SubSegmentListProps, SubSegmentListState> {

  handler?: number;
  columnFormatterMap: any;
  inputRefs: any;

  constructor (props) {
    super(props);
    this.columnFormatterMap = {
      [SubSegmentListColumns.NAME]: this.nameFormatter,
      [SubSegmentListColumns.EDITBTNS]: this.floatingEditBtnsFormatter
    };
    this.inputRefs = {};
  }

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
    if (this.props.model.state.needUpdateViewModelData) {
      this.props.model.updateViewModelData();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.onUnmount(this.handler);
  }

  nameFormatter = (id: any, { name }: any): any => {
    const renderLabel = () => <span className={styles.segmentName}>{name}</span>;
    return (
      <div className={`${styles.nameCell}`}>
        <PermissionChecker
          permissionAware={hasFuncs(Permission.SEGMENT_WRITE)}
          renderOtherwise={renderLabel}
        >
          <Link to={`/audience-segments/${this.props.model.parentId}/subSegments/${id}/edit`} className={styles.segmentName}>
            {name}
          </Link>
        </PermissionChecker>
        <div className={styles.segmentId}>
          {'ID: ' + id}
        </div>
      </div>
    );
  }

  onImport = (segmentId, event) => {
    const files = _.get(event, 'currentTarget.files', []);
    if (!files[0]) {
      return;
    }
    this.props.model.importAudiences(segmentId, files[0], () => {
      if (this.inputRefs[segmentId]) {
        this.inputRefs[segmentId].value = '';
      }
    });
  }

  floatingEditBtnsFormatter = (cell: any, segment: any): any => {
    const onDeleteBtnClick = () => {
      this.props.model.showDeleteModal(segment.id);
    };
    const onImportBtnClick = () => {
      this.inputRefs[segment.id] && this.inputRefs[segment.id].click();
    };
    const onExportBtnClick = () => {
      this.props.model.exportAudiences(segment.id);
    };
    return (
      <TableRowToolBar className={styles.floatingEditArea}>
        <PermissionChecker permissionAware={hasFuncs(Permission.SEGMENT_WRITE)}>
          <Link
            to={`/audience-segments/${this.props.model.parentId}/subSegments/${segment.id}/edit`}
          >
            <IconWithTooltip
              icon={faPencilAlt}
              tooltipProps={{
                id: `subSegmentListEditTip-${segment.id}`,
                tooltip: i18n.t<string>('subSegmentList.labels.editHint')
              }}
            />
          </Link>
          <IconWithTooltip
            icon={faCloudUploadAlt}
            onClick={onImportBtnClick}
            tooltipProps={{
              id: `importAudiencesTip-${segment.id}`,
              tooltip: i18n.t<string>('segmentDetail.labels.fileSizeErrorModalContent', { bytes: numberWithCommas(IMPORT_FILE_SIZE_MAXIMUM) })
            }}
          />
        </PermissionChecker>
        <IconWithTooltip
          icon={faCloudDownloadAlt}
          onClick={onExportBtnClick}
          tooltipProps={{
            id: `subSegmentListExportTip-${segment.id}`,
            tooltip: i18n.t<string>('subSegmentList.labels.exportHint')
          }}
        />
        <PermissionChecker permissionAware={hasFuncs(Permission.SEGMENT_DEL)}>
          <IconWithTooltip
            icon={faTrashAlt}
            onClick={onDeleteBtnClick}
            tooltipProps={{
              id: `subSegmentListDeleteTip-${segment.id}`,
              tooltip: i18n.t<string>('subSegmentList.labels.deleteHint')
            }}
          />
        </PermissionChecker>
        <input
          accept='text/csv'
          className={styles.importBtn}
          type='file'
          ref={el => this.inputRefs[segment.id] = el}
          onChange={_.curry(this.onImport)(segment.id)}
        />
      </TableRowToolBar>
    );
  }

  handleOnSearch = (searchString) => {
    this.props.model.handleOnSearch(searchString);
  }

  renderModal (modalData) {
    const model = this.props.model;
    return (
      <Modal
        title={modalData.title}
        primaryButton={modalData.confirmBtnData && {
          title: modalData.confirmBtnData.title,
          callback: modalData.confirmBtnData.callback
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.close'),
          callback: model.hideModal
        }}
        dangerButton={modalData.dangerBtnData}
        dismiss={model.hideModal}
        animation={false}
      >
        {modalData.message}
      </Modal>
    );
  }

  render () {
    const model = this.props.model;
    const viewModel = model.state.viewModel;
    const columns = Object.values(SubSegmentListColumns).map(columnToRender => {
      return renderColumn(model.getColumnDefinition(columnToRender), this.columnFormatterMap[columnToRender]);
    });

    return (
      <div className={styles.subSegmentListContainer}>
        {model.state.loading && <LoadingIndicator/>}
        <div className={styles.operationArea}>
          <PermissionChecker permissionAware={hasFuncs(Permission.SEGMENT_WRITE)}>
            <Link to={`/audience-segments/${model.parentId}/subSegments/new`}>{i18n.t<string>('subSegmentList.labels.newSegment')}</Link>
          </PermissionChecker>
          <SearchBar
            model={{
              placeholder: i18n.t<string>('subSegmentList.placeholders.searchbar'),
              search: this.handleOnSearch,
              defaultValue: model.searchString
            }}
          />
        </div>
        <div className={styles.subSegmentList}>
          <StickableBootstrapTable
            stickFirstColumn
            stickLastColumn
            keyField={'id'}
            data={viewModel}
            columns={columns}
            defaultSorted={model.sortDescriptor}
            noDataIndication={i18n.t<string>('subSegmentList.labels.nodata')}
          />
        </div>
        {model.state.modalData && this.renderModal(model.state.modalData)}
      </div>
    );
  }
}
