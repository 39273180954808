import _ from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import i18n from 'i18n';

export function createSelectOptions (
  arrayValues: (string | number)[],
  i18nPrefix = '',
  formatter?: Function
): SelectOptions[] {
  return arrayValues.map((value: string | number) => {
    const i18nParam = formatter ? formatter(value) : value;
    const i18nKey = `${i18nPrefix}${i18nParam}`;
    return {
      label: i18n.exists(i18nKey) ? i18n.t<string>(`${i18nPrefix}${i18nParam}`) : value.toString(),
      value
    };
  });
}

export function pickValues (
  selectOptions: SelectOptions[]
): (string | number)[] {
  return _.map(selectOptions, 'value');
}

export function pickValue (selectOptions: SelectOptions): string | number {
  return _.get(selectOptions, 'value');
}

export function findValue (
  selectOptions: SelectOptions[],
  value: string | number
): SelectOptions | undefined {
  return _.find(selectOptions, { value });
}

export function filter (
  selectOptions: SelectOptions[],
  toBeKeeped: string[] | number[]
) {
  return selectOptions.filter(option => {
    return toBeKeeped.includes(option.value as never);
  });
}

export function createSelectOptionsFromEnum (
  enumData,
  i18nPrefix = '',
  ignoreValues: any = [],
  valueDecorater = value => value.toLowerCase()
): SelectOptions[] {
  return Object.keys(enumData)
    .filter(isNaN as any)
    .reduce((acc: SelectOptions[], value: string) => {
      if (ignoreValues.includes(enumData[value])) {
        return acc;
      }
      acc.push({
        label: i18n.t<string>(`${i18nPrefix}${valueDecorater(value)}`),
        value: enumData[value]
      });
      return acc;
    }, []);
}
