import React from 'react';
import { Link } from 'react-router-dom';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { SegmentListProps, SegmentListState, SegmentListColumns } from './SegmentListModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { ColumnDefinition, getNameIdColumn } from 'components/TableColumn/TableColumn';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { TablePlaceholder } from 'components/TablePlaceholder/TablePlaceholder';
import { Modal } from 'components/common/Modal/Modal';
import styles from './segmentList.module.scss';
import i18n from 'i18n';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs, notRoles } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { RoleNames } from 'core';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import { defaultTo } from 'lodash';

export class SegmentList extends React.Component<SegmentListProps, SegmentListState> {

  handler?: number;
  columnSetupExtraFuncs: any;

  constructor (props) {
    super(props);
    this.columnSetupExtraFuncs = {
      [SegmentListColumns.NAME]: (column: ColumnDefinition) => getNameIdColumn(column, segment => `/audience-segments/${segment.id}`),
      [SegmentListColumns.DURATION]: (column: ColumnDefinition) => ({
        ...column,
        formatter: value => i18n.t<string>(`segmentList.labels.duration`, { days: value })
      })
    };
  }

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.onUnmount(this.handler);
  }

  renderRowBtns = (segment: any) => {
    const onDeleteClickHandler = () => {
      this.props.model.showDeleteModal(segment.id);
    };
    return [
      <PermissionChecker key={`edit-${segment.id}`} permissionAware={hasFuncs(Permission.SEGMENT_WRITE)}>
        <IconWithTooltip
          icon={faPencilAlt}
          tooltipProps={{
            id: `segmentListEditTip-${segment.id}`,
            tooltip: i18n.t<string>('segmentList.labels.editHint'),
            link: `/audience-segments/${segment.id}/edit`
          }}
        />
      </PermissionChecker>,
      <PermissionChecker key={`delete-${segment.id}`} permissionAware={hasFuncs(Permission.SEGMENT_DEL)}>
        <IconWithTooltip
          icon={faTrashAlt}
          onClick={onDeleteClickHandler}
          tooltipProps={{
            id: `segmentListDeleteTip-${segment.id}`,
            tooltip: i18n.t<string>('segmentList.labels.deleteHint')
          }}
        />
      </PermissionChecker>
    ];
  }

  handleOnSearch = (searchString) => {
    this.props.model.handleOnSearch(searchString);
  }

  renderModal (modalData) {
    const model = this.props.model;
    return (
      <Modal
        title={modalData.title}
        primaryButton={modalData.confirmBtnData && {
          title: modalData.confirmBtnData.title,
          callback: modalData.confirmBtnData.callback
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.close'),
          callback: model.hideModal
        }}
        dangerButton={modalData.dangerBtnData}
        dismiss={model.hideModal}
        animation={false}
      >
        {modalData.message}
      </Modal>
    );
  }

  renderSysAdminPlaceholder = () => {
    if (SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY)) {
      return <TablePlaceholder placeholder={i18n.t<string>('segmentList.labels.nodata')}/>;
    }
    return <TablePlaceholder placeholder={i18n.t<string>('segmentList.labels.chooseAgency')}/>;
  }

  renderListNoDataIndication = () => {
    return (
      <PermissionChecker
        permissionAware={notRoles(RoleNames.sysAdmin)}
        renderOtherwise={this.renderSysAdminPlaceholder}
      >
        <TablePlaceholder placeholder={i18n.t<string>('segmentList.labels.nodata')}/>
      </PermissionChecker>
    );
  }

  renderListOperations () {
    return [(
      <PermissionChecker key='new-btn' permissionAware={hasFuncs(Permission.SEGMENT_WRITE)}>
        <Link
          to={'/audience-segments/new'}
          className='btn btn-tertiary btn-sm'
        >
          {i18n.t<string>('segmentList.labels.newSegment')}
        </Link>
      </PermissionChecker>
    )];
  }

  render () {
    const model = this.props.model;
    const viewModel = model.state.viewModel;
    const columns = Object.values(SegmentListColumns).map(columnToRender => {
      const columnSetupExtraFunc = defaultTo(this.columnSetupExtraFuncs[columnToRender], column => column);
      return columnSetupExtraFunc(model.getColumnDefinition(columnToRender));
    });

    return (
      <div className={styles.segmentListContainer}>
        {model.state.loading && <LoadingIndicator/>}
        <div className={styles.segmentList}>
          <StickableBootstrapTable
            keyField={'id'}
            customPadding={false}
            data={viewModel}
            columns={columns}
            defaultSorted={model.sortDescriptor}
            noDataIndication={this.renderListNoDataIndication}
            defaultSearchString={model.searchString}
            onHandleSearch={this.handleOnSearch}
            searchbarPlaceholder={i18n.t<string>('segmentList.placeholders.searchbar')}
            renderRowBtns={this.renderRowBtns}
            renderListOperations={this.renderListOperations}
          />
        </div>
        {model.state.modalData && this.renderModal(model.state.modalData)}
      </div>
    );
  }
}
