import { AbstractFormContentModel } from './FormContentModel';
import OutdoorForm from './OutdoorForm';
import { Creative, OutdoorType } from 'core/creative/Creative';
import _ from 'lodash';
import i18n from 'i18next';
import JSZip from 'jszip';
import { formatBytes } from 'utils/StringUtil';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { BasicFormProps } from './BasicFormProps';

const mimeDB = require('mime-db');

export type OutdoorFormProps = {
  readonly model: OutdoorFormModel;
} & BasicFormProps;

export class OutdoorFormModel extends AbstractFormContentModel {

  public needBannerUrl: boolean = false;

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = () => {
    return OutdoorForm;
  }

  validateVideoImage = (fileData) => {
    return this.validateImage(fileData, 1080, 1313);
  }

  validateAudioImage = (fileData) => {
    return this.validateImage(fileData, 1080, 1920);
  }

  validateImage = (fileData, targetWidth, targetHeight) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];
    const width = fileData.width;
    const height = fileData.height;
    if (width !== targetWidth || height !== targetHeight) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.sizeErrorHint2', { size1: `${width} x ${height}`, size2: `${targetWidth} x ${targetHeight}` }));
    }

    if (file.size > 2097152) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validateVideo = (outdoorType, fileData) => {
    const validTypes = ['video/mp4'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];
    const width = fileData.width;
    const height = fileData.height;
    const proportion = (width / height).toFixed(2);
    const targetProportion = outdoorType === OutdoorType.VIDEO ? (9 / 16).toFixed(2) : (16 / 9).toFixed(2);
    if (proportion !== targetProportion) {
      errors.push(i18n.t<string>('outdoorForm.labels.videoProportionErrorHint', { proportion: outdoorType === OutdoorType.VIDEO ? '9:16' : '16:9' }));
    }

    if (file.size > 513802240) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '490MB' }));
    }
    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validateZip = async (fileData) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const validTypes = ['application/zip', 'application/x-zip-compressed'];
    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('html5Form.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];
    if (file.size > 2097152) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    const zipData = await JSZip.loadAsync(file);
    const contents: string[] = [];
    zipData.forEach((_1, zipEntry) => {
      // @ts-ignore
      contents.push(zipEntry.name);
    });
    if (!contents.includes('index.html')) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.zipContentError'));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validateAudio = async (fileData) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const validTypes = ['audio/mpeg'];
    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];
    if (file.size > 2097152) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getInitTypeProperties () {
    return {
      outdoorType: OutdoorType.VIDEO,
      temperatureEnable: false,
      temperatureRange: [25, 30],
      duration: 30,
      useAudio: false
    };
  }

  getFormModelData (creative: Creative) {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues', 'bannerExtra', 'duration', 'outdoorType']);
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const imageUrl = _.get(creativeValue, 'imageUrl');
    const videoUrl = _.get(creativeValue, 'videoUrl');
    const htmlUrl = _.get(creativeValue, 'htmlUrl');
    const audioUrl = _.get(creativeValue, 'audioUrl');
    const mediaData = imageUrl || videoUrl || htmlUrl || audioUrl ? _.omitBy({
      image: imageUrl ? {
        url: imageUrl
      } : undefined,
      video: videoUrl ? {
        url: videoUrl
      } : undefined,
      html: htmlUrl ? {
        url: htmlUrl
      } : undefined,
      audio: audioUrl ? {
        url: audioUrl
      } : undefined
    }, _.isEmpty) : undefined;
    const useTempControl = _.get(creative.basic, 'bannerExtra.useTempControl', false);
    const minTemp = useTempControl ? _.get(creative.basic, 'bannerExtra.minTemp') : 25;
    const maxTemp = useTempControl ? _.get(creative.basic, 'bannerExtra.maxTemp') : 30;
    const useAudio = _.get(creative.basic, 'bannerExtra.useAudio', false);
    return {
      basic: {
        ...creativeBasic,
        typeProperties: {
          outdoorType: _.get(creative.basic, 'outdoorType', OutdoorType.VIDEO),
          duration: _.get(creative.basic, 'duration', 30),
          temperatureEnable: useTempControl,
          temperatureRange: [minTemp, maxTemp],
          useAudio
        },
        medias: mediaData
      },
      limitations: creative.limitations
    };
  }
}
