import { CloudStorageManager, DefaultCloudStorageManager } from 'core/cloudStorage/CloudStorageManager';
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { useFBPreview } from './useFBPreview';

const defaultCloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager();

export const useOneForAllDisplayPreview = (
  advertiserId: number | string,
  typeProperties: any,
  medias: any,
  cloudStorageManager = defaultCloudStorageManager
): {
  previewData: { [key: string]: string | undefined }
} => {

  const [formData, setFormData] = useState({});
  const { previewData } = useFBPreview(typeProperties, formData);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const squareImgData = _.get(medias, 'squareImg', {});
        if (squareImgData.file && !squareImgData.url) {
          squareImgData.url = await cloudStorageManager.uploadFileToCloud(squareImgData.file);
        }
        const storyImgData = _.get(medias, 'storyImg', {});
        if (storyImgData.file && !storyImgData.url) {
          storyImgData.url = await cloudStorageManager.uploadFileToCloud(storyImgData.file);
        }
        setFormData({
          type: 'DISPLAY',
          advertiserId: advertiserId.toString(),
          imageUrl: squareImgData.url,
          storyImgUrl: storyImgData.url
        });
      } catch (e) {}
    };
    fetchData();
  }, [advertiserId, typeProperties, medias, cloudStorageManager]);

  return {
    previewData
  };
};
