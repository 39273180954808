import React from 'react';
import { CreativeListModelProps } from './CreativeListModel';
import i18n from 'i18n';
import styles from './CreativeList.module.scss';
import classNames from 'classnames/bind';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { Redirect } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, hasFuncs, isPmax3Order } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { TablePlaceholder } from 'components/TablePlaceholder/TablePlaceholder';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { PermissionItem } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';

export class CreativeList extends React.Component<CreativeListModelProps> {
  handler: number;
  cssClassNames: any;
  campaignWritePermission: PermissionItem;

  constructor (props) {
    super(props);
    this.cssClassNames = classNames.bind(styles);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
    const l1Object = this.props.model.l1Object;
    const channelAddonFeature = l1Object ? ADDONFEATURE.CHANNEL[l1Object.channel] : undefined;
    this.campaignWritePermission = channelAddonFeature ?
      hasFuncs(Permission.CAMPAIGN_WRITE).and(addOnEnabled(channelAddonFeature)).and(isPmax3Order(l1Object.adsOrderId)) :
      hasFuncs(Permission.CAMPAIGN_WRITE).and(isPmax3Order(l1Object.adsOrderId));
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  renderListNoDataIndication = () => {
    const renderSystemAdminPlaceholder = () => <TablePlaceholder placeholder={i18n.t<string>('campaignCreativeList.labels.noDataWithoutAddPermission')}/>;
    return (
      <PermissionChecker
        permissionAware={this.campaignWritePermission}
        renderOtherwise={renderSystemAdminPlaceholder}
      >
        <TablePlaceholder placeholder={i18n.t<string>('campaignCreativeList.labels.noData')}/>
      </PermissionChecker>
    );
  }

  render () {
    const model = this.props.model;
    const rowClasses = (creative, rowIndex) => {
      let classes: any = null;
      if (rowIndex === 0) {
        classes = styles.summaryRow;
      }

      if (creative.stateColumn && creative.stateColumn.styleName === 'budgetRemain') {
        classes = [classes, styles.lightDanger].join(' ');
      }

      return classes;
    };

    if (model.state.redirectPath) {
      return <Redirect push to={model.state.redirectPath}/>;
    }
    return (
      <div className={styles.creativeList}>
        {model.state.loading && <LoadingIndicator />}
        <div className={styles.tableContainer}>
          <StickableBootstrapTable
            hover
            keyField='id'
            stickFirstColumn
            stickLastColumn
            data={model.creativeList}
            columns={model.columns}
            hidePagination={true}
            noDataIndication={this.renderListNoDataIndication}
            rowClasses={rowClasses}
          />
        </div>
      </div>
    );
  }
}
