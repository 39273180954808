import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import i18n from 'i18next';
import _, { omit } from 'lodash';
import { FormikProps } from 'formik';
import { toast } from 'react-toastify';
import { validateEmpty, validateUrl } from 'utils/ValidateUtils';
import { SelectOptions } from 'components/common/commonType';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';

export type AdvertiserFormState = {
  readonly loading: boolean;
  readonly redirectPath?: string;
  readonly categories?: Array<SelectOptions>;
};
export interface AdvertiserFormModel {
  readonly advertiserId?: string | number;
  readonly state: AdvertiserFormState;
  readonly event: UpdateEventListener<AdvertiserFormModel>;
  readonly title: string;
  readonly advertiser?: AdvertiserFormData;
  readonly formikProps?: FormikProps<AdvertiserFormData>;
  readonly fbAdAccountOptions: (SelectOptions & { disabled?: boolean })[];
  readonly fbPageOptions: (SelectOptions & { disabled?: boolean })[];
  readonly tiktokAdAccountOptions: (SelectOptions & { disabled?: boolean })[];
  readonly breadcrumbs: any[];
  init (): Promise<void>;
  validate (advertiser: AdvertiserFormData): any;
  validateAndSetError (): any;
  setFormikProps (props: FormikProps<AdvertiserFormData>): void;
  submit (): void;
  onUnmount (eventHandler): void;
}

export type AdvertiserFormProps = {
  readonly model: AdvertiserFormModel;
};

export type AdvertiserFormData = {
  id?: number,
  agencyId: number,
  advertiserName: string,
  website: string,
  category?: string,
  note?: string,
  fbAdAccountId: string;
  tiktokAdAccountId: string;
  fbPageIds: string[];
};

abstract class DefaultAdvertiserFormModel implements AdvertiserFormModel {
  event: FireableUpdateEventListener<AdvertiserFormModel>;
  loading: boolean;
  advertiser: any;
  manager: AdvertiserManager;
  formikProps?: FormikProps<AdvertiserFormData>;
  redirectPath?: string;
  categories?: Array<SelectOptions>;
  adRequestSourceManager: AdRequestSourceManager;

  constructor (
    public fbAdAccountOptions: (SelectOptions & { disabled?: boolean })[],
    public tiktokAdAccountOptions: (SelectOptions & { disabled?: boolean })[],
    public fbPageOptions: (SelectOptions & { disabled?: boolean })[],
    adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    manager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    this.event = new FireableUpdateEventListener<AdvertiserFormModel>();
    this.loading = true;
    this.adRequestSourceManager = adRequestSourceManager;
    this.manager = manager;
  }

  abstract get title ();

  abstract initAdvertiser (): Promise<void>;

  abstract submit (): void;

  abstract get breadcrumbs ();

  async init () {
    this.updateState(true);
    try {
      await this.initAdvertiser();
      const categories = await this.adRequestSourceManager.getTenmaxCategories();
      this.categories = categories.map(category => {
        return {
          label: i18n.t<string>(`tenmaxCategory.labels.${category.label}`),
          value: category.value
        };
      });
      if (!_.isEmpty(this.advertiser.category)) {
        const advertiserCategory = this.categories.find(category => category.value === this.advertiser.category);
        !advertiserCategory && this.categories.push({
          label: i18n.t<string>(`tenmaxCategory.labels.${this.advertiser.category.toLowerCase().replace('-', '_')}`),
          value: this.advertiser.category
        });
      }
    } catch (e) {}
    this.updateState(false);
  }

  validate = (advertiser: AdvertiserFormData) => {
    const needValidateFb = advertiser.fbAdAccountId || _.get(advertiser, 'fbPageIds', []).length > 0;
    return _.omitBy(
      {
        advertiserName: validateEmpty(advertiser.advertiserName),
        website: validateUrl(advertiser.website),
        category: validateEmpty(advertiser.category),
        fbAdAccountId: needValidateFb ? validateEmpty(advertiser.fbAdAccountId) : undefined,
        fbPageIds: needValidateFb ? validateEmpty(_.get(advertiser, 'fbPageIds', []).join(',')) : undefined
      }, (value) => value === undefined);
  }

  setFormikProps (props: FormikProps<AdvertiserFormData>) {
    this.formikProps = props;
  }

  onUnmount = (eventHandler) => {
    this.event.remove(eventHandler);
    this.redirectPath = undefined;
  }

  validateAndSetError = () => {
    if (!this.formikProps) {
      return {};
    }

    let errors = this.validate(this.formikProps.values);
    this.formikProps && this.formikProps.setErrors(errors);
    let touched = {};
    this.generateFormikTouchedObj(errors, touched);
    this.formikProps && this.formikProps.setTouched(touched);
    return errors;
  }

  generateFormikTouchedObj (errors, touched) {
    let keys = Object.keys(errors);
    keys.forEach(key => {
      let value = errors[key];
      if (typeof value === 'object') {
        touched[key] = {};
        this.generateFormikTouchedObj(errors[key], touched[key]);
      } else {
        touched[key] = true;
      }
    });
  }

  get state (): AdvertiserFormState {
    return {
      loading: this.loading,
      redirectPath: this.redirectPath,
      categories: this.categories
    };
  }

  toServerPayload (advertiser) {
    return {
      ..._.omit(advertiser, ['fbAdAccountId', 'tiktokAdAccountId', 'fbPageIds']),
      channelAccounts: _.compact([
        advertiser.fbAdAccountId ? {
          accountId: advertiser.fbAdAccountId,
          fbPageIds: advertiser.fbPageIds,
          channelType: 'FB'
        } : undefined,
        advertiser.tiktokAdAccountId ? {
          accountId: advertiser.tiktokAdAccountId,
          channelType: 'TIKTOK'
        } : undefined
      ])
    };
  }

  updateState (loading: boolean, redirectPath?: string) {
    this.loading = loading;
    this.redirectPath = redirectPath;
    this.event.fireEvent(this);
  }
}

export class CreateAdvertiserFormModel extends DefaultAdvertiserFormModel {
  agencyId: string | number | null;

  constructor (
    agencyId: string | number | null,
    fbAdAccountOptions: (SelectOptions & {disabled?: boolean})[],
    tiktokAdAccountOptions: (SelectOptions & {disabled?: boolean})[],
    fbPageOptions: SelectOptions[],
    adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    manager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    super(fbAdAccountOptions, tiktokAdAccountOptions, fbPageOptions, adRequestSourceManager, manager);
    this.agencyId = agencyId;
  }

  get breadcrumbs () {
    return [
      { path: '/advertisers', breadcrumb: i18n.t<string>('appMenus.comapnyManagement.items.advertisers') },
      { path: '/advertisers/new', breadcrumb: this.title }
    ];
  }

  async initAdvertiser (): Promise<void> {
    this.advertiser = {
      advertiserName: '',
      website: 'https://',
      comment: '',
      agencyId: this.agencyId,
      fbAdAccountId: undefined,
      fbPageIds: [],
      tiktokAdAccountId: undefined
    };
  }

  async submit () {
    if (!this.formikProps) {
      return;
    }
    const newAdvertiser = this.formikProps.values;
    this.updateState(true);
    try {
      const advertiserId = await this.manager.createAdvertiser(this.toServerPayload(newAdvertiser));
      this.updateState(false, `/advertisers/${advertiserId}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  get title () {
    return i18n.t<string>('advertiserForm.labels.createTitle');
  }
}

export class EditAdvertiserFormModel extends DefaultAdvertiserFormModel {

  advertiserId: string | number;

  constructor (
    advertiserId: string | number,
    public fbAdAccountOptions: SelectOptions[],
    public tiktokAdAccountOptions: SelectOptions[],
    public fbPageOptions: SelectOptions[],
    adRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager(),
    manager: AdvertiserManager = new DefaultAdvertiserManager()
  ) {
    super(fbAdAccountOptions, tiktokAdAccountOptions, fbPageOptions, adRequestSourceManager, manager);
    this.advertiserId = advertiserId;
  }

  get title () {
    return i18n.t<string>('advertiserForm.labels.editTitle');
  }

  get breadcrumbs () {
    const advertiserName = _.get(this.advertiser, 'advertiserName');
    return [
      { path: '/advertisers', breadcrumb: i18n.t<string>('appMenus.comapnyManagement.items.advertisers') },
      { path: '/advertisers/:advertiserId', breadcrumb: DynamicBreadcrumb, props: { label: advertiserName, matchParam: 'advertiserId' } },
      { path: '/advertisers/:advertiserId/edit', breadcrumb: DynamicBreadcrumb, props: { prefix: i18n.t<string>('common.labels.edit'), label: advertiserName, matchParam: 'advertiserId' } }
    ];
  }

  async initAdvertiser (): Promise<void> {
    const advertiser = await this.manager.getAdvertiser(this.advertiserId);
    const channelAccounts = _.get(advertiser, 'channelAccounts', []);
    const fbAdAccount = channelAccounts.find(account => account.channelType === 'FB');
    const tiktokAdAccount = channelAccounts.find(account => account.channelType === 'TIKTOK');
    this.advertiser = {
      ...omit(advertiser, ['channelAccounts']),
      fbAdAccountId: _.get(fbAdAccount, 'accountId'),
      fbPageIds: _.get(fbAdAccount, 'fbPageIds', []),
      tiktokAdAccountId: _.get(tiktokAdAccount, 'accountId')
    };
  }

  async submit () {
    if (!this.formikProps) {
      return;
    }

    const editedAdvertiser = { ...this.formikProps.values };
    this.updateState(true);
    try {
      await this.manager.updateAdvertiser(this.toServerPayload(editedAdvertiser));
      this.updateState(false, `/advertisers/${this.advertiserId}`);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }
}
