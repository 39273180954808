import { useCallback, useEffect, useMemo, useState } from 'react';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { useCallAPI } from 'hooks/useCallAPI';
import i18n from 'i18n';
import {
  AggregationField,
  EventRule,
  EVENT_TYPES,
  FilterField,
  FILTER_FIELDS,
  ProductSegment,
  PRODUCT_SEGMENT_OPERATOR
} from 'core/product/ProductSegment';
import {
  DURATION_DAYS,
  CombinedEventTypesConfig,
  NonEmptySubConditionTypes,
  CombinedEventTypes,
  InitSubConditionTypes,
  EventTypeConfig,
  FilterFieldOperatorsConfig,
  OperatorInitValuesMapConfig
} from 'core/product/ProductSegmentConfig';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { DefaultRdpManager, RdpManager } from 'core/rdp/RdpManager';
import { useCoreContext } from 'contexts/coreContext';
import _, { defaultTo } from 'lodash';
import { SelectOptions } from 'components/common/commonType';
import { ProductSet } from 'core/product/Product';
import { validateEmpty, validateInteger } from 'utils/ValidateUtils';
import { toast } from 'react-toastify';

export type AlertModalData = {
  title: string;
  message: string;
  dismiss: () => void;
  onConfirm: () => void;
  onCancel: () => void;
};

export type ProductSegmentFormData = {
  advertiser: number | string | undefined;
  segmentName: string;
  retailId: string;
  operator: string;
  inclusion: {
    rules: EventRule[];
  };
  exclusion?: {
    rules: EventRule[];
  };
};

type ProductSegmentBasicFormModelData = {
  agency: number | string;
  globalEventRuleId: number;
  productSegment: ProductSegmentFormData;
  productSetOptions: SelectOptions[];
  alertModalData: AlertModalData | undefined;
  addEventRule: () => void;
  setGlobalEventRuleId: (id: number) => void;
  updateAlertModalData: (alertModalData: AlertModalData | undefined) => void;
  updateProductSegment: (productSegmentData: ProductSegmentFormData) => void;
  validate: (productSegmentFormData: Partial<ProductSegmentFormData>) => Partial<ProductSegmentFormData>;
  onRetailIdChange: (retailId: string, doRefresh: boolean) => void;
  onAdvertiserChange: (advertiser: number | string | undefined, doRefresh: boolean) => void;
  initSubConditions: (eventType: EVENT_TYPES | string) => EventRule['filterSet'];
  wrapProductSegmentFormData: (productSegmentData: ProductSegmentFormData) => ProductSegmentFormData;
};

export type ProductSegmentFormModelData = ProductSegmentBasicFormModelData & {
  formType: string;
  loading: boolean;
  title: string;
  breadcrumbs: any[];
  redirectPath?: string;
  submit: (productSegmentFormData: ProductSegmentFormData) => void;
};

const defaultRdpManager: RdpManager = new DefaultRdpManager();

const useProductSegmentFormModel = (
  callAPIs: (apiMethods: Function[], callback?: Function | undefined, errorHandler?: Function | undefined) => Promise<void>
): ProductSegmentBasicFormModelData => {

  const core = useCoreContext();
  const agency = defaultTo(core?.accountManager.localeMeta?.agencyId, 0);
  const defaultProductSegmentFormData: ProductSegmentFormData = useMemo(() => ({
    advertiser: SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER),
    segmentName: '',
    retailId: '',
    operator: PRODUCT_SEGMENT_OPERATOR.OR,
    inclusion: {
      rules: [
        {
          ruleId: 0,
          durationDays: DURATION_DAYS[0],
          eventType: EVENT_TYPES.ALL_PRODUCT_EVENTS
        }
      ]
    }
  }), []);
  const [advertiser, setAdvertiser] = useState<number | string | undefined>(SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER));
  const [retailId, setRetailId] = useState<string>('');
  const [productSetOptions, setProductSetOptions] = useState<SelectOptions[]>([]);
  const [alertModalData, setAlertModalData] = useState<AlertModalData | undefined>();
  const [globalEventRuleId, setGlobalEventRuleId] = useState<number>(0);
  const [productSegment, setProductSegment] = useState<ProductSegmentFormData>(defaultProductSegmentFormData);

  const addEventRule = useCallback(() => {
    setGlobalEventRuleId(prevId => prevId + 1);
  }, []);

  const updateAlertModalData = useCallback((
    alertModalData: AlertModalData | undefined
  ) => {
    setAlertModalData(alertModalData);
  }, []);

  const updateProductSegment = useCallback((productSegment: ProductSegmentFormData) => {
    setAdvertiser(productSegment.advertiser);
    setRetailId(productSegment.retailId);
    setProductSegment(productSegment);
  }, []);

  const onAdvertiserChange = useCallback((advertiser: number | string | undefined, doRefresh: boolean) => {
    setAdvertiser(advertiser);
    doRefresh && setProductSegment({
      ...defaultProductSegmentFormData,
      advertiser
    });
  }, [defaultProductSegmentFormData]);

  const onRetailIdChange = useCallback((retailId: string, doRefresh: boolean) => {
    setRetailId(retailId);
    doRefresh && setProductSegment({
      ...defaultProductSegmentFormData,
      retailId
    });
  }, [defaultProductSegmentFormData]);

  const fetchProductSets = useCallback(async () => {
    if (!advertiser || !retailId) {
      setProductSetOptions([]);
      return;
    } else {
      callAPIs(
        [defaultRdpManager.getRetailProductSets.bind(defaultRdpManager, retailId, advertiser)],
        (productSets: ProductSet[]) => {
          setProductSetOptions(productSets.map(productSet => ({
            label: productSet.name,
            value: productSet.id.toString()
          })));
        }
      );
    }
  }, [advertiser, retailId, callAPIs]);

  useEffect(() => {
    fetchProductSets();
  }, [fetchProductSets]);

  const initSubConditions = (eventType: EVENT_TYPES | string): {
    operator: PRODUCT_SEGMENT_OPERATOR;
    filters: FilterField[]
  } | undefined => {
    const defaultEventType = InitSubConditionTypes.find(type => type === eventType);
    const defaultFilterFields: FILTER_FIELDS[] = _.get(EventTypeConfig, `${defaultEventType}.filterFields`, []);
    const defaultFilterField: FILTER_FIELDS | '' = (defaultFilterFields.length === 1) ? defaultFilterFields[0] : '';
    const defaultFieldOperator: PRODUCT_SEGMENT_OPERATOR | '' = _.get(FilterFieldOperatorsConfig, `${defaultFilterField}[0]`, '');
    const defaultFilterSet = defaultEventType ? {
      operator: PRODUCT_SEGMENT_OPERATOR.AND,
      filters: [{
        field: defaultFilterField,
        operator: defaultFieldOperator,
        value: _.get(OperatorInitValuesMapConfig, defaultFieldOperator, [''])
      }]
    } : undefined;

    return defaultFilterSet;
  };

  const validateSelectedFilterField = (
    filterSet: {
      operator: PRODUCT_SEGMENT_OPERATOR,
      filters: FilterField[]
    } | undefined,
    eventType: string
  ) => {

    return (!filterSet || _.isEmpty(filterSet.filters)) && _.includes(NonEmptySubConditionTypes, eventType)
     ? i18n.t<string>('productSegmentForm.errors.filterField')
     : undefined;
  };

  const validateFilters = (filters: FilterField[]) => {
    const validateFieldValue = (filterField: FILTER_FIELDS | '', fieldValue: string) => {
      switch (filterField) {
        case FILTER_FIELDS.SALE_PRICE:
        case FILTER_FIELDS.TOTAL_PRICE:
          return validateInteger(fieldValue);
        default:
          return validateEmpty(fieldValue);
      }
    };

    const validatedFilters: object[] = _.map(filters, filterField => {
      const validatedValues: (string | undefined)[] = _.map(filterField.value, fieldValue => validateFieldValue(filterField.field, fieldValue));

      return _.omitBy({
        field: validateEmpty(filterField.field),
        operator: validateEmpty(filterField.operator),
        value: validatedValues.some(value => !_.isNil(value)) ? validatedValues : []
      }, _.isEmpty);
    });
    return validatedFilters.some(validatedFilter => !_.isEmpty(validatedFilter)) ? validatedFilters : [];
  };

  const validateAggregation = (aggregation: AggregationField | undefined) => {
    const validatedValues: (string | undefined)[] = _.map(_.get(aggregation, 'value', []), aggregationValue => validateInteger(aggregationValue));
    return aggregation ? _.omitBy({
      type: validateEmpty(aggregation.type),
      operator: validateEmpty(aggregation.operator),
      value: validatedValues.some(value => !_.isNil(value)) ? validatedValues : []
    }, _.isEmpty) : undefined;
  };

  const validateCombination = (combination: {
    rules: EventRule[]
  } | undefined) => {

    if (!combination) {
      return undefined;
    }

    const eventRules: EventRule[] = combination.rules;
    const validatedRules: object[] = _.map(eventRules, (eventRule: EventRule) => {
      const rule: object = {
        selectedFilterField: validateSelectedFilterField(eventRule.filterSet, eventRule.eventType),
        durationDays: validateInteger(eventRule.durationDays),
        eventType: validateEmpty(eventRule.eventType),
        filterSet: eventRule.filterSet ? _.omitBy({
          filters: validateFilters(eventRule.filterSet.filters)
        }, _.isEmpty) : undefined,
        aggregation: validateAggregation(eventRule.aggregation)
      };
      return _.omitBy(rule, _.isEmpty);
    });
    return _.omitBy({
      rules: validatedRules.some(validatedRule => !_.isEmpty(validatedRule)) ? validatedRules : []
    }, _.isEmpty);
  };

  const validate = (productSegmentFormData: Partial<ProductSegmentFormData>): Partial<ProductSegmentFormData> => {
    return _.omitBy({
      advertiser: validateEmpty(productSegmentFormData.advertiser),
      segmentName: validateEmpty(productSegmentFormData.segmentName),
      retailId: validateEmpty(productSegmentFormData.retailId),
      operator: validateEmpty(productSegmentFormData.operator),
      inclusion: validateCombination(productSegmentFormData.inclusion),
      exclusion: validateCombination(productSegmentFormData.exclusion)
    }, _.isEmpty);
  };

  const wrapProductSegmentFormData = (productSegmentFormData: ProductSegmentFormData): ProductSegmentFormData => {

    const wrapEventRules = (eventRules: EventRule[]): EventRule[] => {

      const wrapCombinedTypes = (eventRules: EventRule[]): EventRule[] => {
        const isCombinedType: boolean = eventRules.length === 1 && _.includes(CombinedEventTypes, eventRules[0].eventType);
        const combinedTypes: EVENT_TYPES[] = isCombinedType ? CombinedEventTypesConfig[eventRules[0].eventType] : [];

        return isCombinedType ? _.map(combinedTypes, (defaultEventType, id) => ({
          ...eventRules[0],
          ruleId: id,
          eventType: defaultEventType
        })) : eventRules;
      };

      const wrapUTMType = (eventRule: EventRule): EventRule => {
        return eventRule.eventType === EVENT_TYPES.UTM ? {
          ...eventRule,
          eventType: EVENT_TYPES.PAGE_VIEW
        } : eventRule;
      };

      const collect = (eventRules: EventRule[]): EventRule[] => {
        return wrapCombinedTypes(eventRules);
      };

      const transfer = (eventRules: EventRule[]): EventRule[] => {
        return eventRules
          .map((eventRule: EventRule) => wrapUTMType(eventRule));
      };

      const wrappedEventRules: EventRule[] = collect(eventRules);

      return transfer(wrappedEventRules);
    };

    const inclusionRules: EventRule[] = _.get(productSegmentFormData, 'inclusion.rules', []);
    const exclusionRules: EventRule[] = _.get(productSegmentFormData, 'exclusion.rules', []);

    return {
      ...productSegmentFormData,
      inclusion: {
        rules: wrapEventRules(inclusionRules)
      },
      exclusion: productSegmentFormData.exclusion ? {
        rules: wrapEventRules(exclusionRules)
      } : productSegmentFormData.exclusion
    };
  };

  return {
    agency,
    globalEventRuleId,
    productSegment,
    productSetOptions,
    alertModalData,
    addEventRule,
    setGlobalEventRuleId,
    updateAlertModalData,
    updateProductSegment,
    validate,
    onRetailIdChange,
    onAdvertiserChange,
    initSubConditions,
    wrapProductSegmentFormData
  };
};

export const useCreateProductSegmentFormModel = (
  rdpManager: RdpManager = defaultRdpManager
): ProductSegmentFormModelData => {

  const {
    loading,
    callAPIs
  } = useCallAPI();
  const {
    agency,
    validate,
    wrapProductSegmentFormData,
    updateProductSegment,
    ...basicFormModelData
  } = useProductSegmentFormModel(callAPIs);

  const [redirectPath, setRedirectPath] = useState<string | undefined>();

  const submit = (productSegmentFormData: ProductSegmentFormData) => {
    const retailId: string = productSegmentFormData.retailId;
    const wrappedData: ProductSegmentFormData = wrapProductSegmentFormData(productSegmentFormData);
    callAPIs([rdpManager.createProductSegment.bind(rdpManager, agency, retailId, wrappedData)], () => {
      toast.success(i18n.t<string>('productSegmentForm.messages.createSuccess'));
      setRedirectPath('/product-segments');
    }, () => {
      toast.error(i18n.t<string>('productSegmentForm.messages.createFailed'));
    });
  };

  return {
    formType: 'create',
    loading,
    title: i18n.t<string>('productSegmentForm.labels.createTitle'),
    breadcrumbs: [
      { path: '/product-segments', breadcrumb: i18n.t<string>('productSegmentDetail.labels.tilte') },
      { path: '/product-segments/new', breadcrumb: i18n.t<string>('productSegmentForm.labels.createTitle') }
    ],
    agency,
    redirectPath,
    ...basicFormModelData,
    submit,
    validate,
    wrapProductSegmentFormData,
    updateProductSegment
  };
};

export const useEditProductSegmentFormModel = (
  segmentId: string,
  retailId: string,
  rdpManager: RdpManager = defaultRdpManager
): ProductSegmentFormModelData => {

  const {
    loading,
    callAPIs
  } = useCallAPI();

  const {
    agency,
    validate,
    productSegment,
    updateProductSegment,
    setGlobalEventRuleId,
    wrapProductSegmentFormData,
    ...others
  } = useProductSegmentFormModel(callAPIs);

  const [redirectPath, setRedirectPath] = useState<string | undefined>();

  const preprocessData = (segment: ProductSegment, retailId: string): ProductSegmentFormData => {

    const preprocessEventRules = (
      combination: {
        rules: EventRule[]
      } | undefined,
      startIndex: number
    ): EventRule[] => {

      if (!combination) {
        return [];
      }

      const preprocessCombinedTypes = (eventRules: EventRule[]): EventRule[] => {
        const combinedType: EVENT_TYPES | undefined = CombinedEventTypes.find(eventType =>
          _.isEqual(eventRules.map((eventRule: EventRule) => eventRule.eventType), CombinedEventTypesConfig[eventType]) &&
          _.every(eventRules, (eventRule: EventRule, _2, collection: EventRule[]) => _.isEqual(
            _.omit(eventRule, ['eventType', 'ruleId', 'selectedFilterField']),
            _.omit(collection[0], ['eventType', 'ruleId', 'selectedFilterField'])
          ))
        );

        return combinedType ? [{
          ...eventRules[0],
          eventType: combinedType
        }] : eventRules;
      };

      const preprocessUTMType = (eventRule: EventRule): EventRule => {
        const filterFields: FilterField[] = _.get(eventRule, 'filterSet.filters', []);
        if (filterFields.some(filterField => {
          const field: EVENT_TYPES | undefined = _.get(filterField.field.split('_'), '[0]');
          return field ? field === EVENT_TYPES.UTM : false;
        })) {
          return {
            ...eventRule,
            eventType: EVENT_TYPES.UTM
          };
        }
        return eventRule;
      };

      const collect = (eventRules: EventRule[]): EventRule[] => {
        return preprocessCombinedTypes(eventRules);
      };

      const transfer = (eventRules: EventRule[]): EventRule[] => {
        return _.map(eventRules, (eventRule, index) => {
          let newEventRule: any = {
            ..._.omitBy(eventRule, _.isNull),
            ruleId: index + startIndex
          };

          return preprocessUTMType(newEventRule);
        });
      };

      const wrappedEventRules: EventRule[] = collect(combination.rules);

      return transfer(wrappedEventRules);
    };

    const operator: PRODUCT_SEGMENT_OPERATOR = segment.combination.inclusion.operator;
    const inclusionRules: EventRule[] = preprocessEventRules(segment.combination.inclusion, 0);
    const exclusionRules: EventRule[] = preprocessEventRules(segment.combination.exclusion, inclusionRules.length);

    return _.omitBy({
      advertiser: segment.advertiser,
      segmentName: segment.name,
      retailId,
      operator,
      inclusion: {
        rules: inclusionRules
      },
      exclusion: !_.isEmpty(exclusionRules) ? {
        rules: exclusionRules
      } : undefined
    }, _.isUndefined) as ProductSegmentFormData;
  };

  useEffect(() => {
    callAPIs([rdpManager.getProductSegment.bind(rdpManager, retailId, segmentId)], (segment: ProductSegment) => {
      const productSegmentFormData: ProductSegmentFormData = preprocessData(segment, retailId);
      const inclusionRules: EventRule[] = productSegmentFormData.inclusion.rules;
      const exclusionRules: EventRule[] = productSegmentFormData.exclusion ? productSegmentFormData.exclusion.rules : [];
      setGlobalEventRuleId(inclusionRules.length + exclusionRules.length);
      updateProductSegment(productSegmentFormData);
    });
  }, [callAPIs, updateProductSegment, setGlobalEventRuleId, rdpManager, retailId, segmentId]);

  const submit = (productSegmentFormData: ProductSegmentFormData) => {
    const wrappedData: ProductSegmentFormData = wrapProductSegmentFormData(productSegmentFormData);
    callAPIs([rdpManager.updateProductSegment.bind(rdpManager, agency, retailId, segmentId, wrappedData)], () => {
      setRedirectPath('/product-segments');
      toast.success(i18n.t<string>('productSegmentForm.messages.updateSuccess'));
    }, () => {
      toast.error(i18n.t<string>('productSegmentForm.messages.updateFailed'));
    });
  };

  return {
    agency,
    loading,
    redirectPath,
    formType: 'edit',
    title: i18n.t<string>('productSegmentForm.labels.editTitle'),
    breadcrumbs: [
      { path: '/product-segments', breadcrumb: i18n.t<string>('productSegmentDetail.labels.tilte') },
      {
        path: '/product-segments/:retail/:id/edit',
        breadcrumb: DynamicBreadcrumb,
        props: {
          label: productSegment.segmentName,
          matchParam: 'id'
        }
      }
    ],
    ...others,
    productSegment,
    submit,
    validate,
    wrapProductSegmentFormData,
    updateProductSegment,
    setGlobalEventRuleId
  };
};
