import React from 'react';
import { RefType } from './RefType';
import { getDataUrlFromFile } from 'utils/ImageUtils';
import _ from 'lodash';
import i18n from 'i18n';

const getImageUrl = async (defaultUrl: string, image?: { file?: File }) => {
  if (image && image.file) {
    return getDataUrlFromFile(image.file);
  }
  return defaultUrl;
};

export const NativeBanner: React.FunctionComponent<any> = React.forwardRef((props, ref: RefType) => {

  /* eslint-disable no-template-curly-in-string */
  const [htmlContent, setHtmlContent] = React.useState(
    props.htmlContent.replace('${title}', '')
      .replace('${sponsored}', '')
      .replace('${description}', '')
      .replace('${clickUrl}', props.bannerUrl)
      .replace('${imgUrl}', '')
      .replace('${lImgUrl}', '')
      .replace('${iconUrl}', '')
      .replace('${lIconUrl}', ''));

  React.useEffect(() => {
    const prepareHtml = async () => {
      const sponsor = _.get(props.typeProperties, 'sponsor', '');
      const title = _.get(props.typeProperties, 'title', '');
      const desc = _.get(props.typeProperties, 'desc', '');
      const rectImg = props.medias.rectImg;
      const squareImg = props.medias.squareImg;
      const defaultRectImgUrl = _.get(props.medias.rectImg, 'url', '');
      const defaultSquareImgUrl = _.get(props.medias.squareImg, 'url', '');
      /* eslint-disable no-template-curly-in-string */
      let sizes = props.size.split('x');
      let html = props.htmlContent
        .replace('${title}', title)
        .replace('${sponsored}', sponsor)
        .replace('${description}', desc)
        .replace('${clickUrl}', props.bannerUrl);
      if (i18n.language === 'zh-TW') {
        html = html.replace('${sponsoredPostfix}', '贊助')
          .replace('${learnMore}', '瞭解詳情');
      } else {
        html = html.replace('${sponsoredPostfix}', 'sponsored')
          .replace('${learnMore}', 'Learn More');
        if ((sizes[0] === '300' && sizes[1] === '250') || (sizes[0] === '336' && sizes[1] === '280')) {
          html = html.replace('class="goToAction"', 'class="goToAction", style="font-size: 10px"');
        }
      }
      const imgUrl = await getImageUrl(defaultRectImgUrl, rectImg);
      html = html.replace('${lImgUrl}', imgUrl)
        .replace('${imgUrl}', imgUrl);
      const iconUrl = await getImageUrl(defaultSquareImgUrl, squareImg);
      html = html.replace('${lIconUrl}', iconUrl)
        .replace('${iconUrl}', iconUrl);
      setHtmlContent(html);
    };
    prepareHtml();
  }, [props]);

  const sizes = props.size.split('x');
  return (
    <iframe
      title={props.size}
      width={sizes[0]}
      height={sizes[1]}
      srcDoc={htmlContent}
      scrolling='no'
      frameBorder='0'
      marginHeight={0}
      marginWidth={0}
    />
  );
});
