import React from 'react';
import { FileInput } from 'components/common/FileInput/FileInput';
import styles from './fileFormInput.module.scss';
import classNames from 'classnames/bind';
import _ from 'lodash';

type FileFormInputType = {
  className?: string,
  onChange: Function,
  validTypes: string[],
  errorBorderHint: boolean,
  url: string,
  file?: File,
  icon: any
};

export const FileFormInput: React.FunctionComponent<FileFormInputType> = (props) => {

  const cssClassNames = classNames.bind(styles);

  const mainClassName = cssClassNames('fileFormInput', props.className, {
    showErrorBorder: props.errorBorderHint,
    showSolidBorder: props.url || props.file
  });

  const onFileChange = (file) => {
    if (!file) {
      props.onChange && props.onChange({
        file: undefined,
        url: undefined
      });
      return;
    }

    props.onChange && props.onChange({
      file,
      url: undefined
    });
  };

  return (
    <div className={mainClassName}>
      <FileInput
        {..._.omit(props, ['className'])}
        onChange={onFileChange}
      />
    </div>
  );
};

FileFormInput.defaultProps = {
  validTypes: ['application/zip', 'application/x-zip-compressed']
};
