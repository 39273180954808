import React from 'react';
import { FormikSwitchField, FormikSwitchFieldProps } from './SwitchField';
import { FormikTagsField, FormikTagsFieldProps } from './TagsField';
import { FormikRadioField, FormikRadioFieldProps } from './RadioField';
import { FormikCheckboxField, FormikCheckboxFieldProps } from './CheckboxField';
import { FormikInputField, FormikInputFieldProps } from './InputField';
import { FormikLabelField, FormikLabelFieldProps } from './LabelField';
import { FormikInputGroupField, FormikInputGroupFieldProps } from './InputGroupField';
import { FormikUrlInputField } from './UrlInputField';
import { FormikTextAreaField } from './TextAreaField';
import { FormikSelectField, FormikSelectFieldProps } from './SelectField';
import { FormikToggleButtonField, FormikToggleButtonFieldProps } from './ToggleButtonField';
import { FormikDatePickerField, FormikDatePickerFieldProps } from './DatePickerField';
import { FormikDateRangePickerField, FormikDateRangePickerFieldProps } from './DateRangePickerField';
import { FormikCustomField, FormikCustomFieldProps } from './CustomField';
import { FormikFileInputField, FormikFileInputFieldProps } from './FileInputField';
import { FormikMultiImageInputField, FormikMultiImageInputFieldProps } from './MultiImageInputField';

interface FormikFieldType {
  Label: React.FC<FormikLabelFieldProps>;
  Input: React.FC<FormikInputFieldProps>;
  Select: React.FC<FormikSelectFieldProps>;
  TextArea: React.FC<FormikInputFieldProps>;
  Switch: React.FC<FormikSwitchFieldProps>;
  InputGroup: React.FC<FormikInputGroupFieldProps>;
  Custom: React.FC<FormikCustomFieldProps>;
  DatePicker: React.FC<FormikDatePickerFieldProps>;
  DateRangePicker: React.FC<FormikDateRangePickerFieldProps>;
  Tags: React.FC<FormikTagsFieldProps>;
  Checkbox: React.FC<FormikCheckboxFieldProps>;
  Radio: React.FC<FormikRadioFieldProps>;
  UrlInput: React.FC<FormikInputFieldProps>;
  FileInput: React.FC<FormikFileInputFieldProps>;
  MultiImageInput: React.FC<FormikMultiImageInputFieldProps>;
  ToggleButton: React.FC<FormikToggleButtonFieldProps>;
}

export const FormikField: FormikFieldType = {
  Label: FormikLabelField,
  Input: FormikInputField,
  Select: FormikSelectField,
  TextArea: FormikTextAreaField,
  Switch: FormikSwitchField,
  Custom: FormikCustomField,
  InputGroup: FormikInputGroupField,
  DatePicker: FormikDatePickerField,
  DateRangePicker: FormikDateRangePickerField,
  Tags: FormikTagsField,
  Checkbox: FormikCheckboxField,
  Radio: FormikRadioField,
  UrlInput: FormikUrlInputField,
  FileInput: FormikFileInputField,
  MultiImageInput: FormikMultiImageInputField,
  ToggleButton: FormikToggleButtonField
};
