import React, { Fragment, Suspense, lazy, useMemo } from 'react';
import { Redirect, Switch, Route, RouteComponentProps } from 'react-router-dom';
import _ from 'lodash';
import { AuthenticationManager } from 'core';
import { alertMessage } from 'components/AlertDialog';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { useFinalReportPageModel } from './FinalReportPageModel';
import { OrderType } from 'core/order/Order';
import { TargetKPI } from 'core/finalReport/FinalReport';

export const FinalReportPage: React.FC<RouteComponentProps<{}> & {
  authenticationManager: AuthenticationManager
}> = ({
  match,
  location,
  authenticationManager
}) => {

  const {
    loading,
    data,
    reportId,
    modalData,
    redirectPath
  } = useFinalReportPageModel(location.search, location.pathname, authenticationManager.logined);
  const basicProps = useMemo(() => ({
    reportId,
    advertiser: _.get(data, 'advertiser', ''),
    settings:  _.get(data, 'settings', []),
    currency: _.get(data, 'currency', 'TWD'),
    createTime: _.get(data, 'create_time', ''),
    targetKPI:  _.get(data, 'target_KPI', TargetKPI.IMPRESSION),
    overallPerformance: _.get(data, 'overall_performance', {}),
    overallReports: _.get(data, 'overall_reports', {})
  }), [data, reportId]);

  const renderModalPage = () => {
    return (
      <Fragment>
        {modalData && alertMessage(modalData.title, modalData.message, modalData.onConfirm)}
      </Fragment>
    );
  };

  const renderPageContent = () => {
    const orderType: OrderType = _.get(data, 'order_type', OrderType.GOJEK);
    const TenmaxReportContent = lazy(() => import('./TenmaxReportContent/TenmaxReportContent'));
    const GojekReportContent = lazy(() => import('./GojekReportContent/GojekReportContent'));

    if (!data) {
      return <div />;
    }

    return (
      <>
        <Suspense fallback={<LoadingIndicator />}>
          {orderType === OrderType.GOJEK &&
            <GojekReportContent
              {...basicProps}
              overallImpressions={_.get(data, 'overall_impressions', {})}
              chartConfig={_.get(data, 'chart_config', [])}
              platformInfo={_.get(data, 'platform_info', [])}
              advertiserType={_.get(data, 'advertiser_type', '')}
              mainImpacts={_.get(data, 'main_impacts', [])}
              subImpacts={_.get(data, 'sub_impacts', [])}
              usersByRegion={_.get(data, 'users_by_region', []).filter(element => !_.isEmpty(element))}
              usersReports={_.get(data, 'users_reports', {})}
              benchmarks={_.get(data, 'benchmarks', [])}
            />
          }
          {orderType === OrderType.TENMAX &&
            <TenmaxReportContent
              {...basicProps}
              overallValues={_.get(data, 'overall_values', {})}
              chartConfig={_.get(data, 'chart_config', [])}
              l1ObjectInfo={_.get(data, 'platform_info', [])}
            />
          }
        </Suspense>
      </>
    );
  };

  if (loading) {
    return <LoadingIndicator />;
  }

  return (
    <Fragment>
      {renderModalPage()}
      {redirectPath && <Redirect to={redirectPath} />}
      {authenticationManager.logined &&
        <Switch>
          <Route path={`*/${ROUTE_PATH.ERROR403}`} render={renderModalPage} />
          <RouteWithPermission
            path={`${match.url}`}
            permissionAware={hasFuncs(Permission.ORDER_READ)}
            redirectPath={`${match.url}/${ROUTE_PATH.ERROR403}`}
            render={renderPageContent}
          />
        </Switch>
      }
    </Fragment>
  );
};
