import { SegmentListDTO, SegmentFormDTO, SegmentDTO, SegmentRuleContent, SubSegmentFormDTO } from 'core/segment/Segment';
import fileDownload from 'js-file-download';
import client from './RestClient';
import _ from 'lodash';
import { AxiosInstance } from 'axios';

export interface SegmentWebService {
  getSegments (agencyId?: number): Promise<Array<SegmentListDTO>>;
  deleteSegment (segmentId: number): Promise<void>;
  getSegment (segmentId: string | number): Promise<SegmentDTO>;
  createSegment (segment: SegmentFormDTO): Promise<number>;
  updateSegment (segment: SegmentFormDTO): Promise<number>;
  createSubSegment (parentId: number, subSegment: SubSegmentFormDTO): Promise<void>;
  updateSubSegment (parentId: number, subSegment: SubSegmentFormDTO): Promise<void>;
  importAudiences (segmentId: number, formData: FormData): Promise<void>;
  exportAudiences (segmentId: number): Promise<void>;
  reImportAudiences (segmentId: number): Promise<void>;
}

function wrapSegmentListDTO (json: any): SegmentListDTO {
  return {
    id: _.get(json, 'id'),
    segmentCount: _.get(json, 'audienceCount'),
    dmpSegmentId: _.get(json, 'dmpSegmentID'),
    advertiserId: _.get(json, 'advertiserID'),
    name: _.get(json, 'name'),
    duration: _.get(json, 'duration'),
    subSegmentAmount: _.get(json, 'subSegmentCount')
  };
}

function wrapSegmentDTO (json: any): SegmentDTO {
  const subSegments = _.get(json, 'subSegments', []);
  const segmentDTO: SegmentDTO = {
    ...wrapSegmentListDTO(json),
    segmentCode: _.get(json, 'segmentCode'),
    subSegments: subSegments ? subSegments.map(subSegment => wrapSegmentDTO(subSegment)) : undefined
  };
  const ruleContents = _.get(json, 'ruleContents');
  if (ruleContents) {
    segmentDTO.ruleContents = ruleContents.map(ruleContent => wrapRuleContent(ruleContent));
  }
  return segmentDTO;
}

function wrapRuleContent (json: any): SegmentRuleContent {
  return {
    pattern: _.get(json, 'pattern'),
    comparator: _.get(json, 'comparator')
  };
}

export class RestfulSegmentWebService implements SegmentWebService {
  restClient: AxiosInstance;
  defualtFileDownload: any;

  constructor (restClient: AxiosInstance = client, defualtFileDownload: any = fileDownload) {
    this.restClient = restClient;
    this.defualtFileDownload = defualtFileDownload;
  }

  async getSegments (agencyId?: number): Promise<Array<SegmentListDTO>> {
    const path = agencyId ? `/v2/segments?agencyId=${agencyId}` : '/v2/segments';
    const response = await this.restClient.get(path);
    return _.get(response, 'data.records', []).map(json => wrapSegmentListDTO(json));
  }

  async deleteSegment (segmentId: number): Promise<void> {
    return this.restClient.delete(`/v2/segments/${segmentId}`);
  }

  async getSegment (segmentId: string | number): Promise<SegmentDTO> {
    const response = await this.restClient.get(`/v2/segments/${segmentId}`);
    return wrapSegmentDTO(_.get(response, 'data'));
  }

  async createSegment (segment: SegmentFormDTO): Promise<number> {
    const response = await this.restClient.post('/v2/segments', {
      advertiserID: segment.advertiserId,
      name: segment.name,
      duration: segment.duration
    });
    return _.get(response.data, 'id', 0);
  }

  async updateSegment (segment: SegmentFormDTO): Promise<number> {
    const response = await this.restClient.put(`/v2/segments/${segment.id}`, {
      name: segment.name,
      duration: segment.duration
    });
    return _.get(response.data, 'id', 0);
  }

  async createSubSegment (parentId: number, subSegment: SubSegmentFormDTO): Promise<void> {
    return this.restClient.post(`/v2/segments/${parentId}/subSegments`, subSegment);
  }

  async updateSubSegment (parentId: number, subSegment: SubSegmentFormDTO): Promise<void> {
    return this.restClient.put(`/v2/segments/${parentId}/subSegments/${subSegment.id}`, {
      name: subSegment.name,
      ruleContents: _.get(subSegment, 'ruleContents', []).filter(ruleContent => !_.isEmpty(ruleContent.pattern))
    });
  }

  async importAudiences (segmentId: number, formData: FormData): Promise<void> {
    return this.restClient.post(`/v2/segments/${segmentId}/import`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  }

  async reImportAudiences (segmentId: number): Promise<void> {
    return this.restClient.post(`/v2/segments/${segmentId}/re-import`);
  }

  async exportAudiences (segmentId: number): Promise<void> {
    const response = await this.restClient.get(`/v2/segments/${segmentId}/export`);
    this.defualtFileDownload(response.data, `segment_${segmentId}.csv`);
  }
}
