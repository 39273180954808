import { CampaignState } from 'core/rtbCampaign/RtbCampaign';
import { BidStrategy, L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';

export type FbAdSet = {
  id: number | string;
  name: string;
  lifetime_budget?: number | string;
  bid_strategy: BidStrategy;
  bid_amount?: number | string;
  optimization_goal: L2ObjectOptimizationGoal;
  billing_event: FbBillingEvent;
  start_time: string;
  end_time: string;
  budget_remaining?: number | string;
  state: CampaignState;
  configured_status: string;
  effective_status: string;
  issues_info: any;
  report: any;
  lifetime_min_spend_target?: number;
  lifetime_spend_cap?: number;
  l1ObjectId: number;
  conversion?: any;
  promoted_object?: any;
  destination_type?: string;
  dayPart?: { [key: string]: string[] | number[] | string };
  draftId?: number;
  isDraft?: boolean;
  daily_budget?: number | string;
  targeting?: any;
  pacing_type?: FbPacingType[];
};

export enum FbOptimizationGoal {
  REACH = 'REACH',
  THRUPLAY = 'THRUPLAY',
  IMPRESSIONS = 'IMPRESSIONS',
  LINK_CLICKS = 'LINK_CLICKS',
  LANDING_PAGE_VIEWS = 'LANDING_PAGE_VIEWS',
  OFFSITE_CONVERSIONS = 'OFFSITE_CONVERSIONS'
}

export enum FbBillingEvent {
  // APP_INSTALLS = 'APP_INSTALLS',
  // CLICKS = 'CLICKS',
  IMPRESSIONS = 'IMPRESSIONS',
  LINK_CLICKS = 'LINK_CLICKS',
  // NONE = 'NONE',
  // OFFER_CLAIMS = 'OFFER_CLAIMS',
  // PAGE_LIKES = 'PAGE_LIKES',
  // POST_ENGAGEMENT = 'POST_ENGAGEMENT',
  THRUPLAY = 'THRUPLAY'
  // PURCHASE = 'PURCHASE',
  // LISTING_INTERACTION = 'LISTING_INTERACTION'
}

export enum FbAppEvent {
  PURCHASE = 'PURCHASE',
  INITIATED_CHECKOUT = 'INITIATED_CHECKOUT',
  ADD_TO_CART = 'ADD_TO_CART',
  CONTENT_VIEW = 'CONTENT_VIEW'
}

export enum FbDestinationType {
  ON_AD = 'ON_AD',
  ON_POST = 'ON_POST',
  ON_VIDEO = 'ON_VIDEO',
  ON_EVENT = 'ON_EVENT',
  ON_PAGE = 'ON_PAGE'
}

export enum FbPacingType {
  STANDARD = 'standard',
  DAY_PARTING = 'day_parting',
  NO_PACING = 'no_pacing'
}

export const ADSET_DEFAULT_AGE_MIN = 21;
export const ADSET_DEFAULT_AGE_MAX = 65;
