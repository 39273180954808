import React from 'react';

interface ObjectFitContainerProps {
  containerWidth: number;
  containerHeight: number;
  onlyScaleDown?: boolean;
  justifyContent?: string;
  alignItems?: string;
  margin?: string | number;
  children?: React.ReactNode;
}

interface ObjectFitContainerState {
  scale: number;
  updated: boolean;
}

export class ObjectFitContainer extends React.Component<ObjectFitContainerProps, ObjectFitContainerState> {

  static defaultProps = {
    containerWidth: 140,
    containerHeight: 140,
    onlyScaleDown: false,
    justifyContent: 'center',
    alignItems: 'center',
    margin: 'auto'
  };

  updated: boolean;

  constructor (props) {
    super(props);
    this.state = { scale: 0, updated: false };
    this.updated = false;
  }

  shouldComponentUpdate () {
    return !this.state.updated;
  }

  render () {
    return (
      <div
        style={{
          width: this.props.containerWidth,
          height: this.props.containerHeight,
          display: 'flex',
          justifyContent: this.props.justifyContent,
          alignItems: this.props.alignItems,
          margin: this.props.margin,
          overflow: 'hidden'
        }}
      >
        <div
          ref={
            (elem) => {
              if (elem && elem.clientWidth > 0 && elem.clientHeight > 0) {
                const targetScale = Math.min(
                  this.props.containerWidth / elem.clientWidth,
                  this.props.containerHeight / elem.clientHeight
                );
                if (targetScale > 1 && this.props.onlyScaleDown) {
                  this.setState({ scale: 1, updated: true });
                } else {
                  this.setState({ scale: targetScale, updated: true });
                }
              }
            }
          }
          style={{ transform: `scale(${this.state.scale})` }}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
