import { getNameIdColumn, getStringArrayColumn, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { Source } from 'core/tenmaxSegment/TenmaxSegment';
import { DefaultTenmaxSegmentManager } from 'core/tenmaxSegment/TenmaxSegmentManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useEffect, useMemo, useState } from 'react';
import i18n from 'i18n';

export enum SourceListColumns {
  NAME = 'id',
  DURATION = 'duration',
  TAGS = 'tags',
  COUNTRIES = 'countries'
}

const tenmaxSegmentManager = new DefaultTenmaxSegmentManager();

export const useSourceListModel = () => {

  const [sources, setSources] = useState<Source[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  const {
    loading,
    callAPIs
  } = useCallAPI();

  const fetchSources = useCallback(() => {
    callAPIs([() => tenmaxSegmentManager.getSources()], sources => {
      setSources(sources);
    });
  }, [callAPIs]);

  useEffect(() => {
    fetchSources();
  }, [fetchSources]);

  const columns = useMemo(() => ([
    getNameIdColumn(
      sortableColumn(SourceListColumns.NAME, i18n.t<string>('sourceList.headers.name')),
      source => `/tenmax-segments/sources/${source.id}`
    ),
    renderColumn(
      sortableColumn(SourceListColumns.DURATION, i18n.t<string>('sourceList.headers.duration')),
      value => `${value} ${i18n.t<string>('common.day')}`
    ),
    getStringArrayColumn(sortableColumn(SourceListColumns.TAGS, i18n.t<string>('sourceList.headers.tags'))),
    getStringArrayColumn(sortableColumn(SourceListColumns.COUNTRIES, i18n.t<string>('sourceList.headers.countries')))
  ]), []);

  const onHandleSearch = useCallback((searchString: string) => {
    setSearchString(searchString);
  }, []);

  const filterdSources = useMemo(() => {
    return sources.filter(source => {
      const idMatch = source.id.toString().includes(searchString);
      const nameMatch = source.name.includes(searchString);
      return idMatch || nameMatch;
    });
  }, [sources, searchString]);

  return {
    loading,
    columns,
    searchString,
    filterdSources,
    onHandleSearch
  };
};
