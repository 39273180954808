import React, { ReactNode } from 'react';
import styles from './checkbox.module.scss';

export interface CheckboxProps {
  id?: string;
  name: string;
  checked?: boolean;
  defaultChecked?: boolean;
  disabled?: boolean;
  onChange?: Function;
  checkboxLabel?: string;
  postfix?: (() => ReactNode) | string | number | ReactNode;
}

const Checkbox: React.FC<CheckboxProps> = ({
  id,
  name,
  checked,
  disabled,
  checkboxLabel,
  defaultChecked,
  postfix,
  onChange
}: CheckboxProps) => {
  const onCheckboxChanged = e => {
    const checked = e.target.checked;
    onChange && onChange(checked);
  };
  const checkboxId = id ? id : `${name}-checkbox`;
  return (
    <div className={styles.checkbox}>
      <input
        type='checkbox'
        id={checkboxId}
        checked={checked}
        defaultChecked={defaultChecked}
        disabled={disabled}
        onChange={onCheckboxChanged}
      />
      <label htmlFor={checkboxId}>{checkboxLabel}</label>
      {typeof postfix === 'function' ? postfix() : postfix}
    </div>
  );
};

Checkbox.defaultProps = {
  checkboxLabel: '',
  disabled: false
};

export default React.memo(Checkbox);
