import ToggleButtons from 'components/common/ToggleButtons/ToggleButtons';
import { TIMEUNIT, useOutdoorMonitorModel } from './OutdoorMonitorModel';
import React, { lazy, Suspense } from 'react';
import i18n from 'i18n';
import styles from './outdoorMonitor.module.scss';
import { DateRangePicker } from 'components/common/DateRangePicker/DateRangePicker';
import moment from 'moment';
import { Button } from 'react-bootstrap';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { SearchBar } from 'components/common/SearchBar';
import { Trans } from 'react-i18next';
import { CustomField } from 'components/common/form/field/CustomField';
import { SelectField } from 'components/common/form/field/SelectField';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Line = lazy(() =>
  import('react-chartjs-2').then(({ Line }) => ({
    default: Line
  }))
);

export const OutdoorMonitor = ({ agencyId, localeMeta }) => {

  const {
    loading,
    timeUnit,
    timeUnitOptions,
    from,
    to,
    dayRangeError,
    columns,
    chartData,
    tableData,
    agencyOptions,
    search,
    selectAgencyOption,
    setSelectAgencyOption,
    setSearch,
    onSearchBtnClick,
    onDayRangeChange,
    onTimeUnitChange
  } = useOutdoorMonitorModel(agencyId, localeMeta);

  function renderScheduling () {
    const endDateMoment = moment(to);
    const startDateMoment = moment(from);
    const days = endDateMoment.diff(startDateMoment, 'days');
    const hours = endDateMoment.diff(startDateMoment, 'hours');
    if (days > 0 || timeUnit === TIMEUNIT.DAY) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingDay'>
          Total <span className='text-dark'><>{{ days: days + 1 }} days</></span>
        </Trans>
      );
    } else if (days === 0) {
      return (
        <Trans i18nKey='campaign.descriptions.campaignSchedulingHour'>
          Total <span className='text-dark'><>{{ hours: hours + 1 }} hours</></span>
        </Trans>
      );
    }
  }

  return (
    <div className={styles.outdoorMonitor}>
      {loading && <LoadingIndicator />}
      <div className={styles.titleArea}>
        <div className={styles.title}>
          {i18n.t<string>('outdoorMonitor.labels.title')}
        </div>
      </div>
      <div className={styles.contentArea}>
        <div className={styles.dimensionSelectArea}>
          <CustomField label={i18n.t<string>('outdoorMonitor.labels.dayRange')} name='dayRange'>
            <div className={styles.dayRange}>
              <ToggleButtons
                name={'timeUnit'}
                value={timeUnit}
                options={timeUnitOptions}
                onChange={onTimeUnitChange}
              />
              <DateRangePicker
                startDate={from}
                endDate={to}
                format={timeUnit === TIMEUNIT.HOUR ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD'}
                onChange={onDayRangeChange}
                showTimePicker={timeUnit === TIMEUNIT.HOUR}
                minDate={moment().startOf('day').format('YYYY-MM-DD')}
              />
              {dayRangeError && <span className={styles.errorHint}>{dayRangeError}</span>}
              <span className={styles.hint}>{renderScheduling()}</span>
            </div>
          </CustomField>
          {(!agencyId || !localeMeta) &&
            <SelectField
              label={i18n.t<string>('outdoorMonitor.labels.agency')}
              options={agencyOptions}
              name='agencyOptions'
              value={selectAgencyOption}
              onChange={setSelectAgencyOption}
              placeholder={i18n.t<string>('outdoorMonitor.placeholders.agencies')}
            />
          }
          <CustomField
            formGroupClassName={styles.reportSearchBtnContainer}
            label=''
            name='searchButton'
          >
            <Button variant='primary' size='sm' onClick={onSearchBtnClick} disabled={dayRangeError !== undefined || !selectAgencyOption}>
              {i18n.t<string>('common.buttons.search')}
            </Button>
          </CustomField>
        </div>
        <Suspense fallback={<LoadingIndicator />}>
          <div className={styles.chartArea}>
            <div className={styles.chart}>
              <Line
                data={chartData.data}
                height={215}
                options={chartData.options}
              />
            </div>
          </div>
        </Suspense>
        <div className={styles.filterArea}>
          <SearchBar
            model={{
              placeholder: i18n.t<string>('outdoorMonitor.placeholders.searchbar'),
              search: setSearch,
              defaultValue: search
            }}
          />
        </div>
        <StickableBootstrapTable
          stickFirstColumn
          keyField='id'
          data={tableData}
          columns={columns}
          noDataIndication={i18n.t<string>('common.labels.noData')}
          pagination={{
            sizePerPage: 50
          }}
        />
      </div>
    </div>
  );
};
