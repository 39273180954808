import client from './RestClient';
import { AxiosInstance } from 'axios';

export interface RoleWebService {

  addRole (scope: string, scopeId: number, email: string, role: string): Promise<void>;

  deleteRole (scope: string, scopeId: number, accountId: number): Promise<void>;

  changeRole (scope: string, scopeId: number, email: string, role: string): Promise<void>;
}

export class RestfulRoleWebService implements RoleWebService {
  public restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  public async addRole (scope: string, scopeId: number, email: string, role: string): Promise<void> {
    return this.restClient.post(`/v2/${scope}/${scopeId}/members?email=${email}&role_name=ROLE_${role}`);
  }

  public async deleteRole (scope: string, scopeId: number, accountId: number): Promise<void> {
    return this.restClient.delete(`/v2/${scope}/${scopeId}/members/${accountId}`);
  }

  public async changeRole (scope: string, scopeId: number, email: string, role: string): Promise<void> {
    return this.restClient.put(`/v2/${scope}/${scopeId}/members?email=${email}&role_name=ROLE_${role}`);
  }
}
