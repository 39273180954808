import {
  UpdateEventListener,
  FireableUpdateEventListener
} from 'utils/UpdateEventListener';
import { SegmentDTO } from 'core/segment/Segment';
import { DefaultSegmentManager, SegmentImportType, SegmentManager } from 'core/segment/SegmentManager';
import { SubSegmentListModel, DefaultSubSegmentListModel } from 'components/SubSegmentList/SubSegmentListModel';
import i18n from 'i18n';
import { CodePage } from 'components/CodePage/CodePage';
import { EditSubSegmentFormModel, CreateSubSegmentFormModel, SubSegmentFormModel } from '../SubSegmentForm/SubSegmentFormModel';
import { toast } from 'react-toastify';
import { numberWithCommas } from 'utils/StringUtil';

export type SegmentDetailState = {
  readonly loading: boolean;
  readonly segmentViewData: any;
  readonly modalData: any;
};

export interface SegmentDetailModel {
  segment?: SegmentDTO;
  readonly segmentId: string | number;
  readonly state: SegmentDetailState;
  readonly event: UpdateEventListener<SegmentDetailModel>;
  init (): Promise<void>;
  onUnmount (eventHandler?: number): void;
  hideModal (): void;
  showCodeModal (): void;
  getSubSegmentListModel (): SubSegmentListModel;
  getCreateSubSegmentFormModel (): SubSegmentFormModel;
  getEditSubSegmentFormModel (subSegmentId: number): SubSegmentFormModel | undefined;
  importAudiences (file: File, callback: () => void): Promise<void>;
  reImportAudiences (): Promise<void>;
  exportAudiences (): Promise<void>;
  setImportType (type: SegmentImportType);
}

export type SegmentDetailProps = {
  readonly model: SegmentDetailModel;
};

export const IMPORT_FILE_SIZE_MAXIMUM = 5242880;

export class DefaultSegmentDetailModel implements SegmentDetailModel {

  segment?: SegmentDTO;
  segmentId: string | number;
  event: FireableUpdateEventListener<SegmentDetailModel>;
  manager: SegmentManager;
  handler?: number;
  segmentViewData: any;
  loading: boolean;
  modalData: any;
  subSegmentListModel?: SubSegmentListModel;
  createSubSegmentFormModel?: CreateSubSegmentFormModel;
  editSubSegmentFormModel?: EditSubSegmentFormModel;
  importType: SegmentImportType = SegmentImportType.COOKIE;

  constructor (
    segmentId: string | number,
    manager: SegmentManager = new DefaultSegmentManager()
  ) {
    this.segmentId = segmentId;
    this.manager = manager;
    this.event = new FireableUpdateEventListener<SegmentDetailModel>();
    this.loading = true;
  }

  get state (): SegmentDetailState {
    return {
      segmentViewData: this.segmentViewData,
      loading: this.loading,
      modalData: this.modalData
    };
  }

  setImportType (type: SegmentImportType) {
    this.importType = type;
  }

  onUnmount (eventHandler?: number) {
    this.segment = undefined;
    eventHandler !== undefined && this.event.remove(eventHandler);
  }

  getSubSegmentListModel () {
    const segmentId = parseInt(this.segmentId.toString(), 10);
    const subSegments = this.segment && this.segment.subSegments ? this.segment.subSegments : [];
    if (this.subSegmentListModel && this.subSegmentListModel.parentId === segmentId) {
      this.subSegmentListModel.updateModelData(subSegments);
      return this.subSegmentListModel;
    }
    this.subSegmentListModel = new DefaultSubSegmentListModel(segmentId, subSegments, () => {
      this.init();
    });
    return this.subSegmentListModel;
  }

  getCreateSubSegmentFormModel (): SubSegmentFormModel {
    const segmentId = parseInt(this.segmentId.toString(), 10);
    if (this.createSubSegmentFormModel && this.createSubSegmentFormModel.parentId === segmentId) {
      return this.createSubSegmentFormModel;
    }
    this.createSubSegmentFormModel = new CreateSubSegmentFormModel(segmentId, this.init.bind(this));
    return this.createSubSegmentFormModel;
  }

  getEditSubSegmentFormModel (subSegmentId: number): SubSegmentFormModel | undefined {
    if (!this.segment || !this.segment.subSegments) {
      return;
    }

    const segmentId = parseInt(this.segmentId.toString(), 10);
    if (this.editSubSegmentFormModel &&
      this.editSubSegmentFormModel.parentId === segmentId &&
      this.editSubSegmentFormModel.subSegment.id === subSegmentId
    ) {
      return this.editSubSegmentFormModel;
    }
    const subSegment = this.segment.subSegments.find(subSegment => subSegment.id === subSegmentId);
    if (!subSegment) {
      return;
    }
    this.editSubSegmentFormModel = new EditSubSegmentFormModel(segmentId, subSegment, this.init.bind(this));
    return this.editSubSegmentFormModel;
  }

  async init (): Promise<void> {
    this.updateState(true);
    try {
      this.segment = await this.manager.getSegment(this.segmentId);
      this.createSubSegmentFormModel = undefined;
      this.editSubSegmentFormModel = undefined;
      this.genSegmentViewData();
    } catch (error) {}
    this.updateState(false);
  }

  genSegmentViewData () {
    const segment = this.segment;
    if (!segment) {
      this.segmentViewData = {};
      return;
    }

    this.segmentViewData = {
      dmpId: segment.dmpSegmentId,
      duration: `${segment.duration} ${i18n.t<string>('segmentForm.labels.durationUnit')}`,
      segmentCount: segment.segmentCount
    };
  }

  showCodeModal = () => {
    if (!this.segment) {
      return;
    }
    this.modalData = {
      title: this.segment.name,
      fullScreen: true,
      component: CodePage,
      componentProps: {
        codeSource: () => this.segment ? this.segment.segmentCode : '',
        id: this.segment.dmpSegmentId,
        i18nWordingPrefix: 'segmentCode.labels'
      }
    };
    this.updateState(false);
  }

  showFileSizeErrorModal = () => {
    this.modalData = {
      title: i18n.t<string>('segmentDetail.labels.fileSizeErrorModalTitle'),
      message: i18n.t<string>('segmentDetail.labels.fileSizeErrorModalContent', { bytes: numberWithCommas(IMPORT_FILE_SIZE_MAXIMUM) })
    };
    this.updateState(false);
  }

  async importAudiences (file: File, callback: () => void): Promise<void> {
    if (!this.segment) {
      return;
    }
    if (file.size > IMPORT_FILE_SIZE_MAXIMUM) {
      this.showFileSizeErrorModal();
      return;
    }
    this.updateState(true);
    try {
      await this.manager.importAudiences(this.segment.id, this.importType, file);
      toast.success(i18n.t<string>('segmentDetail.labels.importSuccess'));
      this.init();
      callback();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  async reImportAudiences (): Promise<void> {
    if (!this.segment) {
      return;
    }
    this.updateState(true);
    try {
      await this.manager.reImportAudiences(this.segment.id);
      toast.success(i18n.t<string>('segmentDetail.labels.resetSuccess'));
      this.init();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  async exportAudiences (): Promise<void> {
    if (!this.segment) {
      return;
    }
    this.updateState(true);
    try {
      await this.manager.exportAudiences(this.segment.id);
      toast.success(i18n.t<string>('segmentDetail.labels.exportSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateState(false);
  }

  hideModal = () => {
    this.modalData = undefined;
    this.updateState(false);
  }

  updateState (loading: boolean = false) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}
