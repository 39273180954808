import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import React from 'react';
import { useProductSetDetailModel } from './ProductSetDetailModel';
import i18n from 'i18n';
import styles from './productSetDetail.module.scss';
import { DynamicBreadcrumb } from 'components/Breadcrumbs/DynamicBreadcrumbs';
import { ProductList } from 'containers/RetailMax/ProductCategory/ProductList';
import { Modal } from 'components/common/Modal/Modal';

export const ProductSetDetail: React.FC<any> = ({
  retailId,
  advertiserId,
  productSetId
}) => {

  const {
    loading,
    productSet,
    editActions,
    submitAlertModalData,
    deleteProducts,
    onDeleteModalClose
  } = useProductSetDetailModel(retailId, advertiserId, productSetId);

  const renderSubmitAlertModal = () => {
    const campaignInfo = submitAlertModalData.usageData.map(data => {
      const creativeInfo = data.creatives.map(creative => (
        <li key={creative.id}>{`${creative.name} (ID: ${creative.id})`}</li>
      ));
      return (
        <div key={data.id}>
          <div>{`${data.name} (ID: ${data.id})`}</div>
          <ul>
            {creativeInfo}
          </ul>
        </div>
      );
    });
    return (
      <Modal
        title={submitAlertModalData.title}
        primaryButton={{
          title: i18n.t<string>('common.buttons.submit'),
          callback: submitAlertModalData.submit
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.cancel'),
          callback: submitAlertModalData.cancel
        }}
        dismiss={submitAlertModalData.cancel}
        animation={false}
      >
        <div>
          <div className={styles.modalHint}>{i18n.t<string>('productSet.labels.changingProductSetAlert')}</div>
          {i18n.t<string>('productSetCreateModal.labels.usageDataTitle')}
          <div className={styles.campaignUsageInfo}>
            {campaignInfo}
          </div>
        </div>
      </Modal>
    );
  };

  if (!productSet) {
    return <LoadingIndicator />;
  }

  return (
    <div className={styles.productSetDetail}>
      {submitAlertModalData && renderSubmitAlertModal()}
      {loading && <LoadingIndicator />}
      {deleteProducts &&
        <Modal
          title={i18n.t<string>('productSetDetail.labels.deleteModalTitle')}
          secondaryButton={{ title: i18n.t<string>('common.buttons.cancel'), callback: onDeleteModalClose }}
          dangerButton={{ title: i18n.t<string>('common.buttons.delete'), callback: deleteProducts }}
          dismiss={onDeleteModalClose}
        >
          {i18n.t<string>('productSetDetail.labels.deleteModalContent')}
        </Modal>
      }
      <div className={styles.titleArea}>
        <TitleWithBreadcrumbs
          title={i18n.t<string>('advertiserDetail.labels.title')}
          routes={[
            { path: '/product-sets', breadcrumb: i18n.t<string>('appMenus.retailMax.items.productSet') },
            { path: '/product-sets/:id/edit', breadcrumb: DynamicBreadcrumb, props: { label: productSet.name, matchParam: 'id' } }
          ]}
        />
      </div>
      <div className={styles.contentArea}>
        <ProductList
          filterSet={{}}
          searchString=''
          productList={productSet.products}
          editActions={editActions}
        />
      </div>
    </div>
  );
};
