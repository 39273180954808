import { FormConfig } from 'components/common/form/FormConfig';
import { useEffect, useState } from 'react';
import i18n from 'i18n';
import { VideoPlacement } from 'core/creative/Creative';
import { validateInteger, validateMinMax, validateUrl } from 'utils/ValidateUtils';
import styles from './useVideoFormConfig.module.scss';

export const useVideoFormConfig = (
  basicFields,
  isEditing: boolean,
  isThirdPartyVideo: boolean,
  skippable: boolean,
  hints: string[],
  validateTenMaxVideo: (value: any) => any,
  getInitFormConfig: () => FormConfig
) => {

  const [formConfig, setFormConfig] = useState<FormConfig>(getInitFormConfig());

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...basicFields])
          .addFormikRadio({
            label: i18n.t<string>('creativeSetupFlow.labels.videoPlacement'),
            name: 'typeProperties.videoPlacement',
            disabled: isEditing,
            options: [{
              label: i18n.t<string>('videoForm.labels.inStream'),
              value: VideoPlacement.IN_STREAM
            }, {
              label: i18n.t<string>('videoForm.labels.outStream'),
              value: VideoPlacement.OUT_STREAM
            }, {
              label: i18n.t<string>('videoForm.labels.unlimitStream'),
              value: VideoPlacement.UNLIMIT
            }]
          })
          .addFormikRadio({
            label: i18n.t<string>('videoForm.labels.videoSource'),
            name: 'typeProperties.videoSrc',
            options: [{
              value: 'tenmax',
              label: i18n.t<string>('videoForm.labels.tenMaxVideo')
            }, {
              value: '3rd',
              label: i18n.t<string>('videoForm.labels.3rdVideo')
            }]
          })
          .addFormikFileInput({
            type: 'video',
            label: i18n.t<string>('videoForm.labels.tenMaxVideoInput'),
            name: 'medias.fileVideo',
            hints,
            validate: validateTenMaxVideo
          }, isThirdPartyVideo)
          .addFormikInput({
            label: i18n.t<string>('videoForm.labels.3rdVideoInput'),
            name: 'medias.urlVideo.url',
            validate: validateUrl
          }, !isThirdPartyVideo)
          .addFormikInputGroup({
            label: i18n.t<string>('videoForm.labels.3rdVideoDuration'),
            name: 'medias.urlVideo.duration',
            className: styles.secondInput,
            postfix: i18n.t<string>('common.units.seconds'),
            type: 'number',
            min: 1,
            max: 120,
            hint: i18n.t<string>('creativeSetupFlow.labels.videoDurationHint'),
            validate: value => validateMinMax(value, 1, 120) || validateInteger(value)
          }, !isThirdPartyVideo)
          .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      ).addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder()
          .addFormikRadio({
            label: i18n.t<string>('creativeSetupFlow.labels.skippableSetting'),
            name: 'typeProperties.skippableSetting.skippable',
            options: [{
              label: i18n.t<string>('videoForm.labels.nonSkippable'),
              value: 0
            }, {
              label: i18n.t<string>('videoForm.labels.skippable'),
              value: 1
            }]
          })
          .addFormikInputGroup({
            label: '',
            name: 'typeProperties.skippableSetting.skipOffset',
            className: styles.secondInput,
            postfix: i18n.t<string>('common.units.seconds'),
            type: 'number',
            min: 1,
            max: 30,
            validate: value => validateMinMax(value, 1, 30) || validateInteger(value)
          }, !(+skippable))
          .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.skippableSetting'))
        .build()
      ).build()
    );
  }, [
    hints,
    basicFields,
    skippable,
    isEditing,
    isThirdPartyVideo,
    validateTenMaxVideo
  ]);

  return formConfig;
};
