import React from 'react';
import { connect } from 'formik';
import i18n from 'i18n';
import _ from 'lodash';

import { TrackEvent, ALLOW_VIEW_CONVERT_AD_TYPE, AdType } from 'core/rtbCampaign/RtbCampaign';
import ThirdPartyTrackingCode from 'components/ThirdPartyTrackingCode/ThirdPartyTrackingCode';
import * as SelectOptionsUtils from 'utils/SelectOptionsUtils';

import styles from './rtbCampaignTrackForm.module.scss';
import { FormikField } from 'components/common/form/field/FormikField';
import { CustomField } from 'components/common/form/field/CustomField';

const trackEventOptions = SelectOptionsUtils.createSelectOptionsFromEnum(
  TrackEvent, 'campaign.labels.convTrackEvent'
);
class CampaignTrackForm extends React.Component<any> {

  render () {
    const { conversionOptions, adType } = this.props;
    const formikValue = _.get(this.props, 'formik.values');
    const conversionTracking = _.get(formikValue, 'conversionTracking');
    const canUseVideoViewConvert = (ALLOW_VIEW_CONVERT_AD_TYPE.includes(adType) || !adType) && conversionTracking !== null;
    return (
      <div>
        <fieldset>
          <legend>
            <span>{i18n.t<string>('campaign.strategyAndTracking.conversionTracking')}</span>
          </legend>
          <div className={styles.component}>
            <FormikField.Select
              name='conversionTracking'
              label={i18n.t<string>('campaign.strategyAndTracking.conversionTracking')}
              options={conversionOptions}
              isClearable={true}
            />
            {canUseVideoViewConvert && (
              <FormikField.ToggleButton
                label={i18n.t<string>('campaign.labels.convTrackEvent')}
                name='convTrackEvent'
                options={trackEventOptions}
              />
            )}
          </div>
      </fieldset>
        <fieldset>
          <legend>
            <span>{i18n.t<string>('campaign.strategyAndTracking.thirdPartyTracking')}</span>
          </legend>
            <div className={styles.component}>
              <CustomField
                name='viewTrackingCodes'
                label={i18n.t<string>('campaign.strategyAndTracking.thirdPartyTracking')}
                hint={<span className={styles.hint}>{i18n.t<string>('campaign.strategyAndTracking.asiaMaxOnlyHint')}</span>}
              >
                <ThirdPartyTrackingCode/>
              </CustomField>
            </div>
        </fieldset>
        { adType === AdType.COMBO &&
          <fieldset>
            <legend>
              <span>{i18n.t<string>('campaign.strategyAndTracking.videoProgressTracking')}</span>
            </legend>
            <div className={styles.component}>
              <FormikField.Input
                name='videoProgressTrackingCode.code'
                label={i18n.t<string>('campaign.strategyAndTracking.videoProgressTrackingCode')}
                as='textarea'
                rows={4}
                hint={<span className={styles.hint}>{i18n.t<string>('campaign.strategyAndTracking.asiaMaxOnlyHint')}</span>}
              />
              <FormikField.Input
                name='videoProgressTrackingCode.offset'
                label={i18n.t<string>('campaign.strategyAndTracking.videoProgressTrackingCodeOffset')}
                type='number'
                min={0}
              />
            </div>
          </fieldset>
        }
      </div>
    );
  }
}

export default connect(CampaignTrackForm);
