import _ from 'lodash';
import i18n from 'i18n';
import { formatBytes } from 'utils/StringUtil';
import { renderErrors } from './CreativeFormHintRenderFunction';
import CtvForm from './CtvForm';
import { VideoFormModel } from './VideoFormModel';
import { BasicFormProps } from './BasicFormProps';
const mimeDB = require('mime-db');

export type CtvFormProps = {
  readonly model: CtvFormModel;
} & BasicFormProps;

export class CtvFormModel extends VideoFormModel {

  getFormContent = () => {
    return CtvForm;
  }

  getInitLimitations () {
    return {
      include: [{
        type: 'device',
        value: [{
          label: 'Connected_tv',
          value: '3'
        }, {
          label: 'Connected_device',
          value: '6'
        }, {
          label: 'Set_top_box',
          value: '7'
        }]
      }],
      exclude: [],
      nonPreferred: [],
      other: [],
      preferred: []
    };
  }

  validateTenMaxVideo = (fileData) => {
    const validTypes = ['video/mp4'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];
    if (file.size > 104857600) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '100MB' }));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }
}
