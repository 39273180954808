import _ from 'lodash';
import { getColumnSetting } from './CreativeListCommonColumnData';
import { renderColumn } from 'components/TableColumn/TableColumn';
import { CreativeListRecord } from 'core/creative/Creative';
import { OneForAllCreativeListDataProvider } from './OneForAllCreativeListDataProvider';

enum COLUMNS {
  FB_CREATIVE_STATUS = 'status'
}

export class OneForAllDisplayCreativeListDataProvider extends OneForAllCreativeListDataProvider {

  getColumns () {
    const basicColumns = super.getColumns();
    const columns = [...basicColumns];
    columns.splice(1, 0, renderColumn(getColumnSetting(COLUMNS.FB_CREATIVE_STATUS), this.formatters.fbStatusFormatter));
    return columns;
  }

  getViewModel (creative: CreativeListRecord) {
    const basicViewModel = super.getViewModel(creative);
    return _.omitBy({
      ...basicViewModel,
      [COLUMNS.FB_CREATIVE_STATUS]: _.get(creative, 'bannerExtra.status')
    }, _.isUndefined);
  }
}
