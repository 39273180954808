import { AbstractFormContentModel } from './FormContentModel';
import PPSForm from './PPSForm';
import { THIRD_PARTY_TYPE, Creative } from 'core/creative/Creative';
import _ from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import i18n from 'i18n';
import { DefaultCreativeManager, CreativeManager } from 'core/creative/CreativeManager';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { SelectOptions } from 'components/common/commonType';
import { BasicFormProps } from './BasicFormProps';
const mimeDB = require('mime-db');

export type PPSFormProps = {
  readonly model: PPSFormModel;
  readonly hintModalData?: any;
  readonly setHintModalData: (modalData: any) => void;
} & BasicFormProps;

export class PPSFormModel extends AbstractFormContentModel {

  public needBannerUrl: boolean = true;
  ppsLayoutIdData?: SelectOptions[];

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;
  constructor (
    private creativeManager: CreativeManager = new DefaultCreativeManager()
  ) {
    super();
  }

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getFormContent = () => {
    return PPSForm;
  }

  validateHtmlSnippet = (code) => {
    const error = validateEmpty(code);
    if (error) {
      return error;
    }
    const errors: any[] = [];
    const clickMacros = [
      '%%CLICK_URL_UNESC%%',
      '%%CLICK_URL_ESC%%',
      '%%CLICK_URL_ESC_ESC%%',
      '%%VAST_URL_UNESC%%'
    ];
    const hasClickMacro = clickMacros.some(macro => code.includes(macro));
    const viewableMacros = [
      '%%VIEWABLE_IMPRESSION_URL_UNESC%%',
      '%%SSP_VIEWABLE_IMPRESSION_URL_UNESC%%',
      '%%ADX_VIEWABLE_IMPRESSION_URL_UNESC%%'
    ];
    const hasViewableMacro = viewableMacros.some(macro => code.includes(macro));
    if (!hasClickMacro || !hasViewableMacro) {
      errors.push(i18n.t<string>('ppsFormValidate.labels.macroError'));
    }
    const MAX_LENGTH = 8000;
    if (code.length > MAX_LENGTH) {
      errors.push(i18n.t<string>('ppsFormValidate.labels.codeSizeToLarge'));
    }

    const regexp = /layoutId:\s*'(.*?)'/;
    const regexpForVer3 = /data-layout-id=\s*"(.*?)"/;
    let matches = code.match(regexp);
    if (!matches || !matches[1]) {
      matches = code.match(regexpForVer3);
    }
    if (!matches || !matches[1]) {
      errors.push(i18n.t<string>('ppsForm.labels.layoutIdNotFound'));
    } else {
      const layoutId = matches[1];
      let layoutIdData: SelectOptions | undefined;
      if (this.ppsLayoutIdData) {
        layoutIdData = this.ppsLayoutIdData.find(data => data.value === layoutId);
      }
      if (!layoutIdData) {
        errors.push(i18n.t<string>('ppsForm.labels.noCorrespondingLayoutId', { layoutId: layoutId }));
      }
    }
    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  validateImage = (fileData) => {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (!fileData || (!fileData.file && !fileData.url)) {
      return;
    }

    if (!fileData.file) {
      return;
    }

    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('creativeSetupFlow.labels.typeErrorHint', { type: extensions[0] });
    }
  }

  getInitTypeProperties () {
    return {
      htmlSnippet: '',
      thirdPartyType: THIRD_PARTY_TYPE.NORMAL
    };
  }

  getFormModelData (creative: Creative) {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const mediaData = creative.basic.bannerImageUrl ? {
      image: {
        url: creative.basic.bannerImageUrl
      }
    } : undefined;
    let layoutId = _.get(creativeBasic, 'ppsLayoutId');
    if (layoutId) {
      layoutId = layoutId.replace('pps_', '');
    }
    return {
      basic: {
        ...creativeBasic,
        typeProperties: {
          htmlSnippet: _.get(creativeValue, 'htmlSnippet'),
          thirdPartyType: _.get(creativeBasic, 'bannerExtra.thirdPartyType'),
          layoutId,
          subLayout: _.get(creativeBasic, 'subLayout')
        },
        medias: mediaData
      },
      limitations: creative.limitations
    };
  }

  async getPpsLayoutId () {
    if (!this.ppsLayoutIdData) {
      this.ppsLayoutIdData = await this.creativeManager.getPpsLayoutId();
    }
    return this.ppsLayoutIdData;
  }

  getPpsThumbnail = async (creativeId) => {
    try {
      return await this.creativeManager.getPpsThumbnail(creativeId);
    } catch (e) {
      return [];
    }
  }
}
