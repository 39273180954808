
export const tiktokTAInventories = {
  tiktok_location: {
    name: 'tiktok_location',
    title: 'limitation.labels.geography',
    i18nPrefix: 'geoLocations.tiktok'
  },
  tiktok_placement: {
    name: 'tiktok_placement',
    title: 'targeting.tiktok.placementsTitle',
    i18nPrefix: 'targeting.tiktok.placements'
  },
  tiktok_os: {
    name: 'tiktok_os',
    title: 'limitation.labels.os',
    i18nPrefix: 'targeting.tiktok.os'
  },
  tiktok_audience: {
    name: 'tiktok_audience',
    title: 'limitation.labels.gojekSegment',
    i18nPrefix: 'targeting.tiktok.segment'
  }
};
