import {
  DraftWebService,
  RestfulDraftWebService
} from 'ws/DraftWebService';

import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { DraftCreateDTO, DraftValidationDTO, DraftPublishResponse } from './Draft';
import { RtbCampaign } from 'core/rtbCampaign/RtbCampaign';
import { wrapCampaign } from 'ws/RtbCampaignWebService';
import { FbAdSet } from 'core/fbAdSet/FbAdSet';
import { TiktokAdGroup } from 'core/tiktokAdGroup/TiktokAdGroup';
import { wrapAdSet } from 'ws/FbAdSetWebService';
import { wrapAdGroupFromServer } from 'ws/TiktokAdGroupWebService';

export interface DraftManager {
  createDraft (draftCreateDTOList: DraftCreateDTO[]): Promise<void>;
  getValidationInfos (draftCreateDTOList: DraftCreateDTO[]): Promise<DraftValidationDTO[]>;
  getDraft (draftId: string): Promise<any>;
  updateDraft (draftId: string, data: any): Promise<any>;
  publishDraft (draftId: string, data: any): Promise<DraftPublishResponse>;
  deleteDrafts (draftIds: (string | number)[]): Promise<any>;
}

export class DefaultDraftManager implements DraftManager {
  webService: DraftWebService;

  constructor (
    webService: DraftWebService = new RestfulDraftWebService()
  ) {
    this.webService = webService;
  }

  async createDraft (draftCreateDTOList: DraftCreateDTO[]): Promise<void> {
    return this.webService.createDraft(draftCreateDTOList);
  }

  async getValidationInfos (draftCreateDTOList: DraftCreateDTO[]): Promise<DraftValidationDTO[]> {
    return this.webService.getValidationInfos(draftCreateDTOList);
  }

  getDraft (draftId: string): Promise<any> {
    throw new Error('need call inheritance class method instead!');
  }

  updateDraft (draftId: string, data: any): Promise<any> {
    throw new Error('need call inheritance class method instead!');
  }

  publishDraft (draftId: string, data: any): Promise<any> {
    throw new Error('need call inheritance class method instead!');
  }

  async deleteDrafts (draftIds: (string | number)[]): Promise<any> {
    return this.webService.deleteDrafts(draftIds);
  }
}

export class RtbCampaignDraftManager extends DefaultDraftManager {

  async getDraft (draftId: string): Promise<RtbCampaign> {
    const data = await this.webService.getDraft(L1ObjectChannel.RTB, draftId);
    return wrapCampaign({
      ...data,
      campaign: {
        ...data.campaign,
        draft: true
      }
    });
  }

  async updateDraft (draftId: string, data: any): Promise<any> {
    return this.webService.updateDraft(L1ObjectChannel.RTB, draftId, data);
  }

  async publishDraft (draftId: string, data: any): Promise<any> {
    return this.webService.publishDraft(L1ObjectChannel.RTB, draftId, data);
  }
}

export class FbAdSetDraftManager extends DefaultDraftManager {

  async getDraft (draftId: string): Promise<FbAdSet> {
    const data = await this.webService.getDraft(L1ObjectChannel.FB, draftId);
    return wrapAdSet({
      ...data,
      draft: true
    });
  }

  async updateDraft (draftId: string, data: any): Promise<any> {
    return this.webService.updateDraft(L1ObjectChannel.FB, draftId, data);
  }

  async publishDraft (draftId: string, data: any): Promise<any> {
    return this.webService.publishDraft(L1ObjectChannel.FB, draftId, data);
  }
}

export class TiktokAdGroupDraftManager extends DefaultDraftManager {

  async getDraft (draftId: string): Promise<TiktokAdGroup> {
    const data = await this.webService.getDraft(L1ObjectChannel.TIKTOK, draftId);
    return wrapAdGroupFromServer({
      ...data,
      draft: true
    });
  }

  async updateDraft (draftId: string, data: any): Promise<any> {
    return this.webService.updateDraft(L1ObjectChannel.TIKTOK, draftId, data);
  }

  async publishDraft (draftId: string, data: any): Promise<DraftPublishResponse> {
    return this.webService.publishDraft(L1ObjectChannel.TIKTOK, draftId, data);
  }
}
