import React from 'react';
import styles from './l1ObjectBudgetManagement.module.scss';
import cx from 'classnames/bind';
import { Status } from 'components/Status/Status';
import { InputGroup } from 'react-bootstrap';
import i18n from 'i18n';

const classNames = cx.bind(styles);

const stateFormatter = ({
  des,
  color
}) => {
  return (
    <Status
      label={des}
      color={color}
    />
  );
};

const budgetFormatter = (budget: number | string, viewData, _2, formatExtraData: any): any => {
  const currency = formatExtraData.currency;
  const onChange = (event) => {
    formatExtraData.onChange(viewData.id, event.target.value);
  };
  const className = classNames({
    error: !!viewData.error
  });
  const inputClassName = classNames('form-control', {
    error: !!viewData.error
  });
  return (
    <>
      <InputGroup>
        <InputGroup.Prepend>
          <InputGroup.Text className={className}>
            {currency}
          </InputGroup.Text>
        </InputGroup.Prepend>
        <input
          className={inputClassName}
          defaultValue={budget.toString()}
          onChange={onChange}
          type='number'
          min={0}
        />
      </InputGroup>
      {viewData.error && <span className={styles.errorTip}>{viewData.error}</span>}
    </>
  );
};

const budgetFooterFormatter = (totalBudget, budgetSum) => {
  const remainBudget = +totalBudget - +budgetSum;
  return i18n.t<string>(`l1ObjectBudgetManagement.hints.campaignBudget`, { total: remainBudget });
};

const formatters = {
  stateFormatter,
  budgetFormatter,
  budgetFooterFormatter
};

export default formatters;
