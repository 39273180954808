import React from 'react';
import { FormControl } from 'react-bootstrap';
import classnames from 'classnames/bind';
import styles from './inputField.module.scss';
import { FormikFieldProps, withFormikField } from 'hoc/withFormikField';
import { FieldLayoutProps, withFieldLayout } from 'hoc/withFieldLayout';

const cx = classnames.bind(styles);

export interface InputProps {
  as?: any;
  type?: string;
  disabled?: boolean;
  rows?: number;
  name: string;
  value?: string;
  className?: string;
  autoComplete?: string;
  min?: number;
  max?: number;
  step?: number;
  error?: any;
  innerRef?: any;
  placeholder?: string;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (value: any) => void;
}

export interface FormikInputProps extends FormikFieldProps, InputProps {}
export interface FormikInputWithFieldLayoutProps extends FormikFieldProps, Omit<FieldLayoutProps, 'name'>, InputProps {}
export type FormikInputFieldProps = ({ withFieldLayout?: true } & FormikInputWithFieldLayoutProps) | ({ withFieldLayout: false } & FormikInputProps);

const defaultProps = {
  type: 'text',
  disabled: false,
  rows: 12,
  autoComplete: 'off'
};

const InputContent: React.FC<InputProps> = ({
  name,
  type,
  error,
  className,
  onChange,
  innerRef,
  ...props
}) => {

  const hasError = error !== undefined;
  const classNameWithError = cx(className, {
    error: hasError
  });

  const inputOnWheel = (e) => e.target.blur();
  return (
    <FormControl
      {...props}
      name={name}
      type={type}
      className={classNameWithError}
      onChange={onChange}
      onWheel={inputOnWheel}
      ref={innerRef}
    />
  );
};
InputContent.defaultProps = defaultProps;
const MemorizedInputContent = React.memo(InputContent);

export const InputField = withFieldLayout<FieldLayoutProps & InputProps>(InputContent);

const FormikInput = withFormikField<FormikInputProps>(MemorizedInputContent);
const FormikInputWithFieldLayout = withFormikField<FormikInputFieldProps>(
  withFieldLayout<FieldLayoutProps & InputProps>(InputContent)
);

export const FormikInputField: React.FC<FormikInputFieldProps> = ({
  withFieldLayout,
  ...props
}) => {
  if (withFieldLayout === false) {
    return <FormikInput {...props} />;
  } else {
    return <FormikInputWithFieldLayout {...props} />;
  }
};
