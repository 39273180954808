import React from 'react';
import _ from 'lodash';
import styles from './html5CreativeDetail.module.scss';
import { CreativeBasic } from 'core/creative/Creative';

export const HTML5CreativeDetail: React.FunctionComponent<{creative: Partial<CreativeBasic>}> = (props) => {
  const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
  if (!creativeValue) {
    return <div/>;
  }
  const htmlUrl = _.get(creativeValue, 'htmlUrl', '');
  if (_.isEmpty(htmlUrl)) {
    return <div/>;
  }

  const width = _.get(creativeValue, 'w', '300');
  const height = _.get(creativeValue, 'h', '250');

  const onClick = (e) => {
    window.open(props.creative.bannerUrl, '_blank', 'noopener');
  };
  return (
    <div className={styles.htmlCreativeDetailContainer} >
      <div onClick={onClick}>
        <iframe
          style={{ width, height }}
          src={htmlUrl + '?t=' + Date.now()}
          title={'html5_preview'}
          frameBorder='0'
        />
      </div>
    </div>
  );
};
