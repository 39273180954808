import { AgencyManager, DefaultAgencyManager } from 'core';
import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import i18n from 'i18n';
import { SelectOptions } from 'components/common/commonType';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import moment from 'moment';
import { useCallAPI } from 'hooks/useCallAPI';

export enum CreditLogColumns {
  DATE = 'ctime',
  NOTE = 'note',
  PAID = 'paid',
  UNPAID = 'unpaid',
  REMAIN = 'updatedCredit'
}

const defaultAgencyManager = new DefaultAgencyManager();

export const useCreditDetailModel = (
  agencyId: number,
  agencyManager: AgencyManager = defaultAgencyManager
) => {

  const { loading, callAPIs } = useCallAPI();
  const [creditInfo, setCreditInfo] = useState<SelectOptions[]>([]);
  const [bills, setBills] = useState<any[]>([]);
  const [billInfo, setBillInfo] = useState<SelectOptions[]>([]);
  const [creditLog, setCreditLog] = useState<any[]>([]);

  const initData = useCallback(async () => {
    callAPIs([
      agencyManager.fetchAgency.bind(agencyManager, agencyId),
      agencyManager.getBill.bind(agencyManager, agencyId),
      agencyManager.getCreditLog.bind(agencyManager, agencyId)
    ], (agency, bills, creditLogs) => {
      const currency = _.get(agency, 'detail.currency', 'NTD');
      const billsData = {
        paid: bills.find(bill => bill.state === 'PAID'),
        unpaid: bills.filter(bill => bill.state === 'UNPAID').reduce((acc, bill) => {
          acc.credit += bill.credit;
          acc.payment += bill.payment;
          acc.billPeriod.push(bill.dateId);
          return acc;
        }, { credit: 0, payment: 0, billPeriod: [] })
      };
      setBills(bills);
      setCreditInfo([
        { label: i18n.t<string>('creditDetail.labels.remainCredit'), value: formatPriceWithCurrency(currency, _.get(agency, 'detail.credit', 0)) },
        { label: i18n.t<string>('creditDetail.labels.totalCredit'), value: formatPriceWithCurrency(currency, _.get(agency, 'detail.addonProps.creditLimit', 0)) }
      ]);
      setBillInfo([
        {
          label: i18n.t<string>('creditDetail.labels.billUnpaid'),
          value: formatPriceWithCurrency(currency, billsData.unpaid.payment),
          extra: billsData.paid ?
            i18n.t<string>('creditDetail.labels.paymentAt', { amount: formatPriceWithCurrency(currency, billsData.paid.payment), at: moment(billsData.paid.paidDate).format('YYYY-MM-DD') }) :
            i18n.t<string>('creditDetail.labels.noLastPaid')
        },
        {
          label: i18n.t<string>('creditDetail.labels.billSpent'),
          value: formatPriceWithCurrency(currency, billsData.unpaid.credit)
        }
      ]);
      setCreditLog(creditLogs.map(log => ({
        ...log,
        [CreditLogColumns.DATE]: moment(log.ctime).format('YYYY-MM-DD'),
        [CreditLogColumns.NOTE]: i18n.t<string>(`creditLog.labels.${_.camelCase(log.actionType)}`, {
          orderNumber: _.get(log, 'note.values.orderNumber'),
          dateId: _.get(log, 'note.values.dateId')
        }),
        [CreditLogColumns.PAID]: formatPriceWithCurrency(currency, log.amount > 0 ? log.amount : 0),
        [CreditLogColumns.UNPAID]: formatPriceWithCurrency(currency, log.amount < 0 ? log.amount * -1 : 0),
        [CreditLogColumns.REMAIN]: formatPriceWithCurrency(currency, log.updatedCredit)
      })));
    });
  }, [agencyManager, agencyId, callAPIs]);

  useEffect(() => {
    initData();
  }, [initData]);

  const columns = [
    renderColumn(sortableColumn(CreditLogColumns.DATE, `creditLog.headers.${CreditLogColumns.DATE}`, true)),
    renderColumn(sortableColumn(CreditLogColumns.NOTE, `creditLog.headers.${CreditLogColumns.NOTE}`, true)),
    renderColumn(sortableColumn(CreditLogColumns.PAID, `creditLog.headers.${CreditLogColumns.PAID}`, true)),
    renderColumn(sortableColumn(CreditLogColumns.UNPAID, `creditLog.headers.${CreditLogColumns.UNPAID}`, true)),
    renderColumn(sortableColumn(CreditLogColumns.REMAIN, `creditLog.headers.${CreditLogColumns.REMAIN}`, true))
  ];

  return {
    loading,
    creditInfo,
    bills,
    billInfo,
    columns,
    creditLog
  };
};
