import { SegmentImportType } from 'core/segment/SegmentManager';
import { Category, CategoryFormType, Source, SourceFormType } from './TenmaxSegment';
import { RestfulTenmaxSegmentWebService, TenmaxSegmentWebService } from 'ws/TenmaxSegmentWebService';

export interface TenmaxSegmentManager {
  getSource: (sourceId: number) => Promise<Source>;
  getSources: () => Promise<Source[]>;
  getCategory: (sourceId: number, categoryId: number) => Promise<Category>;
  getCategories: (sourceId: number) => Promise<Category[]>;
  createSource: (source: SourceFormType) => Promise<number>;
  updateSource: (source: Source) => Promise<void>;
  createCategory: (category: CategoryFormType) => Promise<void>;
  updateCategory: (category: CategoryFormType & {id: number}) => Promise<void>;
  importAudiences: (sourceId: number, categoryId: number, importType: SegmentImportType, file: File) => Promise<void>;
  exportAudiences: (sourceId: number, categoryId: number) => Promise<void>;
}

export class DefaultTenmaxSegmentManager implements TenmaxSegmentManager {

  webService: TenmaxSegmentWebService;
  constructor (webService: TenmaxSegmentWebService = new RestfulTenmaxSegmentWebService()) {
    this.webService = webService;
  }

  async getSource (sourceId: number) {
    return this.webService.getSource(sourceId);
  }

  async getSources () {
    return this.webService.getSources();
  }

  async getCategory (sourceId: number, categoryId: number) {
    return this.webService.getCategory(sourceId, categoryId);
  }

  async getCategories (sourceId: number) {
    return this.webService.getCategories(sourceId);
  }

  async createSource (source: SourceFormType) {
    return this.webService.createSource(source);
  }

  async updateSource (source: Source) {
    await this.webService.updateSource(source);
  }

  async createCategory (category: CategoryFormType) {
    await this.webService.createCategory(category);
  }

  async updateCategory (category: CategoryFormType & {id: number}) {
    await this.webService.updateCategory(category);
  }

  async importAudiences (sourceId: number, categoryId: number, importType: SegmentImportType, file: File): Promise<void> {
    const formData = new FormData();
    formData.append('is_device', importType === SegmentImportType.DEVICE_ID ? 'true' : 'false');
    formData.append('audiences', file);
    await this.webService.importAudiences(sourceId, categoryId, formData);
  }

  async exportAudiences (sourceId: number, categoryId: number): Promise<void> {
    await this.webService.exportAudiences(sourceId, categoryId);
  }
}
