import { useCallback, useEffect, useMemo, useState } from 'react';
import { useCallAPI } from 'hooks/useCallAPI';
import { CustomLayout, CustomLayoutStatus, CustomLayoutWithPagination } from 'core/customLayout/CustomLayout';
import { compact } from 'lodash';
import i18n from 'i18n';
import { toast } from 'react-toastify';
import { getContentWithEyeColumnn, getNameIdColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { Pageable } from 'ws/Pageable';
import { CustomLayoutManager, DefaultCustomLayoutManager } from 'core/customLayout/CustomLayoutManager';

export type DeleteAlertModalData = {
  title: string;
  message: string;
  dismiss: () => void;
  onConfirm: () => void;
  onCancel: () => void;
};

export enum CustomLayoutListColumns {
  ID = 'id',
  NAME = 'name',
  PREVIEW_URL = 'previewUrl',
  LAYOUT_ID = 'layoutId',
  DEVICE = 'device',
  TYPE = 'type'
}

const defaultCustomLayoutManager: CustomLayoutManager = new DefaultCustomLayoutManager();

export const useCustomLayoutListModel = () => {
  const [customLayouts, setCustomLayouts] = useState<CustomLayout[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [deleteAlertModalData, setDeleteAlertModalData] = useState<DeleteAlertModalData | undefined>();
  const [pageable, setPageable] = useState<Pageable & { totalCount: number }>({
    page: 1,
    sizePerPage: 10,
    sort: CustomLayoutListColumns.ID,
    direction: 'desc',
    totalCount: 0
  });

  const {
    loading,
    callAPIs
  } = useCallAPI();

  const columns = useMemo(() => (compact([
    getNameIdColumn(
      sortableColumn(CustomLayoutListColumns.NAME, i18n.t<string>('customLayoutList.headers.name'))
    ),
    getContentWithEyeColumnn(
      sortableColumn(CustomLayoutListColumns.PREVIEW_URL, i18n.t<string>('customLayoutList.headers.previewUrl'), false),
      i18n.t<string>('customLayoutList.buttons.preview'),
      (customLayout: CustomLayout) => window.open(customLayout.previewUrl, '_blank', 'noopener')
    ),
    sortableColumn(CustomLayoutListColumns.LAYOUT_ID, i18n.t<string>('customLayoutList.headers.layoutId')),
    sortableColumn(CustomLayoutListColumns.DEVICE, i18n.t<string>('customLayoutList.headers.device')),
    sortableColumn(CustomLayoutListColumns.TYPE, i18n.t<string>('customLayoutList.headers.type'))
  ])), []);

  const filteredCustomLayouts = useMemo(() => {
    return customLayouts.filter(customLayout => {
      const nameMatched = customLayout.name.toLowerCase().includes(searchString.toLowerCase());
      const idMatched = customLayout.id.toString().includes(searchString);
      return (nameMatched || idMatched);
    });
  }, [customLayouts, searchString]);

  const fetchCustomLayouts = useCallback(async (page: number = 1) => {
    callAPIs([
      () => defaultCustomLayoutManager.getCustomLayouts(searchString, [CustomLayoutStatus.ACTIVE], undefined, {
        page,
        sort: pageable.sort,
        sizePerPage: pageable.sizePerPage,
        direction: pageable.direction
      })
    ], (customLayoutsWithPagination: CustomLayoutWithPagination) => {
      setCustomLayouts(customLayoutsWithPagination.customLayouts);
      setPageable(prev => ({
        ...prev,
        page: customLayoutsWithPagination.pagination.page,
        sizePerPage: customLayoutsWithPagination.pagination.size,
        totalCount: customLayoutsWithPagination.pagination.totalCount
      }));
    });
  }, [callAPIs, searchString, pageable.direction, pageable.sizePerPage, pageable.sort]);

  const deleteCustomLayout = useCallback(async (customLayout: CustomLayout) => {
    callAPIs([
      () => defaultCustomLayoutManager.deleteCustomLayout(customLayout.id)
    ], () => {
      fetchCustomLayouts();
      setDeleteAlertModalData(undefined);
      toast.success(i18n.t<string>('common.messages.succeeded'));
    });
  }, [callAPIs, fetchCustomLayouts]);

  useEffect(() => {
    fetchCustomLayouts();
  }, [fetchCustomLayouts]);

  const onHandleSearch = useCallback((searchString: string) => {
    setSearchString(searchString);
  }, []);

  const onHandleDelete = useCallback((customLayout: CustomLayout) => {
    setDeleteAlertModalData({
      title: i18n.t<string>('customLayoutList.alerts.delete.title'),
      message: i18n.t<string>('customLayoutList.alerts.delete.message', {
        name: customLayout.name
      }),
      dismiss: () => setDeleteAlertModalData(undefined),
      onConfirm: () => deleteCustomLayout(customLayout),
      onCancel: () => setDeleteAlertModalData(undefined)
    });
  }, [deleteCustomLayout]);

  const handleTableChange = useCallback((type: string, props: any) => {
    if (type === 'sort') {
      (props.sortField !== pageable.sort || props.sortOrder !== pageable.direction) &&
        setPageable(prev => ({
          ...prev,
          page: 1,
          sort: props.sortField,
          direction: props.sortOrder
        }));
      return;
    }
    fetchCustomLayouts(props.page);
  }, [pageable.sort, pageable.direction, fetchCustomLayouts]);

  return {
    loading,
    searchString,
    deleteAlertModalData,
    pageable,
    columns,
    filteredCustomLayouts,
    onHandleSearch,
    onHandleDelete,
    handleTableChange
  };
};
