import _ from 'lodash';
import React, { Fragment, ReactElement } from 'react';

import i18n from 'i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ColumnDescription, ColumnFormatter, HeaderFormatter, SortOrder } from 'react-bootstrap-table-next';
import { NameWithIdColumn } from './NameWithIdColumn';
import { SelectOptions } from '../common/commonType';
import { SelectableColumn } from './SelectableColumn';
import { SelectableHeader } from './SelectableHeader';
import { LabelValueColumn } from './LabelValueColumn';
import { ContentWithEyeColumn } from './ContentWithEyeColumn';

export type SortDescriptor = [{

  readonly order: SortOrder;
  readonly dataField: any;
}];

export type ColumnDefinition = Omit<ColumnDescription, 'formatter' | 'headerFormatter'>;

function translatedHeader (column: ColumnDescription, columnIndex: number, { sortElement }) {
  const header = i18n.t<string>(column.text);
  return (
    <Fragment>
      {header}
      {sortElement}
    </Fragment>
  );
}

export function renderColumn<R extends object = any, C= any, E= any> (column: ColumnDefinition, formatter: ColumnFormatter<R, E, C> | undefined = undefined, headerFormatter: HeaderFormatter<R> | undefined = translatedHeader): ColumnDescription {
  const columnProps: ColumnDescription<R> = {
    ...column,
    formatter: formatter,
    headerFormatter
  };
  return _.omitBy(columnProps, _.isNil) as ColumnDescription;
}

export function sortableColumn (dataField: string, text: string, sort: boolean = true): ColumnDefinition {
  return {
    sort,
    text,
    dataField
  };
}

export function checkedFormatter (cell: boolean) {
  return (
    <Fragment>
      {cell ? <FontAwesomeIcon icon={faCheck} /> : <FontAwesomeIcon icon={faTimes} />}
    </Fragment>
  );
}

export function withStyles (column: ColumnDefinition, styles: {[key: string]: string}) {
  return {
    ...column,
    classes: () => styles[column.dataField],
    headerClasses: () => styles[column.dataField]
  };
}

export function selectableHeaderFormatter (column: ColumnDefinition, _2, { sortElement }) {
  const formatExtraData = column.formatExtraData;
  if (!formatExtraData) {
    return (
      <div>
        {column.text}
        {sortElement}
      </div>
    );
  }
  const selectedRows = _.defaultTo(formatExtraData.selectedRows, []);
  return (
    <SelectableHeader
      allSelected={formatExtraData.allSelected}
      selectedCount={selectedRows.length}
      onSelectPage={formatExtraData.onSelectPage}
    >
      {column.text}
      {sortElement}
    </SelectableHeader>
  );
}

export function selectableColumnFormatter<T extends { id: string | number}> (
  data: any,
  record: T,
  rowIndex: number,
  formatExtraData: {
    selectedRows: (string | number)[],
    onSelect: (id: string | number) => void,
    columnFormatter: (data: any, record: T, rowIndex: number, extraData: any) => ReactElement
  }
): ReactElement {
  return (
    <SelectableColumn
      id={record.id}
      isSelected={formatExtraData.selectedRows.includes(record.id)}
      onSelect={formatExtraData.onSelect}
    >
      {formatExtraData.columnFormatter(data, record, rowIndex, formatExtraData)}
    </SelectableColumn>
  );
}

export function getNameIdColumn<T extends { id: string | number, name: string}> (column: ColumnDefinition, getClickUrl?: (record: T) => string | undefined) {
  return renderColumn({
    ...column,
    formatExtraData: { getClickUrl }
  }, function nameWithIdFormatter<T extends { id: string | number, name: string}> (
    _1,
    record: T,
    rowIndex: number,
    formatExtraData?: {
      getClickUrl: (record: T) => string | undefined
    }
  ): ReactElement {

    const clickUrl = formatExtraData!.getClickUrl && formatExtraData!.getClickUrl(record);
    return (
      <NameWithIdColumn
        id={record.id}
        name={record.name}
        clickUrl={clickUrl}
      />
    );
  });
}

export function getStringArrayColumn (column: ColumnDefinition, valueDecorator?: (value: string | number) => string) {
  return renderColumn(column, (strings: string[]): ReactElement => {
    if (!strings) return <div/>;
    const renderStrings = () => (
      strings.map((str, index) => (
        <div key={index}>
          {valueDecorator ? valueDecorator(str) : str}
        </div>
      ))
    );
    return (
      <div>
        {renderStrings()}
      </div>
    );
  });
}

export function getCheckedColumn (column: ColumnDefinition) {
  return renderColumn(column, checkedFormatter);
}

export function getLabelValueColumn (column: ColumnDefinition, getOptions: (record: any) => SelectOptions[]) {
  return renderColumn({
    ...column,
    formatExtraData: { getOptions }
  }, (
    _1,
    record: any,
    _3,
    formatExtraData?: {
      getOptions: (record: any) => SelectOptions[]
    }
  ): ReactElement => {
    return (
      <LabelValueColumn options={formatExtraData!.getOptions(record)} />
    );
  });
}

export function getContentWithEyeColumnn<T extends { id: string | number, name: string}> (
  column: ColumnDefinition,
  eyeDescription: string,
  onClickBtn: (record: T) => void,
  contentData?: {
    component: any;
    props: any;
  }
) {
  return renderColumn({
    ...column,
    formatExtraData: {
      eyeDescription,
      onClickBtn,
      contentData
    }
  }, (
    _1,
    record: T,
    _3,
    formatExtraData?: {
      eyeDescription: string,
      onClickBtn: (record: T) => void,
      contentData?: {
        component: any;
        props: any;
      }
    }
  ): ReactElement => {
    const tooltipProps = {
      id: `faEye-tooltip-${record.id}`,
      tooltip: formatExtraData!.eyeDescription
    };
    return (
      <ContentWithEyeColumn
        onClickBtn={_.partial(formatExtraData!.onClickBtn, record)}
        tooltipProps={tooltipProps}
        contentData={formatExtraData!.contentData}
      />
    );
  });
}

export function getComponentColumn (column: ColumnDefinition, component: React.ElementType, getProps: (record: any) => any) {
  return renderColumn({
    ...column,
    formatExtraData: {
      component,
      getProps
    }
  }, (
    _1,
    record,
    _3,
    formatExtraData?: {
      component: any;
      getProps: any;
    }
  ): ReactElement => {
    if (!formatExtraData) {
      return <div/>;
    }

    const Component = formatExtraData.component;
    return (
      <Component {...formatExtraData.getProps(record)} />
    );
  });
}
