export enum TiktokObjective {
  REACH = 'REACH',
  TRAFFIC = 'TRAFFIC',
  VIDEO_VIEWS = 'VIDEO_VIEWS',
  CONVERSIONS = 'CONVERSIONS'
}

export enum TiktokOptStatus {
  ENABLE = 'ENABLE',
  DISABLE = 'DISABLE',
  DELETE = 'DELETE'
}

export enum TiktokStatus {
  CAMPAIGN_STATUS_DELETE = 'CAMPAIGN_STATUS_DELETE',
  CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY = 'CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY',
  CAMPAIGN_STATUS_ADVERTISER_AUDIT = 'CAMPAIGN_STATUS_ADVERTISER_AUDIT',
  ADVERTISER_CONTRACT_PENDING = 'ADVERTISER_CONTRACT_PENDING',
  CAMPAIGN_STATUS_BUDGET_EXCEED = 'CAMPAIGN_STATUS_BUDGET_EXCEED',
  CAMPAIGN_STATUS_DISABLE = 'CAMPAIGN_STATUS_DISABLE',
  CAMPAIGN_STATUS_ENABLE = 'CAMPAIGN_STATUS_ENABLE'
}

export enum TiktokStatusDetail {
  CAMPAIGN_STATUS_DELETE = 'Deleted',
  CAMPAIGN_STATUS_ADVERTISER_AUDIT_DENY = 'Advertiser review failed',
  CAMPAIGN_STATUS_ADVERTISER_AUDIT = 'Advertiser review in progress',
  ADVERTISER_CONTRACT_PENDING = 'Advertiser contract has not taken effect',
  CAMPAIGN_STATUS_BUDGET_EXCEED = 'Over budget',
  CAMPAIGN_STATUS_DISABLE = 'Suspended',
  CAMPAIGN_STATUS_ENABLE = 'Advertising in delivery'
}

export type TiktokCampaign = {
  campaign_id: string | number | null;
  campaign_name: string;
  advertiser_id: string | number;
  objective_type: string;
  opt_status: TiktokOptStatus;
  status: TiktokStatus;
};
