import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { useCallback, useEffect, useState } from 'react';
import formatters from './listFormatters';
import styles from './goSegmentList.module.scss';
import i18n from 'i18n';
import _ from 'lodash';
import { GoSegmentManager, DefaultGoSegmentManager } from 'core/goSegment/GoSegmentManager';
import { GoSegmentListRecord } from 'core/goSegment/GoSegment';
import { UploadEvent } from 'UploadProgressManager';
import { useCallAPI } from 'hooks/useCallAPI';

export enum GoSegmentListColumns {
  ID = 'segmentId',
  DESCRIPTION = 'description',
  EXPIRATION = 'max_alive_date',
  STATUS = 'status',
  TYPE = 'type',
  DETAIL = 'detail',
  EDITBTNS = 'editBtns'
}

const defaultGoSegmentManager: GoSegmentManager = new DefaultGoSegmentManager();

export const useGoSegmentListModel = (
  isAdmin: boolean,
  agencyId: number | string | undefined,
  advertiserId: number | string | undefined,
  goSegmentManager: GoSegmentManager = defaultGoSegmentManager
) => {

  const [goSegments, setGoSegments] = useState<GoSegmentListRecord[]>([]);
  const [filteredList, setFilteredList] = useState<GoSegmentListRecord[]>([]);
  const [searchString, setSearchString] = useState<string>('');
  const [deleteGoSegment, setDeleteGoSegment] = useState<() => Promise<void> | undefined>();
  const [sql, setSql] = useState<string | undefined>();
  const { loading, callAPIs } = useCallAPI();

  const fetchGoSegmentsData = useCallback(async () => {
    if (!isAdmin || (isAdmin && agencyId)) {
      callAPIs([
        goSegmentManager.getGoSegments.bind(goSegmentManager, agencyId)
      ], goSegments => {
        setGoSegments(goSegments);
        const event = new CustomEvent(UploadEvent.UPLOAD_SYNC);
        window.dispatchEvent(event);
      });
    } else {
      setGoSegments([]);
    }
  }, [agencyId, goSegmentManager, isAdmin, callAPIs]);

  const downloadSegment = useCallback(async (segmentId) => {
    callAPIs([
      goSegmentManager.downloadSegment.bind(goSegmentManager, segmentId)
    ]);
  }, [goSegmentManager, callAPIs]);

  useEffect(() => {
    fetchGoSegmentsData();
  }, [fetchGoSegmentsData]);

  const progressUpdater = useCallback((goSegments, event) => {
    const progressData = event.detail.progressData;
    const ids = Object.keys(progressData);
    let needUpdate = false;
    const newGoSegments = goSegments.map(segment => {
      let progress = segment.progress;
      if (ids.includes(segment.segmentId)) {
        progress = progressData[segment.segmentId] === 1 ? undefined : progressData[segment.segmentId];
        needUpdate = true;
      }
      return {
        ...segment,
        progress
      };
    });
    needUpdate && setGoSegments(newGoSegments);
  }, []);

  useEffect(() => {
    const updateHandler = _.partial(progressUpdater, goSegments);
    window.addEventListener(UploadEvent.UPLOAD_UPDATE, updateHandler);
    return () => {
      window.removeEventListener(UploadEvent.UPLOAD_UPDATE, updateHandler);
    };
  }, [progressUpdater, goSegments]);

  useEffect(() => {
    const filteredList = goSegments.filter(goSegment => {
      const nameInclude = goSegment.name.toLowerCase().includes(searchString.toLowerCase());
      const idInclude = goSegment.segmentId.toString().includes(searchString);
      const advertiserMatch = goSegment.advertiserId === advertiserId || advertiserId === undefined;
      return (nameInclude || idInclude) && advertiserMatch;
    });
    setFilteredList(filteredList);
  }, [advertiserId, searchString, goSegments]);

  const onDeleteBtnClick = (goSegmentToDelete: number) => setDeleteGoSegment(_.partial(deleteFunc, goSegmentToDelete));

  const onGetSQLClick = useCallback(async (audienceBrief: string) => {
    callAPIs([
      goSegmentManager.getSegmentSQL.bind(goSegmentManager, audienceBrief)
    ], sql => setSql(sql));
  }, [goSegmentManager, callAPIs]);

  const onDeleteModalClose = useCallback((refresh: boolean) => {
    setDeleteGoSegment(undefined);
    refresh && fetchGoSegmentsData();
  }, [fetchGoSegmentsData]);

  const deleteFunc = useCallback(async (goSegmentId: number) => {
    callAPIs([
      goSegmentManager.deleteGoSegment.bind(goSegmentManager, goSegmentId)
    ], () => onDeleteModalClose(true));
  }, [goSegmentManager, onDeleteModalClose, callAPIs]);

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `goSegmentList.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const columns = [
    renderColumn(columnDefinition(GoSegmentListColumns.ID), formatters.nameFormatter),
    renderColumn(columnDefinition(GoSegmentListColumns.DESCRIPTION)),
    renderColumn(columnDefinition(GoSegmentListColumns.TYPE),
      value => value ?
        i18n.t<string>(`goSegmentList.types.${value.toLowerCase()}`) :
        i18n.t<string>('goSegmentList.types.upload')),
    renderColumn(columnDefinition(GoSegmentListColumns.STATUS), formatters.statusFormatter),
    renderColumn(columnDefinition(GoSegmentListColumns.DETAIL), formatters.detailFormatter),
    renderColumn(columnDefinition(GoSegmentListColumns.EXPIRATION)),
    renderColumn({
      ...columnDefinition(GoSegmentListColumns.EDITBTNS),
      text: '',
      sort: false,
      formatExtraData: {
        onDeleteBtnClick,
        onGetSQLClick,
        onDownloadClick: downloadSegment
      }
    }, formatters.floatingEditBtnsFormatter)
  ];

  return {
    sql,
    loading,
    columns,
    filteredList,
    searchString,
    onSqlModalClose: _.partial(setSql, undefined),
    deleteGoSegment,
    setSearchString,
    onDeleteModalClose: _.partial(onDeleteModalClose, false)
  };
};
