import React from 'react';

import './AppHeader.scss';

import i18n from 'i18n';
import { ActorMenu } from 'components/ActorMenu';
import { AppHeaderProps, AppHeaderModel, AppHeaderState } from './AppHeaderModel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import appHeaderLogo from 'assets/pmax-logo.png';
import appHeaderLogo2x from 'assets/pmax-logo@2x.png';
import { Modal } from 'components/common/Modal/Modal';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs, notRoles } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import { RoleNames } from 'core';
import { LoadingIndicator } from 'components/common/LoadingIndicator';

export class AppHeader extends React.Component<AppHeaderProps, AppHeaderState> {
  menuRef: any;
  handler: number;

  constructor (props: any) {
    super(props);
    this.menuRef = React.createRef();
    this.state = this.props.model.state;
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  logout = () => {
    this.props.model.logout();
  }

  changeLanguage = () => {
    this.props.model.showHideAccoutMenu();
    this.props.model.showChangeLanguageAlertModel();
  }

  toggle = () => {
    this.props.model.toggle();
  }

  showHideAcountMenu = () => {
    this.props.model.showHideAccoutMenu();
    this.setState(this.props.model.state);
  }

  dismiss = (e: any) => {
    if (this.menuRef.current && this.menuRef.current.contains(e.target)) {
      return;
    }
    if (this.props.model.state.menuShown) {
      this.showHideAcountMenu();
    }
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.dismiss, false);
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
    document.removeEventListener('mousedown', this.dismiss);
  }

  accountMenu (model: AppHeaderModel) {
    const classes = model.state.menuShown ? 'account-menu' : 'account-menu hidden';
    return (
      <ul className={classes}>
        <PermissionChecker permissionAware={hasFuncs(Permission.ACCOUNT_SUDO)}>
          <li className='sudo' onClick={this.props.model.sudo}>{i18n.t<string>('appHeader.sudo.title')}</li>
        </PermissionChecker>
        <PermissionChecker permissionAware={notRoles(RoleNames.guest)}>
          <li className='changePassword' onClick={this.props.model.changePassword}>{i18n.t<string>('appHeader.changePassword')}</li>
        </PermissionChecker>
        <li className='changeLanguage' onClick={this.changeLanguage}>
          {i18n.t<string>(`locales.${model.language}`)}
        </li>
        <li className='logout' onClick={this.logout}>{i18n.t<string>('appHeader.logout')}</li>
      </ul>
    );
  }

  renderModal (modalData) {
    const model = this.props.model;
    const ModalContent: typeof React.Component = modalData.component;
    return (
      <Modal
        fullScreen={modalData.fullScreen}
        title={modalData.title}
        primaryButton={modalData.confirmBtnData && {
          title: modalData.confirmBtnData.title,
          callback: modalData.confirmBtnData.callback
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.close'),
          callback: model.hideModal
        }}
        dismiss={model.hideModal}
        animation={false}
      >
        {ModalContent ? <ModalContent {...modalData.componentProps} /> : modalData.message}
      </Modal>
    );
  }

  render () {
    const model = this.props.model;
    const actorMenu = (model.actorMenuModel !== null) ? <ActorMenu model={model.actorMenuModel} /> : '';
    const email = (model.account !== null) ? model.account.email : '';
    return (
      <div className='app-header'>
        {model.state.loading && <LoadingIndicator />}
        <a className='logo' href='/'>
          <img src={appHeaderLogo} srcSet={appHeaderLogo2x} alt='logo' />
        </a>
        {model.account !== null && <div className='toggle' onClick={this.toggle}><FontAwesomeIcon icon={faBars} /></div>}
        {actorMenu}
        {this.state.modalData && this.renderModal(model.state.modalData)}
        {model.account !== null &&
          <div className='account-info' ref={this.menuRef}>
            <div className='menu-title' onClick={this.showHideAcountMenu}>
              <span>{email}</span>
              <span className='arrow' />
            </div>
            {this.accountMenu(model)}
          </div>
        }
      </div>
    );
  }
}
