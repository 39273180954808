import React, { useState } from 'react';
import _ from 'lodash';
import styles from './adNeonCreativeDetail.module.scss';
import { CreativeBasic } from 'core/creative/Creative';
import config from 'config';

const cloudStorageConfig = config.cloudStorageConfig;

export const RetailProductCreativeDetail: React.FunctionComponent<{creative: Partial<CreativeBasic>}> = (props) => {
  const [loading, setLoading] = useState(true);
  const adneonData = _.get(props.creative, 'bannerExtra.responseDTO');
  if (!adneonData) {
    return <div/>;
  }
  const adTag: any = _.get(adneonData, 'adTag', '');
  if (!adTag) {
    return <div/>;
  }
  const productSet: any = props.creative.productSet;
  if (!productSet) {
    return <div/>;
  }
  const productSetUrl = `https://storage.googleapis.com/${cloudStorageConfig.bucket}/${cloudStorageConfig.creative_container}/productSetSnapshot/${productSet.productSetId}.json?t=${Date.now()}`;
  const regularExpression = /"%%CLICK_URL_UNESC%%(.+?)"/g;
  const clickMacroMatch = adTag.matchAll(regularExpression);
  let htmlContent = adTag;
  for (const match of clickMacroMatch) {
    if (match[1]) {
      htmlContent = htmlContent.replace(match[1], decodeURIComponent(match[1]));
    }
  }

  htmlContent = htmlContent.replace(/%%CLICK_URL_UNESC%%/g, '')
                          .replace(/%%VAST_URL_UNESC%%/g, '')
                          .replace(/%%VIEWABLE_IMPRESSION_URL_UNESC%%/g, '')
                          .replace(/%%SSP_VIEWABLE_IMPRESSION_URL_UNESC%%/g, '')
                          .replace(/%%ADX_VIEWABLE_IMPRESSION_URL_UNESC%%/g, '')
                          .replace(/("key":"product-set-url","content":")(.*?)(",)/g, `$1${productSetUrl}$3`);

  const iframeSandboxSetting = [
    'allow-scripts', 'allow-popups', 'allow-modals', 'allow-orientation-lock',
    'allow-pointer-lock', 'allow-popups-to-escape-sandbox', 'allow-presentation',
    'allow-top-navigation', 'allow-top-navigation-by-user-activation'
  ];
  const width = _.get(adneonData, 'width', '300');
  const height = _.get(adneonData, 'height', '250');
  const onIframeLoaded = () => {
    setLoading(false);
  };
  return (
    <div className={styles.adNeonCreativeDetailContainer}>
      {loading && <div className={styles.loadingCircle} />}
      <iframe
        srcDoc={htmlContent}
        title={'adneon_preview'}
        frameBorder='0'
        sandbox={iframeSandboxSetting.join(' ')}
        width={width}
        height={height}
        marginWidth={0}
        marginHeight={0}
        onLoad={onIframeLoaded}
      />
    </div>
  );
};
