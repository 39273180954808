import { SelectOptions } from 'components/common/commonType';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { ConversionListModel, DefaultConversionListModel } from 'components/ConversionList/ConversionListModel';
import { UpdateEventListener, FireableUpdateEventListener } from 'utils/UpdateEventListener';
import { ConversionListDTO } from 'core/conversion/Conversion';
import { AgencyManager, DefaultAgencyManager } from 'core';

export interface ConversionHomeModel {
  readonly event: UpdateEventListener<ConversionHomeModel>;
  readonly advertiserId?: number;
  readonly advertisers: Array<SelectOptions>;
  readonly conversions: Array<ConversionListDTO>;
  readonly state: ConversionHomeState;
  init (): Promise<void>;
  getConversionListModel (): ConversionListModel;
  setAgencyId? (agencyId?: number): void;
  setAdvertiserId (advertiserId?: number): void;
  updateParams (advertisers: Array<SelectOptions>, conversions: Array<ConversionListDTO>): void;
}

export type ConversionHomeProps = {
  readonly model: ConversionHomeModel;
};

export type ConversionHomeState = {
  readonly advertiserId?: number;
  readonly agencyId?: number;
  readonly agencies?: Array<SelectOptions>;
  readonly loading: boolean;
};

export class DefaultConversionHomeModel implements ConversionHomeModel {

  advertiserId?: number;
  advertisers: Array<SelectOptions>;
  conversionListModel?: ConversionListModel;
  event: FireableUpdateEventListener<ConversionHomeModel>;
  conversions: Array<ConversionListDTO>;
  loading: boolean;

  constructor (advertisers: Array<SelectOptions>, conversions: Array<ConversionListDTO>) {
    this.advertisers = advertisers;
    this.advertiserId = SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER);
    this.event = new FireableUpdateEventListener<ConversionHomeModel>();
    this.conversions = conversions;
    this.loading = false;
  }

  get state (): ConversionHomeState {
    return {
      loading: this.loading,
      advertiserId: this.advertiserId
    };
  }

  async init () {
    // This is intentional
  }

  getConversionOfAdvertiser () {
    return this.conversions.filter(conversion =>
      this.advertiserId === undefined || conversion.advertiserId === this.advertiserId);
  }

  getConversionListModel (): ConversionListModel {
    if (this.conversionListModel) {
      return this.conversionListModel;
    }

    this.conversionListModel = new DefaultConversionListModel(this.getConversionOfAdvertiser());
    return this.conversionListModel;
  }

  setAdvertiserId (advertiserId: number) {
    this.advertiserId = advertiserId;
    this.conversionListModel = new DefaultConversionListModel(this.getConversionOfAdvertiser());
    this.updateState(false);
  }

  updateParams (advertisers: Array<SelectOptions>, conversions: Array<ConversionListDTO>): void {
    this.advertisers = advertisers;
    this.conversions = conversions;
    this.conversionListModel = new DefaultConversionListModel(this.getConversionOfAdvertiser());
    this.updateState(false);
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class SysAdminConversionHomeModel extends DefaultConversionHomeModel {

  agencyManager: AgencyManager;
  agencies: Array<SelectOptions>;
  onAgencyChange: (agencyId) => void;
  constructor (
    advertisers: Array<SelectOptions>,
    conversions: Array<ConversionListDTO>,
    onAgencyChange,
    agencyManager: AgencyManager = new DefaultAgencyManager()
  ) {
    super(advertisers, conversions);
    this.agencies = [];
    this.agencyManager = agencyManager;
    this.onAgencyChange = onAgencyChange;
    this.loading = true;
  }

  get state () {
    return {
      loading: this.loading,
      agencyId: SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY),
      advertiserId: this.advertiserId,
      agencies: this.agencies
    };
  }

  async init () {
    this.updateState(true);
    try {
      this.agencies = await this.agencyManager.getAgenciesOptions();
    } catch (e) {}
    this.updateState(false);
  }

  setAgencyId (agencyId: number) {
    this.advertiserId = undefined;
    SessionStorageHelper.removeItem(SessionStorageItemKeys.ADVERTISER);
    this.onAgencyChange(agencyId);
  }
}
