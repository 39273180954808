import { L1ObjectChannel } from 'core/l1Object/L1Object';

export enum SourceDetails {
  ORDERNAME = 'orderName',
  GROUPNAME = 'groupName',
  CHANNEL = 'channel',
  SELECED = 'selectedOfCampaigns'
}

export enum ConfirmationColumns {
  ORDER = 'order',
  L1OBJECT = 'l1Object',
  CHANNEL = 'channel',
  CREATIVE = 'creative',
  COPIES = 'copies',
  STATUS = 'status'
}

export type DraftCreateDTO = {
  includeBinding: boolean;
  channel: L1ObjectChannel;
  targetL1ObjectId: number;
  sourceL2ChannelId: number;
  numberOfCopies: number;
  draft: boolean;
};

export type DraftValidationDTO = {
  targetL1ObjectId: number;
  channelAccountId: number;
  deliveryStatus: string;
  bindings: Array<any>;
};

export type DraftPublishResponse = {
  includeBinding: boolean,
  result: string,
  reason: string
};
