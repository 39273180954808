import React, { useMemo } from 'react';
import { Slider } from 'components/common/Slider/Slider';
import { DefaultSliderModel } from 'components/common/Slider/SliderModel';
import _ from 'lodash';
import { NativePreview } from 'containers/Creatives/CreativeSetupFlow/FlowSteps/SubSteps/FormContent/NativePreview';
import styles from './nativeCreativeDetail.module.scss';
import { CreativeListRecord } from 'core/creative/Creative';
import { useOneForAllDisplayPreview } from 'hooks/GoCreativePreview/useOneForAllDisplayPreview';
import { renderFBPreviews } from 'components/AdPreview/adPreviewRenderer';

export const OneForAllDisplayCreativeDetail: React.FC<{ creative: CreativeListRecord }> = ({
  creative
}) => {

  const previewAreaWidth = 600;
  const previewAreaHeight = 680;
  const creativeValue = _.get(creative, 'creativeValue', _.get(creative, 'creativeValues'));
  const storyImgUrl = _.get(creative, 'bannerExtra.storyImgUrl');
  const medias = useMemo(() => ({
    squareImg: { url: _.get(creative, 'bannerExtra.imgUrl', '') },
    storyImg: storyImgUrl ? { url: storyImgUrl } : undefined
  }), [creative, storyImgUrl]);
  const typeProperties = useMemo(() => ({
    title: _.get(creative, 'bannerExtra.title'),
    description: _.get(creative, 'bannerExtra.description'),
    message: _.get(creative, 'bannerExtra.message'),
    linkUrl: _.get(creative, 'bannerExtra.fbLandingUrl'),
    pageId: _.get(creative, 'bannerExtra.pageId'),
    callToAction: _.get(creative, 'bannerExtra.callToAction')
  }), [creative]);
  const {
    previewData
  } = useOneForAllDisplayPreview(
    creative.advertiserId,
    typeProperties,
    medias
  );

  return (
    <div className={styles.nativeCreativeDetail}>
      <Slider model={new DefaultSliderModel(previewAreaWidth, previewAreaHeight, true, false, 0, false)}>
        {renderFBPreviews(previewData)}
        <NativePreview
          url={_.get(creativeValue, 'img.url')}
          bannerUrl={_.get(creative, 'bannerUrl')}
          creativeValues={{
            title: _.get(creativeValue, 'title'),
            desc: _.get(creativeValue, 'desc'),
            sponsor: _.get(creativeValue, 'sponsored.name'),
            sponsorLink: _.get(creativeValue, 'sponsored.link')
          }}
        />
        <NativePreview
          square={true}
          url={_.get(creativeValue, 'icon.url')}
          bannerUrl={_.get(creative, 'bannerUrl')}
          creativeValues={{
            title: _.get(creativeValue, 'title'),
            desc: _.get(creativeValue, 'desc'),
            sponsor: _.get(creativeValue, 'sponsored.name'),
            sponsorLink: _.get(creativeValue, 'sponsored.link')
          }}
        />
      </Slider>
    </div>
  );
};
