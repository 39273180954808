import React from 'react';
import { Spring, animated } from 'react-spring';
import styles from './creativePreview.module.scss';
import videoPlaceholder from 'assets/video-placeholder.svg';
import { ThirdPartyVideoPreview } from 'components/common/Video/ThirdPartyVideoPreview';
import _ from 'lodash';
import i18n from 'i18n';
import { CreativeListRecord, CreativeOfCampaign } from 'core/creative/Creative';

export class VideoCreativePreview extends React.Component<{creative: CreativeOfCampaign | CreativeListRecord}, any> {

  timer: any;

  constructor (props) {
    super(props);
    const creativeValue = _.get(props.creative, 'creativeValue', _.get(props.creative, 'creativeValues'));
    this.state = {
      index: 0,
      creativeValue
    };
  }

  componentDidUpdate (prevProps) {
    const prevCreativeValue = _.get(prevProps.creative, 'creativeValue', _.get(prevProps.creative, 'creativeValues'));
    const currentCreativeValue = _.get(this.props.creative, 'creativeValue', _.get(this.props.creative, 'creativeValues'));
    if (JSON.stringify(prevCreativeValue) !== JSON.stringify(currentCreativeValue)) {
      this.setState({
        index: 0,
        creativeValue: currentCreativeValue
      });
    }
  }

  mouseEnterHandler = () => {
    this.timer = setInterval(() => {
      this.setState({
        index: this.state.index >= 4 ? 0 : this.state.index + 1
      });
    }, 1000);
  }

  mouseLeaveHandler = () => {
    clearInterval(this.timer);
    this.setState({
      index: 0
    });
  }

  render () {
    const creativeValue = this.state.creativeValue;
    const isThirdParty = creativeValue.adServingUrl ? true : false;
    const sourceUrl = isThirdParty ? creativeValue.adServingUrl : this.props.creative.bannerImageUrl;
    const portion = creativeValue.h / creativeValue.w;
    if (!sourceUrl) {
      return (
        <div className={styles.videoPreviewPlaceholder}>
          <img src={videoPlaceholder} alt={'preview area'}/>
          <span>{i18n.t<string>('videoCreativePreivew.labels.generating')}</span>
        </div>
      );
    }
    if (isThirdParty) {
      return (
        <div className={styles.thirdPartyVideoCreativePreview}>
          <ThirdPartyVideoPreview url={sourceUrl} canClick={false} />
        </div>
      );
    }
    let width = 200;
    let height = portion * width;
    if (height > 140) {
      width = width * 140 / height;
      height = 140;
    }
    const imgOffsetPercent = 25;
    const imgCount = 5;
    return (
      <Spring
        from={{ x: 0 }}
        to={{ x: this.state.index * imgOffsetPercent }}
        immediate={true}
      >
        {({ x }) => (
          <animated.div
            className={styles.videoCreativePreview}
            onMouseEnter={this.mouseEnterHandler}
            onMouseLeave={this.mouseLeaveHandler}
            style={{
              width: width,
              height: height,
              backgroundImage: `url("${sourceUrl}")`,
              backgroundRepeat: 'no-repeat',
              backgroundPositionX: x.to(x => x + '%'),
              backgroundPositionY: 'center',
              backgroundSize: `${width * imgCount}px ${height}px`
            }}
          />
        )}
      </Spring>
    );
  }
}
