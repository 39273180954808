import React, { useCallback, useMemo, useState } from 'react';
import { FormikProps } from 'formik';
import { GoSegmentFormData } from 'core/goSegment/GoSegment';
import { DefaultSelectComponentModel } from 'components/CampaignLimitation/SelectComponent';
import { DEFAULT_INVENTORY } from 'containers/Limitations/LimitationSetting/LimitationSettingData';
import _ from 'lodash';
import { DefaultInventoryModel, Inventory } from 'containers/Limitations/Inventory';
import i18n from 'i18n';
import styles from './goSegmentForm.module.scss';
import { SelectOptions } from 'components/common/commonType';
import LimitationSet from 'components/CampaignLimitation/LimitationSet/LimitationSet';
import cx from 'classnames/bind';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { CustomField } from 'components/common/form/field/CustomField';

const classNames = cx.bind(styles);

export const GoSegmentSelectFields: React.FC<{
  formType: string,
  formikProps: FormikProps<GoSegmentFormData>,
  extraData: any,
  hasExtraInfo: boolean,
  setExtraInfo: (extraInfo: any) => void
}> = ({
  formType,
  formikProps,
  extraData
}) => {

  const [showInventory, setShowInventory] = useState(formType === 'create');
  const [currentInventory, setCurrentInventory] = useState('');

  const setUpAudienceBrief = useCallback((formikProps, limitationTypeObject) => {
    extraData.setLimitationTypeObject(extraData.limitationTypeObject);
    const values: any[] = Object.values(limitationTypeObject);
    let finalLogicValue;
    if (values.length === 0) {
      finalLogicValue = '';
    } else {
      let result = values.map(
        limitation => `${limitation.value.map(value => value.label).join(' OR ')}`
      ).join(' ) AND ( ');
      finalLogicValue = values.length > 1 ? `( ${result} )` : result;
    }
    formikProps.setFieldValue('audienceBrief', finalLogicValue);
  }, [extraData]);

  const onDeleteLimitationSet = (_1, type) => {
    delete extraData.limitationTypeObject[type];
    setUpAudienceBrief(formikProps, extraData.limitationTypeObject);
  };

  const onLimitationChange = (name: string, options: SelectOptions[]) => {
    extraData.limitationTypeObject[name] = { type: name, value: options.map(option => ({
      label: option.label,
      value: option.value
    })) };
    if (options.length === 0) {
      delete extraData.limitationTypeObject[name];
    }
    setUpAudienceBrief(formikProps, extraData.limitationTypeObject);
  };

  const defaultInventoryModel = useMemo(() => {
    const inventorySetting = _.find(extraData.inventorySettings, setting => setting.name === currentInventory);
    return new DefaultInventoryModel(
      inventorySetting ? currentInventory : DEFAULT_INVENTORY,
      extraData.inventorySettings.map(setting => ({
        name: setting.name,
        title: setting.title,
        cb: setting.cb
      })),
      {},
      'goSegmentForm.inventory.titile'
    );
  }, [currentInventory, extraData.inventorySettings]);

  const renderInventoryContent = () => {
    return extraData.inventorySettings.map(inventorySetting => {
      const InventoryContent: typeof React.Component =
        inventorySetting.component;
      const {
        name,
        singleSelect,
        itemSetting,
        searchPlaceholder
      } = inventorySetting;
      const value = _.get(extraData.limitationTypeObject[name], 'value', []);
      const model = new DefaultSelectComponentModel(
        name,
        singleSelect,
        itemSetting,
        i18n.t<string>(searchPlaceholder),
        _.partial(onLimitationChange, name),
        [],
        value,
        undefined,
        undefined,
        'goSegmentForm.inventory.contentPlaceholder',
        'goSegmentForm.inventory.segmentCount'
      );
      return <InventoryContent key={name} model={model} />;
    });
  };

  const addLimitationOfInventory = (inventory) => {
    setShowInventory(true);
    setCurrentInventory(inventory);
  };

  const renderSelectedLimitation = () => {
    const ignoreDefault = ({ name }) => name !== DEFAULT_INVENTORY;
    return extraData.inventorySettings.slice().filter(ignoreDefault).map(({ name, title }) => {
      const value: SelectOptions[] = _.get(extraData.limitationTypeObject[name], 'value', []);
      return (
        <LimitationSet
          key={title ? title + value : value}
          value={value}
          title={title}
          onChange={_.partial(onLimitationChange, name)}
          deleteLimitation={onDeleteLimitationSet}
          add={_.partial(addLimitationOfInventory, name)}
          type={name}
          disable={formType !== 'create'}
        />
      );
    });
  };

  const onEditBtnClick = () => {
    setShowInventory(prev => !prev);
  };

  const renderFinalLogicValue = () => {
    const audienceBrief = _.get(formikProps, 'values.audienceBrief', '');
    if (audienceBrief === '') {
      return <div/>;
    }
    return (
      <>
        {`${i18n.t<string>('goSegmentForm.hints.selectHint')} : `}
        <span>{audienceBrief}</span>
      </>
    );
  };

  const renderSegmentLimitation = () => {
    const error = _.get(formikProps.errors, 'audienceBrief');
    const inventoryClassName = classNames('inventory', {
      active: showInventory,
      error: error !== undefined
    });
    const selectedLimitationAreaClass = classNames('selectedLimitationArea', {
      short: showInventory
    });
    return (
      <>
        {formType === 'create' &&
          <div
            className={styles.editTitle}
            onClick={onEditBtnClick}
          >
            <span>
              {showInventory
                ? i18n.t<string>('limitation.labels.finishEditing')
                : i18n.t<string>('limitation.labels.edit')}
            </span>
          </div>
        }
        <div className={styles.segmentLimitation}>
          <div className={inventoryClassName}>
            <Inventory model={defaultInventoryModel} width={424}>
              {renderInventoryContent()}
            </Inventory>
            {error && <span className='errorTip'>{error}</span>}
          </div>
          <div className={selectedLimitationAreaClass}>
            <div className={styles.selectedLimitation}>{renderSelectedLimitation()}</div>
            <span className={styles.finalLogicValue}>{renderFinalLogicValue()}</span>
          </div>
        </div>
      </>
    );
  };

  if (extraData.inventorySettings.length === 0) {
    return <LoadingIndicator/>;
  }

  return (
    <CustomField
      label={i18n.t<string>('goSegmentForm.fields.select')}
      name='audienceBrief'
      render={renderSegmentLimitation}
      fieldContentWidth='auto'
    />
  );
};
