import { EditLimitationModel } from 'containers/Limitations/EditLimitationModel';
import { MessageCampaignBasicFormModel } from 'containers/MessageCampaigns/MessageCampaignSetupFlow/MessageCampaignForm/MessageCampaignBasicFormModel';
import _ from 'lodash';
import { MessageCampaignSetupFlowPageModel } from '../MessageCampaignSetupFlowPageModel';

export interface MessageCampaignSetupStepModel {
  readonly flowModel: MessageCampaignSetupFlowPageModel;
  readonly activeTab: number;
  validateCampaignBasic (campaign): any;
  setFormikProps (formikProps): void;
  getMessageCampaignBasicFormModel (adType): MessageCampaignBasicFormModel | undefined;
  getLimitationModel (priceModel): EditLimitationModel;
  onUnmount (): void;
  goNext: () => void;
  goLast: () => void;
  goSubStep: (subStepIndex: number) => void;
}

export type MessageCampaignSetupStepProps = {
  readonly model: MessageCampaignSetupStepModel;
};

export enum CampaignSetupTab {
  BASIC,
  LIMITATION
}

export class DefaultMessageCampaignSetupStepModel implements MessageCampaignSetupStepModel {

  flowModel: MessageCampaignSetupFlowPageModel;
  formikProps: any;

  constructor (
    flowModel: MessageCampaignSetupFlowPageModel,
    public activeTab: number,
    public goSubStep: (subStepIndex: number) => void,
    public goNext: () => void,
    public goLast: () => void,
    registerValidateMethod: (validateMethod) => void
  ) {
    this.flowModel = flowModel;
    registerValidateMethod(this.validate);
  }

  getMessageCampaignBasicFormModel (adType): MessageCampaignBasicFormModel | undefined {
    return this.flowModel.getMessageCampaignBasicFormModel(adType);
  }

  getLimitationModel (priceModel): EditLimitationModel {
    return this.flowModel.getLimitationModel(priceModel);
  }

  setFormikProps (formikProps) {
    this.formikProps = formikProps;
  }

  validate = async () => {
    if (!this.formikProps) {
      return [];
    }
    const campaignBasic = this.formikProps.values;
    const basicErrors = this.validateCampaignBasic(campaignBasic);
    this.setErrorsToFormik(basicErrors);
    const basicErrorNames = Object.keys(basicErrors);
    const limitationModel = this.flowModel.getLimitationModel(campaignBasic.priceModel);
    const limitationErrors = await limitationModel.validate();
    const audienceEstimatedError = await limitationModel.validateEstimatedData();
    const limitationErrorNames = Object.keys({
      ...limitationErrors,
      ...audienceEstimatedError
    });
    this.goErrorSubStep(basicErrorNames, limitationErrorNames);
    return _.concat(basicErrorNames, limitationErrorNames);
  }

  setErrorsToFormik (errors) {
    this.formikProps && this.formikProps.setErrors(errors);
    let touched = {};
    this.generateFormikTouchedObj(errors, touched);
    this.formikProps && this.formikProps.setTouched(touched);
  }

  validateCampaignBasic (campaignBasic) {
    const basicFormModel = this.flowModel.getMessageCampaignBasicFormModel(campaignBasic.adType);
    return basicFormModel ? basicFormModel.validate(campaignBasic, this.flowModel.order, this.flowModel.localeMeta) : {};
  }

  goErrorSubStep (basicErrorNames, limitationErrorNames) {
    const basicPageErrors = [...basicErrorNames];
    if (basicPageErrors.length > 0) {
      this.goSubStep(CampaignSetupTab.BASIC);
    } else if (limitationErrorNames.length > 0) {
      this.goSubStep(CampaignSetupTab.LIMITATION);
    }
  }

  generateFormikTouchedObj (errors, touched) {
    if (!errors) {
      return;
    }
    let keys = Object.keys(errors);
    keys.forEach(key => {
      let value = errors[key];
      if (typeof value === 'object') {
        touched[key] = {};
        this.generateFormikTouchedObj(errors[key], touched[key]);
      } else {
        touched[key] = true;
      }
    });
  }

  onUnmount () {
    if (!this.formikProps || !this.flowModel.state.campaign) {
      return;
    }
    const limitationModel = this.flowModel.getLimitationModel(this.formikProps.values.priceModel);
    this.flowModel.setCampaign({
      basic: { ...this.formikProps.values },
      limitations: limitationModel.limitationValue
    }, true);
  }
}
