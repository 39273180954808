import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { ObjectFitContainer } from '../ObjectFitContainer/ObjectFitContainer';
import styles from './previewItem.module.scss';
import { Modal } from 'react-bootstrap';

export const PreviewItem: React.FC<React.PropsWithChildren<{
  itemWidth: number,
  itemHeight: number,
  itemName: string
}>> = (props) => {
  const [showModal, setShowModal] = React.useState(false);
  const showItemDetail = () => {
    setShowModal(true);
  };
  const hideItemDetail = () => {
    setShowModal(false);
  };
  const modalContent = props.children ? React.cloneElement(props.children as React.ReactElement<any>, { ref: React.createRef() }) : undefined;
  return (
    <div className={styles.previewItem}>
      <div className={styles.previewArea}>
        <ObjectFitContainer
          containerWidth={props.itemWidth}
          containerHeight={props.itemHeight}
          onlyScaleDown={true}
        >
          {props.children}
        </ObjectFitContainer>
      </div>
      <div className={styles.itemInfo}>
        <span>{props.itemName}</span>
        <FontAwesomeIcon icon={faExpandArrowsAlt} onClick={showItemDetail}/>
      </div>
      {showModal &&
        <Modal
          className={styles.itemDetail}
          centered
          show
          onHide={hideItemDetail}
          animation={false}
        >
          <Modal.Body>
            {modalContent}
          </Modal.Body>
        </Modal>
      }
    </div>
  );
};
