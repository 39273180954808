import { SelectOptions } from 'components/common/commonType';
import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { GroupType, ProductGroup } from 'core/limitation/ProductGroup';
import { DefaultProductGroupManager } from 'core/limitation/ProductGroupManager';
import { useCallAPI } from 'hooks/useCallAPI';
import { useEffect, useState } from 'react';
import formatters from './listFormatters';
import styles from './productGroupList.module.scss';

export enum ProductGroupListColumns {
  ID = 'id',
  DESC = 'description',
  AGENCIES = 'agencys',
  EDITBTNS = 'editBtns'
}

export type ProductGroupListState = {
  readonly searchString: string;
  readonly filteredList: ProductGroup[];
  readonly productGroupList: ProductGroup[];
  readonly productGroupToDelete?: number;
  readonly groupType: GroupType;
  readonly campaignsToShow?: SelectOptions[]
};

const defaultProductGroupManager = new DefaultProductGroupManager();

export const useProductGroupListModel = (
  productGroupManager = defaultProductGroupManager
) => {

  const [state, setState] = useState({
    searchString: '',
    productGroupList: [],
    filteredList: [],
    productGroupToDelete: undefined,
    groupType: GroupType.ADSPACE,
    campaignsToShow: undefined
  } as ProductGroupListState);
  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    const filteredList = state.productGroupList.filter(productGroup => {
      return productGroup.groupName.toLowerCase().includes(state.searchString.toLowerCase()) && productGroup.groupType === state.groupType;
    });
    setState(prevState => ({ ...prevState, filteredList }));
  }, [state.searchString, state.groupType, state.productGroupList]);

  useEffect(() => {
    callAPIs([productGroupManager.getProductGroup.bind(productGroupManager)], productGroups => {
      setState(prevState => ({
        ...prevState,
        productGroupList: productGroups,
        filteredList: productGroups,
        loading: false
      }));
    });
  }, [productGroupManager, callAPIs]);

  const deleteProductGroup = async (productGroupId: number) => {
    setState(prevState => ({ ...prevState, loading: true }));
    try {
      await productGroupManager.deleteProductGroupById(productGroupId);
    } catch (e) {}
    setState(prevState => ({ ...prevState, loading: false, productGroupToDelete: undefined }));
  };

  const onDeleteBtnClick = (productGroupToDelete: number) => setState({ ...state, productGroupToDelete });

  const cancelDelete = () => setState({ ...state, productGroupToDelete: undefined });

  const onHandleSearch = (searchString: string) => setState({ ...state, searchString });

  const onSwitchType = (groupType) => {
    if (groupType === null) {
      return;
    }
    setState({ ...state, groupType });
  };

  const showCampaigns = async (productGroupId: number) => {
    setState(prevState => ({ ...prevState, loading: true }));
    try {
      const campaignsToShow = await productGroupManager.getCampaignByGroupId(productGroupId);
      setState(prevState => ({ ...prevState, loading: false, campaignsToShow }));
    } catch (e) {
      setState(prevState => ({ ...prevState, loading: false }));
    }
  };

  const closeCampaignsModal = () => setState({ ...state, campaignsToShow: undefined });

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `productGroupList.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const columns = [
    renderColumn(columnDefinition(ProductGroupListColumns.ID), formatters.nameFormatter),
    renderColumn(columnDefinition(ProductGroupListColumns.DESC)),
    renderColumn(columnDefinition(ProductGroupListColumns.AGENCIES), formatters.agenciesFormatter),
    renderColumn({
      ...columnDefinition(ProductGroupListColumns.EDITBTNS),
      text: '',
      sort: false,
      formatExtraData: {
        onDeleteBtnClick,
        showCampaigns
      }
    }, formatters.floatingEditBtnsFormatter)
  ];

  return {
    state,
    columns,
    loading,
    onSwitchType,
    onHandleSearch,
    deleteProductGroup,
    cancelDelete,
    closeCampaignsModal
  };
};
