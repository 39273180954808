import React from 'react';
import { Tabs, Tab, Button } from 'react-bootstrap';
import { Formik, Form } from 'formik';
import { Link, Redirect } from 'react-router-dom';

import style from './agencyForm.module.scss';
import {
  AgencyFormForAgencyModel,
  AgencyFormForAgencyProps,
  AgencyFormForAgencyState
} from '../AgencyForm/AgencyFormForAgencyModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { DefaultAdLogoFormModel } from 'components/AdLogoForm/AdLogoFormModel';
import AdLogoForm from 'components/AdLogoForm/AdLogoForm';
import i18n from 'i18n';
import { FormikField } from 'components/common/form/field/FormikField';
import { LabelField } from 'components/common/form/field/LabelField';
import _ from 'lodash';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled } from 'core/permission/PermissionDSL';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';

const adLogoValidTypes = ['image/jpeg', 'image/jpg', 'image/png'];
const adLogoHint = [
  i18n.t<string>('creativeSetupFlow.labels.typeHint'),
  i18n.t<string>('adLogoForm.labels.sizeHint')
];
export class AgencyFormForAgency extends React.Component<
  AgencyFormForAgencyProps,
  AgencyFormForAgencyState
> {
  model: AgencyFormForAgencyModel;
  handler: number;
  constructor (props: any) {
    super(props);
    this.model = this.props.model;
    this.state = this.model.state;
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handler = this.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    this.model.init();
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleSubmit (request: any) {
    this.model.save(request);
  }

  render () {
    const { cancelPath, title, defaultAgcData, validate } = this.model;
    const { isLoading, redirectUrl } = this.state;
    if (isLoading) {
      return <LoadingIndicator />;
    }

    if (redirectUrl) {
      return <Redirect to={redirectUrl} push />;
    }

    const enableAdLogo = defaultAgcData.addonFeatures.creatives.customizeAdLogo;
    const adLogoFormModel = enableAdLogo ? new DefaultAdLogoFormModel() : undefined;
    return (
      <div className={style.container}>
        <header>
          <TitleWithBreadcrumbs
            title={i18n.t<string>(title)}
            routes={this.props.model.breadcrumbs}
          />
        </header>
        <div className={style.innerContent}>
          <Formik
            initialValues={defaultAgcData}
            enableReinitialize
            onSubmit={this.handleSubmit}
            validate={validate}
          >
            <Form>
              <Tabs defaultActiveKey='basic' id='agency-detail'>
                <Tab eventKey='basic' title={i18n.t<string>('agencyDetail.labels.basicTab')}>
                  <div className={style.agencyFrom}>
                    <fieldset>
                      <legend>
                        <span>{i18n.t<string>('agency.information')}</span>
                      </legend>
                      <div className={style.fieldContent}>
                        <FormikField.FileInput
                          name='logoData'
                          label={i18n.t<string>('agency.logo')}
                          type='image'
                          validTypes={adLogoValidTypes}
                          hints={adLogoHint}
                          fitContent={false}
                          className={style.logoInput}
                        />
                      </div>
                    </fieldset>
                    <PermissionChecker permissionAware={addOnEnabled(ADDONFEATURE.COMPANY.AGENCY_PROFIT)}>
                      <fieldset>
                        <legend>
                          <span>{i18n.t<string>('agency.marginSetting')}</span>
                        </legend>
                        <div className={style.fieldContent}>
                          <FormikField.InputGroup
                            label={i18n.t<string>('agency.agcPercent')}
                            name='agcPercent'
                            type='number'
                            postfix='%'
                          />
                          <LabelField
                            label={i18n.t<string>('agency.addonProps.maxOrderProfit')}
                            value={`${_.get(defaultAgcData, 'addonProps.maxOrderProfit', 0)}%`}
                          />
                        </div>
                      </fieldset>
                    </PermissionChecker>
                  </div>
                </Tab>
                {adLogoFormModel &&
                  <Tab eventKey='adlogo' title={i18n.t<string>('agencyDetail.labels.adLogoTab')} >
                    <AdLogoForm model={adLogoFormModel} />
                  </Tab>
                }
              </Tabs>
              <div className={style.buttonArea}>
                <Button size='sm' type='submit'>
                  {i18n.t<string>('common.buttons.submit')}
                </Button>
                <Link to={cancelPath} className='btn btn-secondary btn-sm'>
                  {i18n.t<string>('common.buttons.cancel')}
                </Link>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    );
  }
}
