import React from 'react';
import isUrl from 'is-url';
import i18n from 'i18n';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'formik';
import InputModal from 'components/ThirdPartyTrackingCode/InputModal';
import ThirdPartyTrackingCodeCell from 'components/ThirdPartyTrackingCode/ThirdPartyTrackingCodeCell';
import styles from './ThirdPartyTrackingCode.module.scss';

export class ThirdPartyTrackingCode extends React.Component<any> {
  constructor (props) {
    super(props);

    this.handleUpdateCell = this.handleUpdateCell.bind(this);
    this.changetmpTrackingType = this.changetmpTrackingType.bind(this);
    this.changeTmpOriginTrackingCode = this.changeTmpOriginTrackingCode.bind(this);
    this.checkURL = this.checkURL.bind(this);
  }

  state = {
    show: false,
    tmpTrackingType: 'pixel',
    tmpOriginTrackingCode: '',
    previewTrackingCode: '',
    editIndex: -1,
    noUrlBlock: true
  };

  handleUpdateCell () {
    let viewTrackingCodes: any = [];
    if (this.state.noUrlBlock) {
      return;
    }

    if (this.props.formik.values.viewTrackingCodes) {
      viewTrackingCodes = this.props.formik.values.viewTrackingCodes;
    }
    let editIndex = this.state.editIndex;
    if (editIndex < 0) {
      viewTrackingCodes.push(
        {
          trackingType: this.state.tmpTrackingType,
          originTrackingCode: this.state.tmpOriginTrackingCode,
          trackingCode: this.state.previewTrackingCode
        }
      );
    } else {
      viewTrackingCodes[editIndex] = {
        trackingType: this.state.tmpTrackingType,
        originTrackingCode: this.state.tmpOriginTrackingCode,
        trackingCode: this.state.previewTrackingCode
      };
    }
    this.props.formik.setFieldValue('viewTrackingCodes', viewTrackingCodes);
    this.setState({
      show: false
    });
  }

  checkURL (code, event = this.state.tmpTrackingType) {
    let value = code;
    let parser = new DOMParser();
    let doc = parser.parseFromString(value, 'text/html');
    if (event === 'pixel' && doc.getElementsByTagName('img').length > 0) {
      if (isUrl(doc.getElementsByTagName('img')[0].getAttribute('src'))) {
        this.setState({
          previewTrackingCode: doc.getElementsByTagName('img')[0].getAttribute('src'),
          noUrlBlock: false
        });
      } else {
        this.setState({
          previewTrackingCode: i18n.t<string>('campaign.strategyAndTracking.noURL'),
          noUrlBlock: true
        });
      }
    } else if (isUrl(value.trim())) {
      if (event === 'js' && value.substr(-3) !== '.js') {
        this.setState({
          previewTrackingCode: i18n.t<string>('campaign.strategyAndTracking.noURL'),
          noUrlBlock: true
        });
        return ;
      }
      this.setState({
        previewTrackingCode: value,
        noUrlBlock: false
      });
    } else {
      this.setState({
        previewTrackingCode: i18n.t<string>('campaign.strategyAndTracking.noURL'),
        noUrlBlock: true
      });
    }
  }

  changetmpTrackingType (event) {
    this.setState({
      tmpTrackingType: event
    });
    this.checkURL(this.state.tmpOriginTrackingCode, event);
  }

  changeTmpOriginTrackingCode (event) {
    this.setState({
      tmpOriginTrackingCode: event.target.value
    });
    this.checkURL(event.target.value);
  }

  render () {
    const handleClose = () => {
      this.setState({ show: false, noUrlBlock: true });
    };
    const handleEdit = (
      index,
      value= {
        trackingType: 'pixel',
        originTrackingCode: '',
        trackingCode: ''
      }
    ) => {
      return () => this.setState({
        show: true,
        tmpTrackingType: value.trackingType,
        tmpOriginTrackingCode: value.originTrackingCode,
        previewTrackingCode: value.trackingCode,
        editIndex: index
      });
    };
    const handleDeleteCell = (viewTrackingCodes, index) => {
      return () => {
        viewTrackingCodes.splice(index, 1);
        this.props.formik.setFieldValue('viewTrackingCodes', viewTrackingCodes);
      };
    };

    const {
      show,
      tmpTrackingType,
      tmpOriginTrackingCode,
      previewTrackingCode
    } = this.state;
    let viewTrackingCodes = this.props.formik.values.viewTrackingCodes;
    return (
      <div>
        { viewTrackingCodes &&
          viewTrackingCodes.map((key, index) => {
            return (
              <div key={key.trackingCode} className={styles.row}>
                <ThirdPartyTrackingCodeCell
                  previewTrackingCode={key.trackingCode}
                  index={index}
                />
                <Button variant='secondary' size='sm' onClick={handleEdit(index, key)}>
                  <FontAwesomeIcon icon={faPencilAlt} />
                </Button>
                <Button variant='secondary' size='sm' onClick={handleDeleteCell(viewTrackingCodes, index)}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </Button>
              </div>
            );
          })
        }
        <Button variant='secondary' size='sm' onClick={handleEdit(-1)}>
          {i18n.t<string>('campaign.strategyAndTracking.newThirdPartyTracking')}
        </Button>
        {show && (
          <InputModal
            tmpTrackingType={tmpTrackingType}
            tmpOriginTrackingCode={tmpOriginTrackingCode}
            previewTrackingCode={previewTrackingCode}
            changetmpTrackingType={this.changetmpTrackingType}
            changeTmpOriginTrackingCode={this.changeTmpOriginTrackingCode}
            handleUpdateCell={this.handleUpdateCell}
            handleClose={handleClose}
            disabled={this.state.noUrlBlock}
          />
        )}
      </div>
    );
  }
}

export default connect(ThirdPartyTrackingCode);
