import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { ColumnDefinition, getLabelValueColumn, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { AdType, CampaignState } from 'core/rtbCampaign/RtbCampaign';
import formatters from './listFormatters';
import styles from './campaignDashboard.module.scss';
import { useCallAPI } from 'hooks/useCallAPI';
import { DefaultRtbCampaignManager, RtbCampaignManager } from 'core/rtbCampaign/RtbCampaignManager';
import { CampaignDashboardManager, DefaultCampaignDashboardManager } from 'core/campaignDashboard/CampaignDashboardManager';
import i18n from 'i18n';
import { useCoreContext } from 'contexts/coreContext';
import { defaultTo, floor, get, isNumber, values } from 'lodash';
import { formatPriceAny, formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { addOnEnabled } from 'core/permission/PermissionDSL';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import moment from 'moment';
import { CampaignDashboardRecord } from 'core/campaignDashboard/CampaignDashboard';
import { BidStrategy } from 'core/l2Object/L2Object';
import { divideValue } from 'components/ReportTable/ReportDataHelper';
import { numberWithCommas } from 'utils/StringUtil';

export enum CampaignDashboardColumns {
  ID = 'campaignId',
  AGENCY = 'agencyName',
  ADVERTISER = 'advertiserName',
  ORDER = 'orderName',
  PROGRESS = 'executeRate',
  CTR = 'ctr',
  OPTIMIZE = 'optimize',
  WEIGHT = 'magnificationRatio',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  EDITBTNS = 'editBtns'
}

export enum CampaignDashboardExcelColumns {
  ORDER_ID = 'orderId',
  CAMPAIGN_ID = 'campaignId',
  CAMPAIGN_NAME = 'campaignName',
  ADTYPE = 'adType',
  AGENCY = 'agencyName',
  BUDGET = 'budget',
  SPENT = 'spent',
  OPTIMIZE = 'optimize',
  BID_PRICE = 'bidPrice',
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  EDITBTNS = 'editBtns'
}

const defaultCampaignManager: RtbCampaignManager = new DefaultRtbCampaignManager();
const defaultCampaignDashboardManager: CampaignDashboardManager = new DefaultCampaignDashboardManager();

const initSummary = [{
  label: i18n.t<string>('campaignDashboard.labels.orderCount'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.orderTotalBudget'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.orderTotalSpent'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.campaignCount'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.impres'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.clicks'),
  value: 0
}, {
  label: i18n.t<string>('campaignDashboard.labels.ctr'),
  value: '0%'
}, {
  label: i18n.t<string>('campaignDashboard.labels.spent'),
  value: 0
}];

const getDailyBudgetCampaignProgressRate = ({ budget, spent, startDate, endDate }) => {
  const startTime = moment(startDate).startOf('day');
  const endTime = moment(endDate).startOf('day');
  const now = moment().startOf('day');
  const isStart = moment().isSameOrAfter(moment(startDate));
  const isEnd = moment().isAfter(moment(endDate));

  let predictRate;
  if (!isStart) {
    predictRate = 0;
  } else if (isEnd) {
    predictRate = 1;
  } else {
    predictRate = (now.diff(startTime, 'day') + 1) / (endTime.diff(startTime, 'day') + 1);
  }

  const canCalRate = budget !== 0;
  if (canCalRate) {
    return { executeRate: spent / budget, predictRate };
  }
  return { executeRate: 0, predictRate: 0 };
};

const getCampaignProgressRate = ({ budget, currencyRate, spents, olapExpectedSpent }) => {
  const budgetUsd = budget / currencyRate;
  const canCalRate = budgetUsd !== 0;
  if (canCalRate) {
    return {
      executeRate: spents / budget,
      predictRate: olapExpectedSpent / budgetUsd
    };
  }
  return {
    executeRate: 0,
    predictRate: 0
  };
};

const getCampaignData = (campaign) => {
  const isDailyBudgetCampaign = campaign.dailyTargetBudget > 0;
  const campaignProgress = isDailyBudgetCampaign
    ? getDailyBudgetCampaignProgressRate(campaign)
    : getCampaignProgressRate(campaign);
  return {
    ...campaign,
    [CampaignDashboardColumns.PROGRESS]: campaignProgress.executeRate,
    predictRate: campaignProgress.predictRate,
    ctr: divideValue(campaign.clicks * 100, campaign.impres),
    [CampaignDashboardColumns.OPTIMIZE]: campaign.optimize
  };
};

const addonColumnFilter = (
  columnToCheck: CampaignDashboardColumns,
  filterData: {
    column: CampaignDashboardColumns,
    addon: string
  },
  addonFeatures: any
) => {
  const campaignAddons = addonFeatures ? Object.keys(addonFeatures.campaign).filter(key => addonFeatures.campaign[key]) : [];
  const hasAddon = addOnEnabled(filterData.addon).visible({ agencyAddon: campaignAddons });
  return hasAddon ? true : columnToCheck !== filterData.column;
};

export const useCampaignDashboardModel = (
  agencyId: number | null | undefined,
  campaignManager: RtbCampaignManager = defaultCampaignManager,
  campaignDashboardManager: CampaignDashboardManager = defaultCampaignDashboardManager
) => {

  const campaignListFilters = useRef<{
    dayFilter: number,
    adTypeFilter?: AdType[],
    campaignStatusFilter?: number,
    searchString: string
  } | null>(null);
  const summaryInfoFilters = useRef<{
    adTypeFilter?: AdType[]
  } | null>(null);
  const [campaignList, setCampaignList] = useState<CampaignDashboardRecord[]>([]);
  const [filteredList, setFilteredList] = useState<CampaignDashboardRecord[]>([]);
  const {
    loading,
    callAPIs
  } = useCallAPI();
  const [campaignToChangeBidWeight, setCampaignToChangeBidWeight] = useState<number | string | undefined>();
  const [searchString, setSearchString] = useState('');
  const [todaySummary, setTodaySummary] = useState(initSummary);
  const [yesterdaySummary, setYesterdaySummary] = useState(initSummary);
  const [days, setDays] = useState<number>(30);
  const [timeOption, setTimeOption] = useState<number>(1);
  const [campaignStatus, setCampaignStatus] = useState<number>(CampaignState.ACTIVATE);
  const [adType, setAdType] = useState<AdType | ''>('');
  const core = useCoreContext();
  const currency = get(core, 'accountManager.localeMeta.currency', 'NTD');
  const addonFeatures: any = get(core, 'accountManager.localeMeta.addonFeatures');

  const adTypes = useMemo(() => adType === '' ? undefined : [adType], [adType]);

  const setUpFilteredList = useCallback((newCampaignList) => {
    const filteredList = newCampaignList.filter(campaign => {
      const nameInclude = campaign.campaignName.toLowerCase().includes(searchString.toLowerCase());
      const idInclude = campaign.campaignId.toString().includes(searchString);
      const agencyInclude = campaign.agencyName.includes(searchString);
      const advertiserInclude = campaign.advertiserName.includes(searchString);
      const orderInclude = campaign.orderName.includes(searchString);
      return nameInclude || idInclude || agencyInclude || advertiserInclude || orderInclude;
    });

    setFilteredList(filteredList);
  }, [searchString]);

  const fetchCampaignList = useCallback((dayFilter, adTypeFilter, campaignStatusFilter, callback?: () => void) => {
    callAPIs([() => campaignDashboardManager.getCampaignList(dayFilter, adTypeFilter, campaignStatusFilter)], (campaignList) => {
      const newCampaignList = campaignList.map(getCampaignData);
      setCampaignList(newCampaignList);
      setUpFilteredList(newCampaignList);
      callback && callback();
    });
  }, [campaignDashboardManager, setUpFilteredList, callAPIs]);

  const getSummaryInfo = useCallback((adTypeFilter) => {
    if (summaryInfoFilters.current && summaryInfoFilters.current.adTypeFilter === adTypeFilter) {
      return;
    }
    const columns = ['orderCount', 'orderTotalBudget', 'orderTotalSpent', 'campaignCount', 'impres', 'clicks', 'ctr', 'spent'];
    const formattersMap = {
      orderTotalSpent: value => formatPriceWithCurrency(currency, value),
      orderTotalBudget: value => formatPriceWithCurrency(currency, value),
      spent: value => `${currency} ${numberWithCommas(floor(value, 2))}`
    };
    const getSummaryViewData = summary => Object.keys(summary)
      .filter(key => key !== 'date')
      .sort((a, b) => columns.indexOf(a) - columns.indexOf(b))
      .map(key => ({
        label: i18n.t<string>(`campaignDashboard.labels.${key}`),
        value: formattersMap[key] ? formattersMap[key](summary[key]) : numberWithCommas(summary[key])
      }));

    callAPIs([() => campaignDashboardManager.getSummaryInfo(adTypeFilter)], (
      summary
    ) => {
      setYesterdaySummary(getSummaryViewData(summary[0]));
      setTodaySummary(getSummaryViewData(summary[1]));
      summaryInfoFilters.current = {
        adTypeFilter
      };
    });
  }, [campaignDashboardManager, currency, callAPIs]);

  useEffect(() => {
    if (!summaryInfoFilters.current) {
      getSummaryInfo(adTypes);
    }
  }, [adTypes, getSummaryInfo]);

  const onSearchButtonClick = useCallback(() => {
    const dayFilter = days * timeOption;
    const adTypeFilter = adTypes;
    const campaignStatusFilter = campaignStatus === -1 ? undefined : campaignStatus;
    if (
      campaignListFilters.current &&
      campaignListFilters.current.dayFilter === dayFilter &&
      campaignListFilters.current.adTypeFilter === adTypeFilter &&
      campaignListFilters.current.campaignStatusFilter === campaignStatusFilter
    ) {
      if (campaignListFilters.current.searchString !== searchString) {
        setUpFilteredList(campaignList);
        campaignListFilters.current = {
          ...campaignListFilters.current,
          searchString
        };
      }
      return;
    }

    getSummaryInfo(adTypeFilter);

    fetchCampaignList(
      dayFilter,
      adTypeFilter,
      campaignStatusFilter,
      () => {
        campaignListFilters.current = {
          dayFilter,
          adTypeFilter,
          campaignStatusFilter,
          searchString
        };
      }
    );
  }, [days, timeOption, adTypes, campaignStatus, searchString, campaignList, fetchCampaignList, setUpFilteredList, getSummaryInfo]);

  const columnDefinition = (columnName, sortable: boolean = true, customLabel?: string): ColumnDefinition => {
    const label = defaultTo(customLabel, `campaignDashboard.headers.${columnName}`);
    return {
      ...sortableColumn(columnName, label, sortable),
      classes: () => styles[columnName],
      headerClasses : () => styles[columnName]
    };
  };

  const updateCampaignBidWeight = async (bidWeight) => {
    if (!campaignToChangeBidWeight) {
      return;
    }
    const dayFilter = days * timeOption;
    const adTypeFilter = adTypes;
    const campaignStatusFilter = campaignStatus === -1 ? undefined : campaignStatus;
    callAPIs([() => campaignManager.updateCampaignBidWeight(campaignToChangeBidWeight, bidWeight)], () => {
      setCampaignToChangeBidWeight(undefined);
      fetchCampaignList(dayFilter, adTypeFilter, campaignStatusFilter);
    });
  };

  const adminColumns = [
    renderColumn(columnDefinition(CampaignDashboardColumns.ID), formatters.nameFormatter),
    renderColumn(columnDefinition(CampaignDashboardColumns.AGENCY)),
    renderColumn(columnDefinition(CampaignDashboardColumns.ADVERTISER)),
    renderColumn(columnDefinition(CampaignDashboardColumns.ORDER)),
    renderColumn(columnDefinition(CampaignDashboardColumns.PROGRESS), formatters.progressFormatter),
    renderColumn(columnDefinition(CampaignDashboardColumns.CTR), value => `${value.toFixed(2)}%`),
    getLabelValueColumn(columnDefinition(CampaignDashboardColumns.OPTIMIZE), (campaign => ([
      {
        label: i18n.t<string>('campaignDashboard.labels.optimization'),
        value: defaultCampaignManager.getLegacyOptimizeValue(campaign.optimize, campaign.bidPrice)
      },
      {
        label: i18n.t<string>('campaignDashboard.labels.bidStrategy'),
        value: i18n.t<string>(`l1Object.labels.bidStrategy.${(isNumber(campaign.bidPrice) ? BidStrategy.LOWEST_COST_WITH_BID_CAP : BidStrategy.LOWEST_COST_WITHOUT_CAP).toLowerCase()}`)
      },
      {
        label: i18n.t<string>('campaignDashboard.labels.bidCap'),
        value: campaign.bidPrice ?
          `${currency} ${formatPriceAny(+(campaign.bidPrice))}` :
          i18n.t<string>('common.labels.noData')
      }
    ]))),
    renderColumn(columnDefinition(CampaignDashboardColumns.WEIGHT)),
    renderColumn(columnDefinition(CampaignDashboardColumns.START_DATE)),
    renderColumn(columnDefinition(CampaignDashboardColumns.END_DATE)),
    renderColumn({
      ...columnDefinition(CampaignDashboardColumns.EDITBTNS),
      text: '',
      sort: false,
      formatExtraData: {
        showManageBidWeightModal: setCampaignToChangeBidWeight
      }
    }, formatters.floatingEditBtnsFormatter)
  ];

  const agencyColumns = adminColumns.filter(column => {
    return column.dataField !== CampaignDashboardColumns.AGENCY &&
      addonColumnFilter(
        column.dataField, {
          column: CampaignDashboardColumns.WEIGHT,
          addon: ADDONFEATURE.CAMPAIGN.BID_WEIGHT_SETTING
        }, addonFeatures
      );
  });

  const excelColumns = useMemo(() => [
    columnDefinition(CampaignDashboardExcelColumns.ORDER_ID),
    columnDefinition(CampaignDashboardExcelColumns.CAMPAIGN_ID, false, 'campaignDashboard.headers.campaignId_excel'),
    columnDefinition(CampaignDashboardExcelColumns.CAMPAIGN_NAME),
    columnDefinition(CampaignDashboardExcelColumns.AGENCY),
    columnDefinition(CampaignDashboardExcelColumns.START_DATE),
    columnDefinition(CampaignDashboardExcelColumns.END_DATE),
    columnDefinition(CampaignDashboardExcelColumns.BUDGET),
    columnDefinition(CampaignDashboardExcelColumns.SPENT),
    columnDefinition(CampaignDashboardExcelColumns.OPTIMIZE, false, 'campaignDashboard.headers.optimize_excel'),
    columnDefinition(CampaignDashboardExcelColumns.BID_PRICE),
    columnDefinition(CampaignDashboardExcelColumns.ADTYPE)
  ], []);

  const excelColumnDecorater = useMemo(() => ({
    [CampaignDashboardExcelColumns.BUDGET]: value => formatPriceWithCurrency(currency, value),
    [CampaignDashboardExcelColumns.SPENT]: value => formatPriceWithCurrency(currency, value),
    [CampaignDashboardExcelColumns.BID_PRICE]: value => formatPriceWithCurrency(currency, value),
    [CampaignDashboardExcelColumns.START_DATE]: value => moment(value).format('YYYY-MM-DD'),
    [CampaignDashboardExcelColumns.END_DATE]: value => moment(value).format('YYYY-MM-DD'),
    [CampaignDashboardExcelColumns.ADTYPE]: value => i18n.t<string>(`adType.${value.toLowerCase()}`)
  }), [currency]);

  const download = useCallback(async () => {
    const getHeader = (setting) => {
      return i18n.t<string>(setting.text);
    };
    const metricsMapI18n = excelColumns.reduce((acc, setting) => {
      acc[setting.dataField] = getHeader(setting);
      return acc;
    }, {});
    const textDecorators = excelColumns.reduce((acc, setting) => {
      acc[setting.dataField] = excelColumnDecorater[setting.dataField];
      return acc;
    }, {});
    const metrics: string[] = values(metricsMapI18n);
    const excelDataList = filteredList.reduce((excelData, rowData) => {
      const newRowData = {};
      Object.keys(rowData).filter(key => !!metricsMapI18n[key]).forEach(key => {
        const textDecorator = textDecorators[key];
        newRowData[metricsMapI18n[key]] = textDecorator ? textDecorator(rowData[key]) : rowData[key];
      });
      excelData.push(newRowData);
      return excelData;
    }, [] as any);
    const XLSXModule = await import(/* webpackChunkName: "xlsx" */ 'xlsx');
    const XLSX = XLSXModule.default;
    const wb = XLSX.utils.book_new();
    const ws = XLSX.utils.json_to_sheet(excelDataList, { header: metrics });
    XLSX.utils.book_append_sheet(wb, ws, 'report');
    XLSX.writeFile(wb, 'campaignDashboard.xlsx');
  }, [filteredList, excelColumns, excelColumnDecorater]);

  return {
    loading,
    days,
    adType,
    searched: campaignListFilters.current !== null,
    searchString,
    timeOption,
    todaySummary,
    yesterdaySummary,
    columns: agencyId ? agencyColumns : adminColumns,
    filteredList,
    campaignToChangeBidWeight,
    campaignStatus,
    download,
    setAdType,
    setCampaignStatus,
    setTimeOption,
    setSearchString,
    setDays,
    updateCampaignBidWeight,
    hideManageBidWeightModal: () => setCampaignToChangeBidWeight(undefined),
    onSearchButtonClick
  };
};
