import React, { RefObject } from 'react';
import { OrderFormState, OrderFormProps } from './OrderFormModel';
import { Form, Formik, FormikProps } from 'formik';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import i18n from 'i18next';
import styles from './orderForm.module.scss';
import { FinalReportGender, Order, OrderType } from 'core/order/Order';
import _ from 'lodash';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import NavigationPrompt from 'components/common/NavigationPrompt/NavigationPrompt';
import { FormikField } from 'components/common/form/field/FormikField';
import { Dayparts } from 'components/Dayparts/Dayparts';
import { TitleWithBreadcrumbs } from 'components/TitleArea/TitleWithBreadcrumbs';
import { GojekFinalReportSection } from './GojekFinalReportSection';
import { TenmaxFinalReportSection } from './TenmaxFinalReportSection';
import { RTBCAMPAIGN_DEFAULT_AGE_MAX, RTBCAMPAIGN_DEFAULT_AGE_MIN } from 'core/rtbCampaign/RtbCampaign';
import { ErrorHandler } from 'components/common/form/FormErrorHandler/FormErrorHandler';

const renderDayParts = (props) => <Dayparts {...props} />;
export class OrderForm extends React.PureComponent<RouteComponentProps<any> & OrderFormProps, OrderFormState> {

  handler: any;
  mainDomRef: RefObject<any> = React.createRef();

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  cancelSubmit = () => {
    this.props.history.goBack();
  }

  renderForm = (formProps: FormikProps<Order>) => {
    this.props.model.setFormikProps(formProps);
    const Component: typeof React.Component = this.props.model.contentComponent;
    const endDate = formProps.values.endDate;
    const orderType = formProps.values.orderType;
    return (
      <Form>
        <ErrorHandler
          parentRef={this.mainDomRef}
          isSubmitting={formProps.isSubmitting}
          submitCount={formProps.submitCount}
        />
        <>
          <fieldset>
            <legend>
              <span>{i18n.t<string>('orderForm.labels.basic')}</span>
            </legend>
            <div className={styles.fieldContent}>
              <Component {...this.props} formik={formProps}/>
            </div>
          </fieldset>
          {this.props.model.isOutdoorOrder &&
            <fieldset>
              <legend>
                <span>{i18n.t<string>('orderForm.labels.outdoor')}</span>
              </legend>
              <div className={styles.fieldContent}>
                <FormikField.Input
                  label={i18n.t<string>('orderForm.labels.orderPrice')}
                  name='orderPrice'
                  type='number'
                />
                <FormikField.Input
                  label={i18n.t<string>('orderForm.labels.creativeDuration')}
                  name='creativeDuration'
                  type='number'
                />
                <FormikField.Custom
                  label={i18n.t<string>('orderForm.labels.daypart')}
                  name='dayPart'
                  render={renderDayParts}
                />
              </div>
            </fieldset>
          }
          {orderType && this.props.model.showFinalReportSection &&
            <fieldset>
              <legend>
                <span>{i18n.t<string>('orderForm.titles.finalReport')}</span>
              </legend>
              <div className={styles.fieldContent}>
                {orderType === OrderType.GOJEK ?
                  <GojekFinalReportSection
                    orderEndDate={endDate}
                    finalReportProjectType={_.get(formProps.values, 'finalReportProjectType')}
                    finalReportProjectName={_.get(formProps.values, 'finalReportProjectName')}
                    isFinalReportProjectNameExist={this.props.model.isFinalReportProjectNameExist.bind(this.props.model)}
                  /> :
                  <TenmaxFinalReportSection
                    ageMin={_.get(formProps.values, 'ageMin', RTBCAMPAIGN_DEFAULT_AGE_MIN)}
                    ageMax={_.get(formProps.values, 'ageMax', RTBCAMPAIGN_DEFAULT_AGE_MAX)}
                    gender={_.get(formProps.values, 'gender', FinalReportGender.ALL)}
                    onFieldsChange={formProps.setValues}
                  />
                }
              </div>
            </fieldset>
          }
        </>
        <div className={styles.buttonArea}>
          <button
            type='submit'
            className='btn btn-primary btn-sm'
          >
            {i18n.t<string>('common.buttons.submit')}
          </button>
          <button
            type='button'
            className='btn btn-secondary btn-sm'
            onClick={this.cancelSubmit}
          >
            {i18n.t<string>('common.buttons.cancel')}
          </button>
        </div>
      </Form>
    );
  }

  render () {
    const model = this.props.model;
    const state = model.state;
    if (state.redirectPath) {
      return <Redirect to={state.redirectPath} />;
    }
    if (state.loading || !model.order) {
      return <LoadingIndicator />;
    }
    return (
      <div className={styles.orderFormContainer} ref={this.mainDomRef}>
        <NavigationPrompt />
        <div className={styles.titleArea}>
          <TitleWithBreadcrumbs
            title={model.title}
            routes={model.breadcrumbs}
          />
        </div>
        <div className={styles.titleBottomLine} />
        <Formik
          initialValues={model.order}
          enableReinitialize
          onSubmit={model.submit}
          validate={model.validate}
          validateOnBlur={false}
        >
          {this.renderForm}
        </Formik>
      </div>
    );
  }
}
