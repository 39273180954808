import _ from 'lodash';
import { AdType, CreativeDeliverType, RtbCampaignPlanType, VideoAdViewObjective, DeliverType, ALLOW_VIEW_CONVERT_AD_TYPE, CampaignState } from 'core/rtbCampaign/RtbCampaign';
import { getDayOfWeekLabelByValue } from 'components/Dayparts/Dayparts';
import { RtbCampaignSetupFlowPageModel } from '../RtbCampaignSetupFlowPageModel';
import { Currency } from 'core';
import { toast } from 'react-toastify';
import { FireableUpdateEventListener, UpdateEventListener } from 'utils/UpdateEventListener';
import { RtbCampaignManager, DefaultRtbCampaignManager, LegacyEstimateViewModelData } from 'core/rtbCampaign/RtbCampaignManager';
import { Order, OrderType } from 'core/order/Order';
import { CampaignSetupTab } from './RtbCampaignSetupStepModel';
import i18n from 'i18next';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { CreateRtbCampaignSetupFlowStep, EditRtbCampaignSetupFlowStep } from '../RtbCampaignSetupFlowStep';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { EstimatedAudience } from 'core/goSegment/GoSegment';
import { SelectOptions } from 'components/common/commonType';
import moment from 'moment';
import { DraftManager, RtbCampaignDraftManager } from 'core/draft/DraftManager';
import { BidStrategy } from 'core/l2Object/L2Object';

export type RtbCampaignSummaryStepState = {
  readonly loading: boolean;
  readonly showPublishBindingFailed: boolean;
  readonly showAddSaveTargetingModal: boolean;
  readonly legacyEstimateData?: LegacyEstimateViewModelData;
};

export interface RtbCampaignSummaryStepModel {
  readonly flowModel: RtbCampaignSetupFlowPageModel;
  readonly event: UpdateEventListener<RtbCampaignSummaryStepModel>;
  readonly state: RtbCampaignSummaryStepState;
  readonly targetingValue: any;
  readonly estimatedAudience?: EstimatedAudience;
  readonly audienceLowestThreshold: number;
  readonly appliedSavedTAInfo?: SelectOptions;
  readonly savedTargetingList: SelectOptions[];
  readonly order: Order;
  readonly l1Object?: L1Object;
  readonly noLegacyEstimateDes: string;
  readonly showLegacyEstimation: boolean;
  updateState (loading: boolean): void;
  getAdTypeSummaryData: () => any;
  getSetUpSummaryData: () => any;
  getLimitationsSummaryData: () => any;
  getTrackingSummaryData: () => any;
  getCreativeBindingSummaryData: () => any;
  submit: () => void;
  goLast: () => void;
  goStep: (stepIndex: number, subStepIndex: number) => void;
  getLegacyEstimateData: () => Promise<void>;
  onShowAddSaveTargetingModal: (showModal: boolean) => void;
  redirectToLastPage: () => void;
}

export type RtbCampaignSummaryStepProps = {
  readonly model: RtbCampaignSummaryStepModel;
};

export class DefaultRtbCampaignSummaryStepModel implements RtbCampaignSummaryStepModel {

  event: FireableUpdateEventListener<RtbCampaignSummaryStepModel>;
  loading: boolean;
  showPublishBindingFailed: boolean = false;
  showAddSaveTargetingModal: boolean = false;
  stepChangeListener?: (stepIndex, subStepName?: string) => void;
  legacyEstimateData?: LegacyEstimateViewModelData;

  constructor (
    public flowModel: RtbCampaignSetupFlowPageModel,
    public goLast: () => void,
    public goStep: (stepIndex: number, subStepIndex: number) => void,
    protected campaignManager: RtbCampaignManager = new DefaultRtbCampaignManager()
  ) {
    this.event = new FireableUpdateEventListener<RtbCampaignSummaryStepModel>();
    this.loading = false;
  }

  get state () {
    return {
      loading: this.loading,
      showPublishBindingFailed: this.showPublishBindingFailed,
      showAddSaveTargetingModal: this.showAddSaveTargetingModal,
      legacyEstimateData: this.legacyEstimateData
    };
  }

  get noLegacyEstimateDes () {
    // if (this.flowModel.state.campaign.basic.priceModel === RtbCampaignPlanType.RB) {
    //   return i18n.t<string>('campaignSummary.labels.rbNotEstimateDesc');
    // } else {
    //   return i18n.t<string>('campaignSummary.labels.limitationNotEstimateDesc');
    // }
    return i18n.t<string>('campaignSummary.labels.limitationNotEstimateDesc');
  }

  get showLegacyEstimation () {
    // TODO: adjust to Message channel
    return this.flowModel.order.orderType === OrderType.TENMAX && _.includes([
      L1ObjectChannel.RTB,
      // L1ObjectChannel.EDIMAX,
      L1ObjectChannel.RETAIL_MEDIA
    ], this.flowModel.l1Object.channel);
  }

  get estimatedAudience () {
    return this.flowModel.estimatedAudience;
  }

  get audienceLowestThreshold () {
    return this.flowModel.audienceLowestThreshold;
  }

  get targetingValue () {
    return this.flowModel.limitationModel ? this.flowModel.limitationModel.limitationValue : [];
  }

  get order () {
    return this.flowModel.order;
  }

  get l1Object () {
    return this.flowModel.l1Object;
  }

  get savedTargetingList () {
    return this.flowModel.savedTAList.map(savedTA => ({
      label: savedTA.name,
      value: savedTA.id
    }));
  }

  get appliedSavedTAInfo () {
    return this.flowModel.appliedSavedTAInfo;
  }

  getAdTypeSummaryData () {
    return {
      title: i18n.t<string>('campaignSummary.titles.adType'),
      data: {
        adType: {
          title: undefined,
          content: [
            {
              label: i18n.t<string>('campaign.labels.adType'),
              value: this.getAdTypeValue(this.flowModel.state.campaign.basic.adType)
            }
          ]
        }
      }
    };
  }

  get campaignSetupStepIndex () {
    const isCreate = this.flowModel.type === 'create';
    return isCreate ? CreateRtbCampaignSetupFlowStep.SETUP_CAMPAIGN : EditRtbCampaignSetupFlowStep.SETUP_CAMPAIGN;
  }

  getSetUpSummaryData () {
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const priceModelRelatedSummaryData = this.getPriceModelRelatedSummaryData(campaignSetUpData.priceModel);
    return {
      title: i18n.t<string>('campaignSummary.titles.basicSetting'),
      backStep: this.campaignSetupStepIndex,
      backSubStep: CampaignSetupTab.BASIC,
      data: _.omitBy({
        general: {
          title: i18n.t<string>('campaignSummary.titles.generalInformation'),
          content: _.compact(_.concat([
            {
              label: i18n.t<string>('campaignSummary.labels.orderName'),
              value: this.flowModel.order.projectName
            },
            campaignSetUpData.retailType ? {
              label: i18n.t<string>('campaignInfo.labels.retailType'),
              value: i18n.t<string>(`retailers.${campaignSetUpData.retailType}`)
            } : undefined,
            {
              label: i18n.t<string>('campaignSummary.labels.campaignName'),
              value: campaignSetUpData.name
            },
            {
              label: i18n.t<string>('campaignSummary.labels.schedule'),
              value: `${campaignSetUpData.startDate} ~ ${campaignSetUpData.endDate}`
            },
            {
              label: i18n.t<string>('campaignSummary.labels.pricingModel'),
              value: this.getCampaignPlanTypeValue(campaignSetUpData.priceModel)
            },
            {
              label: i18n.t<string>('campaignSummary.labels.tags'),
              value: this.getTags(campaignSetUpData.tags)
            },
            {
              label: i18n.t<string>('campaignSummary.labels.enableMonitor'),
              value: campaignSetUpData.enableMonitor ? i18n.t<string>('common.labels.yes') : i18n.t<string>('common.labels.no')
            }
          ], priceModelRelatedSummaryData))
        },
        optimizeSetting: {
          title: i18n.t<string>('adSetSetupFlow.mainStep.fieldset.optimizationTitle'),
          content: _.compact([
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal'),
              value: i18n.t<string>(`optimizationGoal.${campaignSetUpData.optimize.toLowerCase()}`)
            },
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.bidStrategy'),
              value: i18n.t<string>(`l1Object.labels.bidStrategy.${campaignSetUpData.bidStrategy.toLowerCase()}`)
            },
            campaignSetUpData.bidStrategy === BidStrategy.LOWEST_COST_WITH_BID_CAP ? {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.bidControl'),
              value: formatPriceWithCurrency(this.flowModel.order.currency, +(campaignSetUpData.bidPrice))
            } : undefined,
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent'),
              value: campaignSetUpData.priceModel === RtbCampaignPlanType.RS ?
                i18n.t<string>(`rtbCampaign.billingEvent.${campaignSetUpData.priceModel}.${campaignSetUpData.optimize.toLowerCase()}`) :
                i18n.t<string>(`rtbCampaign.billingEvent.${campaignSetUpData.priceModel}`)
            },
            {
              label: i18n.t<string>('campaignInfo.labels.creativeDeliverType'),
              value: this.getCreativeDeliverTypeValue(campaignSetUpData.creativeDeliverType)
            },
            campaignSetUpData.frequency && !_.isEmpty(campaignSetUpData.frequency) && {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.frequencyControl'),
              value: this.getSessionCappaingCountValue(+campaignSetUpData.frequency.maxFrequency, +campaignSetUpData.frequency.intervalDays)
            },
            campaignSetUpData.dayPart && {
              label: i18n.t<string>('campaignSummary.labels.dayPart'),
              value: this.getDayPartValue(campaignSetUpData.dayPart)
            }
          ])
        }
      }, _.isUndefined)
    };
  }

  getLimitationsSummaryData (): any {
    const limitations = this.flowModel.state.campaign.limitations;
    const summaryData = this.flowModel.getLimitationsSummaryData(limitations);
    return {
      title: i18n.t<string>('campaignSummary.titles.targetSetting'),
      backStep: this.campaignSetupStepIndex,
      backSubStep: CampaignSetupTab.LIMITATION,
      data: summaryData
    };
  }

  getTrackingSummaryData () {
    const needShowConversionTracking = this.flowModel.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CONVERSION_TRACKING.CONV_TRACKING_LIST);
    const needShowThirdPartyTracking = this.flowModel.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.THIRDPARTY_TRACKING);
    const needShowReTargetingTracking = this.flowModel.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CAMPAIGN.RETARGETING_TRACKER);
    if (
      !needShowConversionTracking &&
      !needShowThirdPartyTracking &&
      !needShowReTargetingTracking
    ) {
      return;
    }
    const campaignBasic = this.flowModel.state.campaign.basic;
    const videoProgressTrackingCode = _.get(campaignBasic, 'videoProgressTrackingCode.code');
    const videoProgressTrackingOffset = _.get(campaignBasic, 'videoProgressTrackingCode.offset');
    const adType = campaignBasic.adType || AdType.DISPLAY;
    const conversionTrackingContent = campaignBasic.conversionTracking && [{
      label: i18n.t<string>('campaignSummary.labels.conversionTracking'),
      value: campaignBasic.conversionTracking.label
    }];
    if (ALLOW_VIEW_CONVERT_AD_TYPE.includes(adType) && conversionTrackingContent) {
      conversionTrackingContent.push({
        label: i18n.t<string>('campaignSummary.labels.convTrackEvent'),
        value: i18n.t<string>(`campaignSummary.labels.convTrackEvent${_.startCase(campaignBasic.convTrackEvent)}`) || i18n.t<string>('campaignSummary.labels.convTrackEventClick')
      });
    }
    return {
      title: i18n.t<string>('campaignSummary.titles.trackingSetting'),
      backStep: this.campaignSetupStepIndex,
      backSubStep: CampaignSetupTab.TRACKING,
      data: {
        conversionTracking: needShowConversionTracking ? {
          title: i18n.t<string>('campaignSummary.titles.conversionTracking'),
          content: conversionTrackingContent
        } : undefined,
        viewTrackingCodes: needShowThirdPartyTracking ? {
          title: i18n.t<string>('campaignSummary.titles.trackingCodes'),
          content: campaignBasic.viewTrackingCodes && campaignBasic.viewTrackingCodes.length > 0 && [{
            label: i18n.t<string>('campaignSummary.labels.trackingCodes'),
            value: campaignBasic.viewTrackingCodes.map(code => {
              return code.trackingCode;
            }).join('\r\n')
          }]
        } : undefined,
        videoProgressTrackingCode: needShowReTargetingTracking ? {
          title: i18n.t<string>('campaignSummary.titles.videoProgressTrackingCode'),
          content: videoProgressTrackingCode && videoProgressTrackingOffset && [
            {
              label: i18n.t<string>('campaignSummary.labels.videoProgressTrackingCode'),
              value: videoProgressTrackingCode,
              showArea: true
            },
            {
              label: i18n.t<string>('campaignSummary.labels.videoProgressTrackingOffset'),
              value: `${videoProgressTrackingOffset} ${i18n.t<string>('campaign.labels.sec')}`
            }
          ]
        } : undefined
      }
    };
  }

  getPriceModelRelatedSummaryData (priceModel) {
    const currency = this.flowModel.order.currency;
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const needShowOrderPrice = priceModel !== RtbCampaignPlanType.RS && priceModel !== RtbCampaignPlanType.DCPM;
    const needShowBudget = !_.get(this.flowModel.l1Object, 'autoOptimise', false);
    const needShowDailyTargetBudget = true;
    const needShowVideoAdViewObjective = campaignSetUpData.videoAdViewObjective && (
      ((campaignSetUpData.adType === AdType.VIDEO || campaignSetUpData.adType === AdType.COMBO || campaignSetUpData.adType === AdType.UNKNOW) && priceModel === RtbCampaignPlanType.FCPV) ||
      campaignSetUpData.adType === AdType.THIRD_PARTY_BOTTOM
    );
    return _.compact([
      (needShowOrderPrice && campaignSetUpData.orderPrice) && {
        label: i18n.t<string>('campaignSummary.labels.orderPrice', { model: this.getCampaignPlanTypeValue(priceModel) }),
        value: `${currency} ${campaignSetUpData.orderPrice}`
      },
      (needShowBudget) && {
        label: i18n.t<string>('campaignSummary.labels.campaignBudget'),
        value: `${currency} ${campaignSetUpData.budget}`
      },
      (needShowDailyTargetBudget) && {
        label: i18n.t<string>('campaignSummary.labels.budgetDistribution'),
        value: this.getOptimizeBudgetValue(campaignSetUpData.dailyTargetBudget)
      },
      (needShowVideoAdViewObjective) && {
        label: i18n.t<string>('campaignSummary.labels.videoAdViewObjective'),
        value: this.getVideoAdViewObjective(campaignSetUpData.videoAdViewObjective)
      }
    ]);
  }

  getCreativeBindingSummaryData () {
    const campaignBasic = this.flowModel.state.campaign.basic;
    const bindings = campaignBasic.bindings;
    if (bindings && bindings.length > 0) {
      const now = moment();
      const isDeliverying = now.isAfter(moment(campaignBasic.startDate)) && now.isBefore(moment(campaignBasic.endDate));
      const isActive = campaignBasic.state === CampaignState.ACTIVATE;
      const hasActiveBinding = bindings.find(binding => binding.active) !== undefined;
      const showAlert = isDeliverying && isActive && hasActiveBinding;
      return {
        title: i18n.t<string>('campaignSetupFlow.summaryStep.draftBindingTitle'),
        data: {
          draftBinding: {
            content: _.compact([
              {
                label: i18n.t<string>('campaignSetupFlow.summaryStep.labels.draftBinding'),
                value: bindings.length,
                hintColor: 'red',
                hint: showAlert ? i18n.t<string>('campaignSetupFlow.summaryStep.message.willDeliverImmediately') : undefined
              }
            ])
          }
        }
      };
    }
    return;
  }

  getOptimizeBudgetValue (dailyTargetBudget) {
    const currency = this.flowModel ? this.flowModel.order.currency : Currency.NTD;
    if (dailyTargetBudget === null || dailyTargetBudget === undefined || dailyTargetBudget.toString() === '0') {
      return i18n.t<string>('campaignSummary.labels.budgetScheduleDistribution');
    } else {
      return i18n.t<string>('campaignSummary.labels.budgetDailyDistribution', { currency: currency, budget: dailyTargetBudget });
    }
  }

  getVideoAdViewObjective (videoAdViewObjective) {
    if (videoAdViewObjective.videoAdEvent === VideoAdViewObjective.METRIC) {
      return i18n.t<string>('campaignSummary.labels.videoAdViewObjectiveMetric', { metric: i18n.t<string>(`campaign.labels.${videoAdViewObjective.videoAdMetricEvent.toLowerCase()}`) });
    } else if (videoAdViewObjective.videoAdEvent === VideoAdViewObjective.PROGRESS) {
      return i18n.t<string>('campaignSummary.labels.videoAdViewObjectiveProgress', { offset: videoAdViewObjective.offset });
    } else {
      return i18n.t<string>('campaignSummary.labels.videoAdViewObjectiveDefault');
    }
  }

  getDayPartValue (dayPart) {
    let dayPartValue = _.omitBy(_.omit(dayPart, 'enabled'), _.isEmpty);
    return Object.keys(dayPartValue).map(day => {
      return `${getDayOfWeekLabelByValue(parseInt(day, 10))},${i18n.t<string>('daypart.labels.hourUnit')}: ${dayPartValue[day].join(', ')}`;
    }).join('\r\n');
  }

  getSessionCappaingCountValue (maxFrequency: number, intervalDays: number) {
    return i18n.t<string>('adSetSetupFlow.mainStep.labels.frequencyControl', {
      event: i18n.t<string>('adSetSetupFlow.mainStep.labels.impression'),
      eventPlural: +(maxFrequency) > 1 ? 's' : '',
      interval_days: intervalDays,
      max_frequency: maxFrequency,
      unit: i18n.t<string>('common.units.day'),
      unitPlural: +(intervalDays) > 1 ? 's' : ''
    });
  }

  getCampaignPlanTypeValue (campaignPlanType) {
    return i18n.t<string>(`campaign.labels.${campaignPlanType}`);
  }

  getAdTypeValue (adType) {
    switch (adType) {
      case AdType.DISPLAY:
        return i18n.t<string>('campaignSummary.labels.adTypeDisplay');
      case AdType.OUTDOOR:
        return i18n.t<string>('campaignSummary.labels.adTypeOutdoor');
      case AdType.VIDEO:
        return i18n.t<string>('campaignSummary.labels.adTypeVideo');
      case AdType.THIRD_PARTY:
        return i18n.t<string>('campaignSummary.labels.adTypeThirdParty');
      case AdType.THIRD_PARTY_BOTTOM:
        return i18n.t<string>('campaignSummary.labels.adTypeThirdPartyBottom');
      case AdType.THIRD_PARTY_RECTANGLE:
        return i18n.t<string>('campaignSummary.labels.adTypeThirdPartyRectangle');
      default:
        return adType;
    }
  }

  getDeliverTypeValue (deliverType) {
    switch (deliverType) {
      case DeliverType.ACCELERATED:
        return i18n.t<string>('campaignSummary.labels.accelerated');
      default:
        return i18n.t<string>('campaignSummary.labels.standard');
    }
  }

  getTags (tags) {
    if (!tags || tags.length === 0) {
      return i18n.t<string>('summary.titles.noData');
    }

    return tags.join(',');
  }

  getCreativeDeliverTypeValue (creativeDeliverType) {
    switch (creativeDeliverType) {
      case CreativeDeliverType.OPTIMIZE:
        return i18n.t<string>('campaignSummary.labels.creativeOptimizeDelivery');
      default:
        return i18n.t<string>('campaignSummary.labels.creativeUniformDelivery');
    }
  }

  async getLegacyEstimateData () {
    if (!this.showLegacyEstimation) {
      return;
    }
    const campaign = this.flowModel.state.campaign;
    this.updateState(true);
    try {
      this.legacyEstimateData = await this.campaignManager.getLegacyEstimateData(campaign.basic, campaign.limitations, this.order.campaignBidPrice);
    } catch (e) {}
    this.updateState(false);
  }

  submit = async () => {
    if (this.flowModel.type === 'edit') {
      await this.updateCampaign(this.flowModel.state.campaign, this.flowModel.order, this.flowModel.l1Object);
    } else if (this.flowModel.type === 'split') {
      await this.splitCampaign(this.flowModel.state.campaign, this.flowModel.order, this.flowModel.campaignId, this.flowModel.l1Object);
    } else {
      await this.createCampaign(this.flowModel.state.campaign, this.flowModel.order, this.flowModel.l1Object);
    }
  }

  splitCampaign = async (campaign: any, order: Order, campaignId, l1Object?: L1Object) => {
    this.updateState(true);
    try {
      const l1ObjectId = _.get(l1Object, 'l1ObjectId');
      await this.campaignManager.splitCampaign(campaign, campaignId, l1ObjectId);
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.redirectToLastPage();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  createCampaign = async (campaign: any, order: Order, l1Object?: L1Object) => {
    this.updateState(true);
    try {
      const l1ObjectId = _.get(l1Object, 'l1ObjectId');
      await this.campaignManager.createCampaign(campaign, l1ObjectId);
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.redirectToLastPage();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  updateCampaign = async (campaign: any, order: Order, l1Object?: L1Object) => {
    this.updateState(true);
    try {
      const l1ObjectId = _.get(l1Object, 'l1ObjectId');
      await this.campaignManager.updateCampaign(campaign, l1ObjectId);
      toast.success(i18n.t<string>('common.messages.succeeded'));
      this.redirectToLastPage();
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }

  redirectToLastPage = () => {
    const order = this.flowModel.order;
    const l1Object = this.flowModel.l1Object;
    const l1ObjectId = _.get(l1Object, 'l1ObjectId');
    const redirectPath = l1ObjectId ?
        `/orders/${order.orderNumber}/campaign-groups/${l1ObjectId}` :
        `/orders/${order.orderNumber}`;
    this.flowModel.setFinishedRedirectPath(redirectPath);
  }

  onShowAddSaveTargetingModal = (showModal: boolean) => {
    this.showAddSaveTargetingModal = showModal;
    this.updateState(false);
  }

  updateState (loading: boolean) {
    this.loading = loading;
    this.event.fireEvent(this);
  }
}

export class RtbCampaignDraftSummaryStepModel extends DefaultRtbCampaignSummaryStepModel {

  constructor (
    private draftId: string,
    flowModel: RtbCampaignSetupFlowPageModel,
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void,
    private draftManager: DraftManager = new RtbCampaignDraftManager()
  ) {
    super(flowModel, goLast, goStep);
  }

  submit = async () => {
    const campaign = this.flowModel.state.campaign;
    this.updateState(true);
    try {
      const {
        includeBinding,
        result
      } = await this.draftManager.publishDraft(this.draftId, this.campaignManager.prepareCreateCampaignPayload(campaign));
      if (result === 'SUCCESS') {
        if (includeBinding) {
          toast.success(i18n.t<string>('campaignSetupFlow.messages.publishL2L3Success'));
        } else {
          toast.success(i18n.t<string>('campaignSetupFlow.messages.publishL2Success'));
        }
        this.redirectToLastPage();
      }
      if (result === 'PARTIAL_SUCCESS') {
        this.showPublishBindingFailed = true;
        this.updateState(false);
      } else if (result === 'FAIL') {
        toast.error(i18n.t<string>('campaignSetupFlow.messages.publishL2L3Failed'));
        this.updateState(false);
      }
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }
}

export class MessageRtbCampaignSummaryStepModel extends DefaultRtbCampaignSummaryStepModel {

  getSetUpSummaryData () {
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const priceModelRelatedSummaryData = this.getPriceModelRelatedSummaryData(campaignSetUpData.priceModel);
    return {
      title: i18n.t<string>('campaignSummary.titles.basicSetting'),
      backStep: this.campaignSetupStepIndex,
      backSubStep: CampaignSetupTab.BASIC,
      data: _.omitBy({
        general: {
          title: i18n.t<string>('campaignSummary.titles.generalInformation'),
          content: _.compact(_.concat([
            {
              label: i18n.t<string>('campaignSummary.labels.orderName'),
              value: this.flowModel.order.projectName
            },
            {
              label: i18n.t<string>('campaignSummary.labels.campaignName'),
              value: campaignSetUpData.name
            },
            {
              label: i18n.t<string>('campaignSummary.labels.deliverDate'),
              value: campaignSetUpData.startDate
            },
            {
              label: i18n.t<string>('campaignSummary.labels.messages'),
              value: campaignSetUpData.messages
            },
            {
              label: i18n.t<string>('campaignSummary.labels.pricingModel'),
              value: this.getCampaignPlanTypeValue(campaignSetUpData.priceModel)
            },
            {
              label: i18n.t<string>('campaignSummary.labels.tags'),
              value: this.getTags(campaignSetUpData.tags)
            }
          ], priceModelRelatedSummaryData))
        },
        optimizeSetting: {
          title: i18n.t<string>('adSetSetupFlow.mainStep.fieldset.optimizationTitle'),
          content: _.compact([
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal'),
              value: i18n.t<string>(`optimizationGoal.${campaignSetUpData.optimize.toLowerCase()}`)
            },
            {
              label: i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent'),
              value: i18n.t<string>(`rtbCampaign.billingEvent.${campaignSetUpData.priceModel}`)
            }
          ])
        }
      }, _.isUndefined)
    };
  }

  getPriceModelRelatedSummaryData (priceModel) {
    const currency = this.flowModel.order.currency;
    const campaignSetUpData = this.flowModel.state.campaign.basic;
    const needShowOrderPrice = priceModel !== RtbCampaignPlanType.RS && priceModel !== RtbCampaignPlanType.DCPM;
    const needShowBudget = !_.get(this.flowModel.l1Object, 'autoOptimise', false);
    return _.compact([
      (needShowOrderPrice && campaignSetUpData.orderPrice) && {
        label: i18n.t<string>('campaignSummary.labels.orderPrice', { model: this.getCampaignPlanTypeValue(priceModel) }),
        value: `${currency} ${campaignSetUpData.orderPrice}`
      },
      (needShowBudget) && {
        label: i18n.t<string>('campaignSummary.labels.campaignBudget'),
        value: `${currency} ${campaignSetUpData.budget}`
      }
    ]);
  }
}

export class MessageRtbCampaignDraftSummaryStepModel extends MessageRtbCampaignSummaryStepModel {

  constructor (
    private draftId: string,
    flowModel: RtbCampaignSetupFlowPageModel,
    goLast: () => void,
    goStep: (stepIndex: number, subStepIndex: number) => void,
    private draftManager: DraftManager = new RtbCampaignDraftManager()
  ) {
    super(flowModel, goLast, goStep);
  }

  submit = async () => {
    const campaign = this.flowModel.state.campaign;
    this.updateState(true);
    try {
      const {
        includeBinding,
        result
      } = await this.draftManager.publishDraft(this.draftId, this.campaignManager.prepareCreateCampaignPayload(campaign));
      if (result === 'SUCCESS') {
        if (includeBinding) {
          toast.success(i18n.t<string>('campaignSetupFlow.messages.publishL2L3Success'));
        } else {
          toast.success(i18n.t<string>('campaignSetupFlow.messages.publishL2Success'));
        }
        this.redirectToLastPage();
      }
      if (result === 'PARTIAL_SUCCESS') {
        this.showPublishBindingFailed = true;
        this.updateState(false);
      } else if (result === 'FAIL') {
        toast.error(i18n.t<string>('campaignSetupFlow.messages.publishL2L3Failed'));
        this.updateState(false);
      }
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
      this.updateState(false);
    }
  }
}
