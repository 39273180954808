import { useCallback, useEffect, useMemo, useState } from 'react';
import { formatPriceWithCurrency } from 'helper/CurrencyHelper';
import { ColumnDefinition, renderColumn, sortableColumn } from 'components/TableColumn/TableColumn';
import { useCallAPI } from 'hooks/useCallAPI';
import styles from './storedValueDetail.module.scss';
import { SelectOptions } from 'components/common/commonType';
import { DefaultStoredValueManager, StoredValueManager } from 'core/storedValue/StoredValueManager';
import i18n from 'i18n';
import { useLocation, useParams } from 'react-router-dom';
import { StoredValueLog } from 'core/storedValue/StoredValueLog';
import { priceFormatter } from './PriceFormatter';
import { Agency, DefaultAgencyManager } from 'core';
import { defaultTo, get } from 'lodash';

export enum StoredValueLogColumns {
  DATE = 'createTime',
  DESCRIPTION = 'description',
  COMMENT = 'comment',
  AMOUNT = 'amount',
  REMAIN = 'remain'
}

const defaultAgencyManager = new DefaultAgencyManager();
const defaultStoredValueManager = new DefaultStoredValueManager();

export enum StoredValueEditType {
  NONE = 'none',
  ADD = 'add',
  EDIT = 'edit'
}

export const useStoredValueDetailModel = (
  storedValueManager: StoredValueManager = defaultStoredValueManager,
  agencyManager = defaultAgencyManager
) => {

  const params = useParams<{ agencyId: string }>();
  const agencyId: number | undefined = params.agencyId ? +params.agencyId : undefined;

  if (!agencyId) {
    throw new Error('Agency ID is required');
  }

  const { loading, callAPIs } = useCallAPI();
  const location = useLocation<{
    logs: StoredValueLog[],
    agency: {
      name: string;
      currency: string;
    }
  }>();
  const defaultLogs = get(location.state, 'logs');
  const defaultAgency = get(location.state, 'agency');
  const [storedValueLog, setStoredValueLog] = useState<StoredValueLog[]>(defaultTo(defaultLogs, []));
  const [storedValueInfo, setStoredValueInfo] = useState<SelectOptions[]>([]);
  const [usedValueInfo, setUsedValueInfo] = useState<SelectOptions[]>([]);
  const [remainingValue, setRemainingValue] = useState<number>(0);
  const [agencyInfo, setAgencyInfo] = useState<{
    name: string;
    currency: string;
  }>(defaultTo(defaultAgency, {
    name: '',
    currency: ''
  }));

  const [editType, setEditType] = useState<StoredValueEditType>(StoredValueEditType.NONE);

  const initInfo = useCallback((storedValueLog: StoredValueLog[], agencyInfo: {
    name: string;
    currency: string;
  }) => {
    const storedValueSummary = storedValueManager.getStoredValueSummary(storedValueLog);
    const summaryOfAgency = defaultTo(storedValueSummary.find(item => item.adAgencyId === agencyId), {
      currency: agencyInfo.currency,
      remain: 0,
      stored: 0,
      used: 0
    });

    setStoredValueInfo([{
      label: i18n.t<string>('storedValueDetail.labels.remainValue'),
      value: formatPriceWithCurrency(summaryOfAgency.currency, summaryOfAgency.remain)
    }, {
      label: i18n.t<string>('storedValueDetail.labels.totalValue'),
      value: formatPriceWithCurrency(summaryOfAgency.currency, summaryOfAgency.stored)
    }]);
    setUsedValueInfo([{
      label: i18n.t<string>('storedValueDetail.labels.usedValue'),
      value: formatPriceWithCurrency(summaryOfAgency.currency, summaryOfAgency.used)
    }]);
    setRemainingValue(summaryOfAgency.remain);

  }, [storedValueManager, agencyId]);

  const initData = useCallback(async () => {
    callAPIs([
      () => storedValueManager.getStoredValueLogsByAgencyId(agencyId),
      () => agencyManager.fetchAgency(agencyId)
    ], (storedValueLogs, agency: Agency) => {
      const { detail } = agency;
      const agencyInfo = {
        name: detail.companyName,
        currency: detail.currency
      };
      setAgencyInfo(agencyInfo);
      setStoredValueLog(storedValueLogs);
      initInfo(storedValueLogs, agencyInfo);
    });
  }, [storedValueManager, agencyManager, agencyId, callAPIs, initInfo]);

  useEffect(() => {
    // clear location state
    window.history.replaceState({}, '');
    const logs = defaultTo(defaultLogs, []);
    if (defaultAgency) {
      initInfo(logs, defaultAgency);
      return;
    }
    initData();
  }, [initInfo, initData, defaultLogs, defaultAgency]);

  const columnDefinition = (columnName): ColumnDefinition => ({
    ...sortableColumn(columnName, `storedValueLog.headers.${columnName}`, true),
    classes: () => styles[columnName],
    headerClasses: () => styles[columnName]
  });

  const columns = useMemo(() => [
    renderColumn(columnDefinition(StoredValueLogColumns.DATE)),
    renderColumn(columnDefinition(StoredValueLogColumns.DESCRIPTION)),
    renderColumn(columnDefinition(StoredValueLogColumns.COMMENT)),
    renderColumn(columnDefinition(StoredValueLogColumns.AMOUNT), priceFormatter),
    renderColumn(columnDefinition(StoredValueLogColumns.REMAIN), priceFormatter)
  ], []);

  const onEditValueModalClose = (refresh: boolean) => {
    setEditType(StoredValueEditType.NONE);
    if (refresh) {
      initData();
    }
  };

  return {
    agencyId,
    loading,
    columns,
    editType,
    storedValueLog,
    storedValueInfo,
    usedValueInfo,
    remainingValue,
    agencyInfo,
    onEditValueModalClose,
    setEditType
  };
};
