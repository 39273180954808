import React from 'react';
import { ProductFilter } from './ProductFilter';
import { Product } from 'core/product/Product';
import { ProductListColumns, useProductListModel } from './ProductListModel';
import { ProductSetCreateModal } from 'components/ProductSetCreateModal/ProductSetCreateModal';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import i18n from 'i18n';
import _ from 'lodash';
import styles from './productList.module.scss';
import { ListEditBar } from 'components/ListEditBar/ListEditBar';

export type ProductListProps = {
  filterSet: ProductFilter;
  searchString: string;
  productList: Product[];
  editActions?: {
    label: string,
    onClick: (selectedProducts: (number | string)[], cb?: () => void) => void | Promise<void>
  }[];
  showProductSetCreateModal?: boolean;
  handleCloseModal?: () => void;
};

export const ProductList: React.FC<ProductListProps> = ({
  filterSet,
  searchString,
  productList,
  editActions,
  showProductSetCreateModal,
  handleCloseModal
}) => {

  const {
    state,
    columns,
    selectedProducts,
    // onProductListTypeChange,
    handleRemoveSelect
  } = useProductListModel(filterSet, searchString, productList);

  const retailId = _.get(filterSet, 'retailerId', '');
  const selectedProductsAsOptions = _.map(selectedProducts, productId => {
    const validProduct = _.find(state.filteredList, product => product.productId.toString() === productId);

    return {
      label: _.get(validProduct, 'name', ''),
      value: productId
    };
  });

  const onClose = () => {
    handleCloseModal && handleCloseModal();
    handleRemoveSelect();
    console.log('close modal');
  };

  const renderEditBar = () => {
    const renderItems = () => {
      if (!editActions) {
        return;
      }

      return editActions.map(editAction => {
        const onClick = event => {
          event && event.stopPropagation();
          editAction.onClick(selectedProducts, handleRemoveSelect);
        };
        return (
          <ListEditBar.Item key={editAction.label}>
            <ListEditBar.Link onClick={onClick}>
              {i18n.t<string>(editAction.label)}
            </ListEditBar.Link>
          </ListEditBar.Item>
        );
      });
    };
    return (
      <ListEditBar selectedItems={selectedProducts} handleRemoveSelect={handleRemoveSelect}>
        {renderItems()}
      </ListEditBar>
    );
  };

  return (
    <div className={styles.productListArea}>
      {showProductSetCreateModal &&
        <ProductSetCreateModal
          retailId={retailId}
          selectedProducts={selectedProductsAsOptions}
          onClose={onClose}
        />
      }
      {selectedProducts.length > 0 &&
        <div className={styles.productListTitleBar}>
          {renderEditBar()}
        </div>
      }
      <div className={styles.contentArea}>
        <StickableBootstrapTable
          key={state.selectedProductListType}
          stickFirstColumn
          stickLastColumn
          keyField={ProductListColumns.ID}
          data={state.filteredList}
          columns={columns}
          noDataIndication={i18n.t<string>('productList.labels.noData')}
          defaultSorted={[{
            dataField: ProductListColumns.ID,
            order: 'desc'
          }]}
        />
      </div>
    </div>
  );
};
