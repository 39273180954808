import React from 'react';
import ReactDOM from 'react-dom/client';

import './index.css';
import './bootstrap.scss';

import 'i18n';
import App from './App';
import { StylePage } from './pages/StylePage';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

const routing = (
  <BrowserRouter>
    <Switch>
      <Route path='/styles' component={StylePage} />
      <Route path='/' component={App} />
    </Switch>
  </BrowserRouter>
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(routing);
