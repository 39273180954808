import React, { useEffect, useState } from 'react';
import i18n from 'i18next';
import { connect, FormikContextType } from 'formik';
import { ComboFormProps } from './ComboFormModel';
import { FormConfig } from 'components/common/form/FormConfig';
import { FormContent } from 'components/common/form/Form';
import styles from './comboForm.module.scss';

export const ComboForm: React.FunctionComponent<ComboFormProps & { formik: FormikContextType<any> }> = (props) => {

  const [formConfig, setFormConfig] = useState<FormConfig>(props.getInitFormConfig());

  useEffect(() => {
    setFormConfig(new FormConfig.Builder()
      .addSection(
        new FormConfig.SectionBuilder(
          new FormConfig.FieldsBuilder([...props.basicFields])
            .addFormikFileInput({
              type: 'image',
              label: i18n.t<string>('creativeSetupFlow.labels.image'),
              name: 'medias.image',
              maxWidth: 300,
              className: styles.comboImage,
              validTypes: ['image/jpeg', 'image/jpg', 'image/png'],
              hints: [
                i18n.t<string>('creativeSetupFlow.labels.comboImagetypeHint'),
                i18n.t<string>('creativeSetupFlow.labels.imageSizeHint', { size: '600 x 500' }),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '2MB' })
              ],
              validate: props.model.validateImage
            })
            .addFormikFileInput({
              type: 'video',
              label: i18n.t<string>('videoForm.labels.tenMaxVideoInput'),
              name: 'medias.video',
              maxWidth: 300,
              maxHeight: 500,
              hints: [
                i18n.t<string>('creativeSetupFlow.labels.videoTypeHint'),
                i18n.t<string>('comboForm.labels.videoHint', { sec: 120, height: 720 }),
                i18n.t<string>('creativeSetupFlow.labels.storageHint', { storage: '10MB' })
              ],
              validate: props.model.validateVideo
            })
            .build()
        )
        .withTitle(i18n.t<string>('creativeSetupFlow.labels.creativeBasicInfo'))
        .build()
      ).build()
    );
  }, [
    props.basicFields,
    props.model.validateImage,
    props.model.validateVideo
  ]);

  return (
    <FormContent
      formConfig={formConfig}
    />
  );
};

export default connect(ComboForm);
