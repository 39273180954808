import React, { Fragment } from 'react';
import moment from 'moment';
import { CreativePreview } from 'components/CreativePreview/CreativePreview';
import { DefaultCreativePreviewModel } from 'components/CreativePreview/CreativePreviewModel';
import { faPencilAlt, faEye, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';
import styles from './creativeList.module.scss';
import i18n from 'i18next';
import _ from 'lodash';
import { CreativeListModel } from 'containers/CreativeList/CreativeListModel';
import { Link } from 'react-router-dom';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { Status, getEffectiveStatusDefaultColor } from 'components/Status/Status';
import { CreativeReviews } from 'components/CreativeReview/CreativeReviews';
import { LimitationTableCell } from 'components/LimitationTableCell/LimitationTableCell';
import { OutdoorType } from 'core/creative/Creative';

export const getCreativeListFormatters = (model: CreativeListModel) => {

  const creativeWritePermission = hasFuncs(Permission.CREATIVE_WRITE);

  const nameIdFormatter = (nameIdData: any, creativeData: any) => {
    if (creativeData.id === 0) {
      return <div>{i18n.t<string>('creativeList.labels.nameIdSummary', { count: nameIdData })}</div>;
    }
    const handleOnSelect = (e) => {
      model.handleOnSelect(nameIdData.id);
    };
    const renderLabel = () => <span className={styles.creativeName}>{nameIdData.name}</span>;
    return (
      <div className={styles.creativeCell}>
        <div className={styles.selectCheckBox}>
          <PermissionChecker permissionAware={creativeWritePermission}>
            <input
              type='checkbox'
              checked={creativeData.selected}
              onChange={handleOnSelect}
              id={`creativeListInput${nameIdData.id}`}
            />
            <label htmlFor={`creativeListInput${nameIdData.id}`} />
          </PermissionChecker>
        </div>
        <div className={styles.info}>
          <PermissionChecker
            permissionAware={creativeWritePermission}
            renderOtherwise={renderLabel}
          >
            <Link
              to={`/creatives/${nameIdData.id}/edit`}
              className={styles.creativeName}
            >
              {nameIdData.name}
            </Link>
          </PermissionChecker>
          <div className={styles.creativeID}>
            {`ID: ${nameIdData.id}`}
          </div>
        </div>
      </div>
    );
  };

  const nameIdHeaderFormatter = (nameIdHeader: any, columnIndex: number, { sortElement }) => {
    return (
      <div className={styles.nameIdHeader}>
        <div className={styles.selectCheckBox}>
          <PermissionChecker permissionAware={creativeWritePermission}>
            <input
              type='checkbox'
              checked={model.state.viewModelData.length === model.state.selectedCreatives.length}
              ref={el => el && (el.indeterminate = model.state.viewModelData.length !== model.state.selectedCreatives.length && model.state.selectedCreatives.length > 0)}
              onChange={model.handleOnSelectAll}
              id={'creativeNameIdHeaderFormatter'}
            />
            <label htmlFor='creativeNameIdHeaderFormatter' />
          </PermissionChecker>
        </div>
        {i18n.t<string>(nameIdHeader.text)}
        {sortElement}
      </div>
    );
  };

  const previewFormatter = (previewData: any, creative: any) => {
    if (!previewData) {
      return <div />;
    }
    return (
      <div className={`${styles.creativeCell} ${styles.previewCell}`}>
        <div className={styles.creativeReview}>
          <CreativePreview model={new DefaultCreativePreviewModel(previewData)} />
        </div>
        <IconWithTooltip
          placement='right'
          className={styles.detailBtn}
          onClick={model.showCreativeDetailModal.bind(model, previewData)}
          icon={faEye}
          tooltipProps={{
            id: `creativeListPreviewHint-${creative.id}`,
            tooltip: i18n.t<string>('creativeList.labels.previewHint')
          }}
        />
      </div>
    );
  };

  const textFormatter = (text: any) => {
    if (!text) {
      return <div />;
    }
    return (
      <div className={styles.creativeCell}>
        <div className={styles.text}>
          {text}
        </div>
      </div>
    );
  };

  const outdoorTypeFormatter = (outdoorType: any) => {
    if (!outdoorType) {
      return <div />;
    }
    return (
      <div className={styles.creativeCell}>
        <div className={styles.text}>
          {i18n.t<string>(`outdoorForm.labels.outdoorType${outdoorType}`)}
        </div>
      </div>
    );
  };

  const durationFormatter = (duration: any, creative: any) => {
    if (duration === undefined) {
      return <div />;
    }
    return (
      <div className={styles.creativeCell}>
        <div className={styles.text}>
          {creative.outdoorType === OutdoorType.HTML5 ?
            `${duration} ${i18n.t<string>('common.units.seconds')}` :
            i18n.t<string>(`outdoorForm.labels.duration${duration}`)
          }
        </div>
      </div>
    );
  };

  const ppsLayoutFormatter = (layoutId: any) => {
    if (!layoutId) {
      return <div />;
    }
    const renderLayoutId = () => {
      const realId = layoutId.split('(')[0].substring(4).toLowerCase().replace(/-|\s/g, '_');
      const key = `ppsLayoutId.${realId}`;
      return (
        <div key={layoutId}>
          {i18n.exists(key) ? i18n.t<string>(`ppsLayoutId.${realId}`) : layoutId.split('(')[0].substring(4)}
        </div>
      );
    };
    return (
      <div className={styles.creativeCell}>
        <div className={styles.text}>
          {renderLayoutId()}
        </div>
      </div>
    );
  };

  const urlFormatter = (url: any) => {
    if (!url) {
      return <div />;
    }
    return (
      <div className={styles.creativeCell}>
        <a href={url} target='_blank' rel='noopener noreferrer'>{url}</a>
      </div>
    );
  };

  const bindStateFormatter = (bindingCampaign: any, creative: any) => {
    if (!bindingCampaign) {
      return <div />;
    }
    const bindingStatus = _.countBy(bindingCampaign, campaign => campaign.isActive);
    const hasBind = bindingStatus.true > 0 || bindingStatus.false > 0;
    const hasStartTime = creative.enableStartTime;
    const hasEndTime = creative.enableEndTime;
    const isNowBeforeEnableStartTime = hasStartTime && moment() < moment(creative.enableStartTime);
    const isNowAfterEnableEndTime = hasEndTime && moment() > moment(creative.enableEndTime);

    let state;
    if (isNowAfterEnableEndTime) {
      state = 'afterEndTime';
    } else if (isNowBeforeEnableStartTime) {
      state = 'beforeStartTime';
    } else {
      if (hasBind) {
        state = 'binding';
      } else {
        state = 'notBindYet';
      }
    }

    return (
      <div className={styles.creativeCell}>
        <div className={styles.bindState}>
          {state === 'afterEndTime' &&
            <div className={styles.afterEndTimeState}>{i18n.t<string>('creativeList.labels.afterEndTime')}</div>
          }
          {state === 'beforeStartTime' &&
            <div className={styles.beforeStartTimeState}>{i18n.t<string>('creativeList.labels.beforeStartTime')}</div>
          }
          {state === 'notBindYet' &&
            <div className={styles.notBindState}>{i18n.t<string>('creativeList.labels.notBindYet')}</div>
          }
          {state === 'binding' &&
            <Fragment>
              <div className={styles.enableBindState}>{i18n.t<string>('creativeList.labels.bindEnable', { count: bindingStatus.true ? bindingStatus.true : 0 })}</div>
              <div className={styles.disableBindState}>{i18n.t<string>('creativeList.labels.bindDisable', { count: bindingStatus.false ? bindingStatus.false : 0 })}</div>
            </Fragment>
          }

          <div className={styles.duration}>
            {hasStartTime && <div>{`${i18n.t<string>('creativeList.labels.enableStartTime')}: ${creative.enableStartTime}`}</div>}
            {hasEndTime && <div>{`${i18n.t<string>('creativeList.labels.enableEndTime')}: ${creative.enableEndTime}`}</div>}
          </div>
        </div>
        {hasBind &&
          <PermissionChecker permissionAware={creativeWritePermission}>
            <IconWithTooltip
              placement='right'
              className={styles.editBtn}
              onClick={model.showCreativeBindingManage.bind(model, creative)}
              icon={faPencilAlt}
              tooltipProps={{
                id: `creativeListBindHint-${creative.id}`,
                tooltip: i18n.t<string>('creativeList.labels.bindHint')
              }}
            />
          </PermissionChecker>
        }
      </div>
    );
  };

  const limitationFormatter = (limitationData: any, creative: any) => {
    if (!limitationData) {
      return <div />;
    }

    return <LimitationTableCell summaryData={limitationData} l2ChannelId={creative.id}/>;
  };

  const approvalFormatter = (creativeData: any) => {
    if (!creativeData) {
      return <div />;
    }

    return (
      <CreativeReviews
        showCreativeType={true}
        creativeData={creativeData}
        reviewCreative={model.reviewCreative.bind(model)}
      />
    );
  };

  const streamingFormatter = (streamingSetting: { inStream: boolean, outStream: boolean }) => {
    if (!streamingSetting) {
      return <div />;
    }
    let content = streamingSetting.inStream ?
      i18n.t<string>('creativeList.labels.inStream') :
      i18n.t<string>('creativeList.labels.outStream');
    if (streamingSetting.inStream && streamingSetting.outStream) {
      content = i18n.t<string>('creativeList.labels.unlimited');
    }
    return (
      <div className={styles.creativeCell}>
        <span style={{ whiteSpace: 'nowrap' }}>
          {content}
        </span>
      </div>
    );
  };

  const skipSettingFormatter = (skipSetting) => {
    if (!skipSetting) {
      return <div />;
    }
    const { skippable, nonSkippable, skipOffset } = skipSetting;
    return (
      <div className={styles.creativeCell}>
        {skippable &&
          <>
            {`${i18n.t<string>('creativeList.labels.skippable')} : ${i18n.t<string>('creativeList.labels.skipOffset', { offset: skipOffset })}`}
            <br />
          </>
        }
        {nonSkippable &&
          <>
            {i18n.t<string>('creativeList.labels.nonSkippable')}
            <br />
          </>
        }
        {(!skippable && !nonSkippable) && i18n.t<string>('creativeList.labels.nonSkippable')}
      </div>
    );
  };

  const floatingEditBtnsFormatter = (cell: any, creative: any): any => {
    const canNotDeleteCreative = _.get(creative, 'bindingCampaign', []).length > 0;
    return (
      <PermissionChecker permissionAware={hasFuncs(Permission.CREATIVE_WRITE).or(hasFuncs(Permission.CREATIVE_DEL))}>
        <TableRowToolBar className={styles.floatingEditArea}>
          <PermissionChecker permissionAware={creativeWritePermission}>
            <Link to={`/creatives/${creative.id}/edit`}>
              <IconWithTooltip
                icon={faPencilAlt}
                tooltipProps={{
                  id: `creativeListEditTip-${creative.id}`,
                  tooltip: i18n.t<string>('creativeList.labels.editHint')
                }}
              />
            </Link>
            <IconWithTooltip
              icon={faCopy}
              onClick={model.cloneCreatives.bind(model, [creative.id])}
              tooltipProps={{
                id: `creativeListCloneTip-${creative.id}`,
                tooltip: i18n.t<string>('creativeList.labels.cloneHint')
              }}
            />
          </PermissionChecker>
          <PermissionChecker permissionAware={hasFuncs(Permission.CREATIVE_DEL)}>
            <IconWithTooltip
              disabled={canNotDeleteCreative}
              icon={faTrashAlt}
              onClick={model.showDeleteCreativeModal.bind(model, [creative.id])}
              tooltipProps={{
                id: `creativeListDeleteTip-${creative.id}`,
                tooltip: canNotDeleteCreative ?
                  i18n.t<string>('creativeList.labels.cannotDeleteHint') :
                  i18n.t<string>('creativeList.labels.deleteHint')
              }}
            />
          </PermissionChecker>
        </TableRowToolBar>
      </PermissionChecker>
    );
  };

  const fbStatusFormatter = (status) => {
    const finalStatus = status ? status : 'ACTIVE';
    return (
      <Status
        label={i18n.t<string>(`fbCreative.status.${finalStatus.toLowerCase()}`)}
        color={getEffectiveStatusDefaultColor(finalStatus)}
      />
    );
  };

  const stateFormatter = status => {
    return (
      <Status
        label={status ?
          i18n.t<string>('campaignCreativeList.status.enable') :
          i18n.t<string>('campaignCreativeList.status.disable')}
        color={status ? 'success' : 'black'}
      />
    );
  };

  const productSetFormatter = productSet => {
    return (
      <div className={styles.productSetCell}>
        <div className={styles.productSetName}>{productSet.name}</div>
        <div className={styles.productSetId}>{`ID : ${productSet.id}`}</div>
      </div>
    );
  };

  return {
    nameIdFormatter,
    previewFormatter,
    textFormatter,
    urlFormatter,
    bindStateFormatter,
    limitationFormatter,
    approvalFormatter,
    floatingEditBtnsFormatter,
    nameIdHeaderFormatter,
    streamingFormatter,
    skipSettingFormatter,
    ppsLayoutFormatter,
    outdoorTypeFormatter,
    durationFormatter,
    fbStatusFormatter,
    stateFormatter,
    productSetFormatter
  };
};
