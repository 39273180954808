import { AbstractFormContentModel } from './FormContentModel';
import Html5Form from './Html5Form';
import { Creative } from 'core/creative/Creative';
import _ from 'lodash';
import i18n from 'i18n';
import { formatBytes } from 'utils/StringUtil';
import JSZip from 'jszip';
import { renderErrors } from './CreativeFormHintRenderFunction';
import { BasicFormProps } from './BasicFormProps';
import { CreativeSetupFlowPageModel } from 'containers/Creatives/CreativeSetupFlow/CreativeSetupFlowPageModel';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { AdLogo } from 'core/adLogo/AdLogo';
const mimeDB = require('mime-db');

export type Html5FormProps = {
  readonly model: Html5FormModel;
} & BasicFormProps;

export class Html5FormModel extends AbstractFormContentModel {

  public needBannerUrl: boolean = true;

  constructor (
    public flowPageModel: CreativeSetupFlowPageModel
  ) {
    super();
  }

  public get defaultAdLogo (): AdLogo | undefined {
    if (this.flowPageModel.addonFeatureManager.isFeatureEnable(ADDONFEATURE.CREATIVES.CUSTOMIZE_ADLOGO)) {
      return this.flowPageModel.defaultAdLogo;
    }
    return undefined;
  }

  addLimitation?: (operate: string, limitationType: string, label: string, value: string) => void;

  setLimitationHook (addLimitation: (operate: string, limitationType: string, label: string, value: string) => void): void {
    this.addLimitation = addLimitation;
  }

  getBannerSize = () => {
    const recommendSize = ['320x50', '728x90', '300x250', '300x600', '300x100'];
    const otherSize = ['160x600', '336x280', '234x60', '320x100', '468x60',
      '970x90', '970x250', '970x325', '640x1000', '120x600',
      '300x1050', '250x250', '200x200', '180x150', '125x125',
      '320x250', '336x480', '320x480'];
    const recommendSizeOptions = recommendSize.map(size => {
      const widthHeight = size.split('x');
      return {
        label: `${widthHeight[0]} x ${widthHeight[1]} (${i18n.t<string>('html5Form.labels.recommended')})`,
        value: size
      };
    });

    const otherSizeOptions = otherSize.map(size => {
      const widthHeight = size.split('x');
      return {
        label: `${widthHeight[0]} x ${widthHeight[1]}`,
        value: size
      };
    });
    return recommendSizeOptions.concat(otherSizeOptions);
  }

  getFormContent = () => {
    return Html5Form;
  }

  validateZip = async (fileData) => {
    if (!fileData || (!fileData.file && !fileData.url)) {
      return i18n.t<string>('formValidate.labels.emptyError');
    }

    if (!fileData.file) {
      return;
    }

    const validTypes = ['application/zip', 'application/x-zip-compressed'];
    const file = fileData.file;
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = _.get(mimeDB[file.type], 'extensions', ['Unknown']);
      return i18n.t<string>('html5Form.labels.typeErrorHint', { type: extensions[0] });
    }

    const errors: any[] = [];
    if (file.size > 2097152) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.storageErrorHint', { storage1: formatBytes(file.size), storage2: '2MB' }));
    }

    const zipData = await JSZip.loadAsync(file);
    const contents: string[] = [];
    zipData.forEach((_1, zipEntry) => {
      // @ts-ignore
      contents.push(zipEntry.name);
    });
    if (!contents.includes('index.html')) {
      errors.push(i18n.t<string>('creativeSetupFlow.labels.zipContentError'));
    }

    return errors.length > 0 ? renderErrors(errors) : undefined;
  }

  getInitTypeProperties () {
    return {
      bannerSize: '320x50'
    };
  }

  getFormModelData (creative: Creative) {
    const creativeBasic: any = _.omit(creative.basic, ['id', 'srcType', 'creativeValues']);
    const creativeValueString = _.get(creative, 'basic.creativeValues', '{}');
    const creativeValue = JSON.parse(creativeValueString);
    const medias = _.omitBy({
      htmlZip: _.omitBy({
        url: _.get(creativeValue, 'htmlUrl')
      }, _.isUndefined)
    }, _.isEmpty);
    const width = _.get(creativeValue, 'w', '300');
    const height = _.get(creativeValue, 'h', '250');
    return {
      basic: {
        ...creativeBasic,
        typeProperties: {
          bannerSize: `${width}x${height}`
        },
        medias
      },
      limitations: creative.limitations
    };
  }
}
