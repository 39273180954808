import { DefaultGoSegmentManager, GoSegmentManager } from 'core/goSegment/GoSegmentManager';
import { useState } from 'react';
import _ from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import { GoSegmentFormData } from 'core/goSegment/GoSegment';

const defaultGoSegmentManager: GoSegmentManager = new DefaultGoSegmentManager();

export type GoSegmentFormModelData = {
  formType: string;
  loading: boolean;
  initGoSegment?: Partial<GoSegmentFormData>;
  redirectPath?: string;
  extraData?: any;
  validate: (values: Partial<GoSegmentFormData>) => any;
  save: (values: Partial<GoSegmentFormData>) => Promise<void>;
};

export const useCreateGoSegmentFormModel = (
  goSegmentManager: GoSegmentManager = defaultGoSegmentManager
) => {
  const [loading, setLoading] = useState(false);
  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);

  const save = async (saveValue) => {
    setLoading(true);
    try {
      await goSegmentManager.createGoSegment(saveValue);
      setRedirectPath('/gojek-segments');
    } catch (e) {
      setLoading(false);
    }
  };
  return {
    loading,
    redirectPath,
    save,
    validate: basicValidate
  };
};

export const useEditGoSegmentFormModel = (
  segmentId: number | string,
  goSegmentManager: GoSegmentManager = defaultGoSegmentManager
) => {
  const [loading, setLoading] = useState(false);
  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);

  const save = async (saveValue) => {
    setLoading(true);
    try {
      await goSegmentManager.updateGoSegment(segmentId, saveValue);
      setRedirectPath('/gojek-segments');
    } catch (e) {
      setLoading(false);
    }
  };

  return {
    loading,
    redirectPath,
    save,
    validate: basicValidate
  };
};

function basicValidate (value: Partial<GoSegmentFormData>) {
  return _.omitBy({
    name: validateEmpty(value.name),
    advertiserId: validateEmpty(value.advertiserId)
  }, _.isEmpty);
}
