import React, { useCallback } from 'react';
import i18n from 'i18n';
import styles from './messageCampaignOptimize.module.scss';
import { MessageFormModel } from 'containers/MessageCampaigns/MessageCampaignSetupFlow/MessageCampaignForm/MessageFormModel';
import { FormikField } from 'components/common/form/field/FormikField';
import { LabelField } from 'components/common/form/field/LabelField';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { MessageCampaignPlanType } from 'core/messageCampaign/MessageCampaign';

const MessageCampaignOptimize: React.FunctionComponent<{
  model: MessageFormModel,
  priceModel: MessageCampaignPlanType,
  optimize: L2ObjectOptimizationGoal,
  onOptimizeChanged: (value: any) => void
}> = ({
  model,
  priceModel,
  optimize,
  onOptimizeChanged
}) => {

  const {
    canEditOptimize,
    optimizeOptions
  } = model;

  const optimizeLabelFormatter = useCallback((optimization: any) => i18n.t<string>(`optimizationGoal.${optimization.toLowerCase()}`), []);

  return (
    <fieldset>
      <legend>
        <span>{i18n.t<string>('adSetSetupFlow.mainStep.fieldset.optimizationTitle')}</span>
      </legend>
      <div className={styles.component}>
        {canEditOptimize(priceModel) ?
          <FormikField.Select
            options={optimizeOptions}
            label={i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal')}
            name='optimize'
            simpleValue
            onChange={onOptimizeChanged}
          /> :
          <FormikField.Label
            label={i18n.t<string>('adSetSetupFlow.mainStep.field.optimizationGoal')}
            name='optimize'
            formatter={optimizeLabelFormatter}
          />}
          <LabelField
            name='billingEvent'
            label={i18n.t<string>('adSetSetupFlow.mainStep.field.billingEvent')}
            className={styles.billingEvent}
            value={
              !priceModel || !optimize ?
              i18n.t<string>('messageCampaign.billingEvent.unspecified') :
              i18n.t<string>(`messageCampaign.billingEvent.${priceModel}`)
            }
          />
      </div>
    </fieldset>
  );
};

export default React.memo(MessageCampaignOptimize);
