import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { useCoreContext } from 'contexts/coreContext';
import { RoleNames } from 'core';
import { hasRoles } from 'core/permission/PermissionDSL';
import { get } from 'lodash';
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { StoredValueList } from './StoredValueList/StoredValueList';
import { StoredValueDetail } from './StoredValueDetail/StoredValueDetail';

export const StoredValue: React.FC = () => {

  const core = useCoreContext();
  const agencyId: number | undefined = get(core, 'accountManager.localeMeta.agencyId');
  return (
    <Switch>
      <Route
        path={'/stored-value/:agencyId'}
        children={<StoredValueDetail />}
      />
      <RouteWithPermission
        path={'/stored-value'}
        permissionAware={hasRoles(RoleNames.sysAdmin)}
        redirectPath={`/stored-value/${agencyId}`}
        children={<StoredValueList />}
      />
    </Switch>
  );
};
