import {
  OrderWebService,
  RestfulOrderWebService,
  OrdersWithPagination
} from 'ws/OrderWebService';

import { Order, OrderType } from 'core/order/Order';
import { Pageable } from 'ws/Pageable';
import { RestfulBlobWebService } from 'ws/BlobWebService';
import { SelectOptions } from 'components/common/commonType';
import { RestfulGCPCloudStorageWebService } from 'ws/GCPCloudStorageWebService';
import { CloudResourceWebService } from 'ws/CloudResourceWebService';
import config from 'config';
import { FinalReportStatus } from 'core/finalReport/FinalReportStatus';

export interface OrderManager {
  getOrder (orderNumber: string): Promise<Order>;
  getOrderById (orderId: string | number): Promise<Order>;
  getOrders (
    advertiserId: number | null | undefined,
    pageable: Pageable,
    search?: string,
    deliveries?: string[]): Promise<OrdersWithPagination>;
  approveOrder (orderId: number): Promise<void>;
  rejectOrder (orderId: number): Promise<void>;
  createOrder (order: Order): Promise<Order>;
  updateOrder (order: any): Promise<Order>;
  settleOrder (orderId: number): Promise<void>;
  unsettleOrder (orderId: number): Promise<void>;
  getOrderExternalTypes (): Promise<Array<SelectOptions>>;
  getOrderOptions (orderType?: OrderType): Promise<Array<SelectOptions>>;
  getExistedProjectNames (): Promise<string[]>;
  getFinalReportStatus (orderId: number): Promise<FinalReportStatus>;
}

export class DefaultOrderManager implements OrderManager {
  webService: OrderWebService;
  cloudStorageWebService: CloudResourceWebService;

  constructor (
    webService: OrderWebService = new RestfulOrderWebService(),
    cloudStorageWebService: CloudResourceWebService = config.cloudStorageConfig.platform === 'azure' ? new RestfulBlobWebService() : new RestfulGCPCloudStorageWebService()
  ) {
    this.webService = webService;
    this.cloudStorageWebService = cloudStorageWebService;
  }

  async getOrder (orderNumber: string): Promise<Order> {
    const order = await this.webService.getOrder(orderNumber);
    return order;
  }

  async getOrderById (orderId: string | number): Promise<Order> {
    return this.webService.getOrderById(orderId);
  }

  async getOrders (
    advertiserId: number | null | undefined,
    pageable: Pageable,
    search?: string,
    deliveries?: string[]): Promise<OrdersWithPagination> {
    const ordersWithPagination = await this.webService.getOrders(advertiserId, pageable, search, deliveries);
    return ordersWithPagination;
  }

  async approveOrder (orderId: number) {
    await this.webService.approveOrder(orderId);
  }

  async rejectOrder (orderId: number) {
    await this.webService.rejectOrder(orderId);
  }

  async createOrder (order: Order) {
    const newOrder = await this.webService.createOrder(order);
    return newOrder;
  }

  async updateOrder (order: any) {
    const updatedOrder = await this.webService.updateOrder(order);
    return updatedOrder;
  }

  async getOrderExternalTypes () {
    return this.cloudStorageWebService.getOrderExternalTypes();
  }

  async settleOrder (orderId: number) {
    await this.webService.settleOrder(orderId);
  }

  async unsettleOrder (orderId: number) {
    await this.webService.unsettleOrder(orderId);
  }

  async getOrderOptions (orderType?: OrderType) {
    return this.webService.getOrderOptions(orderType);
  }

  async getExistedProjectNames () {
    return this.webService.getExistedProjectNames();
  }

  async getFinalReportStatus (orderId: number): Promise<FinalReportStatus> {
    return this.webService.getFinalReportStatus(orderId);
  }
}
