import React, { Fragment } from 'react';
import { Route, Switch, RouteComponentProps, Redirect } from 'react-router';
import _ from 'lodash';
import './MainPage.scss';

import { Reports } from 'containers/Reports';
import { Accounts } from 'containers/Accounts';
import { Agencies } from 'containers/Agencies';
import { AppHeader } from 'components/AppHeader';
import { AppMenuBar } from 'components/AppMenuBar';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { FinalReportPage } from 'pages/FinalReportPage/FinalReportPage';
import { FBAppReviewerPage } from 'pages/FBAppReviewerPage/FBAppReviewerPage';

import { MainPageProps, MainPageState } from './MainPageModel';
import { Orders } from 'containers/Orders/Orders';
import { ErrorPage } from '../ErrorPage/ErrorPage';
import MenuBarExpandContext from 'contexts/menuBarExpandContext';
import { Creatives } from 'containers/Creatives/Creatives';
import Swagger from 'containers/Swagger/Swagger';
import { CampaignRedirect } from 'containers/L2Objects/CampaignRedirect';
import { L1ObjectRedirect } from 'containers/L1Objects/L1ObjectRedirect';
import { CreativeRedirect } from 'containers/Creatives/CreativeRedirect';
import { BindingRedirect } from 'components/CampaignCreativeManagement/BindingRedirect';
import { Advertisers } from 'containers/Advertisers/Advertisers';
import { Conversions } from 'containers/Conversions/Conversions';
import PermissionContext from 'contexts/permissionContext';
import { addOnEnabled, hasFuncs, hasRoles, notRoles } from 'core/permission/PermissionDSL';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { Permission } from 'core/auth/Permission';
import { ROUTE_PATH } from 'enum/RoutePath';
import { Doctors } from 'containers/Doctors/Doctors';
import { DefaultDoctorsModel } from 'containers/Doctors/DoctorsModel';
import { RoleNames } from 'core';
import { ProductGroups } from 'containers/ProductGroups/ProductGroups';
import { OutdoorMonitor } from 'containers/OutdoorMonitor/OutdoorMonitor';
import { CurrencyRateList } from 'containers/CurrencyRates/CurrencyRateList';
import CoreContext from 'contexts/coreContext';
import { Segments } from 'containers/Segments/Segments';
import { RetailMax } from 'containers/RetailMax';
import { ContactUs } from 'components/ContactUs/ContactUs';
import { GoSegments } from 'containers/GoSegments/GoSegments';
import { useDefaultSegmentsModel } from 'containers/GoSegments/GoSegmentsModel';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { StoredValue } from 'containers/StoredValue/StoredValue';
import { TenmaxSegments } from 'containers/TenmaxSegments/TenmaxSegments';
import { ElementScrollControl } from 'components/ElementScrollControl/ElementScrollControl';
import { CustomLayouts } from 'containers/CustomLayout/CustomLayouts';
import { MessageSegments } from 'containers/MessageSegments/MessageSegments';
import { useSystemAdminSegmentsModel } from 'containers/MessageSegments/MessageSegmentsModel';

export class MainPage extends React.Component<RouteComponentProps<{}> & MainPageProps, MainPageState> {

  handler?: number;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
  }

  componentDidMount () {
    this.props.model.init();
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  componentDidUpdate (prevProps, prevState: MainPageState) {
    if (prevState.permissionContext.actor !== this.props.model.state.permissionContext.actor) {
      this.removeErrorCodeOnActorChange();
    }
  }

  removeErrorCodeOnActorChange () {
    const errorCodeReg = /\/(?:error403|error404)$/;
    if (this.props.location.pathname.match(errorCodeReg)) {
      this.props.history.replace(this.props.location.pathname.replace(errorCodeReg, ''));
    }
  }

  renderAccount = (props) => {
    return (
      <Accounts
        model={this.props.model.accountsModel}
      />
    );
  }

  renderOEMAccount = (props) => {
    return (
      <Accounts
        model={this.props.model.oemAccountsModel}
      />
    );
  }

  renderOrders = (props) => {
    return (
      <Orders
        model={this.props.model.ordersModel}
      />
    );
  }

  renderCreatives = (props) => {
    return (
      <Creatives
        {...props}
        model={this.props.model.creativesModel}
      />
    );
  }

  renderOnboarding = () => {
    const model = this.props.model;

    return (
      <FBAppReviewerPage
        type='onboarding'
        imgPrefix='images-img-permission'
        model={model}
      />
    );
  }

  renderReports = (props) => {
    return (
      <Reports
        {...props}
        core={this.props.model.core}
      />
    );
  }

  renderRetailMax = (props) => {
    const mainPagePath = this.props.model.mainPagePath;

    return (
      <RetailMax
        {...props}
        model={this.props.model.retailMaxModel}
        mainPagePath={mainPagePath}
      />
    );
  }

  renderSwagger = (props) => {
    const authenticationManager = this.props.model.core && this.props.model.core.authenticationManager;
    return (
      <Swagger authenticationManager={authenticationManager} />
    );
  }

  renderAgencies = (props) => {
    return (
      <Agencies
        model={this.props.model.agenciesModel}
      />
    );
  }

  renderAudienceSegments = () => {
    return (
      <Segments
        model={this.props.model.segmentsModel}
      />
    );
  }

  renderGojekSegments = () => {
    return <GoSegments useModel={useDefaultSegmentsModel}/>;
  }

  renderMessageSegments = () => {
    const useModel = this.props.model.core.accountManager.localeMeta?.agencyId ?
      useDefaultSegmentsModel :
      useSystemAdminSegmentsModel;
    return <MessageSegments useModel={useModel}/>;
  }

  redirectToSource = () => {
    return <Redirect to='/tenmax-segments/sources'/>;
  }

  renderTenmaxSegments = () => {
    return (
      <TenmaxSegments/>
    );
  }

  renderConversions = props => {
    return (
      <Conversions
        {...props}
        model={this.props.model.conversionsModel}
      />
    );
  }

  renderCampaignDetail = (props) => {
    const query = new URLSearchParams(props.location.search);
    const l1ObjectId = query.get('l1ObjectId');
    const campaignId = props.match.params.campaignId;
    if (!l1ObjectId) {
      return <Redirect to='/'/>;
    }
    return (
      <CampaignRedirect
        l1ObjectId={l1ObjectId}
        campaignId={campaignId}
      />
    );
  }

  renderL1ObjectDetail = (props) => {
    const l1ObjectId = props.match.params.l1ObjectId;
    return (
      <L1ObjectRedirect
        l1ObjectId={l1ObjectId}
      />
    );
  }

  renderCreativeDetail = (props) => {
    const creativeId = props.match.params.creativeId;
    return (
      <CreativeRedirect
        creativeId={creativeId}
      />
    );
  }

  renderBindingDetail = (props) => {
    const query = new URLSearchParams(props.location.search);
    const l1ObjectId = query.get('l1ObjectId');
    const campaignId = query.get('campaignId');
    if (!l1ObjectId || !campaignId) {
      return <Redirect to='/'/>;
    }
    return (
      <BindingRedirect
        l1ObjectId={l1ObjectId}
        campaignId={campaignId}
      />
    );
  }

  renderAppMenuBar = () => {
    return (
      <AppMenuBar model={this.props.model.barModel} />
    );
  }

  renderAdvertisers = () => {
    return (
      <Advertisers model={this.props.model.advertisersModel} />
    );
  }

  renderErrorPage = (errorCode) => {
    const onBackClicked = (path: string) => {
      this.props.history.push(path);
    };
    return (
      <ErrorPage
        errorCode={errorCode}
        onBackClicked={onBackClicked}
        currentPath={this.props.location.pathname}
      />
    );
  }

  renderContactUs = () => {
    const account = this.props.model.core.authenticationManager.account;
    if (!account) {
      return;
    }
    return <ContactUs account={account}/>;
  }

  renderMainPage = () => {
    const mainPagePath = this.props.model.mainPagePath;
    return <Redirect to={mainPagePath} />;
  }

  renderDoctors = () => {
    return <Doctors model={new DefaultDoctorsModel()} />;
  }

  renderProductGroups = () => {
    return <ProductGroups />;
  }

  renderCurrencyRate = () => {
    return <CurrencyRateList />;
  }

  renderStoredValue = () => {
    return <StoredValue />;
  }

  renderCustomLayouts = () => {
    return <CustomLayouts />;
  }

  renderFinalReportPageContent = (props) => {
    const indicator = this.state.loading ? <LoadingIndicator /> : null;
    const model = this.props.model;
    const account = model.account;

    return (account === null || indicator ?
      indicator :
      <FinalReportPage
        {...props}
        authenticationManager={this.props.authenticationManager}
      />
    );
  }

  renderMainPageContent = () => {
    const indicator = this.state.loading ? <LoadingIndicator /> : null;
    const model = this.props.model;
    const account = model.account;
    const actor = model.core.authenticationManager.actor;
    const hasFbAdsManagements = model.hasFbAdsManagements;
    const { sysAdmin, goJekAccountManager, fbAgencyManager, guest, agencyReport, adsReport, agencyAdmin, agencyManager } = RoleNames;
    const renderEmpty = () => (<div />);

    return (
      <div className='main-page'>
        <AppHeader model={model.headerModel} />
        <div className='main-page-container'>
          {
            account === null || indicator ?
              indicator :
              <Fragment>
                <Switch>
                  <Route path={`*/${ROUTE_PATH.ERROR403}`} render={this.renderAppMenuBar} />
                  <Route path={`*/${ROUTE_PATH.ERROR404}`} render={this.renderAppMenuBar} />
                  <Route path='/orders/:orderNumber/campaigns' render={renderEmpty} />
                  <Route path='/orders/:orderNumber/campaign-groups/:l1ObjectId/campaigns' render={renderEmpty} />
                  <Route path='/orders/:orderNumber/campaign-groups/:l1ObjectId/message-campaigns' render={renderEmpty} />
                  <Route path='/orders/:orderNumber/campaign-groups/:l1ObjectId/drafts' render={renderEmpty} />
                  <Route path='/creatives/new' render={renderEmpty} />
                  <Route path='/creatives/:creativeId/edit' render={renderEmpty} />
                  <Route path='/gojek-segments/new' render={renderEmpty} />
                  <Route path='/gojek-segments/:segmentId/edit' render={renderEmpty} />
                  <Route path='/custom-layouts/new' render={renderEmpty} />
                  <Route render={this.renderAppMenuBar} />
                </Switch>
                <div className='content'>
                  <ElementScrollControl>
                    {(actor && actor.roleName === RoleNames.fbAgencyManager) && !hasFbAdsManagements ?
                      <Fragment>
                        <Switch>
                          <Route path={`*/${ROUTE_PATH.ERROR403}`} component={_.partial(this.renderErrorPage, 403)} />
                          <Route path={`*/${ROUTE_PATH.ERROR404}`} component={_.partial(this.renderErrorPage, 404)} />
                          <RouteWithPermission
                            path='/onboarding'
                            permissionAware={hasRoles(fbAgencyManager)}
                            redirectPath={this.props.model.mainPagePath}
                            render={this.renderOnboarding}
                          />
                          <Route render={this.renderMainPage} />
                        </Switch>
                      </Fragment> :
                      <Fragment>
                        <Switch>
                          <Route path={`*/${ROUTE_PATH.ERROR403}`} component={_.partial(this.renderErrorPage, 403)} />
                          <Route path={`*/${ROUTE_PATH.ERROR404}`} component={_.partial(this.renderErrorPage, 404)} />
                          <Route path='/reports' render={this.renderReports} />
                          <RouteWithPermission
                            path='/contact-us'
                            redirectPath={this.props.model.mainPagePath}
                            permissionAware={hasRoles(guest)}
                            render={this.renderContactUs}
                          />
                          <RouteWithPermission
                            path='/onboarding'
                            permissionAware={hasRoles(fbAgencyManager)}
                            redirectPath={`/onboarding/${ROUTE_PATH.ERROR403}`}
                            render={this.renderOnboarding}
                          />
                          <RouteWithPermission
                            path='/accounts'
                            permissionAware={hasFuncs(Permission.ACCOUNT_READ)}
                            redirectPath={`/accounts/${ROUTE_PATH.ERROR403}`}
                            render={this.renderAccount}
                          />
                          <RouteWithPermission
                            path='/oem-accounts'
                            permissionAware={hasFuncs(Permission.ACCOUNT_READ)}
                            redirectPath={`/oem-accounts/${ROUTE_PATH.ERROR403}`}
                            render={this.renderOEMAccount}
                          />
                          <RouteWithPermission
                            path='/agencies'
                            permissionAware={hasFuncs(Permission.AGENCY_READ)}
                            redirectPath={`/agencies/${ROUTE_PATH.ERROR403}`}
                            render={this.renderAgencies}
                          />
                          <RouteWithPermission
                            path='/orders'
                            permissionAware={hasFuncs(Permission.ORDER_READ)}
                            redirectPath={`/orders/${ROUTE_PATH.ERROR403}`}
                            render={this.renderOrders}
                          />
                          <RouteWithPermission
                            exact
                            path='/creatives/:creativeId/redirectToDetail'
                            permissionAware={hasFuncs(Permission.CREATIVE_READ)}
                            redirectPath={`/${ROUTE_PATH.ERROR403}`}
                            render={this.renderCreativeDetail}
                          />
                          <RouteWithPermission
                            path='/creatives'
                            permissionAware={hasFuncs(Permission.CREATIVE_READ).and(notRoles(sysAdmin, agencyReport, adsReport, goJekAccountManager, guest))}
                            redirectPath={`/creatives/${ROUTE_PATH.ERROR403}`}
                            render={this.renderCreatives}
                          />
                          <RouteWithPermission
                            path='/advertisers'
                            permissionAware={hasFuncs(Permission.ADVERTISER_READ)}
                            redirectPath={`/advertisers/${ROUTE_PATH.ERROR403}`}
                            render={this.renderAdvertisers}
                          />
                          <RouteWithPermission
                            path='/audience-segments'
                            permissionAware={addOnEnabled(ADDONFEATURE.ADSORDER.TENMAX)}
                            redirectPath={`/audience-segments/${ROUTE_PATH.ERROR403}`}
                            render={this.renderAudienceSegments}
                          />
                          <RouteWithPermission
                            path='/gojek-segments'
                            permissionAware={addOnEnabled(ADDONFEATURE.ADSORDER.GOJEK)}
                            redirectPath={`/gojek-segments/${ROUTE_PATH.ERROR403}`}
                            render={this.renderGojekSegments}
                          />
                          <RouteWithPermission
                            path='/message-segments'
                            permissionAware={addOnEnabled(ADDONFEATURE.CHANNEL.MESSAGE).or(hasRoles(sysAdmin))}
                            redirectPath={`/message-segments/${ROUTE_PATH.ERROR403}`}
                            render={this.renderMessageSegments}
                          />
                          <RouteWithPermission
                            exact
                            path='/tenmax-segments'
                            permissionAware={hasRoles(RoleNames.sysAdmin)}
                            redirectPath={`/tenmax-segments/${ROUTE_PATH.ERROR403}`}
                            render={this.redirectToSource}
                          />
                          <RouteWithPermission
                            path='/tenmax-segments/sources'
                            permissionAware={hasRoles(RoleNames.sysAdmin)}
                            redirectPath={`/tenmax-segments/sources/${ROUTE_PATH.ERROR403}`}
                            render={this.renderTenmaxSegments}
                          />
                          <RouteWithPermission
                            path='/conversions'
                            permissionAware={hasFuncs(Permission.CONVERSION_READ)}
                            redirectPath={`/conversions/${ROUTE_PATH.ERROR403}`}
                            render={this.renderConversions}
                          />
                          <RouteWithPermission
                            path='/campaigns/:campaignId/redirectToDetail'
                            permissionAware={hasFuncs(Permission.ORDER_READ, Permission.CAMPAIGN_READ)}
                            redirectPath={`/${ROUTE_PATH.ERROR403}`}
                            render={this.renderCampaignDetail}
                          />
                          <RouteWithPermission
                            path='/campaign-groups/:l1ObjectId/redirectToDetail'
                            permissionAware={hasFuncs(Permission.ORDER_READ, Permission.CAMPAIGN_READ)}
                            redirectPath={`/${ROUTE_PATH.ERROR403}`}
                            render={this.renderL1ObjectDetail}
                          />
                          <RouteWithPermission
                            path='/bindings/:bindingId/redirectToDetail'
                            permissionAware={hasFuncs(Permission.ORDER_READ, Permission.CAMPAIGN_READ, Permission.CREATIVE_READ)}
                            redirectPath={`/${ROUTE_PATH.ERROR403}`}
                            render={this.renderBindingDetail}
                          />
                          <RouteWithPermission
                            path='/system/product-groups'
                            permissionAware={hasRoles(RoleNames.sysAdmin, RoleNames.agencyAdmin, RoleNames.agencyManager)}
                            redirectPath={`/system/product-groups/${ROUTE_PATH.ERROR403}`}
                            render={this.renderProductGroups}
                          />
                          <RouteWithPermission
                            path='/system/currency-rate'
                            permissionAware={hasRoles(sysAdmin)}
                            redirectPath={`/system/currency-rate/${ROUTE_PATH.ERROR403}`}
                            render={this.renderCurrencyRate}
                          />
                          <RouteWithPermission
                            path='/stored-value'
                            permissionAware={hasRoles(sysAdmin).or(hasRoles(agencyAdmin, agencyManager).and(addOnEnabled(ADDONFEATURE.COMPANY.STORED_VALUE)))}
                            redirectPath={`/stored-value/${ROUTE_PATH.ERROR403}`}
                            render={this.renderStoredValue}
                          />
                          <RouteWithPermission
                            path='/custom-layouts'
                            permissionAware={hasRoles(sysAdmin)}
                            redirectPath={`/custom-layouts/${ROUTE_PATH.ERROR403}`}
                            render={this.renderCustomLayouts}
                          />
                          <Route path='/api-swagger' render={this.renderSwagger} />
                          <Route render={this.renderRetailMax} />
                          <Route render={this.renderMainPage} />
                        </Switch>
                      </Fragment>
                    }
                  </ElementScrollControl>
                </div>
              </Fragment>
          }
        </div>
      </div>
    );
  }

  renderOutdoorMonitor = () => {
    const actor = this.props.model.core.authenticationManager.actor;
    const localeMeta = this.props.model.core.accountManager.localeMeta;
    const agencyId = actor?.agencyId;
    return <OutdoorMonitor agencyId={agencyId} localeMeta={localeMeta} />;
  }

  render () {
    const model = this.props.model;
    return (
      <MenuBarExpandContext.Provider
        value={this.props.model.barModel.state.expanded}
      >
        <PermissionContext.Provider
          value={model.state.permissionContext}
        >
          <CoreContext.Provider
            value={model.core}
          >
            <Switch>
              <Route path='/final-report' render={this.renderFinalReportPageContent} />
              <Route render={this.renderMainPageContent} />
            </Switch>
          </CoreContext.Provider>
        </PermissionContext.Provider>
      </MenuBarExpandContext.Provider>
    );
  }
}
