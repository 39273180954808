import { Actor, RoleNames } from 'core';
import { hasRoles } from 'core/permission/PermissionDSL';
import { Metrics, ReportDimension, ReportRecord } from 'core/report/ReportData';
import _ from 'lodash';
import { divideValue } from './ReportDataHelper';
import { ReportDataProvider } from './ReportDataProvider';
import { tableColumnSettings } from './reportTableColumnSettings';

enum COLUMNS {
  NAME = 'name',
  MESSAGE_SEND = 'messageSend',
  MESSAGE_ARRIVED = 'messageArrived',
  CLICKS = 'clicks',
  MESSAGE_CTR = 'messageCTR',
  SPENT = 'spent',
  TAGS = 'tags',
  EDITBTNS = 'editBtns'
}

export class MessageReportDataProvider implements ReportDataProvider {

  onDimensionNameClick: any;
  onDateClick: any;
  onEditClick: any;
  constructor (onDimensionNameClick, onDateClick, onEditClick) {
    this.onDimensionNameClick = onDimensionNameClick;
    this.onDateClick = onDateClick;
    this.onEditClick = onEditClick;
  }

  get avalableDimensions () {
    return [
      ReportDimension.DAY,
      ReportDimension.AGENCY,
      ReportDimension.ADVERTISER,
      ReportDimension.ORDER,
      ReportDimension.L1_OBJECT,
      ReportDimension.L2_OBJECT
    ];
  }

  getReportDimensions (actor: Actor | null): Array<ReportDimension> {
    const isSysAdmin = hasRoles(RoleNames.sysAdmin).visible({ actor });
    const isAdvertiser = hasRoles(RoleNames.adsAdmin, RoleNames.adsReport, RoleNames.adsSales).visible({ actor });
    const forbidDimensions = isSysAdmin ?
    [ReportDimension.MONTH, ReportDimension.HOUR] :
    [ReportDimension.MONTH, ReportDimension.HOUR, ReportDimension.AGENCY];
    isAdvertiser && forbidDimensions.push(ReportDimension.ADVERTISER);
    return this.avalableDimensions.filter(dimension => forbidDimensions.indexOf(dimension) === -1);
  }

  getReportTableColumnSettings (allowMetrics: Array<Metrics>, tableData: any, dimension: ReportDimension, currency: string) {
    const allColumnSettings = tableColumnSettings(tableData, dimension, currency, this.onDimensionNameClick, this.onDateClick);
    const tableAllowMetrics = [
      ...allowMetrics,
      COLUMNS.NAME,
      COLUMNS.EDITBTNS,
      COLUMNS.MESSAGE_CTR
    ];
    dimension === ReportDimension.CAMPAIGN && tableAllowMetrics.push(COLUMNS.TAGS);
    return _.compact(
      Object.values(COLUMNS).map(column => {
        return tableAllowMetrics.indexOf(column) !== -1 &&
          allColumnSettings[column];
      })
    );
  }

  getReportTableData (records: Array<ReportRecord>) {
    return records.map(record => {
      return {
        key: record.dimensionName + _.get(record, 'dimensionID', 0),
        id: _.get(record, 'dimensionID', 0).toString(),
        [COLUMNS.NAME]: record.dimensionName,
        [COLUMNS.MESSAGE_SEND]: record.messageSend,
        [COLUMNS.MESSAGE_ARRIVED]: record.messageArrived,
        [COLUMNS.CLICKS]: record.clicks,
        [COLUMNS.MESSAGE_CTR]: divideValue(_.get(record, 'clicks', 0) * 100, _.get(record, 'messageArrived', 0)),
        [COLUMNS.SPENT]: record.spent,
        [COLUMNS.TAGS]: record.tags
      };
    });
  }
}
