import React from 'react';
import { faEye, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import { Link } from 'react-router-dom';
import styles from './tiktokAdGroupList.module.scss';
import i18n from 'i18n';
import { TiktokAdGroup, TiktokBidStrategy, TiktokBillingEvent } from 'core/tiktokAdGroup/TiktokAdGroup';
import { formatPrice, formatPriceWithCurrency } from 'helper/CurrencyHelper';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { addOnEnabled, hasFuncs, inCondition, notSelfServeAdObject } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import _, { clone } from 'lodash';
import { Status } from 'components/Status/Status';
import { Trans } from 'react-i18next';
import { deliveryStatusDataSetting } from './adGroupDeliveryStatusDataSetting';
import { L1ObjectObjective } from 'core/l1Object/L1Object';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { L2ObjectOptimizationGoal } from 'core/l2Object/L2Object';
import { LimitationTableCell } from 'components/LimitationTableCell/LimitationTableCell';

export const getAdGroupOptStatusDesData = (adGroup) => {
  let label = _.startCase(_.lowerCase(adGroup.opt_status));
  let color = 'black';
  switch (adGroup.opt_status) {
    case 'DISABLE':
      label = i18n.t<string>('campaignList.labels.deactivateState');
      color = 'black';
      break;
    case 'DELETE':
      label = i18n.t<string>('campaignList.labels.deleteState');
      color = 'danger';
      break;
    case 'ENABLE':
      label = i18n.t<string>('campaignList.labels.activateState');
      color = 'success';
      break;
    default:
      break;
  }

  return {
    label,
    color
  };
};

export const getAdGroupEffectiveStatusDesData = (adGroup) => {
  const isDraft = _.get(adGroup, 'isDraft', false);
  if (isDraft) {
    return null;
  }

  const deliveryStatusData = deliveryStatusDataSetting[adGroup.status];
  if (!deliveryStatusData) {
    return {
      label: _.startCase(_.lowerCase(adGroup.status)),
      color: ''
    };
  }
  const reasons = _.get(adGroup, 'review.reasons', []);
  const hasRejectedAd = _.get(adGroup, 'review.hasRejectedAd', false);
  if (hasRejectedAd) {
    reasons.push(i18n.t<string>('campaignList.labels.hasRejectedAd'));
  }
  const rejectReasonsDom = reasons.map(rejectReason => (
    <li key={rejectReason}>
      {rejectReason}
    </li>
  ));
  const renderExtraInfo = () => {
    return reasons && reasons.length > 0 ? (
      <div>
        <div className={styles.deliveryAdReviewSection}>{deliveryStatusData.des}</div>
        <div className={styles.deliveryAdReviewSection}>
          <div>Reasons:</div>
          <ul>
            {rejectReasonsDom}
          </ul>
        </div>
      </div>
    ) : `${deliveryStatusData.des}`;
  };
  if (adGroup.budget_remaining > 0 &&
    (adGroup.status === 'ADGROUP_STATUS_CAMPAIGN_DISABLE' || adGroup.status === 'ADGROUP_STATUS_DISABLE')
  ) {
    return {
      label: i18n.t<string>('campaignList.labels.budgetRemainState'),
      color: 'danger',
      renderExtraInfo
    };
  }
  return {
    label: i18n.t<string>(deliveryStatusData.label),
    color: deliveryStatusData.color,
    renderExtraInfo
  };
};

const campaignWritePermission = hasFuncs(Permission.CAMPAIGN_WRITE).and(addOnEnabled(ADDONFEATURE.CHANNEL.TIKTOK));

const nameHeaderFormatter = (
  adGroupLength: number,
  selectedObjects: (number | string)[],
  onSelectAll,
  isDeletedList,
  column: any,
  _1,
  { sortElement }
) => {
  const campaignEditPermission = clone(campaignWritePermission).and(inCondition(() => !isDeletedList));
  return (
    <div className={styles.nameHeader}>
      <PermissionChecker permissionAware={campaignEditPermission}>
        <input
          type='checkbox'
          checked={adGroupLength === selectedObjects.length}
          ref={el => el && (el.indeterminate = adGroupLength !== selectedObjects.length && selectedObjects.length > 0)}
          onChange={onSelectAll}
          id={'input0'}
        />
        <label htmlFor='input0' />
      </PermissionChecker>
      {i18n.t<string>(column.text)}
      {sortElement}
    </div>
  );
};

const creativeBindingFormatter = (
  enableBindingCount: number , { adgroup_id, draftId, isDraft, opt_status }, _1, formatExtraData?: { onClick, l1Object }
) => {
  if (!formatExtraData) {
    return <div/>;
  }
  const { onClick, l1Object } = formatExtraData;
  const onClickBinding = () => onClick(isDraft ? draftId : adgroup_id, isDraft);

  const renderCheckButton = () => (
    <IconWithTooltip
      className={styles.detailBtn}
      onClick={onClickBinding as () => void}
      icon={faEye}
      tooltipProps={{
        id: `campaignListCreativeViewHint-${adgroup_id}`,
        tooltip: i18n.t<string>('campaignList.labels.creativeViewHint')
      }}
    />
  );
  const campaignEditPermission = clone(campaignWritePermission).and(inCondition(() => opt_status !== 'DELETE')).and(notSelfServeAdObject(l1Object));
  return (
      <div className={styles.bindingCell}>
        {enableBindingCount === 0 || !enableBindingCount ? i18n.t<string>('creativeList.labels.none') : enableBindingCount}
        <PermissionChecker
          permissionAware={campaignEditPermission}
          renderOtherwise={renderCheckButton}
        >
          <IconWithTooltip
            placement='right'
            className={styles.campaignEditBtn}
            onClick={onClickBinding as () => void}
            icon={faPencilAlt}
            tooltipProps={{
              id: `campaignListCreativeEditHint-${adgroup_id}`,
              tooltip: i18n.t<string>('campaignList.labels.creativeEditHint')
            }}
          />
        </PermissionChecker>
      </div>
  );
};

const nameFormatter = (objectId: number | string, tiktokAdGroup: TiktokAdGroup, _2, formatExtraData: any): any => {
  const renderLabel = () => <span className={styles.name}>{tiktokAdGroup.adgroup_name}</span>;
  const { selectedAdGroups, selectedDrafts, onSelect, l1Object } = formatExtraData;
  const isDraft: boolean = _.get(tiktokAdGroup, 'isDraft', false);
  const adgroupId: number | string = tiktokAdGroup.adgroup_id;
  const draftId: number | string = isDraft ? objectId.toString().split('_')[2] : '';
  const objectIsChecked = !isDraft ? selectedAdGroups.indexOf(adgroupId.toString()) > -1 : selectedDrafts.indexOf(draftId.toString()) > -1;
  const editPath = isDraft ?
    `${formatExtraData.currentUrl}/drafts/${draftId}/edit` :
    `${formatExtraData.currentUrl}/campaigns/${adgroupId}/edit`;
  const campaignEditPermission = clone(campaignWritePermission).and(inCondition(() => tiktokAdGroup.opt_status !== 'DELETE'));
  return (
    <div className={`${styles.nameCell}`}>
      <div className={styles.selectCheckBox}>
        <PermissionChecker permissionAware={campaignEditPermission}>
          <input
            type='checkbox'
            checked={objectIsChecked}
            onChange={_.partial(onSelect, tiktokAdGroup)}
            id={`input${objectId}`}
          />
          <label htmlFor={`input${objectId}`} />
        </PermissionChecker>
      </div>
      <div className={styles.info}>
        <PermissionChecker permissionAware={notSelfServeAdObject(l1Object).and(campaignEditPermission)} renderOtherwise={renderLabel}>
          <Link to={editPath} className={`${styles.name} ${styles.link}`}>
            {tiktokAdGroup.adgroup_name}
          </Link>
        </PermissionChecker>
        <div className={styles.id}>
          {'ID: ' + objectId}
        </div>
        {isDraft &&
          <div className={styles.draftIcon}>
            {i18n.t<string>('l2ObjectList.labels.draft')}
          </div>
        }
      </div>
    </div>
  );
};

const stateFormatter = (stateData) => {
  return (
    <Status
      label={stateData.label}
      color={stateData.color}
    />
  );
};

const deliveryFormatter = (stateData, tiktokAdGroup: TiktokAdGroup) => {
  const isDraft: boolean = _.get(tiktokAdGroup, 'isDraft', false);
  if (isDraft) {
    const renderExtraInfo = () => i18n.t<string>('campaignList.labels.draftStateHint');
    return (
      <Status
        label={i18n.t<string>('campaignList.labels.deactivateState')}
        color={'black'}
        renderExtraInfo={renderExtraInfo}
      />
    );
  }
  return (
    <Status
      {...stateData}
    />
  );
};

const scheduleFormatter = (startTime: string, tiktokAdGroup: TiktokAdGroup, _1): any => {
  return (
    <Trans i18nKey='campaignList.labels.schedule'>
      {{ start: startTime }}<br/>to<br/>{{ end: tiktokAdGroup.schedule_end_time }}
    </Trans>
  );
};

const budgetFormatter = (budget: number | string, _1, _2, formatExtraData: any): any => {
  if (budget === -1) {
    return i18n.t<string>('common.labels.noData');
  }
  const currency = formatExtraData.currency;
  return formatPrice(currency, budget ? +budget : 0);
};

const optimizationFormatter = (optimization: string, tiktokAdGroup: TiktokAdGroup, _1, formatExtraData: any): any => {
  const currency = formatExtraData.currency;
  const validOptimizations: string[] = Object.values(L2ObjectOptimizationGoal);
  return (
    <div className={styles.optimizationCell}>
      <div className={styles.title}>
        {i18n.t<string>('adGroupSetupFlow.mainStep.field.optimizationGoal')}
      </div>
      <div>:</div>
      <div>
        {
           validOptimizations.includes(optimization) ?
            i18n.t<string>(`optimizationGoal.${optimization.toLowerCase()}`) :
            tiktokAdGroup.optimize_goal
        }
      </div>
      {
        _.get(tiktokAdGroup, 'promoted_object.custom_event_type') &&
        <>
          <div className={styles.title}>
            {i18n.t<string>('adGroupSetupFlow.mainStep.field.promoted_object_custom_event_type')}
          </div>
          <div>:</div>
          <div>
            {_.startCase(_.lowerCase(_.get(tiktokAdGroup, 'promoted_object.custom_event_type')))}
          </div>
        </>
      }
      <div className={styles.title}>
        {i18n.t<string>('adGroupSetupFlow.mainStep.field.bidStrategy')}
      </div>
      <div>:</div>
      <div>
        {
          Object.values(TiktokBidStrategy).includes(tiktokAdGroup.bid_type) ?
            i18n.t<string>(`adGroup.bidStrategy.${tiktokAdGroup.bid_type.toLowerCase()}`) :
            tiktokAdGroup.bid_type
        }
      </div>
      <div className={styles.title}>
        {i18n.t<string>('adGroupSetupFlow.mainStep.field.bidControl')}
      </div>
      <div>:</div>
      <div>
        {tiktokAdGroup.bid ?
         formatPriceWithCurrency(currency, +(tiktokAdGroup.bid)) :
         i18n.t<string>('common.labels.noData')
        }
      </div>
      <div className={styles.title}>
        {i18n.t<string>('adGroupSetupFlow.mainStep.field.billingEvent')}
      </div>
      <div>:</div>
      <div>
        {
          Object.values(TiktokBillingEvent).includes(tiktokAdGroup.billing_event) ?
            i18n.t<string>(`adGroup.billingEvent.${tiktokAdGroup.billing_event.toLowerCase()}`) :
            tiktokAdGroup.billing_event
        }
      </div>
      <div className={styles.title}>
        {i18n.t<string>('adGroupSetupFlow.mainStep.field.pacing')}
      </div>
      <div>:</div>
      <div>
        {i18n.t<string>(`adGroup.pacing.${tiktokAdGroup.pacing.toLowerCase()}`)}
      </div>
    </div>
  );
};

const targetingFormatter = (summaryData: any, tiktokAdGroup: TiktokAdGroup) => {
  return <LimitationTableCell summaryData={summaryData} l2ChannelId={tiktokAdGroup.adgroup_id}/>;
};

const resultsFormatter = (value, _1, _2, formatExtraData: any) => {
  const objective = formatExtraData.objective;
  const hint = objective === L1ObjectObjective.AWARENESS ? 'Reach' : 'Link Clicks';
  return (
    <div className={styles.resultCell}>
      <div className={styles.value}>
        {value === undefined ? 0 : value}
      </div>
      <div className={styles.objective}>
        {hint}
      </div>
    </div>
  );
};

const floatingEditBtnsFormatter = (_1, tiktokAdGroup: TiktokAdGroup, _2, formatExtraData: any): any => {
  const adgroupId: number | string = tiktokAdGroup.adgroup_id;
  const draftId: number | string = _.get(tiktokAdGroup, 'draftId', 0);
  const onDeleteBtnClick = () => formatExtraData.onDeleteBtnClick(adgroupId);
  const editPath = tiktokAdGroup.isDraft ?
    `${formatExtraData.currentUrl}/drafts/${draftId}/edit` :
    `${formatExtraData.currentUrl}/campaigns/${adgroupId}/edit`;
  const editMessage = tiktokAdGroup.isDraft ?
    i18n.t<string>('l2ObjectList.labels.editDraft') :
    i18n.t<string>('adGroupSetupFlow.mainStep.editTitle');
  const canDelete = formatExtraData.canDeleteSelectedAdGroups([adgroupId]);
  const campaignEditPermission = clone(campaignWritePermission).and(inCondition(() => tiktokAdGroup.opt_status !== 'DELETE'));
  const renderEmpty = () => <div/>;
  return (
    <PermissionChecker permissionAware={campaignEditPermission} renderOtherwise={renderEmpty}>
      <TableRowToolBar className={styles.floatingEditArea}>
        <PermissionChecker permissionAware={notSelfServeAdObject(formatExtraData.l1Object)}>
          <IconWithTooltip
            icon={faPencilAlt}
            tooltipProps={{
              id: `tiktokAdGroup-editTip-${adgroupId}-${draftId}`,
              link: editPath,
              tooltip: editMessage
            }}
          />
        </PermissionChecker>
        <IconWithTooltip
          icon={faTrashAlt}
          disabled={!canDelete}
          onClick={onDeleteBtnClick}
          tooltipProps={{
            id: `fbAdGroup-deleteTip-${adgroupId}-${draftId}`,
            tooltip: canDelete ? i18n.t<string>('adGroup.deleteTitle') : i18n.t<string>('adGroup.hints.cannotDelete')
          }}
        />
      </TableRowToolBar>
    </PermissionChecker>
  );
};

const formatters = {
  nameHeaderFormatter,
  nameFormatter,
  stateFormatter,
  deliveryFormatter,
  creativeBindingFormatter,
  optimizationFormatter,
  scheduleFormatter,
  budgetFormatter,
  targetingFormatter,
  resultsFormatter,
  floatingEditBtnsFormatter
};
export default formatters;
