import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import React from 'react';
import i18n from 'i18n';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import Select from 'components/common/Select/Select';
import styles from './doctorTable.module.scss';
import { DoctorTableProps, DoctorTableState, DOCTER_TABLE_TAB } from './DoctorTableModel';
import { Nav } from 'react-bootstrap';
import { SearchBar } from 'components/common/SearchBar';
import { DoctorTableRowExtender } from './DoctorTableRowExtender';

export class DoctorTable extends React.Component<DoctorTableProps, DoctorTableState> {

  handler?: number;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
  }

  componentDidMount () {
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.handler && this.props.model.event.remove(this.handler);
  }

  renderFilter () {
    const filterData = this.props.model.getFilters();
    return (
      <div className={styles.customFilters}>
        {Object.keys(filterData).map(filterKey => {
          const data = filterData[filterKey];
          return (
            <div className={styles.filterListContainer} key={filterKey}>
              <div className={styles.title}>
                {i18n.t<string>(`doctorTable.labels.${filterKey}Title`)}
              </div>
              <Select
                className={`${styles.filterList} ${styles[filterKey]}`}
                options={data.options}
                name={filterKey}
                simpleValue
                value={data.value}
                onChange={data.onSelect}
                placeholder={i18n.t<string>(`doctorTable.placeholders.${filterKey}`)}
              />
            </div>
          );
        })}
      </div>
    );
  }

  renderSpaces = (rowData) => {
    return (
      <DoctorTableRowExtender
        isFiltered={this.state.selectedTab === DOCTER_TABLE_TAB.FILTERED}
        type={this.state.selectedFilterType}
        spaces={rowData.spaces}
      />
    );
  }

  render () {
    const model = this.props.model;
    const selectedTab = model.state.selectedTab;
    return (
      <div className={styles.doctorTable} key={i18n.language}>
        {this.state.loading && <LoadingIndicator/>}
        <div className={styles.filterArea}>
          {this.renderFilter()}
          <SearchBar
            model={{
              placeholder: i18n.t<string>('doctorTable.placeholders.searchbar'),
              search: model.handleOnSearch,
              defaultValue: this.state.searchString
            }}
          />
        </div>
        <Nav
          activeKey={selectedTab}
          onSelect={model.setTab}
        >
          <Nav.Item key={DOCTER_TABLE_TAB.FILTERED}>
            <Nav.Link eventKey={DOCTER_TABLE_TAB.FILTERED}>{i18n.t<string>('doctorTable.tabs.filtered')}</Nav.Link>
          </Nav.Item>
          <Nav.Item key={DOCTER_TABLE_TAB.REMAIN}>
            <Nav.Link eventKey={DOCTER_TABLE_TAB.REMAIN}>{i18n.t<string>('doctorTable.tabs.remained')}</Nav.Link>
          </Nav.Item>
        </Nav>
        <StickableBootstrapTable
          keyField='key'
          data={this.state.tableData ? this.state.tableData : []}
          columns={model.getColumns()}
          noDataIndication={i18n.t<string>('common.labels.noData')}
          expandRow={{
            onlyOneExpanding: true,
            renderer: this.renderSpaces
          }}
        />
      </div>
    );
  }
}
