import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import i18n from 'i18n';
import styles from './outdoorMonitor.module.scss';
import _ from 'lodash';
import config from 'config';
import { AgencyManager, DefaultAgencyManager, LocaleMeta } from 'core';
import { SelectOptions } from 'components/common/commonType';
import { DefaultOutdoorBkManager, OutdoorBkManager } from 'core/outdoorBK/OutdoorBkManager';
import { OutdoorBkRecordType } from 'core/outdoorBK/OutdoorBkRecord';
import { nameFormatter, impressionSecondFormatter } from './OutdoorMonitorTableFormatters';
import { useCallAPI } from 'hooks/useCallAPI';

export enum TIMEUNIT {
  DAY = 'day',
  HOUR = 'hour'
}

const CHART_TOTAL_COLOR = config.reportMetric1Color;
const CHART_FORMAL_COLOR = config.reportMetric2Color;
const CHART_PROPOSAL_COLOR = '#615f67';

function getChartDataSet (label, data, color) {
  return {
    label,
    data,
    fill: false,
    borderColor: color,
    backgroundColor: color,
    lineTension: 0,
    borderWidth: 1
  };
}

const defaultOutdoorBkManager: OutdoorBkManager = new DefaultOutdoorBkManager();
const defaultAgencyManager: AgencyManager = new DefaultAgencyManager();
const initDayRange = {
  from: moment().startOf('day').format('YYYY-MM-DD'),
  to: moment().add(29, 'days').endOf('day').format('YYYY-MM-DD')
};
const initHourRange = {
  from: moment().startOf('day').format('YYYY-MM-DD HH:mm'),
  to: moment().add(1, 'day').endOf('day').format('YYYY-MM-DD HH:mm')
};

export const useOutdoorMonitorModel = (
  agencyId: number | null | undefined,
  localeMeta?: LocaleMeta,
  outdoorBkManager: OutdoorBkManager = defaultOutdoorBkManager,
  agencyManager: AgencyManager = defaultAgencyManager
) => {

  const [timeUnit, setTimeUnit] = useState(TIMEUNIT.DAY);
  const [selectAgencyOption, setSelectAgencyOption] = useState<SelectOptions | undefined>(undefined);
  const [dayRangeError, setDayRangeError] = useState<string | undefined>(undefined);
  const timeUnitOptions = useMemo(() => createSelectOptionsFromEnum(TIMEUNIT, 'outdoorMonitor.labels.'), []);
  const [search, setSearch] = useState<string>('');
  const [dayRange, setDayRange] = useState(initDayRange);
  const [reportData, setReportData] = useState<any>({
    total: {},
    remaining: {},
    orders: []
  });
  const { loading, callAPIs } = useCallAPI();

  const onDayRangeChange = (from?: string, to?: string) => {
    if (!from || !to) {
      return;
    }
    setDayRange({ from, to });
    if (timeUnit === TIMEUNIT.DAY && moment(from).isBefore(moment(to).subtract(29, 'days'))) {
      setDayRangeError(i18n.t<string>('outdoorMonitor.labels.dayMaximumError'));
    } else if (timeUnit === TIMEUNIT.HOUR && moment(from).isBefore(moment(to).subtract(48, 'hours'))) {
      setDayRangeError(i18n.t<string>('outdoorMonitor.labels.hourMaximumError'));
    } else {
      setDayRangeError(undefined);
    }
  };

  const onTimeUnitChange = (timeUnit: string | number) => {
    setTimeUnit(timeUnit.toString() === TIMEUNIT.DAY ? TIMEUNIT.DAY : TIMEUNIT.HOUR);
    setDayRangeError(undefined);
    if (timeUnit.toString() === TIMEUNIT.DAY) {
      setDayRange(initDayRange);
    } else {
      setDayRange(initHourRange);
    }
  };

  const fillDateRecord = useCallback((date, data) => {
    if (!(date in data)) {
      data[date] = 0;
    }
  }, []);

  const getOutdoorData = useCallback(async (timeUnit: TIMEUNIT, dayRange, agencyOption?: SelectOptions) => {
    callAPIs([
      agencyOption ?
        async () => outdoorBkManager.getOutdoorBkData(
          agencyOption.value,
          timeUnit,
          dayRange.from,
          dayRange.to,
          _.get(agencyOption, 'extra.timeZone', '+08:00')
        ) :
        () => ({
          total: {},
          remaining: {},
          orders: []
        })
    ], data => {
      const start = moment(dayRange.from).startOf(timeUnit);
      const to = moment(dayRange.to).endOf(timeUnit);
      let current = start;
      const format = timeUnit === TIMEUNIT.DAY ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm';
      while (current.isBefore(to)) {
        const date = current.format(format);
        fillDateRecord(date, data.total);
        fillDateRecord(date, data.remaining);
        data.orders.forEach(data => fillDateRecord(date, data));
        current = current.add(1, timeUnit);
      }
      setReportData(data);
    });
  }, [fillDateRecord, outdoorBkManager, callAPIs]);

  const [agencyOptions, setAgencyOptions] = useState<SelectOptions[]>([]);

  useEffect(() => {
    callAPIs([
      (!agencyId || !localeMeta) ?
        agencyManager.getAgenciesOptions.bind(agencyManager, 'company.outdoorAgency', ['timeZone']) :
        () => [{ label: agencyId, value: agencyId, extra: { timeZone: localeMeta.timezone } }]
    ], agencyOptions => {
      getOutdoorData(TIMEUNIT.DAY, initDayRange, agencyOptions[0]);
      setAgencyOptions(agencyOptions);
      setSelectAgencyOption(agencyOptions[0]);
    });
  }, [getOutdoorData, agencyManager, agencyId, localeMeta, callAPIs]);

  const getColumns = () => {
    const start = moment(dayRange.from).startOf(timeUnit);
    const to = moment(dayRange.to).endOf(timeUnit);
    let current = start;
    const columns: any[] = [{
      text: '',
      dataField: 'name',
      headerClasses: () => styles.firstColumn,
      formatter: nameFormatter
    }];
    const format = timeUnit === TIMEUNIT.DAY ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm';
    while (current.isBefore(to)) {
      columns.push({
        text: current.format(format),
        dataField: current.format(format),
        headerClasses: () => styles.date,
        formatter: impressionSecondFormatter
      });
      current = current.add(1, timeUnit);
    }
    return columns;
  };
  const [columns, setColumns] = useState<any>(getColumns());

  const chartData = useMemo(() => {
    const totalData = { ...reportData.total };
    const chartData: {
      formal: { [key: string]: number }
      proposal: { [key: string]: number }
    } = reportData.orders.reduce((
      acc,
      data
    ) => {
      const type = data.type.toLowerCase();
      const dateData = _.omit(data, ['id', 'name', 'type']);
      Object.keys(dateData).forEach(key => {
        let value = _.defaultTo(acc[type][key], 0);
        value += dateData[key];
        acc[type][key] = value;
      });
      return acc;
    }, {
      formal: {},
      proposal: {}
    });
    const proposalData: number[] = Object.values(_.defaultTo(chartData.proposal, {}));
    let formalData: number[] =
      Object.values(_.defaultTo(chartData.formal, {})).map(
        (value, index) => proposalData[index] + value
      );
    const labels: string[] = Object.keys(totalData);
    const data = {
      labels,
      datasets: [
        getChartDataSet(OutdoorBkRecordType.TOTAL.toString().toLowerCase(), Object.values(totalData), CHART_TOTAL_COLOR),
        getChartDataSet(OutdoorBkRecordType.FORMAL.toString().toLowerCase(), Object.values(formalData), CHART_FORMAL_COLOR),
        getChartDataSet(OutdoorBkRecordType.PROPOSAL.toString().toLowerCase(), Object.values(proposalData), CHART_PROPOSAL_COLOR)
      ]
    };
    const grid = { borderDash: [5, 2] };
    const tickCallback = (tick, _1, tickList) => {
      const tickLength = Math.max(15, 100 / tickList.length);
      const label = labels[tick];
      return label.length > tickLength ? `${label.substring(0, tickLength)}...` : label;
    };
    const tooltipLabel = (tooltipItems) => {
      const type = tooltipItems.dataset.label;
      return chartData[type] ?
        `${i18n.t<string>(`outdoorMonitor.labels.${type}`)}: ${chartData[type][tooltipItems.label]}` :
        `${i18n.t<string>(`outdoorMonitor.labels.${type}`)}: ${tooltipItems.formattedValue}`;
    };
    return {
      data,
      options: {
        layout: {
          padding: {
            left: 10,
            right: 30
          }
        },
        maintainAspectRatio: false,
        animation: {
          duration: 0
        },
        scales: {
          x: {
            grid,
            ticks: {
              maxRotation: 0,
              font: {
                size: 12
              },
              callback: tickCallback
            }
          },
          yAxes: {
            grid,
            type: 'linear' as const,
            ticks: {
              font: {
                size: 12
              },
              beginAtZero: true,
              maxTicksLimit: 5
            }
          }
        },
        plugins: {
          datalabels: {
            display: false
          },
          tooltip: {
            mode: 'index' as const,
            intersect: false,
            callbacks: {
              label: tooltipLabel
            }
          },
          legend: {
            position: 'bottom' as const,
            labels: {
              boxWidth: 15,
              generateLabels: chart => chart.data.datasets.map(dataset => ({
                text: i18n.t<string>(`outdoorMonitor.labels.${dataset.label}`),
                strokeStyle: dataset.borderColor,
                fillStyle: dataset.borderColor
              }))
            }
          }
        }
      }
    };
  }, [reportData]);

  const tableData = useMemo(() => {
    const total = reportData.total;
    const remain = reportData.remaining;
    const orders = reportData.orders;
    return [{
      ...total, id: 0, name: i18n.t<string>('outdoorMonitor.labels.total')
    }, {
      ...remain, id: 1, name: i18n.t<string>('outdoorMonitor.labels.remain')
    }, ...orders.filter(order => order.name.toLowerCase().includes(search.toLowerCase()))];
  }, [reportData, search]);

  const onSearchBtnClick = () => {
    getOutdoorData(timeUnit, dayRange, selectAgencyOption);
    setColumns(getColumns());
  };

  return {
    loading,
    timeUnit,
    timeUnitOptions,
    from: dayRange.from,
    to: dayRange.to,
    dayRangeError,
    tableData,
    columns,
    chartData,
    agencyOptions,
    search,
    selectAgencyOption,
    setSelectAgencyOption,
    setSearch,
    onDayRangeChange,
    onSearchBtnClick,
    onTimeUnitChange
  };
};
