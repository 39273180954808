import { connect } from 'formik';
import _ from 'lodash';
import React from 'react';
import { MessageCampaignInfo } from 'components/MessageCampaignBasicForm/MessageCampaignInfo';
import MessageCampaignOptimize from 'components/MessageCampaignBasicForm/MessageCampaignOptimize';
import CoreContext from 'contexts/coreContext';
import { BidStrategy } from 'core/l2Object/L2Object';
import { DailyBudgetPlan } from 'core/rtbCampaign/RtbCampaign';

export class MessageCampaignBasicForm extends React.Component<any> {
  handler: number;
  constructor (props) {
    super(props);
    this.handleGetRemainBudget = this.handleGetRemainBudget.bind(this);
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state, () => {
        const { dailyBudgetType } = this.props.model.state;
        if (dailyBudgetType === _.get(this.props, 'formik.values.dailyBudgetType')) {
          return;
        }
        let dailyTargetBudget: any = undefined;
        if (dailyBudgetType === DailyBudgetPlan.DAILY.toString()) {
          dailyTargetBudget = '0';
        }

        this.props.formik.setFieldValue('dailyTargetBudget', dailyTargetBudget);
      });
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentDidMount () {
    let dailyTargetBudget = _.get(this.props, 'formik.values.dailyTargetBudget');
    if (dailyTargetBudget === null) {
      dailyTargetBudget = undefined;
    }
    // wait for formik didMount flag set to true
    setTimeout(() => {
      this.props.formik.setFieldValue('dailyTargetBudget', dailyTargetBudget);
    }, 0);
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  handleOptimizeChanged = (newOptimize) => {
    if (this.props.model.showFrequencyControl(newOptimize)) {
      this.props.formik.setFieldValue('frequency', {
        intervalDays: 1,
        maxFrequency: 5
      });
    } else {
      this.props.formik.setFieldValue('frequency', undefined);
    }
  }

  handleChangePriceModel = (model) => {
    this.props.model.setCurrentPriceModel(model);
    this.props.model.onPriceModelChangeCallback(model, this.props.formik.values);
  }

  handleGetRemainBudget () {
    const campaignBudget = _.get(this.props, 'formik.values.budget', '');
    return this.props.model.getRemainBudget(campaignBudget);
  }

  handleChagneOrderPrice = (orderPrice) => {
    const formikValue = _.get(this.props, 'formik.values');
    const bidStrategy = _.get(formikValue, 'bidStrategy');
    if (bidStrategy === BidStrategy.LOWEST_COST_WITHOUT_CAP) {
      return;
    }
  }

  render () {
    const {
      showOptimizeSection,
      optimizeAddonEnable,
      getBidFloor
    } = this.props.model;

    const formikValue = _.get(this.props, 'formik.values');

    this.props.model.setFormikValue(formikValue);
    const priceModel = _.get(formikValue, 'priceModel');
    const optimize = _.get(formikValue, 'optimize');
    const remainBudget = this.handleGetRemainBudget();
    const bidFloor = getBidFloor(optimize);
    const orderPriceMinimum = optimizeAddonEnable ? getBidFloor(priceModel) : bidFloor;
    return (
      <CoreContext.Consumer>
        {(coreContext) => {
          const renderCampaignInfo = () => {
            return (
              <MessageCampaignInfo
                model={this.props.model}
                formik={this.props.formik}
                remainBudget={remainBudget}
                orderPriceMinimum={orderPriceMinimum}
                onChangeOrderPrice={this.handleChagneOrderPrice}
                onChangePriceModel={this.handleChangePriceModel}
              />
            );
          };
          const renderCampaignOptimize = () => {

            if (!showOptimizeSection) {
              return undefined;
            }

            return (
              <MessageCampaignOptimize
                model={this.props.model}
                priceModel={priceModel}
                optimize={optimize}
                onOptimizeChanged={this.handleOptimizeChanged}
              />
            );
          };
          return (
            <div>
              {renderCampaignInfo()}
              {renderCampaignOptimize()}
            </div>
          );
        }}
      </CoreContext.Consumer>
    );
  }
}

export default connect(MessageCampaignBasicForm);
