import client, { clientWithoutErrorHandler } from './RestClient';
import { Keyword } from 'core/limitation/Keyword';
import { createQueryString } from 'helper/KeywordHelper';
import { SavedTargeting } from 'core/limitation/Limitation';
import _ from 'lodash';
import { OrderType } from 'core/order/Order';
import { AxiosInstance } from 'axios';

export interface LimitationWebService {
  keywordEstimate (request: Keyword): Promise<number>;
  getAgencyLimitationPreSet (type: string): Promise<Array<any>>;
  categoriesUnionCount (categories: Array<string>, lang: string): Promise<number>;
  getSavedTargetings (advertiserId: number, channel: string, adsOrderType: OrderType): Promise<SavedTargeting[]>;
  createSavedTargeting (savedTargeting: Partial<SavedTargeting>, adsOrderType: OrderType): Promise<SavedTargeting>;
  updateSavedTargeting (savedTargeting: Partial<SavedTargeting>, adsOrderType: OrderType): Promise<SavedTargeting>;
  deleteSavedTargeting (id: number): Promise<void>;
}

export class RestfulLimitationWebService implements LimitationWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async keywordEstimate (request: Keyword): Promise<number> {
    const queryString = createQueryString(
      request.firstQueryParameters,
      request.secondQueryParameters
    );

    try {
      const rest = await this.restClient.post('/v2/keyword/estimate', {
        query: queryString,
        lang: request.language.toUpperCase()
      });
      return rest.data;
    } catch (e) {
      console.error(e);
      return Promise.resolve(0);
    }
  }

  async getAgencyLimitationPreSet (type: string): Promise<Array<Object>> {
    const resp = await this.restClient.get(`v2/default-limitations/${type}`);
    return resp.data.records;
  }

  async categoriesUnionCount (contentCategory: Array<string>, lang: string): Promise<number> {
    try {
      const resp = await this.restClient.post(`/v2/content-category/estimate`, {
        contentCategory,
        lang: lang.toUpperCase()
      });
      return resp.data;
    } catch (e) {
      console.error(e);
      return Promise.resolve(0);
    }
  }

  async getSavedTargetings (advertiserId: number, channel: string, adsOrderType: OrderType): Promise<SavedTargeting[]> {
    try {
      const resp = await clientWithoutErrorHandler.get(`saved-targeting?advertiser_id=${advertiserId}&channel=${channel}&adsOrderType=${adsOrderType}`);
      return resp.data.records;
    } catch (e) {
      return [];
    }
  }
  async createSavedTargeting (savedTargeting: Partial<SavedTargeting>, adsOrderType: OrderType): Promise<SavedTargeting> {
    return this.restClient.post('saved-targeting', {
      ...savedTargeting,
      adsOrderType
    });
  }
  async updateSavedTargeting (savedTargeting: Partial<SavedTargeting>, adsOrderType: OrderType): Promise<SavedTargeting> {
    return this.restClient.put(`saved-targeting/${savedTargeting.id}`, {
      ..._.omit(savedTargeting, ['id']),
      adsOrderType
    });
  }
  async deleteSavedTargeting (id: number): Promise<void> {
    return this.restClient.delete(`saved-targeting/${id}`);
  }
}
