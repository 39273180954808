import { DefaultCreativeManager } from 'core/creative/CreativeManager';
import { useEffect, useMemo, useState } from 'react';
import { useCallAPI } from 'hooks/useCallAPI';

export const useFBPreview = (
  typeProperties: any,
  formData: any
): {
  previewData: { [key: string]: string | undefined }
} => {
  const fbPreviewTypes = useMemo(() => [
    'VALUE_MOBILE_INTERSTITIAL',
    'VALUE_MOBILE_FEED_STANDARD',
    'VALUE_INSTAGRAM_STORY',
    'VALUE_INSTAGRAM_STANDARD'
  ], []);
  const initPreviewData = fbPreviewTypes.reduce<{ [key: string]: string | undefined }>((acc, type) => {
    acc[type] = undefined;
    return acc;
  }, {});
  const [previewData, setPreviewData] = useState(initPreviewData);
  const { callAPIs } = useCallAPI();

  useEffect(() => {
    const fetchData = async () => {
      if (!formData.type) {
        return;
      }
      try {
        Object.keys(typeProperties).forEach(key => {
          if (typeProperties[key] !== null && key !== 'deepLink') {
            formData[`${key}`] = typeProperties[key];
          }
        });
        const creativeManager = new DefaultCreativeManager();
        callAPIs([creativeManager.getFbCreativePreview.bind(creativeManager, formData)], (previewIframes) => {
          let result = {};
          fbPreviewTypes.forEach(type => {
            result[type] = previewIframes[type];
          });
          setPreviewData(result);
        });
      } catch (e) {}
    };
    fetchData();
  }, [typeProperties, fbPreviewTypes, formData, callAPIs]);

  return {
    previewData
  };
};
