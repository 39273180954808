import { useCallback } from 'react';
import { Route, Switch } from 'react-router-dom';
import { CustomLayoutSetupFlowPage } from './CustomLayoutSetupFlow/CustomLayoutSetupFlowPage';
import { CustomLayoutList } from './CustomLayoutList/CustomLayoutList';

export const CustomLayouts = () => {

  const renderCustomLayoutCreatePage = useCallback(() => {
    return <CustomLayoutSetupFlowPage/>;
  }, []);

  const renderCustomLayoutList = useCallback(() => {
    return <CustomLayoutList />;
  }, []);

  return (
    <Switch>
      <Route
        path={'/custom-layouts/new'}
        render={renderCustomLayoutCreatePage}
      />
      <Route
        render={renderCustomLayoutList}
      />
    </Switch>
  );
};
