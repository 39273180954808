import { AxiosInstance } from 'axios';
import client from './RestClient';
import { CustomLayout, CustomLayoutWithPagination, CustomLayoutDeviceType, CustomLayoutType, Macro } from 'core/customLayout/CustomLayout';
import _ from 'lodash';
import { Pageable } from './Pageable';

export type PrevewValue = string | number | { file?: File, url?: string};

export type CustomLayoutDTO = {
  macros: {[key: string]: Macro},
  name: string,
  previewUrl: string,
  layoutId: string,
  type: CustomLayoutType,
  device: CustomLayoutDeviceType
};

export type CustomLayoutCreateDTO = {
  sessionId: string
} & CustomLayoutDTO;

export interface CustomLayoutWebService {
  uploadTemplate: (file: File) => Promise<{
    sessionId: string,
    macros: string[]
  }>;
  getPreviewUrl: (sessionId: string, previewData: {[key: string]: string}) => Promise<string>;
  createCustomLayout: (payload: CustomLayoutCreateDTO) => Promise<void>;
  getCustomLayouts: (search?: string, status?: number[], type?: string[], pageable?: Pageable) => Promise<CustomLayoutWithPagination>;
  deleteCustomLayout: (id: number) => Promise<void>;
}

function wrapCustomLayout (json: any): CustomLayout {
  return {
    id: _.get(json, 'id'),
    name: _.get(json, 'name'),
    layoutId: _.get(json, 'layoutId'),
    previewUrl: _.get(json, 'previewUrl'),
    device: _.get(json, 'device'),
    type: _.get(json, 'type'),
    macros: _.get(json, 'macros', {}),
    status: _.get(json, 'status')
  };
}

export class RestfulCustomLayoutWebService implements CustomLayoutWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async uploadTemplate (file: File) {
    const formData = new FormData();
    formData.append('file', file);
    const response = await this.restClient.post(
      '/v2/custom-layouts/parser-custom-layout-zip',
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      }
    );
    return response.data;
  }

  async getPreviewUrl (sessionId: string, previewData: {[key: string]: string}) {
    const response = await this.restClient.post(
      `/v2/custom-layouts/preview/${sessionId}`,
      previewData
    );
    return response.data;
  }

  async createCustomLayout (payload: CustomLayoutCreateDTO) {
    await this.restClient.post(
      '/v2/custom-layouts',
      payload
    );
  }

  async getCustomLayouts (search: string = '', status: number[] = [], type: string[] = [], pageable?: Pageable) {
    let url = '';
    let sort = _.defaultTo(pageable?.sort, 'id');
    let direction = _.defaultTo(pageable?.direction, 'desc');
    if (!_.isNil(pageable)) {
      let { page, sizePerPage } = pageable;
      url = `/v2/custom-layouts?page=${page}&size=${sizePerPage}&sort=${sort},${direction}`;
    } else {
      url = `/v2/custom-layouts?page=${1}&sort=${sort},${direction}`;
    }
    if (search !== '') {
      url = url + `&search=${encodeURIComponent(search)}`;
    }
    if (!_.isEmpty(status)) {
      url = url + `&status=${status.join(',')}`;
    }
    if (!_.isEmpty(type)) {
      url = url + `&type=${type.join(',')}`;
    }
    const response = await this.restClient.get(url);
    const records = _.defaultTo(response.data.records, []).flatMap((json: any) => wrapCustomLayout(json));
    return {
      pagination: response.data.pagination,
      customLayouts: records
    };
  }

  async deleteCustomLayout (id: number): Promise<void> {
    return this.restClient.delete(`/v2/custom-layouts/${id}`);
  }
}
