import { ProductFormValue, ProductGroup } from './ProductGroup';
import { ProductGroupWebService, RestfulProductGroupWebService } from 'ws/ProductGroupWebService';

export interface ProductGroupManager {
  createProductGroup (productGroup: ProductFormValue): Promise<void>;
  updateProductGroup (productGroupId: number | string, productGroup: ProductFormValue): Promise<void>;
  getProductGroup (): Promise<ProductGroup[]>;
  getProductGroupById (groupId: number): Promise<ProductGroup>;
  getCampaignByGroupId (groupId: number);
  deleteProductGroupById (groupId: number): Promise<void>;
}

export class DefaultProductGroupManager implements ProductGroupManager {
  webService: ProductGroupWebService;

  constructor (webService: ProductGroupWebService = new RestfulProductGroupWebService()) {
    this.webService = webService;
  }

  createProductGroup (productGroup: ProductFormValue): Promise<void> {
    return this.webService.createProductGroup(toServerPayload(productGroup));
  }
  updateProductGroup (productGroupId: number | string, productGroup: ProductFormValue): Promise<void> {
    return this.webService.updateProductGroup(productGroupId, toServerPayload(productGroup));
  }
  getProductGroup (): Promise<ProductGroup[]> {
    return this.webService.getProductGroup();
  }
  getProductGroupById (groupId: number): Promise<ProductGroup> {
    return this.webService.getProductGroupById(groupId);
  }
  getCampaignByGroupId (groupId: number) {
    return this.webService.getCampaignByGroupId(groupId);
  }
  deleteProductGroupById (groupId: number): Promise<void> {
    return this.webService.deleteProductGroupById(groupId);
  }

}

function toServerPayload (productGroup: ProductFormValue): ProductFormValue {
  productGroup.groupValues = productGroup.groupValues.map(value => {
    const { limitationName, limitationValue } = value;
    const newName = limitationName.replace(`${limitationValue},`, '');
    return {
      limitationName: newName,
      limitationValue
    };
  });
  return productGroup;
}
