import _ from 'lodash';

import client from './RestClient';
import { Creative, CreativeBasic, CreativeListRecord, CreativeOfCampaign, CreativeStatus, CreativeType } from 'core/creative/Creative';
import { Pageable } from './Pageable';
import { Pagination } from 'core/pagination/Pagination';
import { SelectOptions } from 'components/common/commonType';
import i18n from 'i18next';
import { Limitation } from 'core/limitation/Limitation';
import { L1ObjectChannel } from 'core/l1Object/L1Object';
import { AxiosInstance } from 'axios';
import { AdLogoType } from 'core/adLogo/AdLogo';

export interface CreativeWebService {
  getCreativesByCampaignId (campaignId: number, isDraft: boolean): Promise<Array<CreativeOfCampaign>>;
  getCreativesByAdSetId (adSetId: string, isDraft: boolean): Promise<Array<CreativeOfCampaign>>;
  getCreativesByAdGroupId (adGroupId: string, isDraft: boolean): Promise<CreativeOfCampaign[]>;
  getWatingBindCreatives (payload: WaitingBindGetParams): Promise<CreativeOfCampaign[]>;
  reviewCreatives (creativeIds: Array<number>, adxs: Array<string>): Promise<void>;
  getCreative (creativeId: number): Promise<any>;
  createCreative (creativeformData: FormData): Promise<{
    creativeId: number,
    fbCreativeId: string
  }>;
  createCreativesByJson (creatives: any): Promise<{
    data: any[],
    error: {
      error_message: string | null,
      name: string
    }[]
  } | undefined>;
  updateCreative (creativeformData: FormData): Promise<number>;
  getCreatives (creativeType: CreativeType, advertiserId: number | undefined, pageable: Pageable, search?: string): Promise<CreativesWithPagination>;
  updateCreativeBindingStatus (creatives: Array<number>, isActive: boolean);
  cloneCreatives (creatives: Array<number>): Promise<void>;
  deleteCreative (creativeIds: Array<number>): Promise<void>;
  updateCreativeBindingCampaigns (creativeId: number, campaigns: Array<number>, isActive: boolean);
  getCreativeOptions (from?: string, to?: string): Promise<Array<SelectOptions>>;
  getCreativeSizeOptions (): Promise<Array<SelectOptions>>;
  getPpsLayoutId (): Promise<SelectOptions[]>;
  getPpsThumbnail (creativeId): Promise<string[]>;
  getCreativeNativeToBannerTemplate (): Promise<any>;
  getFbCreativePreview (creativeTypeProperties): Promise<any>;
  getBindingOptions (): Promise<SelectOptions[]>;
  generateRectImgBySquareImg (imageUrl: string): Promise<string>;
  createFBVideoAndThumbnail (videoData: { title: string, url: string }[], advertiserId: number | string, includeThumbnail: boolean): Promise<{
    title: string,
    thumbnail: string,
    video_id: string
  }[]>;
  getFBVideoThumbnail (advertiserId: number | string, videoIds: (number | string)[]): Promise<{
    title: string,
    thumbnail: string,
    video_id: string
  }[]>;
  createTiktokVideo (videoData: { title: string, url: string }, advertiserId: number | string): Promise<{
    video_id: string
  }>;
  getTiktokCreativePreview (creativeTypeProperties): Promise<any>;
  getAdneonCreativePreview (data: any): Promise<any>;
  publicVideoAssetId (videoAssetId: string): Promise<string>;
}

export type WaitingBindGetParams = {
  channel: L1ObjectChannel;
  advertiserId: number;
  billingEvent?: string;
  adType?: string;
  retail?: string;
};

function wrapWaitingBindCreative (json: any): CreativeOfCampaign {

  const vimpression = _.get(json, 'report.viewable', 0);
  const impression = _.get(json, 'report.impres', 0);
  const clicks = _.get(json, 'report.clicks', 0);
  const ctr = impression > 0 ? clicks / impression : 0;
  const vctr = vimpression > 0 ? clicks / vimpression : 0;
  const view = _.get(json, 'view', 0);
  const vtr = view > 0 ? view / impression : 0;
  const creativeType = _.get(json, 'creativeType');
  let size = _.get(json, 'width', 0) + 'X' + _.get(json, 'height', 0);
  if (creativeType === CreativeType.OUTDOOR || creativeType === CreativeType.COMBO) {
    const videoW = _.get(json, 'creativeValues.videoW', 0);
    const videoH = _.get(json, 'creativeValues.videoH', 0);
    const imageW = _.get(json, 'creativeValues.imgW', 0);
    const imageH = _.get(json, 'creativeValues.imgH', 0);
    size = (videoW + imageW) + 'X' + (videoH + imageH);
  }
  const creativeId = _.get(json, 'creativeId');
  const status = _.get(json, 'status');
  const deilivery = _.get(json, 'deilivery');
  const rtbCreativeId = _.get(json, 'rtbCreativeId');
  return {
    report: _.get(json, 'report'),
    rtbCreativeId: rtbCreativeId ? rtbCreativeId : _.get(json, 'creativeId'),
    id: creativeId ? creativeId : _.get(json, 'l3ChannelId'),
    name: _.get(json, 'creativeName'),
    status:  _.get(json, 'creativeState', CreativeStatus.ACTIVE),
    vctr,
    view,
    vtr,
    vimps: vimpression,
    ctr: ctr,
    imps: impression,
    clicks: clicks,
    size,
    creativeType,
    enableStartTime: _.get(json, 'enableStartTime'),
    enableEndTime: _.get(json, 'enableEndTime'),
    approvalStatus: _.get(json, 'approvalStatus'),
    bannerImageUrl: _.get(json, 'bannerImageUrl'),
    creativeValue: _.get(json, 'creativeValues', {}),
    createTime: _.get(json, 'ctime'),
    landingPageUrl: _.get(json, 'landingPageUrl', ''),
    isActiveBinding: _.get(json, 'isActiveBinding', true),
    bindingState: status ? status : _.get(json, 'bindingState', 0),
    tenmaxCategory: _.get(json, 'tenmaxCategory'),
    origTenmaxCategory: _.get(json, 'origTenmaxCategory'),
    shortcutUrl: _.get(json, 'shortcutUrl', ''),
    nativeBanner: wrapNativeBanner(_.get(json, 'nativeBanners')),
    containFBInternalUrl: _.get(json, 'containFBInternalUrl', false),
    effectiveStatus: deilivery ? deilivery : _.get(json, 'effectiveStatus'),
    issuesInfo: _.get(json, 'issuesInfo[0].error_message', ''),
    adReviewFeedback: _.get(json, 'adReviewFeedback', ''),
    l3ChannelId: _.get(json, 'l3ChannelId', ''),
    bannerExtra: _.get(json, 'bannerExtra'),
    previewLink: _.get(json, 'previewLink'),
    ppsLayoutId: _.get(json, 'ppsLayoutId'),
    subLayout: _.get(json, 'subLayout'),
    retail: _.get(json, 'retail'),
    productSet: _.get(json, 'productSet')
  };
}

function wrapAdLogo (json: any) {
  if (!json) {
    return undefined;
  }
  return {
    type: _.get(json, 'type', AdLogoType.NULL),
    link: _.get(json, 'link'),
    image: _.get(json, 'imgUrl') ? {
      url: _.get(json, 'imgUrl')
    } : undefined
  };
}

function wrapCreativeBasic (json: any): CreativeBasic {
  const basic: CreativeBasic = {
    advertiserId: _.get(json, 'advertiserId'),
    agencyId: _.get(json, 'agencyId'),
    bannerUrl: _.get(json, 'bannerUrl'),
    creativeId: _.get(json, 'creativeId'),
    creativeType: _.get(json, 'creativeType'),
    creativeValues: _.get(json, 'creativeValues'),
    landingPageUrl: _.get(json, 'landingPageUrl'),
    name: _.get(json, 'name'),
    tenmaxCategory: _.get(json, 'tenmaxCategory'),
    bannerExtra: _.get(json, 'bannerExtra'),
    bannerImageUrl: _.get(json, 'bannerImageUrl'),
    enableStartTime: _.get(json, 'enableStartTime'),
    enableEndTime: _.get(json, 'enableEndTime'),
    retail: _.get(json, 'retail'),
    productSet: _.get(json, 'productSet'),
    ppsLayoutId: _.get(json, 'ppsLayoutId'),
    subLayout: _.get(json, 'subLayout'),
    adLogo: wrapAdLogo(_.get(json, 'adLogo'))
  };

  const nativeBanner = wrapNativeBanner(_.get(json, 'nativeBanner'));
  if (nativeBanner) {
    basic.nativeBanner = nativeBanner;
  }

  if (basic.creativeType === CreativeType.NATIVE) {
    basic.enableNativeBanner = _.get(json, 'enableNativeBanner', false);
  }

  if (basic.creativeType === CreativeType.OUTDOOR) {
    basic.outdoorType = _.get(json, 'outdoorType', 1);
    basic.duration = _.get(json, 'duration', 30);
  }

  return basic;
}

function wrapNativeBanner (json: any): { [key: string]: any } | undefined {
  if (!json || json.length === 0) {
    return;
  }
  let nativeBannerData = {};
  json.forEach(bannerData => {
    nativeBannerData[`${bannerData.width}x${bannerData.height}`] = bannerData;
  });
  return nativeBannerData;
}

function wrapCreative (json: any): Creative {
  return {
    basic: wrapCreativeBasic(_.get(json, 'creative')),
    limitations: wrapLimitations(
      _.get(json, 'limitations', []),
      _.get(json, 'creative.dealIds')
    )
  };
}

function wrapCreativeListRecord (json: any): CreativeListRecord {
  return {
    advertiserId: _.get(json, 'advertiserId'),
    approvalStatus: _.get(json, 'approvalStatus'),
    bannerImageUrl: _.get(json, 'bannerImageUrl'),
    bannerUrl: _.get(json, 'bannerUrl'),
    bindingCampaign: _.get(json, 'bindingCampaign'),
    bindingCount: _.get(json, 'bindingCount'),
    createTime: _.get(json, 'createTime'),
    creativeId: _.get(json, 'creativeId'),
    creativeState: _.get(json, 'creativeState'),
    creativeType: _.get(json, 'creativeType'),
    creativeValues: _.get(json, 'creativeValues'),
    outdoorType: _.get(json, 'outdoorType'),
    duration: _.get(json, 'duration'),
    ppsLayoutId: _.get(json, 'ppsLayoutId'),
    name: _.get(json, 'name'),
    tenmaxCategory: _.get(json, 'tenmaxCategory'),
    origTenmaxCategory: _.get(json, 'origTenmaxCategory'),
    limitations: wrapLimitations(
      _.get(json, 'limitations', []),
      _.get(json, 'creative.dealIds')
    ),
    bannerExtra: _.get(json, 'bannerExtra'),
    enableNativeBanner: _.get(json, 'enableNativeBanner', false),
    nativeBanner: wrapNativeBanner(_.get(json, 'nativeBanners')),
    enableStartTime: _.get(json, 'enableStartTime'),
    enableEndTime: _.get(json, 'enableEndTime'),
    productSet: _.get(json, 'productSet'),
    retail: _.get(json, 'retail'),
    subLayout: _.get(json, 'subLayout')
  };
}

function wrapCreativeWatingBindList (json: any): Array<CreativeOfCampaign> {
  return _.defaultTo(json, []).flatMap((json: any) =>
    wrapWaitingBindCreative(json)
  );
}

function wrapCreativList (json: any): Array<CreativeListRecord> {
  return _.defaultTo(json, []).flatMap((json: any) =>
    wrapCreativeListRecord(json)
  );
}

function wrapPagination (json: any): Pagination {
  return {
    page: _.get(json, 'page', 1),
    size: _.get(json, 'size', 10),
    totalCount: _.get(json, 'totalCount', 0)
  };
}

function wrapLimitation (json: any): Limitation {
  return {
    op: _.get(json, 'op'),
    value: _.get(json, 'limits', []).map(limitsJson => {
      return _.omitBy({
        label: _.get(limitsJson, 'label'),
        value: _.get(limitsJson, 'value'),
        isGroup: _.get(json, 'isGroup')
      }, _.isUndefined);
    }),
    type: _.get(json, 'type')
  };
}

function wrapLimitations (limitations, dealIds) {
  const limitationMap = {
    inc: {},
    exc: {},
    Preferred: {},
    NonPreferred: {},
    other: {}
  };
  limitations.forEach(limitation => {
    const wrappedLimitation = wrapLimitation(limitation);
    if (limitation.type === 'instl') {
      limitationMap[limitation.op]['adFormat'] = {
        op: limitation.op,
        type: 'adFormat',
        value: [{
          label: 'instl',
          value: 'instl'
        }]
      };
    } else if (limitation.type in limitationMap[limitation.op]) {
      limitationMap[limitation.op][limitation.type].value = _.concat(limitationMap[limitation.op][limitation.type].value, wrappedLimitation.value);
    } else {
      limitationMap[limitation.op][limitation.type] = wrappedLimitation;
    }
  });

  const wrappedLimitations: { [type: string]: Limitation[] } = {
    include: Object.values(limitationMap['inc']),
    exclude: Object.values(limitationMap['exc']),
    preferred: Object.values(limitationMap['Preferred']),
    nonPreferred: Object.values(limitationMap['NonPreferred']),
    other: Object.values(limitationMap['other'])
  };

  wrappedLimitations.other.push({
    op: 'inc',
    value: dealIds ? dealIds.map(dealId => {
      return {
        label: dealId,
        value: dealId
      };
    }) : [],
    type: 'dealId'
  });
  return wrappedLimitations;
}

function wrapPpsLayoutId (json) {
  const key = `ppsLayoutId.${_.get(json, 'layoutId', '').toLowerCase().replace(/-|\s/g, '_')}`;
  const enLabel = _.get(json, 'nameEn');
  const zhLabel = _.get(json, 'nameZh');
  i18n.addResource('en', 'translation', key, enLabel);
  i18n.addResource('zh-TW', 'translation', key, zhLabel);
  const value = _.get(json, 'layoutId');
  return {
    label: value,
    value: value,
    extra: _.get(json, 'subLayout')
  };
}

export type CreativesWithPagination = {
  pagination: Pagination,
  creatives: Array<CreativeListRecord>
};

export class RestfulCreativeWebService implements CreativeWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getCreativesByCampaignId (campaignId: number, isDraft: boolean): Promise<Array<CreativeOfCampaign>> {
    const response = await this.restClient.get(`/v2/rtb-campaigns/${campaignId}/creatives?draft=${isDraft}`);
    return wrapCreativeWatingBindList(response.data.records);
  }

  async getCreativesByAdSetId (adSetId: string, isDraft: boolean): Promise<Array<CreativeOfCampaign>> {
    const response = await this.restClient.get(`/v2/fb/campaigns/${adSetId}/creatives?draft=${isDraft}`);
    const responseData = response.data.records.map(data =>
      Object.assign({ ...data }, {
        ..._.get(data, 'report', {}),
        impression: _.get(data, 'report.impres', 0),
        rtbCreativeId: data.creativeId
      }));
    return wrapCreativeWatingBindList(responseData);
  }

  async getCreativesByAdGroupId (adGroupId: string, isDraft: boolean): Promise<CreativeOfCampaign[]> {
    const response = await this.restClient.get(`/v2/tiktok/adgroups/${adGroupId}/creatives?draft=${isDraft}`);
    const responseData = response.data.records.map(data =>
      Object.assign({ ...data }, {
        clicks: _.get(data, 'report.clicks', 0),
        impression: _.get(data, 'report.impres', 0)
      }));
    return wrapCreativeWatingBindList(responseData);
  }

  async getWatingBindCreatives (payload: WaitingBindGetParams): Promise<CreativeOfCampaign[]> {
    const {
      channel,
      advertiserId,
      billingEvent,
      adType,
      retail
    } = payload;
    let apiApth = `/v2/l3-object/${channel}/waiting-bind?advertiser_id=${advertiserId}`;
    if (billingEvent) {
      apiApth = `${apiApth}&billingEvent=${billingEvent}`;
    }
    if (adType) {
      apiApth = `${apiApth}&ad_type=${adType}`;
    }
    if (retail) {
      apiApth = `${apiApth}&retail=${retail}`;
    }
    const response = await this.restClient.get(apiApth);
    return wrapCreativeWatingBindList(response.data.records).filter(creative => creative.creativeType in CreativeType);
  }

  async reviewCreatives (creativeIds: Array<number>, adxs: Array<string>): Promise<void> {
    return this.restClient.post(`/v2/creatives/review`, {
      creativeIds: creativeIds,
      adxs: adxs
    });
  }

  async getCreative (creativeId: number): Promise<Creative> {
    const response = await this.restClient.get(`v2/creatives/${creativeId}`);
    return wrapCreative(response.data);
  }

  async createCreative (creativeformData: FormData): Promise<{
    creativeId: number,
    fbCreativeId: string
  }> {
    const response = await this.restClient.post('v2/creatives/', creativeformData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return {
      creativeId: _.get(response.data, 'creative.creativeId', 0),
      fbCreativeId: _.get(response.data, 'creative.fbCreativeId', 0)
    };
  }

  async createCreativesByJson (creatives: any): Promise<{
    data: any[],
    error: {
      error_message: string | null,
      name: string
    }[]
  } | undefined> {
    const response = await this.restClient.post('v2/creatives/batch-create', creatives);
    return response.data;
  }

  async updateCreative (creativeformData: FormData): Promise<number> {
    const response = await this.restClient.post(`v2/creatives/${creativeformData.get('creative.id')}`, creativeformData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return _.get(response.data, 'creative.creativeId', 0);
  }

  async getCreatives (creativeType: CreativeType, advertiserId: number | undefined, pageable: Pageable, search?: string): Promise<CreativesWithPagination> {
    let { page, sizePerPage, sort, direction } = pageable;
    direction = direction ? direction : 'desc';
    let apiPath = `/v2/creatives?banner_type=${creativeType}&page=${page}&size=${sizePerPage}&sort=${sort},${direction}`;
    if (advertiserId) {
      apiPath = `/v2/advertisers/${advertiserId}/creatives?banner_type=${creativeType}&page=${page}&size=${sizePerPage}&sort=${sort},${direction}`;
    }
    if (search) {
      apiPath = `${apiPath}&search=${encodeURIComponent(search)}`;
    }
    const response = await this.restClient.get(apiPath);
    return {
      creatives: wrapCreativList(response.data.records),
      pagination: wrapPagination(response.data.pagination)
    };
  }

  async updateCreativeBindingStatus (creatives: Array<number>, isActive: boolean) {
    if (isActive) {
      return this.restClient.post(`/v2/creatives/${creatives.join(',')}/enable-binding-campaigns`);
    }
    return this.restClient.post(`/v2/creatives/${creatives.join(',')}/disable-binding-campaigns`);
  }

  async updateCreativeBindingCampaigns (creativeId: number, campaigns: Array<number>, isActive: boolean) {
    return this.restClient.post(`/v2/creatives/${creativeId}/binding-campaigns`, {
      campaignIds: campaigns,
      active: isActive
    });
  }

  async cloneCreatives (creatives: Array<number>): Promise<void> {
    return this.restClient.post(`/v2/creatives/${creatives.join(',')}/copy`);
  }

  async deleteCreative (creativeIds: Array<number>): Promise<void> {
    return this.restClient.delete(`/v2/creatives?creativeIds=${creativeIds.join(',')}`);
  }

  async getCreativeOptions (from?: string, to?: string): Promise<Array<SelectOptions>> {
    let path = '/v2/creatives/options';
    const params = { from, to };
    const paramsString = Object.keys(params)
      .filter(key => params[key] !== undefined)
      .map(key => `${key}=${params[key]}`)
      .join('&');
    const response = await this.restClient.get(`${path}?${paramsString}`);
    return _.get(response, 'data.records', []);
  }

  async getCreativeSizeOptions (): Promise<Array<SelectOptions>> {
    const response = await this.restClient.get('/v2/creatives/dimensions/options');
    return _.get(response, 'data.records', []);
  }

  async getPpsLayoutId () {
    const response = await this.restClient.get('/v2/creatives/pps-layoutIds');
    return _.get(response, 'data.records', []).map(json => wrapPpsLayoutId(json));
  }

  async getPpsThumbnail (creativeId) {
    const response = await this.restClient.get(`/v2/creatives/${creativeId}/pps-thumb`);
    return _.get(response, 'data.records', []);
  }

  async getCreativeNativeToBannerTemplate () {
    const response = await this.restClient.get('/v2/creatives/native-to-banner/templates');
    return _.get(response, 'data.records', []).filter(url => url.includes('300-250') || url.includes('320-100') || url.includes('300-600'));
  }

  async getFbCreativePreview (creativeTypeProperties): Promise<any> {
    const response = await this.restClient.post('/v2/creatives/preview', creativeTypeProperties);
    return response.data;
  }

  async getBindingOptions (): Promise<SelectOptions[]> {
    const response = await this.restClient.get('/v2/creatives/binding/options');
    return response.data.records;
  }

  async generateRectImgBySquareImg (imageUrl: string): Promise<string> {
    const response = await this.restClient.post(`/v2/images/square-to-rectangle?url=${imageUrl}`);
    return _.get(response.data, 'imageUrl');
  }

  async createFBVideoAndThumbnail (videoData: { title: string, url: string }[], advertiserId: number | string, includeThumbnail: boolean): Promise<any> {
    let path = `/v2/creatives/fb/video/upload?advertiser_id=${advertiserId}`;
    if (includeThumbnail) {
      path = path + '&include_thumbnail=true';
    }
    const response = await this.restClient.post(path, videoData);
    return _.get(response, 'data.records', []);
  }

  async getFBVideoThumbnail (advertiserId: number | string, videoIds: (number | string)[]): Promise<{
    title: string,
    thumbnail: string,
    video_id: string
  }[]> {
    const response = await this.restClient.get(`/v2/creatives/fb/video/thumbnail?video_id=${videoIds.join(',')}&advertiser_id=${advertiserId}`);
    return _.get(response, 'data.records', []);
  }

  async createTiktokVideo (videoData: { title: string, url: string; }, advertiserId: number | string): Promise<{
    video_id: string
  }> {
    let path = `/v2/creatives/tiktok/video/upload?advertiser_id=${advertiserId}`;
    const response = await this.restClient.post(path, videoData);
    return response.data;
  }

  async getTiktokCreativePreview (creativeTypeProperties): Promise<any> {
    const response = await this.restClient.post('/v2/creatives/tiktok/preview', creativeTypeProperties);
    return response.data;
  }

  async getAdneonCreativePreview (data: any): Promise<any> {
    const response = await this.restClient.post('/v2/creatives/adNeon/preview', data);
    return response.data;
  }

  async publicVideoAssetId (videoAssetId: string): Promise<string> {
    const response = await this.restClient.get(`/v2/creatives/publishVideo?assetId=${videoAssetId}`);
    return response.data.videoUrl;
  }
}
