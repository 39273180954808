import { useCallAPI } from 'hooks/useCallAPI';
import { useCallback, useState } from 'react';
import { CustomLayoutFormType } from 'core/customLayout/CustomLayout';

export type CustomLayoutSetupFlowData = {
  sessionId?: string;
  zipFile?: File;
  previewData?: {[key: string]: string};
  previewUrlFromServer?: string;
} & Partial<CustomLayoutFormType>;

export const useCustomLayoutSetupFlowPageModel = () => {

  const { loading } = useCallAPI();
  const [redirectPath, setRedirectPath] = useState<string>();
  const [finished, setFinished] = useState<boolean>(false);
  const [customLayoutSetupFlowData, setCustomLayoutSetupFlowData] = useState<CustomLayoutSetupFlowData>({});

  const onPromptCancel = useCallback(() => {
    setRedirectPath(undefined);
  }, []);

  const onSubmit = useCallback(() => {
    setFinished(true);
    setRedirectPath('/custom-layouts');
  }, []);

  const onCancel = useCallback(() => {
    setRedirectPath('/custom-layouts');
  }, []);

  return {
    loading,
    finished,
    redirectPath,
    customLayoutSetupFlowData,
    onSubmit,
    onCancel,
    onPromptCancel,
    setCustomLayoutSetupFlowData
  };
};
