import Cookies from 'universal-cookie';

const cookies = new Cookies();

export interface UserDefaults {
  language: string;
}

export class CookieUserDefaults implements UserDefaults {
  get language (): string {
    return cookies.get('language') || navigator.language || 'zh-TW';
  }

  set language (language: string) {
    cookies.set('language', language, { path: '/' });
  }
}

const defaults = new CookieUserDefaults();

export default defaults;
