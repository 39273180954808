import { LoadingIndicator } from 'components/common/LoadingIndicator/LoadingIndicator';
import React from 'react';
import { Nav } from 'react-bootstrap';

import { SudoModelProps, SudoModelState, ACCOUNT_TYPE, SudoListColumns } from './SudoModel';
import styles from './sudo.module.scss';
import i18n from 'i18n';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { SearchBar } from 'components/common/SearchBar';

export class Sudo extends React.Component<SudoModelProps, SudoModelState> {
  model: any;
  handler: number;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
    this.model = this.props.model;
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    this.props.model.resetState();
    this.props.model.getActiveAccount();
  }

  componentDidUpdate (prevProps: SudoModelProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.getActiveAccount();
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
  }

  handleTableChange = async (type: string, props) => {
    if (type === 'sort') {
      await this.model.onSort(props.sortField, props.sortOrder);
      return;
    }
    await this.model.onChangePage(props.page);
  }

  render () {
    const model = this.model;
    const columns = model.columns;
    return (
      <div>
        {this.state.loading && <LoadingIndicator />}
        <div className={styles.searchBarContainer}>
          <SearchBar model={this.props.model} />
        </div>
        <Nav activeKey={this.state.activeNav} onSelect={this.props.model.changeTab}>
          <Nav.Item key={ACCOUNT_TYPE.PLATFORM}>
            <Nav.Link eventKey={ACCOUNT_TYPE.PLATFORM}>{i18n.t<string>('sudo.label.platformAccounts')}</Nav.Link>
          </Nav.Item>
          <Nav.Item key={ACCOUNT_TYPE.OEM}>
            <Nav.Link eventKey={ACCOUNT_TYPE.OEM}>{i18n.t<string>('sudo.label.oemAccounts')}</Nav.Link>
          </Nav.Item>
        </Nav>
        <div className={styles.accountListContainer}>
          <StickableBootstrapTable
            stickFirstColumn
            hover
            remote
            keyField={SudoListColumns.ACCOUNT_ID}
            data={model.accounts}
            columns={columns}
            defaultSorted={model.defaultSorts}
            noDataIndication={i18n.t<string>(model.noDataDescription)}
            onTableChange={this.handleTableChange}
            pagination={{
              page: model.state.page,
              sizePerPage: model.state.size,
              totalSize: model.state.totalCount
            }}
          />
        </div>
      </div>
    );
  }
}
