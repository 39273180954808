import { AddonFeatureManager } from 'core';
import { RtbCampaignSetupFlowPageModel, EditRtbCampaignDraftSetupFlowPageModel } from './RtbCampaignSetupFlow/RtbCampaignSetupFlowPageModel';
import { SelectOptions } from 'components/common/commonType';
import { Order } from 'core/order/Order';
import { L1Object, L1ObjectChannel } from 'core/l1Object/L1Object';
import { RtbCampaignBasic } from 'core/rtbCampaign/RtbCampaign';

export interface RtbCampaignDraftsModel {
  readonly addonFeatureManager: AddonFeatureManager;
  readonly channel: L1ObjectChannel;
  getEdtiCampaignDraftModel (draftId: string, localeMeta): RtbCampaignSetupFlowPageModel;
  setOrder (order: Order): void;
}

export type RtbCampaignDraftsProps = {
  readonly model: RtbCampaignDraftsModel;
};

export class DefaultRtbCampaignDraftsModel implements RtbCampaignDraftsModel {

  addonFeatureManager: AddonFeatureManager;
  advertisers?: Array<SelectOptions>;
  dratEditModel?: EditRtbCampaignDraftSetupFlowPageModel;

  order: Order;
  channel: L1ObjectChannel;

  constructor (order: Order, addonFeatureManager: AddonFeatureManager, private l1Object: L1Object, private campaignList?: RtbCampaignBasic[]) {
    this.order = order;
    this.channel = l1Object.channel;
    this.addonFeatureManager = addonFeatureManager;
  }

  setOrder (order: Order) {
    this.order = order;
    this.dratEditModel && this.dratEditModel.setOrder(order);
  }

  getEdtiCampaignDraftModel (draftId: string, localeMeta): RtbCampaignSetupFlowPageModel {
    if (this.dratEditModel && this.dratEditModel.campaignId === draftId) {
      return this.dratEditModel;
    }
    this.dratEditModel = new EditRtbCampaignDraftSetupFlowPageModel(
      draftId,
      this.order,
      this.addonFeatureManager,
      this.l1Object,
      localeMeta,
      this.campaignList ? this.campaignList : []
    );
    return this.dratEditModel;
  }
}
