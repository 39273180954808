import React, { useState, useMemo } from 'react';
import styles from './doctorTableRowExtender.module.scss';
import i18n from 'i18n';
import { defaultItemSetting } from 'components/CampaignLimitation/SelectItemComponent';
import { SelectComponent } from 'components/CampaignLimitation/SelectComponent';
import renderReason from './SpaceReasonFormatter';
import { SingleSelectComponentModel } from 'components/CampaignLimitation/SelectComponent/SingleSelectComponentModel';

export const DoctorTableRowExtender: React.FC<{isFiltered: boolean, type: string, spaces: any[]}> = props => {

  const options = useMemo(() => {
    return props.spaces.map(space => ({
      value: space.spaceId,
      label: space.spaceName,
      ...space
    }));
  }, [props.spaces]);
  const [space, setSpace] = useState<any | undefined>(undefined);
  const selectComponentModel = useMemo(() => {
    const onSpaceChanged = (newSpaces: any[]) => {
      if (props.isFiltered) {
        setSpace(newSpaces.pop());
      }
    };
    return new SingleSelectComponentModel(
      'name',
      false,
      {
        ...defaultItemSetting(),
        readonly: !props.isFiltered
      },
      i18n.t<string>('doctorTableRowExtender.placeholders.spaceList'),
      onSpaceChanged,
      options,
      [],
      undefined
    );
  }, [props.isFiltered, options]);

  return (
    <div className={styles.doctorTableExtender}>
      <div className={styles.spaceList}>
        <SelectComponent
          model={selectComponentModel}
        />
      </div>
      {
        props.isFiltered &&
        <div className={styles.reasonContainer}>
          <div className={styles.reasonTitle}>
            {i18n.t<string>('doctorTableRowExtender.labels.reasonTitle')}
          </div>
          <div className={styles.reasonContent}>
            {
              space && space.type ?
                renderReason(space) :
                <div className={styles.reasonPlaceholder}>
                  {i18n.t<string>('doctorTableRowExtender.placeholders.banReason')}
                </div>
            }
          </div>
        </div>
      }
    </div>
  );
};
