import React from 'react';
import Select from 'components/common/Select/Select';
import { FormControl } from 'react-bootstrap';
import { SelectOptions } from 'components/common/commonType';
import styles from './selectInput.module.scss';

export type SelectInputValue = {
  option: string | number,
  inputValue: string
};

export type SelectInputProps = {
  className?: string,
  value: SelectInputValue,
  options: Array<SelectOptions>,
  onChange: (newValue: SelectInputValue) => void
};

export class SelectInput extends React.Component<SelectInputProps> {

  onSelectChange = (option) => {
    this.props.onChange({
      ...this.props.value,
      option
    });
  }

  onInputChange = (event) => {
    this.props.onChange({
      ...this.props.value,
      inputValue: event.target.value
    });
  }

  render () {
    return (
      <div className={`${styles.selectInput} ${this.props.className}`}>
        <Select
          className={styles.selection}
          options={this.props.options}
          name='selectInputOptions'
          simpleValue
          value={this.props.value.option}
          onChange={this.onSelectChange}
        />
        <FormControl
          value={this.props.value.inputValue}
          onChange={this.onInputChange}
        />
      </div>
    );
  }
}
