import styles from './title.module.scss';
import classNames from 'classnames/bind';
const cx = classNames.bind(styles);

export const Title: React.FC<{className?: string} & React.PropsWithChildren> = ({
  children,
  className
}) => {
  const titleClass = cx(styles.title, className);
  return (
    <div className={titleClass}>
      {children}
    </div>
  );
};
