import React from 'react';
import i18n from 'i18n';

import { AgencyMemberTabModel, AgencyMemberTabProps, AgencyMemberTabState } from './AgencyMemberTabModel';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { AgencyMemberList } from 'components/AgencyDetail/AgencyMemberList/AgencyMemberList';
import { DefaultAgencyMemberListModel } from 'components/AgencyDetail/AgencyMemberList/AgencyMemberListModel';
import style from './agencyMemberTab.module.scss';
import { ActorPermissionForm } from 'components/ActorPermissionForm';
import { hasFuncs } from 'core/permission/PermissionDSL';
import { Permission } from 'core/auth/Permission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { Modal } from 'components/common/Modal/Modal';

export class AgencyMemberTab extends React.Component<AgencyMemberTabProps, AgencyMemberTabState> {

  handler: number;
  model: AgencyMemberTabModel;

  constructor (props: any) {
    super(props);
    this.state = this.props.model.state;
    this.model = this.props.model;
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    this.model.fetch();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.model = this.props.model;
      this.props.model.fetch();
    }
  }

  componentWillUnmount () {
    this.model.event.remove(this.handler);
  }

  renderModal (modalData) {
    const model = this.props.model;
    const ModalContent: typeof React.Component = modalData.component;
    return (
      <Modal
        fullScreen={modalData.fullScreen}
        title={modalData.title}
        primaryButton={modalData.confirmBtnData && {
          title: modalData.confirmBtnData.title,
          callback: modalData.confirmBtnData.callback
        }}
        secondaryButton={{
          title: i18n.t<string>('common.buttons.close'),
          callback: model.hideModal
        }}
        dismiss={model.hideModal}
        animation={false}
      >
        {ModalContent ? <ModalContent {...modalData.componentProps} /> : modalData.message}
      </Modal>
    );
  }

  render () {
    const model = this.model;
    const permissionFormModel = model.permissionFormModel;
    return (
      <div className={style.agencyMemberTab}>
        {this.state.loading && <LoadingIndicator />}
        {this.state.modalData && this.renderModal(model.state.modalData)}
        <div className={style.operationPanel}>
          <PermissionChecker permissionAware={hasFuncs(Permission.AGENCY_MEMBER_WRITE)}>
            <button className={'btn btn-tertiary btn-sm'} onClick={model.addRole}>
              {i18n.t<string>('agency.member.addMember')}
            </button>
          </PermissionChecker>
          <PermissionChecker permissionAware={hasFuncs(Permission.ACCOUNT_WRITE)}>
            <button className={'btn btn-tertiary btn-sm'} onClick={model.addAccount}>
              {i18n.t<string>('agency.member.addAccount')}
            </button>
          </PermissionChecker>
        </div>
        <div className={style.listContainer}>
          <AgencyMemberList agencyId={model.agencyId} model={new DefaultAgencyMemberListModel(this.state.members ? this.state.members : [], model.roleActions)} />
        </div>
        {permissionFormModel && <ActorPermissionForm model={permissionFormModel} />}
      </div>
    );
  }
}
