import { faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import IconWithTooltip from 'components/IconWithTooltip/IconWithTooltip';
import TableRowToolBar from 'containers/TableRowToolBar/TableRowToolBar';
import React from 'react';
import { Link } from 'react-router-dom';
import i18n from 'i18n';
import styles from './productSegmentList.module.scss';
import { ProductSegmentExecStatus, ProductSegmentRecord } from 'core/product/ProductSegment';

const nameFormatter = (id: any, productSegmentRecord: ProductSegmentRecord, _2, formatExtraData: any): any => {
  return (
    <div className={`${styles.nameCell}`}>
      <Link to={`/product-segments/${formatExtraData.retail}/${id}/edit`} className={styles.name}>
        {productSegmentRecord.name}
      </Link>
      <div className={styles.id}>
        {'ID: ' + id}
      </div>
    </div>
  );
};

const execStatusFormatter = (execStatus: any): any => {
  return i18n.t<string>(`productSegment.execStatus.${ProductSegmentExecStatus[execStatus].toLowerCase()}`);
};

const floatingEditBtnsFormatter = (_1, productSegmentRecord: ProductSegmentRecord, _2, formatExtraData: any): any => {
  const onArchiveBtnClick = () => formatExtraData.onArchiveBtnClick(productSegmentRecord);

  return (
    <TableRowToolBar className={styles.floatingEditArea}>
      <IconWithTooltip
        icon={faPencilAlt}
        tooltipProps={{
          id: `productSegmentList-editTip-${productSegmentRecord.segmentId}`,
          link: `/product-segments/${formatExtraData.retail}/${productSegmentRecord.segmentId}/edit`,
          tooltip: i18n.t<string>('goSegmentList.hints.edit')
        }}
      />
      <IconWithTooltip
        icon={faTrashAlt}
        onClick={onArchiveBtnClick}
        tooltipProps={{
          id: `productSegmentList-deleteTip-${productSegmentRecord.segmentId}`,
          tooltip: i18n.t<string>('productSegmentList.hints.archive')
        }}
      />
    </TableRowToolBar>
  );
};

const formatters = {
  nameFormatter,
  execStatusFormatter,
  floatingEditBtnsFormatter
};
export default formatters;
