import React from 'react';

import styles from './accountList.module.scss';
import i18n from 'i18n';

import { AccountListProps } from './AccountListModel';
import { StickableBootstrapTable } from 'components/common/Table/StickableBootstrapTable';
import { Account } from 'core/auth/Account';

export class AccountList extends React.Component<AccountListProps> {
  render () {
    const model = this.props.model;
    const columns = model.columns;
    return (
      <div className={styles.accountList}>
        <StickableBootstrapTable<Account>
          hover
          keyField='id'
          data={model.accounts}
          columns={columns}
          defaultSorted={model.defaultSorts}
          noDataIndication={i18n.t<string>(model.noDataDescription)}
        />
      </div>
    );
  }
}
