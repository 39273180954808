import React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router-dom';
import { CreativesProps, CreativesState } from './CreativesModel';
import { CreativeSetupFlowPage } from './CreativeSetupFlow/CreativeSetupFlowPage';
import { LoadingIndicator } from 'components/common/LoadingIndicator';
import { CreativesHome } from './CreativesHome/CreativesHome';
import { hasFuncs } from 'core/permission/PermissionDSL';
import RouteWithPermission from 'components/RouteWithPermission/RouteWithPermission';
import { Permission } from 'core/auth/Permission';
import PermissionChecker from 'containers/PermissionChecker/PermissionChecker';
import { ROUTE_PATH } from 'enum/RoutePath';
import { withErrorBoundary } from 'hoc/withErrorBoundary';
import { ERROR_CODE } from 'core/ErrorCode';

const CreativeHomeView = withErrorBoundary(ERROR_CODE.CREATIVE_HOME, CreativesHome);
const CreateCreativeView = withErrorBoundary(ERROR_CODE.CREATIVE_CREATE, CreativeSetupFlowPage);
const EditCreativeView = withErrorBoundary(ERROR_CODE.CREATIVE_EDIT, CreativeSetupFlowPage);

export class Creatives extends React.PureComponent<RouteComponentProps<any> & CreativesProps, CreativesState> {

  actor: any;
  handler: any;

  componentDidMount () {
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
    this.props.model.init();
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
      this.props.model.init();
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  renderCreativeCreatePage = (props) => {
    const query = new URLSearchParams(this.props.location.search);
    const campaignId = query.get('campaignId');
    const orderNumber = query.get('orderNumber');
    const l1ObjectId = query.get('l1ObjectId');
    const channel = query.get('channel');
    const draftId = query.get('draftId');

    const model = (campaignId || draftId) && orderNumber ?
      this.props.model.getCreativeCreateFromCampaignModel(orderNumber, l1ObjectId, campaignId, draftId, channel) : this.props.model.getCreativeCreateModel();

    if (!model) {
      return <div />;
    }
    return (
      <CreateCreativeView
        {...props}
        model={model}
      />
    );
  }

  renderCreativeEditPage = (props) => {
    const query = new URLSearchParams(this.props.location.search);
    const campaignId = query.get('campaignId') || undefined;
    const orderNumber = query.get('orderNumber') || undefined;
    const l1ObjectId = query.get('l1ObjectId') || undefined;
    const draftId = query.get('draftId') || undefined;

    const creativeId = props.match.params.creativeId;
    const model = this.props.model.getCreativeEditModel(creativeId, campaignId, orderNumber, l1ObjectId, draftId);
    if (!model) {
      return <div />;
    }
    const renderRedirect = () => <Redirect to={`${props.match.url}/${ROUTE_PATH.ERROR403}`} />;
    return (
      <PermissionChecker
        permissionAware={hasFuncs(Permission.CREATIVE_WRITE)}
        renderOtherwise={renderRedirect}
      >
        <EditCreativeView
          {...props}
          key={creativeId}
          model={model}
        />
      </PermissionChecker>
    );
  }

  renderContent = (props) => {
    const model = this.props.model.getCreativesHomeModel();
    if (!model) {
      return <div />;
    }
    return (
      <CreativeHomeView {...props} model={model} />
    );
  }

  render () {
    const model = this.props.model;
    if (model.state.loading) {
      return <LoadingIndicator />;
    }
    return (
      <Switch>
        <RouteWithPermission
          permissionAware={hasFuncs(Permission.CREATIVE_WRITE)}
          redirectPath={`/creatives/new/${ROUTE_PATH.ERROR403}`}
          path={'/creatives/new'}
          render={this.renderCreativeCreatePage}
        />
        <Route
          path={'/creatives/:creativeId/edit'}
          render={this.renderCreativeEditPage}
        />
        <RouteWithPermission
          permissionAware={hasFuncs(Permission.CREATIVE_READ)}
          redirectPath={`/creatives/${ROUTE_PATH.ERROR403}`}
          render={this.renderContent}
        />
      </Switch>
    );
  }
}
