import JSZip from 'jszip';
import i18n from 'i18n';
import { get } from 'lodash';
import { CustomLayoutCreateDTO, CustomLayoutWebService, PrevewValue, RestfulCustomLayoutWebService } from 'ws/CustomLayoutWebService';
import { CustomLayoutWithPagination, Macro } from './CustomLayout';
import { CloudStorageManager, DefaultCloudStorageManager } from 'core/cloudStorage/CloudStorageManager';
import { CreativeManager, DefaultCreativeManager } from 'core/creative/CreativeManager';
import { Pageable } from 'ws/Pageable';

const mimeDB = require('mime-db');

export type CustomLayoutManager = {
  validateTemplate: () => Promise<string[] | undefined>;
  uploadTemplate: (file: File) => Promise<{
    sessionId: string,
    macros: string[]
  }>;
  getPreviewUrl: (sessionId: string, previewData: {[key: string]: PrevewValue}, macros: {[key: string]: Macro}) => Promise<{
    previewUrlFromServer: string,
    macroValueMap: {[key: string]: string}
  }>;
  createCustomLayout: (payload: CustomLayoutCreateDTO) => Promise<void>;
  getCustomLayouts: (search?: string, status?: number[], type?: string[], pageable?: Pageable) => Promise<CustomLayoutWithPagination>;
  getMacroValueMap: (previewData: {[key: string]: PrevewValue}, macros: {[key: string]: Macro}) => Promise<{[key: string]: string}>;
  deleteCustomLayout: (id: number) => Promise<void>;
};

export class DefaultCustomLayoutManager implements CustomLayoutManager {

  constructor (
    private webService: CustomLayoutWebService = new RestfulCustomLayoutWebService(),
    private cloudStorageManager: CloudStorageManager = new DefaultCloudStorageManager(),
    private creativeManager: CreativeManager = new DefaultCreativeManager()
  ) { }

  async validateTemplate (file?: File) {
    if (!file) {
      return [i18n.t<string>('formValidate.labels.emptyError')];
    }

    const validTypes = ['application/zip', 'application/x-zip-compressed'];
    if (validTypes.indexOf(file.type) === -1) {
      const extensions = get(mimeDB[file.type], 'extensions', ['Unknown']);
      return [i18n.t<string>('html5Form.labels.typeErrorHint', { type: extensions[0] })];
    }

    const errors: any[] = [];
    const zipData = await JSZip.loadAsync(file);
    const contents: string[] = [];
    zipData.forEach((_1, zipEntry) => {
      // @ts-ignore
      let names = zipEntry.name.split('/');
      // only check entries that contain exact the file or exact a folder
      if (names.length > 2) return;

      let fileName = names.pop();
      if (fileName && !fileName.startsWith('._')
        && !fileName.startsWith('__MACOSX')
        && !fileName.startsWith('.DS_Store')) {
        contents.push(fileName);
      }
    });
    if (!contents.includes('ad_tag.html')) {
      errors.push(i18n.t<string>('customLayoutSetupFlowPage.errors.zipContentError'));
    }
    return errors.length > 0 ? errors : undefined;
  }

  async uploadTemplate (file: File) {
    return this.webService.uploadTemplate(file);
  }

  async getMacroValueMap (previewData: {[key: string]: PrevewValue}, macros: {[key: string]: Macro}) {
    const macroNames = Object.keys(previewData);
    let macroValueMap = {};
    for (let i = 0; i < macroNames.length; i++) {
      const macroName = macroNames[i];
      const data = previewData[macroName];
      const file = get(data, 'file');
      const macroConfig = macros[macroName];
      switch (macroConfig.type) {
        case 'image':
          macroValueMap[macroName] = get(data, 'url');
          if (file) {
            const url = await this.cloudStorageManager.uploadFileToCloud(file);
            macroValueMap[macroName] = url;
          }
          break;
        case 'video':
          macroValueMap[macroName] = get(data, 'url');
          if (file) {
            const assetId = await this.cloudStorageManager.uploadVideoToCloud(file);
            if (assetId) {
              const videoUrl = await this.creativeManager.publicVideoAssetId(assetId);
              macroValueMap[macroName] = videoUrl;
            }
          }
          break;
        default:
          macroValueMap[macroName] = data;
          break;
      }
    }
    return macroValueMap;
  }

  async getPreviewUrl (sessionId: string, previewData: {[key: string]: PrevewValue}, macros: {[key: string]: Macro}) {
    const macroValueMap = await this.getMacroValueMap(previewData, macros);
    const previewUrlFromServer = await this.webService.getPreviewUrl(sessionId, macroValueMap);
    return {
      previewUrlFromServer,
      macroValueMap
    };
  }

  async createCustomLayout (payload: CustomLayoutCreateDTO) {
    return this.webService.createCustomLayout(payload);
  }

  async getCustomLayouts (search: string = '', status: number[] = [], type: string[] = [], pageable?: Pageable) {
    return this.webService.getCustomLayouts(search, status, type, pageable);
  }

  async deleteCustomLayout (id: number) {
    return this.webService.deleteCustomLayout(id);
  }
}
