import React from 'react';
import './AppFooter.scss';

export class AppFooter extends React.Component {
  render () {
    const postfixToFilter = ['-gojek'];
    const year = new Date().getFullYear();
    let version = `v${process.env.REACT_APP_VERSION}`;
    postfixToFilter.forEach(postfix => version = version.replace(postfix, ''));
    return (
      <div className='app-footer'>
        <div>
          {year} &copy; PerforMax {version}
          <br/>
          powered by TenMax
          <br/>
        </div>
      </div>
    );
  }
}
