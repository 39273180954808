import { getEffectiveStatusDefaultColor } from 'components/Status/Status';
import { ColumnDefinition } from 'components/TableColumn/TableColumn';
import { FilterMenuTabConfig } from 'components/common/FilterMenuTab/FilterMenuTab';
import { SelectOptions } from 'components/common/commonType';
import { CampaignAuditLogModel } from 'containers/AuditLog/CampaignAuditLogModel';
import { AbstractSortableList, SortableList } from 'containers/Common/AbstractSortableList';
import { AddonFeatureManager, LocaleMeta } from 'core';
import { ADDONFEATURE } from 'core/agency/AddonFeature';
import { DraftManager, RtbCampaignDraftManager } from 'core/draft/DraftManager';
import { L1Object } from 'core/l1Object/L1Object';
import { DefaultL1ObjectManager, L1ObjectManager } from 'core/l1Object/L1ObjectManager';
import { MessageCampaign, MessageCampaignStatus } from 'core/messageCampaign/MessageCampaign';
import { DefaultMessageCampaignManager, MessageCampaignManager } from 'core/messageCampaign/MessageCampaignManager';
import { Order, State } from 'core/order/Order';
import { AdType, CampaignState } from 'core/rtbCampaign/RtbCampaign';
import { formatPriceAny, formatPriceWithCurrency, getPriceValue } from 'helper/CurrencyHelper';
import i18n from 'i18n';
import _ from 'lodash';
import moment from 'moment';
import { toast } from 'react-toastify';
import { numberWithCommas } from 'utils/StringUtil';
import {
  FireableUpdateEventListener,
  UpdateEventListener
} from 'utils/UpdateEventListener';
import {
  MessageCampaignListColumns,
  basicColumns,
  performanceColumns,
  performanceColumnsWithViewable
} from './MessageCampaignListColumnSetting';
import styles from './messageCampaignList.module.scss';

const timeFormat = 'YYYY-MM-DD HH:mm:ss';
export interface MessageCampaignListModel extends SortableList {
  readonly order: Order;
  readonly l1Object: L1Object;
  readonly campaignList: Array<MessageCampaign>;
  readonly state: CampaignListState;
  readonly event: UpdateEventListener<MessageCampaignListModel>;
  readonly campaignTags: Array<string>;
  readonly campaignStates: Array<string>;
  readonly objectTypes: Array<string>;
  readonly canCreateCampaign: boolean;
  readonly canSplitCampaign: boolean;
  readonly showSplitBtn: boolean;
  readonly searchString: string;
  readonly lang: string;
  readonly canNotCreateMessage: string;
  readonly newCampaignPath: string;
  readonly editCampaignPath: string;
  readonly copyCampaignPath: string;
  readonly filterMenuTabConfigs: Array<FilterMenuTabConfig>;
  deleteCampaignIds: Array<number>;
  deleteDraftIds: Array<number>;
  onCampaignChange: () => void;
  handleOnSelect (campaign): void;
  handleOnSelectAll (): void;
  handleRemoveSelect (): void;
  getColumnDefinition (columnName): ColumnDefinition;
  showTable (listType, e): void;
  handleOnSearch (campaignName): void;
  handleOnTagFilterApply (tags): void;
  handleOnStateFilterApply (stateDesList): void;
  handleOnObjectTypeFilterApply (objectTypeList): void;
  activeCampaign (event): Promise<void>;
  deactiveCampaign (event): Promise<void>;
  allSelectedCampaignSameType (): boolean;
  selectUnknowAdTypeCampaign (): boolean;
  deleteCampaign (campaignIds): Promise<void>;
  deleteDraft (draftIds): Promise<void>;
  canDeleteSelectedCampaigns (campaignIds: Array<number>): boolean;
  canEditSelectedCampaigns (campaignIds: Array<number>): boolean;
  setVisibilityOfDeleteConfirmModal (show: boolean): void;
  setVisibilityOfDraftDeleteConfirmModal (show: boolean): void;
  setCampaignToChangeBidWeight (campaignId?: number | string): void;
  getReportLink (campaign): string;
  updateModelData (order: Order, campaignList: Array<MessageCampaign>): void;
  updateViewModelData (): void;
  onSearchChange (searchString): void;
  getTabs (): SelectOptions[];
  setVisibilityOfDraftCreateModal (show: boolean): void;
  getCampaignStatusDesData (campaign: any): {
    des: string;
    color: string;
  };
  getCampaignEffectiveStatusDesData (campaign: any): {
    des: string;
    color: string;
    extraInfo?: any;
  };

  isCampaignBudgetRemain (campaign): boolean;
  getAuditLogModel (): CampaignAuditLogModel | undefined;
}

export type CampaignListProps = {
  readonly model: MessageCampaignListModel;
};

export enum CampaignListType {
  BASIC = 'basic',
  PERFORMANCE = 'performance',
  AUDIT_LOG = 'auditLog'
}

export type CampaignListState = {
  readonly loading: boolean;
  readonly selectedCampaign: number[];
  readonly selectedDraft: number[];
  readonly columnsToShow: MessageCampaignListColumns[];
  readonly seletedTab: string;
  readonly viewModelData: any[];
  readonly summaryData: any;
  readonly selectedTagFilter: string[];
  readonly selectedStateFilter: string[];
  readonly selectedEffectiveStatusFilter: string[];
  readonly selectedObjectTypeFilter: string[];
  readonly showDraftCreateModal: boolean;
  readonly showDraftDeleteConfirmModal: boolean;
  readonly showDeleteConfirmModal: boolean;
  readonly campaignToChangeBidWeight?: number | string;
  readonly needUpdateViewModelData: boolean;
};

export class DefaultMessageCampaignListModel extends AbstractSortableList implements MessageCampaignListModel {

  event: FireableUpdateEventListener<MessageCampaignListModel> = new FireableUpdateEventListener<MessageCampaignListModel>();
  selectedCampaign: number[] = [];
  selectedDraft: number[] = [];
  filteredViewModelData: any[] = [];
  columnsToShow: Array<MessageCampaignListColumns>;
  seletedTab: string = CampaignListType.BASIC;
  campaignTags: string[] = [];
  campaignStates: string[] = [];
  campaignEffectiveStatus: string[] = [];
  objectTypes: string[] = [];
  searchString: string;
  selectedTagFilter: string[] = [];
  selectedStateFilter: string[] = [];
  selectedEffectiveStatusFilter: string[] = [];
  selectedObjectTypeFilter: string[] = [];
  loading: boolean = false;
  showDeleteConfirmModal: boolean = false;
  showDraftCreateModal: boolean = false;
  showDraftDeleteConfirmModal: boolean = false;
  campaignToChangeBidWeight?: number | string;
  lang: string = i18n.language;
  modelDeleteCampaignIds: number[] = [];
  modelDeleteDraftIds: number[] = [];
  needUpdateViewModelData: boolean = false;
  summaryData: any = {};
  isChannelAllowed: boolean;
  auditLogModel?: CampaignAuditLogModel;

  constructor (
    public order: Order,
    public l1Object: L1Object,
    public campaignList: any[],
    protected addonFeatureManager: AddonFeatureManager,
    public onCampaignChange,
    public onSearchChange: (search: string) => void,
    defaultSearchString: string | null,
    private budgetBalance: number,
    private localeMeta?: LocaleMeta,
    public manager: MessageCampaignManager = new DefaultMessageCampaignManager(),
    public rtbCampaignDraftManager: DraftManager = new RtbCampaignDraftManager(),
    l1ObjectManager: L1ObjectManager = new DefaultL1ObjectManager()
  ) {
    super('id', 'desc');
    this.columnsToShow = [...basicColumns];
    if (this.hideBudget) {
      this.columnsToShow = this.columnsToShow.filter(column => column !== MessageCampaignListColumns.PROGRESS);
    }
    this.searchString = defaultSearchString ? defaultSearchString : '';
    this.isChannelAllowed = l1Object ?
      l1ObjectManager.isChannelAllowed(l1Object.channel, addonFeatureManager) :
      true;
    this.updateViewModelData();
  }

  get canNotCreateMessage (): string {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    if (canNotCreateState.includes(state)) {
      return i18n.t<string>('orderDetail.labels.orderStateCannotCreate');
    }

    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    if (isEnd) {
      return i18n.t<string>('orderDetail.labels.isEndCannotCreate');
    }

    if (!this.l1Object.autoOptimise && this.budgetBalance <= 0) {
      return i18n.t<string>('orderDetail.labels.lessThanBudgetMinimum', { budget: 0 });
    }
    // 簡訊廣告最早的建立時間只能是隔一天的早上 8 點
    if (moment(this.order.endDate).isSame(moment(), 'day')) {
      return i18n.t<string>('orderDetail.labels.messageCampaignStartTimeError');
    }

    return '';
  }

  getCanNotCopyMessage (campaign: any): string {
    // TODO
    return i18n.t<string>('common.labels.comingSoon');

    // const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    // const state = this.order.state;
    // if (canNotCreateState.includes(state)) {
    //   return i18n.t<string>('orderDetail.labels.orderStateCannotCreate');
    // }

    // const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    // if (isEnd) {
    //   return i18n.t<string>('orderDetail.labels.isEndCannotCreate');
    // }
    // const campaignIsStart = moment().isAfter(campaign.startDate);

    // let startDate = moment(campaign.startDate);
    // if (campaignIsStart) {
    //   startDate = moment().startOf('day');
    // }
    // let endDate = moment(campaign.endDate);

    // const minCampaignBudgetPerDay = this.order.campaignConstraint.budgetMinimum;
    // const scheduleDateCount = endDate.diff(startDate, 'days') + 1;
    // const minBudgetOfNewCampaign = minCampaignBudgetPerDay * scheduleDateCount;
    // if (this.budgetBalance < minBudgetOfNewCampaign) {
    //   return i18n.t<string>('campaignList.labels.canCopyNeedMore', {
    //     parent: this.l1Object && !_.isNil(this.l1Object.budget) ? 'Campaign group' : 'Order',
    //     budget: formatPriceWithCurrency(this.order.currency, minBudgetOfNewCampaign - this.budgetBalance)
    //   });
    // }

    // return '';
  }

  getCanNotSplitMessage (campaign: any): string {
    // TODO
    return i18n.t<string>('common.labels.comingSoon');

    // const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    // const state = this.order.state;
    // if (canNotCreateState.includes(state)) {
    //   return i18n.t<string>('orderDetail.labels.orderStateCannotCreate');
    // }

    // const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    // if (isEnd) {
    //   return i18n.t<string>('orderDetail.labels.isEndCannotCreate');
    // }

    // if (this.l1Object && this.l1Object.autoOptimise) {
    //   return '';
    // }

    // const targetBudget = campaign.budget;
    // const expectSpent = campaign.spents * 1.1;
    // const minCampaignBudgetPerDay = this.order.campaignConstraint.budgetMinimum;
    // const startDate = moment(campaign.startDate);
    // const today = moment().startOf('day');
    // const isStart = today.isAfter(startDate);
    // const endDate = moment(campaign.endDate);
    // const scheduleDateCount = endDate.diff(isStart ? today : startDate, 'days') + 1;
    // const minBudgetFromNow = minCampaignBudgetPerDay * scheduleDateCount;
    // const firstCampaignBudget = isStart ?
    //   Math.max(expectSpent, minCampaignBudgetPerDay * today.diff(startDate, 'days')) + minBudgetFromNow :
    //   minBudgetFromNow;
    // const secondCampaignBudget = minBudgetFromNow;
    // const minBudgetFor2Campaign = firstCampaignBudget + secondCampaignBudget;
    // const isBelowMinBudget = targetBudget < minBudgetFor2Campaign;
    // if (isBelowMinBudget) {
    //   return i18n.t<string>('campaignList.labels.canSplitMinimum', { budget: formatPriceWithCurrency(this.order.currency, minBudgetFor2Campaign) });
    // }

    // return '';
  }

  get deleteCampaignIds (): Array<number> {
    return this.modelDeleteCampaignIds;
  }

  set deleteCampaignIds (campaignIds) {
    this.modelDeleteCampaignIds = campaignIds;
  }

  get deleteDraftIds (): Array<number> {
    return this.modelDeleteDraftIds;
  }

  set deleteDraftIds (draftIds) {
    this.modelDeleteDraftIds = draftIds;
  }

  get state (): CampaignListState {
    return {
      selectedCampaign: this.selectedCampaign,
      selectedDraft: this.selectedDraft,
      columnsToShow: this.columnsToShow,
      seletedTab: this.seletedTab,
      needUpdateViewModelData: this.needUpdateViewModelData,
      viewModelData: this.filteredViewModelData,
      summaryData: this.summaryData,
      selectedTagFilter: this.selectedTagFilter,
      selectedStateFilter: this.selectedStateFilter,
      selectedEffectiveStatusFilter: this.selectedEffectiveStatusFilter,
      selectedObjectTypeFilter: this.selectedObjectTypeFilter,
      loading: this.loading,
      showDraftCreateModal: this.showDraftCreateModal,
      showDraftDeleteConfirmModal: this.showDraftDeleteConfirmModal,
      showDeleteConfirmModal: this.showDeleteConfirmModal,
      campaignToChangeBidWeight: this.campaignToChangeBidWeight
    };
  }

  get canCreateCampaign (): boolean {
    const canNotCreateState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    const noBudget = !this.l1Object.autoOptimise && this.budgetBalance <= 0;
    const isOverTodayAndOverToday = moment(this.order.endDate).isSame(moment(), 'day');
    return !(canNotCreateState.includes(state) || isEnd || noBudget || isOverTodayAndOverToday) && this.isChannelAllowed;
  }

  get canSplitCampaign (): boolean {
    const canNotSplitState = [State.NOT_APPROVE, State.REJECT, State.SETTLE, State.SETTLED, State.CHANGE_PENDING];
    const state = this.order.state;
    const isEnd = moment(this.order.endDate).isBefore(moment().startOf('day'));
    return !(canNotSplitState.includes(state) || isEnd) && this.isChannelAllowed;
  }

  get showSplitBtn (): boolean {
    return this.l1Object && this.l1Object.autoOptimise ? false : true;
  }

  get newCampaignPath () {
    return 'new';
  }

  get editCampaignPath () {
    return 'edit';
  }

  get copyCampaignPath () {
    return 'copy';
  }

  get filterMenuTabConfigs (): FilterMenuTabConfig[] {
    return [
      {
        filterType: i18n.t<string>('l2ObjectList.labels.statusFilter'),
        menuTitle: i18n.t<string>('l2ObjectList.labels.statusFilterMenuTitle'),
        tag: i18n.t<string>('l2ObjectList.labels.statusTag'),
        selectedValues: this.state.selectedStateFilter,
        options: this.campaignStates,
        applyMethod: this.handleOnStateFilterApply
      },
      {
        filterType: i18n.t<string>('l2ObjectList.labels.deliveryFilter'),
        menuTitle: i18n.t<string>('l2ObjectList.labels.deliveryFilterMenuTitle'),
        tag: i18n.t<string>('l2ObjectList.labels.deliveryTag'),
        selectedValues: this.state.selectedEffectiveStatusFilter,
        options: this.campaignEffectiveStatus,
        applyMethod: this.handleOnEffectiveStatusFilterApply
      },
      {
        filterType: i18n.t<string>('campaignList.labels.tagFilter'),
        menuTitle: i18n.t<string>('campaignList.labels.tagFilterMenuTitle'),
        tag: i18n.t<string>('campaignList.labels.tagTag'),
        selectedValues: this.state.selectedTagFilter,
        options: this.campaignTags,
        applyMethod: this.handleOnTagFilterApply
      },
      {
        filterType: i18n.t<string>('l2ObjectList.labels.objectTypeFilter'),
        menuTitle: i18n.t<string>('l2ObjectList.labels.objectTypeFilterMenuTitle'),
        tag: i18n.t<string>('l2ObjectList.labels.objectTypeTag'),
        selectedValues: this.state.selectedObjectTypeFilter,
        options: this.objectTypes,
        applyMethod: this.handleOnObjectTypeFilterApply
      }
    ];
  }

  get hideBudget () {
    return this.l1Object && this.l1Object.autoOptimise;
  }

  getTabs () {
    return Object.values(CampaignListType)
    // TODO: 還沒做，先隱藏
    .filter(tab => tab !== CampaignListType.AUDIT_LOG)
    .map(tab => ({
      label: i18n.t<string>(`campaignList.tabs.${tab}`),
      value: tab
    }));
  }

  sortComparator = (field: string | undefined, dataA, dataB, order) => {
    const dataAToCompare = field ? dataA[field] : dataA;
    const dataBToCompare = field ? dataB[field] : dataB;
    if (order === 'desc') {
      return dataAToCompare < dataBToCompare ? 1 : -1;
    }

    return dataAToCompare > dataBToCompare ? 1 : -1;
  }

  getSortFunc = (dataField?: string) => {
    return _.partial(this.sortComparator, dataField);
  }

  get nameColumnAdditional () {
    return {
      events: {
        onClick: () => {
          // This is intentional
        }
      },
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        const dataAIsDraft = _.get(dataA, 'listId', 0).toString().includes('draft');
        const dataBIsDraft = _.get(dataB, 'listId', 0).toString().includes('draft');
        const sameObjectType = dataAIsDraft === dataBIsDraft;
        const compareListId = _.get(dataA, 'listId', 0).toString().localeCompare(_.get(dataB, 'listId', 0).toString());
        if (!sameObjectType) {
          return dataAIsDraft ? -1 : 1;
        }
        if (compareListId === 0) {
          return 0;
        }
        if (order === 'desc') {
          return compareListId > 0 ? -1 : 1;
        } else {
          return compareListId < 0 ? -1 : 1;
        }
      }
    };
  }

  get stateColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        const stateDes = [
          i18n.t<string>('campaignList.labels.activateState'),
          i18n.t<string>('campaignList.labels.stoppingState'),
          i18n.t<string>('campaignList.labels.deactivateState'),
          i18n.t<string>('campaignList.labels.deleteState')
        ];
        const dataAToCompare = stateDes.indexOf(dataA['des']);
        const dataBToCompare = stateDes.indexOf(dataB['des']);
        if (order === 'desc') {
          return dataAToCompare > dataBToCompare ? 1 : -1;
        } else {
          return dataAToCompare < dataBToCompare ? 1 : -1;
        }
      }
    };
  }

  get deliveryColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        const stateDes = [
          i18n.t<string>('campaignList.labels.budgetRemainState'),
          i18n.t<string>('campaignList.labels.activateState'),
          i18n.t<string>('campaignList.labels.stoppingState'),
          i18n.t<string>('campaignList.labels.deactivateState'),
          i18n.t<string>('campaignList.labels.noCreativesState'),
          i18n.t<string>('campaignList.labels.notStartState'),
          i18n.t<string>('campaignList.labels.endState'),
          i18n.t<string>('campaignList.labels.deleteState')
        ];
        const dataAToCompare = stateDes.indexOf(dataA['des']);
        const dataBToCompare = stateDes.indexOf(dataB['des']);
        if (order === 'desc') {
          return dataAToCompare > dataBToCompare ? 1 : -1;
        } else {
          return dataAToCompare < dataBToCompare ? 1 : -1;
        }
      }
    };
  }

  get scheduleColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: (dataA, dataB, order) => {
        if (!dataA || !dataB) {
          return !dataA ? -1 : 1;
        }

        if (order === 'desc') {
          return moment(dataA.start).isBefore(moment(dataB.start)) ? 1 : -1;
        }

        return moment(dataB.start).isBefore(moment(dataA.start)) ? 1 : -1;
      }
    };
  }

  get resultColumnAdditional () {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: this.getSortFunc('value'),
      formatExtraData: {
        objective: _.get(this.l1Object, 'objective')
      }
    };
  }

  getCommonColumnAdditional (columnName?: string) {
    return {
      sort: true,
      onSort: this.handleSort,
      sortFunc: this.getSortFunc(columnName)
    };
  }

  getColumnDefinition (columnName): ColumnDefinition {
    let additional = {};

    let customLabel;
    switch (columnName) {
      case MessageCampaignListColumns.NAME:
        additional = this.nameColumnAdditional;
        break;
      case MessageCampaignListColumns.STATE:
        additional = this.stateColumnAdditional;
        break;
      case MessageCampaignListColumns.DELIVERY:
        additional = this.deliveryColumnAdditional;
        break;
      case MessageCampaignListColumns.CREATIVE:
        additional = this.getCommonColumnAdditional();
        break;
      case MessageCampaignListColumns.SCHEDULE:
        additional = this.scheduleColumnAdditional;
        break;
      case MessageCampaignListColumns.PROGRESS:
        additional = this.getCommonColumnAdditional('executeRate');
        break;
      case MessageCampaignListColumns.BUDGET:
        customLabel = this.hideBudget ? i18n.t<string>('campaignList.headers.budgetDistributionColumn') : i18n.t<string>('campaignList.headers.budgetColumn');
        additional = this.getCommonColumnAdditional('budget');
        break;
      case MessageCampaignListColumns.EDITBTNS:
        additional = {
          text: ''
        };
        break;
      case MessageCampaignListColumns.RESULTS:
        additional = this.resultColumnAdditional;
        break;
      case MessageCampaignListColumns.SPENT:
        customLabel = i18n.t<string>(`campaignList.headers.${MessageCampaignListColumns.SPENT}`) + ` (${this.order.currency})`;
        additional = {
          sort: true,
          onSort: this.handleSort
        };
        break;
      case MessageCampaignListColumns.CPC:
        customLabel = i18n.t<string>(`campaignList.headers.${MessageCampaignListColumns.CPC}`) + ` (${this.order.currency})`;
        additional = this.getCommonColumnAdditional('value');
        break;
      case MessageCampaignListColumns.CPA:
        customLabel = i18n.t<string>(`campaignList.headers.${MessageCampaignListColumns.CPA}`) + ` (${this.order.currency})`;
        additional = this.getCommonColumnAdditional('value');
        break;
      case MessageCampaignListColumns.IMPRES:
      case MessageCampaignListColumns.VIEWABLE:
      case MessageCampaignListColumns.CLICKS:
      case MessageCampaignListColumns.CTR:
      case MessageCampaignListColumns.VCTR:
      case MessageCampaignListColumns.CONVERT:
      case MessageCampaignListColumns.CVR:
      case MessageCampaignListColumns.VIEW:
      case MessageCampaignListColumns.VIEWRATE:
      case MessageCampaignListColumns.VIEWABLE_VIEWRATE:
        additional = this.getCommonColumnAdditional('value');
        break;
      default:
        break;
    }

    return this.columnDefinition(columnName, customLabel, additional);
  }

  columnDefinition (columnName, customLabel, additional = {}): ColumnDefinition {
    const columnClassGetter = () => {
      return styles[columnName];
    };

    return {
      sort: false,
      text: customLabel ? customLabel : `campaignList.headers.${columnName}`,
      dataField: columnName,
      classes: columnClassGetter,
      headerClasses: columnClassGetter,
      ...additional
    };
  }

  getReportLink (campaign) {
    const from = encodeURIComponent(moment(campaign.startDate).startOf('day').format(timeFormat));
    const to = encodeURIComponent(moment(campaign.endDate).endOf('day').format(timeFormat));
    const dimension = 'l2ChannelId';
    return `/reports/performance?dimension=${dimension}&from=${from}&to=${to}&${dimension}=${campaign.id}`;
  }

  getCampaignStatusDesData = (campaign) => {
    let des = '';
    let color;
    switch (campaign.state) {
      case CampaignState.DEACTIVATE:
        des = i18n.t<string>('campaignList.labels.deactivateState');
        color = 'black';
        break;
      case CampaignState.DELETE:
        des = i18n.t<string>('campaignList.labels.deleteState');
        color = 'danger';
        break;
      case CampaignState.ACTIVATE:
        des = i18n.t<string>('campaignList.labels.activateState');
        break;
      default:
        des = i18n.t<string>('campaignList.labels.stoppingState');
        color = 'whiteTheme4';
        break;
    }

    return {
      des,
      color
    };
  }

  getCampaignEffectiveStatusDesData = (campaign) => {
    let des = _.startCase(_.lowerCase(campaign.effectiveStatus));
    let color = getEffectiveStatusDefaultColor(campaign.effectiveStatus);
    let extraInfo;
    let state = campaign.state;
    switch (campaign.effectiveStatus) {
      case MessageCampaignStatus.DRAFT:
        if (state === CampaignState.DEACTIVATE) {
          des = i18n.t<string>('campaignList.labels.deactivateState');
          color = 'black';
        } else {
          des = i18n.t<string>('campaignList.labels.notStartState');
          color = 'light';
        }
        break;
      case MessageCampaignStatus.SCHEDULED:
        des = i18n.t<string>('messageCampaignList.labels.scheduledState');
        color = 'light';
        break;
      case MessageCampaignStatus.RUNNING:
      case MessageCampaignStatus.PENDING:
      case MessageCampaignStatus.REPLENISH:
        des = i18n.t<string>('campaignList.labels.activateState');
        color = 'theme1';
        break;
      case MessageCampaignStatus.ABANDON:
        des = i18n.t<string>('campaignList.labels.deleteState');
        color = 'danger';
        break;
      case MessageCampaignStatus.ABNORMAL:
        des = i18n.t<string>('messageCampaignList.labels.abnormalState');
        color = 'warning';
        break;
      case MessageCampaignStatus.FINISHED:
        des = i18n.t<string>('campaignList.labels.endState');
        color = 'light';
        break;
      default:
        break;
    }

    return {
      des,
      color,
      extraInfo
    };
  }

  isCampaignBudgetRemain = (campaign) => {
    return ['PAUSED', 'CAMPAIGN_GROUP_PAUSED'].includes(campaign.effectiveStatus) &&
      campaign.budget - campaign.spents > 0;
  }

  getCampaignTypeDes (type) {
    if (type in AdType) {
      return i18n.t<string>(`campaignList.labels.adType${_.upperFirst(_.camelCase(type))}`);
    }

    return i18n.t<string>('campaignList.labels.adTypeEmpty');
  }

  getProgress (campaign) {
    const campaignProgress = this.getCampaignProgressRate(campaign);
    const executeRate = campaignProgress.executeRate;
    const predictRate = Math.min(campaignProgress.predictRate, 1);
    const warnignLine = campaign.budget * 1.1;
    const discrepancy = predictRate - executeRate;
    return {
      spents: `${this.order.currency} ${numberWithCommas(_.floor(campaign.spents, 2).toFixed(2))}`,
      budget: `${this.order.currency} ${numberWithCommas(_.floor(campaign.budget, 2).toFixed(2))}`,
      executeRate,
      predictRate,
      danger: campaign.spents > warnignLine,
      deepWarning: discrepancy > 0.03,
      warning: discrepancy > 0.01 && discrepancy <= 0.03
    };
  }

  getPriceModelDes (priceModel) {
    return i18n.t<string>(`campaign.labels.${priceModel}`);
  }

  getLimitationsToShow (limitations) {
    return this.manager.getLimitationSummaryData(
      _.defaultTo(limitations, {})
    );
  }

  getCpc (campaign) {
    let spents = campaign.spents;
    let clicks = campaign.clicks;
    let cpc = 0;
    if (clicks !== 0) {
      cpc = spents / clicks;
    }

    return cpc;
  }

  getCtr (campaign) {
    let impres = campaign.impres;
    let clicks = campaign.clicks;
    let vctr = 0;
    if (impres !== 0) {
      vctr = (clicks / impres) * 100;
    }
    return vctr;
  }

  getVctr (campaign) {
    let viewable = campaign.viewable;
    let clicks = campaign.clicks;
    let vctr = 0;
    if (viewable !== 0) {
      vctr = (clicks / viewable) * 100;
    }
    return vctr;
  }

  getCpa (campaign) {
    let spents = campaign.spents;
    let convs = campaign.convs;
    let cpa = 0;
    if (convs !== 0) {
      cpa = spents / convs;
    }
    return cpa;
  }

  getCvr (campaign) {
    let convs = campaign.convs;
    let clicks = campaign.clicks;
    let cvr = 0;
    if (clicks !== 0) {
      cvr = (convs / clicks) * 100;
    }
    return cvr;
  }

  getViewRate = (campaign) => {
    let adView = campaign.adView;
    let impres = campaign.impres;
    let viewRate = 0;
    if (impres !== 0) {
      viewRate = (adView / impres) * 100;
    }
    return viewRate;
  }

  getViewableViewRate = (campaign) => {
    let adView = campaign.adView;
    let viewable = campaign.viewable;
    let viewRate = 0;
    if (viewable !== 0) {
      viewRate = (adView / viewable) * 100;
    }
    return viewRate;
  }

  getObjectTypeDesc (campaign) {
    if (campaign.isDraft) {
      return i18n.t<string>('l2ObjectList.labels.draft');
    } else {
      return i18n.t<string>('l2ObjectList.labels.ordinaryCampaign_rtb');
    }
  }

  getViewModelData () {
    this.campaignTags = [];
    this.campaignStates = [];
    this.objectTypes = [];
    const showBudget = true;
    const viewModelData: Array<any> = this.campaignList.map(data => {
      const campaign = data.basic;
      this.campaignTags = _.uniq(_.concat(this.campaignTags, _.defaultTo(campaign.tags, [])));
      const stateData = this.getCampaignStatusDesData(campaign);
      const effectiveData = this.getCampaignEffectiveStatusDesData(campaign);
      this.campaignStates = _.uniq(_.concat(this.campaignStates, stateData.des));
      this.campaignEffectiveStatus = _.uniq(_.concat(this.campaignEffectiveStatus, effectiveData.des));
      const objectTypeDes = this.getObjectTypeDesc(campaign);
      this.objectTypes = _.uniq(_.concat(this.objectTypes, objectTypeDes));
      const results = _.get(campaign, 'report.results', 0);
      const listId = campaign.isDraft ? `${campaign.id}_draft_${campaign.draftId}` : campaign.id;

      const campaignData: any = {
        listId,
        id: campaign.id,
        draftId: campaign.draftId,
        spents: campaign.spents,
        budget: campaign.budget,
        priceModel: campaign.priceModel,
        optimize: campaign.optimize,
        orderPrice: campaign.orderPrice,
        bidPrice: campaign.bidPrice,
        impres: campaign.impres,
        startDate: campaign.startDate,
        endDate: campaign.endDate,
        state: campaign.state,
        effectiveStatus: campaign.effectiveStatus,
        stateColumn: stateData,
        deliveryColumn: effectiveData,
        resultsColumn: {
          value: results,
          desc: numberWithCommas(results)
        },
        isDraft: campaign.isDraft,
        nameColumn: {
          name: campaign.name,
          listId,
          typeDes: this.getCampaignTypeDes(campaign.adType)
        },
        creativeColumn: 1,
        scheduleColumn: {
          start: moment(campaign.startDate).format(timeFormat)
        },
        progressColumn: showBudget ? this.getProgress(campaign) : undefined,
        budgetColumn: {
          budget: campaign.budget,
          budgetDes: showBudget ? formatPriceWithCurrency(this.order.currency, campaign.budget) : undefined
        },
        priceColumn: {
          priceModel: this.getPriceModelDes(campaign.priceModel),
          type: campaign.optimize
        },
        limitationColumn: this.getLimitationsToShow(data.limitations),
        tagsColumn: campaign.tags,
        viewableColumn: {
          value: campaign.viewable,
          desc: numberWithCommas(campaign.viewable)
        },
        impresColumn: {
          value: campaign.impres,
          desc: numberWithCommas(campaign.impres)
        },
        clicksColumn: {
          value: campaign.clicks,
          desc: numberWithCommas(campaign.clicks)
        },
        convertColumn: {
          value: campaign.convs,
          desc: numberWithCommas(campaign.convs)
        },
        viewColumn: {
          value: campaign.adView,
          desc: numberWithCommas(campaign.adView)
        },
        cpcColumn: { value: this.getCpc(campaign) },
        ctrColumn: { value: this.getCtr(campaign) },
        vctrColumn: { value: this.getVctr(campaign) },
        cpaColumn: { value: this.getCpa(campaign) },
        cvrColumn: { value: this.getCvr(campaign) },
        viewRateColumn: { value: this.getViewRate(campaign) },
        viewableViewRateColumn: { value: this.getViewableViewRate(campaign) },
        uuCountColumn: { value: campaign.uuCount }
      };
      campaignData.canNotCopyMessage = this.getCanNotCopyMessage(campaignData);
      campaignData.canNotSlitMessage = this.getCanNotSplitMessage(campaignData);
      return campaignData;
    });

    this.summaryData = {
      id: 0,
      listId: 'summaryRow',
      ...this.getSummaryData(this.campaignList)
    };

    return viewModelData;
  }

  getAuditLogModel (): CampaignAuditLogModel | undefined {
    if (!this.order || !this.campaignList) {
      return undefined;
    }

    const isAdmin = this.localeMeta ? _.isNil(this.localeMeta.agencyId) : false;

    if (
      this.auditLogModel &&
      this.campaignList === this.auditLogModel.campaignList
    ) {
      return this.auditLogModel;
    }

    this.auditLogModel = new CampaignAuditLogModel(
      isAdmin,
      this.campaignList
    );
    return this.auditLogModel;
  }

  showTable = (listType: CampaignListType, e) => {
    e && e.stopPropagation();
    this.seletedTab = listType;
    const viewableEnable = this.addonFeatureManager.isFeatureEnable(ADDONFEATURE.REPORT.REPORT_VIEWABLE_CTR);
    switch (listType) {
      case CampaignListType.PERFORMANCE:
        if (viewableEnable) {
          this.columnsToShow = [...performanceColumnsWithViewable];
        } else {
          this.columnsToShow = [...performanceColumns];
        }
        break;
      default:
        this.columnsToShow = [...basicColumns];
        break;
    }
    if (this.hideBudget) {
      this.columnsToShow = this.columnsToShow.filter(column => column !== MessageCampaignListColumns.PROGRESS);
    }
    if (listType === CampaignListType.AUDIT_LOG) {
      this.selectedCampaign = [];
    }
    this.updateState();
  }

  handleOnSelect = (campaign) => {
    const isDraft: boolean = _.get(campaign, 'isDraft', false);
    const objectId = isDraft ? campaign.draftId : campaign.id;
    const selectedArray: number[] = isDraft ? this.selectedDraft : this.selectedCampaign;
    if (selectedArray.indexOf(objectId) > -1) {
      _.remove(selectedArray, id => id === objectId);
    } else {
      selectedArray.push(objectId);
    }

    this.updateState();
  }

  handleOnSelectAll = () => {
    const selectedArrays = [...this.selectedCampaign, ...this.selectedDraft];
    const filteredSelectableViewModelData = this.filteredViewModelData.filter(viewModelData => {
      return this.canEditSelectedCampaigns([viewModelData.id]);
    });
    if (selectedArrays.length === filteredSelectableViewModelData.length) {
      this.selectedCampaign = [];
      this.selectedDraft = [];
    } else {
      this.selectedCampaign = this.filteredViewModelData
        .filter(viewModelData => !viewModelData.isDraft && this.canEditSelectedCampaigns([viewModelData.id]))
        .map(viewModelData => viewModelData.id);
      this.selectedDraft = this.filteredViewModelData
        .filter(viewModelData => viewModelData.isDraft && this.canEditSelectedCampaigns([viewModelData.draftId]))
        .map(viewModelData => viewModelData.draftId);
    }
    this.updateState();
  }

  handleOnSearch = (searchString) => {
    this.searchString = searchString;
    this.onSearchChange(searchString);
    this.updateViewModelData();
  }

  handleOnTagFilterApply = (tags) => {
    this.selectedTagFilter = [...tags];
    this.updateViewModelData();
  }

  handleOnStateFilterApply = (stateDesList) => {
    this.selectedStateFilter = [...stateDesList];
    this.updateViewModelData();
  }

  handleOnEffectiveStatusFilterApply = (effectiveStatusDesList) => {
    this.selectedEffectiveStatusFilter = [...effectiveStatusDesList];
    this.updateViewModelData();
  }

  handleOnObjectTypeFilterApply = (objectTypeList) => {
    this.selectedObjectTypeFilter = [...objectTypeList];
    this.updateViewModelData();
  }

  updateViewModelData = () => {
    this.needUpdateViewModelData = false;
    const allViewModelData = this.getViewModelData();
    this.filteredViewModelData = _.filter(allViewModelData,
      viewModelData => {
        const campaignData = this.campaignList.find(data => {
          const campaign = data.basic;
          return campaign.id === viewModelData.id || campaign.draftId === viewModelData.draftId;
        });
        if (!campaignData) {
          return false;
        }
        const initWithoutDelete = (this.selectedStateFilter.length === 0 && this.selectedEffectiveStatusFilter.length === 0)
          ? (viewModelData.state !== CampaignState.DELETE && viewModelData.effectiveStatus !== MessageCampaignStatus.ABANDON) : true;
        const stateIsMatch = this.selectedStateFilter.length === 0 || this.selectedStateFilter.includes(viewModelData.stateColumn.des);
        const effecitveStatuseIsMatch = this.selectedEffectiveStatusFilter.length === 0 || this.selectedEffectiveStatusFilter.includes(viewModelData.deliveryColumn.des);
        const tagsIsMatch = this.selectedTagFilter.length === 0 || _.intersection(viewModelData.tagsColumn, this.selectedTagFilter).length > 0;
        const objectTypeIsMatch = this.selectedObjectTypeFilter.length === 0 || this.selectedObjectTypeFilter.includes(this.getObjectTypeDesc(viewModelData));
        const nameIsMatch = viewModelData.nameColumn.name.toLowerCase().includes(this.searchString.toLowerCase());
        const listIdIsMatch = viewModelData.listId ? viewModelData.listId.toString().includes(this.searchString) : false;
        return (nameIsMatch || listIdIsMatch) && tagsIsMatch && objectTypeIsMatch && stateIsMatch && effecitveStatuseIsMatch && initWithoutDelete;
      }
    );
    const selectedObjects = _.intersectionWith(this.campaignList, this.filteredViewModelData,
      (campaign, viewModel) => viewModel.isDraft ? campaign.basic.draftId === viewModel.draftId : campaign.basic.id === viewModel.id);
    this.summaryData = {
      id: 0,
      listId: 'summaryRow',
      ...this.getSummaryData(selectedObjects)
    };

    this.updateState();
  }

  getSummaryData = (campaignList: Array<any>) => {
    const viewableSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.basic.viewable, 0);
    const clickSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.basic.clicks, 0);
    const convsSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.basic.convs, 0);
    const spentsSum = campaignList.reduce<number>((partial, campaign) => partial + getPriceValue(this.order.currency, campaign.basic.spents), 0);
    const impresSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.basic.impres, 0);
    const viewSum = campaignList.reduce<number>((partial, campaign) => partial + campaign.basic.adView, 0);
    return {
      nameColumn: i18n.t<string>('campaignList.labels.campaignCount', { count: campaignList.length }),
      budgetColumn: this.hideBudget ? '' : i18n.t<string>('campaignList.labels.campaignBudget', {
        number: formatPriceWithCurrency(
          this.order.currency,
          campaignList.reduce<number>((partial, campaign) => partial + campaign.basic.budget, 0)
        )}
      ),
      viewableColumn: numberWithCommas(viewableSum),
      impresColumn: numberWithCommas(impresSum),
      clicksColumn: numberWithCommas(clickSum),
      convertColumn: numberWithCommas(convsSum),
      viewColumn: numberWithCommas(viewSum),
      spents: numberWithCommas(spentsSum),
      cpcColumn: formatPriceAny(clickSum === 0 ? 0.00 : _.floor(spentsSum / clickSum, 2)),
      ctrColumn: `${impresSum === 0 ? 0.00 : ((clickSum / impresSum) * 100).toFixed(2)}%`,
      vctrColumn: `${viewableSum === 0 ? 0.00 : ((clickSum / viewableSum) * 100).toFixed(2)}%`,
      cpaColumn: formatPriceAny(convsSum === 0 ? 0.00 : _.floor(spentsSum / convsSum, 2)),
      cvrColumn: `${clickSum === 0 ? 0.00 : ((convsSum / clickSum) * 100).toFixed(2)}%`,
      viewRateColumn: `${impresSum === 0 ? 0.00 : ((viewSum / impresSum) * 100).toFixed(2)}%`,
      viewableViewRateColumn: `${viewableSum === 0 ? 0.00 : ((viewSum / viewableSum) * 100).toFixed(2)}%`
    };
  }

  getCampaignProgressRate = ({ budget, startDate, spents }) => {
    const isEnd = moment().isAfter(moment(startDate));
    const canCalRate = budget !== 0;
    if (canCalRate) {
      return {
        executeRate: isEnd ? spents / budget : 0,
        predictRate: isEnd ? 1 : 0
      };
    }
    return {
      executeRate: 0,
      predictRate: 0
    };
  }

  handleRemoveSelect = () => {
    this.selectedCampaign = [];
    this.selectedDraft = [];
    this.updateState();
  }

  activeCampaign = async (event): Promise<void> => {
    event && event.stopPropagation();
    this.excuteCampaignAction('activate', i18n.t<string>('campaignList.labels.activeSuccess'));
  }

  deactiveCampaign = async (event): Promise<void> => {
    event && event.stopPropagation();
    this.excuteCampaignAction('deactivate', i18n.t<string>('campaignList.labels.deactiveSuccess'));
  }

  generateUpdateStatePayload = (selectedObjects: number[], objectType: 'campaign' | 'draft') => {
    const key = objectType === 'campaign' ? 'id' : 'draftId';
    const payload = selectedObjects.map(objectId => {
      const object = this.campaignList.find(campaign => _.get(campaign.basic, key) && _.get(campaign.basic, key).toString() === objectId.toString());
      return {
        l2ChannelId: objectId,
        isDraft: _.get(object, 'isDraft', false)
      };
    });
    return payload;
  }

  excuteCampaignAction = async (action: 'activate' | 'deactivate', successMessage) => {
    this.updateLoading(true);
    const updateStatePayload = [
      ...this.generateUpdateStatePayload(this.selectedCampaign, 'campaign'),
      ...this.generateUpdateStatePayload(this.selectedDraft, 'draft')
    ];
    try {
      await this.manager.updateCampaignState(updateStatePayload, action);
      this.selectedCampaign = [];
      this.selectedDraft = [];
      this.onCampaignChange();
      toast.success(successMessage);
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  deleteCampaign = async (campaignIds: Array<number>): Promise<void> => {
    this.updateLoading(true);
    try {
      await this.manager.deleteCampaigns(campaignIds);
      this.selectedCampaign = [];
      this.onCampaignChange(true);
      toast.success(i18n.t<string>('campaignList.labels.deleteSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  deleteDraft = async (draftIds: Array<number>): Promise<void> => {
    this.updateLoading(true);
    try {
      await this.rtbCampaignDraftManager.deleteDrafts(draftIds);
      this.selectedDraft = [];
      this.onCampaignChange(true);
      toast.success(i18n.t<string>('campaignList.labels.deleteSuccess'));
    } catch (e) {
      (e instanceof Error) && toast.error(e.message);
    }
    this.updateLoading(false);
  }

  allSelectedCampaignSameType () {
    if (this.selectedCampaign.length === 0 && this.selectedDraft.length === 0) {
      return true;
    }

    const types = _.uniq(
      _.filter(this.campaignList, campaign =>
        (campaign.basic.id !== undefined && this.selectedCampaign.indexOf(campaign.basic.id) > -1) ||
        (campaign.basic.draftId !== undefined && this.selectedDraft.indexOf(campaign.basic.draftId) > -1))
        .map(campaign => campaign.adType)
    );
    return types.length === 1;
  }

  selectUnknowAdTypeCampaign () {
    if (this.selectedCampaign.length === 0 && this.selectedDraft.length === 0) {
      return false;
    }

    const selectCampaignList = _.filter(
      this.campaignList, campaign =>
      (campaign.basic.id !== undefined && this.selectedCampaign.indexOf(campaign.basic.id) > -1) ||
      (campaign.basic.draftId !== undefined && this.selectedDraft.indexOf(campaign.basic.draftId) > -1)
    );
    return selectCampaignList.filter(campaign => !campaign.basic.adType).length > 0;
  }

  canDeleteSelectedCampaigns (campaignIds: Array<number>) {
    if (campaignIds.length === 0) {
      return false;
    }

    const canDeleteCampaigns = _.filter(this.campaignList, campaign => {
      const selected = campaign.basic.id !== undefined && campaignIds.indexOf(campaign.basic.id) > -1;
      if (!selected) {
        return false;
      }
      const isEnd = moment().isAfter(moment(campaign.basic.startDate).startOf('day'));
      return !isEnd;
    });

    return canDeleteCampaigns.length === campaignIds.length;
  }

  canEditSelectedCampaigns (campaignIds: Array<number>) {
    if (campaignIds.length === 0) {
      return false;
    }

    const canEditCampaigns = _.filter(this.campaignList, campaign => {
      const selected = campaign.basic.id !== undefined && campaignIds.indexOf(campaign.basic.id) > -1;
      if (!selected) {
        return false;
      }
      const isEnd = moment().isAfter(moment(campaign.basic.startDate).startOf('day'));
      return !isEnd;
    });

    return canEditCampaigns.length === campaignIds.length;
  }

  setVisibilityOfDeleteConfirmModal (show: boolean) {
    this.showDeleteConfirmModal = show;
    this.updateState();
  }

  setVisibilityOfDraftCreateModal = (show: boolean) => {
    this.showDraftCreateModal = show;
    this.updateState();
  }

  setVisibilityOfDraftDeleteConfirmModal = (show: boolean) => {
    this.showDraftDeleteConfirmModal = show;
    this.updateState();
  }

  setCampaignToChangeBidWeight (campaignId?: number | string) {
    this.campaignToChangeBidWeight = campaignId;
    this.updateState();
  }

  updateModelData (order: Order, campaignList: Array<MessageCampaign>) {
    this.order = order;
    this.campaignList = campaignList;
    this.needUpdateViewModelData = true;
  }

  updateLoading (loading: boolean) {
    this.loading = loading;
    this.updateState();
  }

  updateState () {
    this.event.fireEvent(this);
  }
}
