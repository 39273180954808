import React from 'react';
import { SearchBar } from '../SearchBar/SearchBar';
import styles from './groupOptionSelection.module.scss';
import { GroupOptionSelectionProps, GroupOptionSelectionState } from './GroupOptionSelectionModel';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classnames from 'classnames/bind';
import i18n from 'i18next';
import _ from 'lodash';

export class GroupOptionSelection extends React.Component<GroupOptionSelectionProps, GroupOptionSelectionState> {

  handler: number;
  cssClassnames: any;
  ref: any;

  constructor (props) {
    super(props);
    this.ref = React.createRef();
    this.cssClassnames = classnames.bind(styles);
    this.handler = this.props.model.event.add((model) => {
      this.setState(model.state);
    });
  }

  componentDidMount () {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add((model) => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.event.remove(this.handler);
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.props.model.closeDropdown();
    }
  }

  renderGroups () {
    const model = this.props.model;
    const groupOptions = model.state.filteredGroupOptions;
    return Object.keys(groupOptions).map(groupLabel => {
      return (
        <div key={groupLabel} className={styles.group}>
          <div className={styles.groupTitle}>
            {groupLabel}
          </div>
          <div className={styles.groupContent}>
            {groupOptions[groupLabel].map(option => {
              const clickOptionFn = () => {
                model.handleOnSelect(option.value ? parseInt(option.value.toString(), 10) : undefined);
              };
              return (
                <div key={option.value} className={styles.option} onClick={clickOptionFn}>
                  {option.label}
                </div>
              );
            })}
          </div>
        </div>
      );
    });
  }

  render () {
    const model = this.props.model;
    const selectedValue = model.state.selectedValue;
    const clickAllOptionFn = () => { model.handleOnSelect(); };
    const dropdownClassName = this.cssClassnames('dropdownArea', {
      open: model.state.open
    });
    const useCustomStyle = model.useCustomStyle;
    const selectedOptionClassName = this.cssClassnames('selectedOption', {
      isPlaceholder: !selectedValue && !model.showShowAllBtn,
      customStyle: useCustomStyle
    });
    return (
      <div ref={this.ref} className={styles.groupOptionSelection}>
        <div className={selectedOptionClassName} onClick={model.triggerDropdown}>
          {this.props.model.getLabelByValue(selectedValue)}
          <FontAwesomeIcon icon={faCaretDown} />
        </div>
        <div className={dropdownClassName}>
          <SearchBar
            model={{
              placeholder: i18n.t<string>(`groupOptionsSelection.labels.${_.camelCase('search-' + model.i18nKey)}`),
              search: model.handleOnSearch
            }}
          />
          <div className={styles.options}>
            {model.showShowAllBtn &&
              <div className={styles.selectAll} onClick={clickAllOptionFn}>
                {this.props.model.getLabelByValue(undefined)}
              </div>
            }
            <div className={styles.groups}>
              {this.renderGroups()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
