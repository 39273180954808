import { Retail, Product, ProductSet } from 'core/product/Product';
import client from './RestClient';
import _ from 'lodash';
import { Pageable } from './Pageable';
import { ProductSegment, ProductSegmentRecord } from 'core/product/ProductSegment';
import { Pagination } from 'core/pagination/Pagination';
import { AxiosInstance } from 'axios';

export interface ProductSetUsageInfo {
  campaign: {
    id: string,
    name: string
  };
  creative: {
    id: string,
    name: string
  };
  mapState: number;
}
export interface RdpWebService {
  getRetails (): Promise<Retail[]>;
  getRetailProducts (retailId: number | string, queries?: any): Promise<Product[]>;
  getAllProducts (queries?: any): Promise<Product[]>;
  getRetailProductSets (
    retailId: string,
    advertiserId: number | string
  ): Promise<ProductSet[]>;
  getAllRetailProductSets (
    agencyId: number | string,
    advertiserId?: number | string
  ): Promise<ProductSet[]>;
  getProductSetDetail (retailId: number | string, productSetId: number | string): Promise<ProductSet>;
  createProductSet (retailId: number | string, productSetFormData): Promise<ProductSet>;
  updateProductSetAdd (retailId: number | string, productSetFormData): Promise<ProductSet>;
  updateProductSetRemove (retailId: number | string, productSetFormData): Promise<ProductSet>;
  getProductSegment (retailId: number | string, segmentId: string): Promise<ProductSegment>;
  getProductSegments (
    agencyId: number | string,
    retailerId: number | string,
    pageable: Pageable,
    searchString: string,
    showActive: boolean,
    advertiserId?: number | string
  ): Promise<{
    productSegments: ProductSegmentRecord[],
    pagination: Pagination
  }>;
  createProductSegment (retailId: string, productSegmentPayload): Promise<void>;
  updateProductSegment (retailId: string, segmentId: string, productSegmentPayload): Promise<void>;
  deleteProductSegment (retailId: string, segmentId: string): Promise<void>;
  getProductSetUsageInfo (productSetId: number | string): Promise<ProductSetUsageInfo[]>;
}

const wrapProductSetFromServer: any = (json: any) => {
  const products = _.get(json, 'products', []);

  return {
    ...json,
    update_frequency: _.get(json, 'update_frequency', 0),
    products: products.map(product => ({
      ...wrapProductFromServer(product),
      retailId: _.get(json, 'retailId', '')
    }))
  };
};

const wrapProductFromServer: any = (json: any) => {
  const skus = _.get(json, 'skus[0]', {});

  return {
    productId: _.get(json, 'productId', 0),
    retailId: _.get(json, 'retailId', ''),
    name: _.get(skus, 'name', ''),
    category: _.get(skus, 'category', ''),
    salePrice: _.get(skus, 'salePrice', 0),
    imgLink: _.get(skus, 'imgLink', '')
  };
};

export class RestfulRdpWebService implements RdpWebService {
  restClient: AxiosInstance;

  constructor (restClient: AxiosInstance = client) {
    this.restClient = restClient;
  }

  async getRetails (): Promise<Retail[]> {
    const response = await this.restClient.get(`/rdp/retail/all`);
    return response.data;
  }

  async getRetailProducts (retailId: number | string, queries: any = {}): Promise<Product[]> {
    const response = await this.restClient.get(`/rdp/retail/${retailId}/products/search/${JSON.stringify(queries)}`);
    return response.data.map(product => wrapProductFromServer(product));
  }

  async getAllProducts (queries: any = {}): Promise<Product[]> {
    const response = await this.restClient.get(`/rdp/retail/all/products/search/${JSON.stringify(queries)}`);
    return response.data.map(product => wrapProductFromServer(product));
  }

  async getRetailProductSets (
    retailId: string,
    advertiserId: number | string
  ): Promise<ProductSet[]> {
    const response = await this.restClient.get(`/rdp/retail/${retailId}/productSets/?advertiserId=${advertiserId}`);
    return response.data.map(productSet => wrapProductSetFromServer(productSet));
  }

  async getAllRetailProductSets (
    agencyId: number | string,
    advertiserId?: number | string
  ): Promise<ProductSet[]> {
    let parameters = `agencyId=${agencyId}`;
    if (advertiserId) {
      parameters = `${parameters}&advertiserId=${advertiserId}`;
    }
    const response = await this.restClient.get(`/rdp/retail/all/productSets?${parameters}`);
    return response.data.map(productSet => wrapProductSetFromServer(productSet));
  }

  async getProductSetDetail (retailId: number | string, productSetId: number | string): Promise<ProductSet> {
    const response = await this.restClient.get(`/rdp/retail/${retailId}/productSets/${productSetId}/products`);
    return wrapProductSetFromServer(response.data);
  }

  async createProductSet (retailId: number | string, productSetFormData): Promise<ProductSet> {
    const response = await this.restClient.post(`/rdp/retail/${retailId}/productSets`, productSetFormData);
    return wrapProductSetFromServer(response.data);
  }

  async updateProductSetAdd (retailId: number | string, productSetFormData: any): Promise<ProductSet> {
    const response = await this.restClient.put(`/rdp/retail/${retailId}/productSets/add`, productSetFormData);
    return wrapProductSetFromServer(response.data);
  }

  async updateProductSetRemove (retailId: number | string, productSetFormData: any): Promise<ProductSet> {
    const response = await this.restClient.put(`/rdp/retail/${retailId}/productSets/remove`, productSetFormData);
    return wrapProductSetFromServer(response.data);
  }

  async getProductSegment (retailId: number | string, segmentId: string): Promise<ProductSegment> {
    const response = await this.restClient.get(`/rdp/retail/${retailId}/segments/${segmentId}`);
    return response.data;
  }

  async getProductSegments (
    agencyId: number | string,
    retailerId: number | string,
    pageable: Pageable,
    searchString: string,
    showActive: boolean,
    advertiserId?: number | string
  ): Promise<any> {
    let { page, sizePerPage } = pageable;
    let path = `/rdp/retail/${retailerId}/segments?agencyId=${agencyId}`;
    if (advertiserId) {
      path = `${path}&advertiserId=${advertiserId}`;
    }
    path = `${path}&page=${page}&size=${sizePerPage}&search=${searchString}&enabled=${showActive}`;
    const response = await this.restClient.get(path);
    const pagination = response.data.pagination;
    return {
      productSegments: response.data.records,
      pagination: {
        page: _.get(pagination, 'page', 1),
        size: _.get(pagination, 'size', 10),
        totalCount: _.get(pagination, 'totalCount', 0)
      }
    };
  }

  async createProductSegment (retailId: string, productSegmentPayload: any): Promise<void> {
    await this.restClient.post(`/rdp/retail/${retailId}/segments`, productSegmentPayload);
  }

  async updateProductSegment (retailId: string, segmentId: string, productSegmentPayload) {
    await this.restClient.put(`/rdp/retail/${retailId}/segments/${segmentId}`, productSegmentPayload);
  }

  async deleteProductSegment (retailId: string, segmentId: string): Promise<void> {
    return this.restClient.delete(`/rdp/retail/${retailId}/segments/${segmentId}`);
  }

  async getProductSetUsageInfo (productSetId: number | string): Promise<ProductSetUsageInfo[]> {
    const response = await this.restClient.get(`/rdp/productSets/${productSetId}/campaignCreatives`);
    return response.data.records;
  }
}
