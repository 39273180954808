import React from 'react';
import i18n from 'i18next';
import styles from './imageCreativeSizeHint.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { otherWidthHeightMap } from './ImageFormModel';

export const ImageCreativeSizeHint: React.FunctionComponent<{closeHint: () => void}> = (props) => {

  const renderRecommendSize = (width, height) => {
    return (
      <div className={styles[`size${width}x${height}`]}>
        {`${width} x ${height}`}
      </div>
    );
  };

  const renderOtherSize = () => {
    return Object.keys(otherWidthHeightMap).map((width) => {
      const heights = otherWidthHeightMap[width];
      return heights.map((height) => {
        const size = `${width} x ${height}`;
        return (
          <div className={styles.otherSizeInstance} key={size}>
            <div/>
            {size}
          </div>
        );
      });
    });
  };

  return (
    <div className={styles.imageCreativeSizeHint}>
      <div className={styles.title}>
        <span>{i18n.t<string>('imageCreativeSizeHint.labels.sizeInstruction')}</span>
        <FontAwesomeIcon icon={faTimes} onClick={props.closeHint}/>
      </div>
      <div className={styles.recommendSize}>
        <div className={styles.sectionTitle}>
          {i18n.t<string>('imageCreativeSizeHint.labels.recommendTitle')}
        </div>
        <div className={styles.recommendSizeContent}>
          {renderRecommendSize(336, 280)}
          {renderRecommendSize(300, 100)}
          {renderRecommendSize(300, 250)}
          {renderRecommendSize(320, 100)}
          {renderRecommendSize(320, 50)}
          {renderRecommendSize(728, 90)}
          {renderRecommendSize(160, 600)}
          {renderRecommendSize(300, 600)}
          {renderRecommendSize(468, 60)}
          {renderRecommendSize(970, 250)}
          {renderRecommendSize(970, 90)}
        </div>
      </div>
      <div className={styles.otherSize}>
        <div className={styles.sectionTitle}>
          {i18n.t<string>('imageCreativeSizeHint.labels.otherSizeTitle')}
        </div>
        <div className={styles.otherSizeContent}>
          {renderOtherSize()}
        </div>
      </div>
    </div>
  );
};
