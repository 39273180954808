import { toast } from 'react-toastify';

export enum UploadEvent {
  UPLOAD_START = 'upload-start',
  UPLOAD_END = 'upload-end',
  UPLOAD_UPDATE = 'upload-update',
  UPLOAD_SYNC = 'upload-sync'
}
const UPDATE_INTERVAL = 3;

function isCustomEvent (event: Event): event is CustomEvent {
  return event instanceof CustomEvent;
}

let instance;
export class UploadProgressManager {

  static getInstance () {
    if (!instance) {
      instance = new UploadProgressManager();
    }
    return instance;
  }

  timer;
  uploadData = {};

  start () {
    window.addEventListener(UploadEvent.UPLOAD_START, this.uploadStartHandler);
    window.addEventListener(UploadEvent.UPLOAD_END, this.uploadEndHandler);
    window.addEventListener(UploadEvent.UPLOAD_SYNC, this.uploadSyncHandler);
  }

  stop () {
    window.removeEventListener(UploadEvent.UPLOAD_START, this.uploadStartHandler);
    window.removeEventListener(UploadEvent.UPLOAD_END, this.uploadEndHandler);
    window.removeEventListener(UploadEvent.UPLOAD_SYNC, this.uploadSyncHandler);
    this.stopProcessUpdater();
  }

  dispatchProgressData = () => {
    const progressData: { [id: string]: number } = {};
    Object.keys(this.uploadData).forEach(id => {
      this.uploadData[id].remainSecond = this.uploadData[id].remainSecond - UPDATE_INTERVAL;
      this.uploadData[id].remainSecond = this.uploadData[id].remainSecond < 0 ? 0 : this.uploadData[id].remainSecond;
      const { totalSecond, remainSecond } = this.uploadData[id];
      progressData[id] = 1 - remainSecond / totalSecond;
      progressData[id] = progressData[id] > 0.95 ? 0.95 : progressData[id];
    });
    const event = new CustomEvent<{
      progressData: { [id: string]: number }
    }>(UploadEvent.UPLOAD_UPDATE, { detail: { progressData } });
    window.dispatchEvent(event);
  }

  startProcessUpdater = () => {
    window.onbeforeunload = event => {
      toast.warn('There are still files being uploading, leaving page will be canceled!');
      const e = event;
      if (e) {
        e.preventDefault();
        e.returnValue = '';
      }
      return '';
    };
    this.timer = setInterval(() => {
      this.dispatchProgressData();
    }, UPDATE_INTERVAL * 1000);
  }

  stopProcessUpdater = () => {
    this.timer && clearInterval(this.timer);
    this.timer = undefined;
    window.onbeforeunload = null;
  }

  uploadStartHandler = (event: Event) => {
    if (!isCustomEvent(event)) {
      return;
    }
    const detail = event.detail;
    if (!detail.id) {
      return;
    }
    if (Object.keys(this.uploadData).length === 0) {
      this.startProcessUpdater();
    }
    this.uploadData[detail.id] = {
      totalSecond: detail.totalSecond,
      remainSecond: detail.remainSecond
    };
  }

  uploadEndHandler = (event: Event) => {
    if (!isCustomEvent(event)) {
      return;
    }
    delete this.uploadData[event.detail.id];
    const updateEvent = new CustomEvent<{progressData: {
      [id: string]: number
    }}>(UploadEvent.UPLOAD_UPDATE, {
      detail: {
        progressData: {
          [event.detail.id.toString()]: 1
        }
      }
    });
    window.dispatchEvent(updateEvent);
    if (Object.keys(this.uploadData).length === 0) {
      this.stopProcessUpdater();
    }
  }

  uploadSyncHandler = () => {
    this.dispatchProgressData();
  }
}
