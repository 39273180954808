import React, { MutableRefObject } from 'react';

type RefType = ((instance: HTMLVideoElement | null) => void) | MutableRefObject<HTMLVideoElement | null> | null;
interface VideoPreviewProps {
  maxWidth?: number;
  maxHeight?: number;
  src: File | string;
  ref?: RefType;
}

const adjustWidthHeight = (width: number, height: number, maxWidth: number, maxHeight: number) => {
  const ratio = height / width;
  if (width > maxWidth) {
    width = maxWidth;
    height = width * ratio;
  }
  if (height > maxHeight) {
    height = maxHeight;
    width = height / ratio;
  }
  return {
    width,
    height
  };
};

const getVideoSourceUrl = (src: File | string) => {
  return src instanceof File ? URL.createObjectURL(src) : src;
};

export const VideoPreview: React.FunctionComponent<VideoPreviewProps> = React.forwardRef((({ src, maxWidth = 600, maxHeight = 400 }, parentRef: RefType) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const source = getVideoSourceUrl(src);
  const handleVideoMetaData = () => {
    if (!videoRef.current) {
      return;
    }
    let width = videoRef.current.videoWidth;
    let height = videoRef.current.videoHeight;
    const {
      width: newWidth,
      height: newHeight
    } = adjustWidthHeight(width, height, maxWidth, maxHeight);
    videoRef.current.width = newWidth;
    videoRef.current.height = newHeight;
  };

  return (
    <video
      controls
      ref={ref => {
        if (videoRef.current !== ref) {
          if (parentRef) {
            if (typeof parentRef === 'function') {
              parentRef(ref);
            } else {
              parentRef.current = ref;
            }
          }
          videoRef.current && videoRef.current.removeEventListener('loadedmetadata', handleVideoMetaData);
          videoRef.current = ref;
          videoRef.current && videoRef.current.addEventListener('loadedmetadata', handleVideoMetaData);
        }
      }}
    >
      <source src={source} type='video/mp4'/>
    </video>
  );
}));
