import { SelectOptions } from 'components/common/commonType';
import { AgencyManager, DefaultAgencyManager } from 'core';
import { AdvertiserManager, DefaultAdvertiserManager } from 'core/advertiser/AdvertiserManager';
import { SessionStorageHelper, SessionStorageItemKeys } from 'helper/StorageHelper';
import { useCallAPI } from 'hooks/useCallAPI';
import { useEffect, useState } from 'react';

const defaultAdvertiserManager: AdvertiserManager = new DefaultAdvertiserManager();
const defaultAgencyManager: AgencyManager = new DefaultAgencyManager();

export const useMessageSegmentsModel = (
  advertiserManager: AdvertiserManager = defaultAdvertiserManager
) => {

  const { loading, callAPIs } = useCallAPI();
  const [advertisers, setAdvertisers] = useState<SelectOptions[]>([]);
  const [advertiserId, setAdvertiserId] = useState(SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER));

  useEffect(() => {
    callAPIs([
      advertiserManager.getAdvertiserOptions.bind(advertiserManager)
    ], advertisers => setAdvertisers(advertisers));
  }, [advertiserManager, callAPIs]);

  return {
    loading,
    agencyId: undefined,
    agencies: [],
    advertiserId,
    advertisers,
    setAgencyId: () => {
      // This is intentional
    },
    setAdvertiserId
  };
};

export const useSystemAdminSegmentsModel = (
  agencyManager: AgencyManager = defaultAgencyManager,
  advertiserManager: AdvertiserManager = defaultAdvertiserManager
) => {

  const { loading, callAPIs } = useCallAPI();
  const [agencies, setAgencies] = useState<SelectOptions[]>([]);
  const [advertisers, setAdvertisers] = useState<SelectOptions[]>([]);
  const [agencyId, setAgencyId] = useState(SessionStorageHelper.getNumberItem(SessionStorageItemKeys.AGENCY));
  const [advertiserId, setAdvertiserId] = useState(SessionStorageHelper.getNumberItem(SessionStorageItemKeys.ADVERTISER));

  useEffect(() => {
    callAPIs([
      agencyManager.getAgenciesOptions.bind(agencyManager)
    ], agencies => setAgencies(agencies));
  }, [agencyManager, callAPIs]);

  useEffect(() => {
    if (!agencyId) {
      return;
    }

    callAPIs([
      advertiserManager.getAdvertiserOptions.bind(advertiserManager, agencyId)
    ], advertisers => setAdvertisers(advertisers));
  }, [agencyId, advertiserManager, callAPIs]);

  return {
    loading,
    agencyId,
    agencies,
    advertiserId,
    advertisers,
    setAgencyId,
    setAdvertiserId
  };
};
