import React from 'react';
import styles from './creativeBasicForm.module.scss';
import { CreativeBasicFormProps, CreativeBasicFormState } from './CreativeBasicFormModel';
import { Form } from 'react-bootstrap';
import i18n from 'i18next';
import { CreativeType } from 'core/creative/Creative';
import _ from 'lodash';
import cx from 'classnames/bind';

export class CreativeBasicForm extends React.Component<CreativeBasicFormProps, CreativeBasicFormState> {

  handler: any;
  classNames = cx.bind(styles);

  constructor (props) {
    super(props);
    this.handler = this.props.model.event.add(model => {
      this.setState(model.state);
    });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.model !== this.props.model) {
      prevProps.model.event.remove(this.handler);
      this.handler = this.props.model.event.add(model => {
        this.setState(model.state);
      });
    }
  }

  componentWillUnmount () {
    this.props.model.onUnmount(this.handler);
  }

  renderLabel = (props) => {
    const value = props.field.value;
    return (
      <Form.Label className={styles.formLabel}>
        {i18n.t<string>(`creativeType.${_.camelCase(CreativeType[value])}`)}
      </Form.Label>
    );
  }

  renderHintModal = () => {
    const model = this.props.model;
    if (!model.state.hintModalData) {
      return undefined;
    }
    const HintModal = model.state.hintModalData.component;
    const modalProps = model.state.hintModalData.props;
    return <HintModal {...modalProps}/>;
  }

  render () {
    const model = this.props.model;
    const contentModel = model.getContentModelByCreativeType();
    const Content: typeof React.Component = contentModel ? contentModel.getFormContent() : () => <div />;
    return (
      <div className={styles.creativeBasicForm}>
        <Content
          model={contentModel}
          getInitFormConfig={model.getInitFormConfig}
          renderHintModal={this.renderHintModal}
          hintModalData={model.state.hintModalData}
          setHintModalData={model.setHintModalData}
          basicFields={model.state.basicFields}
        />
      </div>
    );
  }
}
