import { Route, Switch } from 'react-router-dom';
import { SourceList } from './Source/SourceList';
import { SourceForm } from './Source/SourceForm';
import { SourceDetail } from './Source/SourceDetail';
import { useCreateSourceFormModel, useEditSourceFormModel } from './Source/SourceFormModel';

export const TenmaxSegments = () => {

  const renderSourceDetail = () => {
    return <SourceDetail/>;
  };

  const renderSourceCreateForm = () => {
    return <SourceForm useModel={useCreateSourceFormModel}/>;
  };

  const renderSourceEditForm = () => {
    return <SourceForm useModel={useEditSourceFormModel}/>;
  };

  const renderSourceList = () => {
    return (
      <SourceList/>
    );
  };

  return (
    <Switch>
      <Route
        path={'/tenmax-segments/sources/new'}
        render={renderSourceCreateForm}
      />
      <Route
        path={'/tenmax-segments/sources/:sourceId/edit'}
        render={renderSourceEditForm}
      />
      <Route
        path={'/tenmax-segments/sources/:sourceId'}
        render={renderSourceDetail}
      />
      <Route
        render={renderSourceList}
      />
    </Switch>
  );
};
