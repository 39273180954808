export enum FieldType {
  IMAGE = 'image',
  VIDEO = 'video',
  TEXT = 'text',
  URL = 'url'
}

export type FieldConfig = {
  type: FieldType;
  limits?: {
    fileSize?: string;
    fileRatio?: string;
    fileStorage?: number;
    fileType?: string[];
    videoDuration?: number;
    textMinLength?: number;
    textMaxLength?: number;
  }
};
