import { SelectOptions } from 'components/common/commonType';
import { AgencyManager, DefaultAgencyManager } from 'core';
import { AdRequestSourceManager, DefaultAdRequestSourceManager } from 'core/adRequestSource/AdRequestSourceManager';
import { GroupType, InputType, ProductFormValue } from 'core/limitation/ProductGroup';
import { DefaultProductGroupManager, ProductGroupManager } from 'core/limitation/ProductGroupManager';
import { useContext, useEffect, useMemo, useState } from 'react';
import { createSelectOptionsFromEnum } from 'utils/SelectOptionsUtils';
import _ from 'lodash';
import { validateEmpty } from 'utils/ValidateUtils';
import coreContext from 'contexts/coreContext';
import i18n from 'i18n';
import { isSystemAdmin } from 'helper/ActorHelper';
import { useCallAPI } from 'hooks/useCallAPI';

const defaultProductGroupManager: ProductGroupManager = new DefaultProductGroupManager();
const defaultAdRequestSourceManager: AdRequestSourceManager = new DefaultAdRequestSourceManager();
const defaultAgencyManager: AgencyManager = new DefaultAgencyManager();

const useFetchProductGroupOptions = (
  adRequestSourceManager: AdRequestSourceManager = defaultAdRequestSourceManager,
  agencyManager: AgencyManager = defaultAgencyManager
) => {

  const core = useContext(coreContext);
  const isSysAdmin = core ? isSystemAdmin(core.authenticationManager.actor) : false;

  const [options, setOptions] = useState<{
    spaceOptions: SelectOptions[],
    agencyOptions: SelectOptions[],
    domainOptions: SelectOptions[]
  }>({
    spaceOptions: [],
    agencyOptions: [],
    domainOptions: []
  });
  const { loading, callAPIs } = useCallAPI();

  useEffect(() => {
    callAPIs([
      adRequestSourceManager.getSSPSpaces.bind(adRequestSourceManager),
      adRequestSourceManager.getDomains.bind(adRequestSourceManager),
      agencyManager.getAgenciesOptions.bind(agencyManager)
    ], (spaceData, domainData, agencyOptions) => {
      setOptions({
        spaceOptions: spaceData.filter(data => !data.isGroup).map(spaceData => ({
          label: `${spaceData.value},${spaceData.label}`,
          value: spaceData.value,
          options: spaceData.options ? spaceData.options.map(data => ({ label: `${data.value},${data.label}`, value: data.value })) : undefined
        })),
        agencyOptions,
        domainOptions: domainData.filter(data => !data.isGroup).map(data => ({ label: data.label, value: data.value }))
      });
    });
  }, [agencyManager, adRequestSourceManager, callAPIs, isSysAdmin]);

  return {
    loading,
    options
  };
};

const toServerStructure = (productGroup: ProductFormValue) => ({
  ...productGroup,
  groupValues: productGroup.groupValues ?
    productGroup.groupValues.map(groupValue => ({ limitationName: groupValue.label, limitationValue: groupValue.value })) :
    []
});

const validate = (value: ProductFormValue) => {
  return _.omitBy({
    groupName: validateEmpty(value.groupName)
  }, _.isEmpty);
};

const useCustomModal = () => {

  const [modalData, setModalData] = useState<any>(undefined);

  const showCustomModal = (groupType: GroupType) => setModalData({
    title: i18n.t<string>(`productGroupForm.labels.${groupType === GroupType.ADSPACE ? 'customSpace' : 'customDomain'}`),
    value: {
      [GroupType.ADSPACE]: {
        id: '',
        name: ''
      },
      [GroupType.DOMAIN]: []
    }
  });

  const hideCustomModal = () => setModalData(undefined);

  const validateModalData = (groupType: GroupType, inputType: InputType, values) => {
    if (groupType === GroupType.DOMAIN) {
      return;
    }

    if (inputType === InputType.SINGLE) {
      return _.omitBy({
        [GroupType.ADSPACE]: _.omitBy({
          id: validateEmpty(values[groupType].id),
          name: validateEmpty(values[groupType].name)
        }, _.isEmpty)
      }, _.isEmpty);
    } else {
      return _.omitBy({
        [GroupType.ADSPACE]: _.omitBy({
          inputs: validateEmpty(values[groupType].inputs)
        }, _.isEmpty)
      }, _.isEmpty);
    }
  };

  return {
    modalData,
    showCustomModal,
    hideCustomModal,
    validateModalData
  };
};

export type ProductGroupFormData = {
  title: string;
  loading: boolean;
  initProductGroup?: ProductFormValue;
  productGroupTypeOptions: SelectOptions[];
  spaceOptions: SelectOptions[];
  domainOptions: SelectOptions[];
  agencyOptions: SelectOptions[];
  redirectPath?: string;
  selectedInputType: InputType;
  inputTypes: InputType[];
  onInputTypeChange: (inputType: any) => void;
  modalData: any,
  showCustomModal: (groupType: GroupType) => void,
  hideCustomModal: () => void,
  validateModalData: (groupType: GroupType, inputType: InputType, value) => any,
  validate: (values: ProductFormValue) => any;
  save: (values: ProductFormValue) => Promise<void>;
};

export const useCreateProductGroupFormModel = (
  productGroupManager: ProductGroupManager = defaultProductGroupManager
): ProductGroupFormData => {

  const core = useContext(coreContext);
  const agencyIds = core && core.authenticationManager.actor && core.authenticationManager.actor.agencyId ?
    [core.authenticationManager.actor.agencyId] :
    [];
  const inputTypes = useMemo(() => Object.values(InputType), []);

  const initProductGroup = {
    groupName: '',
    groupType: GroupType.ADSPACE,
    description: '',
    groupValues: [],
    agencyIds
  };

  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);
  const { loading: loadingForOptions, options } = useFetchProductGroupOptions();
  const customModalData = useCustomModal();
  const { loading, callAPIs } = useCallAPI();
  const [ selectedInputType, setSelectedInputType ] = useState<InputType>(InputType.SINGLE);
  const productGroupTypeOptions = useMemo(() => createSelectOptionsFromEnum(GroupType, 'productGroupForm.labels.'), []);

  const save = async (saveValue) => {
    callAPIs([
      productGroupManager.createProductGroup.bind(productGroupManager, toServerStructure(saveValue))
    ], () => {
      setRedirectPath('/system/product-groups');
    });
  };

  const onInputTypeChange = (inputType: any) => {
    if (inputType === null) {
      return;
    }
    setSelectedInputType(inputType);
  };

  return {
    title: i18n.t<string>('productGroupForm.labels.createTitle'),
    redirectPath,
    selectedInputType,
    inputTypes,
    onInputTypeChange,
    loading: loading || loadingForOptions,
    initProductGroup,
    productGroupTypeOptions,
    ...options,
    ...customModalData,
    validate,
    save
  };
};

export const useEditProductGroupFormModel = (
  productGroupId: number,
  productGroupManager: ProductGroupManager = defaultProductGroupManager
): ProductGroupFormData => {

  const [redirectPath, setRedirectPath] = useState<string | undefined>(undefined);
  const [initProductGroup, setInitProductGroup] = useState<ProductFormValue | undefined>(undefined);
  const { loading: loadingForOptions, options } = useFetchProductGroupOptions();
  const customModalData = useCustomModal();
  const { loading, callAPIs } = useCallAPI();
  const [ selectedInputType, setSelectedInputType ] = useState<InputType>(InputType.SINGLE);
  const inputTypes = useMemo(() => Object.values(InputType), []);

  const productGroupTypeOptions = useMemo(() => createSelectOptionsFromEnum(GroupType, 'productGroupForm.labels.'), []);

  useEffect(() => {
    callAPIs([
      productGroupManager.getProductGroupById.bind(productGroupManager, productGroupId)
    ], productGroup => {
      const isSpace = productGroup.groupType === GroupType.ADSPACE;
      setInitProductGroup({
        ...productGroup,
        agencyIds: _.get(productGroup, 'agencyIds', []),
        groupValues: _.get(productGroup, 'groupValues', []).map(groupValue => {
          const value = groupValue.limitationValue.replace(/^\\/, '');
          return {
            label: isSpace ? `${value},${groupValue.limitationName}` : groupValue.limitationName,
            value
          };
        })
      });
    });
  }, [productGroupId, productGroupManager, callAPIs]);

  const save = async (saveValue) => {
    callAPIs([
      productGroupManager.updateProductGroup.bind(productGroupManager, productGroupId, toServerStructure(saveValue))
    ], () => {
      setRedirectPath('/system/product-groups');
    });
  };

  const onInputTypeChange = (inputType: any) => {
    if (inputType === null) {
      return;
    }
    setSelectedInputType(inputType);
  };

  return {
    title: i18n.t<string>('productGroupForm.labels.editTitle'),
    redirectPath,
    loading: loading || loadingForOptions,
    initProductGroup,
    selectedInputType,
    onInputTypeChange,
    inputTypes,
    productGroupTypeOptions,
    ...customModalData,
    ...options,
    validate,
    save
  };
};
