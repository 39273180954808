import React from 'react';
import { VideoInput } from 'components/common/Video/VideoInput';
import styles from './videoFormInput.module.scss';
import classNames from 'classnames/bind';

export const VideoFormInput: React.FunctionComponent<any> = (props) => {

  const cssClassNames = classNames.bind(styles);

  const onFileChange = (file) => {
    if (!file) {
      props.onChange && props.onChange({
        file: undefined,
        url: undefined
      });
      return;
    }
    const validTypes = ['video/mp4'];
    if (validTypes.indexOf(file.type) === -1) {
      props.onChange && props.onChange({
        file,
        url: props.url
      });
      return;
    }
    const video = document.createElement('video');
    video.addEventListener('loadedmetadata', () => {
      props.onChange && props.onChange({
        file,
        width: video.videoWidth,
        height: video.videoHeight,
        duration: video.duration,
        url: props.url
      });
    });
    video.src = URL.createObjectURL(file);
  };
  const mainClassdName = cssClassNames('videoPreview', props.className, {
    showErrorBorder: props.errorBorderHint,
    showSolidBorder: props.url || props.file
  });
  return (
    <div className={mainClassdName}>
      <div className={styles.videoContent}>
        <VideoInput
          {...props}
          onChange={onFileChange}
        />
      </div>
    </div>
  );
};
